import React, { Component } from "react";
import ReactTable from "react-table";
import { withStyles } from '@material-ui/core/styles';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import communicationRegisterStyles from "../../../assets/styles/communicationRegisterStyles";
import { withRouter } from "react-router-dom";
import { formatShortDate } from "../../../globals";
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { notificationClauses } from '../../../variables/notificationClauses';
import clsx from 'clsx';
import matchSorter from 'match-sorter';

class UncorrectedDefectRegister extends Component {

    state = {
        clauseData : null,
        type : ""
    };

    findRelevantClauseData = (clauseNumber) => {
        return notificationClauses.clauseData.find(obj => obj.clauseNumber === clauseNumber);
    };

    // Handle clicks in register row/s to redirect to relevant detailed page
    onRowClick = (row) => {
        let path = '/app/detailedNotification';
        this.props.history.push({
            pathname: path,
            state: {
                type: this.state.type,
                id: row.id,
                apiEndpoints: notificationClauses.apiUrls,
                clauseData: this.state.clauseData
            }
        });
    };

    getReduxStateName = () => {
        return "uncorrected_defect_register";
    };

    componentDidMount() {
        const type = "notification";
        const payload = {
            url: notificationClauses.apiUrls.uncorrectedDefectRegister,
            id: this.getReduxStateName()
        };
        this.setState({
            clauseData : this.findRelevantClauseData("45.1"),
            type : type
        }, () => {
            this.props.onGetRegisterData(payload);
        });
    }

    render() {
        const { clauseData, type } = this.state;
        const { classes } = this.props;
        if (this.props.registersErrors[this.getReduxStateName()]) {
            return null;
        }
        else if (this.props.registersLoading[this.getReduxStateName()] || this.props.registersLoading[this.getReduxStateName()] === undefined) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} sm={12} lg={12} className={classes.loadGridItem}><CircularProgress color={"primary"} className={clsx(classes.progress, classes.loadingWheel)} style={{ marginTop: 15 }} /></GridItem></GridContainer>;
        } else {
            return (
                clauseData && type && <GridContainer>
                    <GridItem xs={12} sm={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.gridItem + " " + classes.cardAlignment}>
                            <CardBody>
                                <ReactTable
                                    /*Click on a row to see diary entry details functionality*/
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                e.preventDefault();
                                                this.onRowClick(rowInfo.row);

                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.props.registers[this.getReduxStateName()] ? this.props.registers[this.getReduxStateName()] : []}
                                    filterable
                                    resizable={false}
                                    showPageJump={false}
                                    noDataText={"No data"}
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false,
                                        },
                                        {
                                            Header: "Title",
                                            accessor: "title",
                                            id: "title",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["title"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Contract",
                                            id: "contractNumber",
                                            accessor: "contract.number",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["contractNumber"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Defect",
                                            id: "defect",
                                            accessor: "defect.title",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["defect"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Cost",
                                            accessor: "cost",
                                            id: "cost",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["cost"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Submitted Date",
                                            accessor: date => formatShortDate(date.dateCreated),
                                            id: "dateCreated",
                                            show: true,
                                            Cell: row => (
                                                <p className={classes.marBtm0}>{row.value !== null ? formatShortDate(row.value) : ""}</p>
                                            ),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["dateCreated"] }),
                                            filterAll: true
                                        },
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{ cursor: "pointer" }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        registers: state.communicationReducer.registers,
        registersLoading: state.communicationReducer.registersLoading,
        registersErrors: state.communicationReducer.registersErrors
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRegisterData: (payload) => dispatch(actionCreators.getRegisterData(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationRegisterStyles)(UncorrectedDefectRegister)));

