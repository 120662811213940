import * as actionTypes from "../actions/actionTypes";

const initialState = {
    value: 0
};

const setSelectedTab = (state, action) => {
    return {
        value : action.value
    };
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_SELECTED_TAB: return setSelectedTab(state, action);
        default: return state
    }
};

export default reducer;