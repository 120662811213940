import customSelectStyles from "../jss/material-dashboard-pro-react/customSelectStyle";
import validationFormsStyle from "../jss/material-dashboard-pro-react/views/validationFormsStyle";

const emailDialogStyles = theme => ({
    ...customSelectStyles,...validationFormsStyle,
    gridContainer : {
        marginTop: 27
    },
    gridItem : {
        // textAlign: "center", margin: "15px 15px 0 15px"
    },
    heading : {
        marginBottom: 0, fontWeight: 400, display: "inline", marginRight: 10
    },
    addButton : {
        // cursor: "pointer", fontSize: 16, marginBottom: -3
    },
    customListItem : {
        paddingLeft: 0, borderBottom: "1px solid #999"
    },
    closeIcon : {
        cursor: "pointer", fontSize: 16, marginTop: 5, color: "red"
    },
    noDataText : {
        fontSize:14,
        fontStyle:"italic",
        // marginLeft: 5,
        marginBottom : 0,
        //fontWeight : "bold"
    },
    inputContainer : {
        marginTop : -10
    },
    marLftN15 : {
        [theme.breakpoints.down("xs")]: {
            marginLeft: -15
        }
    },
    block : {
        [theme.breakpoints.down("xs")]: {
            display: "block"
        }
    },
});

export default emailDialogStyles;
