//views
import AddDiaryEntry from "./views/Pages/Diary/AddDiaryEntry.jsx";
import AddEventRegister from "./views/Pages/CompensationEvent/AddEventRegister.jsx";
import AddProject from "./views/Pages/Dashboard/Project/AddProject.jsx";
import ContractView from "./views/Pages/Contract/ContractView.jsx";
import Taskview from "./views/Pages/Dashboard/Taskview.jsx";
import Diary from "./views/Pages/Diary/Diary.jsx";
import ViewEventNotice from "./views/Pages/CompensationEvent/CompensationEventNotification/ViewEventNotice.jsx";
import EventRegister from "./views/Pages/CompensationEvent/EventRegister.jsx";
import LoginPage from "./views/Pages/User/LoginPage.jsx";
import LogoutPage from "./views/Pages/User/LogoutPage.jsx";
import NewEarlyWarning from "./views/Pages/Risk/Early Warning/NewEarlyWarning.jsx";
import ProjectView from "./views/Pages/Dashboard/Project/ProjectView.jsx";
import RegisterPage from "./views/Pages/User/RegisterPage.jsx";
import EarlyWarningRegister from "./views/Pages/Risk/Early Warning/EarlyWarningRegister.jsx";
import UserProfile from "./views/Pages/User/UserProfile.jsx";
import ViewEarlyWarning from "./views/Pages/Risk/Early Warning/ViewEarlyWarning.jsx";
import ViewDiaryEntry from "./views/Pages/Diary/ViewDiaryEntry";
import ViewEvent from "./views/Pages/CompensationEvent/ViewEvent.jsx";
import NoticeRegister from "./views/Pages/CompensationEvent/CompensationEventNotification/NoticeRegister.jsx";
import AddEventNotice from "./views/Pages/CompensationEvent/CompensationEventNotification/AddEventNotice.jsx";
import CompensationEventTabs from "./views/Pages/CompensationEvent/CompensationEventTabs.jsx";
import CommunicationMaster from "./views/Pages/Communications/CommunicationMaster.jsx";
// import CommunicationDetailedPage from "./views/Pages/Communications/CommunicationDetailedPage.jsx";
import SubmissionDetailedPage from "./views/Pages/Communications/CommunicationDetails/SubmissionDetailedPage.jsx";
import NotificationDetailedPage from "./views/Pages/Communications/CommunicationDetails/NotificationDetailedPage.jsx";
import Defects from "./views/Pages/Defects/Defects.jsx";
import OtherRegistersTabs from "./views/Pages/OtherRegisters/OtherRegistersTabs";

//icons
import Assignment from "@material-ui/icons/Assignment";
import Ballot from "@material-ui/icons/Ballot";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";
import ReportProblem from "@material-ui/icons/ReportProblem";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import FeedbackIcon from '@material-ui/icons/Feedback';
import ErrorIcon from '@material-ui/icons/Error';
import ViewList from '@material-ui/icons/ViewList';

//defines links to go in sidebar menu
let routes = [
  {
    path: "/taskView",
    name: "Task View",
    icon: DashboardIcon,
    component: Taskview,
    layout: "/app"
  },
  {
    path: "/projectView",
    name: "Project View",
    icon: ViewStreamIcon,
    component: ProjectView,
    layout: "/app",
    invisible: false
  },
  {
    path: "/contractView",
    name: "Contracts",
    component: ContractView,
    layout: "/app",
    invisible: true
  },
  {
    path: "/riskRegister",
    name: "Risk Register",
    icon: ReportProblem,
    component: EarlyWarningRegister,
    layout: "/app",
    invisible: false
  },
  {
    path: "/eventRegister",
    name: "Compensation Event Register",
    // icon: Ballot,
    component: EventRegister,
    layout: "/app",
    invisible: true
  },
  {
    path: "/allEventRegister",
    name: "Event Register",
    icon: Ballot,
    component: CompensationEventTabs,
    layout: "/app",
    invisible: false
  },
  {
    path: "/diary",
    name: "Daily Diary Register",
    icon: Assignment,
    component: Diary,
    layout: "/app",
    invisible: false
  },
  {
    path: "/addDiary",
    name: "New Diary Entry",
    component: AddDiaryEntry,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/viewDiary",
    name: "Selected Diary Entry",
    component: ViewDiaryEntry,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/communications",
    name: "Communications",
    component: CommunicationMaster,
    layout: "/app",
    invisible: false,
    icon: FeedbackIcon
  },
  {
    path: "/defects",
    name: "Defects Register",
    component: Defects,
    layout: "/app",
    invisible: false,
    icon : ErrorIcon
  },
  {
    path: "/otherRegisters",
    name: "Other Registers",
    component: OtherRegistersTabs,
    layout: "/app",
    invisible: false,
    icon : ViewList
  },
  {
    path: "/detailedSubmission",
    name: "Submission Detailed",
    component: SubmissionDetailedPage,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/detailedNotification",
    name: "Notification Detailed",
    component: NotificationDetailedPage,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/addProject",
    name: "New Project",
    component: AddProject,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/viewRisk",
    name: "Selected Risk",
    component: ViewEarlyWarning,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/newEarlyWarning",
    name: "New Early Warning",
    component: NewEarlyWarning,
    layout: "/app",
    invisible: true,
  },
  {
    path: "/registerPage",
    name: "Register",
    mini: "R",
    component: RegisterPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/userProfile",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/app"
  },
  {
    path: "/logoutPage",
    name: "Log Out",
    icon: ExitToApp,
    component: LogoutPage,
    layout: "/auth",
  },
  {
    path: "/loginPage",
    name: "Log In",
    component: LoginPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/viewNotice",
    name: "Selected Compensation Event Notice",
    component: ViewEventNotice,
    layout: "/app",
    invisible: true
  },
  {
    path: "/addEvent",
    name: "Add Event",
    component: AddEventRegister,
    layout: "/app",
    invisible: true
  },
  {
    path: "/addNotice",
    name: "Add Event Notice",
    component: AddEventNotice,
    layout: "/app",
    invisible: true
  },
  {
    path: "/viewEvent",
    name: "Selected Event",
    component: ViewEvent,
    layout: "/app",
    invisible: true
  },
  {
    path: "/noticeRegister",
    name: "Compensation Event Notice Register",
    component: NoticeRegister,
    layout: "/app",
    invisible: true
  },
  
];
export default routes;
