import React from 'react';
import GridItem from "../Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import GridContainer from "../Grid/GridContainer";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import defectCategoryDropdownStyles from "../../assets/styles/defectCategoryDropdownStyles";

const DefectCategoryDropdown = (props) =>
        <GridContainer className={props.classes.marTop10}>
            <GridItem xs={12} sm={12} md={12} lg={12} className={props.classes.marBtm10}>
                <FormControl fullWidth className={props.classes.fontColor} error={props.inputState === "error"}>
                    <InputLabel className={props.classes.fontSize14} htmlFor="category-select" style={props.inputState !== "error" ? props.inputState === "success" ? {color: "#4caf50"} : {color: "#616161"} : null}>Defect Category *</InputLabel>
                    <Select
                        value={props.category}
                        onChange={(event) => props.onChange(event, "category", props.statePrefix)}
                        className={props.classes.fontSize14}
                        inputProps={{
                            id: "category-select"
                        }}
                    >
                        <MenuItem className={props.classes.fontSize14} disabled value={0}><em>Choose defect category</em></MenuItem>
                        {props.categories.map((item, index) =>
                            <MenuItem className={props.classes.fontSize14 + " " + props.classes.listItem} key={index}
                                      value={item}>{item}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </GridItem>
        </GridContainer>

DefectCategoryDropdown.propTypes = {
    onChange : PropTypes.func,
    category : PropTypes.string,
    categories : PropTypes.array.isRequired,
    statePrefix : PropTypes.string,
    inputState : PropTypes.string,
};

export default withStyles(defectCategoryDropdownStyles)(DefectCategoryDropdown);
