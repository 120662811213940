import React from "react";
import * as PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter} from 'react-router-dom';
// import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
// import Person from "@material-ui/icons/Person";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "../../components/CustomInput/CustomInput.jsx";
// import Button from "../../components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {

//function that routes to user profile page
  handleUserClick = () => {
      let path = '/app/userProfile';
      this.props.history.push({
          pathname: path
      });
  };

  render() {
    // const { classes } = this.props;
    // const searchButton = classes.top + " " + classes.searchButton;

    return (
      <div>
        {/*<CustomInput*/}
        {/*  formControlProps={{*/}
        {/*    className: classes.top + " " + classes.search*/}
        {/*  }}*/}
        {/*  inputProps={{*/}
        {/*    placeholder: "Search",*/}
        {/*    inputProps: {*/}
        {/*      "aria-label": "Search",*/}
        {/*      className: classes.searchInput*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Button*/}
        {/*  color="white"*/}
        {/*  aria-label="edit"*/}
        {/*  justIcon*/}
        {/*  round*/}
        {/*  className={searchButton}*/}
        {/*>*/}
        {/*  <Search*/}
        {/*    className={classes.headerLinksSvg + " " + classes.searchIcon}*/}
        {/*  />*/}
        {/*</Button>*/}

        {/*<Button*/}
        {/*  color="transparent"*/}
        {/*  aria-label="Person"*/}
        {/*  justIcon*/}
        {/*  round*/}
        {/*  className={classes.buttonLink}*/}
        {/*  muiClasses={{*/}
        {/*    label: ""*/}
        {/*  }}*/}
        {/*  onClick={() => this.handleUserClick()}*/}
        {/*>*/}
        {/*  <Person*/}
        {/*    className={*/}
        {/*      classes.headerLinksSvg +*/}
        {/*      " " +*/}
        {/*      classes.links*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Hidden mdUp implementation="css">*/}
        {/*    <span className={classes.linkText}>*/}
        {/*      {"Profile"}*/}
        {/*    </span>*/}
        {/*  </Hidden>*/}
        {/*</Button>*/}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks));
