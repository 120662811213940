import React from 'react';

//Custom Components
// import CustomDashBoardToDoItemAddQuote from "../../components/Card/CustomDashBoardToDoItemAddQuote.jsx";
// import CustomDashBoardToDoItemCENApproval from "../Card/CustomDashBoardToDoItemCENApproval.jsx";
import CustomDashBoardToDoItemExtension from "../Card/CustomDashBoardToDoItemExtension.jsx";
// import CustomDashBoardToDoItemQuotationApproval from "../Card/CustomDashBoardToDoItemQuotationApproval.jsx";
import CustomDashBoardToDoItem from "../Card/CustomDashBoardToDoItem";
import CommunicationToDoCard from "../Card/CommunicationToDoCard";
import CustomDashBoardToDoItemTimeBarNotification from "../Card/CustomDashBoardToDoItemTimeBarNotification";
// import CustomDashBoardToDoItemDiaryResponse from "../Card/CustomDashBoardToDoItemDiaryResponse.jsx";

class DashboardToDoList extends React.Component {

    // componentDidMount(){
    //     alert(item.type.toLowerCase() + " " + )
    // }
    render() {

        // const { classes } = this.props;


        return (
            <div style={{ maxHeight: 600, overflowX: "hidden", overflowY: "auto" }}>
                {/* Render custom to do card for each item in list*/}
                {this.props.items ? this.props.items
                    .filter(item => {        //filter each to do item based on contract and project chosen

                        if (item.type !== null) {

                            let projectFlag = false;
                            let contractFlag = false;

                            //Submit Quote
                            if (item.type.toLowerCase() === "ce" || item.type.toLowerCase() === "reviewquote") {

                                this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                    parseInt(item.compensationEvent.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                    parseInt(item.compensationEvent.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;

                            }

                            //CEN Approval
                            if (item.type.toLowerCase() === "cen") {

                                this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                    parseInt(item.compensationEventNotification.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                    parseInt(item.compensationEventNotification.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;
                            }

                            if (item.type.toLowerCase() === "risk") {
                                this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                    parseInt(item.risk.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                    parseInt(item.risk.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;

                            }

                            if (item.type.toLowerCase() === "submissionrevisionresponse" || item.type.toLowerCase() === "submissionrevision") {
                                this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                    parseInt(item.submission.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                    parseInt(item.submission.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;
                            }

                            if (item.type.toLowerCase() === "notificationrevisionresponse" || item.type.toLowerCase() === "notificationrevision") {
                                this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                    parseInt(item.notification.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                    parseInt(item.notification.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;
                            }

                            //Extension
                            if (item.type.toLowerCase() === "extension") {

                                //CE Extension
                                if (item.subType === "CE") {
                                    this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                        parseInt(item.compensationEvent.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                    this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                        parseInt(item.compensationEvent.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;
                                }
                                //CEN Extension
                                else if (item.subType === "CEN") {

                                    this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                        parseInt(item.compensationEventNotification.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false) : projectFlag = true;

                                    this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                        parseInt(item.compensationEventNotification.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false) : contractFlag = true;
                                }
                                // //Dairy Response Extension
                                // else{
                                //     this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                                //       parseInt(item.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false ) : projectFlag = true;
                                //
                                //     this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                                //       parseInt(item.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false ) : contractFlag = true;
                                // }

                            }

                            //  //Dairy Response approval
                            // if(item.type.toLowerCase() === "dailyDiary"){
                            //
                            //     // this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                            //     //   parseInt(item.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false ) : projectFlag = true;
                            //     //
                            //     // this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                            //     //   parseInt(item.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false ) : contractFlag = true;
                            //
                            // }

                            return projectFlag || contractFlag;
                        }
                        else {
                            return false;
                        }

                    })
                    .sort(function (item1, item2) {        //sort to do items based on how much time is left to reply

                        if (item1.type.toLowerCase() === "extension") { //ensure extension to do item sits at top regardless of time left
                            return -1;
                        }
                        else if (item2.type.toLowerCase() === "extension") { //ensure extension to do item sits at top regardless of time left
                            return 1;
                        }
                        else {

                            if (item1.sortByDate === null) {
                                return -1;
                            }

                            if (item2.sortByDate === null) {
                                return 1;
                            }

                            let returnValue;
                            returnValue = (new Date(item1.sortByDate).getTime()) - (new Date(item2.sortByDate).getTime());

                            if (returnValue === 0) {
                                if (item1.updateDate === null) {
                                    return -1;
                                }
                                if (item2.updateDate === null) {
                                    return 1;
                                }
                                returnValue = (new Date(item2.updateDate).getTime()) - (new Date(item1.updateDate).getTime());
                            }

                            return returnValue;
                        }

                    }).map((item, index) =>
                        item.type.toLowerCase() === "extension" ?
                            <CustomDashBoardToDoItemExtension key={index} item={item} /> :
                            item.type.toLowerCase() === "submissionrevisionresponse" || item.type.toLowerCase() === "submissionrevision" || item.type.toLowerCase() === "notificationrevisionresponse" || item.type.toLowerCase() === "notificationrevision" ?
                                <CommunicationToDoCard key={index} item={item} /> :
                                (item.type.toLowerCase() === "ce" || item.type.toLowerCase() === "cen") && item.subType === "Time_Bar" ?
                                    <CustomDashBoardToDoItemTimeBarNotification key={index} item={item} /> :
                                    <CustomDashBoardToDoItem key={index} item={item} />
                    ) : ""}
            </div>

        );
    }
}
export default DashboardToDoList;