import React from 'react';
import * as PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../CustomButtons/Button";
import Card from "./Card.jsx";
import CardBody from "./CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { getCurrentDate } from "../../globals";
import { withRouter } from "react-router-dom";
import { submissionClauses } from "../../variables/submissionClauses";
import { notificationClauses } from "../../variables/notificationClauses";
import communicationToDoCardStyles from "../../assets/styles/communicationToDoCardStyles";
import { withStyles } from '@material-ui/core/styles';
import * as actionCreators from "../../store/actions";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

class CommunicationToDoCard extends React.Component {

  state = {
    communicationClauseNumber: "",
    communicationType: "",
    titleAbbreviation: "",
    toDoItemName: "",
    clause: {},
    loaded: false
  };

  //function that routes to specific
  routeToViewMore = (id) => {
    if (this.props.item.subType === "Acknowledge") {
      let payload = {
        url: "/update_submission_revision_status",
        id: this.getReduxStateName(),
      }

      let params = {_submissionID: this.props.item[this.state.communicationType].id}

      payload.params = params

      this.props.onAcknowledgeToDo(payload);
    }
    else if (this.props.item.subType === "Prompt") {
      let path = '';
      if (this.state.communicationType === "submission") {
        this.props.onSetSelectedTab(0);
        path = '/app/communications';
      }
      else if (this.state.communicationType === "notification") {
        this.props.onSetSelectedTab(1);
        path = '/app/communications';
      }

      this.props.history.push({
        pathname: path
      });  
    }
    else {
      let path = '';
      if (this.state.communicationType === "submission") {
        path = '/app/detailedSubmission';
      }
      else if (this.state.communicationType === "notification") {
        path = '/app/detailedNotification';
      }
      const clauseData = this.state.clause.clauseData.find(item => item.clauseNumber === this.state.communicationClauseNumber);

      if (clauseData) {
        this.props.history.push({
          pathname: path,
          state: {
            id: id,
            apiEndpoints: this.state.clause.apiUrls,
            clauseData: clauseData,
            type: this.state.communicationType
          },
        });
      }
    }
  };

  getTimeToReply = (item) => {
    let currentDate = new Date(getCurrentDate() + " 23:59:59");
    let actionDays = 0;

    //if (item["periodOfReplyDate"] !== null) {
    let actionDate = new Date(item["periodOfReplyDate"]);

    let timeDiff = actionDate.getTime() - currentDate.getTime();
    actionDays = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;
    //}

    return actionDays;
  };

  updateTimeToReply = () => {
    if (this.props.item[this.state.toDoItemName]["periodOfReplyDate"] !== null)
      return this.getTimeToReply(this.props.item[this.state.toDoItemName]) + " day(s) left";
    else
      return null;
  };

  updateTimeToReplyColor = () => {
    let colourDays = "";

    let totalActionDays = this.props.item[this.state.communicationType]["actionPeriod"];
    let daysToReply = this.getTimeToReply(this.props.item[this.state.toDoItemName]);

    if (totalActionDays !== 0) {
      let amberBorderLine = Math.ceil(((totalActionDays / 6) * 3));

      let redBorderLine = Math.ceil((totalActionDays / 6));

      if (daysToReply <= redBorderLine) {
        colourDays = "#F44336";
      }
      else if (daysToReply <= amberBorderLine) {
        colourDays = "#FF9800";
      }
      else {
        colourDays = "#4CAE50";
      }
    }
    else {
      colourDays = "#F44336";
    }
    return colourDays;

  };

  getReduxStateName = () => {
    return this.state.communicationType + this.props.item[this.state.communicationType]["id"];
  };

  componentDidMount() {
    let communicationClauseNumber = "";
    let communicationType = "";
    let toDoItemName = "";
    let titleAbbreviation = "";
    let clause = null;
    let actionButtonName = "";
    let undefineType = true;

    switch (this.props.item.type) {
      case "SubmissionRevisionResponse": {
        communicationClauseNumber = this.props.item["submissionRevision"].clause.toString();
        communicationType = "submission";
        toDoItemName = "submissionRevision";
        titleAbbreviation = "SUB";
        clause = submissionClauses;
        undefineType = false;
        break;
      }
      case "SubmissionRevision": {
        communicationClauseNumber = this.props.item["submissionRevision"].clause.toString();
        communicationType = "submission";
        toDoItemName = "submissionRevisionResponse";
        titleAbbreviation = "SUB";
        clause = submissionClauses;
        undefineType = false;
        break;
      }
      case "NotificationRevisionResponse": {
        communicationClauseNumber = this.props.item["notificationRevision"].clause.toString();
        communicationType = "notification";
        toDoItemName = "notificationRevision";
        titleAbbreviation = "NOT";
        clause = notificationClauses;
        undefineType = false;
        break;
      }
      case "NotificationRevision": {
        communicationClauseNumber = this.props.item["notificationRevision"].clause.toString();
        communicationType = "notification";
        toDoItemName = "notificationRevisionResponse";
        titleAbbreviation = "NOT";
        clause = notificationClauses;
        undefineType = false;
        break;
      }
      default:
        break;
    }

    switch (this.props.item.subType) {
      case 'Acknowledge':
        actionButtonName = "Acknowledge Receipt";
        break;
      case 'Prompt':
        actionButtonName = "Go To";
        break;
      default:
        actionButtonName = "View More";
        break;
    }

    if (!undefineType) {
      this.setState({
        communicationClauseNumber: communicationClauseNumber,
        communicationType: communicationType,
        toDoItemName: toDoItemName,
        titleAbbreviation: titleAbbreviation,
        clause: clause,
        actionButtonName: actionButtonName,
        loaded: true
      });
    }

  }

  render() {
    const { classes, item, acknowledgeToDoLoading } = this.props;
    const { communicationClauseNumber, communicationType, loaded, actionButtonName } = this.state;

    const toDoCardParagraphFloatRight = classes.toDoCardParagraph + " " + classes.toDoCardFloatRight;

    return (
      loaded && <div>
        <Card className={classes.toDoCard}>
          <CardBody className={classes.toDoCardBody}>
            <GridContainer>
              {/* Communication number */}
              <GridItem xs={6} sm={6} lg={6} className={classes.toDoCardGridItem}>
                <div className={classes.toDoCardPaddingLeft}>
                  <Tooltip
                    title={"Clause Number"}
                    placement="top-start"
                  >
                    <p className={classes.toDoCardParagraph}>{communicationClauseNumber}</p>
                  </Tooltip>
                </div>
              </GridItem>

              {/* Day(s) left to reply */}
              {this.updateTimeToReply() != null ?
              <GridItem xs={6} sm={6} lg={6} className={classes.toDoCardGridItem}>
                <div className={classes.toDoCardPaddingRight}>

                  <p className={toDoCardParagraphFloatRight}>
                    {this.updateTimeToReply()}
                    <FiberManualRecord style={{ color: this.updateTimeToReplyColor() }} className={classes.daysLeftToReply} />
                  </p>
                </div>
              </GridItem> : null}

              {/* To Do Description*/}
              <GridItem xs={12} sm={12} lg={12} >
                <div className={classes.toDoCardPaddingLeft}>
                  <Tooltip
                    title={item["description"] ? item["description"] : ""}
                    placement="top-start"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <h4 className={classes.toDoDescription}>{item["description"] ? item["description"] : ""}</h4>
                  </Tooltip>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} lg={12}>
                <div className={classes.toDoCardPaddingLeft}>
                  <hr className={classes.toDoActionSeparator} />
                  <Button simple size={"sm"} color={"primary"} className={classes.toDoAction} disabled={item.subType === "Acknowledge" && acknowledgeToDoLoading[this.getReduxStateName()]} onClick={() => this.routeToViewMore(item[communicationType]["id"])}>
                    {actionButtonName}
                  </Button>
                  {item.subType === "Acknowledge" && acknowledgeToDoLoading[this.getReduxStateName()] && <CircularProgress size={24} className={classes.loadingWheel} />}
                </div>
              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

CommunicationToDoCard.propTypes = {
  item: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    acknowledgeToDoLoading: state.toDoReducer.acknowledgeToDoLoading,
    acknowledgeToDoError: state.toDoReducer.acknowledgeToDoError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcknowledgeToDo: payload => dispatch(actionCreators.acknowledgeToDo(payload)),
    onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload)),
    onSetSuccess: payload => dispatch(actionCreators.setSuccessMessage(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationToDoCardStyles)(CommunicationToDoCard)));
