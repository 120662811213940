import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
import InputAdornment from "@material-ui/core/InputAdornment/index";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl/index";
import Select from "@material-ui/core/Select/index";
import InputLabel from "@material-ui/core/InputLabel/index";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/index";
import { verifyLength, addZero, daysArray, getCurrentDate, monthsArray } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";

const styles = theme => ({
    ...customSelectStyles,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});

class AddDiaryEntry extends React.Component{

    state = {

        days : daysArray(),
        months : monthsArray(),

        project: 0,

        postData: {
            contract: 0,
            diaryDate: getCurrentDate(),
            siteManager: "",
            rain: 0,
            maxTemperature: 0,
            minTemperature: 0,
            peopleOnSite: {
                ourPeople: {
                    seniorManagement: 0,
                    management: 0,
                    supervisors: 0,
                    operators: 0,
                    skilledLabour: 0,
                    semiSkilledLabour: 0,
                    unskilledLabour: 0,
                    disabledPeople: 0,
                },
                subContractor: [],
                otherPeople: []
            },
            equipmentOnSite: [

            ],
            // deliveredDescription: "",
            // deliveredNumber: 0,
            plantDelivered: [],
            programActivities: {
                startedToday: [
                ],
                programActivityProgressOnSite: "",
                programActivityCompleted: "",
                delayDesc: ""
            },
            healthSafetyIncidentsDesc: "",
            numHealthSafetyIncidents: 0,
            environmentalIncidentsDesc: "",
            numEnvironmentalIncidents: 0,
            qualityIncidentsDesc: "",
            numQualityIncidents: 0,
            generalComments: "",
            //othersToBeInvited: [],
        },

        newSubContractor: "",
        newSubContractorDiscipline: "",
        newSubContractorNumPeople: 0,

        newOtherPeopleOnSiteDescription: "",
        newOtherPeopleNumPeople: 0,

        newEquipment: "",
        newEquipmentWorking: "Yes",

        newPlantDeliveredDescription: "",
        newPlantDeliveredNumber: 0,

        newActivity: "",
        newActivityNumber: 0,

        newOtherEmail: "",
        // othersTobeInvited: [],

        diaryDateInputState: "",

        error: false,
        isLoaded: false,
        loadingAddEntry: false,

        allProjects: [],
        currentChosenProject: "",

        openAddAttachmentDialog: false,
        attachments: [],
        currentFile: {},
        currentFileName: "No file chosen",

        diaryDatesTaken: [],

    };

    //onChange event handler for input fields
    onChange = (event) => {

        const id = event.target.id;

        //Project Input
        if(id === "diaryProjectInput") {

            let index = this.state.allProjects.findIndex(o => o.project.id === parseInt(event.target.value));

            this.setState({
                currentChosenProject: index,
                project: event.target.value,
            });
        }

        //Contract Input
        if(id === "diaryContractInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {contract: parseInt(event.target.value)}),
            });
        }
        //Date Input
        else if(id === "diaryDateInput"){

            this.setState({ postData: Object.assign(
                    {},
                    this.state.postData,
                    {   diaryDate: event.target.value }),
            });

            let valid = true;

            if(this.state.diaryDatesTaken.length !== 0){
                this.state.diaryDatesTaken.forEach(item => {

                    if(this.state.postData.contract === item.contract){

                        if(event.target.value === item.date){
                            alert("Error - Only one active diary per day allowed");
                            this.setState({diaryDateInputState: "error"});
                            valid = false;
                        }
                    }

                });
            }

            if(valid){

                let diaryDate = new Date(event.target.value);
                let thresholdDate = new Date(getCurrentDate());
                thresholdDate.setDate(thresholdDate.getDate() - 3);

                if(diaryDate.getTime() < thresholdDate.getTime()){
                    alert("Error - A diary entry cannot be created for before " + thresholdDate.getFullYear() + "-" + addZero(thresholdDate.getMonth()+1) + "-" + addZero(thresholdDate.getDate()) );
                    this.setState({diaryDateInputState: "error"});
                    valid = false;
                }
            }

            if(valid){

                if (verifyLength(event.target.value, 1)) {
                    this.setState({diaryDateInputState: "success"});
                } else {
                    this.setState({diaryDateInputState: "error"});
                }

            }

        }
        //Site Manager Input
        else if(id === "diarySiteManagerInput"){
            this.setState({ postData: Object.assign(
                    {},
                    this.state.postData,
                    {   siteManager: event.target.value }),
            });
        }
        //Rain Input
        else if(id === "diaryRainInput"){
            this.setState({ postData: Object.assign(
                    {},
                    this.state.postData,
                    {   rain: event.target.value }),
            });
        }
        //Max temperature Input
        else if(id === "diaryMaxTemperatureInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        maxTemperature: event.target.value}),
            });
        }
        //Min temperature Input
        else if(id === "diaryMinTemperatureInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        minTemperature: event.target.value }),
            });
        }
        //Senior Management Input
        else if(id === "diarySeniorManagementInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { seniorManagement: event.target.value
                                    }),
                        }),
                })
            });
        }
        //Management Input
        else if(id === "diaryManagementInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { management: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Supervisors Input
        else if(id === "diarySupervisorsInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { supervisors: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Operators Input
        else if(id === "diaryOperatorsInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { operators: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Skilled labour Input
        else if(id === "diarySkilledLabourInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { skilledLabour: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Semi-skilled labour Input
        else if(id === "diarySemiSkilledLabourInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { semiSkilledLabour: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Unskilled labour Input
        else if(id === "diaryUnskilledLabourInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { unskilledLabour: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //Disabled labour Input
        else if(id === "diaryDisabledLabourInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { ourPeople: Object.assign(
                                    {},
                                    this.state.postData.peopleOnSite.ourPeople,
                                    { disabledPeople: event.target.value
                                    }),
                            }),
                    })
            });
        }
        //New Sub Contractor Input
        else if(id === "diaryNewSubContractorDesInput"){
            this.setState({
                newSubContractor: event.target.value
            });
        }
        //New Sub Contractor Discipline Input
        else if(id === "diaryNewSubContractorDisciplineInput"){
            this.setState({
                newSubContractorDiscipline: event.target.value
            });
        }
        //New Sub Contractor Number of people Input
        else if(id === "diaryNewSubContractorNumOfPeopleInput"){
            this.setState({
                newSubContractorNumPeople: event.target.value
            });
        }
        //New Other People Description Input
        else if(id === "diaryNewOtherPeopleDesInput"){
            this.setState({
                newOtherPeopleOnSiteDescription: event.target.value
            });
        }
        //New Other People Number of people Input
        else if(id === "diaryNewOtherPeopleNumOfPeopleInput"){
            this.setState({
                newOtherPeopleNumPeople: event.target.value
            });
        }
        // //Other people on site Input
        // else if(id === "diaryOtherPeopleInput"){
        //
        //     this.setState({
        //         postData: Object.assign(
        //             {},
        //             this.state.postData,
        //             {
        //                 peopleOnSite: Object.assign(
        //                     {},
        //                     this.state.postData.peopleOnSite,
        //                     { otherPeople: event.target.value
        //                     }),
        //             })
        //     });
        // }
        //New Equipment Input
        else if(id === "diaryNewEquipmentInput"){
            this.setState({
                newEquipment: event.target.value
            });
        }
        //New Equipment Is Working Input
        else if(id === "diaryNewEquipmentWorkingInput"){
            this.setState({
                newEquipmentWorking: event.target.value
            });
        }
        //New Plant Description Input
        else if(id === "diaryNewPlantDescriptionInput"){
            this.setState({
                newPlantDeliveredDescription: event.target.value
            });
        }
        //New Plant Number Delivered Is Working Input
        else if(id === "diaryNewPlantNumberDeliveredInput"){
            this.setState({
                newPlantDeliveredNumber: event.target.value
            });
        }
        // //Plant Description
        // else if(id === "diaryDeliveredDescription"){
        //
        //     this.setState({
        //         postData: Object.assign(
        //             {},
        //             this.state.postData,
        //             { deliveredDescription: event.target.value }),
        //     });
        // }
        // //Plant Delivered Today
        // else if(id === "diaryDeliveredNumber"){
        //
        //     this.setState({
        //         postData: Object.assign(
        //             {},
        //             this.state.postData,
        //             { deliveredNumber: event.target.value }),
        //     });
        // }
        //New Activity Input
            else if(id === "diaryNewActivityInput"){
            this.setState({
                newActivity: event.target.value
            });
        }
        //New Activity Number Input
        else if(id === "diaryNewActivityNumberInput"){
            this.setState({
                newActivityNumber: event.target.value
            });
        }
        //Progress On Site Input
        else if(id === "diaryProgressOnSiteInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        programActivities: Object.assign(
                            {},
                            this.state.postData.programActivities,
                            { programActivityProgressOnSite: event.target.value }),
                    })
            });
        }
        //Program Activities Completed Input
        else if(id === "diaryActivitiesCompleted"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        programActivities: Object.assign(
                            {},
                            this.state.postData.programActivities,
                            { programActivityCompleted: event.target.value }),
                    })
            });
        }
        //Delays Disruptions Input
        else if(id === "diaryDelaysInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        programActivities: Object.assign(
                            {},
                            this.state.postData.programActivities,
                            { delayDesc: event.target.value }),
                    })
            });
        }
        //Health & Safety Description
        else if(id === "diaryHealthSafetyIncidentsDescInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { healthSafetyIncidentsDesc: event.target.value }),
            });
        }
        //Health & Safety Number
        else if(id === "diaryNumHealthSafetyIncidentsInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { numHealthSafetyIncidents: event.target.value }),
            });
        }
        //Environmental Description
        else if(id === "diaryEnvironmentalIncidentsDescInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { environmentalIncidentsDesc: event.target.value }),
            });
        }
        //Environmental Number
        else if(id === "diaryNumEnvironmentalIncidentsInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { numEnvironmentalIncidents: event.target.value }),
            });
        }
        //Quality Description
        else if(id === "diaryQualityIncidentsDescInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { qualityIncidentsDesc: event.target.value }),
            });
        }
        //Quality Number
        else if(id === "diaryNumQualityIncidentsInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { numQualityIncidents: event.target.value }),
            });
        }
        //General Comments
        else if(id === "diaryGeneralCommentsInput")
        {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { generalComments: event.target.value }),
            });
        }
    };

    //function that handles changing an attachment
    changeAttachment = (event) => {

        if(event.target.files.length === 0){
            this.setState({
                currentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }

    };

    //function that handles removing items from input lists
    removeFromList = (index, type) => {

        //sub contractor list
        if(type === "subContractors"){

            let arr = this.state.postData.peopleOnSite.subContractor;
            arr.splice(index, 1);

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { subContractor: arr }),
                    })
            });

        }
        //other people on site list
        else if(type === "otherPeople"){

            let arr = this.state.postData.peopleOnSite.otherPeople;
            arr.splice(index, 1);

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        peopleOnSite: Object.assign(
                            {},
                            this.state.postData.peopleOnSite,
                            { otherPeople: arr }),
                    })
            });

        }
        //equipment
        else if(type === "equipment"){

            let arr = this.state.postData.equipmentOnSite;
            arr.splice(index, 1);

            this.setState(
                {
                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            equipmentOnSite:  arr
                        })
                }
            );

        }
        //plant & material
        else if(type === "plant"){

            let arr = this.state.postData.plantDelivered;
            arr.splice(index, 1);

            this.setState(
                {
                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            plantDelivered:  arr
                        })
                }
            );
        }
        //activities started today
        else if(type === "activities"){

            let arr = this.state.postData.programActivities.startedToday;
            arr.splice(index, 1);

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        programActivities: Object.assign(
                            {},
                            this.state.postData.programActivities,
                            { startedToday: arr }),
                    })
            });
        }
        //attachments
        else if(type === "attachments"){

            let arr = this.state.attachments;
            arr.splice(index, 1);

            this.setState({
                attachments: arr
            });

        }
        //other people to be invited
        // else if(type === "otherPeopleToBeInvited"){
        //
        //     let arr = this.state.postData.othersToBeInvited;
        //     arr.splice(index, 1);
        //
        //     this.setState({
        //         postData : Object.assign(
        //             {}, this.state.postData,
        //             {
        //                 othersToBeInvited: arr
        //             }
        //         )
        //     });
        // }
    };

    addToList = (type) => {

        //sub contractor list
        if(type === "subContractors"){

            let arr = this.state.postData.peopleOnSite.subContractor;

            arr.push({
                subContractorName: this.state.newSubContractor,
                discipline: this.state.newSubContractorDiscipline,
                peopleOnSiteSubContractor: this.state.newSubContractorNumPeople
            });

            this.setState(
                {
                    newSubContractor: "",
                    newSubContractorDiscipline: "",
                    newSubContractorNumPeople: 0,

                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            peopleOnSite: Object.assign(
                                {},
                                this.state.postData.peopleOnSite,
                                { subContractor: arr }),
                        })
                }
            );
        }
        //other people on site list
        if(type === "otherPeople"){

            let arr = this.state.postData.peopleOnSite.otherPeople;

            arr.push({
                description: this.state.newOtherPeopleOnSiteDescription,
                noOfPeople: this.state.newOtherPeopleNumPeople,
            });

            this.setState(
                {
                    newOtherPeopleOnSiteDescription: "",
                    newOtherPeopleNumPeople: 0,

                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            peopleOnSite: Object.assign(
                                {},
                                this.state.postData.peopleOnSite,
                                { otherPeople: arr }),
                        })
                }
            );
        }
        //equipment
        else if(type === "equipment"){

            let arr = this.state.postData.equipmentOnSite;

            arr.push({
                equipmentName: this.state.newEquipment,
                isWorking: this.state.newEquipmentWorking,
            });

            this.setState(
                {
                    newEquipment: "",
                    newEquipmentWorking: "Yes",

                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            equipmentOnSite:  arr
                        })
                }
            );

        }
        //plant & material
        else if(type === "plant"){

            let arr = this.state.postData.plantDelivered;

            arr.push({
                description: this.state.newPlantDeliveredDescription,
                numberDelivered: this.state.newPlantDeliveredNumber,
            });

            this.setState(
                {
                    newPlantDeliveredDescription: "",
                    newPlantDeliveredNumber: 0,

                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            plantDelivered:  arr
                        })
                }
            );

        }
        //activities started today
        else if(type === "activities"){

            let arr = this.state.postData.programActivities.startedToday;

            arr.push({
                programActivityNumber: this.state.newActivityNumber,
                programActivityDesc: this.state.newActivity
            });

            this.setState(
                {
                    newActivityNumber: 0,
                    newActivity: "",

                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {
                            programActivities: Object.assign(
                                {},
                                this.state.postData.programActivities,
                                { startedToday: arr }),
                        })
                }
            );
        }
        //attachments
        else if(type === "attachments"){

            if(this.state.currentFileName === "No file chosen"){
                alert("Please choose an attachment");
                return;
            }

            let arr = this.state.attachments;

            let obj = {
                fileName: this.state.currentFileName,
                file: this.state.currentFile
            };

            arr.push(obj);

            this.setState({
                attachments: arr, openAddAttachmentDialog: false, currentFileName: "No file chosen", currentFile: {}
            });

        }
        //other people to be invited
        // else if(type === "otherPeopleToBeInvited"){
        //
        //     if(verifyEmail(this.state.newOtherEmail)) {
        //
        //         let arr = this.state.postData.othersToBeInvited;
        //
        //         let obj = {
        //             email: this.state.newOtherEmail,
        //         };
        //
        //         arr.push(obj);
        //
        //         this.setState({
        //             newOtherEmail: "",
        //             postData : Object.assign(
        //                 {}, this.state.postData,
        //                 {
        //                     othersToBeInvited: arr
        //                 }
        //             )
        //         });
        //     }
        //     else{
        //         alert("Invalid email");
        //     }
        //
        // }
    };

    constructDiariesDates = () => {

        let arr = this.state.diaryDatesTaken;

        //use daily_diaries api
        const headers = {
            "Content-Type" : "application/json"
        };

        fetch(getApiRoot() + "/api/daily_diaries", {
            credentials: "include",
            headers,
            method: 'GET',
        }).then(res => {

            if(res.status === 200){
                return res.json();
            }
        })
            .then(
                (result) => {

                    if(result !== undefined){
                        result.forEach(diary => {
                            arr.push({
                                date: new Date(diary.diaryDate).getFullYear() + "-" + addZero(new Date(diary.diaryDate).getMonth()+1) + "-" + addZero(new Date(diary.diaryDate).getDate()),
                                contract: diary.contract.id
                            });

                            if(this.state.postData.contract === diary.contract.id){

                                if(new Date(diary.diaryDate).getTime() === new Date(getCurrentDate()).getTime()){
                                    this.setState({diaryDateInputState: "error"});
                                }
                            }
                            // console.log(arr);
                        });

                        this.setState({
                            diaryDatesTaken: arr, isLoaded: true,
                        });
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    };

    //function that implements projects_contracts_list api to populate project & contract dropdowns
    getAllProjects = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/projects_contracts_list", {
            credentials: "include",
            headers,
            method: "GET"
            ,})
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        // isLoaded: true,
                        allProjects: result,
                        project: result[0].project.id,
                        postData: Object.assign(
                            {},
                            this.state.postData,
                            {
                                contract: result[0].contracts[0].id
                            }),
                        currentChosenProject: 0
                    });

                    this.constructDiariesDates();

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

    //function that validates data and calls addDiaryAPI function
    onAddEntry = (event) => {

        this.setState({ loadingAddEntry : true });

        let flag = true;

        // if (this.state.diaryDateInputState === "") {
        //     this.setState({ diaryDateInputState: "error" });
        //     flag = false;
        // }

        let diaryDateClashValid = true;
        let diaryDateAgeValid = true;

        if(this.state.diaryDatesTaken.length !== 0){
            this.state.diaryDatesTaken.forEach(item => {

                if(this.state.postData.contract === item.contract){

                    if(this.state.postData.diaryDate === item.date){
                        diaryDateClashValid = false;
                    }
                }

            });
        }

        if(diaryDateClashValid){
            let diaryDate = new Date(this.state.postData.diaryDate);
            let thresholdDate = new Date(getCurrentDate());
            thresholdDate.setDate(thresholdDate.getDate() - 3);

            if(diaryDate.getTime() < thresholdDate.getTime()){
                diaryDateAgeValid = false;
            }
        }

        if(diaryDateAgeValid && diaryDateClashValid){

            if (this.state.diaryDateInputState === "error") {
                flag = false;
            }

            if(this.state.postData.contract === 0){
                flag = false;
            }

        }

        if(flag && diaryDateClashValid && diaryDateAgeValid){
            //calls create_daily_dairy api
            this.addDiaryAPI();
        }
        else if(!diaryDateClashValid){
            this.setState({ loadingAddEntry : false });
            alert("Error - Only one active diary per day allowed");
        }
        else if(!diaryDateAgeValid){
            this.setState({ loadingAddEntry : false });
            alert("Error - A diary entry cannot be created for more than 3 days ago" );
        }
        else {
            this.setState({ loadingAddEntry : false });
            alert("Contract and Date fields are required");
        }

        event.preventDefault();
    };

    //function that calls create_daily_diary api request
    addDiaryAPI = () => {

        let data = JSON.stringify(this.state.postData);

        // alert(data);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + '/api/create_daily_diary', {
                credentials: "include",
                method: "POST",
                headers,
                body: data,
            }).then(res => {

                if(res.status === 200){
                    return res.json();
                }
                else{
                    alert("Something went wrong - HTTP: " + res.status);
                    this.setState({ loadingAddEntry : false });
                }

            }).then(result => {

                if(result !== undefined){
                    //Upload attachments if create was successful
                    this.uploadFiles(result.id);
                }

            }).catch(error => alert(error));
        // }, 2000)

    };

    //function that calls upload_files api request to add attachments to diary entry
    uploadFiles = (id) => {

        if(this.state.attachments.length !== 0) {

            let data = new FormData();
            data.append('id', id);
            data.append('type', "dailyDiary");

            this.state.attachments.forEach(object => {
                data.append('file', object.file);
            });


            // setTimeout(() => {
            fetch(getApiRoot() + '/api/upload_files', {
                credentials: "include",
                method: "POST",
                body: data,
            }).then(res => {

                this.setState({ loadingAddEntry : false });

                if (res.status === 200) {

                    let path = "/app/taskView";

                    this.props.history.push({
                        pathname: path
                    });

                }
                else {
                    alert("Something went wrong - HTTP: " + res.status);


                }

            }).catch(error => alert(error));
            // }, 2000);
        }
        else{

            this.setState({ loadingAddEntry : false });

            let path = "/app/taskView";

            this.props.history.push({
                pathname: path
            });

        }

    };

    //event that fires before component renders
    componentDidMount() {
        this.getAllProjects();
    }


    render(){

        const { classes } = this.props;
        const { error, isLoaded, loadingAddEntry } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Shows loading wheel until initial API returns data or if an error occurs
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={8} className={classes.alignTable}>
                        <Card className={classes.cardAlignment} style={{paddingTop: 15}}>
                            <CardBody>
                                <form autoComplete={"false"}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <GridContainer>

                                                {/* Project */}
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="diaryProjectInput">
                                                            Project
                                                        </InputLabel>
                                                        <Select
                                                            displayEmpty
                                                            native
                                                            value={this.state.project}
                                                            onChange={(event) => this.onChange(event)}
                                                            inputProps={{
                                                                id: 'diaryProjectInput',
                                                            }}
                                                        >
                                                            <option disabled value={0}>Choose project</option>
                                                            {this.state.allProjects.map((item, index) => (
                                                                <option key={index}
                                                                        value={item.project.id}>{item.project.title}</option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>

                                                {/* Contract */}
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="diaryContractInput">
                                                            Contract
                                                        </InputLabel>
                                                        <Select
                                                            displayEmpty
                                                            native
                                                            value={this.state.postData.contract}
                                                            onChange={(event) => this.onChange(event)}
                                                            inputProps={{
                                                                id: 'diaryContractInput'
                                                            }}
                                                        >
                                                            <option disabled value={0}>Choose contract</option>
                                                            {this.state.currentChosenProject !== "" ? this.state.allProjects[this.state.currentChosenProject].contracts.map((item, index) => (
                                                                <option key={index}
                                                                        value={item.id}>{item.number}</option>
                                                            )) : ""}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>

                                                {/* Date */}
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <CustomInput
                                                        id="diaryDateInput"
                                                        labelText="Date"
                                                        labelProps={{
                                                            shrink: true
                                                        }}
                                                        success={this.state.diaryDateInputState === "success"}
                                                        error={this.state.diaryDateInputState === "error"}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            style: {marginTop: -9},
                                                            value: this.state.postData.diaryDate,
                                                            // onChange={this.handleInputChange}
                                                            onChange: event =>
                                                                this.onChange(event),
                                                            type: "date",
                                                            endAdornment:
                                                                this.state.diaryDateInputState === "error" ? (
                                                                    <InputAdornment position="end">
                                                                        <Close className={classes.danger}/>
                                                                    </InputAdornment>
                                                                ) : undefined
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>

                                        </GridItem>
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <GridContainer>

                                                {/* Site Manager */}
                                                <GridItem xs={12} sm={12} lg={12}>
                                                    <CustomInput
                                                        id="diarySiteManagerInput"
                                                        labelText="Site Manager"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.riskDescription,
                                                            onChange: event =>
                                                                this.onChange(event),
                                                            type: "text",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        {/* Rain */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <CustomInput
                                                        id="diaryRainInput"
                                                        labelText="Rain (mm)"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.rain,
                                                            onChange: event =>
                                                                this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>

                                                {/* Max Temperature */}
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <CustomInput
                                                        id="diaryMaxTemperatureInput"
                                                        labelText="Max Temperature (&deg; C)"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.maxTemperature,
                                                            onChange: event =>
                                                                this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>

                                                {/* Min Temperature */}
                                                <GridItem xs={12} sm={4} lg={4}>
                                                    <CustomInput
                                                        id="diaryMinTemperatureInput"
                                                        labelText="Min Temperature (&deg; C)"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            style: {marginBottom: 15},
                                                            value: this.state.postData.minTemperature,
                                                            onChange: event =>
                                                                this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>


                                        {/* People on site */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>People on site</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Our people */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 15, marginBottom: 15}}>
                                            <p>Our People</p>
                                        </GridItem>

                                        {/* Senior Management */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 12}}>Senior Management:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diarySeniorManagementInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.seniorManagement,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        {/* Management */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 12}}>Management:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diaryManagementInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.management,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        {/* Supervisors */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 12}}>Supervisors:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diarySupervisorsInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.supervisors,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>


                                        {/* Operators */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 12}}>Operators:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diaryOperatorsInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.operators,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>


                                        {/* Labour */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    <p style={{fontSize: 12}}>Labour</p>
                                                </GridItem>

                                                {/* Skilled Labour */}
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 10, marginLeft: 10}}>Skilled:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diarySkilledLabourInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.skilledLabour,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>

                                                {/* Semi-Skilled Labour */}
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 10, marginLeft: 10}}>Semi-Skilled:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diarySemiSkilledLabourInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.semiSkilledLabour,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>

                                                {/* Unskilled Labour */}
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 10, marginLeft: 10}}>Unskilled:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diaryUnskilledLabourInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.unskilledLabour,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>

                                                {/* Disabled Labour */}
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <p style={{fontSize: 10, marginLeft: 10}}>Disabled:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="diaryDisabledLabourInput"
                                                        formControlProps={{
                                                            style:{paddingTop: 0}
                                                        }}
                                                        inputProps={{
                                                            value: this.state.postData.peopleOnSite.ourPeople.disabledPeople,
                                                            onChange: event => this.onChange(event),
                                                            type: "number",
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        {/* Sub Contractors */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 0}}>
                                            <hr/>
                                            <p>Sub <span style={{fontStyle: "italic"}}>Contractors</span></p>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>

                                                {/* Current Sub Contractors */}
                                                {this.state.postData.peopleOnSite.subContractor.length ?
                                                    <GridItem xs={12} lg={12}>
                                                        <List>
                                                            {
                                                                this.state.postData.peopleOnSite.subContractor.map((item, index) =>

                                                                    <ListItem
                                                                        style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                        key={index}>
                                                                        <GridContainer>
                                                                            <GridItem xs={4} lg={4}>
                                                                                <p style={{margin: 0}}>{item.subContractorName}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={4} lg={4}>
                                                                                <p style={{margin: 0}}>Discipline - {item.discipline}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={4} lg={4}>
                                                                                <p style={{margin: 0}}># of people - {item.peopleOnSiteSubContractor}</p>
                                                                            </GridItem>
                                                                        </GridContainer>

                                                                        {/* Remove item from list */}
                                                                        <ListItemSecondaryAction>
                                                                            <Close className={classes.danger}
                                                                                   style={{cursor: "pointer", marginTop: 0}}
                                                                                   onClick={() => this.removeFromList(index, "subContractors")}/>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>)
                                                            }
                                                        </List>
                                                    </GridItem>
                                                    : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                                    </GridItem>
                                                }

                                                {/* New Sub contractor fields */}
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    <List>
                                                        <ListItem style={{paddingLeft: 0}}>
                                                            <GridItem xs={12} sm={6} md={6} lg={6} style={{marginLeft: -15}}>
                                                                <CustomInput
                                                                    id="diaryNewSubContractorDesInput"
                                                                    labelText={<p>Sub <span style={{fontStyle: "italic"}}>Contractor</span></p>}
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.newSubContractor,
                                                                        onChange: event =>
                                                                            this.onChange(event),
                                                                        type: "text",
                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={6} lg={4}>
                                                                <CustomInput
                                                                    id="diaryNewSubContractorDisciplineInput"
                                                                    labelText="Discipline"
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.newSubContractorDiscipline,
                                                                        onChange: event =>
                                                                            this.onChange(event),
                                                                        type: "text",

                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={6} sm={2} md={2} lg={2}>
                                                                <CustomInput
                                                                    id="diaryNewSubContractorNumOfPeopleInput"
                                                                    labelText="# People"
                                                                    labelProps={{}}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.newSubContractorNumPeople,
                                                                        onChange: event =>
                                                                            this.onChange(event),
                                                                        type: "number",
                                                                    }}
                                                                />
                                                            </GridItem>

                                                            {/* Add new sub contractor */}
                                                            <ListItemSecondaryAction>
                                                                <Add style={{cursor: "pointer", marginTop: 10}} onClick={() => this.addToList("subContractors")}/>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </List>
                                                </GridItem>

                                            </GridContainer>
                                            <hr/>
                                        </GridItem>

                                        {/* Other people on site */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 0}}>
                                            <p>Other people on site</p>
                                        </GridItem>

                                        {/*<GridItem xs={12} sm={12} md={12} lg={12}>*/}
                                        {/*    <CustomInput*/}
                                        {/*        id="diaryOtherPeopleInput"*/}
                                        {/*        labelProps={{}}*/}
                                        {/*        formControlProps={{*/}
                                        {/*            fullWidth: true,*/}
                                        {/*            style:{paddingTop: 0}*/}
                                        {/*        }}*/}
                                        {/*        inputProps={{*/}
                                        {/*            value: this.state.postData.peopleOnSite.otherPeople,*/}
                                        {/*            onChange: event => this.onChange(event),*/}
                                        {/*            type: "number",*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</GridItem>*/}

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <GridContainer>

                                                {/* Current Other People on Site */}
                                                {this.state.postData.peopleOnSite.otherPeople.length ?
                                                    <GridItem xs={12} lg={12}>
                                                        <List>
                                                            {
                                                                this.state.postData.peopleOnSite.otherPeople.map((item, index) =>

                                                                    <ListItem
                                                                        style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                        key={index}>
                                                                        <GridContainer>
                                                                            <GridItem xs={6} lg={6}>
                                                                                <p style={{margin: 0}}>{item.description}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={6} lg={6}>
                                                                                <p style={{margin: 0}}># of people - {item.noOfPeople}</p>
                                                                            </GridItem>
                                                                        </GridContainer>

                                                                        {/* Remove item from list */}
                                                                        <ListItemSecondaryAction>
                                                                            <Close className={classes.danger}
                                                                                   style={{cursor: "pointer", marginTop: 0}}
                                                                                   onClick={() => this.removeFromList(index, "otherPeople")}/>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>)
                                                            }
                                                        </List>
                                                    </GridItem>
                                                    : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                                    </GridItem>
                                                }

                                                {/* New Other People On Site fields   */}
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    <List>
                                                        <ListItem style={{paddingLeft: 0}}>
                                                            <GridItem xs={8} sm={8} md={8} lg={8} style={{marginLeft: -15}}>
                                                                <CustomInput
                                                                    id="diaryNewOtherPeopleDesInput"
                                                                    labelText= "Description"
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.newOtherPeopleOnSiteDescription,
                                                                        onChange: event =>
                                                                            this.onChange(event),
                                                                        type: "text",
                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                                                <CustomInput
                                                                    id="diaryNewOtherPeopleNumOfPeopleInput"
                                                                    labelText="# People"
                                                                    labelProps={{}}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.newOtherPeopleNumPeople,
                                                                        onChange: event =>
                                                                            this.onChange(event),
                                                                        type: "number",
                                                                    }}
                                                                />
                                                            </GridItem>

                                                            {/* Add new other people on site */}
                                                            <ListItemSecondaryAction>
                                                                <Add style={{cursor: "pointer", marginTop: 10}} onClick={() => this.addToList("otherPeople")}/>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </List>
                                                </GridItem>

                                            </GridContainer>
                                            {/*<hr/>*/}
                                        </GridItem>

                                        {/* Equipment on site */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Equipment on site</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Current equipment list */}
                                        {this.state.postData.equipmentOnSite.length ?
                                            <GridItem xs={12} lg={12}>
                                                <List>
                                                    {
                                                        this.state.postData.equipmentOnSite.map((item, index) =>

                                                            <ListItem
                                                                style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                key={index}>
                                                                <GridContainer>
                                                                    <GridItem xs={8} lg={8}>
                                                                        <p style={{margin: 0}}>{item.equipmentName}</p>
                                                                    </GridItem>
                                                                    <GridItem xs={4} lg={4}>
                                                                        <p style={{margin: 0}}>Working - {item.isWorking}</p>
                                                                    </GridItem>
                                                                </GridContainer>

                                                                {/* Remove item from list */}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                           style={{cursor: "pointer", marginTop: 0}}
                                                                           onClick={() => this.removeFromList(index, "equipment")}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                    }
                                                </List>
                                            </GridItem>
                                            : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                            </GridItem>
                                        }

                                        {/* New Equipment input fields */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <List>
                                                <ListItem style={{paddingLeft: 0}}>
                                                    <GridItem xs={12} sm={8} md={8} lg={8} style={{marginLeft: -15}}>
                                                        <CustomInput
                                                            id="diaryNewEquipmentInput"
                                                            labelText="Equipment"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newEquipment,
                                                                onChange: event =>
                                                                    this.onChange(event),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={6} lg={4}>
                                                        <FormControl fullWidth style={{marginTop: 0}}>
                                                            <InputLabel shrink htmlFor="diaryNewEquipmentWorkingInput">
                                                                Working
                                                            </InputLabel>
                                                            <Select
                                                                native
                                                                displayEmpty={true}
                                                                value={this.state.newEquipmentWorking}
                                                                onChange={event => this.onChange(event)}
                                                                inputProps={{
                                                                    id: 'diaryNewEquipmentWorkingInput',
                                                                    style: {fontSize: 14}
                                                                }}
                                                            >
                                                                <option value={"No"}>No</option>
                                                                <option value={"Yes"}>Yes</option>
                                                            </Select>
                                                        </FormControl>
                                                    </GridItem>

                                                    {/* Add new equipment entry */}
                                                    <ListItemSecondaryAction>
                                                        <Add style={{cursor: "pointer", marginTop: 10}} onClick={() => this.addToList("equipment")}/>
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            </List>
                                        </GridItem>

                                        {/* Plant */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Plant & Material</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Current plant & material list */}
                                        {this.state.postData.plantDelivered.length ?
                                            <GridItem xs={12} lg={12}>
                                                <List>
                                                    {
                                                        this.state.postData.plantDelivered.map((item, index) =>

                                                            <ListItem
                                                                style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                key={index}>
                                                                <GridContainer>
                                                                    <GridItem xs={8} lg={8}>
                                                                        <p style={{margin: 0}}>{item.description}</p>
                                                                    </GridItem>
                                                                    <GridItem xs={4} lg={4}>
                                                                        <p style={{margin: 0}}># delivered- {item.numberDelivered}</p>
                                                                    </GridItem>
                                                                </GridContainer>

                                                                {/* Remove item from list */}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                           style={{cursor: "pointer", marginTop: 0}}
                                                                           onClick={() => this.removeFromList(index, "plant")}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                    }
                                                </List>
                                            </GridItem>
                                            : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                            </GridItem>
                                        }

                                        {/* New plant & material input fields */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <List>
                                                <ListItem style={{paddingLeft: 0}}>
                                                    <GridItem xs={12} sm={8} md={8} lg={8} style={{marginLeft: -15}}>
                                                        <CustomInput
                                                            id="diaryNewPlantDescriptionInput"
                                                            labelText="Description"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newPlantDeliveredDescription,
                                                                onChange: event =>
                                                                    this.onChange(event),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={4} sm={4} md={4} lg={4}>
                                                        <CustomInput
                                                            id="diaryNewPlantNumberDeliveredInput"
                                                            labelText="# Delivered"
                                                            labelProps={{}}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newPlantDeliveredNumber,
                                                                onChange: event =>
                                                                    this.onChange(event),
                                                                type: "number",
                                                            }}
                                                        />
                                                    </GridItem>

                                                    {/* Add new equipment entry */}
                                                    <ListItemSecondaryAction>
                                                        <Add style={{cursor: "pointer", marginTop: 10}} onClick={() => this.addToList("plant")}/>
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            </List>
                                        </GridItem>

                                        {/*/!* Plant description *!/*/}
                                        {/*<GridItem xs={12} sm={12} lg={12}>*/}
                                        {/*    <CustomInput*/}
                                        {/*        id="diaryDeliveredDescription"*/}
                                        {/*        labelText="Description"*/}
                                        {/*        formControlProps={{*/}
                                        {/*            fullWidth: true*/}
                                        {/*        }}*/}
                                        {/*        labelProps={{*/}
                                        {/*            shrink: true*/}
                                        {/*        }}*/}
                                        {/*        inputProps={{*/}
                                        {/*            multiline: true,*/}
                                        {/*            rows: 5,*/}
                                        {/*            style: {marginBottom: 15},*/}
                                        {/*            value: this.state.postData.deliveredDescription,*/}
                                        {/*            onChange: (event) => this.onChange(event),*/}
                                        {/*            type: "text",*/}
                                        {/*        }}*/}
                                        {/*    />*/}

                                        {/*/!* Number of plants delivered today *!/*/}
                                        {/*</GridItem>*/}
                                        {/*        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                                        {/*            <CustomInput*/}
                                        {/*                id="diaryDeliveredNumber"*/}
                                        {/*                labelText="# Delivered Today"*/}
                                        {/*                labelProps={{}}*/}
                                        {/*                formControlProps={{*/}
                                        {/*                    fullWidth: true,*/}
                                        {/*                }}*/}
                                        {/*                inputProps={{*/}
                                        {/*                    value: this.state.postData.deliveredNumber,*/}
                                        {/*                    onChange: event => this.onChange(event),*/}
                                        {/*                    type: "number",*/}
                                        {/*                }}*/}
                                        {/*            />*/}
                                        {/*        </GridItem>*/}
                                        {/*    <hr/>*/}


                                        {/* Activities */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Activities</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 30}}>
                                            <p>Program Activities Started Today</p>
                                        </GridItem>

                                        {/* Current program activities started today list */}
                                        {this.state.postData.programActivities.startedToday.length ?
                                            <GridItem xs={12} lg={12}>
                                                <List>
                                                    {
                                                        this.state.postData.programActivities.startedToday.map((item, index) =>

                                                            <ListItem
                                                                style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                key={index}>
                                                                <GridContainer>
                                                                    <GridItem xs={8} lg={8}>
                                                                        <p style={{margin: 0}}>{item.programActivityDesc}</p>
                                                                    </GridItem>
                                                                    <GridItem xs={4} lg={4}>
                                                                        <p style={{margin: 0}}>Activity #{item.programActivityNumber}</p>
                                                                    </GridItem>
                                                                </GridContainer>

                                                                {/* Remove item from list */}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                           style={{cursor: "pointer", marginTop: 0}}
                                                                           onClick={() => this.removeFromList(index, "activities")}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                    }
                                                </List>
                                            </GridItem>
                                            : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                            </GridItem>
                                        }

                                        {/* New program activity started today input fields*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <List>
                                                <ListItem style={{paddingLeft: 0}}>
                                                    <GridItem xs={12} sm={8} md={8} lg={8} style={{marginLeft: -15}}>
                                                        <CustomInput
                                                            id="diaryNewActivityInput"
                                                            labelText="Activity"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newActivity,
                                                                onChange: event =>
                                                                    this.onChange(event),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={6} lg={4}>
                                                        <CustomInput
                                                            id="diaryNewActivityNumberInput"
                                                            labelText="Activity #"
                                                            labelProps={{}}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newActivityNumber,
                                                                onChange: event => this.onChange(event),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>

                                                    {/* Add new program activity started today entry */}
                                                    <ListItemSecondaryAction>
                                                        <Add style={{cursor: "pointer", marginTop: 10}}  onClick={() => this.addToList("activities")}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                            <hr/>
                                        </GridItem>

                                        {/* Progress On Site */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryProgressOnSiteInput"
                                                labelText="Progress On Site"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    value: this.state.postData.programActivities.programActivityProgressOnSite,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                            <hr/>
                                        </GridItem>

                                        {/* Program Activities Completed */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryActivitiesCompleted"
                                                labelText="Program Activities Completed"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    value: this.state.postData.programActivities.programActivityCompleted,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                            <hr/>
                                        </GridItem>

                                        {/* Delays/Disruptions/Variations/Disagreements */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryDelaysInput"
                                                labelText="Delays/Disruptions/Variations/Disagreements"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    value: this.state.postData.programActivities.delayDesc,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/* Safety, Health, Environment and Quality Assurance */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Safety, Health, Environment and Quality Assurance</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Safety, Health */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 15}}>
                                            <p>Safety & Health</p>
                                        </GridItem>

                                        {/* Near Misses/Incidents/Accidents/Site Safety input */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryHealthSafetyIncidentsDescInput"
                                                labelText="Near Misses/Incidents/Accidents/Site Safety"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 0},
                                                    value: this.state.postData.healthSafetyIncidentsDesc,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/* # Health and Safety Incidents input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                id="diaryNumHealthSafetyIncidentsInput"
                                                labelText="# Health and Safety Incidents"
                                                labelProps={{}}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.numHealthSafetyIncidents,
                                                    onChange: event => this.onChange(event),
                                                    type: "number",
                                                }}
                                            />
                                            <hr/>
                                        </GridItem>

                                        {/* Environment */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 0}}>
                                            <p>Environment</p>
                                        </GridItem>

                                        {/* Environment description*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryEnvironmentalIncidentsDescInput"
                                                labelText="Description"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 0},
                                                    value: this.state.postData.environmentalIncidentsDesc,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/* Number of environmental incidents */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                id="diaryNumEnvironmentalIncidentsInput"
                                                labelText="# Environmental Incidents"
                                                labelProps={{}}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.numEnvironmentalIncidents,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "number",
                                                }}
                                            />
                                            <hr/>
                                        </GridItem>

                                        {/* Quality Assurance */}
                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 0}}>
                                            <p>Quality</p>
                                        </GridItem>

                                        {/* Quality description*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryQualityIncidentsDescInput"
                                                labelText="Description"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 0},
                                                    value: this.state.postData.qualityIncidentsDesc,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/* Number of quality incidents */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                id="diaryNumQualityIncidentsInput"
                                                labelText="# Quality Incidents"
                                                success={this.state.costImpactValueEditState === "success"}
                                                error={this.state.costImpactValueEditState === "error"}
                                                labelProps={{}}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.numQualityIncidents,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "number",
                                                }}
                                            />

                                        </GridItem>

                                        {/* Attachments */}

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "50px 0 10px 0"}}/>
                                            <GridContainer>
                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                    <p style={{marginBottom: 0}}><strong>Attachments</strong></p>
                                                </GridItem>
                                                {/* Add Attachment button */}
                                                <GridItem xs={4} sm={4} lg={4}>
                                                    <Add style={{cursor: "pointer", fontSize: 16, float: "right", marginTop: 3}}
                                                         onClick={() => {
                                                             this.setState({
                                                                 openAddAttachmentDialog: true
                                                             });
                                                         }}/>
                                                </GridItem>
                                            </GridContainer>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Current Attachments list */}
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.attachments.length !== 0 ? this.state.attachments.map((item, index) =>
                                                            <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                      key={index}>
                                                                {item.fileName}
                                                                {/* Remove item from list */}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                           style={{cursor: "pointer", fontSize: 16, marginTop: 5}}
                                                                           onClick={() => this.removeFromList(index, "attachments")}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                        : "No Attachments"
                                                }
                                            </List>
                                        </GridItem>

                                        {/* General Comments */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "50px 0 10px 0"}}/>
                                            <p><strong>General</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="diaryGeneralCommentsInput"
                                                labelText="Comments"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    value: this.state.postData.generalComments,
                                                    onChange: (event) => this.onChange(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/* Others to be invited Input */}
                                        {/*<GridItem xs={12} sm={12} md={12} lg={12}>*/}
                                        {/*    <hr style={{margin: "20px 0 10px 0"}}/>*/}
                                        {/*    <p><strong>Others To Be Invited</strong></p>*/}
                                        {/*    <hr style={{margin: "10px 0 10px 0"}}/>*/}
                                        {/*</GridItem>*/}
                                        {/*<GridItem xs={12} sm={12} lg={12}>*/}

                                        {/*    /!* Current Others To Be Invited *!/*/}
                                        {/*    <div style={{marginBottom: 0}}>*/}
                                        {/*        <List>*/}
                                        {/*            {*/}
                                        {/*                this.state.postData.othersToBeInvited === undefined || this.state.postData.othersToBeInvited.length === 0 ?*/}
                                        {/*                    <p>None</p> : this.state.postData.othersToBeInvited.map((item, index) =>*/}
                                        {/*                        <ListItem*/}
                                        {/*                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}*/}
                                        {/*                            key={index}>*/}
                                        {/*                            {item.email}*/}
                                        {/*                            <ListItemSecondaryAction>*/}
                                        {/*                                <Close className={classes.danger} style={{*/}
                                        {/*                                    cursor: "pointer",*/}
                                        {/*                                    fontSize: 16,*/}
                                        {/*                                    marginTop: 5*/}
                                        {/*                                }}*/}
                                        {/*                                       onClick={() => this.removeFromList(index, "otherPeopleToBeInvited")}/>*/}
                                        {/*                            </ListItemSecondaryAction>*/}
                                        {/*                        </ListItem>)*/}
                                        {/*            }*/}

                                        {/*        </List>*/}

                                        {/*        /!* New Other To Be Invited Input *!/*/}
                                        {/*        <CustomInput*/}
                                        {/*            id="newOtherInput"*/}
                                        {/*            labelText="Add Email"*/}
                                        {/*            formControlProps={{*/}
                                        {/*                fullWidth: true*/}
                                        {/*            }}*/}
                                        {/*            inputProps={{*/}
                                        {/*                value: this.state.newOtherEmail,*/}
                                        {/*                onChange: (event) =>*/}
                                        {/*                    this.setState({newOtherEmail: event.target.value}),*/}
                                        {/*                type: "email",*/}
                                        {/*                endAdornment: (*/}
                                        {/*                    <Add onClick={() => this.addToList("otherPeopleToBeInvited")}*/}
                                        {/*                         style={{*/}
                                        {/*                             cursor: "pointer",*/}
                                        {/*                             fontSize: 16,*/}
                                        {/*                             marginTop: 5*/}
                                        {/*                         }}/>)*/}
                                        {/*            }}*/}
                                        {/*        />*/}

                                        {/*    </div>*/}
                                        {/*</GridItem>*/}



                                        <GridItem xs={12} sm={12} lg={12}>
                                            {/*Add diary entry button*/}
                                            <div style={{position: "relative", float: "right"}}>
                                                <Button type={"submit"} style={{float: "right"}}
                                                        onClick={(event) => this.onAddEntry(event)}
                                                        color="primary" disabled={loadingAddEntry}>
                                                    Add
                                                </Button>
                                                {loadingAddEntry && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openAddAttachmentDialog}
                        onClose={() => {
                            this.setState({
                                openAddAttachmentDialog: false, currentFile: {}, currentFileName: "No file chosen"
                            });
                        }}
                        aria-labelledby="addAttachmentDialog"
                    >
                        <DialogTitle id="addAttachmentDialog">Add Attachment</DialogTitle>
                        <DialogContent>

                            {/* File Upload */}
                            <GridContainer justify={"center"}>
                                <GridItem xs={12} sm={12} md={12} lg={12}
                                          style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                    <input
                                        accept="*"
                                        className={classes.input}
                                        id="uploadAttachmentInput"
                                        type="file"
                                        onChange={(event) => this.changeAttachment(event)}
                                    />
                                    <label htmlFor="uploadAttachmentInput">
                                        <Button size={"sm"} variant="contained" component="span"
                                                className={classes.button}>
                                            Choose File
                                        </Button>
                                    </label>
                                </GridItem>

                                {/* Current Attachment Name */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{
                                        fontSize: 12,
                                        textAlign: "center",
                                        marginTop: 10
                                    }}>{this.state.currentFileName}</p>
                                </GridItem>

                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({
                                    openAddAttachmentDialog: false, currentFile: {}, currentFileName: "No file chosen"
                                });
                            }} color="info">
                                Close
                            </Button>
                            <div style={{position: "relative"}}>
                                <Button onClick={() => this.addToList("attachments")}
                                        color="primary">
                                    Add
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                </GridContainer>
            );
        }
    }

} export default withStyles(styles)(AddDiaryEntry);
