import React from "react";
import Tabs from "../../../components/CustomTabs/CustomTabs.jsx";

import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import communicationsStyles from "../../../assets/styles/communicationsStyles";
import { withStyles } from '@material-ui/core/styles';
import { notificationClauses } from '../../../variables/notificationClauses';
import { submissionClauses } from '../../../variables/submissionClauses';
import Submissions from './CommunicationTabs/Submissions';
import Notifications from './CommunicationTabs/Notifications';
import Issuance from './CommunicationTabs/Issuance';
import Instruction from './CommunicationTabs/Instruction';
import Request from './CommunicationTabs/Request';
import Assessment from './CommunicationTabs/Assessment';
import * as actionCreators from "../../../store/actions/tab";
import {connect} from "react-redux";

class CommunicationMaster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submissionIndicator : false,
            notificationIndicator : false,
            assessmentIndicator : false,
            instructionIndicator : false,
            issueIndicator : false,
            requestIndicator : false,
            stateLoaded : false,
        }

    };

    componentDidMount() {
        if(!this.props.location.state){
            this.setState({
                stateLoaded : true
            });
        }
    }

    componentWillUnmount() {
        this.props.onSetSelectedTab(0);
    }

    getClauseData = (type) => {
        switch(type) {
            case "submission" :
                return submissionClauses;
            case "notification" :
                return notificationClauses;
            default : 
                return null;
        }
    }

    render() {
        const { stateLoaded } = this.state;
        return (
            //<div ref={this.testRef} style={{overflow: "auto",}}>
            stateLoaded && <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Tabs
                        plainTabs={true}
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: "Submission",
                                tabContent: (
                                    <Submissions type={"submission"} clauses={this.getClauseData("submission")} />
                                ),
                            },
                            {
                                tabName: (
                                    "Notification"
                                ),
                                tabContent: (
                                    <Notifications type={"notification"} clauses={this.getClauseData("notification")} />
                                ),
                            },
                            {
                                tabName: (
                                    "Issuance"
                                ),
                                tabContent: (
                                    <Issuance type={"issuance"} clauses={this.getClauseData("issuance")} />
                                )
                            },
                            {
                                tabName: (
                                    "Instruction"
                                ),
                                tabContent: (
                                    <Instruction type={"instruction"} clauses={this.getClauseData("instruction")} />
                                )
                            },
                            {
                                tabName: (
                                    "Assessment"
                                ),
                                tabContent: (
                                    <Request type={"assessment"} clauses={this.getClauseData("assessment")} />
                                )
                            },
                            {
                                tabName: (
                                    "Request"
                                ),
                                tabContent: (
                                    <Assessment type={"request"} clauses={this.getClauseData("request")} />
                                )
                            },

                        ]}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        value: state.tabReducer.value
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(communicationsStyles)(CommunicationMaster));
