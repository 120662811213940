import React from 'react';
import * as actionCreators from '../../store/actions/index';
import { connect } from 'react-redux';
import Snackbars from "../Snackbar/Snackbar";

const ErrorNotification = (props) => {
    return (
        props.error && <Snackbars
        place="br"
        color={"danger"}
        message={props.error.toString()}
        open={props.isOpen}
        close
        closeNotification={props.onHideError}
    />
    );
};

const mapStateToProps = (state) => {
    return {
        error : state.errorReducer.error,
        isOpen : state.errorReducer.isOpen,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onHideError : () => dispatch(actionCreators.hideError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotification);
