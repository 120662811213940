import * as actionTypes from './actionTypes';
import axiosInstance from "../../axiosInstance";

//START : Get Permissions

const getPermissionsStart = () => {
  return {
    type: actionTypes.GET_PERMISSIONS_START
  };
};

const getPermissionsFail = (error) => {
  return {
    type: actionTypes.GET_PERMISSIONS_FAIL,
    data: null,
    error: error
  };
};

const getPermissionsSuccess = (permissions) => {
  return {
    type: actionTypes.GET_PERMISSIONS_SUCCESS,
    data: permissions,
    error: null
  };
};

export const getPermissions = () => {
  return (dispatch) => {
    dispatch(getPermissionsStart());
    return axiosInstance.get('/api/permissions')
      .then(response => {
        const fetchedPermissions = [];
        for (let key in response.data) {
          fetchedPermissions.push({
            ...response.data[key]
          });
        }
        dispatch(getPermissionsSuccess(fetchedPermissions));
      })
      .catch(error => {
        dispatch(getPermissionsFail(error));
        throw error;
      });
  };
};

const shouldGetPermissions = (state) => {
  const user = state.userReducer;
  if (user.permissions.length === 0) {
    return true;
  }
  else {
    return false;
  }
};

export const getPermissionsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldGetPermissions(getState())) {
      return dispatch(getPermissions());
    }
  }
};

//END : Get Permissions

//START : Get Role

const getRoleStart = () => {
  return {
    type: actionTypes.GET_ROLE_START
  };
};

const getRoleFail = (error) => {
  return {
    type: actionTypes.GET_ROLE_FAIL,
    data: null,
    error: error
  };
};

const getRoleSuccess = (role) => {
  return {
    type: actionTypes.GET_ROLE_SUCCESS,
    data: role,
    error: null
  };
};

export const getRole = () => {
  return (dispatch) => {
    dispatch(getRoleStart());
    return axiosInstance.get('/api/role')
      .then(response => {
        dispatch(getRoleSuccess(response.data));
      })
      .catch(error => {
        dispatch(getRoleFail(error));
        throw error;
      });
  };
};

//END : Get Role

//START : User Logout
export const userLogout = () => {
  return {
    type: actionTypes.USER_LOGOUT
  };
};
//End : User Logout