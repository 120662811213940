import * as actionTypes from './actionTypes';

export const setSelectedTab = (value) => {
    return {
        type: actionTypes.SET_SELECTED_TAB,
        value: value
    };
};


