const clause313Radio = [
  {
    number: 1,
    reason: "the Contractor's plans which it shows are not practicable",
    choice: "Yes",
    comments: ""
  },
  {
    number: 2,
    reason: "it does not show the information which this contract requires",
    choice: "Yes",
    comments: ""
  },
  {
    number: 3,
    reason: "it does not represent the Contractor's plans realistically",
    choice: "Yes",
    comments: ""
  },
  {
    number: 4,
    reason: "it does not comply with the Works Information",
    choice: "Yes",
    comments: ""
  }
];

const clause321Radio = [
  {
    number: 1,
    reason: "the actual progress achieved on each operation and its effect upon the timing of the remaining work",
    choice: "Yes",
    comments: ""
  },
  {
    number: 2,
    reason: "the effects of implemented compensation events",
    choice: "Yes",
    comments: ""
  },
  {
    number: 3,
    reason: "how the Contractor plans to deal with any delays and to correct notified Defects and",
    choice: "Yes",
    comments: ""
  },
  {
    number: 4,
    reason: "any other changes which the Contractor proposes to make to the Accepted Programme",
    choice: "Yes",
    comments: ""
  }
];

const clause312Radio = [
  {
    number: 1,
    reason: "the starting date, access dates, Key Dates and Completion Date",
    choice: "Yes",
    comments: ""
  },
  {
    number: 2,
    reason: "planned Completion",
    choice: "Yes",
    comments: ""
  },
  {
    number: 3,
    reason:
      "the order and timing of the operations which the Contractor plans to do in order to Provide the Works",
    choice: "Yes",
    comments: ""
  },
  {
    number: 4,
    reason:
      "the order and timing of the work of the Employer and Others as last agreed with them by the Contractor or, if not so agreed, as stated in the Works Information",
    choice: "Yes",
    comments: ""
  },
  {
    number: 5,
    reason:
      "the dates when the Contractor plans to meet each Condition stated for the Key Dates and to complete other work needed to allow the Employer and Others to do their work",
    choice: "Yes",
    comments: ""
  },
  {
    number: 6,
    reason: "provisions for float",
    choice: "Yes",
    comments: ""
  },
  {
    number: 7,
    reason: "provisions for time risk allowances",
    choice: "Yes",
    comments: ""
  },
  {
    number: 8,
    reason: "provisions for health and safety requirements",
    choice: "Yes",
    comments: ""
  },
  {
    number: 9,
    reason: "the procedures set out in this contract",
    choice: "Yes",
    comments: ""
  },
  {
    number: 10,
    reason:
      "the dates when, in order to Provide the Works in accordance with his programme, the Contractor will need access to a part of the Site if later than its access date",
    choice: "Yes",
    comments: ""
  },
  {
    number: 11,
    reason:
      "the dates when, in order to Provide the Works in accordance with his programme, the Contractor will need acceptances",
    choice: "Yes",
    comments: ""
  },
  {
    number: 12,
    reason:
      "the dates when, in order to Provide the Works in accordance with his programme, the Contractor will need Plant and Materials and other things to be provided by the Employer and",
    choice: "Yes",
    comments: ""
  },
  {
    number: 13,
    reason:
      "the dates when, in order to Provide the Works in accordance with his programme, the Contractor will need information from Others",
    choice: "Yes",
    comments: ""
  },
  {
    number: 14,
    reason:
      "for each operation, a statement of how the Contractor plans to do the work identifying the principal Equipment and other resources which he plans to use and",
    choice: "Yes",
    comments: ""
  },
  {
    number: 15,
    reason:
      "other information which the Works Information requires the Contractor to show on a programme submitted for acceptance",
    choice: "Yes",
    comments: ""
  }
];

const clause322Radio = [
    {
      number: 1,
      reason:
        "within the period for reply after the Project Manager has instructed him to",
      choice: "Yes",
      comments: ""
    },
    {
      number: 2,
      reason: "when the Contractor chooses to and, in any case",
      choice: "Yes",
      comments: ""
    },
    {
      number: 3,
      reason:
        "at no longer interval than the interval stated in the Contract Data from the starting date until Completion of the whole of the works",
      choice: "Yes",
      comments: ""
    }
];

export const submissionClauses = {
  apiUrls: {
    preview: "/preview_submission",
    previewRevision: "/preview_submission_revision",
    previewRevisionResponse: "/preview_submission_revision_response",
    create: "/create_submission",
    createRevision: "/create_submission_revision",
    createRevisionResponse: "/create_submission_revision_response",
    send: "/send_submission",
    sendRevision: "/send_submission_revision",
    sendRevisionResponse: "/send_submission_revision_response",
    sendDownload: "/send_submission_and_download",
    sendDownloadRevision: "/send_submission_revision_and_download",
    sendDownloadRevisionResponse: "/send_submission_revision_response_and_download",
    register: "/submission_by_clause",
    details: "/submission",
    attachments: "/upload_files",
    projectsAndContracts: "/projects_contracts_list",
    submissionTos : "/submission_tos",
    deleteSubmissionTo : "/delete_submission_to"
  },
  clauseData: [
    {
      id : 1,
      clauseNumber: "21.2",
      clauseTitle: "Design for Acceptance",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Affected Activities",
          field: "affectedActivities",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 2,
      clauseNumber: "24.1",
      clauseTitle: "Change of Key Personnel",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 3,
      clauseNumber: "26.2",
      clauseTitle: "Submit name of Proposed Subcontractor",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 4,
      clauseNumber: "26.3",
      clauseTitle: "Submit Proposed Subcontractor Terms",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 5,
      clauseNumber: "31.1",
      clauseTitle: "Submit 1st Programme for Acceptance",
      hasRevisions: false,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          review: "Review",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header: "Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        reviewFields: [
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          },
          {
            labelText : "The Contractor shows on each programme which he submits for acceptance",
            clauseNumber : 31.2,
            field: "clause_31_2_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause312Radio
          },
          {
            labelText : "The Contractor shows on each revised programme which he submits for acceptance",
            clauseNumber : 32.1,
            field: "clause_32_1_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause321Radio
          },
          {
            labelText : "The Project Manager is required to within two weeks of the Contractor submitting a programme to him for acceptance, to either accept the programme or notifies the Contractor of his reasons for not accepting it",
            subText : "My reason for not accepting the submitted programme is that",
            clauseNumber : 31.3,
            field: "clause_31_3_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause313Radio
          },
          {
            labelText : "The Project Manager herewith",
            field: "responses",
            required: true,
            type: "response",
            responseAction : {
              accept: "Accept",
              reject : "Reject"
            },
          },
        ]
      }
    },
    {
      id : 6,
      clauseNumber: "32.2",
      clauseTitle: "Submit Programme for Acceptance",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          review : "Review",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText : "The Contractor submits a revised programme to the Project Manager for acceptance",
          subText : "The Contractor' reason for submitting a revised programme is",
          clauseNumber : 32.2,
          field: "clause_32_2_reasons",
          required: false,
          type: "clauseRadio",
          objectTemplate: clause322Radio,
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        reviewFields: [
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          },
          {
            labelText : "The Contractor shows on each programme which he submits for acceptance",
            clauseNumber : 31.2,
            field: "clause_31_2_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause312Radio
          },
          {
            labelText : "The Contractor shows on each revised programme which he submits for acceptance",
            clauseNumber : 32.1,
            field: "clause_32_1_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause321Radio
          },
          {
            labelText : "The Project Manager is required to within two weeks of the Contractor submitting a programme to him for acceptance, to either accept the programme or notifies the Contractor of his reasons for not accepting it",
            subText : "My reason for not accepting the submitted programme is that",
            clauseNumber : 31.3,
            field: "clause_31_3_response",
            required: false,
            type: "clauseRadio",
            objectTemplate: clause313Radio
          },
          {
            labelText : "The Project Manager herewith",
            field: "responses",
            required: true,
            type: "response",
            responseAction : {
              accept: "Accept",
              reject : "Reject"
            },
          },
        ]
      }
    },
    {
      id : 7,
      clauseNumber: "44.2",
      clauseTitle: "Submit quote for Defect not Corrected",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 8,
      clauseNumber: "50.4",
      clauseTitle: "Application for Payment",
      // hasRevisions: true,
      displayData : {
        submit : {
          initial : "Submit",
          revision : "Submit new revision"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        }
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "submissionTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header: "Date Submitted",
            property: "dateCreated",
            visible: true
          },
        ]
      },
      response: null
    },
    // {
    //   clauseNumber: "7",
    //   clauseTitle: "Request to Publicise",
    //   hasRevisions: true,
    //   dataFields: [
    //     {
    //       type: "contract",
    //       field: "contract",
    //       revisionField: false
    //     },
    //     {
    //       labelText: "Title",
    //       field: "title",
    //       required: true,
    //       type: "text",
    //       revisionField: false
    //     },
    //     {
    //       labelText: "Description",
    //       field: "description",
    //       required: false,
    //       type: "textArea",
    //       revisionField: false
    //     },
    //     {
    //       labelText: "Attachments",
    //       field: "attachments",
    //       required: false,
    //       type: "attachment",
    //       revisionField: true
    //     },
    //     {
    //       labelText: "Send To",
    //       field: "submissionTos",
    //       required: false,
    //       type: "email",
    //       revisionField: true
    //     }
    //   ],
    //   register: {
    //     fields: [
    //       {
    //         header: "",
    //         property: "id",
    //         visible: false
    //       },
    //       {
    //         header: "",
    //         property: "type",
    //         visible: false
    //       },
    //       {
    //         header: "Title",
    //         property: "title",
    //         visible: true
    //       },
    //       {
    //         header : "Current revisions",
    //         property: "numberRevisions",
    //         visible: true
    //       },
    //       {
    //         header : "Revision Status",
    //         property: "revisionStatus",
    //         visible: true
    //       }
    //     ]
    //   },
    //   response: {
    //     rejectFields: [
    //       {
    //         labelText: "Comments",
    //         field: "comments",
    //         required: false,
    //         type: "textArea"
    //       },
    //       {
    //         labelText: "Reasons for rejection",
    //         field: "rejectReasons",
    //         required: true,
    //         type: "textArea"
    //       },
    //       {
    //         labelText: "Attachments",
    //         field: "attachments",
    //         required: false,
    //         type: "attachment"
    //       }
    //     ],
    //     acceptFields: [
    //       {
    //         labelText: "Comments",
    //         field: "comments",
    //         required: false,
    //         type: "textArea"
    //       }
    //     ]
    //   }
    // }
  ]
};