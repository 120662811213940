import React from 'react';
import * as PropTypes from "prop-types";
import { withRouter} from 'react-router-dom';

//Core components
import Collapse from '@material-ui/core/Collapse';
import Tooltip from "@material-ui/core/Tooltip";

//Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

//Custom Components
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";


class CustomDashBoardRiskRegisterItem extends React.Component{

    state = {
        open: true,
        severityColor: "",
        days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    };

    //function that handles collapse of priority 2 information
    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    //function that routes to specific risk
    routeToViewRisk = () => {
        let path = '/app/viewRisk';
        this.props.history.push({
            pathname: path,
            state: {
                id: this.props.item.id
            },
        });
    };

    //function that sets the color of the severity icon
    componentDidMount() {
        if(this.props.item.severity.toLowerCase() === "very high"){
            this.setState({
                severityColor: "#F44336",
            })
        }
        else if(this.props.item.severity.toLowerCase() === "high"){
            this.setState({
                severityColor: "#F44336",
            })
        }
        else if(this.props.item.severity.toLowerCase() === "medium"){
            this.setState({
                severityColor: "#FF9800",
            })
        }
        else {
            this.setState({
                severityColor: "#4CAE50",
            })
        }
    }

    render(){
        const { classes } = this.props;

        return (
                <Card style={{marginTop: 0, marginBottom: 20}}>
                    <CardBody style={{padding: 0}}>
                        <GridContainer>
                            {/*Priority 1: Risk Number*/}
                            <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                                <div style={{padding: "0 0 0 10px", margin: "3px 0 0 0"}}>
                                    {/*<Tooltip*/}
                                    {/*    title="EW-0010"*/}
                                    {/*    placement="top"*/}
                                    {/*    classes={{ tooltip: classes.tooltip }}*/}
                                    {/*>*/}
                                        <p style={{margin: "0 5px 0 0", fontSize: 10, display: "inline"}}>{this.props.item.riskNumber}</p>
                                    {/*</Tooltip>*/}
                                </div>
                            </GridItem>
                            {/*Priority 1: Severity */}
                            <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 5}}>
                                {this.props.item.severity !== "" ? <div style={{padding: "5px 10px 0 0"}}>
                                    <Tooltip
                                        title={this.props.item.severity.charAt(0).toUpperCase() + this.props.item.severity.slice(1)}
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <FiberManualRecord style={{float: "right", width: 14, height: 14, marginLeft: 5, marginTop: -1, color: this.state.severityColor}}/>
                                    </Tooltip>
                                </div> : ""}
                            </GridItem>

                            {/*Priority 1: Risk Description*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <div style={{padding: "0 10px"}}>
                                    <Tooltip
                                        title={this.props.item.description}
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}} className={classes.cardTitle}>{this.props.item.description}</h4>
                                    </Tooltip>
                                </div>
                            </GridItem>

                            {/* Expand Priority 2 button*/}
                            <GridItem xs={12} sm={12} lg={12}>
                                <div style={{height: 14}}>
                                    <Button style={{padding: 0, margin: 0, backgroundColor: "rgb(52, 109, 152)", boxShadow: "none"}} fullWidth onClick={this.handleClick}>{this.state.open ?  <ExpandMore style={{cursor:"pointer",height: 14, width: 14}}/> : <ExpandLess style={{cursor:"pointer", height: 14, width: 14}}  />}</Button>
                                </div>
                                </GridItem>

                        </GridContainer>
                        <Collapse in={!this.state.open} timeout="auto" unmountOnExit>

                            <GridContainer style={{fontSize: 10, padding: "10px 10px 0 10px"}}>
                                {/* Priority 2: Potential Impact*/}
                                <GridItem xs={12} sm={12} lg={12} style={{fontSize: 10, color: "#000", fontWeight: 400}}>
                                    <hr style={{margin: 0}}/>
                                    <p style={{marginBottom: 0}}>Potential Impact</p>
                                    <hr style={{margin: 0}}/>
                                </GridItem>

                                {/* Priority 2: Increase Prices and Delay Completion */}
                                <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title={"Increase the total of the Prices:"}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Increase the total of the Prices:</p>
                                            </Tooltip>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title={"Delay Completion:"}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Delay Completion:</p>
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <p style={{marginBottom: 0}}>{this.props.item.increasePricesValue ? this.props.item.increasePrices + " - " + this.props.item.increasePricesValue + " ZAR" : this.props.item.increasePrices}</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <p style={{marginBottom: 0}}>{this.props.item.impactDelayCompletionValue ? this.props.item.impactDelayCompletion + " - " + this.props.item.impactDelayCompletionValue + " day(s)" : this.props.item.impactDelayCompletion}</p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                {/* Priority 2: Impair performance and Delay Meeting */}
                                <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title={<p style={{marginBottom: 0}}>Impair performance of the <span style={{fontStyle: "italic"}}>works</span> in use:</p>}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Impair performance of the <span style={{fontStyle: "italic"}}>works</span> in use:</p>
                                            </Tooltip>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title="Delay Meeting a Key Date:"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Delay Meeting a Key Date:</p>
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            {/*<Tooltip*/}
                                            {/*    title="No"*/}
                                            {/*    placement="top"*/}
                                            {/*    classes={{ tooltip: classes.tooltip }}*/}
                                            {/*>*/}
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.impactImpairPerformance.charAt(0).toUpperCase() + this.props.item.impactImpairPerformance.slice(1)}</p>
                                            {/*</Tooltip>*/}
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            {/*<Tooltip*/}
                                            {/*    title="No"*/}
                                            {/*    placement="top"*/}
                                            {/*    classes={{ tooltip: classes.tooltip }}*/}
                                            {/*>*/}
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.impactDelayMeeting.charAt(0).toUpperCase() + this.props.item.impactDelayMeeting.slice(1)}</p>
                                            {/*</Tooltip>*/}
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>

                                {/* Priority 2: Increase the Contractor's total cost */}
                                <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                    <Tooltip
                                        title={<p style={{marginBottom: 0}}>Increase the <span style={{fontStyle: "italic"}}>Contractor's</span> total cost:</p>}
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <p style={{marginBottom: 0}}>Increase the <span style={{fontStyle: "italic"}}>Contractor's</span> total cost:</p>
                                    </Tooltip>
                                </GridItem>

                                <GridItem xs={12} sm={12} lg={12}>
                                    {/*<Tooltip*/}
                                    {/*    title="No"*/}
                                    {/*    placement="top"*/}
                                    {/*    classes={{ tooltip: classes.tooltip }}*/}
                                    {/*>*/}
                                        <p style={{marginBottom: 0}}>{this.props.item.increaseContractorsTotalCost.charAt(0).toUpperCase() + this.props.item.increaseContractorsTotalCost.slice(1)}</p>
                                    {/*</Tooltip>*/}
                                    <hr style={{margin: 0}}/>
                                </GridItem>

                                {/* Priority 2: Contract and Project */}
                                <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <p style={{marginBottom: 0}}>Contract:</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <p style={{marginBottom: 0}}>Project:</p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title={this.props.item.contract.number}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.number}</p>
                                            </Tooltip>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title={this.props.item.contract.project.title}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.project.title}</p>
                                            </Tooltip>

                                        </GridItem>
                                    </GridContainer>
                                    <hr style={{margin: 0}}/>
                                </GridItem>

                                {/* Priority 2: View Risk and Submitted date */}
                                <GridItem xs={12} sm={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "4px 0 0 0"}} onClick={() => this.routeToViewRisk()}>View Risk</Button>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} lg={6}>
                                            <Tooltip
                                                title="Submitted"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                            <p style={{float: "right", margin: "0 0 0 0"}}>{this.props.item.dateCreated !== null ? new Date(this.props.item.dateCreated).getDate() + " " + this.state.months[new Date(this.props.item.dateCreated).getMonth()] + " " + new Date(this.props.item.dateCreated).getFullYear() : ""}</p>
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                            </GridContainer>
                        </Collapse>
                    </CardBody>
                </Card>

        );
    }
}

CustomDashBoardRiskRegisterItem.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withRouter(CustomDashBoardRiskRegisterItem);