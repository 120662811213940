import React from "react";
import * as actionCreators from '../../../store/actions/index';
import { connect } from 'react-redux';

class LogoutPage extends React.Component {

    //function that routes if a user logs out
    logout = () => {
        this.props.onUserLogout();
        let path = "/";

        this.props.history.push({
            pathname: path
        });
    };

    //Logout API
    componentDidMount = () => {

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch("/api/logout", {
            credentials: "include",
            method: "GET",
            headers
        }).then(res => {

            if(res.status === 200 || res.status === 204){
                this.logout();
            }
            else{
                alert("Logout error, please try again");
            }

        }).catch((error) => {
            console.error(error);
            this.logout();
        })

    };

    render(){
        return(
            <div>
            </div>
        );
    }


}

const mapDispatchToProps = dispatch => {
    return {
        onUserLogout: () => dispatch(actionCreators.userLogout())
    };
};

export default connect(null, mapDispatchToProps)(LogoutPage);
