import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// import CustomInput from '../../../components/CustomInput/CustomInput.jsx';
// import {selectedDiaryEntry} from "../../../variables/general.jsx";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";

import { addZero, daysArray, monthsArray } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
// import CommentsList from "../../../components/CustomLists/CommentsList";
// import Add from "@material-ui/icons/Add";
import ReactTable from "react-table";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

import fileDownload from "js-file-download";
import GetApp from "@material-ui/icons/GetApp";
import CustomInput from "../../../components/CustomInput/CustomInput";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import CustomInput from "../../../components/CustomInput/CustomInput";
// import DialogActions from "@material-ui/core/DialogActions";

//Custom Styles
const styles = theme => ({
    ...customSelectStyles,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
    alignLastCard: {
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 15
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 15
        }
    }
});

class AddDiaryEntry extends React.Component{

    state = {

        days : daysArray(),
        months : monthsArray(),

        error: false,
        isLoaded: false,

        diary: {
            id: 0,
            contract: {
                id: 0,
                number: "",
                dateContractCreated: "",
                project: {
                    id: 0,
                    title: "",
                    initialValue: 0,
                    revisedValue: 0,
                    dateProjectCommences: "",
                    dateProjectCompletes: "",
                    dateProjectCompletesRevised: "",
                    dateProjectCreated: "",
                    dateProjectUpdated: ""
                },
                initialValue: 0,
                revisedValue: 0,
                dateContractCommences: "",
                dateContractCompletes: "",
                dateContractCompletesRevised: "",
                works: "",
                attachments: []
            },
            diaryDate: "",
            dateUpdated: "",
            dateDiaryCreated: "",
            siteManager: "",
            rain: 0,
            maxTemperature: 0,
            minTemperature: 0,
            peopleOnSite: {
                id: 0,
                ourPeople: {
                    id: 0,
                    seniorManagement: "",
                    management: "",
                    supervisors: "",
                    operators: "",
                    skilledLabour: "",
                    semiSkilledLabour: "",
                    unskilledLabour: ""
                },
                subContractor: [],
                otherPeople: ""
            },
            equipmentOnSite: [],
            deliveredNumber: "",
            deliveredDescription: "",
            programActivities: {
                id: 0,
                startedToday: [],
                programActivityCompleted: "",
                programActivityProgressOnSite: "",
                delayDesc: ""
            },
            numHealthSafetyIncidents: "",
            healthSafetyIncidentsDesc: "",
            numEnvironmentalIncidents: "",
            environmentalIncidentsDesc: "",
            numQualityIncidents: "",
            qualityIncidentsDesc: "",
            generalComments: "",
            user: {
                id: 0,
                username: "",
                roles: [],
                userDetails: {
                    id: 0,
                    name: "",
                    surname: "",
                    contactNumber: "",
                    fax: "",
                    email: "",
                    companyName: "",
                    address: {
                        id: 0,
                        address: ""
                    }
                },
                dateRegistered: ""
            },
            comments: [],
            dailyDiaryResponse: {
                id: 0,
                status: "",
                comment: null,
                user: null,
                dateDiaryResponseSubmitted: ""
            },
            attachments: []
        },


        reason: "",
        newComment: "",
        loadingAddAttachment: false,
        loadingDownloadFile: false,

        openUploadAttachmentDialog: false,
        currentAttachmentFileName: "No file chosen",

        currentFile: {},

        showResponseButtons: false,

        // openRequestExtensionDialog: false,
        // extensionDateValue: getCurrentDate(),
        // extensionTimeValue: "00:00",
        // loadingRequestExtension: false,
    };

    //function that routes back to diary register page
    routeChange = () => {
        let path = "/app/diary";
        this.props.history.push({
            pathname: path
        });
    };

    //function that handles opening dialogs
    // handleClickOpen = () => {
    //
    //     this.setState({
    //         openUploadAttachmentDialog: true
    //     });
    // };

    //function that handles closing dialogs
    // handleCancel = () => {
    //
    //     this.setState({
    //         openUploadAttachmentDialog: false, currentAttachmentFileName: "No file chosen"
    //     });
    //
    // };

    //function that handles opening dialogs
    // handleOpen = (type) => {
    //
    //     if(type === "extension"){
    //         this.setState({ openRequestExtensionDialog: true});
    //     }
    // };
    //
    // //function that handles closing dialogs
    // handleClose = (type) => {
    //
    //     if(type === "extension"){
    //         this.setState({ openRequestExtensionDialog: false, extensionDateValue: getCurrentDate(), extensionTimeValue: "00:00" });
    //     }
    //
    // };

    //function that handles any comment change
    // onCommentChange = (event) => {
    //
    //     this.setState( {
    //         newComment: event.target.value
    //     });
    //
    // };

    // onChange = (event, type) => {
    //
    //     if(type === "extension"){
    //
    //         const id = event.target.id;
    //
    //         if(id === "extensionDateInput"){
    //             this.setState({
    //                 extensionDateValue: event.target.value
    //             });
    //         }
    //
    //         if(id === "extensionTimeInput"){
    //             this.setState({
    //                 extensionTimeValue: event.target.value
    //             });
    //         }
    //
    //     }
    //
    // };

    //function that handles calling the upload_files api request for adding an attachment
    // handleAddAttachment = (event) => {
    //
    //     this.setState({
    //         loadingAddAttachment: true
    //     });
    //
    //     let data = new FormData();
    //     data.append('file', this.state.currentFile);
    //     data.append('type', 'dailyDiary');
    //     data.append('id', this.props.location.state.id);
    //
    //     // setTimeout(() => {
    //     fetch(getApiRoot() + '/api/upload_files', {
    //         credentials: "include",
    //         method: "POST",
    //         body: data,
    //     }).then(res => {
    //
    //         this.setState({loadingAddAttachment: false});
    //
    //         if (res.status === 200) {
    //             // alert("Attachment successfully uploaded");
    //             this.setState({currentAttachmentFileName: "No file chosen", currentFile: {}, openUploadAttachmentDialog: false, loadingDownloadFile: true});
    //             this.getInitialDiaryData();
    //
    //         } else {
    //             alert("Something went wrong - HTTP: " + res.status);
    //         }
    //
    //     }).catch(error => alert(error));
    //     // }, 2000);
    //
    //     event.preventDefault();
    // };

    // changeAttachment = (event) => {
    //
    //     if(event.target.files.length === 0){
    //         this.setState({
    //             currentAttachmentFileName: "No file chosen"
    //         });
    //     }
    //     else{
    //         // alert(event.target.files[0]);
    //         this.setState({
    //             currentAttachmentFileName: event.target.files[0].name, currentFile: event.target.files[0]
    //         });
    //     }
    //
    // };

    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setState({
                        isLoaded: true,
                        // permissions: result
                    });
                }

                this.setPermissions(result);

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
        // },2000)

    };

    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let diaryResponse = permissions.find(o => o.type === "dailyDiary" && o.subtype === "response").permission;

            if(diaryResponse === "true"){
                this.setState({
                    showResponseButtons: true,
                });
            }
            else{
                this.setState({
                    showResponseButtons: false,
                });
            }

        }
        else{
            this.setState({
                showResponseButtons: false,
            });
        }

    };

    //function that gets initial daily diary data with daily_diary api request
    getInitialDiaryData = () => {

        // this.setState({
        //     isLoaded: false,
        // });

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/daily_diary/" + this.props.location.state.id, {
            credentials: "include",
            method: "GET",
            headers,
        })
            .then(res => {
                if(res.status === 200){
                    return res.json();
                }
                else{
                    alert("Something went wrong - HTTP " + res.status);
                }
            })
            .then(
                (result) => {

                    if(result !== undefined){

                        this.setState({
                            // isLoaded: true,
                            diary: result,
                            loadingDownloadFile: false
                        });

                        this.getPermissions();

                    }


                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)
    };

    //function that handles onClick of actions (accept/reject/request extension)
    handleActionClick = (value) => {

        let data = {
            dailyDiary: this.state.diary.id,
            status: value,
            comment: this.state.reason,
        };

        // alert(JSON.stringify(data));

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/submit_daily_diary_response', {
            credentials: "include",
            method: "POST",
            headers,
            body: JSON.stringify(data),
        }).then(res => {

            if(res.status === 200){
                // alert("Submitted answer");
                this.setState({
                    // diary.dailyDiaryResponse.status
                    diary: Object.assign(
                        {}, this.state.diary,
                        {
                            dailyDiaryResponse: Object.assign(
                                {}, this.state.diary.dailyDiaryResponse,
                                {
                                    status: value,
                                    comment: this.state.reason
                                }
                            )
                        }
                    )
                })

            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));

    };



    //function that handles adding a comment
    addComment = () => {
        // alert("Add Comment: " + this.state.newCommentValue);

        this.setState({
            loadingAddComment: true
        });

        let data = JSON.stringify({
            dailyDiary: this.props.location.state.id,
            comment: this.state.newComment
        });

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/create_comment', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            this.setState({ loadingAddComment : false });

            if(res.status === 200){
                // alert("Comment successfully added");
                this.setState({ newComment : "" });

                this.getInitialDiaryData();

            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
        // }, 2000)


    };


    //function that downloads files from back end with download_files api
    downloadFileAPI = (uuid, fileName) => {

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/download_file/' + uuid, {
            credentials: "include",
            method: "GET",
        }).then(res => res.blob())
            .then(result => {

                fileDownload(result, fileName);

                this.setState({
                    loadingDownloadFile: false
                });

            //{

            // if (res.status === 200) {
            //
            //     return res;
            //
            // } else {
            //     alert("Something went wrong - HTTP: " + res.status);
            // }

        }).catch(error => alert(error));

    };

    //function that retrieves initial diary data to populate the page
    componentDidMount() {
        this.getInitialDiaryData();
    }


    render(){

        const { classes } = this.props;
        const { error, isLoaded, /*loadingAddComment,*/ /*loadingAddAttachment,*/ loadingDownloadFile, /*loadingRequestExtension*/ } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}} /></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer justify={"center"}>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignFirstCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Diary Date */}
                                    <GridItem xs={12} lg={12}>
                                        <h4 style={{marginBottom: 20}}><strong>{this.state.diary.diaryDate !== null ? new Date(this.state.diary.diaryDate).getDate() + " " + this.state.months[new Date(this.state.diary.diaryDate).getMonth()] + " " + new Date(this.state.diary.diaryDate).getFullYear() : ""}</strong></h4>
                                    </GridItem>

                                    {/* Contract */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14}}><strong>Contract:</strong> {this.state.diary.contract.number}</p>
                                    </GridItem>

                                    {/* Project */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14, float: "right"}}>
                                            <strong>Project:</strong> {this.state.diary.contract.project.title}</p>
                                    </GridItem>

                                    {/* Submitted Date */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12}}>Submitted: {this.state.diary.dateDiaryCreated !== null ? new Date(this.state.diary.dateDiaryCreated).getDate() + " " + this.state.months[new Date(this.state.diary.dateDiaryCreated).getMonth()] + " " + new Date(this.state.diary.dateDiaryCreated).getFullYear() : ""}</p>
                                    </GridItem>

                                    {/* Site Manager */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12, float: "right"}}>Site
                                            Manager: {this.state.diary.siteManager}</p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Min Temperature */}
                                    <GridItem xs={4} sm={4} lg={4}>
                                        <p style={{fontSize: 14}}><strong>Min Temp</strong></p>
                                        <p style={{fontSize: 14}}>{this.state.diary.minTemperature} &deg;C</p>
                                    </GridItem>

                                    {/* Rain */}
                                    <GridItem xs={4} sm={4} lg={4}>
                                        <p style={{fontSize: 14, textAlign: "center"}}><strong>Rain</strong></p>
                                        <p style={{fontSize: 14, textAlign: "center"}}>{this.state.diary.rain} mm</p>
                                    </GridItem>

                                    {/* Max Temperature */}
                                    <GridItem xs={4} sm={4} lg={4}>
                                        <p style={{fontSize: 14, textAlign: "right"}}><strong>Max Temp</strong></p>
                                        <p style={{fontSize: 14, textAlign: "right"}}>{this.state.diary.maxTemperature} &deg;C</p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card>
                            <CardBody>

                                {/* People on site */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>People on site</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                {/* Our People */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 20px 0"}}>Our People</p>
                                </GridItem>


                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>

                                        {/* Senior Management */}
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <p style={{fontSize: 12}}>Senior Management: {this.state.diary.peopleOnSite.ourPeople.seniorManagement}</p>
                                        </GridItem>

                                        {/* Management */}
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <p style={{fontSize: 12}}>Management: {this.state.diary.peopleOnSite.ourPeople.management}</p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>

                                        {/* Supervisors */}
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <p style={{fontSize: 12}}>Supervisors: {this.state.diary.peopleOnSite.ourPeople.supervisors}</p>
                                        </GridItem>

                                        {/* Operators */}
                                        <GridItem xs={12} sm={6} md={6} lg={6}>
                                            <p style={{fontSize: 12}}>Operators: {this.state.diary.peopleOnSite.ourPeople.operators}</p>
                                        </GridItem>

                                    </GridContainer>
                                </GridItem>

                                {/* Labour */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{fontSize: 12}}>Labour</p>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginLeft: 5}}>
                                    <GridContainer>

                                        {/* Skilled Labour */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p style={{fontSize: 10}}>Skilled: {this.state.diary.peopleOnSite.ourPeople.skilledLabour}</p>
                                        </GridItem>

                                        {/* Semi-Skilled Labour */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p style={{fontSize: 10}}>Semi-Skilled: {this.state.diary.peopleOnSite.ourPeople.semiSkilledLabour}</p>
                                        </GridItem>

                                        {/* Unskilled Labour */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p style={{fontSize: 10}}>Unskilled: {this.state.diary.peopleOnSite.ourPeople.unskilledLabour}</p>
                                        </GridItem>

                                        {/* Disabled Labour */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p style={{fontSize: 10}}>Disabled: {this.state.diary.peopleOnSite.ourPeople.disabledPeople}</p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                {/* Sub Contractors */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Sub Contractors</p>
                                </GridItem>

                                <GridItem xs={12} lg={12}>
                                    {this.state.diary.peopleOnSite.subContractor.length ?
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.diary.peopleOnSite.subContractor.map((item) =>

                                                        <ListItem
                                                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                            key={item.id}>
                                                            <GridContainer>
                                                                <GridItem xs={5} lg={5}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>{item.subContractorName}</p>
                                                                </GridItem>
                                                                <GridItem xs={5} lg={5}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>Discipline - {item.discipline}</p>
                                                                </GridItem>
                                                                <GridItem xs={2} lg={2}>
                                                                    <p style={{float: "right", fontSize: 12, marginBottom: 0}}># of people - {item.peopleOnSiteSubContractor}</p>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </ListItem>)
                                                }
                                            </List>
                                        </GridItem>
                                        : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                        </GridItem>
                                    }
                                </GridItem>

                                {/* Other people on site */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Other people on site</p>
                                </GridItem>

                                <GridItem xs={12} lg={12}>
                                    {this.state.diary.peopleOnSite.otherPeople.length ?
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.diary.peopleOnSite.otherPeople.map((item) =>

                                                        <ListItem
                                                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                            key={item.id}>
                                                            <GridContainer>
                                                                <GridItem xs={8} lg={8}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>{item.description}</p>
                                                                </GridItem>
                                                                <GridItem xs={4} lg={4}>
                                                                    <p style={{
                                                                        float: "right",
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}># of people - {item.noOfPeople}</p>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </ListItem>)
                                                }
                                            </List>
                                        </GridItem>
                                        : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                        </GridItem>
                                    }
                                </GridItem>

                            </CardBody>
                        </Card>
                        <Card style={{paddingTop: 15}} className={classes.alignCard}>

                            {/* Equipment On Site */}
                            <CardBody>
                                <GridItem xs={12} sm={12} md={12} lg={12} >
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>Equipment on site</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                <GridItem xs={12} lg={12} style={{marginBottom: 20}}>
                                    {this.state.diary.equipmentOnSite.length ?
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.diary.equipmentOnSite.map((item, index) =>

                                                        <ListItem
                                                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                            key={index}>
                                                            <GridContainer>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>{item.equipmentName}</p>
                                                                </GridItem>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0,
                                                                        float: "right"
                                                                    }}>Working - {item.isWorking}</p>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </ListItem>)
                                                }
                                            </List>
                                        </GridItem>
                                        : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                        </GridItem>
                                    }
                                </GridItem>


                            </CardBody>
                        </Card>

                        <Card style={{paddingTop: 15}} className={classes.alignCard}>

                            {/* Plant */}
                            <CardBody>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>Plant</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                <GridItem xs={12} lg={12} style={{marginBottom: 20}}>
                                    {this.state.diary.plantDelivered.length ?
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.diary.plantDelivered.map((item, index) =>

                                                        <ListItem
                                                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                            key={index}>
                                                            <GridContainer>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>{item.description}</p>
                                                                </GridItem>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0,
                                                                        float: "right"
                                                                    }}># Delivered - {item.numberDelivered}</p>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </ListItem>)
                                                }
                                            </List>
                                        </GridItem>
                                        : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                        </GridItem>
                                    }
                                </GridItem>

                            </CardBody>
                        </Card>

                        <Card style={{paddingTop: 15}} className={classes.alignCard}>

                            {/* Activities */}
                            <CardBody>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>Activities</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                {/* Program Activities Started Today */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Program Activities Started Today</p>
                                </GridItem>

                                <GridItem xs={12} lg={12} style={{marginBottom: 20}}>
                                    {this.state.diary.programActivities.startedToday.length ?
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.diary.programActivities.startedToday.map((item, index) =>

                                                        <ListItem
                                                            style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                            key={index}>
                                                            <GridContainer>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0
                                                                    }}>{item.programActivityDesc}</p>
                                                                </GridItem>
                                                                <GridItem xs={6} lg={6}>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        marginBottom: 0,
                                                                        float: "right"
                                                                    }}># Activity - {item.programActivityNumber}</p>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </ListItem>)
                                                }
                                            </List>
                                        </GridItem>
                                        : <GridItem xs={12} lg={12}><p style={{fontStyle: "italic"}}>No Entries</p>
                                        </GridItem>
                                    }
                                </GridItem>

                                {/* Progress on site */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Progress On Site</p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}>{this.state.diary.programActivities.programActivityProgressOnSite}</p>
                                </GridItem>

                                {/* Program activities completed*/}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Program Activities Completed</p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}>{this.state.diary.programActivities.programActivityCompleted}</p>
                                </GridItem>

                                {/* Delays/Disruptions/Variations/Disagreements */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Delays/Disruptions/Variations/Disagreements</p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 20px 0", fontSize: 12}}>{this.state.diary.programActivities.delayDesc}</p>
                                </GridItem>


                            </CardBody>
                        </Card>

                        <Card style={{paddingTop: 15}} className={classes.alignCard}>
                            <CardBody>

                                {/* SHEQ */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>Safety, Health, Environment and Quality Assurance</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                {/* Safety & Health */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Safety & Health</p>
                                </GridItem>

                                {/* Safety & Health Incidents*/}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}>{this.state.diary.healthSafetyIncidentsDesc}</p>
                                </GridItem>

                                {/* Safety & Health Number of Incidents */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}># Health & Safety Incidents: {this.state.diary.numHealthSafetyIncidents}</p>
                                </GridItem>

                                {/* Environment */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Environment</p>
                                </GridItem>

                                {/* Environment Incidents*/}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}>{this.state.diary.environmentalIncidentsDesc}</p>
                                </GridItem>

                                {/* Environment Number of Incidents*/}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}># Environmental Incidents: {this.state.diary.numEnvironmentalIncidents}</p>
                                </GridItem>

                                {/* Quality */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "20px 0 0 0"}}>Quality</p>
                                </GridItem>

                                {/* Quality Incidents */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 0 0", fontSize: 12}}>{this.state.diary.qualityIncidentsDesc}</p>
                                </GridItem>

                                {/* Quality Number of Incidents */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{margin: "10px 0 20px 0", fontSize: 12}}># Quality Incidents: {this.state.diary.numQualityIncidents}</p>
                                </GridItem>


                            </CardBody>
                        </Card>

                        <Card className={classes.alignCard}>
                            <CardBody>

                                {/* General comments */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>General Comments</strong></p>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                <GridItem xs={12} sm={12} lg={12}>
                                    <p>{this.state.diary.generalComments !== "" ? this.state.diary.generalComments : <span style={{fontStyle: "italic"}}>None</span>}</p>
                                </GridItem>
                            </CardBody>
                        </Card>

                        <Card style={{marginBottom: 0}} className={classes.alignCard}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Attachments */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 16}}><strong>Attachments</strong></p>
                                    </GridItem>

                                    {/* Add Attachment button */}
                                    {/*<GridItem xs={6} sm={6} lg={6}>*/}
                                    {/*    <Add style={{cursor: "pointer", fontSize: 16, float: "right"}}*/}
                                    {/*         onClick={() => this.handleClickOpen()}/>*/}
                                    {/*</GridItem>*/}

                                    {/* Current Attachments Table */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{position: "relative"}}>
                                            <ReactTable

                                                data={this.state.diary.attachments ? this.state.diary.attachments: []}
                                                filterable={false}
                                                sortable={false}
                                                resizable={false}
                                                noDataText="No attachments"
                                                columns={[
                                                    {
                                                        Header: "File",
                                                        accessor: "fileName",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                    },
                                                    {
                                                        Header: "Uploaded By",
                                                        accessor: "user.userDetails",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value.name + " " + row.value.surname}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Uploaded",
                                                        accessor: "attachmentCreated",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "id",
                                                        headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                        style: {"textAlign": "center"},
                                                        Cell: row => (
                                                            <div>
                                                                <Button simple disabled={loadingDownloadFile} onClick={() => {

                                                                    this.setState({
                                                                        loadingDownloadFile: true
                                                                    });

                                                                    let id = this.state.diary.attachments.findIndex(o => o.id === row.value);
                                                                    let uuid = this.state.diary.attachments[id].uuid;
                                                                    let fileName = this.state.diary.attachments[id].fileName;

                                                                    this.downloadFileAPI(uuid, fileName);

                                                                }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                            </div>

                                                        ),
                                                    },
                                                ]}
                                                defaultSorted={[
                                                    {
                                                        id: "id",
                                                        desc: true
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationBottom={true}
                                                className="-striped -highlight"
                                                showPageJump={false}
                                            />
                                            { this.state.loadingDownloadFile && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>


                        {/*<Card>*/}
                        {/*    <CardBody>*/}
                        {/*        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                        {/*            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                        {/*            <p><strong>Comments</strong></p>*/}
                        {/*            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                        {/*        </GridItem>*/}

                        {/*        /!* Current Comments *!/*/}
                        {/*        <GridItem xs={12} lg={12}>*/}
                        {/*            <CommentsList items={this.state.diary.comments}/>*/}
                        {/*        </GridItem>*/}

                        {/*        /!* New Comment Input *!/*/}
                        {/*        <GridItem xs={12} sm={12} lg={12} style={{marginTop: 15}}>*/}
                        {/*            <CustomInput*/}
                        {/*                labelText="New Comment"*/}
                        {/*                id="newDiaryComment"*/}

                        {/*                formControlProps={{*/}
                        {/*                    fullWidth: true,*/}

                        {/*                }}*/}
                        {/*                inputProps={{*/}
                        {/*                    multiline: true,*/}
                        {/*                    value: this.state.newComment,*/}
                        {/*                    onChange: (event) => this.onCommentChange(event)*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        </GridItem>*/}

                        {/*        /!* Add New Comment Button *!/*/}
                        {/*        <GridItem xs={12} sm={12} lg={12}>*/}
                        {/*            <div style={{position: "relative", float:"left"}}>*/}
                        {/*                <Button color="primary" style={{padding: "10px 20px", margin: "15px 0"}}*/}
                        {/*                        onClick={() => this.addComment()} disabled={loadingAddComment}>Add</Button>*/}
                        {/*                {loadingAddComment && <CircularProgress size={24} style={{*/}
                        {/*                    position: 'absolute',*/}
                        {/*                    top: '50%',*/}
                        {/*                    left: '50%',*/}
                        {/*                    marginTop: -12,*/}
                        {/*                    marginLeft: -12*/}
                        {/*                }}/>}*/}
                        {/*            </div>*/}
                        {/*        </GridItem>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}

                        <Card className={classes.alignLastCard}>
                            <CardBody>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                    <p><strong>Review</strong></p>
                                    <hr style={{margin: "10px 0 20px 0"}}/>
                                </GridItem>

                                {this.state.diary.dailyDiaryResponse.status.toLowerCase() === "pending" && this.state.showResponseButtons ?
                                    <div>
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="actionReason"
                                                // success={this.state.reasonState === "success"}
                                                // error={this.state.reasonState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    style: {paddingTop: 0},
                                                }}
                                                inputProps={{
                                                    value: this.state.reason,
                                                    onChange:(event) => this.setState({
                                                        reason: event.target.value
                                                    }),
                                                    type: "text",
                                                    placeholder: "Comment",
                                                }}

                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} lg={12} style={{padding: "0 !important"}}>
                                                    <Button color="info"
                                                            onClick={() => this.handleActionClick("Rejected")}>Reject</Button>
                                                    <Button color="primary"
                                                            onClick={() => this.handleActionClick("Accepted")}>Accept</Button>
                                                </GridItem>
                                                {/*<GridItem xs={12} sm={12} lg={12}>*/}
                                                {/*    <Button size="sm" simple color="primary" style={{float: "left", padding: 2, marginTop: 10}} onClick={() => this.handleOpen("extension")}>Request Extension</Button>*/}
                                                {/*</GridItem>*/}
                                            </GridContainer>
                                        </GridItem>
                                    </div>
                                    :  <GridItem xs={12} sm={12} lg={12}>
                                        <p>{"Current Status - " + this.state.diary.dailyDiaryResponse.status}</p>
                                        {this.state.diary.dailyDiaryResponse.comment !== null ? <p>{"Comment - " + this.state.diary.dailyDiaryResponse.comment}</p> : ""}
                                    </GridItem>}
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/* Route back to diary register button */}
                    <GridItem xs={12} sm={12} lg={10}>
                        <Button simple color={"primary"} style={{paddingLeft: 5, marginTop: -10}} onClick={() => this.routeChange()}>Back to Daily Diary Register</Button>
                    </GridItem>

                    {/* Upload Attachment Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openUploadAttachmentDialog}*/}
                    {/*    onClose={() => this.handleCancel()}*/}
                    {/*    aria-labelledby="uploadRiskAttachmentDialog"*/}
                    {/*>*/}
                    {/*    <form>*/}
                    {/*        <DialogTitle id="uploadAttachmentDialog">Upload Attachments</DialogTitle>*/}
                    {/*        <DialogContent>*/}

                    {/*            /!* File Upload *!/*/}
                    {/*            <GridContainer justify={"center"}>*/}
                    {/*                <GridItem xs={12} sm={12} md={12} lg={12}*/}
                    {/*                          style={{textAlign: "center", margin: "15px 15px 0 15px"}}>*/}
                    {/*                    <input*/}
                    {/*                        accept="*"*/}
                    {/*                        className={classes.input}*/}
                    {/*                        id="uploadAttachmentInput"*/}
                    {/*                        type="file"*/}
                    {/*                        onChange={(event) => this.changeAttachment(event)}*/}
                    {/*                    />*/}
                    {/*                    <label htmlFor="uploadAttachmentInput">*/}
                    {/*                        <Button size={"sm"} variant="contained" component="span"*/}
                    {/*                                className={classes.button}>*/}
                    {/*                            Choose File*/}
                    {/*                        </Button>*/}
                    {/*                    </label>*/}
                    {/*                </GridItem>*/}

                    {/*                /!* Current Attachment Name *!/*/}
                    {/*                <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                    <p style={{*/}
                    {/*                        fontSize: 12,*/}
                    {/*                        textAlign: "center",*/}
                    {/*                        marginTop: 10*/}
                    {/*                    }}>{this.state.currentAttachmentFileName}</p>*/}
                    {/*                </GridItem>*/}

                    {/*            </GridContainer>*/}
                    {/*        </DialogContent>*/}
                    {/*        <DialogActions>*/}
                    {/*            <Button onClick={() => this.handleCancel()} color="info">*/}
                    {/*                Close*/}
                    {/*            </Button>*/}
                    {/*            <div style={{position: "relative"}}>*/}
                    {/*                <Button type={"submit"} onClick={(event) => this.handleAddAttachment(event)} disabled={loadingAddAttachment}*/}
                    {/*                        color="primary">*/}
                    {/*                    Upload*/}
                    {/*                </Button>*/}
                    {/*                {loadingAddAttachment && <CircularProgress size={24} style={{*/}
                    {/*                    position: 'absolute',*/}
                    {/*                    top: '50%',*/}
                    {/*                    left: '50%',*/}
                    {/*                    marginTop: -12,*/}
                    {/*                    marginLeft: -12*/}
                    {/*                }}/>}*/}
                    {/*            </div>*/}
                    {/*        </DialogActions>*/}
                    {/*    </form>*/}
                    {/*</Dialog>*/}

                    {/*<Dialog*/}
                    {/*    open={this.state.openRequestExtensionDialog}*/}
                    {/*    onClose={() => this.handleClose("extension")}*/}
                    {/*    aria-labelledby="extensionDialog"*/}
                    {/*    // fullScreen*/}
                    {/*    maxWidth={false}>*/}
                    {/*    <DialogTitle id="extensionDialog">Request Extension</DialogTitle>*/}
                    {/*    <DialogContent>*/}

                    {/*        /!* Date of extension*!/*/}
                    {/*        <GridItem xs={12} sm={12} lg={12} >*/}
                    {/*            <CustomInput*/}
                    {/*                id="extensionDateInput"*/}
                    {/*                labelText="Extended date"*/}
                    {/*                labelProps={{*/}
                    {/*                    shrink: true*/}
                    {/*                }}*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true,*/}
                    {/*                }}*/}
                    {/*                inputProps={{*/}
                    {/*                    value: this.state.extensionDateValue,*/}
                    {/*                    onChange: event => this.onChange(event, "extension"),*/}
                    {/*                    type: "date",*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </GridItem>*/}

                    {/*        /!* Time of extension*!/*/}
                    {/*        <GridItem xs={12} sm={12} lg={12} >*/}
                    {/*            <CustomInput*/}
                    {/*                id="extensionTimeInput"*/}
                    {/*                labelText="Extended time"*/}
                    {/*                labelProps={{*/}
                    {/*                    shrink: true*/}
                    {/*                }}*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true,*/}
                    {/*                }}*/}
                    {/*                inputProps={{*/}
                    {/*                    value: this.state.extensionTimeValue,*/}
                    {/*                    onChange: event => this.onChange(event, "extension"),*/}
                    {/*                    type: "time",*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </GridItem>*/}

                    {/*    </DialogContent>*/}

                    {/*    <DialogActions>*/}

                    {/*        /!* Extension Back Button *!/*/}
                    {/*        <Button color={"info"} onClick={() => this.handleClose("extension")}>*/}
                    {/*            Back*/}
                    {/*        </Button>*/}

                    {/*        /!* Extension Submit Button *!/*/}
                    {/*        <div style={{position: "relative"}}>*/}
                    {/*            <Button type={"submit"} onClick={() => this.handleActionClick(3)}*/}
                    {/*                    color="primary" >*/}
                    {/*                Submit*/}
                    {/*            </Button>*/}
                    {/*            {loadingRequestExtension && <CircularProgress size={24} style={{*/}
                    {/*                position: 'absolute',*/}
                    {/*                top: '50%',*/}
                    {/*                left: '50%',*/}
                    {/*                marginTop: -12,*/}
                    {/*                marginLeft: -12*/}
                    {/*            }}/>}*/}
                    {/*        </div>*/}

                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                </GridContainer>
            );
        }
    }

} export default withStyles(styles)(AddDiaryEntry);