import * as actionTypes from '../actions/actionTypes';

const initialState = {
    successMessage : null,
    isOpen : false
};

const setSuccessMessage = (action) => {
    return { 
        successMessage : action.success,
        isOpen : true
    };
}

const hideSuccessMessage = () => {
    return { 
        successMessage : null,
        isOpen : false
    };
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_SUCCESS: return setSuccessMessage(action);
        case actionTypes.HIDE_SUCCESS: return hideSuccessMessage();
        default: return state;
    }
};

export default reducer;