import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axiosInstance";

const getProjectsAndContractsStart = () => {
    return {
        type: actionTypes.GET_PROJECTS_AND_CONTRACTS_START,
    };
};

const getProjectsAndContractsFail = (error) => {
    return {
        type: actionTypes.GET_PROJECTS_AND_CONTRACTS_FAIL,
        data: null,
        error: error,
    };
};

const getProjectsAndContractsSuccess = (data) => {
    return {
        type: actionTypes.GET_PROJECTS_AND_CONTRACTS_SUCCESS,
        data: data,
        error: null,
    };
};

export const getProjectsAndContracts = (payload) => {
    return (dispatch, getState) => {
        if (getState().projectAndContractReducer.projectsAndContractsLoading !== true) {
            dispatch(getProjectsAndContractsStart());
            return axiosInstance.get("/api" + payload.url)
                .then(response => {
                    dispatch(getProjectsAndContractsSuccess(response.data));
                })
                .catch(error => {
                    dispatch(getProjectsAndContractsFail(error));
                    throw error;
                });
        }
    };
};