import React, { createRef } from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CustomInput from "../../CustomInput/CustomInput";
import Button from "../../CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import communicationRevisionResponseFormStyles from "../../../assets/styles/communicationRevisionResponseFormStyles";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import UploadAttachmentInput from "../../CustomInput/UploadAttachmentInput";
import RadioGroupAccordion from "../../Accordion/RadioGroupAccordion";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from "@material-ui/core/IconButton";
import {firstToUpper} from "../../../globals";

class NotificationResponseFormContent extends React.Component {

  constructor(props) {
    super(props);
    this.topOfPage = createRef(); //ref to top of dialog
  }

  state = {
    showScrollTop : false, //show the scroll to top button
  };

    //open and close form
    onOpenAndCloseForm = () => {
        this.props.openClose();
    };

    //call parent component on change method
    onChangeInput = (item, event, index ) => {
        this.props.onChangeInput(item, event, index);
    };

    //remove item from list components
    removeFromList = (index, item) => {
        this.props.removeFromList(index, item);
    };

    actionMethod = () => {
        this.props.actionMethod("preview");
    };

    //scroll to the top of the page onclick
    scrollToTop = () => {
      if(this.topOfPage.current){ //check if the reference exists
        this.topOfPage.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    //show scroll to top button when user initially scrolls
    handleScroll = () => {
      if(!this.state.showScrollTop){
        this.setState({
          showScrollTop : true,
        });
      }
    };

    render() {
        const { classes, displayResponseData } = this.props;
        const { showScrollTop } = this.state;
        // const scrollTopLabelClass = isMobile ? classes.scrollTopLabelMobile : classes.scrollTopLabel;

        return(
            <React.Fragment>

                {/* App bar component that contains title and dialog actions */}
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    {/* heading */}
                    <Typography variant={"h6"} className={classes.appBarHeading}>
                      {this.props.responseType.toLowerCase() === "accepted" ? displayResponseData.accept : displayResponseData.reject}
                    </Typography>

                    {/* close button */}
                    <Button size="sm" simple={true} className={classes.appBarButtons} onClick={this.onOpenAndCloseForm} disabled={this.props.loadingPreview}>Close</Button>

                    {/* preview button */}
                    <div className={classes.relativeDiv}>
                      <Button size="sm" onClick={this.actionMethod} simple={true} className={classes.appBarButtons} disabled={this.props.loadingPreview}>Preview</Button>
                      {this.props.loadingPreview && <CircularProgress size={24} className={classes.loadingWheel} /> }
                    </div>
                  </Toolbar>
                </AppBar>

              {/* dialog content which includes the response form */}
              <DialogContent onScroll={this.handleScroll} className={classes.dialogContent}>

                {/* ref to scroll to */}
                <div ref={this.topOfPage}/>
                    <GridContainer>

                            {/* generate input fields dynamically based on data sent in */}
                            {this.props.fields.map((item, key) => {

                                switch (item.type) {
                                    case "text" :
                                        return (
                                            <GridItem xs={12} sm={12} lg={12} key={key}>
                                                <CustomInput
                                                    labelText={item.required ? item.labelText + " *" : item.labelText}
                                                    success={this.props[item.field + "State"] === "success"}
                                                    error={this.props[item.field + "State"] === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        defaultValue: this.props[item.field] || "",
                                                        onBlur: event =>
                                                            this.props.onChangeInput(item, event),
                                                        type: "text",
                                                        endAdornment:
                                                            this.props[item.field + "State"] === "error" ? (
                                                                <InputAdornment position="end">
                                                                    <Close className={classes.danger}/>
                                                                </InputAdornment>
                                                            ) : undefined
                                                    }}
                                                />
                                            </GridItem>);
                                    case "textArea" :
                                        return (
                                            <GridItem xs={12} sm={12} lg={12} key={key}>
                                                <CustomInput
                                                    labelText={item.required ? item.labelText + " *" : item.labelText}
                                                    success={this.props[item.field + "State"] === "success"}
                                                    error={this.props[item.field + "State"] === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        multiline: true,
                                                        rows: 5,
                                                        defaultValue: this.props[item.field] || "",
                                                        onBlur: event =>
                                                            this.props.onChangeInput(item, event),
                                                        type: "text",
                                                        endAdornment:
                                                            this.props[item.field + "State"] === "error" ? (
                                                                <InputAdornment position="end">
                                                                    <Close className={classes.danger}/>
                                                                </InputAdornment>
                                                            ) : undefined
                                                    }}
                                                />
                                            </GridItem>);
                                    case "date" :
                                        return (
                                            <GridItem xs={12} sm={12} lg={12} key={key}>
                                                <CustomInput
                                                    labelText={item.required ? item.labelText + " *" : item.labelText}
                                                    success={this.props[item.field + "State"] === "success"}
                                                    error={this.props[item.field + "State"] === "error"}
                                                    labelProps={{
                                                        shrink: true
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        value: this.props[item.field] || "",
                                                        onChange: event =>
                                                            this.props.onChangeInput(item, event),
                                                        type: "date",
                                                        className: classes.input,
                                                        endAdornment:
                                                            this.props[item.field + "State"] === "error" ? (
                                                                <InputAdornment position="end">
                                                                    <Close className={classes.danger}/>
                                                                </InputAdornment>
                                                            ) : undefined
                                                    }}
                                                />
                                            </GridItem>
                                        );
                                    case "attachment" :
                                        return (
                                            <GridItem xs={12} sm={12} lg={12} key={key}>
                                                {/* Upload Attachment Component */}
                                                <UploadAttachmentInput  onChange={this.props.onChangeInput}
                                                                        data={item}
                                                                        id={"upload" + firstToUpper(item.field) + "Input"}
                                                                        onRemoveFromListParent={this.removeFromList}
                                                                        attachments={this.props[item.field]}
                                                                        heading={item.required ? item.labelText + " *" : item.labelText}
                                                                        headingColor={this.props[item.field + "HeadingColor"]}
                                                                        noDataText={item.required ? "No attachments (required)" : "No attachments"}/>
                                            </GridItem>
                                        );

                                    case "clauseRadio" :
                                        return (
                                            <GridItem xs={12} sm={12} lg={12} key={key}>
                                                <RadioGroupAccordion
                                                    clauseData={item}
                                                    radioData={this.props[item.field]}
                                                    onChange={this.props.onChangeInput}
                                                    isDetails={false}
                                                />
                                            </GridItem>
                                        );
                                    default:
                                        return "";
                                }
                            })
                            }
                            {/* show required information for all required fields in the form */}
                            {this.props.showRequiredDescription ?
                              <GridItem xs={12} sm={12} lg={12}>
                                  {/* preview fields description*/}
                                  <p className={classes.requiredDescription} style={{marginTop: 15}}>* Required Information</p>
                              </GridItem>
                              : ""
                            }

                    </GridContainer>

                {/* Scroll back to top of full page dialog*/}
                {showScrollTop ? <label className={classes.scrollTopLabel}>
                  <IconButton className={classes.scrollTopButton} onClick={() => this.scrollToTop()} component={"span"} size={"sm"} color={"primary"}>
                    <KeyboardArrowUpIcon className={classes.scrollTopIcon}/>
                  </IconButton>
                </label> : ""
                }

              </DialogContent>
            </React.Fragment>
        );
    }
}

//component proptypes
NotificationResponseFormContent.propTypes = {
    openClose : PropTypes.func,
    actionMethod : PropTypes.func,
    loadingPreview : PropTypes.bool,
    responseType : PropTypes.string,
};

export default withStyles(communicationRevisionResponseFormStyles)(NotificationResponseFormContent);
