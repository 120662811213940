import cardStyle from "../../assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";
import cardImagesStyles from "../../assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";

const communicationToDoCardStyles = {
    ...cardStyle,
    ...cardImagesStyles,
    toDoCard : {
        marginTop: 0,
        marginBottom: 20
    },
    toDoCardBody : {
        padding: 0
    },
    toDoCardGridItem : {
        margin : 0
    },
    toDoCardPaddingLeft : {
        padding: "0 0 0 10px"
    },
    toDoCardPaddingRight : {
        padding: "0 10px 0 0"
    },
    toDoCardParagraph : {
        margin: "5px 0 0 0",
        fontSize: 10
    },
    toDoCardFloatRight : {
        float: "right"
    },
    daysLeftToReply : {
        float: "right",
        width: 14,
        height: 14,
        marginLeft: 5,
        marginTop: 3
    },
    toDoDescription : {
        fontSize: "1em",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginTop: 0,
        color: "#000"
    },
    toDoAction : {
        float: "left",
        padding: 0,
        fontSize: 9,
        margin: "5px 0 5px 0"
    },
    toDoActionSeparator : {
        margin : 0
    },
    loadingWheel : {
        position: 'absolute',
        marginLeft: -59
    }
};

export default communicationToDoCardStyles;