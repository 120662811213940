import * as actionTypes from '../actions/actionTypes';

const initialState = {
    registers: {},
    registersLoading : {},
    registersErrors : {},
    details : {},
    detailsLoading : false,
    detailsErrors : null,
    preview : {},
    previewLoading : {},
    previewError : {},
    createId : {},
    createLoading : {},
    createError : {},
    sendLoading : {},
    sendError : {},
    sendDownloadLoading : {},
    sendDownloadError : {},
    defects : {},
    defectsLoading : {},
    defectsErrors : {},
    recipients : {},
    recipientsLoading : {},
    recipientsErrors : {},
    deleteRecipientLoading : false,
    deleteRecipientErrors : null,
    defaultRecipients : [],
    defaultRecipientsLoading : false,
    defaultRecipientsErrors : null,
};

//START : Communication Register 

const getRegisterDataStart = (state, action) => {
    return { 
        ...state, 
        registersLoading : {
            ...state.registersLoading, [action.id] : true
        }
    };
}

const getRegisterDataSuccess = (state, action) => {
    return { 
        ...state, 
        registers : {
            ...state.registers, [action.id] : action.data
        },
        registersLoading : {
            ...state.registersLoading, [action.id] : false
        }
    };
}

const getRegisterDataFail = (state, action) => {
    return { 
        ...state, 
        registersErrors : {
            ...state.registersErrors, [action.id] : action.error
        },
        registersLoading : {
            ...state.registersLoading, [action.id] : false
        }
    };
}

//END : Communication Register 

//START : Communication Details

const getDetailsStart = (state, action) => {
    return { 
        ...state,
        detailsLoading : true
    };
}

const getDetailsSuccess = (state, action) => {
    return { 
        ...state, 
        details : action.data,
        detailsErrors : null,
        detailsLoading : false
    };
}

const getDetailsFail = (state, action) => {
    return { 
        ...state, 
        details : null,
        detailsErrors : action.error,
        detailsLoading : false
    };
}

//END : Communication Details

//START : Communication Preview

const previewCommunicationStart = (state, action) => {
    return { 
        ...state,
        previewLoading : {
            ...state.previewLoading, 
            [action.id] : true
        }
    };
}

const previewCommunicationSuccess = (state, action) => {
    return { 
        ...state, 
        preview : {
            ...state.preview,
            [action.id] : action.data
        },
        previewError : {
            ...state.previewError,
            [action.id] : null
        },
        previewLoading : {
            ...state.previewLoading, 
            [action.id] : false
        }
    };
}

const previewCommunicationFail = (state, action) => {
    return { 
        ...state, 
        preview : {
            ...state.preview,
            [action.id] : null
        },
        previewError : {
            ...state.previewError,
            [action.id] : action.error
        },
        previewLoading : {
            ...state.previewLoading, 
            [action.id] : false
        }
    };
}

//END : Communication Preview

//START : Communication Create

const createCommunicationStart = (state, action) => {
    return { 
        ...state,
        createLoading : {
            ...state.createLoading, 
            [action.id] : true
        }
    };
}

const createCommunicationSuccess = (state, action) => {
    return { 
        ...state, 
        createId : {
            ...state.createId,
            [action.id] : action.data
        },
        createError : {
            ...state.createError,
            [action.id] : null
        },
        createLoading : {
            ...state.createLoading, 
            [action.id] : false
        }
    };
}

const createCommunicationFail = (state, action) => {
    return { 
        ...state, 
        createId : {
            ...state.createId,
            [action.id] : null
        },
        createError : {
            ...state.createError,
            [action.id] : action.error
        },
        createLoading : {
            ...state.createLoading, 
            [action.id] : false
        }
    };
}

//END : Communication Preview

//START : Communication Send

const sendCommunicationStart = (state, action) => {
    return { 
        ...state,
        sendLoading : {
            ...state.sendLoading, 
            [action.id] : true
        }
    };
}

const sendCommunicationSuccess = (state, action) => {
    return { 
        ...state, 
        sendError : {
            ...state.sendError,
            [action.id] : null
        },
        sendLoading : {
            ...state.sendLoading, 
            [action.id] : false
        }
    };
}

const sendCommunicationFail = (state, action) => {
    return { 
        ...state, 
        sendError : {
            ...state.sendError,
            [action.id] : action.error
        },
        sendLoading : {
            ...state.sendLoading, 
            [action.id] : false
        }
    };
}

//END : Communication Send

//START : Communication Send Download

const sendDownloadCommunicationStart = (state, action) => {
    return { 
        ...state,
        sendDownloadLoading : {
            ...state.sendDownloadLoading, 
            [action.id] : true
        }
    };
}

const sendDownloadCommunicationSuccess = (state, action) => {
    return { 
        ...state, 
        sendDownloadError : {
            ...state.sendDownloadError,
            [action.id] : null
        },
        sendDownloadLoading : {
            ...state.sendDownloadLoading, 
            [action.id] : false
        }
    };
}

const sendDownloadCommunicationFail = (state, action) => {
    return { 
        ...state, 
        sendDownloadError : {
            ...state.sendDownloadError,
            [action.id] : action.error
        },
        sendDownloadLoading : {
            ...state.sendDownloadLoading, 
            [action.id] : false
        }
    };
}

//END : Communication Send Download

//START : Communication Defects

const getDefectsStart = (state, action) => {
    return {
        ...state,
        defectsLoading : {
            ...state.defectsLoading, [action.id] : true
        }
    };
}

const getDefectsSuccess = (state, action) => {
    return {
        ...state,
        defects : {
            ...state.defects, [action.id] : action.data
        },
        defectsErrors : {
            ...state.defectsErrors, [action.id] : null
        },
        defectsLoading : {
            ...state.defectsLoading, [action.id] : false
        }
    };
}

const getDefectsFail = (state, action) => {
    return {
        ...state,
        defectsErrors : {
            ...state.defectsErrors, [action.id] : action.error
        },
        defectsLoading : {
            ...state.defectsLoading, [action.id] : false
        }
    };
}

//END : Communication Defects

//START : Communication Recipients

const getRecipientsStart = (state, action) => {
    return {
        ...state,
        recipientsLoading : {
            ...state.recipientsLoading, [action.id] : true
        }
    };
}

const getRecipientsSuccess = (state, action) => {
    return {
        ...state,
        recipients : {
            ...state.recipients, [action.id] : action.data
        },
        recipientsErrors : {
            ...state.recipientsErrors, [action.id] : null
        },
        recipientsLoading : {
            ...state.recipientsLoading, [action.id] : false
        }
    };
}

const getRecipientsFail = (state, action) => {
    return {
        ...state,
        recipientsErrors : {
            ...state.recipientsErrors, [action.id] : action.error
        },
        recipientsLoading : {
            ...state.recipientsLoading, [action.id] : false
        }
    };
}

//END : Communication Recipients

//START : Communication Recipient Delete

const deleteRecipientStart = (state, action) => {
    return {
        ...state,
        deleteRecipientLoading : true
    };
}

const deleteRecipientSuccess = (state, action) => {
    return {
        ...state,
        deleteRecipientErrors : null,
        deleteRecipientLoading : false
    };
}

const deleteRecipientFail = (state, action) => {
    return {
        ...state,
        deleteRecipientErrors : action.error,
        deleteRecipientLoading : false
    };
}

//END : Communication Recipient Delete

//START :  Communication Default Recipients

const getDefaultRecipientsStart = (state, action) => {
    return {
        ...state,
        defaultRecipientsLoading : true
    };
}

const getDefaultRecipientsSuccess = (state, action) => {
    return {
        ...state,
        defaultRecipients : action.data,
        defaultRecipientsErrors : null,
        defaultRecipientsLoading : false,
    };
}

const getDefaultRecipientsFail = (state, action) => {
    return {
        ...state,
        defaultRecipients : null,
        defaultRecipientsErrors : action.error,
        defaultRecipientsLoading : false
    };
}

//END : Communication Default Recipients

//Reducer
const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_COMMUNICATION_REGISTER_START: return getRegisterDataStart(state, action);
        case actionTypes.GET_COMMUNICATION_REGISTER_SUCCESS: return getRegisterDataSuccess(state, action);
        case actionTypes.GET_COMMUNICATION_REGISTER_FAIL: return getRegisterDataFail(state, action);
        case actionTypes.GET_COMMUNICATION_DETAILS_START: return getDetailsStart(state, action);
        case actionTypes.GET_COMMUNICATION_DETAILS_SUCCESS: return getDetailsSuccess(state, action);
        case actionTypes.GET_COMMUNICATION_DETAILS_FAIL: return getDetailsFail(state, action);
        case actionTypes.PREVIEW_COMMUNICATION_START: return previewCommunicationStart(state, action);
        case actionTypes.PREVIEW_COMMUNICATION_SUCCESS: return previewCommunicationSuccess(state, action);
        case actionTypes.PREVIEW_COMMUNICATION_FAIL: return previewCommunicationFail(state, action);
        case actionTypes.CREATE_COMMUNICATION_START: return createCommunicationStart(state, action);
        case actionTypes.CREATE_COMMUNICATION_SUCCESS: return createCommunicationSuccess(state, action);
        case actionTypes.CREATE_COMMUNICATION_FAIL: return createCommunicationFail(state, action);
        case actionTypes.SEND_COMMUNICATION_START: return sendCommunicationStart(state, action);
        case actionTypes.SEND_COMMUNICATION_SUCCESS: return sendCommunicationSuccess(state, action);
        case actionTypes.SEND_COMMUNICATION_FAIL: return sendCommunicationFail(state, action);
        case actionTypes.SEND_DOWNLOAD_COMMUNICATION_START: return sendDownloadCommunicationStart(state, action);
        case actionTypes.SEND_DOWNLOAD_COMMUNICATION_SUCCESS: return sendDownloadCommunicationSuccess(state, action);
        case actionTypes.SEND_DOWNLOAD_COMMUNICATION_FAIL: return sendDownloadCommunicationFail(state, action);
        case actionTypes.GET_DEFECTS_START: return getDefectsStart(state, action);
        case actionTypes.GET_DEFECTS_SUCCESS: return getDefectsSuccess(state, action);
        case actionTypes.GET_DEFECTS_FAIL: return getDefectsFail(state, action);
        case actionTypes.GET_RECIPIENTS_START: return getRecipientsStart(state, action);
        case actionTypes.GET_RECIPIENTS_SUCCESS: return getRecipientsSuccess(state, action);
        case actionTypes.GET_RECIPIENTS_FAIL: return getRecipientsFail(state, action);
        case actionTypes.DELETE_RECIPIENT_START: return deleteRecipientStart(state, action);
        case actionTypes.DELETE_RECIPIENT_SUCCESS: return deleteRecipientSuccess(state, action);
        case actionTypes.DELETE_RECIPIENT_FAIL: return deleteRecipientFail(state, action);
        case actionTypes.GET_DEFAULT_RECIPIENTS_START: return getDefaultRecipientsStart(state, action);
        case actionTypes.GET_DEFAULT_RECIPIENTS_SUCCESS: return getDefaultRecipientsSuccess(state, action);
        case actionTypes.GET_DEFAULT_RECIPIENTS_FAIL: return getDefaultRecipientsFail(state, action);
        default: return state;
    }
};
export default reducer;