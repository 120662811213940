import * as actionTypes from './actionTypes';
import axiosInstance from "../../axiosInstance";
import * as actionCreators from "./success";

//START : Send Time Bar Notification

const sendTimeBarNotificationStart = (id) => {
    return {
        type: actionTypes.SEND_TIME_BAR_NOTIFICATION_START,
        id: id
    };
};

const sendTimeBarNotificationFail = (error, id) => {
    return {
        type: actionTypes.SEND_TIME_BAR_NOTIFICATION_FAIL,
        error: error,
        id: id
    };
};

const sendTimeBarNotificationSuccess = (id) => {
    return {
        type: actionTypes.SEND_TIME_BAR_NOTIFICATION_SUCCESS,
        error: null,
        id: id
    };
};

export const sendTimeBarNotification = (payload) => {
    return (dispatch) => {
        dispatch(sendTimeBarNotificationStart(payload.id));
        return axiosInstance.post("/api" + payload.url, payload.data)
            .then(() => {
                dispatch(sendTimeBarNotificationSuccess(payload.id));
            })
            .catch(error => {
                dispatch(sendTimeBarNotificationFail(error, payload.id));
                throw error;
            });
    };
};

//END : Send Time Bar Notification

//START : Acknowledge ToDo

const acknowledgeToDoStart = (id) => {
    return {
        type: actionTypes.ACKNOWLEDGE_TODO_START,
        id: id
    };
};

const acknowledgeToDoFail = (error, id) => {
    return {
        type: actionTypes.ACKNOWLEDGE_TODO_FAIL,
        error: error,
        id: id
    };
};

const acknowledgeToDoSuccess = (id) => {
    return {
        type: actionTypes.ACKNOWLEDGE_TODO_SUCCESS,
        error: null,
        id: id
    };
};

export const acknowledgeToDo = (payload) => {
    return (dispatch) => {
        dispatch(acknowledgeToDoStart(payload.id));
        return axiosInstance.post("/api" + payload.url, null, { params : payload.params })
            .then(() => {
                dispatch(acknowledgeToDoSuccess(payload.id));
                dispatch(actionCreators.setSuccessMessage('Receipt submitted'));
            })
            .catch(error => {
                dispatch(acknowledgeToDoFail(error, payload.id));
                throw error;
            });
    };
};

//END : Acknowledge ToDo