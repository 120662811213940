import fullScreenDialogStyles from "./fullScreenDialogStyles";

const communicationRevisionDetailsStyles = theme =>  ({
    ...fullScreenDialogStyles,
    // divFloatRight : {
    //     [theme.breakpoints.up('sm')]: {
    //         float: "right"
    //     }
    // },
    marginTopOfEl : {
        marginTop: 15
    },
    buttonAlign : {
        [theme.breakpoints.down("lg")]: {
            marginRight: 10
        },
        [theme.breakpoints.down("md")]: {
            marginRight: 10
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 10
        },
        [theme.breakpoints.down("xs")]: {
            marginRight: 0
        }
    },
    buttonWrap : {
        [theme.breakpoints.down("xs")]: {
            whiteSpace: "pre-wrap",
        }
    },
});
export default communicationRevisionDetailsStyles;
