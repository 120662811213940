import React from "react"
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody.jsx";
import { cardTitle } from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import cardImagesStyles from "../../../../assets/jss/material-dashboard-pro-react/cardImagesStyles";
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import dashboardStyle from "../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { daysArray,  getCurrentDate, monthsArray } from "../../../../globals";
import {  getApiRoot } from "../../../../api";
import { withRouter} from 'react-router-dom';
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";

const styles = theme => ({
    ...cardImagesStyles, ...customSelectStyles, ...dashboardStyle, ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    textWhite: {
        "&, & *": {
            color: "#FFF"
        }
    }
});


class NoticeRegister extends React.Component {

    constructor(props) {
        super(props);
        this.routeViewEventNotice = this.routeViewEventNotice.bind(this);
        this.routeToAddEventRegister = this.routeToAddEventRegister.bind(this);
        this.state = {

            days : daysArray(),
            months : monthsArray(),

            error: null,
            isLoaded: false,

            compensationEventNotifications: [],

            headers : [
                { label: 'CEN #', key: 'cenNumber' },
                { label: 'Clause #', key: 'clauseNumber' },
                { label: 'Event Description', key: 'description' },
                { label: 'Status', key: 'eventState.description' },
                { label: 'Remaining period for reply', key: 'eventState.actionPeriod' },
            ],
        };
    }

    //event for button click add event notice
    routeToAddEventRegister(){
        let path = '/app/addNotice';
        this.props.history.push({
            pathname: path
        });
    }

    // event for specific event notice clicked
    routeViewEventNotice(rowData) {
        let path = '/app/viewNotice';
        this.props.history.push({
            pathname: path,
            state: {
                id: rowData.id,
            }
        });
    }

    //function to calculate time to reply
    getTimeToReply = (eventNoticeId) => {

        let eventNotice = this.state.compensationEventNotifications.find(o => o.id === eventNoticeId);
        let currentDate = new Date(getCurrentDate() + " 23:59:59");
        let actionDays = 0;

        if(eventNotice.actionPeriodDate !== null){

            let actionDate = new Date(eventNotice.actionPeriodDate);

            let timeDiff = actionDate.getTime() - currentDate.getTime();
            actionDays = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;

        }
        else{
            actionDays = 0;
        }
        return actionDays;
    };

    //calling API
    componentDidMount() {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/compensation_event_notifications", {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        compensationEventNotifications: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    render() {
        const {classes} = this.props;
        //loading wheel
        const { error, isLoaded } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                // start grid
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card style={{marginTop: 10}}>
                            <CardBody>
                                <ReactTable resizable={false}
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                this.routeViewEventNotice(rowInfo.row);

                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.state.compensationEventNotifications}
                                    filterable
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "CEN #",
                                            accessor: "cenNumber",
                                            maxWidth: 100
                                        },
                                        {
                                            Header: "Clause #",
                                            accessor: "clauseNumber",
                                            maxWidth: 100
                                        },
                                        {
                                            Header: "Event Description",
                                            accessor: "description",
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "eventState.description",
                                        },
                                        {
                                            Header: "Remaining Period for reply",
                                            accessor: "id",
                                            Cell: row => (
                                                <p>{this.getTimeToReply(row.value) + " day(s)"}</p>
                                            )
                                        },
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{cursor: "pointer"}}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default withRouter(withStyles(styles)(NoticeRegister));
