const defectCategoryDropdownStyles = {
    fontSize14: {
        fontSize: 14
    },
    marTop10: {
        marginTop: 10
    },
    marBtm10: {
        marginBottom: 10
    },
    fontColor: {
        color: "#616161"
    },
    listItem: {
        whiteSpace: "pre-wrap",
        height: "auto"
    }
};

export default defectCategoryDropdownStyles;