import React from 'react';
import { withRouter} from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { getApiRoot } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CustomInput from "../CustomInput/CustomInput";
import DialogActions from "@material-ui/core/DialogActions";

const getCurrentDate = () => {

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //As January is 0.
    let yyyy = today.getFullYear();

    if(dd < 10) dd = '0'+ dd;
    if(mm < 10) mm = '0'+ mm;

    return (yyyy + '-' + mm + '-' + dd);
};


class CustomDashBoardToDoItemExtension extends React.Component{

    state = {
        open: true,
        reason: "",

        days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

        loadingAcceptExtension: false,
        loadingRejectExtension: false,

        suggestDateDialog: false,

        openRequestExtensionDialog: false,
        extensionDateValue: getCurrentDate(),
        extensionTimeValue: "23:59",

        loadingRequestExtension: false,
    };

    //function that handles collapse of priority 2 information
    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    //function that routes to specific CEN
    routeToViewMore = (type) => {

        if(type === "cen"){

            let path = '/app/viewNotice';

            this.props.history.push({
                pathname: path,
                state: {
                    id: this.props.item.compensationEventNotification.id
                },
            });

        }
        else if(type === "ce"){
          let path = '/app/viewEvent';

          this.props.history.push({
            pathname: path,
            state: {
              id: this.props.item.compensationEvent.id
            },
          });
        }


    };

    onChange = (event, type) => {

        if(type === "extension"){

            const id = event.target.id;

            if(id === "extensionDateInput"){
                this.setState({
                    extensionDateValue: event.target.value
                });
            }

        }

    };

    openSuggestDateDialog = () => {
        this.setState({
            suggestDateDialog : true
        });
    };

    closeSuggestDateDialog = () => {
        this.setState({
            suggestDateDialog : false
        });
    };


    // function that handles onClick of actions (request extension)
    handleActionClick = (value) => {

        let data = {};

        if(value === 1 ){
            this.setState({
                loadingAcceptExtension: true
            });

            data = {
                id: this.props.item.extension.id,
                action: value,
            };
        }
        else if(value === 2){
            this.setState({
                loadingRejectExtension: true
            });

            data = {
                id: this.props.item.extension.id,
                action: value,
            };
        }
        else{
            this.setState({
                loadingRequestExtension: true
            });

            let concatDate = this.state.extensionDateValue + " " + this.state.extensionTimeValue;

            data = {
                id: this.props.item.extension.id,
                action: value,
                extensionDate: concatDate
            };
        }

            const headers = new Headers({
                "Content-Type": "application/json",
            });

            // setTimeout(() => {
                fetch(getApiRoot() + '/api/extension', {
                    credentials: "include",
                    method: "POST",
                    headers,
                    body: JSON.stringify(data),
                }).then(res => {

                    if(value === 1 ){
                        this.setState({
                            loadingAcceptExtension: false
                        });
                    }
                    else if(value === 2){
                        this.setState({
                            loadingRejectExtension: false
                        });
                    }
                    else{
                        this.setState({
                            loadingRequestExtension: false
                        });
                    }

                    if (res.status === 200) {
                        // alert("Submitted answer");

                       window.location.reload();

                    } else {
                        alert("Something went wrong - HTTP: " + res.status);
                    }

                }).catch(error => alert(error));
            // }, 2000)
    };




    render(){
        const { item } = this.props;
        const { loadingAcceptExtension, loadingRejectExtension, loadingRequestExtension } = this.state;
        return (
            <Card style={{marginTop: 0, marginBottom: 20}}>
                <CardBody style={{padding: 0}}>
                    <GridContainer>
                        {/* Priority 1: Number*/}
                        <GridItem xs={12} sm={12} lg={12} style={{marginBottom: 0}}>
                            {item.subType === "CEN" || item.subType === "CE"  ?

                                    <div style={{padding: "0 0 0 10px"}}>
                                    <Tooltip
                                        title={
                                            item.subType === "CEN" ? "CEN Number"
                                                : item.subType === "CE" ? "CE Number"
                                                : ""
                                        }
                                        placement="top"
                                        // classes={{ tooltip: classes.tooltip }}
                                    >
                                        <p style={{margin: "5px 0 0 0", fontSize: 10}}>
                                            {
                                                // item.extension.compensationEventNotification !== null ?
                                                //     item.extension.compensationEventNotification.cenNumber :
                                                //     item.extension.compensationEvent !== null ?
                                                //         item.extension.compensationEvent.ceNumber : ""

                                                item.subType === "CEN" ?  item.compensationEventNotification.cenNumber
                                                : item.subType === "CE"  ?  item.compensationEvent.ceNumber
                                                : ""
                                            }
                                        </p>
                                    </Tooltip>
                                </div>

                                : ""
                            }
                        </GridItem>

                        {/* Priority 1: To Do Description*/}
                        <GridItem xs={12} sm={12} lg={12} >
                            <div style={{padding: "0 10px"}}>
                                <Tooltip
                                    title={item.description}
                                    placement="top"
                                    // classes={{ tooltip: classes.tooltip }}
                                >
                                    <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}}>{item.description}</h4>
                                </Tooltip>
                            </div>
                        </GridItem>

                        {/* Priority 2 expand button */}
                        <GridItem xs={12} sm={12} lg={12}>
                            <div style={{height: 14}}>
                                <Button style={{padding: 0, margin: 0, backgroundColor: "rgb(36, 60, 155)", boxShadow: "none"}} fullWidth onClick={this.handleClick}>{this.state.open ?  <ExpandMore style={{cursor:"pointer",height: 14, width: 14}}/> : <ExpandLess style={{cursor:"pointer", height: 14, width: 14}}  />}</Button>
                            </div>
                        </GridItem>

                    </GridContainer>
                    <Collapse in={!this.state.open} timeout="auto" unmountOnExit>
                        <GridContainer style={{fontSize: 10, padding: "10px 10px 0 10px"}}>
                            {/* Priority 2: Action Buttons*/}
                            <GridItem xs={12} sm={12} lg={12} style={{padding: "0 !important"}}>
                                <div style={{position: "relative"}}>
                                    <Button size="sm" color="info" style={{float: "right"}} onClick={
                                        () => this.handleActionClick(2)
                                    }>Reject</Button>
                                    {loadingRejectExtension && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                                <div style={{position: "relative"}}>
                                    <Button size="sm" color="primary" style={{float: "right"}} onClick={
                                            () => this.handleActionClick(1)
                                    }>Accept</Button>
                                    {loadingAcceptExtension && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                                <div style={{position: "relative"}}>
                                    <Button  size="sm" simple color="primary" style={{float: "right", paddingRight: 5}} onClick={
                                            () => this.openSuggestDateDialog()
                                    }>Suggest Date</Button>
                                </div>
                            </GridItem>


                            {/* Priority 2: View specific CE / CEN / Diary */}
                            <GridItem xs={12} sm={12} lg={12}>
                                <hr style={{margin: 0}}/>
                                <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "5px 0 5px 0"}} onClick={
                                    item.subType === "CEN" ? () => this.routeToViewMore("cen")
                                    : item.subType === "CE" ? () => this.routeToViewMore("ce")
                                    : () => this.routeToViewMore("nothing")
                                }>View More</Button>
                            </GridItem>

                        </GridContainer>
                    </Collapse>
                </CardBody>

                <Dialog
                    open={this.state.suggestDateDialog}
                    onClose={() => this.closeSuggestDateDialog()}
                    aria-labelledby="suggestDateDialog"
                    // fullScreen
                    maxWidth={false}>
                    <DialogTitle id="suggestDateDialog">Suggest Date</DialogTitle>
                    <DialogContent>

                        {/* Suggested Date */}
                        <GridItem xs={12} sm={12} lg={12} >
                            <CustomInput
                                id="extensionDateInput"
                                labelText="Extended date"
                                labelProps={{
                                    shrink: true
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: this.state.extensionDateValue,
                                    onChange: event => this.onChange(event, "extension"),
                                    type: "date",
                                }}
                            />
                        </GridItem>


                    </DialogContent>

                    <DialogActions>

                        {/* Suggested Back Button */}
                        <Button color={"info"} onClick={() => this.closeSuggestDateDialog()}>
                            Back
                        </Button>

                        {/* Suggested Submit Button */}
                        <div style={{position: "relative"}}>
                            <Button type={"submit"} onClick={() => this.handleActionClick(3)} color="primary">
                                Suggest
                            </Button>
                            {loadingRequestExtension && <CircularProgress size={24} style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12
                            }}/>}
                        </div>

                    </DialogActions>
                </Dialog>


            </Card>

        );
    }
}


export default withRouter(CustomDashBoardToDoItemExtension);