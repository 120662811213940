import communicationClauseStyles from "./communicationClauseStyles";

const  radioGroupAccordionStyles = {
    ...communicationClauseStyles,
    gridItemMar15 : {
        marginTop: "15px",
        marginBottom: "15px"
    },
    card : {
        backgroundColor: "#ffffff",
        minWidth: "250px"
    },
    marZero : {
        margin: 0
    },
};

export default radioGroupAccordionStyles;