import React, { Component, /*Fragment,*/ /*createRef*/ } from 'react';
import { withStyles } from '@material-ui/core/styles';
import communicationDetailedPageStyles from "../../../../assets/styles/communicationDetailedPageStyles";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card";
import SubmissionRevisions from "../../../../components/Communications/Submissions/SubmissionRevisions";
import { withRouter } from 'react-router-dom';
import { firstToUpper, formatShortDate } from "../../../../globals";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import Add from "@material-ui/icons/Add";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import SubmissionCreateForm from "../../../../components/Communications/Submissions/SubmissionCreateForm";
import AttachmentsTable from "../../../../components/Table/AttachmentsTable";
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';
import RadioGroupAccordion from "../../../../components/Accordion/RadioGroupAccordion";

class SubmissionDetailedPage extends Component {

    //set state to be used
    state = {
        openSubmitCollapse: false
    };

    //handle open and close of accordion
    handleToggleCollapse = () => {
        this.setState(prevState => ({ openSubmitCollapse: !prevState.openSubmitCollapse }));
    };

    //only generate certain fields to display
    validateFieldType = (item) => {
        // return (item.type !== "email" && item.type !== "contract" && item.type !== "attachment");
        return (item.type !== "email" && item.type !== "contract" && item.type !== "attachment");
    };

    //find the latest revision by finding the item in the array with the largest id
    findLatestRevision = (arr) => {
        arr.sort((a, b) => b.id - a.id);
        return 0;
    };

    getCurrentRevision = () => {
        return this.props.details[this.props.location.state.type + "Revisions"].length;
    };

    getCurrentDateForResponse = () => {
        let index = this.props.details[this.props.location.state.type + "Revisions"] && this.props.details[this.props.location.state.type + "Revisions"].length > 0 ? this.findLatestRevision(this.props.details[this.props.location.state.type + "Revisions"]) : -1;
        return index !== -1 ? formatShortDate(this.props.details[this.props.location.state.type + "Revisions"][index]["periodOfReplyDate"]) : "N/A";
    };

    //dynamically generate state based on clause fields
    generateState = (item, data) => {
        if (this.validateFieldType(item)) {
            this.setState({
                [item.field]: data[item.field],
                [item.field + "StateLoaded"]: true
            });
        }
    };

    //function that checks if all state has been set
    stateLoaded = () => {
        let loaded = true;
        for (let i = 0; i < this.props.location.state.clauseData.dataFields.length; i++) {

            let item = this.props.location.state.clauseData.dataFields[i];

            if ((!item.revisionField && this.props.location.state.clauseData.hasRevisions) || !this.props.location.state.clauseData.hasRevisions) {
                if (this.validateFieldType(item)) {
                    if (this.state[item.field + "StateLoaded"] !== true) {
                        loaded = false;
                    }
                }
            }
        }
        return loaded;
    };

    //dynamically generate state based on clause fields
    generateComponent = (item, key) => {
        if (item.type === "attachment") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <p><strong>Attachments</strong></p>
                    <AttachmentsTable attachments={this.props.details[item.field]} />
                </GridItem>
            );
        }
        else if(item.type === "clauseRadio") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} style={{marginTop: -20}}>
                    <RadioGroupAccordion
                        clauseData={item}
                        radioData={this.props.details[item.field] && this.props.details[item.field].length !== 0 ? this.props.details[item.field] : []}
                        isDetails={true}
                    />
                </GridItem>
            );
        }
        else {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <p><strong>{item.labelText}: </strong> {this.props.details[item.field] && this.props.details[item.field] !== "" ? this.props.details[item.field] : "None"}</p>
                </GridItem>
            );
        }
    };

    //get the fields to use in the submit of a new revision
    getFieldsAPI = () => {
        const data = this.props.details;

        this.props.location.state.clauseData.dataFields.forEach((item) => {
            if ((!item.revisionField && this.props.location.state.clauseData.hasRevisions) || !this.props.location.state.clauseData.hasRevisions) {
                this.generateState(item, data);
            }
        });
    };

    getSubmitFormPermission = () => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            //submission to be replaced with this.props.type
            const permissionObject = this.props.permissions.find(o => o.type === (this.props.location.state.type + "Revision") && o.subtype === "create");
            if(permissionObject && permissionObject.permission === "true"){
               return true;
            }
        }
        return false;
    };

    shouldSubmitFormShow = () => {
        let revisionsArray = this.props.details[this.props.location.state.type + "Revisions"];
        let showFormPermission = false;
        let showFormState = false;

        if(revisionsArray && revisionsArray.length !== 0){
            const latestRevision = revisionsArray[this.findLatestRevision(revisionsArray)];
            showFormState = this.props.location.state.clauseData.hasRevisions && latestRevision.status && latestRevision.status.toLowerCase() === "rejected";
            showFormPermission = this.getSubmitFormPermission();
        }
    
        return showFormPermission && showFormState;
    };

    shouldRespondActionsShow = () => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            //submission to be replaced with this.props.location.state.type
            const permissionObject = this.props.permissions.find(o => o.type === (this.props.location.state.type + "Revision") && o.subtype === "response");
            if(permissionObject && permissionObject.permission === "true"){
               return true;
            }
        }
        return false;
    };

    componentDidMount() {
        const payload = {
            url: this.props.location.state.apiEndpoints.details + "/" + this.props.location.state.id,
        };

        this.props.onGetDetailsAndPermissions(payload).then(() => {
            this.getFieldsAPI();
        });

        
    }
    render() {
        const { classes, permissionsLoading, permissionsError, detailsErrors, detailsLoading, details, location } = this.props;

        const smallFontNoMargin = classes.smallFont;
        // const smallFontNoMarginFloatRight = classes.smallFont + " " + classes.floatRight;
        const loadingWheelIncludingDefault = classes.progress + " " + classes.loadingWheel;

        //display fallback ui if there is an error on the page
        if (permissionsError || detailsErrors) {
            return null;
        }
        else if (detailsLoading || permissionsLoading) {
            //Loading wheel for initial API request
            return (
                <GridContainer>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                        <CircularProgress color={"primary"} className={loadingWheelIncludingDefault} />
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                this.stateLoaded() && <GridContainer justify={"center"}>

                    {/* Main Container*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignFirstCard}>

                        <Card className={classes.infoCard}>
                            <GridContainer>

                                {/* Clause Number */}
                                <GridItem xs={12} sm={12} lg={12}>
                                    <p className={smallFontNoMargin}>Clause {details.clause} : {firstToUpper(location.state.type) + " " + details[location.state.type + "NumberInteger"]}</p>
                                </GridItem>

                                {/*/ Clause Title /*/}
                                {/* <GridItem xs={12} sm={6} lg={6}>
                                    <p className={smallFontNoMarginFloatRight}>{location.state.clauseData.clauseTitle}</p>
                                </GridItem> */}

                                 {/* Generate fields that could differ per clause */}
                                 {location.state.clauseData.dataFields.map((item, key) => {
                                    if (location.state.clauseData.hasRevisions && (!item.revisionField || item.displayInitialDetails) && item.type !== "clauseRadio") {
                                        if (this.validateFieldType(item)) {
                                            return this.generateComponent(item, key);
                                        }
                                    }
                                    return null;
                                })}

                                 {/* Date Created */}
                                <GridItem xs={12} sm={12} lg={12}>
                                    <p><strong>Date Submitted: </strong> {details.dateCreated !== null ? formatShortDate(details.dateCreated) : ""}</p>
                                </GridItem>

                                {/* Current Revision */}
                                {location.state.clauseData.hasRevisions && details[location.state.type + "Revisions"] && details[location.state.type + "Revisions"].length > 0 ?
                                <GridItem xs={12} sm={12} lg={12}>
                                    <p><strong>Current Revision:</strong> {this.getCurrentRevision()}</p>
                                </GridItem>
                                : null}

                                {/* Date for response */}
                                {location.state.clauseData.hasRevisions && details[location.state.type + "Revisions"] && details[location.state.type + "Revisions"].length !== 0 ?
                                <GridItem xs={12} sm={6} lg={6}>
                                    <p><strong>Current date for response: </strong> {this.getCurrentDateForResponse()} </p>
                                </GridItem> : null}

                                {location.state.clauseData.dataFields.map((item, key) => {
                                    if (location.state.clauseData.hasRevisions && (!item.revisionField || item.displayInitialDetails) && item.type === "clauseRadio") {
                                        if (this.validateFieldType(item)) {
                                            return this.generateComponent(item, key);
                                        }
                                    }
                                    return null;
                                })}
                            </GridContainer>
                        </Card>

                        {/* Show submit new revision dropdown */}
                        {this.shouldSubmitFormShow() ?
                            <GridContainer>
                                <GridItem xs={12} sm={12} lg={12}>
                                    <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse()}>
                                        <CardBody>
                            <p className={classes.collapseParagraph}><Add style={{ marginBottom: -3, fontSize: 16 }} /> {location.state.clauseData.displayData.submit.revision}</p>
                                            <Button className={classes.collapseButton} fullWidth>{!this.state.openSubmitCollapse ? <ExpandMore className={classes.collapseIcon} /> : <ExpandLess className={classes.collapseIcon} />}</Button>
                                        </CardBody>
                                    </Card>

                                    {/* content of accordion */}
                                    <Collapse in={this.state.openSubmitCollapse} timeout="auto" unmountOnExit>
                                        <SubmissionCreateForm
                                            type={location.state.type}
                                            clauseData={location.state.clauseData}
                                            apiEndpoints={location.state.apiEndpoints}
                                            isRevision={true}
                                            initialData={details}
                                            id={location.state.id}
                                        />
                                    </Collapse>
                                </GridItem>
                            </GridContainer>
                            : null}

                        {/* show details */}
                        {details[this.props.location.state.type + "Revisions"] && details[this.props.location.state.type + "Revisions"].length !== 0 ? 
                            details[this.props.location.state.type + "Revisions"].sort((a, b) => b.id - a.id).map((revisionItem, revisionIndex) => {
                                
                                let openCollapse = revisionIndex === 0;
                                return <SubmissionRevisions 
                                revisionData={revisionItem} 
                                open={openCollapse} 
                                key={revisionIndex} 
                                type={location.state.type} 
                                revisionNumber={details[this.props.location.state.type + "Revisions"].length - revisionIndex}
                                apiEndpoints={location.state.apiEndpoints}
                                clauseData={location.state.clauseData}
                                communicationId={location.state.id}
                                allowedToRespond={this.shouldRespondActionsShow()} />
                           
                            }) : null}

                    </GridItem>
                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.userReducer.permissions,
        permissionsLoading: state.userReducer.permissionsLoading,
        permissionsError: state.userReducer.permissionsError,
        details: state.communicationReducer.details,
        detailsLoading: state.communicationReducer.detailsLoading,
        detailsErrors: state.communicationReducer.detailsErrors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetDetailsAndPermissions: (payload) => dispatch(actionCreators.getPermissionsAndDetails(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationDetailedPageStyles)(SubmissionDetailedPage)));
