import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import communicationTabStyles from "../../../../assets/styles/communicationTabStyles";
import NotificationClause from '../../../../components/Communications/Notifications/NotificationClause';
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';
import PropTypes from 'prop-types';

const Notifications = (props) => {

    const { classes, permissionsError, permissionsLoading, permissions, clauses, type, /*roleError,*/ roleLoading, role } = props;

    const shouldAccordionShow = (clauseNumber) => {
        // let createPermission = false;
        // let readPermission = false;

        // if(permissions && permissions.length !== 0){
        //     //submission to be replaced with this.props.type
        //     const createPermissionObject = permissions.find(o => o.type === "submission" && o.subtype === "create");
        //     if(createPermissionObject && createPermissionObject.permission === "true"){
        //        createPermission = true;
        //     }

        //     const readPermissionObject = permissions.find(o => o.type === "submission" && o.subtype === "read");
        //     if(readPermissionObject && readPermissionObject.permission === "true"){
        //         readPermission = true;
        //     }
        // }
        // return (createPermission || readPermission);
        return true;
    };

    useEffect(() => {
        props.onGetPermissions();
        // props.onGetRole();
    }, []);

    if(permissionsError /*|| roleError*/) {
        return null;
    }
    else if (permissionsLoading || roleLoading) {
        //Loading wheel for initial API request
        return (
            <div>
                <CircularProgress size={24} color={"primary"} className={classes.loadingWheel}/>
            </div>
        );
    }
    else{
        return(
            <div>
                {clauses.clauseData && clauses.clauseData.length !==0 ?  clauses.clauseData.map((item, key) => //generate clause dropdown for each in the data
                    shouldAccordionShow(item.clauseNumber) ? <NotificationClause 
                        key={key} 
                        clauseData={item} 
                        apiEndpoints={clauses.apiUrls} 
                        type={type} 
                        role={role}
                        permissions={permissions} /> : null//ref={element => this.myRef[key] = element}
                ) : ""}
            </div>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        permissions: state.userReducer.permissions,
        permissionsLoading: state.userReducer.permissionsLoading,
        permissionsError: state.userReducer.permissionsError,
        role : state.userReducer.role,
        roleLoading : state.userReducer.roleLoading,
        roleError : state.userReducer.roleError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetPermissions : () => dispatch(actionCreators.getPermissionsIfNeeded()),
        onGetRole : () => dispatch(actionCreators.getRole())
    };
};

Notifications.propTypes = {
    type : PropTypes.string.isRequired,
    clauses : PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(communicationTabStyles)(Notifications));