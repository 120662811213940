const communicationRevisionsAccordionStyles = {

    collapseCard: {
        cursor:"pointer",
        margin: "10px 0 0 0",
    },
    otherCard : {
        margin: "30px 0 0 0",
    },
    collapseParagraph: {
        margin: 0,
        display: "inline"
    },
    collapseIcon : {
        color: "#000"
    },
    collapseButton:{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(0, 0, 0 ,0)",
        boxShadow: "none",
        float: "right",
        width: "fit-content"
    },
    gridItem:{
        margin: 0
    },
    botMargin : {
        marginBottom : 30
    },
    loadingWheel : {
        position: 'absolute',
        top: '45%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    floatRight : {
        float : "right"
    },
    lessPadding : {
      padding : "0 5px"
    },
    verticalAlignContainer : {
        display: "table",

    },
    verticalAlignElement : {
        display: "table-cell", verticalAlign: "middle"
    },
    noMargin : {
        margin : 0
    },
    cardMargin : {
        marginTop : 15
    },
    italic : {
        fontStyle: "italic"
    },
    marginRight : {
        marginRight: 10
    },
    marginBtm15 : {
        marginBottom: 15
    },
    noMaxWidth : {
        maxWidth: "none"
    },
    displayResponseText : {
        float: "left",
        padding: "0 20px"
    },
    lineHgt51 : {
        lineHeight: "51px"
    }
};
export default communicationRevisionsAccordionStyles;
