import React, {Component} from "react";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import AddComment from "@material-ui/icons/AddComment";
import Comment from "@material-ui/icons/Comment";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import * as PropTypes from "prop-types";
import Button from "../CustomButtons/Button";
import CustomInput from "./CustomInput";
import GridContainer from "../Grid/GridContainer";
// styles
import radioGroupInputStyles from "../../assets/styles/radioGroupInputStyles";
import {replaceSubStringWithItalics} from "../../globals";

class RadioGroupInput extends Component {
    state = {
        open : false,
    };

    // method to open and close the comments dialog
    onOpenAndCloseDialog = () => {
        this.setState(state => ({ open: !state.open }));
    };

    // method to set the initial color of the comment icon based on the returned data
    setInitialValue = (value) => {
        if (value !== "")
            return "primary";
        else
            return "default"
    };

    render() {
        const { open } = this.state;
        const { classes, clauseData, dataItem, index, isDetails } = this.props;
        return (
            <GridItem xs={12} sm={12} lg={12}>
                    <Card className={classes.card}>
                        <CardBody>
                                {
                                    // Test to see whether the requested page is for rendering in response details
                                    (isDetails) ?
                                        // If true render the following content based on the data received
                                        <GridContainer>
                                            {/* Display the description of the reason*/}
                                            <GridItem xs={6} sm={8} lg={9} className={classes.selfAlignCenter}>
                                                <p className={classes.marZero}>
                                                    {replaceSubStringWithItalics(dataItem.reason)}
                                                </p>
                                            </GridItem>

                                            {/* Display the choice that was selected (radiobutton)*/}
                                            <GridItem xs={4} sm={3} lg={2}
                                                      className={classes.selfAlignCenter + " " + classes.marLeft10}>
                                                <p className={classes.marZero}>
                                                    Answer: <b>{dataItem.choice}</b>
                                                </p>
                                            </GridItem>

                                            {/* Display the comment icon if there were any comments made*/}
                                            {
                                                (dataItem.comments === "") ?
                                                    <React.Fragment />
                                                        :
                                                    <GridItem xs={1} sm={1} lg={1}
                                                              className={classes.selfAlignCenter + " " + classes.marLeft20}>

                                                        <label htmlFor="icon-button-file">
                                                            <IconButton onClick={this.onOpenAndCloseDialog}
                                                                        color={this.setInitialValue(dataItem.comments)}>
                                                                <Comment/>
                                                            </IconButton>
                                                        </label>

                                                        <Dialog open={open}
                                                                onClose={this.onOpenAndCloseDialog}
                                                                fullWidth={true}
                                                        >
                                                            <DialogContent>
                                                                <CustomInput
                                                                    labelText={"Comments"}
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                        multiline: true,
                                                                        defaultValue: dataItem.comments,
                                                                        type: "text",
                                                                    }}
                                                                    readOnly={true}
                                                                />
                                                                <DialogActions>
                                                                    <Button className={classes.marginTopOfEl}
                                                                            onClick={this.onOpenAndCloseDialog}
                                                                            color="info">Close</Button>
                                                                </DialogActions>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </GridItem>
                                            }
                                        </GridContainer>

                                        :

                                        // If false render a component containing blank fields
                                        <GridContainer>
                                            {/* Display the description of the reason*/}
                                            <GridItem xs={7} sm={6} lg={8} className={classes.selfAlignCenter}>
                                                <p className={classes.marZero}>
                                                    {replaceSubStringWithItalics(dataItem.reason)}
                                                </p>
                                            </GridItem>

                                            {/* Display radio group for user to make a choice (Yes, No, NA)*/}
                                            <GridItem xs={3} sm={5} lg={3}
                                                      className={classes.selfAlignCenter + " " + classes.marLeft10}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={4} lg={4}>
                                                        <FormControlLabel
                                                            className={classes.textAlignCenter}
                                                            control={
                                                                <Radio
                                                                    checked={dataItem.choice.toLowerCase() === "yes"}
                                                                    onChange={(event) => this.props.onChange(clauseData, event, index)}
                                                                    type={"radio"}
                                                                    value="Yes"
                                                                    aria-label="Yes"
                                                                    icon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioUnchecked}/>
                                                                    }
                                                                    checkedIcon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioChecked}/>
                                                                    }
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label="Yes"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={4} lg={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={dataItem.choice.toLowerCase() === "no"}
                                                                    onChange={(event) => this.props.onChange(clauseData, event, index)}
                                                                    value="No"
                                                                    type={"radio"}
                                                                    aria-label="No"
                                                                    icon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioUnchecked}/>
                                                                    }
                                                                    checkedIcon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioChecked}/>
                                                                    }
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label="No"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={4} lg={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={dataItem.choice.toLowerCase() === "na"}
                                                                    onChange={(event) => this.props.onChange(clauseData, event, index)}
                                                                    value="NA"
                                                                    type={"radio"}
                                                                    aria-label="NA"
                                                                    icon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioUnchecked}/>
                                                                    }
                                                                    checkedIcon={
                                                                        <FiberManualRecord
                                                                            className={classes.radioChecked}/>
                                                                    }
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label="NA"
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>

                                            {/* Enable user to add comments through interacting with an icon and editing on a dialog */}
                                            <GridItem xs={1} sm={1} lg={1}
                                                      className={classes.selfAlignCenter + " " + classes.marLeft10}>

                                                <label htmlFor="icon-button-file">
                                                    <IconButton onClick={this.onOpenAndCloseDialog}
                                                                color={this.setInitialValue(dataItem.comments)}>
                                                        <AddComment/>
                                                    </IconButton>
                                                </label>

                                                <Dialog open={open}
                                                        onClose={this.onOpenAndCloseDialog}
                                                        fullWidth={true}
                                                >
                                                    <DialogContent>
                                                        <CustomInput
                                                            labelText={"Comments"}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                multiline: true,
                                                                defaultValue: dataItem.comments,
                                                                onBlur: event => this.props.onChange(clauseData, event, index),
                                                                type: "text",
                                                            }}
                                                        />
                                                        <DialogActions>
                                                            <Button className={classes.marginTopOfEl}
                                                                    onClick={this.onOpenAndCloseDialog}
                                                                    color="info">Done</Button>
                                                        </DialogActions>
                                                    </DialogContent>
                                                </Dialog>
                                            </GridItem>
                                        </GridContainer>
                                }
                        </CardBody>
                    </Card>
            </GridItem>
        );
    }
}

RadioGroupInput.propTypes = {
    onChange: PropTypes.func,
    clauseData: PropTypes.object.isRequired,
    dataItem: PropTypes.object.isRequired,
    isDetails: PropTypes.bool
};

export default withStyles(radioGroupInputStyles)(RadioGroupInput);
