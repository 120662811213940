import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import fileDownload from "js-file-download";
import axiosInstance from "../../axiosInstance";

//START : Communication Register 
const getRegisterDataStart = (id) => {
    return {
        type: actionTypes.GET_COMMUNICATION_REGISTER_START,
        id: id
    };
};

const getRegisterDataFail = (error, id) => {
    return {
        type: actionTypes.GET_COMMUNICATION_REGISTER_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const getRegisterDataSuccess = (data, id) => {
    return {
        type: actionTypes.GET_COMMUNICATION_REGISTER_SUCCESS,
        data: data,
        error: null,
        id: id
    };
};

export const getRegisterData = (payload) => {
    return (dispatch, getState) => {
        if (getState().communicationReducer.registersLoading[payload.id] !== true) {
            dispatch(getRegisterDataStart(payload.id));
            axiosInstance.get("/api" + payload.url)
                .then(response => {
                    const registerData = [];
                    for (let key in response.data) {
                        registerData.push({
                            ...response.data[key]
                        });
                    }
                    dispatch(getRegisterDataSuccess(registerData, payload.id));
                })
                .catch(error => {
                    dispatch(getRegisterDataFail(error, payload.id));
                    throw error;
                });
        }

    };
};

//END : Communication Register 

//START : Communication Details

const getDetailsStart = () => {
    return {
        type: actionTypes.GET_COMMUNICATION_DETAILS_START,
    };
};

const getDetailsFail = (error) => {
    return {
        type: actionTypes.GET_COMMUNICATION_DETAILS_FAIL,
        data: null,
        error: error
    };
};

const getDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_COMMUNICATION_DETAILS_SUCCESS,
        data: data,
        error: null
    };
};

export const getDetails = (payload) => {
    return (dispatch, getState) => {
        if (getState().communicationReducer.detailsLoading !== true) {
            dispatch(getDetailsStart());
            return axiosInstance.get("/api" + payload.url)
                .then(response => {
                    dispatch(getDetailsSuccess(response.data));
                })
                .catch(error => {
                    dispatch(getDetailsFail(error));
                    throw error;
                });
        }

    };
};

export const getPermissionsAndDetails = (payload) => {
    return (dispatch) => {
        return dispatch(actionCreators.getPermissions()).then(() => {
            return dispatch(getDetails(payload));
        });
    }
};

//END : Communication Details

//START : Communication Preview

const previewCommunicationStart = (id) => {
    return {
        type: actionTypes.PREVIEW_COMMUNICATION_START,
        id: id
    };
};

const previewCommunicationFail = (error, id) => {
    return {
        type: actionTypes.PREVIEW_COMMUNICATION_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const previewCommunicationSuccess = (data, id) => {
    return {
        type: actionTypes.PREVIEW_COMMUNICATION_SUCCESS,
        data: data,
        error: null,
        id: id
    };
};

export const previewCommunication = (payload) => {
    return (dispatch) => {
        dispatch(previewCommunicationStart(payload.id));
        return axiosInstance.post("/api" + payload.url, payload.data, {
            responseType: 'blob',
        })
            .then(response => {
                dispatch(previewCommunicationSuccess(response.data, payload.id));
            })
            .catch(error => {
                dispatch(previewCommunicationFail(error, payload.id));
                throw error;
            });
    };
};

//END : Communication Preview

//START : Communication Create

const createCommunicationStart = (id) => {
    return {
        type: actionTypes.CREATE_COMMUNICATION_START,
        id: id
    };
};

const createCommunicationFail = (error, id) => {
    return {
        type: actionTypes.CREATE_COMMUNICATION_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const createCommunicationSuccess = (data, id) => {
    return {
        type: actionTypes.CREATE_COMMUNICATION_SUCCESS,
        data: data,
        error: null,
        id: id
    };
};

export const createCommunication = (payload) => {
    return (dispatch) => {
        dispatch(createCommunicationStart(payload.id));
        return axiosInstance.post("/api" + payload.url, payload.data)
            .then(response => {
                dispatch(createCommunicationSuccess(response.data.id, payload.id));
            })
            .catch(error => {
                dispatch(createCommunicationFail(error, payload.id));
                throw error;
            });
    };
};

//END : Communication Create

//START : Communication Send

const sendCommunicationStart = (id) => {
    return {
        type: actionTypes.SEND_COMMUNICATION_START,
        id: id
    };
};

const sendCommunicationFail = (error, id) => {
    return {
        type: actionTypes.SEND_COMMUNICATION_FAIL,
        error: error,
        id: id
    };
};

const sendCommunicationSuccess = (id) => {
    return {
        type: actionTypes.SEND_COMMUNICATION_SUCCESS,
        error: null,
        id: id
    };
};

export const sendCommunication = (payload) => {
    return (dispatch) => {
        dispatch(sendCommunicationStart(payload.id));
        return axiosInstance.post("/api" + payload.url, {
                id : payload.createId
            })
            .then(() => {
                dispatch(sendCommunicationSuccess(payload.id));
            })
            .catch(error => {
                dispatch(sendCommunicationFail(error, payload.id));
                throw error;
            });
    };
};

//END : Communication Send

//START : Communication Send & Download

const sendDownloadCommunicationStart = (id) => {
    return {
        type: actionTypes.SEND_DOWNLOAD_COMMUNICATION_START,
        id: id
    };
};

const sendDownloadCommunicationFail = (error, id) => {
    return {
        type: actionTypes.SEND_DOWNLOAD_COMMUNICATION_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const sendDownloadCommunicationSuccess = (id) => {
    return {
        type: actionTypes.SEND_DOWNLOAD_COMMUNICATION_SUCCESS,
        error: null,
        id: id
    };
};

export const sendDownloadCommunication = (payload) => {
    return (dispatch) => {
        dispatch(sendDownloadCommunicationStart(payload.id));
        return axiosInstance.post("/api" + payload.url, {
                    id : payload.createId
                },{
                    responseType: 'blob',
                })
                .then(response => {
                    let fileName = "Contractly document.pdf";
                    if (response.headers['content-disposition'] !== null) {
                        let strArr = response.headers['content-disposition'].toString().split("\"");

                        if (strArr.length > 1) {
                            fileName = strArr[1];
                        }
                    }
                    dispatch(sendDownloadCommunicationSuccess(payload.id));
                    fileDownload(response.data, fileName);
                })
                .catch(error => {
                    dispatch(sendDownloadCommunicationFail(error, payload.id));
                    throw error;
                });
    };
};

//END : Communication Send & Download

//START : Communication Defects

const getDefectsStart = (id) => {
    return {
        type: actionTypes.GET_DEFECTS_START,
        id: id
    };
};

const getDefectsFail = (error, id) => {
    return {
        type: actionTypes.GET_DEFECTS_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const getDefectsSuccess = (data, id) => {
    return {
        type: actionTypes.GET_DEFECTS_SUCCESS,
        data: data,
        error: null,
        id: id
    };
};

export const getDefects = (payload) => {
    return (dispatch, getState) => {
        if (getState().communicationReducer.defectsLoading[payload.id] !== true) {
            dispatch(getDefectsStart(payload.id));
            return axiosInstance.get("/api" + payload.url)
                .then(response => {
                    dispatch(getDefectsSuccess(response.data, payload.id));
                })
                .catch(error => {
                    dispatch(getDefectsFail(error, payload.id));
                    throw error;
                });
        }
    };
};

//END : Communication Defects

//START : Communication Recipients

const getRecipientsStart = (id) => {
    return {
        type: actionTypes.GET_RECIPIENTS_START,
        id: id
    };
};

const getRecipientsFail = (error, id) => {
    return {
        type: actionTypes.GET_RECIPIENTS_FAIL,
        data: null,
        error: error,
        id: id
    };
};

const getRecipientsSuccess = (data, id) => {
    return {
        type: actionTypes.GET_RECIPIENTS_SUCCESS,
        data: data,
        error: null,
        id: id
    };
};

export const getRecipients = (payload) => {
    return (dispatch, getState) => {
        if (getState().communicationReducer.recipientsLoading[payload.id] !== true) {
            dispatch(getRecipientsStart(payload.id));
            return axiosInstance.get("/api" + payload.url)
                .then(response => {
                    dispatch(getRecipientsSuccess(response.data, payload.id));
                })
                .catch(error => {
                    dispatch(getRecipientsFail(error, payload.id));
                    throw error;
                });
        }
    };
};

//END : Communication Recipients

//START : Communication Recipient Delete

const deleteRecipientStart = () => {
    return {
        type: actionTypes.DELETE_RECIPIENT_START,
    };
};

const deleteRecipientFail = (error) => {
    return {
        type: actionTypes.DELETE_RECIPIENT_FAIL,
        error: error,
    };
};

const deleteRecipientSuccess = () => {
    return {
        type: actionTypes.DELETE_RECIPIENT_SUCCESS,
        error: null,
    };
};

export const deleteRecipient = (payload) => {
    return (dispatch) => {
        dispatch(deleteRecipientStart());
        return axiosInstance.delete("/api" + payload.url)
            .then(() => dispatch(deleteRecipientSuccess()))
            .catch(error => {
                dispatch(deleteRecipientFail(error));
                throw error;
            });
    };
};

//END : Communication Recipient Delete

//START : Communication Default Recipients

const getDefaultRecipientsStart = () => {
    return {
        type: actionTypes.GET_DEFAULT_RECIPIENTS_START,
    };
};

const getDefaultRecipientsFail = (error) => {
    return {
        type: actionTypes.GET_DEFAULT_RECIPIENTS_FAIL,
        data: null,
        error: error,
    };
};

const getDefaultRecipientsSuccess = (data) => {
    return {
        type: actionTypes.GET_DEFAULT_RECIPIENTS_SUCCESS,
        data: data,
        error: null,
    };
};

export const getDefaultRecipients = (payload) => {
    return (dispatch,getState) => {
        if (getState().communicationReducer.defaultRecipientsLoading !== true) {
            dispatch(getDefaultRecipientsStart());
            return axiosInstance.get("/api" + payload.url, { params: payload.params })
                .then(response => {
                    dispatch(getDefaultRecipientsSuccess(response.data));
                })
                .catch(error => {
                    dispatch(getDefaultRecipientsFail(error));
                    throw error;
                });
        }
    }
};

//END : Communication Default Recipients