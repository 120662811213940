
import React from "react";

const allClausesNoticeTable = {
    clauses: [
        {
            number: "61.3",
            name: <div><p>The <span style={{fontStyle: "italic"}}>Contractor</span> notifies the <span style={{fontStyle: "italic"}}>Project Manager</span> of an event which has happened or which he expects to happen as a compensation event if <br/>
                • the <span style={{fontStyle: "italic"}}>Contractor</span> believes that the event is a compensation event and <br/>
                • the <span style={{fontStyle: "italic"}}>Project Manager</span> has not notified the event to the <span style={{fontStyle: "italic"}}>Contractor</span>. <br/>
                If the <span style={{fontStyle: "italic"}}>Contractor</span> does not notify a compensation event within eight weeks of becoming aware of the event,
                he is not entitled to a change in the Prices, the Completion Date or a Key Date unless the event arises from 
                the <span style={{fontStyle: "italic"}}>Project Manager</span> or the <span style={{fontStyle: "italic"}}>Supervisor</span> giving an instruction, issuing a certificate, changing an earlier decision 
                or correcting an assumption.</p></div>,
            tooltipName: "The Contractor notifies the Project Manager of an \n" +
                "event which has happened or which he expects to happen as a compensation event if\n" +
                "• the Contractor believes that the event is a compensation event and\n"+
                "• the Project Manager has not notified the event to the Contractor.\n" +
                "If the Contractor does not notify a compensation event within eight weeks of becoming aware of the event,\n" +
                "he is not entitled to a change in the Prices, the Completion Date or a Key Date unless the event arises from \n"+
                "the Project Manager or the Supervisor giving an instruction, issuing a certificate, changing an earlier decision \n"+
                "or correcting an assumption.\n",
            subClauses: {
                data:[
                    {
                        number:"60.1(1)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction changing the Works Information except <br/>
                            • a change made in order to accept a Defect or <br/>
                            • a change to the Works Information provided by the <span style={{fontStyle: "italic"}}>Contractor</span> for his 
                            design which is made either at his request or to comply with other Works
                            Information provided by the <span style={{fontStyle: "italic"}}>Employer</span>.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction changing the Works Information except \n" +
                            "• a change made in order to accept a Defect or \n" +
                            "• a change to the Works Information provided by the Contractor for his\n" +
                            "design which is made either at his request or to comply with other Works\n" +
                            "Information provided by the Employer." ,
                    },
                    {
                        number:"60.1(2)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> does not allow access to and use of a part of the Site by the 
                            later of its access date and the date shown on the Accepted Programme.</p></div>,
                        tooltipDescription: "The Employer does not allow access to and use of a part of the Site by the \n" +
                            "later of its access date and the date shown on the Accepted Programme.\n"  ,
                    },
                    {
                        number:"60.1(3)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> does not provide something which he is to provide by the 
                            date for providing it shown on the Accepted Programme. </p></div>,
                        tooltipDescription: "The Employer does not provide something which he is to provide by the \n" +
                            "date for providing it shown on the Accepted Programme." ,
                    },
                    {
                        number:"60.1(4)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction to stop or not to start any work 
                            or to change a Key Date.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction to stop or not to start any work \n" +
                            "or to change a Key Date." ,
                    },
                    {
                        number:"60.1(5)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> or Others <br/>
                            • do not work within the times shown on the Accepted Programme,<br/>
                            • do not work within the conditions stated in the Works Information or <br/>
                            • carry out work on the Site that is not stated in the Works Information.</p></div>,
                        tooltipDescription: "The Employer or Others \n" +
                            "• do not work within the times shown on the Accepted Programme,\n" +
                            "• do not work within the conditions stated in the Works Information or \n" +
                            "• carry out work on the Site that is not stated in the Works Information." ,
                    },
                    {
                        number:"60.1(6)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> or the <span style={{fontStyle: "italic"}}>Supervisor</span> does not reply to a communication 
                            from the <span style={{fontStyle: "italic"}}>Contractor</span> within the period required by this contract.</p></div>,
                        tooltipDescription: "The Project Manager or the Supervisor does not reply to a communication \n" +
                            "from the Contractor within the period required by this contract." ,
                    },
                    {
                        number:"60.1(7)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction for dealing with an object of 
                            value or of historical or other interest found within the Site. </p></div>,
                        tooltipDescription: "The Project Manager gives an instruction for dealing with an object of \n" +
                            "value or of historical or other interest found within the Site." ,
                    },
                    {
                        number:"60.1(8)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> or the <span style={{fontStyle: "italic"}}>Supervisor</span> changes a decision which he has 
                            previously communicated to the <span style={{fontStyle: "italic"}}>Contractor</span>.</p></div>,
                        tooltipDescription: "The Project Manager or the Supervisor changes a decision which he has \n" +
                            "previously communicated to the Contractor." ,
                    },
                    {
                        number:"60.1(9)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> withholds an acceptance (other than acceptance of 
                            a quotation for acceleration or for not correcting a Defect) for a reason not 
                            stated in this contract.</p></div>,
                        tooltipDescription: "The Project Manager withholds an acceptance (other than acceptance of \n" +
                            "a quotation for acceleration or for not correcting a Defect) for a reason not \n" +
                            "stated in this contract." ,
                    },
                    {
                        number:"60.1(10)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Supervisor</span> instructs the <span style={{fontStyle: "italic"}}>Contractor</span> to search for a Defect and no 
                            Defect is found unless the search is needed only because the <span style={{fontStyle: "italic"}}>Contractor</span> gave 
                            insufficient notice of doing work obstructing a required test or inspection.</p></div>,
                        tooltipDescription: "The Supervisor instructs the Contractor to search for a Defect and no \n" +
                            "Defect is found unless the search is needed only because the Contractor gave \n" +
                            "insufficient notice of doing work obstructing a required test or inspection." ,
                    },
                    {
                        number:"60.1(11)",
                        description: <div><p>A test or inspection done by the <span style={{fontStyle: "italic"}}>Supervisor</span> causes unnecessary delay.</p></div>,
                        tooltipDescription: "A test or inspection done by the Supervisor causes unnecessary delay. "
                    },
                    {
                        number:"60.1(12)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Contractor</span> encounters physical conditions which <br/>
                            • are within the Site, <br/>
                            • are not weather conditions and <br/>
                            • an experienced <span style={{fontStyle: "italic"}}>Contractor</span> would have judged at the Contract Date to 
                            have such a small chance of occurring that it would have been unreasonable for him to have allowed for them. 
                            Only the difference between the physical conditions encountered and those 
                            for which it would have been reasonable to have allowed is taken into account 
                            in assessing a compensation event.</p></div>,
                        tooltipDescription: "The Contractor encounters physical conditions which \n" +
                            "• are within the Site,\n" +
                            "• are not weather conditions and \n" +
                            "• an experienced Contractor would have judged at the Contract Date to \n" +
                            "have such a small chance of occurring that it would have been unreasonable for him to have allowed for them. \n" +
                            "Only the difference between the physical conditions encountered and those \n" +
                            "for which it would have been reasonable to have allowed is taken into account \n" +
                            "in assessing a compensation event. ",
                    },
                    {
                        number:"60.1(13)",
                        description: <div><p>A weather measurement is recorded <br/>
                            • within a calendar month, <br/>
                            • before the Completion Date for the whole of the works and <br/>
                            • at the place stated in the Contract Data <br/>
                            the value of which, by comparison with the weather data, is shown to occur on 
                            average less frequently than once in ten years. Only the difference between the weather measurement and the weather which 
                            the weather data show to occur on average less frequently than once in ten 
                            years is taken into account in assessing a compensation event.</p></div>,
                        tooltipDescription: "A weather measurement is recorded \n" +
                            "• within a calendar month, \n" +
                            "• before the Completion Date for the whole of the works and \n" +
                            "• at the place stated in the Contract Data \n" +
                            "the value of which, by comparison with the weather data, is shown to occur on \n" +
                            "average less frequently than once in ten years. Only the difference between the weather measurement and the weather which \n" +
                            "the weather data show to occur on average less frequently than once in ten \n" +
                            "years is taken into account in assessing a compensation event.",
                    },
                    {
                        number:"60.1(14)",
                        description: <div><p>An event which is an <span style={{fontStyle: "italic"}}>Employer's</span> risk stated in this contract.</p></div>,
                        tooltipDescription: "An event which is an Employer's risk stated in this contract.",
                    },
                    {
                        number:"60.1(15)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> certifies take over of a part of the works before 
                            both Completion and the Completion Date.</p></div>,
                        tooltipDescription: "The Project Manager certifies take over of a part of the works before \n" +
                            "both Completion and the Completion Date.",
                    },
                    {
                        number:"60.1(16)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> does not provide materials, facilities and samples for tests 
                            and inspections as stated in the Works Information.</p></div>,
                        tooltipDescription: "The Employer does not provide materials, facilities and samples for tests \n" +
                            "and inspections as stated in the Works Information.",
                    },
                    {
                        number:"60.1(17)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> notifies a correction to an assumption which he has 
                            stated about a compensation event.</p></div>,
                        tooltipDescription: "The Project Manager notifies a correction to an assumption which he has \n" +
                           "stated about a compensation event.",
                    },
                    {
                        number:"60.1(18)",
                        description: <div><p>A breach of contract by the <span style={{fontStyle: "italic"}}>Employer</span> which is not one of the other
                            compensation events in this contract.</p></div>,
                        tooltipDescription: "A breach of contract by the Employer which is not one of the other \n" +
                            "compensation events in this contract.",
                    },
                    {
                        number:"60.1(19)",
                        description: <div><p>An event which <br/>
                            • stops the <span style={{fontStyle: "italic"}}>Contractor</span> completing the works or <br/>
                            • stops the <span style={{fontStyle: "italic"}}>Contractor</span> completing the works by the date shown on the Accepted Programme,  <br/>
                            and which <br/>
                            • neither Party could prevent, <br/>
                            • an experienced <span style={{fontStyle: "italic"}}>Contractor</span> would have judged at the Contract Date to 
                            have such a small chance of occurring that it would have been unreasonable for him to have allowed for it and <br/>
                            • is not one of the other compensation events stated in this contract.</p></div>,
                        tooltipDescription: "An event which \n" +
                            "• stops the Contractor completing the works or \n" +
                            "• stops the Contractor completing the works by the date shown on the \n" +
                            "Accepted Programme, and which \n" +
                            "• neither Party could prevent, \n" +
                            "• an experienced Contractor would have judged at the Contract Date to \n" +
                            "have such a small chance of occurring that it would have been unreasonable for him to have allowed for it and \n" +
                            "• is not one of the other compensation events stated in this contract.",
                    },
                    {
                        number:"60.1(20)",
                        description: <div><p>Plant & Material Purchased by the <span style={{fontStyle: "italic"}}>Contractor</span> in foreign currency where 
                            the exchange rate at the time of order of same is above R14/USD.</p></div>,
                        tooltipDescription: "Plant & Material Purchased by the Contractor in foreign currency where \n" +
                            "the exchange rate at the time of order of same is above R14/USD.",
                    },
                    {
                        number:"60.1(21)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> is a State-funded entity with maximum budget limitations per annum. 
                            The <span style={{fontStyle: "italic"}}>Employer</span> shall disclose its budget and the funds available from the budget for the works at the commencement 
                            of each of the <span style={{fontStyle: "italic"}}>Employer's</span> financial years until the later of the Defects Date or the issue of the Defects Certificate. 
                            The Parties shall cooperate to manage the <span style={{fontStyle: "italic"}}>Employer's</span> budget relative to the pace of the work. 
                            In the event the pace of the works require adjustment due to the <span style={{fontStyle: "italic"}}>Employer's</span> budgetary constraints, 
                            the <span style={{fontStyle: "italic"}}>Project Manager</span> shall notify the <span style={{fontStyle: "italic"}}>Contractor</span> promptly of a compensation event.</p></div>,
                        tooltipDescription: "The Employer is a State-funded entity with maximum budget limitations per annum. \n" +
                            "The Employer shall disclose its budget and the funds available from the budget for the works at the commencement \n" +
                            "of each of the Employer's financial years until the later of the Defects Date or the issue of the Defects Certificate. \n" +
                            "The Parties shall cooperate to manage the Employer's budget relative to the pace of the work. \n" +
                            "In the event the pace of the works require adjustment due to the Employer's budgetary constraints, \n" +
                            "the Project Manager shall notify the Contractor promptly of a compensation event. ",
                    },
                ]
            }
        }
    ]
};

const allClausesTable = {
    clauses: [
        {
            number: "61.1",
            name: <div><p>For compensation events which arise from the <span style={{fontStyle: "italic"}}>Project Manager</span> 
                or the <span style={{fontStyle: "italic"}}>Supervisor</span> giving an instruction, issuing a certificate, changing an earlier 
                decision or correcting an assumption, the <span style={{fontStyle: "italic"}}>Project Manager</span> notifies the <span style={{fontStyle: "italic"}}>Contractor</span> of 
                the compensation event at the time of that communication. He also instructs the 
                <span style={{fontStyle: "italic"}}>Contractor</span> to submit quotations, unless the event arises from a fault of the 
                <span style={{fontStyle: "italic"}}>Contractor</span> or quotations have already been submitted. 
                The <span style={{fontStyle: "italic"}}>Contractor</span> puts the instruction or changed decision into effect.</p></div>,
            tooltipName:
                "For compensation events which arise from the Project Manager \n" +
                "or the Supervisor giving an instruction, issuing a certificate, changing an earlier \n" +
                "decision or correcting an assumption, the Project Manager notifies the Contractor of \n" +
                "the compensation event at the time of that communication. He also instructs the \n" +
                "Contractor to submit quotations, unless the event arises from a fault of the \n"+
                "Contractor or quotations have already been submitted.\n"+
                "The Contractor puts the instruction or changed decision into effect.\n",

            subClauses: {
                data:[
                    {
                        number: "60.1(1)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction changing the Works Information except <br/>
                            • a change made in order to accept a Defect or <br/>
                            • a change to the Works Information provided by the <span style={{fontStyle: "italic"}}>Contractor</span> for his
                            design which is made either at his request or to comply with other Works 
                            Information provided by the <span style={{fontStyle: "italic"}}>Employer</span>.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction changing the Works Information except \n"+
                            "• a change made in order to accept a Defect or \n"+
                            "• a change to the Works Information provided by the Contractor for his \n"+
                            "design which is made either at his request or to comply with other Works \n"+
                            "Information provided by the Employer.\n",
                    },
                    {
                        number: "60.1(4)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction to stop or not to start any work 
                            or to change a Key Date.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction to stop or not to start any work \n" +
                            "or to change a Key Date."
                    },
                    {
                        number: "60.1(7)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction for dealing with an object of 
                            value or of historical or other interest found within the Site.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction for dealing with an object of \n"+
                           "value or of historical or other interest found within the Site."
                    },
                    {
                        number: "60.1(8)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> or the <span style={{fontStyle: "italic"}}>Supervisor</span> changes a decision which he has 
                            previously communicated to the <span style={{fontStyle: "italic"}}>Contractor</span>.</p></div>,
                        tooltipDescription: "The Project Manager or the Supervisor changes a decision which he has \n"+
                            "previously communicated to the Contractor."
                    },
                    {
                        number: "60.1(10)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Supervisor</span> instructs the <span style={{fontStyle: "italic"}}>Contractor</span> to search for a Defect and no 
                            Defect is found unless the search is needed only because the <span style={{fontStyle: "italic"}}>Contractor</span> gave 
                            insufficient notice of doing work obstructing a required test or inspection.</p></div>,
                        tooltipDescription: "The Supervisor instructs the Contractor to search for a Defect and no \n"+
                            "Defect is found unless the search is needed only because the Contractor gave \n"+
                            "insufficient notice of doing work obstructing a required test or inspection."
                    },
                    {
                        number: "60.1(17)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> notifies a correction to an assumption which he has 
                            stated about a compensation event.</p></div>,
                        tooltipDescription: "The Project Manager notifies a correction to an assumption which he has \n"+
                            "stated about a compensation event."
                    },
                    {
                        number:"60.1(21)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> is a State-funded entity with maximum budget limitations per annum. 
                            The <span style={{fontStyle: "italic"}}>Employer</span> shall disclose its budget and the funds available from the budget for the works at the commencement 
                            of each of the <span style={{fontStyle: "italic"}}>Employer's</span> financial years until the later of the Defects Date or the issue of the Defects Certificate. 
                            The Parties shall cooperate to manage the <span style={{fontStyle: "italic"}}>Employer's</span> budget relative to the pace of the work.
                            In the event the pace of the works require adjustment due to the <span style={{fontStyle: "italic"}}>Employer's</span> budgetary constraints, 
                            the <span style={{fontStyle: "italic"}}>Project Manager</span> shall notify the <span style={{fontStyle: "italic"}}>Contractor</span> promptly of a compensation event.</p></div>,
                        tooltipDescription: "The Employer is a State-funded entity with maximum budget limitations per annum. \n" +
                            "The Employer shall disclose its budget and the funds available from the budget for the works at the commencement \n" +
                            "of each of the Employer's financial years until the later of the Defects Date or the issue of the Defects Certificate. \n" +
                            "The Parties shall cooperate to manage the Employer's budget relative to the pace of the work. \n" +
                            "In the event the pace of the works require adjustment due to the Employer's budgetary constraints, \n" +
                            "the Project Manager shall notify the Contractor promptly of a compensation event. ",
                    },
                ]
            }
        },
        {
            number: "61.2",
            name: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> may instruct the <span style={{fontStyle: "italic"}}>Contractor</span> to submit quotations for a 
                proposed instruction or a proposed changed decision. The <span style={{fontStyle: "italic"}}>Contractor</span> does not 
                put a proposed instruction or a proposed changed decision into effect.</p></div>,
            tooltipName: "The Project Manager may instruct the Contractor to submit quotations for a \n"+
                "proposed instruction or a proposed changed decision. The Contractor does not \n"+
                "put a proposed instruction or a proposed changed decision into effect.",
            subClauses: {
                data:[
                    {
                        number: "60.1(1)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction changing the Works Information except <br/>
                            • a change made in order to accept a Defect or <br/>
                            • a change to the Works Information provided by the <span style={{fontStyle: "italic"}}>Contractor</span> for his
                            design which is made either at his request or to comply with other Works 
                            Information provided by the <span style={{fontStyle: "italic"}}>Employer</span>.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction changing the Works Information except \n"+
                            "• a change made in order to accept a Defect or \n"+
                            "• a change to the Works Information provided by the Contractor for his \n"+
                            "design which is made either at his request or to comply with other Works \n"+
                            "Information provided by the Employer.",
                    },
                    {
                        number: "60.1(4)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction to stop or not to start any work
                            or to change a Key Date.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction to stop or not to start any work \n"+
                            "or to change a Key Date." ,
                    },
                    {
                        number: "60.1(7)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> gives an instruction for dealing with an object of 
                            value or of historical or other interest found within the Site.</p></div>,
                        tooltipDescription: "The Project Manager gives an instruction for dealing with an object of \n"+
                            "value or of historical or other interest found within the Site." ,
                    },
                    {
                        number: "60.1(8)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> or the <span style={{fontStyle: "italic"}}>Supervisor</span> changes a decision which he has 
                            previously communicated to the <span style={{fontStyle: "italic"}}>Contractor</span>.</p></div>,
                        tooltipDescription: "The Project Manager or the Supervisor changes a decision which he has \n"+
                            "previously communicated to the Contractor." ,
                    },
                    {
                        number: "60.1(10)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Supervisor</span> instructs the <span style={{fontStyle: "italic"}}>Contractor</span> to search for a Defect and no 
                            Defect is found unless the search is needed only because the <span style={{fontStyle: "italic"}}>Contractor</span> gave 
                            insufficient notice of doing work obstructing a required test or inspection.</p></div>,
                        tooltipDescription: "The Supervisor instructs the Contractor to search for a Defect and no \n"+
                            "Defect is found unless the search is needed only because the Contractor gave \n"+
                            "insufficient notice of doing work obstructing a required test or inspection." ,
                    },
                    {
                        number: "60.1(17)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Project Manager</span> notifies a correction to an assumption which he has 
                            stated about a compensation event.</p></div>,
                        tooltipDescription: "The Project Manager notifies a correction to an assumption which he has \n"+
                            "stated about a compensation event." ,
                    },
                    {
                        number:"60.1(21)",
                        description: <div><p>The <span style={{fontStyle: "italic"}}>Employer</span> is a State-funded entity with maximum budget limitations per annum. 
                            The <span style={{fontStyle: "italic"}}>Employer</span> shall disclose its budget and the funds available from the budget for the works at the commencement 
                            of each of the <span style={{fontStyle: "italic"}}>Employer's</span> financial years until the later of the Defects Date or the issue of the Defects Certificate. 
                            The Parties shall cooperate to manage the <span style={{fontStyle: "italic"}}>Employer's</span> budget relative to the pace of the work. 
                            In the event the pace of the works require adjustment due to the <span style={{fontStyle: "italic"}}>Employer's</span> budgetary constraints, 
                            the <span style={{fontStyle: "italic"}}>Project Manager</span> shall notify the <span style={{fontStyle: "italic"}}>Contractor</span> promptly of a compensation event.</p></div>,
                        tooltipDescription: "The Employer is a State-funded entity with maximum budget limitations per annum. \n" +
                            "The Employer shall disclose its budget and the funds available from the budget for the works at the commencement \n" +
                            "of each of the Employer's financial years until the later of the Defects Date or the issue of the Defects Certificate. \n" +
                            "The Parties shall cooperate to manage the Employer's budget relative to the pace of the work. \n" +
                            "In the event the pace of the works require adjustment due to the Employer's budgetary constraints, \n" +
                            "the Project Manager shall notify the Contractor promptly of a compensation event. ",
                    },
                ]
            }
        }
    ]
};

const dataTableEvents = {
    headerRow: ["Primary Key", "Event Number", "Event Description", "Event Type", "Payment", "EOT", "Contract", "Project", "Submitted Date"],
    dataRows: [
        [1, "CE-0010", "Expect late drawings for CVY20", "Delay in the provision of free-issue materials by employer", "ZAR 1 043 492", "5 day(s)", "Demo Contract", "Demo Project" ,"2019-04-18"],
        [2, "CE-0011", "Expect late drawings for CVY21", "Delay in the provision of free-issue materials by employer", "ZAR 1 033 482", "4 day(s)", "Demo Contract", "Demo Project" ,"2019-04-19"],
        [3, "CE-0012", "Expect late drawings for CVY22", "Delay in the provision of free-issue materials by employer", "ZAR 1 064 472", "8 day(s)", "Demo Contract", "Demo Project" ,"2019-04-20"],
        [4, "CE-0013", "Expect late drawings for CVY23", "Delay in the provision of free-issue materials by employer", "ZAR 1 085 452", "1 day(s)", "Demo Contract", "Demo Project" ,"2019-04-22"],
        [5, "CE-0014", "Expect late drawings for CVY24", "Delay in the provision of free-issue materials by employer", "ZAR 1 087 422", "2 day(s)", "Demo Contract", "Demo Project" ,"2019-04-26"],
        [6, "CE-0015", "Expect late drawings for CVY25", "Delay in the provision of free-issue materials by employer", "ZAR 1 023 452", "3 day(s)", "Demo Contract", "Demo Project" ,"2019-04-30"],
        [7, "CE-0016", "Expect late drawings for CVY26", "Delay in the provision of free-issue materials by employer", "ZAR 1 083 422", "7 day(s)", "Demo Contract", "Demo Project" ,"2019-05-02"],
    ]
};

const dataTableEventsNotice = {
    headerRow: ["Primary Key", "Event Number", "Event Description", "Event Type", "Payment", "EOT", "Contract", "Project", "Submitted Date"],
    dataRows: [
        [1, "CEN-0010", "Late drawings for CVY20",  "Delay in the provision of free-issue materials by employer", "ZAR 1 023 452", "6 day(s)", "Demo Contract", "Demo Project" ,"2019-04-17"],
        [2, "CEN-0011", "Late drawings for CVY21",  "Delay in the provision of free-issue materials by employer", "ZAR 1 033 482", "8 day(s)", "Demo Contract", "Demo Project" ,"2019-04-15"],
        [3, "CEN-0012", "Late drawings for CVY22",  "Delay in the provision of free-issue materials by employer", "ZAR 1 083 422", "2 day(s)", "Demo Contract", "Demo Project" ,"2019-04-19"],
        [4, "CEN-0013", "Late drawings for CVY23",  "Delay in the provision of free-issue materials by employer", "ZAR 1 085 452", "3 day(s)", "Demo Contract", "Demo Project" ,"2019-04-21"],
        [5, "CEN-0014", "Late drawings for CVY24",  "Delay in the provision of free-issue materials by employer", "ZAR 1 087 422", "1 day(s)", "Demo Contract", "Demo Project" ,"2019-04-25"],
        [6, "CEN-0015", "Late drawings for CVY25",  "Delay in the provision of free-issue materials by employer", "ZAR 1 043 492", "8 day(s)", "Demo Contract", "Demo Project" ,"2019-04-29"],
        [7, "CEN-0016", "Late drawings for CVY26",  "Delay in the provision of free-issue materials by employer", "ZAR 1 064 472", "5 day(s)", "Demo Contract", "Demo Project" ,"2019-05-06"],
    ]
};

const selectedViewEventData = {
    event:{
        primaryKey: 1,
        eventTitle: "Late delivery of transformers for PI",
        eventContract: "Demo Contract",
        eventProject: "Demo Project",
        submitted: "2019-04-15",
        becameAwareDate: "2019-04-15",

        submitterName: "Rikard Schouwstra",
        linkedRiskName: "Risk 1",
        timeToReply: "5 day(s)",

        eventNumber: "CE-0214",
        earlyWarningNumber: "EW-1245",
        eventStatus: "Active",
        eventType: "Delay in the provision of free-issue materials by employer",
        eventDetails: "We need to install transformer at structure 3 in power island by 18/04/16 otherwise no activities can proceed in this area ",
        affectedActivities: "PI: Structure 3 installation of transformer; PI: Structure 3 MV cabling",


        costImpact: "Yes",
        completionDelay: "No",
        impairPerformance: "No",
        delayKeyDates: "No",
        contractorCost: "No",

        mitigationItems: [
            {
                description: "Begin installation on floors 4-5",
                cost: "500000",
                costImpact: "Yes",
                // costImpactValue: 500000,
                delayCompletionImpact: "No",
                // delayCompletionImpactValue: 0,
                impairPerformanceImpact: "No",
                delayKeyDatesImpact: "Yes",
                increaseContractorCost: "No",
                comments: [{
                    person: "Rikard Schouwstra",
                    time: "14:00",
                    date: "2019-08-12",
                    comment : "This is a mitigation comment"
                }],
                attachments: [
                    {
                        description: "floorImage4",
                        uploader: "Divan Ehlers",
                        uploadDate: "2018-04-19"
                    },
                ]

            },
            {
                description: "Strategy 2",
                cost: "3000",
                costImpact: "No",
                // costImpactValue: 500000,
                delayCompletionImpact: "No",
                // delayCompletionImpactValue: 0,
                impairPerformanceImpact: "No",
                delayKeyDatesImpact: "No",
                increaseContractorCost: "No",
                comments: [{
                    person: "Pieter van Wyk",
                    time: "14:00",
                    date: "2019-08-12",
                    comment : "This is a mitigation comment 1"
                }],
                attachments: [
                    {
                        description: "imageTitle",
                        uploader: "Divan Ehlers",
                        uploadDate: "2018-04-19"
                    }
                ]

            },
        ],
        attachments: [
            {
                description: "imageTitle",
                uploader: "Divan Ehlers",
                uploadDate: "2018-04-19"
            }
        ],
        eventQuote:[
            {
                quoteNumber:"1",
                quoteCost:"ZAR 7418",
                quoteDelay: "10",
                program:"Program 1.pdf",
                uploadBy:"Divan Ehlers",
                uploadDate:"2019-06-12",

            }
        ],
        comments: [{"person" : "Durant Pretorius", "comment": "Don't send any heavy plant to this area", "date" : "2019-04-10", "time" : "15:35"},
            {"person" : "Rikard Schouwstra", "comment": "Please take note of safety requirements", "date" : "2019-04-30", "time" : "17:00"}]
    }
};


const selectedViewEventNoticeData = {
        event:{
            primaryKey: 1,
            eventTitle: "Late delivery of transformers for PI",
            eventContract: "Demo Contract",
            eventProject: "Demo Project",
            submitted: "2019-04-15",
            becameAwareDate: "2019-04-15",

            submitterName: "Rikard Schouwstra",
            linkedRiskName: "Risk 1",
            timeToReply: "5 day(s)",

            eventNumber: "CE-0214",
            earlyWarningNumber: "EW-1245",
            eventStatus: "Active",
            eventType: "Delay in the provision of free-issue materials by employer",
            eventDetails: "We need to install transformer at structure 3 in power island by 18/04/16 otherwise no activities can proceed in this area ",
            affectedActivities: "PI: Structure 3 installation of transformer; PI: Structure 3 MV cabling",


            costImpact: "Yes",
            completionDelay: "No",
            impairPerformance: "No",
            delayKeyDates: "No",
            contractorCost: "No",

            mitigationItems: [
                {
                    description: "Begin installation on floors 4-5",
                    cost: "500000",
                    costImpact: "Yes",
                    // costImpactValue: 500000,
                    delayCompletionImpact: "No",
                    // delayCompletionImpactValue: 0,
                    impairPerformanceImpact: "No",
                    delayKeyDatesImpact: "Yes",
                    increaseContractorCost: "No",
                    comments: [{
                        person: "Rikard Schouwstra",
                        time: "14:00",
                        date: "2019-08-12",
                        comment : "This is a mitigation comment"
                    }],
                    attachments: [
                        {
                            description: "floorImage4",
                            uploader: "Divan Ehlers",
                            uploadDate: "2018-04-19"
                        },
                    ]

                },
                {
                    description: "Strategy 2",
                    cost: "3000",
                    costImpact: "No",
                    // costImpactValue: 500000,
                    delayCompletionImpact: "No",
                    // delayCompletionImpactValue: 0,
                    impairPerformanceImpact: "No",
                    delayKeyDatesImpact: "No",
                    increaseContractorCost: "No",
                    comments: [{
                        person: "Pieter van Wyk",
                        time: "14:00",
                        date: "2019-08-12",
                        comment : "This is a mitigation comment 1"
                    }],
                    attachments: [
                        {
                            description: "imageTitle",
                            uploader: "Divan Ehlers",
                            uploadDate: "2018-04-19"
                        }
                    ]

                },
            ],
            attachments: [
                {
                    description: "imageTitle",
                    uploader: "Divan Ehlers",
                    uploadDate: "2018-04-19"
                }
            ],
            eventQuote:[
                {
                    quoteNumber:"1",
                    quoteCost:"ZAR 7418",
                    program:"Program 1.pdf",
                    uploadBy:"Divan Ehlers",
                    uploadDate:"2019-06-12",

                }
            ],
            comments: [{"person" : "Durant Pretorius", "comment": "Don't send any heavy plant to this area", "date" : "2019-04-10", "time" : "15:35"},
                {"person" : "Rikard Schouwstra", "comment": "Please take note of safety requirements", "date" : "2019-04-30", "time" : "17:00"}]
        }
    };

export {
    dataTableEvents,
    dataTableEventsNotice,
    selectedViewEventData,
    selectedViewEventNoticeData,
    allClausesNoticeTable,
    allClausesTable
};
