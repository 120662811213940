import fullScreenDialogStyles from "./fullScreenDialogStyles";

const previewDialogContentStyles = {
    ...fullScreenDialogStyles,
    // submitButtonContainer : {
    //     position: "relative",
    //     float: "right",
    // },
    // submitButton : {
    //     float: "right"
    // },

    // noPadding : {
    //     padding : 0,
    //     overflowX : "hidden"
    // }
    pdfPage: {
        '& canvas' : {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            background : "#fff"
        }
    },
    pdfContainer : {
        marginTop : 5
    }
};

export default previewDialogContentStyles;
