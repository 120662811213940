import customSelectStyles from "../jss/material-dashboard-pro-react/customSelectStyle";

const recipientListStyles = theme => ({
    ...customSelectStyles,
    gridContainer : {
        marginTop: 27
    },
    heading : {
        marginBottom: 0, fontWeight: 400, display: "inline", marginRight: 10
    },
    customListItem : {
        paddingLeft: 0, borderBottom: "1px solid #999"
    },
    closeIcon : {
        cursor: "pointer", fontSize: 16, marginTop: 5, color: "red"
    },
    noDataText : {
        fontSize:14,
        fontStyle:"italic",
        marginBottom : 0,
    },
    inputContainer : {
        marginTop : -10
    },
    marLftN15 : {
        [theme.breakpoints.down("xs")]: {
            marginLeft: -15
        }
    },
    block : {
        [theme.breakpoints.down("xs")]: {
            display: "block"
        }
    },
});

export default recipientListStyles;