import React from 'react';
import * as actionCreators from '../../store/actions/index';
import { connect } from 'react-redux';
import Snackbars from "../Snackbar/Snackbar";

const SuccessNotification = (props) => {
    return (
        props.successMessage && <Snackbars
        place="br"
        color={"success"}
        message={"Success! " + props.successMessage.toString()}
        open={props.isOpen}
        close
        closeNotification={props.onHideSuccessMessage}
    />
    );
};

const mapStateToProps = (state) => {
    return {
        successMessage : state.successReducer.successMessage,
        isOpen : state.successReducer.isOpen,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onHideSuccessMessage : () => dispatch(actionCreators.hideSuccessMessage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessNotification);
