import cardFooterStyle from "../../assets/jss/material-dashboard-pro-react/components/cardFooterStyle";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const responseAccordionStyles = {
    ...customCheckboxRadioSwitch,
    ...cardFooterStyle,
    card : {
        marginBottom: -5,
        marginTop: 10,
        backgroundColor: "#fff",
        minWidth: 250
    },
    selfAlignCenter : {
        alignSelf: "center"
    },
    textAlignCenter : {
        textAlign: "center"
    },
    marLeft10 : {
        marginLeft: -10
    },
    marZero : {
        margin: 0
    },
};

export default responseAccordionStyles;