import React from "react";
import revisionResponsePreviewDialogStyles from "../../../assets/styles/revisionResponsePreviewDialogStyles";
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from "@material-ui/core";
import * as PropTypes from "prop-types";
import {
  firstToUpper, removeAllWhiteSpace,
  verifyLength
} from "../../../globals";
import Transition from "../../CustomDialogs/FullScreenDialogTransition";
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { withRouter } from "react-router-dom";

class SubmissionRevisionResponseDialog extends React.Component {
  state = {
    currentPage: 0,
    showRequiredDescription: false,
    actionType: ""
  };

  //event handler for dynamic input boxes
  onChangeInput = (item, event, indexPar = 0) => {

    // validate if item is required
    if (item.required) {
      if (item.type === "text" || item.type === "textArea") {
        verifyLength(removeAllWhiteSpace(event.target.value), 1)
          ? this.setState({ [item.field + "State"]: "success" })
          : this.setState({ [item.field + "State"]: "error" });
      } else if (item.type === "checkBox") {
        event.target.checked === true
          ? this.setState({ [item.field + "State"]: "success" })
          : this.setState({ [item.field + "State"]: "error" });
      }
    }

    if (item.type === "text" || item.type === "textArea" || item.type === "response") {
      this.setState({ [item.field]: event.target.value });
    }

    //update input value state
    else if (item.type === "clauseRadio") {

      let updatedObj = {};

      if (event.target.type === "radio") {
        updatedObj = Object.assign({}, this.state[item.field][indexPar], {
          "choice": event.target.value
        });
      }
      else {
        updatedObj = Object.assign({}, this.state[item.field][indexPar], {
          "comments": event.target.value
        });
      }

      this.setState({
        [item.field]: [
          ...this.state[item.field].slice(0, indexPar),
          updatedObj,
          ...this.state[item.field].slice(indexPar + 1)
        ]
      });

    }
    else if (item.type === "attachment") {
      if (event.target.files.length === 0) {
        this.props.onSetError("No file chosen");
      } else {
        //create object containing image data to upload
        let arr = [...this.state[item.field]];

        let obj = {
          fileName: event.target.files[0].name,
          file: event.target.files[0]
        };
        //clear value so that on change fires
        event.target.value = '';
        //push object to attachment array
        arr.push(obj);

        //update state to new values
        this.setState({
          [item.field]: arr,
          [item.field + "HeadingColor"]: "#000000"
        });

        //create string to use when calling preview api
        this.createAttachmentsString(arr, item);
      }
    }
  };

  //open close dialog handler
  openCloseDialog = () => {
    this.setState({
      currentPage: this.state.currentPage - 1,
      showRequiredDescription: false
    });

    if (this.state.currentPage === 0)
      this.props.openAndCloseDialog();
  };

  //action methods, children components call this function
  dialogActionOnClick = type => {
    this.setState({
      actionType: type
    }, () => {
      if (type === "send" || type === "sendDownload") {
        this.createCommunication(type);
      }
      else if (type === "preview") {
        this.previewCommunication();
      }
    });
  };

  isDataValid = () => {
    if (this.state.currentPage < this.props.components.length - 1) {
      this.setState((prevState) => {
        return {
          currentPage: prevState.currentPage + 1
        }
      });
    }
  };

  //dynamically build json object to post to api
  buildJsonObject = () => {
    let jsonObj = {};

    let componentIndex = this.props.components.findIndex(
      obj => obj.type === "response"
    );

    //set status of response
    if (this.props.components[componentIndex].responseType === "reviewed")
    {
      this.props.fields.forEach(fieldObject => {
          if (fieldObject.type === "response")
            jsonObj["status"] = (this.state[fieldObject.field] + "ed").toLowerCase();
        }
      )
    }

    else
      jsonObj["status"] = this.props.components[componentIndex].responseType;

    //set revision id
    jsonObj[this.props.type + "Revision"] = this.props.revisionId;

    if (this.props.components[componentIndex].responseType === "accepted") {
      for (let i = 0; i < this.props.fields.length; i++) {
        jsonObj[this.props.fields[i].field] = this.state[
          this.props.fields[i].field
        ];
      }
    } else {
      for (let i = 0; i < this.props.fields.length; i++) {
        //build attachment string
        if (this.props.fields[i].type === "attachment") {
          jsonObj[
            "preview" + firstToUpper(this.props.fields[i].field) + "String"
          ] = this.state[
            "preview" + firstToUpper(this.props.fields[i].field) + "String"
            ];
        }
        else {
          //build other input key value pairs
          jsonObj[this.props.fields[i].field] = this.state[
            this.props.fields[i].field
          ];
        }
      }
    }
    return jsonObj;
  };

  getReduxStateName = () => {
    return this.props.type + "_" + this.props.clauseData.id;
  };

  //call preview api that returns preview pdf
  previewCommunication = () => {
    if (this.validateForm()) {
      let payload = {
        id: this.getReduxStateName(),
        url: this.props.apiEndpoints.previewRevisionResponse,
        data: this.buildJsonObject()
      }

      this.props.onPreview(payload).then(() => {
        this.isDataValid();
      });
    } else {
      //validation failed
      this.props.onSetError("One or more empty fields");
    }
  };

  //revision response api
  createCommunication = type => {
    let payload = {
      id: this.getReduxStateName(),
      url: this.props.apiEndpoints.createRevisionResponse,
      data: this.buildJsonObject()
    }

    this.props.onCreate(payload).then(() => {
      this.sendCommunication(this.props.createId[this.getReduxStateName()], type);
    });
  };

  //calls apis for send and send & download of preview
  sendCommunication = (id, sendType) => {
    let promises = [];

    for (let i = 0; i < this.props.fields.length; i++) {
      let item = this.props.fields[i];

      if (item.type === "attachment") {
        if (this.state[item.field].length !== 0) {
          let data = new FormData();
          data.append("id", id);
          data.append("type", this.props.type + "_revision_response");

          this.state[item.field].forEach(object => {
            data.append("file", object.file);
          });

          let individualPayload = {
            url: this.props.apiEndpoints.attachments,
            data: data
          }
          promises.push(this.props.onUploadFiles(individualPayload));
        }
      }
    }

    let payload = {
      promises: promises
    };

    this.props.onUploadAllFiles(payload).then(() => {
      let payload = {
        id: this.getReduxStateName(),
        createId: this.props.createId[this.getReduxStateName()],
      };

      if (sendType === "sendDownload") {
        payload.url = this.props.apiEndpoints.sendDownloadRevisionResponse;

        this.props.onSendDownload(payload).then(() => {
          this.openCloseDialog();
          
          let detailedPagePayload = {
            url: this.props.apiEndpoints.details + "/" + this.props.communicationId,
          };

          this.props.onGetDetailsAndPermissions(detailedPagePayload).then(() => {
              let message = "Response for Clause " + this.props.clauseData.clauseNumber + " submitted";
              this.props.onSuccess(message);
          });
        });
      }
      else {
        payload.url = this.props.apiEndpoints.sendRevisionResponse;

        this.props.onSend(payload).then(() => {
          this.openCloseDialog();
          
          let detailedPagePayload = {
            url: this.props.apiEndpoints.details + "/" + this.props.communicationId,
          };

          this.props.onGetDetailsAndPermissions(detailedPagePayload).then(() => {
            let message = "Response for Clause " + this.props.clauseData.clauseNumber + " submitted";
            this.props.onSuccess(message);
          });
        });
      }
    });
  };

  //remove item from list components
  removeFromList = (index, item) => {
    //set temp array to list
    let arr = [...this.state[item.field]];

    //remove chosen item from list
    arr.splice(index, 1);

    //change heading color if item is required and list is empty
    if (arr.length === 0 && item.required) {
      this.setState({
        [item.field + "HeadingColor"]: "#f44336"
      });
    }
    //update list state
    this.setState({
      [item.field]: arr
    });

    //creates string of attachments to use in preview template
    this.createAttachmentsString(arr, item);
  };

  //create attachment string to use for preview
  createAttachmentsString = (arr, item) => {
    //check if list is empty
    if (arr.length !== 0) {
      let attachmentsString = "";

      arr.forEach(object => {
        attachmentsString += object.fileName + "; ";
      });


      this.setState({
        ["preview" + firstToUpper(item.field) + "String"]: attachmentsString
      });
    } else {
      this.setState({
        ["preview" + firstToUpper(item.field) + "String"]: ""
      });
    }
  };

  //validate form before calling preview api
  validateForm = () => {
    let validationPassed = true;

    for (let i = 0; i < this.props.fields.length; i++) {
      if (this.props.fields[i].required && this.props.fields[i].type === "attachment") {
        //change heading color if list is required
        if (this.state[this.props.fields[i].field].length === 0) {
          this.setState({
            [this.props.fields[i].field + "HeadingColor"]: "#f44336"
          });
          validationPassed = false;
        }
      } else if (this.props.fields[i].required && removeAllWhiteSpace(this.state[this.props.fields[i].field]) === ""
      ) {
        //check if other required inputs are not empty

        this.setState({
          [this.props.fields[i].field + "State"]: "error"
        });
        validationPassed = false;
      }
    }
    return validationPassed;
  };

  //ensure setState finishes before rendering components
  stateLoaded = () => {
    let flag = true;

    for (let i = 0; i < this.props.fields.length; i++) {
      if (this.state[this.props.fields[i].field + "StateSet"] !== true) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  //dynamically generates state based on response fields
  componentDidMount() {
    this.props.fields.forEach(item => {
      if (item.required) {
        this.setState({
          showRequiredDescription: true
        });
      }

      if (item.type === "attachment") {
        this.setState({
          [item.field]: [],
          [item.field + "HeadingColor"]: "#000000",
          ["preview" + firstToUpper(item.field) + "String"]: "",
          [item.field + "StateSet"]: true
        });
      }
      else if (item.type === "clauseRadio") {
        // update state to new values
        this.setState({
          [item.field]: item.objectTemplate.slice(0),
          [item.field + "StateSet"]: true
        });
      }
      else {
        this.setState({
          [item.field]: "",
          [item.field + "State"]: "",
          [item.field + "StateSet"]: true
        });
      }

    });
  }

  //dynamically generates components based on current page
  generateComponent = (actionType) => {
    const ComponentName = this.props.components[this.state.currentPage].component;
    const key = this.getReduxStateName();

    if (this.props.components[this.state.currentPage].type === "preview") {
      //generate preview component in dialog
      return (
        <ComponentName
          openClose={this.openCloseDialog}
          actionMethod={this.dialogActionOnClick}
          fields={this.props.fields}
          previewPDF={this.props.preview[key]}
          loadingSend={this.props.sendLoading[key] || (this.props.createLoading[key] && actionType === "send") || (this.props.uploadFileLoading && actionType === "send")}
          loadingSendDownload={this.props.sendDownloadLoading[key] || (this.props.createLoading[key] && actionType === "sendDownload") || (this.props.uploadFileLoading && actionType === "sendDownload")}
          {...this.state}
        />
      );
    } else {
      //generate revision response form component in dialog
      return (
        <ComponentName
          openClose={this.openCloseDialog}
          responseType={this.props.components[this.state.currentPage].responseType}
          loadingPreview={this.props.previewLoading[key]}
          actionMethod={this.dialogActionOnClick}
          fields={this.props.fields}
          onChangeInput={this.onChangeInput}
          removeFromList={this.removeFromList}
          displayResponseData={this.props.clauseData.displayData.response}
          {...this.state}
        />
      );
    }
  };

  render() {
    const { currentPage, actionType } = this.state;
    const { classes, components, open } = this.props;

    return (
      //check if state loaded and the current dialog content page valid
      this.stateLoaded() &&
      currentPage < components.length && (
        <div>
          <Dialog
            open={open}
            aria-labelledby={components[currentPage].id}
            fullScreen={true}
            TransitionComponent={Transition}
            className={classes.dialog}
          >
            {this.generateComponent(actionType)}
          </Dialog>
        </div>
      )
    );
  }
}

//component prop types
SubmissionRevisionResponseDialog.propTypes = {
  openAndCloseDialog : PropTypes.func,
  fields : PropTypes.array,
  components : PropTypes.array,
  revisionId :PropTypes.number,
  communicationId :PropTypes.number,
  apiEndpoints :PropTypes.object,
  type : PropTypes.string,
  clauseData : PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    uploadFileLoading: state.fileReducer.uploadFileLoading,
    uploadFileError: state.fileReducer.uploadFileLoading,
    preview: state.communicationReducer.preview,
    previewLoading: state.communicationReducer.previewLoading,
    previewError: state.communicationReducer.previewError,
    createId: state.communicationReducer.createId,
    createLoading: state.communicationReducer.createLoading,
    createError: state.communicationReducer.createError,
    sendLoading: state.communicationReducer.sendLoading,
    sendError: state.communicationReducer.sendError,
    sendDownloadLoading: state.communicationReducer.sendDownloadLoading,
    sendDownloadError: state.communicationReducer.sendDownloadError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPreview: (payload) => dispatch(actionCreators.previewCommunication(payload)),
    onCreate: (payload) => dispatch(actionCreators.createCommunication(payload)),
    onSend: (payload) => dispatch(actionCreators.sendCommunication(payload)),
    onSendDownload: (payload) => dispatch(actionCreators.sendDownloadCommunication(payload)),
    onUploadFiles: (payload) => dispatch(actionCreators.uploadFiles(payload)),
    onUploadAllFiles: (payload) => dispatch(actionCreators.uploadAllFiles(payload)),
    onSuccess : (payload) => dispatch(actionCreators.setSuccessMessage(payload)),
    onGetDetailsAndPermissions: (payload) => dispatch(actionCreators.getPermissionsAndDetails(payload)),
    onSetError : (payload) => dispatch(actionCreators.setError(payload))
  };
};


//export component with jsx styles
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(revisionResponsePreviewDialogStyles)(
  SubmissionRevisionResponseDialog
)));
