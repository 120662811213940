import React, { createRef } from "react";
import communicationRevisionDetailsStyles from "../../../assets/styles/communicationRevisionDetailsStyles";
import { withStyles } from '@material-ui/core/styles';
import Button from "../../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import * as PropTypes from "prop-types";
import AttachmentsTable from "../../Table/AttachmentsTable";
import { firstToUpper } from "../../../globals";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import RadioGroupAccordion from "../../Accordion/RadioGroupAccordion";

class NotificationRevisionDetails extends React.Component {
    constructor(props){
        super(props);
        this.topOfPage = createRef();
    }

    //set state to be used
    state = {
        open : false,
        showScrollTop : false
    };

    //scroll to the top of the page onclick
    scrollToTop = () => {
        if(this.topOfPage.current){
            this.topOfPage.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    //show scroll to top button when user initially scrolls
    handleScroll = () => {
        if(!this.state.showScrollTop){
            this.setState({
                showScrollTop : true
            });
        }
    };

    //return the status of the current revision
    getStatus = () => {
        if(this.props.revisionIsResponse){
            return this.props.data && this.props.data.statusValue ? this.props.data.statusValue.toLowerCase() === "accepted" ? this.props.displayStatusData.accepted : this.props.displayStatusData.rejected : null;
        }
        return this.props.data && this.props.data.status && this.props.data.status.statusValue ? this.props.data.status.statusValue.toLowerCase() === "accepted" ? this.props.displayStatusData.accepted : this.props.displayStatusData.rejected : null;
    }

    //dynamically generates components based on clause fields
    generateComponent = (item, key) => {
        if(item.type.toLowerCase() === "attachment"){
            return (
                <div key={key}>
                    <p><strong>{item.labelText}: </strong></p>
                    <AttachmentsTable attachments={this.props.data[item.field]} />
                </div>
            );
        }
        else if(item.type.toLowerCase() === "checkbox"){
            return (
                <div key={key}>
                    <p><strong>{item.labelText}: </strong></p>
                       {this.props.data[item.field] && this.props.data[item.field].length !== 0 ? this.props.data[item.field].map((obj, index) =>
                               <p key={index}>{obj}</p>
                      ): null}
                </div>
            );
        }
        else if(item.type === "clauseRadio") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <RadioGroupAccordion
                        clauseData={item}
                        radioData={this.props.data[item.field] && this.props.data[item.field].length !== 0 ? this.props.data[item.field] : []}
                        isDetails={true}
                    />
                </GridItem>
            );
        }
        else{
            return (
                <p key={key}><strong>{item.labelText}: </strong>{this.props.data[item.field] !== "" ? this.props.data[item.field] : "None"}</p>
            );
        }

    };

    //open and close revision details dialog
    onOpenAndCloseDialog = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render () {

        const { classes, displayResponseData } = this.props;
        const { open, showScrollTop } = this.state;

        return (
             <div className={classes.divFloatRight + " " + classes.buttonAlign}>
               <Button color={"primary"} onClick={() => this.onOpenAndCloseDialog()}>Response Details</Button>

                <Dialog open={open}
                        onClose={() => this.onOpenAndCloseDialog()}
                        fullScreen={true}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant={"h6"} className={classes.appBarHeading}>
                                {displayResponseData.details}
                            </Typography>


                            <Button
                              size="sm"
                              simple={true}
                              className={classes.appBarButtons}
                              onClick={() => this.onOpenAndCloseDialog()}>
                                Close
                            </Button>

                        </Toolbar>
                    </AppBar>
                    <DialogContent onScroll={this.handleScroll} className={classes.dialogContent}>

                        <div ref={this.topOfPage}/>
                        <GridContainer>
                            <GridItem xs={12} sm={12} lg={12}>
                                <p style={{marginTop : 30}}><strong>Status: </strong>{firstToUpper(this.getStatus())}</p>
                                {this.getStatus() === this.props.displayStatusData.accepted ?
                                    this.props.responseFields.acceptFields.map((item, key) => (
                                        this.generateComponent(item, key)
                                    )) : 
                                    this.props.responseFields.rejectFields.map((item, key) => (
                                        this.generateComponent(item, key)
                                    ))
                                }
                            </GridItem>
                        </GridContainer>

                    </DialogContent>

                    {showScrollTop ? <label className={classes.scrollTopLabel}>
                        <IconButton className={classes.scrollTopButton} onClick={() => this.scrollToTop()} component={"span"} size={"sm"} color={"primary"}>
                            <KeyboardArrowUpIcon className={classes.scrollTopIcon}/>
                        </IconButton>
                    </label> : null
                    }
                </Dialog>
            </div>
        );
    }
}

NotificationRevisionDetails.propTypes = {
    responseFields : PropTypes.object,
    data : PropTypes.object,
    displayResponseData : PropTypes.object,
    revisionIsResponse : PropTypes.bool,
    displayStatusData : PropTypes.object,
};

export default withStyles(communicationRevisionDetailsStyles)(NotificationRevisionDetails);
