import React from "react"
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// import ViewSwitch from "../../../../components/CustomSwitch/ViewSwitch.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody.jsx";
import {cardTitle} from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import Add from "@material-ui/icons/Add";
// import Person from "@material-ui/icons/Person";
import Create from "@material-ui/icons/Create";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import Dialog from "@material-ui/core/Dialog/index";
// import List from "@material-ui/core/List/index";
import InputAdornment from "@material-ui/core/InputAdornment/index";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import { verifyEmail, verifyLength, verifyNumber, daysArray, monthsArray } from "../../../../globals";
import { getApiRoot } from "../../../../api";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";

//Custom styles
const styles = theme => ({
    ...dashboardStyle,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    // cardAlignment: {
    //     marginTop: 10,
    //     [theme.breakpoints.down("sm")]: {
    //         marginTop: 20
    //     },
    //     [theme.breakpoints.down("xs")]: {
    //         marginTop: 20
    //     }
    // },
    gridPadding: {
        [theme.breakpoints.down("sm")]: {
            padding: 0
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0
        }
    }
});


class ProjectView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            days : daysArray() ,
            months : monthsArray(),

            // loadingProjectManagers: false,
            loadingEditProject: false,
            isLoaded: false,
            error: null,

            //row of project where action button is pressed
            projectRowKey: 0,

            // openProjectManagerDialog: false,
            openEditProjectDialog: false,
            // openAddContractDialog: false,
            openContractsDialog: false,

            // addProjectManagerEmail: "",
            // addContractor: "",

            projectNameState: "success",
            initialValueState: "success",
            initialEndDateState: "success",
            initialStartDateState: "success",

            projectNameEdit: "",
            // initialValueEdit: "",
            commencementDateEdit: "",
            completionDateEdit: "",

            projects: [],
            // currentProjectManagers: [],

            showAddButton: false,
            showEditButton: false,
        };
    }


    //Function to route to add project page
    routeToAddProject(){
        let path = '/app/addProject';
        this.props.history.push({
            pathname: path ,
        });
    }

    //Function to route to View contract page
    routeToContractView(projectId){
        let path = '/app/contractView';
        this.props.history.push({
            pathname: path ,
            state: {
                projectId: projectId,
            }
        });
    }

    //Function that adds project manager to project
    // handleAddProjectManager = () => {
    //
    //     //this.setState({ addProjectManager: ""});
    //
    //     if(verifyEmail(this.state.addProjectManagerEmail)){
    //
    //         //let data = ;
    //
    //         //let arr = this.state.currentProjectManagers;
    //
    //         let newProjectManagerObj = {
    //             // user_id: 1,
    //             user_email: this.state.addProjectManagerEmail,
    //             project_id: this.state.projectRowKey
    //         };
    //
    //         let data = JSON.stringify(newProjectManagerObj);
    //
    //         const headers = new Headers({
    //             "Content-Type": "application/json",
    //         });
    //
    //         this.setState({
    //             loadingProjectManagers: true
    //         });
    //
    //         // setTimeout(() => {
    //             fetch(getApiRoot() + '/api/add_project_manager', {
    //                 credentials: "include",
    //                 method: "POST",
    //                 headers,
    //                 body: data,
    //             }).then(res => {
    //
    //                 // this.setState({
    //                 //     loading: false
    //                 // });
    //
    //                 if (res.status === 200) {
    //
    //                     this.setState({
    //                         addProjectManagerEmail: ""
    //                     });
    //
    //                     this.getProjectManagersAPI(this.state.projectRowKey);
    //
    //                 } else {
    //                     alert("Something went wrong - HTTP: " + res.status);
    //
    //                     this.setState({
    //                         loadingProjectManagers: false
    //                     });
    //                 }
    //
    //
    //             }).catch(error => alert(error));
    //         // }, 2000)
    //
    //        // arr.push(newProjectManagerObj);
    //
    //         //this.setState({currentProjectManagers: arr});
    //
    //         //this.setState({ openProjectManagerDialog: false });
    //     }
    //     else{
    //         alert("Invalid Email: " + this.state.addProjectManagerEmail);
    //     }
    // };

    //Function that removes project manager from project
    // handleRemoveProjectManager = (index) => {
    //
    //     // arr.splice(index, 1);
    //     // this.setState({ data: data} );
    //
    //     let removePMObj = {
    //         user_id : 1,
    //         user_remove_id: this.state.currentProjectManagers[index].id,
    //         project_id: this.state.projectRowKey
    //     };
    //
    //     let data = JSON.stringify(removePMObj);
    //
    //     const headers = new Headers({
    //         "Content-Type": "application/json",
    //     });
    //
    //     this.setState({
    //         loadingProjectManagers: true
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + '/api/delete_project_manager', {
    //             credentials: "include",
    //             method: "POST",
    //             headers,
    //             body: data,
    //         }).then(res => {
    //
    //             // this.setState({
    //             //     loading: false
    //             // });
    //
    //             if (res.status === 200) {
    //                 this.getProjectManagersAPI(this.state.projectRowKey);
    //
    //             } else {
    //                 alert("Something went wrong - HTTP: " + res.status);
    //             }
    //
    //
    //         }).catch(error => alert(error));
    //     // }, 2000)
    //
    //
    // };

    //Function that edit project details
    handleSubmitEditProject = () => {
        // alert(
        //     "Submitted data:  \n{ \nProject Name: " + this.state.projectNameEdit +
        //     ", \nInitial Value: " + this.state.initialValueEdit +
        //     ", \nInitial Start Date: " + this.state.commencementDateEdit +
        //     ", \nInitial End Date: " + this.state.completionDateEdit +
        //     "\n}"
        // );

        this.setState({
            loadingEditProject: true
        });

        let flag =  true;

        if (this.state.projectNameState === "") {
            this.setState({ projectNameState: "error" });
            flag = false;
        }

        // if (this.state.initialValueState === "") {
        //     this.setState({ initialValueState: "error" });
        //     flag = false;
        // }

        if (this.state.initialStartDateState === "") {
            this.setState({ initialStartDateState: "error" });
            flag = false;
        }
        if (this.state.initialEndDateState === "") {
            this.setState({ initialEndDateState: "error" });
            flag = false;
        }

        if(this.state.projectNameState === "error" || this.state.initialValueState === "error" || this.state.initialStartDateState === "error" || this.state.initialEndDateState === "error"){
            flag = false;
            alert("One or more empty fields");
        }

        if(flag) {

            // let data = this.state.projects;
            // data.forEach((o, i) => {
            //     if(o.id === this.state.projectRowKey)
            //     {
            //         data[i].title = this.state.projectNameEdit;
            //         data[i].initialValue = this.state.initialValueEdit;
            //         data[i].dateProjectCommences = this.state.commencementDateEdit;
            //         data[i].dateProjectCompletes = this.state.completionDateEdit;
            //     }
            // });

            let postData = {
                project_id: this.state.projectRowKey,
                //user_id: 1
                title: this.state.projectNameEdit,
                // initialValue: this.state.initialValueEdit,
                commences: this.state.commencementDateEdit,
                completes: this.state.completionDateEdit
            };

            let data = JSON.stringify(postData);

            const headers = new Headers({
                "Content-Type": "application/json",
            });

            // setTimeout(() => {
                fetch(getApiRoot() + '/api/update_project', {
                    credentials: "include",
                    method: "POST",
                    headers,
                    body: data,
                }).then(res => {

                    if (res.status === 200) {
                        this.getAllProjectsAPI();
                    } else {
                        alert("Something went wrong - HTTP: " + res.status);

                        this.setState({
                            loadingEditProject: false
                        });
                    }

                }).catch(error => alert(error));
            // }, 2000)


        }
        //event.preventDefault();
    };

    //Function cancel edit project dialog
    handleCancelEditProject= () => {
        this.setState({ openEditProjectDialog: false,
            projectNameState: "success",
            // initialValueState: "success",
            initialEndDateState: "success",
            initialStartDateState: "success",
        });
    };

    // function that handles change of edit project dialog input boxes
    change(event, stateName, type, stateNameEqualTo) {

        const id = event.target.id;

        if(id === "projectNameEditInput"){
            this.setState({
                projectNameEdit: event.target.value
            });
        }
        // else if(id === "projectInitialValueEditInput"){
        //     this.setState({
        //         initialValueEdit: event.target.value
        //     });
        // }
        else if(id === "projectInitialStartDateEditInput"){
            this.setState({
                commencementDateEdit: event.target.value
            });
        }
        else if(id === "projectInitialEndDateEditInput"){
            this.setState({
                completionDateEdit: event.target.value
            });
        }


        switch (type) {
            case "email":
                if (verifyEmail(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "number":
                if (verifyNumber(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "length":
                if (verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            default:
                break;
        }
    }

    //API that retrieves all project managers for a project
    // getProjectManagersAPI = (id) => {
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + "/api/project_managers/" + id, {
    //             credentials: "include",
    //             headers,
    //             method: "GET"
    //         }).then((res) => {
    //             // alert(res.status);
    //             if(res.status === 200)
    //                 return res.json();
    //         }).then(
    //                 (result) => {
    //                     this.setState({
    //                         currentProjectManagers: result,
    //                         loadingProjectManagers: false
    //                     });
    //                 },
    //                 (error) => {
    //                     alert(error);
    //
    //                     this.setState({
    //                         loadingProjectManagers: false
    //                     });
    //                 }
    //             )
    //     // },2000)
    // };

    //API that initially retrieves all projects
    getAllProjectsAPI = () => {
        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + "/api/projects", {
                credentials: "include",
                headers,
                method: "GET"
            })
                .then(res => {
                    if(res.status === 200){
                        return res.json();
                    }
                    else{
                        alert("Something went wrong - HTTP " + res.status);
                    }
                })
                .then(
                    (result) => {

                        if(result !== undefined){

                            this.setState({
                                // isLoaded: true,
                                projects: result,
                                openEditProjectDialog: false,
                                loadingEditProject: false
                            });


                            this.getPermissions();
                        }

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            openEditProjectDialog: false,
                            loadingEditProject: false,
                            error
                        });
                    }
                )
        // },2000)
    };

    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET"
            ,})
            .then(res => {
                    if(res.status === 200){
                        return res.json();
                    }
                    else{
                        this.setState({
                            isLoaded: true,
                        });

                        alert("Something went wrong - HTTP " + res.status);
                    }
                }
            )
            .then(
                (result) => {

                    if(result !== undefined){
                        this.setState({
                            isLoaded: true,
                            // permissions: result
                        });

                        this.setPermissions(result);
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let createProject = permissions.find(o => o.type === "project" && o.subtype === "create").permission;

            if(createProject === "true"){
                this.setState({
                    // showAddButton: true,
                    showAddButton: false,
                    showEditButton: true,
                });
            }
            else{
                this.setState({
                    showAddButton: false,
                    showEditButton: false,
                });
            }

        }
        else{
            this.setState({
                showAddButton: false,
                showEditButton: false,
            });
        }

    };

    //event that fires before page renders
    componentDidMount() {
        this.getAllProjectsAPI();
    }

    render() {
        const {classes} = this.props;
        const { error, isLoaded, /*loadingProjectManagers, */ loadingEditProject } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Shows loading wheel until initial API returns data or if an error occurs
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}} color={"primary"} className={classes.progress} /></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer>
                    {/*Toggle that switches to Taskview*/}
                    {/*<GridItem xs={12}>*/}
                    {/*    <ViewSwitch history={this.props.history} classes={classes} to="/app/taskView"*/}
                    {/*                label={"Project View"} state={true}/>*/}
                    {/*</GridItem>*/}
                    {/*Button that routes to add project page*/}
                    {this.state.showAddButton ?
                        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignInNavBar}>
                            <Button justIcon round color="primary" className={classes.buttonStyle}
                                    onClick={() => this.routeToAddProject()}><Add/></Button>
                        </GridItem> : ""
                    }
                    {/*All projects table*/}
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.cardAlignment}>
                            <CardBody>
                                <ReactTable

                                    data={this.state.projects}
                                    filterable
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "title",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },
                                        {
                                            Header: "Initial Value",
                                            accessor: "initialValue",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },
                                        {
                                            Header: "Revised Value",
                                            accessor: "revisedValue",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },
                                        {
                                            Header: "Commencement Date",
                                            accessor: "dateProjectCommences",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            //Column template for date
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Completion Date",
                                            accessor: "dateProjectCompletes",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            //Column template for date
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Revised Completion Date",
                                            accessor: "dateProjectCompletesRevised",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            //Column template for date
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "id",
                                            //Column template for management of project managers, edit project and view contracts
                                            Cell: row => (
                                                <div>
                                                    {/*<Tooltip*/}
                                                    {/*    title={"Edit Project Managers"}*/}
                                                    {/*    placement="top"*/}
                                                    {/*    classes={{ tooltip: classes.tooltip }}*/}
                                                    {/*>*/}
                                                    {/*    <Button*/}
                                                    {/*        size={"sm"}*/}
                                                    {/*        justIcon*/}
                                                    {/*        round*/}
                                                    {/*        simple*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            let obj = this.state.projects.find(o => o.id === row.value);*/}
                                                    {/*            this.setState({*/}
                                                    {/*                openProjectManagerDialog: true,*/}
                                                    {/*                projectRowKey: obj.id,*/}
                                                    {/*                loadingProjectManagers: true*/}
                                                    {/*            });*/}

                                                    {/*            this.getProjectManagersAPI(obj.id);*/}
                                                    {/*        }}*/}
                                                    {/*        color="primary"*/}
                                                    {/*    >*/}
                                                    {/*        <Person/>*/}
                                                    {/*    </Button>*/}
                                                    {/*</Tooltip>*/}

                                                    {this.state.showEditButton ?
                                                    <Tooltip
                                                        title={"Edit Project"}
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button
                                                            size={"sm"}
                                                            justIcon
                                                            round
                                                            simple
                                                            onClick={() => {
                                                                let obj = this.state.projects.find(o => o.id === row.value);

                                                                // let today = new Date();
                                                                // let dd = String(today.getDate()).padStart(2, '0');
                                                                // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                                // let yyyy = today.getFullYear();
                                                                //
                                                                // today = mm + '-' + dd + '-' + yyyy;

                                                                let commenceDate = "";
                                                                let completionDate = "";

                                                                if(obj.dateProjectCommences !== null) {
                                                                    // let commenceDateArr = new Date(obj.dateProjectCommences).toISOString().split("T");
                                                                    // commenceDate = commenceDateArr[0];

                                                                    commenceDate = new Date(obj.dateProjectCommences);
                                                                    let dd = String(commenceDate.getDate()).padStart(2, '0');
                                                                    let mm = String(commenceDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                                    let yyyy = commenceDate.getFullYear();

                                                                    commenceDate = yyyy + '-' + mm + '-' + dd;
                                                                }

                                                               if(obj.dateProjectCompletes !== null){
                                                                   // let completionDateArr = new Date(obj.dateProjectCompletes).toISOString().split("T");
                                                                   // completionDate = completionDateArr[0];

                                                                   completionDate = new Date(obj.dateProjectCompletes);
                                                                   let dd = String(completionDate.getDate()).padStart(2, '0');
                                                                   let mm = String(completionDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                                   let yyyy = completionDate.getFullYear();

                                                                   completionDate = yyyy + '-' + mm + '-' + dd;
                                                               }

                                                                this.setState({
                                                                    projectRowKey: obj.id,
                                                                    projectNameEdit: obj.title,
                                                                    // initialValueEdit: obj.initialValue,
                                                                    commencementDateEdit: commenceDate,
                                                                    completionDateEdit: completionDate,
                                                                });


                                                                this.setState({openEditProjectDialog: true});
                                                            }}
                                                            color="info"
                                                        >
                                                            <Create/>
                                                        </Button>
                                                    </Tooltip> : ""}
                                                    <Tooltip
                                                        title={"Contracts"}
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button
                                                            size={"sm"}
                                                            justIcon
                                                            round
                                                            simple
                                                            onClick={() => {
                                                                let obj = this.state.projects.find(o => o.id === row.value);
                                                                this.routeToContractView(obj.id);
                                                            }}
                                                            color="github"
                                                        >
                                                            <LibraryBooksIcon/>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            ),
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            filterable: false,
                                            sortable: false,
                                        }

                                    ]}

                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/*Project Managers Dialog*/}
                    {/*<Dialog*/}
                    {/*    open={this.state.openProjectManagerDialog}*/}
                    {/*    onClose={() => {*/}
                    {/*        this.setState({openProjectManagerDialog: false});*/}
                    {/*    }}*/}
                    {/*    aria-labelledby="projectManagerDialog"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="projectManagerDialog">Project Managers</DialogTitle>*/}
                    {/*    <DialogContent style={{width: 400}}>*/}
                    {/*        <div style={{marginBottom: 0}}>*/}
                    {/*            <p style={{fontSize: 14, marginTop: 20}}>*/}
                    {/*                Current Project Manager(s):*/}
                    {/*            </p>*/}
                    {/*            {loadingProjectManagers && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }*/}
                    {/*            <List>*/}
                    {/*                {*/}
                    {/*                    this.state.currentProjectManagers.map((item, index) =>*/}
                    {/*                        <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}} key={index}>*/}
                    {/*                            {item.email}*/}
                    {/*                            <ListItemSecondaryAction>*/}
                    {/*                                <Close className={classes.danger} style={{cursor: "pointer", fontSize: 16, marginTop: 5}} onClick={() => this.handleRemoveProjectManager(index)}/>*/}
                    {/*                            </ListItemSecondaryAction>*/}
                    {/*                        </ListItem>)*/}
                    {/*                }*/}

                    {/*            </List>*/}

                    {/*            <CustomInput*/}
                    {/*                id="projectMangerInput"*/}
                    {/*                labelText="Add Project Manager"*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true*/}
                    {/*                }}*/}
                    {/*                inputProps={{*/}
                    {/*                    value: this.state.addProjectManagerEmail,*/}
                    {/*                    onChange: (event) =>*/}
                    {/*                        this.setState({addProjectManagerEmail: event.target.value}),*/}
                    {/*                    type: "email",*/}
                    {/*                    endAdornment: (*/}
                    {/*                        <Add onClick={() => this.handleAddProjectManager()}*/}
                    {/*                             style={{cursor: "pointer", fontSize: 16, marginTop: 5}}/>)*/}
                    {/*                }}*/}
                    {/*            />*/}

                    {/*        </div>*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => {*/}
                    {/*            this.setState({openProjectManagerDialog: false, currentProjectManagers: [], addProjectManagerEmail: ""});*/}
                    {/*        }}*/}
                    {/*                color="info">*/}
                    {/*            Close*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}
                    {/*Edit Project Dialog*/}
                    <Dialog
                        open={this.state.openEditProjectDialog}
                        onClose={() => {
                            this.setState({openEditProjectDialog: false});
                        }}
                        aria-labelledby="editProjectDialog"
                    >
                        <DialogTitle id="editProjectDialog">Edit Project</DialogTitle>
                        <DialogContent style={{width: 400}}>
                            <div>
                                <GridContainer>
                                    {/*Project Name Edit*/}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <CustomInput
                                            id="projectNameEditInput"
                                            labelText="Project Name *"
                                            success={this.state.projectNameState === "success"}
                                            error={this.state.projectNameState === "error"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                style: {marginBottom: 15},
                                                value: this.state.projectNameEdit,
                                                onChange: event =>
                                                    this.change(event, "projectName", "length", 1),
                                                type: "text",
                                                endAdornment:
                                                    this.state.projectNameState === "error" ? (
                                                        <InputAdornment position="end">
                                                            <Close className={classes.danger}/>
                                                        </InputAdornment>
                                                    ) : (
                                                        undefined
                                                    )
                                            }}
                                        />
                                    </GridItem>
                                        {/*Project Initial Value Edit*/}
                                    {/*<GridItem xs={12} sm={12} lg={12}>*/}
                                    {/*    <CustomInput*/}
                                    {/*        id="projectInitialValueEditInput"*/}
                                    {/*        labelText="Initial Value *"*/}
                                    {/*        success={this.state.initialValueState === "success"}*/}
                                    {/*        error={this.state.initialValueState === "error"}*/}
                                    {/*        formControlProps={{*/}
                                    {/*            fullWidth: true*/}
                                    {/*        }}*/}
                                    {/*        inputProps={{*/}
                                    {/*            style: {marginBottom: 15},*/}
                                    {/*            value: this.state.initialValueEdit,*/}
                                    {/*            // onChange={this.handleInputChange}*/}
                                    {/*            onChange: event =>*/}
                                    {/*                this.change(event, "initialValue", "number"),*/}
                                    {/*            type: "number",*/}
                                    {/*            endAdornment:*/}
                                    {/*                this.state.initialValueState === "error" ? (*/}
                                    {/*                    <InputAdornment position="end">*/}
                                    {/*                        <Close className={classes.danger}/>*/}
                                    {/*                    </InputAdornment>*/}
                                    {/*                ) : (*/}
                                    {/*                    undefined*/}
                                    {/*                )*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</GridItem>*/}
                                    {/*Project Commencement Date Edit*/}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <CustomInput
                                            id="projectInitialStartDateEditInput"
                                            labelText="Commencement Date *"
                                            labelProps={{
                                                shrink: true
                                            }}
                                            success={this.state.initialStartDateState === "success"}
                                            error={this.state.initialStartDateState === "error"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                style: {marginBottom: 15},
                                                value: this.state.commencementDateEdit,
                                                // onChange={this.handleInputChange}
                                                onChange: event =>
                                                    this.change(event, "initialStartDate", "length", 1),
                                                type: "date",
                                                endAdornment:
                                                    this.state.initialStartDateState === "error" ? (
                                                        <InputAdornment position="end">
                                                            <Close className={classes.danger}/>
                                                        </InputAdornment>
                                                    ) : undefined
                                            }}
                                        />
                                    </GridItem>
                                    {/*Project Completion Date Edit*/}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <CustomInput
                                            id="projectInitialEndDateEditInput"
                                            labelText="Completion Date *"
                                            labelProps={{
                                                shrink: true
                                            }}
                                            success={this.state.initialEndDateState === "success"}
                                            error={this.state.initialEndDateState === "error"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                style: {marginBottom: 15},
                                                value: this.state.completionDateEdit,
                                                // onChange={this.handleInputChange}
                                                onChange: event =>
                                                    this.change(event, "initialEndDate", "length", 1),
                                                type: "date",
                                                endAdornment:
                                                    this.state.initialEndDateState === "error" ? (
                                                        <InputAdornment position="end">
                                                            <Close className={classes.danger}/>
                                                        </InputAdornment>
                                                    ) : undefined
                                            }}
                                        />
                                    </GridItem>
                                    {/*Required fields marker*/}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <p style={{color: "rgba(0, 0, 0, 0.54)", float: "right"}}>Required fields marked
                                            with *</p>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCancelEditProject()} color="info">
                                Cancel
                            </Button>
                            <div style={{position: "relative"}}>
                                <Button onClick={() => this.handleSubmitEditProject()} color="primary" disabled={loadingEditProject}>
                                    Update
                                </Button>
                                {loadingEditProject && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }
                            </div>
                        </DialogActions>
                    </Dialog>
                </GridContainer>
            );
        }
    }
}
export default withStyles(styles)(ProjectView);
