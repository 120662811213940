import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import { pdfjs } from "react-pdf";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import ErrorNotification from "./components/Notifications/ErrorNotification";
import SuccessNotification from "./components/Notifications/SuccessNotification";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";

import store from './createStore';
import axiosInstance from "./axiosInstance";

// store.dispatch(loginSuccess())

// For common config
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.defaults.headers.get["Accept"] = "application/json";

axiosInstance.interceptors.response.use(function(response) {
    return response;
}, function (error) {
    if(error.response.status === 401){
        hist.push("/auth/loginPage");
    }
    return Promise.reject(error);
});

const hist = createBrowserHistory();
const persistedStore = persistStore(store);

// require('dotenv').config();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistedStore} loading={null}>
        <Router history={hist}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/app" component={AdminLayout} />
            <Redirect from="/" to="/auth/loginPage" />
          </Switch>
        </Router>
      <ErrorNotification />
      <SuccessNotification />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
