import React from "react";
import Tabs from "../../../components/CustomTabs/CustomTabs.jsx";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actionCreators from "../../../store/actions/tab";
import {connect} from "react-redux";
import AccessArrangedRegister from "./AccessArrangedRegister";
import UncorrectedDefectRegister from "./UncorrectedDefectRegister";
import UncorrectedDefectSecondRegister from "./UncorrectedDefectSecondRegister";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";

class OtherRegistersTabs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stateLoaded : false,
        };
    }

    componentWillUnmount() {
        this.props.onSetSelectedTab(0);
    }

    componentDidMount() {
        if(!this.props.location.state){
            this.setState({
                stateLoaded : true
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { stateLoaded } = this.state;
        return (
            stateLoaded && <GridContainer>
                {/*tabs*/}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Tabs
                        plainTabs={true}
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: "43.4 Register",
                                tabContent: (
                                    <AccessArrangedRegister classes={classes}/>
                                )
                            },
                            {
                                tabName: "45.1 Register",
                                tabContent: (
                                    <UncorrectedDefectRegister classes={classes}/>
                                )
                            },
                            {
                                tabName: "45.2 Register",
                                tabContent: (
                                    <UncorrectedDefectSecondRegister classes={classes}/>
                                )
                            },

                        ]}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        value: state.tabReducer.value
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(compensationEventTabsStyles)(OtherRegistersTabs));
