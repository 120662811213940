import React from 'react';
import { withRouter} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import * as actionCreators from "../../store/actions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core/styles";
import * as PropTypes from "prop-types";

const styles = {
    loadingWheel : {
        position: 'absolute',
        marginLeft: -24
    },
};

class CustomDashBoardToDoItemTimeBarNotification extends React.Component{

    getReduxStateName = () => {
        let number = this.props.item.type.toLowerCase() === "ce" ? this.props.item.compensationEvent.ceNumber: this.props.item.compensationEventNotification.cenNumber

        return "time_bar_notification_" + number;
    };

    callSendClauseAPI = () => {

        let payload = {
            url: "/time_bar_notification",
            id: this.getReduxStateName(),
        }

        if(this.props.item.type.toLowerCase() === "ce"){
            payload.data = JSON.stringify({"compensationEvent": this.props.item.compensationEvent.id, "eventState": this.props.item.eventState.id});
        }
        else {
            payload.data = JSON.stringify({"compensationEventNotification": this.props.item.compensationEventNotification.id, "eventState": this.props.item.eventState.id});
        }

        this.props.onSendTimeBarNotification(payload).then(() => {
            if(this.props.item.type.toLowerCase() === "ce"){
                this.props.onSetSuccess("Clause 62.6 has been sent.")
            }
            else {
                this.props.onSetSuccess("Clause 61.4 has been sent.")
            }

        })
    }

    render(){
        const { classes, sendTimeBarNotificationError, sendTimeBarNotificationLoading, item } = this.props;
        if(sendTimeBarNotificationError[this.getReduxStateName()])
            return null;
        else
            {
        return (
            <div>
                <Card style={{marginTop: 0, marginBottom: 20}}>
                    <CardBody style={{padding: 0}}>
                        <GridContainer>
                            {/* Priority 1: CE/CEN Number*/}
                            <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                                <div style={{padding: "0 0 0 10px"}}>
                                    <Tooltip
                                        title={item.type.toLowerCase() === "ce" ? "CE Number": "CEN Number"}
                                        placement="top"
                                    >
                                        <p style={{margin: "5px 0 0 0", fontSize: 10}}>{item.type.toLowerCase() === "ce" ? item.compensationEvent.ceNumber: item.compensationEventNotification.cenNumber }</p>
                                    </Tooltip>
                                </div>
                            </GridItem>

                            {/* Priority 1: To Do Description*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <div style={{padding: "0 10px"}}>
                                    <Tooltip
                                        title={item.eventState.description}
                                        placement="top"
                                    >
                                        <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}}>{item.eventState.description}</h4>
                                    </Tooltip>
                                </div>
                            </GridItem>


                            <GridItem xs={12} sm={12} lg={12}>
                                <div style={{padding: "0 10px"}}>
                                    <hr style={{margin: 0}}/>
                                    <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "5px 0 5px 0"}} disabled={sendTimeBarNotificationLoading[this.getReduxStateName()]} onClick={() => this.callSendClauseAPI()}>Send</Button>
                                    {sendTimeBarNotificationLoading[this.getReduxStateName()] && <CircularProgress size={24} className={classes.loadingWheel} /> }
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
        }
    }
}

//set up prop types to suppress warnings
CustomDashBoardToDoItemTimeBarNotification.propTypes = {
    item: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        sendTimeBarNotificationLoading: state.toDoReducer.sendTimeBarNotificationLoading,
        sendTimeBarNotificationError: state.toDoReducer.sendTimeBarNotificationError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendTimeBarNotification: payload => dispatch(actionCreators.sendTimeBarNotification(payload)),
        onSetSuccess: payload => dispatch(actionCreators.setSuccessMessage(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(CustomDashBoardToDoItemTimeBarNotification)));
