// import axios from "axios";

export function getApiRoot(){

  let hostUrl = '';

  // if(process.env.REACT_APP_HOST_ENV === "staging"){
  //   hostUrl = 'https://contractly-api-staging.herokuapp.com';
  // }
  // else if(process.env.REACT_APP_HOST_ENV === "production"){
  //   hostUrl = 'https://contractly-api.herokuapp.com';
  // }
  // return (window.location.href.indexOf('localhost') !== -1) ? '' : 'https://contractly-api.herokuapp.com';
  return hostUrl;

}

export function getPermissionsEndpoint (){
  return "/permissions";
}


// function to perform api calls to backend
//to be changed to axios in the future, and to be made more generic to be used for the entire project
export async function communicationsAPI (obj, url, isHeadersRequired, method, urlPar, isFileUpload, isBlob, returnFileName, tempVar=false) {
  let init = {};

  if (method === "POST") {

    let data = {};

    if (isFileUpload) {
      data = obj;
    } else {
      data = JSON.stringify(obj);
    }

    if (isHeadersRequired) {

      let headers = new Headers({
        "Content-Type": "application/json",
      });

      init = {
        credentials: "include",
        method: method,
        headers,
        body: data
      }

    } else {
      init = {
        credentials: "include",
        method: method,
        body: data
      };
    }
  }
  else {

    if (isHeadersRequired) {
      const headers = new Headers({
        "Accept": "application/json",
      });

      init = {
        credentials: "include",
        method: method,
        headers,
      };
    }
    else {
      init = {
        credentials: "include",
        method: method,
      };
    }

    if(urlPar) {
      url += '/' + obj;
    }

  }
  const response = await fetch(getApiRoot() + '/api' + url, init);

  if (!response.ok) {
    return response.status;
  }

  if(returnFileName && isBlob){

    let fileName = "Contractly Document.pdf";

    if(response.headers.get('Content-Disposition') !== null) {
      let strArr = response.headers.get('Content-Disposition').toString().split("\"");

      if(strArr.length > 1)
        fileName = strArr[1];
    }

    const resultBlob = await response.blob();

    return {
      fileName : fileName,
      data : resultBlob
    };
  }
  else if(!returnFileName && isBlob){
    return await response.blob();
  }
  else if (tempVar){
    return response;
  }
  else{
    return await response.json();
  }

}

// function to perform api calls to backend
//to be changed to axios in the future, and to be made more generic to be used for the entire project
export function communicationsJSONPromiseAPI (obj, url, isHeadersRequired, method, urlPar, isFileUpload) {
  let init = {};

  if (method === "POST") {

    let data = {};

    if (isFileUpload) {
      data = obj;
    } else {
      data = JSON.stringify(obj);
    }

    if (isHeadersRequired) {

      let headers = new Headers({
        "Content-Type": "application/json",
      });

      init = {
        credentials: "include",
        method: method,
        headers,
        body: data
      }

    } else {
      init = {
        credentials: "include",
        method: method,
        body: data
      };
    }
  }
  else {

    if (isHeadersRequired) {
      const headers = new Headers({
        "Accept": "application/json",
      });

      init = {
        credentials: "include",
        method: method,
        headers,
      };
    }
    else {
      init = {
        credentials: "include",
        method: method,
      };
    }

    if(urlPar) {
      url += '/' + obj;
    }

  }
  return fetch(getApiRoot() + '/api' + url, init)
      .then((response) => {
        if (!response.ok) {
          throw response.status.toString();
        }
        return response.json();
      })
      .then((json) => {
        return json;
      });
}

//to be changed to axios in the future
export function promiseAPI(url){

  const headers = new Headers({
    "Accept": "application/json",
  });

  // setTimeout(() => {
  return fetch(getApiRoot() + "/api" + url, {
    credentials: "include",
    headers,
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw response.status.toString();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    });

}