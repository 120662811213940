import React from 'react';
import reactStringReplace from 'react-string-replace';

// function that verifies if a string has a given length or not
export function verifyLength(value, length, itemField) {
    if (itemField && itemField === "title")
        return value.length >= length && value.length <= 100;
    return value.length >= length;
}

// function that verifies if value contains only numbers
export function verifyNumber(value) {
    let numberRex = new RegExp("^[0-9]+$");
    return numberRex.test(value);
}

// function that verifies if value is a valid decimal number
export function verifyDecimal(value) {
    let numberRex = new RegExp("^\\d+(\\.\\d{1,2})?$");
    return numberRex.test(value);
}

// function that verifies if value is a valid email
export function verifyEmail(value) {
    let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (emailRex.test(value));
}

// function that verifies if date is valid
export function verifyDate(value) {
    return value instanceof Date && !isNaN(value);
}

// add zero to number if days or months are less than 10
export function addZero(i){
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

// get days in string array
export function daysArray(){
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
}

// get months in string array
export function monthsArray(){
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}

// function to return today's date
export function getCurrentDate () {

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //As January is 0.
    let yyyy = today.getFullYear();

    if(dd < 10) dd = '0'+ dd;
    if(mm < 10) mm = '0'+ mm;

    return (yyyy + '-' + mm + '-' + dd);
}

// function to return date value as datetime
export function formatLongDate (dateVal) {

    let months = monthsArray();

    return new Date(dateVal).getDate() + " " + months[new Date(dateVal).getMonth()] + " " + new Date(dateVal).getFullYear() + " at " +  addZero(new Date(dateVal).getHours()) + ":" + addZero(new Date(dateVal).getMinutes()) + ":" + addZero(new Date(dateVal).getSeconds());
}

// function to return date value as date
export function formatShortDate (dateVal) {

    let months = monthsArray();

    return new Date(dateVal).getDate() + " " + months[new Date(dateVal).getMonth()] + " " + new Date(dateVal).getFullYear();
}

// function to check whether item is last in the array
export function checkIfLastInArray (size, index) {
   return index === size - 1;
}

// set the first letter in a word to uppercase
export function firstToUpper(word){
    return word.charAt(0).toUpperCase() + word.substring(1);
}

// set the first letter in a word to uppercase
export function firstToLower(word){
    return word.charAt(0).toLowerCase() + word.substring(1);
}

// remove all whitespaces from a word/sentence
export function removeAllWhiteSpace(sentence){
    return sentence.replace(/ /g,'');
}


export function actionStateName(type) {
    //set loading wheel
    return type === "send" ? "loadingSendWheel" : "loadingSendDownloadWheel";
}

const WORDS_TO_ITALIC = [
    ' contractor',
    'project manager',
    'supervisor',
    'employer'
];

export function replaceSubStringWithItalics(sentence){

    const wordsToConvert = WORDS_TO_ITALIC;
    let replacedText = sentence;

    wordsToConvert.forEach((item) => {
        var regex = new RegExp(`(${item})`, 'gi');
        
        replacedText = reactStringReplace(replacedText, regex, (match, i) => {
            return <span key={item + i} style={{ fontStyle: 'italic' }}>{match}</span>;
        });

    });

    return replacedText;

}

// function handleErrors(response) {
//     if (!response.ok) {
//         throw new Error(response.status);
//     }
//     return response;
// }
