import validationFormsStyle from "../jss/material-dashboard-pro-react/views/validationFormsStyle";

const communicationSubmitForm = {
    ...validationFormsStyle,
    card : {
        margin : "10px 0 0 0",
        paddingTop : 15
    },
    submitButtonContainer : {
        position: "relative", float: "right", marginTop: 15
    },
    submitButton : {
        float: "right"
    },
    loadingWheel : {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        marginTop: 15,
        // marginLeft: -12
    },
    previewLoadingWheel : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    relativeDiv : {
        position: 'relative',
    },
    requiredDescription : {
        marginTop: 30,
        marginLeft: 15,
        fontSize: 12,
        fontStyle : "italic"
    },
    input : {
        '& input::-webkit-calendar-picker-indicator' : { position: "absolute", left: 0, marginLeft: 0 },
        '& ::-webkit-datetime-edit' : { position: "relative", left: 16 },
        '& ::-webkit-datetime-edit-fields-wrapper': { position: "relative", left: 16 }
    }
};

export default communicationSubmitForm;
