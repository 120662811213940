import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import communicationDetailedPageStyles from "../../../../assets/styles/communicationDetailedPageStyles";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card";
import NotificationRevisions from "../../../../components/Communications/Notifications/NotificationRevisions";
import { withRouter } from 'react-router-dom';
import { firstToUpper, formatShortDate } from "../../../../globals";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import Add from "@material-ui/icons/Add";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import NotificationCreateForm from "../../../../components/Communications/Notifications/NotificationCreateForm";
import AttachmentsTable from "../../../../components/Table/AttachmentsTable";
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';

class NotificationDetailedPage extends Component {

    //set state to be used
    state = {
        openSubmitCollapse: false
    };

    //handle open and close of accordion
    handleToggleCollapse = () => {
        this.setState(prevState => ({ openSubmitCollapse: !prevState.openSubmitCollapse }));
    };

    //only generate certain fields to display
    validateFieldType = (item) => {
        // return (item.type !== "email" && item.type !== "contract" && item.type !== "attachment");
        return (item.type !== "email" && item.type !== "contract" && item.type !== "clauseRadio" && item.type !== "default");
    };

    //find the latest revision by finding the item in the array with the largest id
    // findLatestRevision = (arr) => {
    //     let id = Math.max.apply(Math, arr.map(o => o.id));
    //     return !isNaN(id) ? arr.findIndex(o => o.id === id) : -1;
    // };
    // Sort revisions array to find latest revision
    findLatestRevisionIndex = (arr) => {
        arr.sort((a, b) => b.id - a.id);
        return 0;
    };

    returnLatestRevision = () => {
        let revisionsArray = this.props.details[this.props.location.state.type + "Revisions"];
        let latestRevisionIndex = this.findLatestRevisionIndex(revisionsArray);
        return revisionsArray[latestRevisionIndex].id;
    }

    getCurrentRevision = () => {
        let revisionsArray = this.props.details[this.props.location.state.type + "Revisions"];
        if (revisionsArray && revisionsArray.length > 0) {
            if (this.props.location.state.clauseData.revisionIsResponse) {
                const latestRevisionIndex = this.findLatestRevisionIndex(revisionsArray);
                const latestRevision = revisionsArray[latestRevisionIndex];
                return latestRevision[this.props.location.state.type + "RevisionResponse"] && latestRevision[this.props.location.state.type + "RevisionResponse"].length > 0 ? latestRevision[this.props.location.state.type + "RevisionResponse"].length : 0;
            }
            return revisionsArray.length;
        }
        return 0;
    };

    getCurrentDateForResponse = () => {
        let revisionsArray = this.props.details[this.props.location.state.type + "Revisions"];
        let index =  revisionsArray && revisionsArray.length > 0 ? this.findLatestRevisionIndex(revisionsArray) : -1;
        return index !== -1 && this.props.details[this.props.location.state.type + "Revisions"][index]["periodOfReplyDate"] ? formatShortDate(revisionsArray[index]["periodOfReplyDate"]) : "N/A";
    };

    generateComponent = (item, key) => {
        if (item.type === "attachment") {
            return (
                <GridItem xs={12} sm={12} lg={12} style={{marginBottom: 15}} key={key}>
                    <p><strong>Attachments</strong></p>
                    <AttachmentsTable attachments={this.props.details[item.field]} />
                </GridItem>
            );
        }
        else if(item.type === "date"){
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <p><strong>{item.labelText}: </strong> {this.props.details[item.field] && this.props.details[item.field] !== "" && formatShortDate(this.props.details[item.field]) ? formatShortDate(this.props.details[item.field]) : "None"}</p>
                </GridItem>
            );
        }
        else if(item.type === "defect"){
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <p><strong>{item.labelText}: </strong> {this.props.details[item.field] && this.props.details[item.field].title ? this.props.details[item.field].title : "None"}</p>
                </GridItem>
            );
        }
        else {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <p><strong>{item.labelText}: </strong> {this.props.details[item.field] && this.props.details[item.field] !== "" ? this.props.details[item.field] : "None"}</p>
                </GridItem>
            );
        }
    };

    getSubmitFormPermission = (subtype) => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            const permissionObject = this.props.permissions.find(o => o.type === (this.props.location.state.type + "Revision") && o.subtype === subtype);
            if(permissionObject && permissionObject.permission === "true"){
                if(permissionObject.clauses === null){
                    return true;
                }
                else{
                    return permissionObject.clauses.find(clauseNumber => clauseNumber === this.props.location.state.clauseData.clauseNumber) ? true : false;
                }
            }
        }
        return false;
    };

    shouldSubmitFormShow = () => {
        let revisionsArray = this.props.details[this.props.location.state.type + "Revisions"];
        let showFormPermission = false;
        let showFormState = false;

        if (revisionsArray && revisionsArray.length !== 0) {
            const latestRevisionIndex = this.findLatestRevisionIndex(revisionsArray);
            if(latestRevisionIndex !== -1){
                const latestRevision = revisionsArray[latestRevisionIndex];
                const revisionIsResponse = this.props.location.state.clauseData.revisionIsResponse;
                if (revisionIsResponse) {
                    const responseObjectName = this.props.location.state.type + "RevisionResponse";
                    let responseExists = latestRevision[responseObjectName] && latestRevision[responseObjectName].length > 0;
                    let latestResponseObject = responseExists && this.findLatestRevisionIndex(latestRevision[responseObjectName]) !== -1 ? latestRevision[responseObjectName][this.findLatestRevisionIndex(latestRevision[responseObjectName])] : null;
                    let responseRejected = responseExists && latestResponseObject.status && latestResponseObject.status.statusValue && latestResponseObject.status.statusValue.toLowerCase() === "rejected";

                    showFormState = this.props.location.state.clauseData.hasRevisions && (responseRejected || !responseExists);
                    showFormPermission = this.getSubmitFormPermission("response");
                }
                else {
                    showFormState = this.props.location.state.clauseData.hasRevisions && latestRevision.status && latestRevision.status.toLowerCase() === "rejected";
                    showFormPermission = this.getSubmitFormPermission("create");
                }
            }
        }
        return showFormPermission && showFormState;
    };

    shouldRespondActionsShow = () => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            let responseObjectName = this.props.location.state.type + "Revision";
            const revisionIsResponse = this.props.location.state.clauseData.revisionIsResponse;
            if (revisionIsResponse) {
                responseObjectName = this.props.location.state.type + "RevisionResponse";
            }
            const permissionObject = this.props.permissions.find(o => o.type === responseObjectName && o.subtype === "response");
            if(permissionObject && permissionObject.permission === "true"){
                if(permissionObject.clauses === null){
                    return true;
                }
                else{
                    return permissionObject.clauses.find(clauseNumber => clauseNumber === this.props.location.state.clauseData.clauseNumber) ? true : false;
                }
            }
        }
        return false;
    };

    componentDidMount() {
        const payload = {
            url: this.props.location.state.apiEndpoints.details + "/" + this.props.location.state.id,
        };
        this.props.onGetDetailsAndPermissions(payload);
    }
    render() {
        const { classes, permissionsLoading, permissionsError, detailsErrors, detailsLoading, details, location } = this.props;

        const smallFontNoMargin = classes.smallFont;
        // const smallFontNoMarginFloatRight = classes.smallFont + " " + classes.floatRight;
        const loadingWheelIncludingDefault = classes.progress + " " + classes.loadingWheel;

        //display fallback ui if there is an error on the page
        if (permissionsError || detailsErrors) {
            return null;
        }
        else if (detailsLoading || permissionsLoading) {
            //Loading wheel for initial API request
            return (
                <GridContainer>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                        <CircularProgress color={"primary"} className={loadingWheelIncludingDefault} />
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                <GridContainer justify={"center"}>

                    {/* Main Container*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignFirstCard}>

                        <Card className={classes.infoCard}>
                            <GridContainer>

                                {/* Clause Number */}
                                <GridItem xs={12} sm={12} lg={12}>
                                    <p className={smallFontNoMargin}>Clause {details.clause} : {firstToUpper(location.state.type) + " " + details[location.state.type + "NumberInteger"]}</p>
                                </GridItem>

                                {/* Generate fields that could differ per clause */}
                                {location.state.clauseData.dataFields.map((item, key) => {
                                    if (location.state.clauseData.hasRevisions && (!item.revisionField || item.displayInitialDetails)) {
                                        if (this.validateFieldType(item)) {
                                            return this.generateComponent(item, key);
                                        }
                                    }
                                    return null;
                                })}

                                {/* Date Created */}
                                <GridItem xs={12} sm={12} lg={12}>
                                    <p><strong>Date Submitted: </strong> {details.dateCreated !== null ? formatShortDate(details.dateCreated) : ""}</p>
                                </GridItem>

                                {/* Current Revision */}
                                {location.state.clauseData.hasRevisions && details[location.state.type + "Revisions"] && details[location.state.type + "Revisions"].length > 0 ?
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <p><strong>Current {location.state.clauseData.displayData.details}:</strong> {this.getCurrentRevision()}</p>
                                    </GridItem>
                                    : null}

                                {/* Date for response */}
                                {location.state.clauseData.hasRevisions && details[location.state.type + "Revisions"] && details[location.state.type + "Revisions"].length !== 0 ?
                                    <GridItem xs={12} sm={6} lg={6}>
                                        <p><strong>Current date for response: </strong> {this.getCurrentDateForResponse()} </p>
                                    </GridItem> : null}

                            </GridContainer>
                        </Card>

                        {/* Show submit new revision dropdown */}
                        {this.shouldSubmitFormShow() ?
                            <GridContainer>
                                <GridItem xs={12} sm={12} lg={12}>
                                    <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse()}>
                                        <CardBody>
                                            <p className={classes.collapseParagraph}><Add style={{ marginBottom: -3, fontSize: 16 }} /> {location.state.clauseData.displayData.submit.revision}</p>
                                            <Button className={classes.collapseButton} fullWidth>{!this.state.openSubmitCollapse ? <ExpandMore className={classes.collapseIcon} /> : <ExpandLess className={classes.collapseIcon} />}</Button>
                                        </CardBody>
                                    </Card>

                                    {/* content of accordion */}
                                    <Collapse in={this.state.openSubmitCollapse} timeout="auto" unmountOnExit>
                                        <NotificationCreateForm
                                            type={location.state.type}
                                            clauseData={location.state.clauseData}
                                            apiEndpoints={location.state.apiEndpoints}
                                            isRevision={true}
                                            initialData={details}
                                            id={this.props.location.state.clauseData.revisionIsResponse ? this.returnLatestRevision() : location.state.id}
                                            communicationId={location.state.id}
                                        />
                                    </Collapse>
                                </GridItem>
                            </GridContainer>
                            : null}

                        {/* show details */}
                        {details[this.props.location.state.type + "Revisions"] && details[this.props.location.state.type + "Revisions"].length !== 0 ?
                            details[this.props.location.state.type + "Revisions"].sort((a, b) => b.id - a.id).map((revisionItem, revisionIndex) => {
                                if (this.props.location.state.clauseData.revisionIsResponse) {
                                    return revisionItem[this.props.location.state.type + "RevisionResponse"] && revisionItem[this.props.location.state.type + "RevisionResponse"].length !== 0 ?
                                        revisionItem[this.props.location.state.type + "RevisionResponse"].map((responseItem, responseIndex) => {
                                            let openCollapse = responseIndex === 0;
                                            return <NotificationRevisions
                                                revisionData={responseItem}
                                                open={openCollapse}
                                                key={responseIndex}
                                                type={location.state.type}
                                                revisionId={responseItem.id}
                                                revisionDataIfResponse={revisionItem}
                                                revisionNumber={revisionItem[this.props.location.state.type + "RevisionResponse"].length - responseIndex}
                                                apiEndpoints={location.state.apiEndpoints}
                                                clauseData={location.state.clauseData}
                                                communicationId={location.state.id}
                                                allowedToRespond={this.shouldRespondActionsShow()} />
                                        }) : null
                                }
                                else {
                                    let openCollapse = revisionIndex === 0;
                                    return <NotificationRevisions
                                        revisionData={revisionItem}
                                        revisionId={revisionItem.id}
                                        open={openCollapse}
                                        key={revisionIndex}
                                        type={location.state.type}
                                        revisionNumber={details[this.props.location.state.type + "Revisions"].length - revisionIndex}
                                        apiEndpoints={location.state.apiEndpoints}
                                        clauseData={location.state.clauseData}
                                        communicationId={location.state.id}
                                        allowedToRespond={this.shouldRespondActionsShow()} />
                                }

                            }) : null}

                    </GridItem>
                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.userReducer.permissions,
        permissionsLoading: state.userReducer.permissionsLoading,
        permissionsError: state.userReducer.permissionsError,
        details: state.communicationReducer.details,
        detailsLoading: state.communicationReducer.detailsLoading,
        detailsErrors: state.communicationReducer.detailsErrors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetDetailsAndPermissions: (payload) => dispatch(actionCreators.getPermissionsAndDetails(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationDetailedPageStyles)(NotificationDetailedPage)));
