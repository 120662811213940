import React, { Component } from "react";
import ReactTable from "react-table";
import { withStyles } from '@material-ui/core/styles';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import communicationRegisterStyles from "../../../assets/styles/communicationRegisterStyles";
import { withRouter } from "react-router-dom";
import { formatShortDate, firstToUpper } from "../../../globals";
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import { notificationClauses } from '../../../variables/notificationClauses';
import clsx from 'clsx';
import matchSorter from 'match-sorter';

class Defects extends Component {

    state = {
        clauseData : null,
        type : ""
    };

    findRelevantClauseData = (clauseNumber) => {
        return notificationClauses.clauseData.find(obj => obj.clauseNumber === clauseNumber);
    };

    // Handle clicks in register row/s to redirect to relevant detailed page
    onRowClick = (row) => {
        let path = '/app/detailedNotification';
        this.props.history.push({
            pathname: path,
            state: {
                type: this.state.type,
                id: row.id,
                apiEndpoints: notificationClauses.apiUrls,
                clauseData: this.state.clauseData
            }
        });
    };

    // Sort revisions array to find latest revision
    findLatestRevision = (arr) => {
        arr.sort((a, b) => b.id - a.id);
        return 0;
    };

    getCurrentResponseRevision = (id) => {
        let arr = this.props.registers[this.getReduxStateName()];
        let index = arr.findIndex(o => o.id === id);

        if (index !== -1) {
            let revisionArr = arr[index][this.state.type + "Revisions"];
            if (revisionArr && revisionArr.length > 0) {
                const latestRevisionIndex = this.findLatestRevision(revisionArr);
                const responseExists = revisionArr[latestRevisionIndex][this.state.type + "RevisionResponse"] && revisionArr[latestRevisionIndex][this.state.type + "RevisionResponse"].length > 0;
                return responseExists ? revisionArr[latestRevisionIndex][this.state.type + "RevisionResponse"].length : 0;
            }
        }
        return 0;
    };

    // Return the latest revision status
    getRevisionStatus = (id) => {
        let arr = this.props.registers[this.getReduxStateName()];
        let index = arr.findIndex(o => o.id === id);

        if (index !== -1) {
            let revisionArr = arr[index][this.state.type + "Revisions"];
            if (revisionArr && revisionArr.length > 0) {
                const latestRevisionIndex = this.findLatestRevision(revisionArr);

                if(revisionArr[latestRevisionIndex] && revisionArr[latestRevisionIndex].status){
                    if (revisionArr[this.findLatestRevision(revisionArr)].status.toLowerCase() === "open") {
                        return "Open";
                    }
                    else {
                        return firstToUpper(revisionArr[latestRevisionIndex].status);
                    }
                }
            }
        }
        return "";
    };

    // Set colour of revision status depending on the current status (Pending = Blue, Accepted = Green, Rejected = Red)
    getRevisionStatusColor = (id) => {
        let status = this.getRevisionStatus(id);
        if (status !== "") {
            if (status.toLowerCase() === "open") {
                return '#2196f3';
            }
            else if (status.toLowerCase() === "rejected") {
                return '#f44336';
            }
            else {
                return '#4caf50';
            }
        }
        return "";
    };

    getCurrentDateForResponse = (id) => {
        let arr = this.props.registers[this.getReduxStateName()];
        let index = arr.findIndex(o => o.id === id);

        console.log(arr);
        console.log(index);

        if (index !== -1) {
            let revisionArr = arr[index][this.state.type + "Revisions"];
            if (revisionArr && revisionArr.length > 0) {
                const latestRevisionIndex = this.findLatestRevision(revisionArr);
                return revisionArr[latestRevisionIndex]["periodOfReplyDate"] ? formatShortDate(revisionArr[latestRevisionIndex]["periodOfReplyDate"]) : "N/A";
            }
        }
        return "N/A";
    };

    getReduxStateName = () => {
        return "defects_register";
    };

    componentDidMount() {
        const type = "notification";
        const payload = {
            url: notificationClauses.apiUrls.defectsRegister,
            id: this.getReduxStateName()
        };
        this.setState({
            clauseData : this.findRelevantClauseData("42.2"),
            type : type
        }, () => {
            this.props.onGetRegisterData(payload);
        });
    }

    render() {
        const { clauseData, type } = this.state;
        const { classes } = this.props;
        if (this.props.registersErrors[this.getReduxStateName()]) {
            return null;
        }
        else if (this.props.registersLoading[this.getReduxStateName()] || this.props.registersLoading[this.getReduxStateName()] === undefined) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} sm={12} lg={12} className={classes.loadGridItem}><CircularProgress color={"primary"} className={clsx(classes.progress, classes.loadingWheel)} style={{ marginTop: 15 }} /></GridItem></GridContainer>;
        } else {
            return (
                clauseData && type && <GridContainer>
                    <GridItem xs={12} sm={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.gridItem + " " + classes.cardAlignment}>
                            <CardBody>
                                <ReactTable
                                    /*Click on a row to see diary entry details functionality*/
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                e.preventDefault();
                                                this.onRowClick(rowInfo.row);

                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.props.registers[this.getReduxStateName()] ? this.props.registers[this.getReduxStateName()] : []}
                                    filterable
                                    resizable={false}
                                    showPageJump={false}
                                    noDataText={"No data"}
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false,
                                        },
                                        {
                                            Header: "Title",
                                            accessor: "title",
                                            id: "title",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["title"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Defect Location",
                                            accessor: "defectsLocation",
                                            id: "defectsLocation",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["defectsLocation"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Contract",
                                            id: "contractNumber",
                                            accessor: "contract.number",
                                            show: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["contractNumber"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Correction Status",
                                            accessor: "id",
                                            show: true,
                                            Cell: row => (
                                                <p className={classes.marBtm0} style={{ color: this.getRevisionStatusColor(row.value) }}>{row.value !== null ? this.getRevisionStatus(row.value) : ""}</p>
                                            ),
                                            //Customized filtering dropdown
                                            filterMethod: (filter, row) => {
                                                let latestRevisionStatus = this.getRevisionStatus(row.id).toLowerCase();
                                                if (filter.value === "all") {
                                                    return true;
                                                }
                                                if (filter.value === "open") {
                                                    return latestRevisionStatus === "open";
                                                }
                                                if (filter.value === "accepted") {
                                                    return latestRevisionStatus === "accepted";
                                                }
                                                return latestRevisionStatus === "rejected";
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="open">Open</option>
                                                    <option value="accepted">Accepted</option>
                                                    <option value="rejected">Rejected</option>
                                                </select>
                                        },
                                        {
                                            Header: "Correction Revisions",
                                            accessor: "id",
                                            id:"correctionRevisions",
                                            show: true,
                                            Cell: row => (
                                                <p className={classes.marBtm0}>{row.value !== null ? this.getCurrentResponseRevision(row.value) : ""}</p>
                                            ),
                                            //Customized filtering dropdown
                                            filterMethod: (filter, row) => {
                                                return (this.getCurrentResponseRevision(row.id).toString() === filter.value);
                                            },
                                        },
                                        {
                                            Header: "Submitted Date",
                                            accessor: date => formatShortDate(date.dateCreated),
                                            id: "dateCreated",
                                            show: true,
                                            //filterable: false,
                                            Cell: row => (
                                                <p className={classes.marBtm0}>{row.value !== null ? formatShortDate(row.value) : ""}</p>
                                            ),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["dateCreated"] }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Response Date",
                                            accessor: date => this.getCurrentDateForResponse(date.id),
                                            id: "responseDate",
                                            show: true,
                                            //filterable: false,
                                            Cell: row => (
                                                <p className={classes.marBtm0}>{row.value !== null ? row.value : ""}</p>
                                            ),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["responseDate"] }),
                                            filterAll: true
                                        },
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{ cursor: "pointer" }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        registers: state.communicationReducer.registers,
        registersLoading: state.communicationReducer.registersLoading,
        registersErrors: state.communicationReducer.registersErrors
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRegisterData: (payload) => dispatch(actionCreators.getRegisterData(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationRegisterStyles)(Defects)));

