import React, { Fragment } from "react";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Button from "../../CustomButtons/Button";
import communicationRevisionsAccordionStyles from "../../../assets/styles/communicationRevisionsAccordionStyles";
import { formatShortDate } from "../../../globals";

//Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AttachmentsTable from "../../Table/AttachmentsTable";
import NotificationRevisionAction from "./NotificationRevisionAction";
import GridContainer from "../../Grid/GridContainer";
import NotificationRevisionDetails from "./NotificationRevisionDetails";

class NotificationRevisions extends React.Component {

    //set state to be used within component
    state = {
        openCollapse: this.props.open, //open revision collapse state
    };

    //handles to collapse of the revision accordion
    handleToggleCollapse = () => {
        this.setState(state => ({ openCollapse: !state.openCollapse }));
    };

    //only generate certain fields to display
    validateFieldType = (item) => {
        // return (item.type !== "email" && item.type !== "contract" && item.type !== "attachment");
        return (item.type !== "email" && item.type !== "contract" && item.type !== "clauseRadio" && item.type !== "default");
    };
    //dynamically generate state based on clause fields
    generateComponent = (item, key) => {
        if (item.type === "attachment") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>Attachments</strong></p>
                    <AttachmentsTable attachments={this.props.revisionData[item.field]} />
                </GridItem>
            );
        }
        else if(item.type === "date"){
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>{item.labelText}: </strong> {this.props.revisionData[item.field] && this.props.revisionData[item.field] !== "" && formatShortDate(this.props.revisionData[item.field]) ? formatShortDate(this.props.revisionData[item.field]) : "None"}</p>
                </GridItem>
            );
        }
        else if(item.type === "defect"){
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>{item.labelText}: </strong> {this.props.revisionData[item.field] && this.props.revisionData[item.field].title ? this.props.revisionData[item.field].title : "None"}</p>
                </GridItem>
            );
        }
        else {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>{item.labelText}: </strong> {this.props.revisionData[item.field] && this.props.revisionData[item.field] !== "" ? this.props.revisionData[item.field] : "None"}</p>
                </GridItem>
            );
        }
    };

    //find the latest revision by finding the item in the array with the largest id
    findLatestResponse = (arr) => {
        arr.sort((a, b) => b.id - a.id);
        return 0;
    };

    getStatus = () => {
        // return "open";
        if (this.props.clauseData.revisionIsResponse) {
            return this.props.revisionData.status && this.props.revisionData.status.statusValue ? this.props.revisionData.status.statusValue.toLowerCase() : "";
        }
        return this.props.revisionData.status ? this.props.revisionData.status.toLowerCase() : "";
    }

    render() {
        const { classes, allowedToRespond, clauseData, revisionData, type, apiEndpoints, communicationId, revisionNumber, revisionId, revisionDataIfResponse } = this.props;
        const otherCardBotMargin = classes.otherCard + " " + classes.botMargin;
        const floatRightLessPaddingGridItem = classes.floatRight + " " + classes.lessPadding;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} lg={12} className={classes.gridItem}>
                    {clauseData.hasRevisions ?
                        <Fragment>
                            {/* card that shrinks and expands */}
                            <Card className={classes.collapseCard} onClick={this.handleToggleCollapse}>
                                <CardBody>
                                    {/* current revision */}
                                    <p className={classes.collapseParagraph}>{clauseData.displayData.details + ' ' + revisionNumber}</p>
                                    <Button className={classes.collapseButton} fullWidth>{!this.state.openCollapse ? <ExpandMore className={classes.collapseIcon} /> : <ExpandLess className={classes.collapseIcon} />}</Button>
                                </CardBody>
                            </Card>

                            {/* everything inside the collapsed content */}
                            <Collapse in={this.state.openCollapse} timeout="auto" unmountOnExit>
                                <Card className={classes.otherCard}>
                                    <CardBody>
                                        <GridContainer className={classes.verticalAlignContainer}>

                                            {/*revision creation date*/}
                                            <GridItem xs={12} sm={6} md={6} lg={6} className={classes.verticalAlignElement}>
                                                <p className={classes.noMargin}><strong>Date Created: </strong> {revisionData.dateCreated !== null ? formatShortDate(revisionData.dateCreated) : null}</p>
                                            </GridItem>

                                            {/* revision response or revision response details depending on revision status */}
                                            {allowedToRespond && clauseData.response ?
                                                <GridItem xs={12} sm={12} md={12} lg={12} className={floatRightLessPaddingGridItem}>
                                                    {this.getStatus() === "open" ?
                                                        <NotificationRevisionAction
                                                            apiEndpoints={apiEndpoints}
                                                            revisionId={revisionId}
                                                            revisionDataIfResponse={revisionDataIfResponse ? revisionDataIfResponse : null}
                                                            type={type}
                                                            communicationId={communicationId}
                                                            clauseData={clauseData} />
                                                        :
                                                        <NotificationRevisionDetails
                                                            data={clauseData.revisionIsResponse ? revisionData.status : revisionData[type + "RevisionResponse"] && revisionData[type + "RevisionResponse"].length > 0 ? revisionData[type + "RevisionResponse"][this.findLatestResponse(revisionData[type + "RevisionResponse"])] : {}}
                                                            displayResponseData={clauseData.displayData.response}
                                                            revisionIsResponse={clauseData.revisionIsResponse}
                                                            responseFields={clauseData.response}
                                                            displayStatusData={clauseData.displayData.status}/>}

                                                </GridItem> : null}


                                            {!allowedToRespond && clauseData.response ?
                                                <GridItem xs={12} sm={6} md={6} lg={6} className={floatRightLessPaddingGridItem}>
                                                    {this.getStatus() !== "open" ?
                                                        <NotificationRevisionDetails
                                                            data={clauseData.revisionIsResponse ? revisionData.status : revisionData[type + "RevisionResponse"] && revisionData[type + "RevisionResponse"].length > 0 ? revisionData[type + "RevisionResponse"][this.findLatestResponse(revisionData[type + "RevisionResponse"])] : {}}
                                                            displayResponseData={clauseData.displayData.response}
                                                            revisionIsResponse={clauseData.revisionIsResponse}
                                                            responseFields={clauseData.response}
                                                            displayStatusData={clauseData.displayData.status}/>
                                                        : <p className={classes.noMargin + " " + classes.italic + " " + classes.marginRight}><strong>Response Pending</strong></p>}
                                                </GridItem> : null
                                            }
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                                <Card className={otherCardBotMargin}>
                                    <CardBody>
                                        {/* Generate fields that could differ per clause */}
                                        {clauseData.dataFields.map((item, key) => {
                                            if (item.revisionField && this.validateFieldType(item)) {
                                                return this.generateComponent(item, key);
                                            }
                                            return null;
                                        })}
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Fragment>
                        :
                        <Fragment>
                            <Card className={classes.cardMargin}>
                                <CardBody>
                                    <GridContainer className={classes.verticalAlignContainer}>
                                        {/* revision response or revision response details depending on revision status */}
                                        {allowedToRespond && clauseData.response ?
                                            <GridItem xs={12} sm={12} md={12} lg={12} className={floatRightLessPaddingGridItem}>
                                                {this.getStatus() === "open" ?
                                                    <NotificationRevisionAction
                                                        apiEndpoints={apiEndpoints}
                                                        revisionId={revisionId}
                                                        revisionDataIfResponse={revisionDataIfResponse ? revisionDataIfResponse : null}
                                                        type={type}
                                                        communicationId={communicationId}
                                                        clauseData={clauseData}/>
                                                    :
                                                    <NotificationRevisionDetails
                                                        data={revisionData[type + "RevisionResponse"] && revisionData[type + "RevisionResponse"].length > 0  ? revisionData[type + "RevisionResponse"][this.findLatestResponse(revisionData[type + "RevisionResponse"])] : {}}
                                                        displayResponseData={clauseData.displayData.response}
                                                        revisionIsResponse={clauseData.revisionIsResponse}
                                                        responseFields={clauseData.response}
                                                        displayStatusData={clauseData.displayData.status}/>}
                                            </GridItem> : null}


                                        {!allowedToRespond && clauseData.response ?
                                            <GridItem xs={12} sm={12} md={12} lg={12} className={floatRightLessPaddingGridItem}>
                                                {this.getStatus() !== "open" ?
                                                    <NotificationRevisionDetails
                                                        data={revisionData[type + "RevisionResponse"] && revisionData[type + "RevisionResponse"].length > 0  ? revisionData[type + "RevisionResponse"][this.findLatestResponse(revisionData[type + "RevisionResponse"])] : {}}
                                                        displayResponseData={clauseData.displayData.response}
                                                        revisionIsResponse={clauseData.revisionIsResponse}
                                                        responseFields={clauseData.response}
                                                        displayStatusData={clauseData.displayData.status}/>
                                                    : <p className={classes.noMargin + " " + classes.italic + " " + classes.marginRight}><strong>Response Pending</strong></p>}
                                            </GridItem> : null
                                        }
                                    </GridContainer>

                                    {/* Generate fields that could differ per clause */}
                                    {clauseData.dataFields.map((item, key) => {
                                        if (this.validateFieldType(item)) {
                                            return this.generateComponent(item, key);
                                        }
                                        return null;
                                    })}
                                </CardBody>
                            </Card>
                        </Fragment>}
                </GridItem>
            </GridContainer>
        );
    }
}

//set proptypes to be used
NotificationRevisions.propTypes = {
    revisionData: PropTypes.object.isRequired,
    type: PropTypes.string,
    revisionNumber: PropTypes.number,
    allowedToRespond: PropTypes.bool.isRequired,
    clauseData: PropTypes.object.isRequired
};

export default withStyles(communicationRevisionsAccordionStyles)(NotificationRevisions);




