import * as actionTypes from "../actions/actionTypes";

const initialState = {
    projectsAndContracts : [],
    projectsAndContractsLoading : false,
    projectsAndContractsErrors : null
};

const getProjectsAndContractsStart = (state, action) => {
    return {
        ...state,
        projectsAndContractsLoading : true
    };
}

const getProjectsAndContractsSuccess = (state, action) => {
    return {
        ...state,
        projectsAndContracts : action.data,
        projectsAndContractsErrors : null,
        projectsAndContractsLoading : false
    };
}

const getProjectsAndContractsFail = (state, action) => {
    return {
        ...state,
        projectsAndContracts :  null,
        projectsAndContractsErrors : action.error,
        projectsAndContractsLoading : false
    };
}

//Reducer
const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_PROJECTS_AND_CONTRACTS_START: return getProjectsAndContractsStart(state, action);
        case actionTypes.GET_PROJECTS_AND_CONTRACTS_SUCCESS: return getProjectsAndContractsSuccess(state, action);
        case actionTypes.GET_PROJECTS_AND_CONTRACTS_FAIL: return getProjectsAndContractsFail(state, action);
        default: return state;
    }
};

export default reducer;