import React, { Fragment } from "react";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Button from "../../CustomButtons/Button";
import communicationRevisionsAccordionStyles from "../../../assets/styles/communicationRevisionsAccordionStyles";
import {
    formatShortDate,
} from "../../../globals";

//Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AttachmentsTable from "../../Table/AttachmentsTable";
import SubmissionRevisionAction from "./SubmissionRevisionAction";
import GridContainer from "../../Grid/GridContainer";
import SubmissionRevisionDetails from "./SubmissionRevisionDetails";

class SubmissionRevisions extends React.Component {

    //set state to be used within component
    state = {
        openCollapse: this.props.open, //open revision collapse state
    };

    //handles to collapse of the revision accordion
    handleToggleCollapse = () => {
        this.setState(state => ({ openCollapse: !state.openCollapse }));
    };

    //only generate certain fields to display
    validateFieldType = (item) => {
        // return (item.type !== "email" && item.type !== "contract" && item.type !== "attachment");
        return (item.type !== "email" && item.type !== "contract" && item.type !== "clauseRadio");
    };
    //dynamically generate state based on clause fields
    generateComponent = (item, key) => {
        if (item.type === "attachment") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>Attachments</strong></p>
                    <AttachmentsTable attachments={this.props.revisionData[item.field]} />
                </GridItem>
            );
        }
        else if (item.type === "date") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>{item.labelText}: </strong> {this.props.revisionData[item.field] && this.props.revisionData[item.field] !== "" && formatShortDate(this.props.revisionData[item.field]) ? formatShortDate(this.props.revisionData[item.field]) : "None"}</p>
                </GridItem>
            );
        }
        else {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key} className={this.props.classes.lessPadding}>
                    <p><strong>{item.labelText}: </strong> {this.props.revisionData[item.field] && this.props.revisionData[item.field] !== "" ? this.props.revisionData[item.field] : "None"}</p>
                </GridItem>
            );
        }
    };

    render() {
        const { classes, allowedToRespond, clauseData, revisionData, type, apiEndpoints, communicationId } = this.props;

        const otherCardBotMargin = classes.otherCard + " " + classes.botMargin;
        const floatRightLessPaddingGridItem = classes.floatRight + " " + classes.lessPadding;


        return (
            <GridContainer>
                <GridItem xs={12} sm={12} lg={12} className={classes.gridItem}>
                    {clauseData.hasRevisions ?
                        <Fragment>
                            {/* card that shrinks and expands */}
                            <Card className={classes.collapseCard} onClick={this.handleToggleCollapse}>
                                <CardBody>
                                    {/* current revision */}
                                    {clauseData.hasRevisions ? <p className={classes.collapseParagraph}>Revision {this.props.revisionNumber}</p>
                                        : <p className={classes.collapseParagraph}>Details</p>
                                    }
                                    <Button className={classes.collapseButton} fullWidth>{!this.state.openCollapse ? <ExpandMore className={classes.collapseIcon} /> : <ExpandLess className={classes.collapseIcon} />}</Button>
                                </CardBody>
                            </Card>

                            {/* everything inside the collapsed content */}
                            <Collapse in={this.state.openCollapse} timeout="auto" unmountOnExit>
                                <Card className={classes.otherCard}>
                                    <CardBody>
                                        <GridContainer className={classes.verticalAlignContainer}>

                                            {/*revision creation date*/}
                                            <GridItem xs={12} sm={6} md={6} lg={6} className={classes.verticalAlignElement}>
                                                <p className={classes.noMargin}><strong>Date Created: </strong> {revisionData.dateCreated !== null ? formatShortDate(revisionData.dateCreated) : null}</p>
                                            </GridItem>

                                            {/* revision response or revision response details depending on revision status */}
                                            {allowedToRespond && clauseData.response ?
                                                <GridItem xs={12} sm={12} md={12} lg={12} className={floatRightLessPaddingGridItem}>
                                                    {revisionData.status && revisionData.status.toLowerCase() === "open" ?
                                                        <SubmissionRevisionAction
                                                            apiEndpoints={apiEndpoints}
                                                            revisionId={revisionData.id}
                                                            type={type}
                                                            communicationId={communicationId}
                                                            clauseData={clauseData} />
                                                        :
                                                        <SubmissionRevisionDetails
                                                            data={revisionData[type + "RevisionResponse"]}
                                                            displayResponseData={clauseData.displayData.response}
                                                            responseFields={clauseData.response} />}
                                                </GridItem> : null}


                                            {!allowedToRespond && clauseData.response ?
                                                <GridItem xs={12} sm={6} md={6} lg={6} className={floatRightLessPaddingGridItem}>
                                                    {revisionData.status && revisionData.status.toLowerCase() !== "open" ?
                                                        <SubmissionRevisionDetails
                                                            data={revisionData[type + "RevisionResponse"]}
                                                            displayResponseData={clauseData.displayData.response}
                                                            responseFields={clauseData.response} />
                                                        : <p className={classes.noMargin + " " + classes.italic + " " + classes.marginRight}><strong>Response Pending</strong></p>}
                                                </GridItem> : null
                                            }
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                                <Card className={otherCardBotMargin}>
                                    <CardBody>
                                        {/* Generate fields that could differ per clause */}
                                        {clauseData.dataFields.map((item, key) => {
                                            if (item.revisionField && this.validateFieldType(item)) {
                                                return this.generateComponent(item, key);
                                            }
                                            return null;
                                        })}
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Fragment>
                        :
                        <Fragment>
                            <Card className={classes.cardMargin}>
                                <CardBody>
                                    <GridContainer className={classes.verticalAlignContainer}>
                                        {/* revision response or revision response details depending on revision status */}
                                        {clauseData.response ?
                                            <GridItem xs={6} sm={6} md={6} lg={6} className={classes.displayResponseText + " " + classes.lineHgt51}>
                                                <p style={{marginBottom: 0}}><strong>Response:</strong></p>
                                            </GridItem> : null}
                                        {allowedToRespond && clauseData.response ?
                                            <GridItem xs={6} sm={12} md={12} lg={12} className={floatRightLessPaddingGridItem + " " + classes.marginBtm15 + " " + classes.noMaxWidth}>
                                                {revisionData.status && revisionData.status.toLowerCase() === "open" ?
                                                    <SubmissionRevisionAction
                                                        apiEndpoints={apiEndpoints}
                                                        revisionId={revisionData.id}
                                                        type={type}
                                                        communicationId={communicationId}
                                                        clauseData={clauseData} />
                                                    :
                                                    <SubmissionRevisionDetails
                                                        data={revisionData[type + "RevisionResponse"]}
                                                        displayResponseData={clauseData.displayData.response}
                                                        responseFields={clauseData.response} />}
                                            </GridItem> : null}


                                        {!allowedToRespond && clauseData.response ?
                                            <GridItem xs={12} sm={6} md={6} lg={6} className={floatRightLessPaddingGridItem + " " + classes.marginBtm15}>
                                                {revisionData.status && revisionData.status.toLowerCase() !== "open" ?
                                                    <SubmissionRevisionDetails
                                                        data={revisionData[type + "RevisionResponse"]}
                                                        displayResponseData={clauseData.displayData.response}
                                                        responseFields={clauseData.response} />
                                                    : <p className={classes.noMargin + " " + classes.italic + " " + classes.marginRight + " " + classes.lineHgt51}><strong>Response Pending</strong></p>}
                                            </GridItem> : null
                                        }
                                    </GridContainer>

                                    {/* Generate fields that could differ per clause */}
                                    {clauseData.dataFields.map((item, key) => {
                                        if (this.validateFieldType(item)) {
                                            return this.generateComponent(item, key);
                                        }
                                        return null;
                                    })}
                                </CardBody>
                            </Card>
                        </Fragment>
                    }
                </GridItem>
            </GridContainer>
        );
    }
}

//set proptypes to be used
SubmissionRevisions.propTypes = {
    revisionData: PropTypes.object.isRequired,
    type: PropTypes.string,
    revisionNumber: PropTypes.number,
    allowedToRespond: PropTypes.bool.isRequired,
    clauseData: PropTypes.object.isRequired
};

export default withStyles(communicationRevisionsAccordionStyles)(SubmissionRevisions);




