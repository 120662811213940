import React, { useEffect } from "react";
import ReactTable from "react-table";
import { withStyles } from '@material-ui/core/styles';
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as PropTypes from "prop-types";
import communicationRegisterStyles from "../../../assets/styles/communicationRegisterStyles";
import { withRouter } from "react-router-dom";
import { formatShortDate, firstToUpper } from "../../../globals";
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import matchSorter from "match-sorter";

const SubmissionRegister = (props) => {
    const { onGetRegisterData, apiEndpoints, clauseData, type } = props;
    // Handle clicks in register row/s to redirect to relevant detailed page
    const onRowClick = (row) => {
        let path = '/app/detailedSubmission';
        props.history.push({
            pathname: path,
            state: {
                type: props.type,
                id: row.id,
                apiEndpoints: props.apiEndpoints,
                clauseData: props.clauseData
            }
        });
    };

    // Sort revisions array to find latest revision
    const findLatestRevision = (arr) => {
        arr.sort((a, b) => b.id - a.id);
        return 0;
    };

    // Return the latest revision status
    const getRevisionStatus = (id) => {
        let arr = props.registers[props.type + "_" + props.clauseData.id];
        let index = arr.findIndex(o => o.id === id);

        if (index !== -1) {
            let revisionArr = arr[index][props.type + "Revisions"];
            if (revisionArr && revisionArr.length > 0) {
                const latestRevisionIndex = findLatestRevision(revisionArr);

                if (revisionArr[latestRevisionIndex].status.toLowerCase() === "open") {
                    return firstToUpper(revisionArr[latestRevisionIndex].status);
                }

                else {
                    return firstToUpper(revisionArr[latestRevisionIndex].status);
                }
            }
        }
        return "";
    };

    // Set colour of revision status depending on the current status (Pending = Blue, Accepted = Green, Rejected = Red)
    const getRevisionStatusColor = (id) => {
        let status = getRevisionStatus(id);
        if (status !== "") {
            if (status.toLowerCase() === "open") {
                return '#2196f3';
            }
            else if (status.toLowerCase() === "rejected") {
                return '#f44336';
            }
            else {
                return '#4caf50';
            }
        }
        return "";
    };

    // Set colour of status if there is 1 revision depending on the current status (Pending = Blue, Accepted = Green, Rejected = Red)
    // const getStatusColor = (status) => {
    //     return status.toLowerCase() === "open" ? '#2196f3' : status.toLowerCase() === "rejected" ? '#f44336' : '#4caf50';
    // };

    const generateColumns = () => {
        let columnsTemp = [];

        props.clauseData.register.fields.forEach(field => {
            let obj = {};

            if (field.property === "dateCreated") {
                obj = {
                    Header: field.header,
                    accessor: date => formatShortDate(date.dateCreated),
                    show: field.visible,
                    id: field.property,
                    Cell: row => (
                        <p className={props.classes.marBtm0}>{row.value !== null ? formatShortDate(row.value) : ""}</p>
                    ),
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["dateCreated"] }),
                    filterAll: true
                };
            }
            else if (field.property === "numberRevisions") {
                obj = {
                    Header: field.header,
                    accessor: props.type + "Revisions.length",
                    show: field.visible,
                    Cell: row => (
                        <p className={props.classes.marBtm0}>{row.value !== null ? row.value : 0}</p>
                    ),
                }
            }
            else if (field.property === "revisionStatus") {
                obj = {
                    Header: field.header,
                    accessor: "id",
                    show: field.visible,
                    Cell: row => (
                        <p className={props.classes.marBtm0} style={{ color: getRevisionStatusColor(row.value) }}>{row.value !== null ? getRevisionStatus(row.value) : ""}</p>
                    ),
                    //Customized filtering dropdown
                    filterMethod: (filter, row) => {
                        let latestRevisionStatus = getRevisionStatus(row.id).toLowerCase();
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "open") {
                            return latestRevisionStatus === "open";
                        }
                        if (filter.value === "accepted") {
                            return latestRevisionStatus === "accepted";
                        }
                        return latestRevisionStatus === "rejected";
                    },
                    Filter: ({filter, onChange}) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: "100%"}}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            <option value="open">Open</option>
                            <option value="accepted">Accepted</option>
                            <option value="rejected">Rejected</option>
                        </select>
                };
            }
                // else if (field.property === "status") {
                //     obj = {
                //         Header: field.header,
                //         accessor: field.property,
                //         show: field.visible,
                //         Cell: row => (
                //             <p style={{ color: getStatusColor(row.value) }}>{row.value !== null ? firstToUpper(row.value) : ""}</p>
                //         ),
                //     };
            // }
            else {
                obj = {
                    Header: field.header,
                    accessor: field.property,
                    show: field.visible,
                    Cell: row => (
                        <p className={props.classes.marBtm0}>{row.value !== null ? row.value : ""}</p>
                    ),
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: [field.property] }),
                    filterAll: true
                };
            }

            columnsTemp.push(obj);

        });

        return columnsTemp;
    };

    const getReduxStateName = () => {
        return props.type + "_" + props.clauseData.id;
    };

    // useEffect(() => {
    //     const payload = {
    //         url: props.apiEndpoints.register + "/" + props.clauseData.clauseNumber,
    //         id: props.type + "_" + props.clauseData.id
    //     };
    //     props.onGetRegisterData(payload);
    // }, []);

    useEffect(() => {
        const payload = {
            url: apiEndpoints.register + "/" + clauseData.clauseNumber,
            id: type + "_" + clauseData.id
        };
        onGetRegisterData(payload);
    }, [apiEndpoints, clauseData, type, onGetRegisterData]);

    if (props.registersErrors[getReduxStateName()]) {
        return null;
    }
    else if (props.registersLoading[getReduxStateName()] || props.registersLoading[getReduxStateName()] === undefined) {
        //Loading wheel for initial API request
        return <GridContainer><GridItem xs={12} sm={12} lg={12} className={props.classes.loadGridItem}><CircularProgress color={"primary"} className={props.classes.progress} style={{ marginTop: 15 }} /></GridItem></GridContainer>;
    } else {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} lg={12}>
                    <Card className={props.classes.gridItem}>
                        <CardBody>
                            <ReactTable
                                /*Click on a row to see diary entry details functionality*/
                                getTrProps={(state, rowInfo) => {
                                    return {
                                        onClick: (e, handleOriginal) => {

                                            e.preventDefault();

                                            onRowClick(rowInfo.row);

                                            if (handleOriginal) {
                                                handleOriginal();
                                            }
                                        }
                                    };
                                }}

                                data={props.registers[getReduxStateName()] ? props.registers[getReduxStateName()] : []}
                                filterable
                                resizable={false}
                                showPageJump={false}
                                noDataText={"No data"}
                                columns={generateColumns()}
                                defaultSorted={[
                                    {
                                        id: "id",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={5}
                                showPaginationTop
                                showPaginationBottom={false}
                                className="-striped -highlight"
                                style={{ cursor: "pointer" }}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

SubmissionRegister.propTypes = {
    type: PropTypes.string,
    apiEndpoints: PropTypes.object,
    clauseData: PropTypes.object
};

const mapStateToProps = state => {
    return {
        registers: state.communicationReducer.registers,
        registersLoading: state.communicationReducer.registersLoading,
        registersErrors: state.communicationReducer.registersErrors
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRegisterData: (payload) => dispatch(actionCreators.getRegisterData(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(communicationRegisterStyles)(SubmissionRegister)));

