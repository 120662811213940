import React, { createRef } from "react";
import communicationRevisionDetailsStyles from "../../../assets/styles/communicationRevisionDetailsStyles";
import { withStyles } from '@material-ui/core/styles';
import Button from "../../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, /*DialogTitle*/} from "@material-ui/core";
// import DialogActions from "@material-ui/core/DialogActions";
import * as PropTypes from "prop-types";
import AttachmentsTable from "../../Table/AttachmentsTable";
import { firstToUpper } from "../../../globals";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import RadioGroupAccordion from "../../Accordion/RadioGroupAccordion";

class SubmissionRevisionDetails extends React.Component {

    constructor(props){
        super(props);
        this.topOfPage = createRef(); //ref to top of dialog
    }

    //set state to be used
    state = {
        open : false,
        details : this.props.data,
        fields : [],
        showScrollTop : false
    };

    //scroll to the top of the page onclick
    scrollToTop = () => {
        if(this.topOfPage.current){
            this.topOfPage.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    //show scroll to top button when user initially scrolls
    handleScroll = () => {
        if(!this.state.showScrollTop){
            this.setState({
                showScrollTop : true
            });
        }
    };

    //generate dynamic state to hold detailed values
    generateState = () => {

        let arr = [];

        //check status to determine fields to display
        if(this.props.displayResponseData.review) {
            arr = this.props.responseFields.reviewFields;

            if(this.props.data.status.toLowerCase() === "accepted"){
                const index = arr.findIndex(revisionObject => revisionObject.field === "clause_31_3_response");

                if (index > -1)
                    arr.splice(index, 1);
            }
        }

        else if(this.props.data.status.toLowerCase() === "rejected") {
            arr = this.props.responseFields.rejectFields;
        }
        else {
            arr = this.props.responseFields.acceptFields;
        }

        this.setState({
            fields : arr
        });

        arr.forEach(item => {
            this.setState({
                [item.field] : this.state.details[item.field],
                [item.field + "StateLoaded"] : true
            });
        });

    };

    //check if all state has been set
    stateLoaded = () => {

        let loaded = true;

        for(let i = 0; i < this.state.fields.length; i++){
            let item = this.state.fields[i];
                if(!this.state[item.field + "StateLoaded"]){
                   loaded = false;
                }
        }

        return loaded;
    };

    //generate dynamic state when component mounts
    componentDidMount() {
        this.generateState();
    }

    //dynamically generates components based on clause fields
    generateComponent = (item, key) => {

        if(item.type.toLowerCase() === "attachment"){ //generate attachment component
            return (
                <div key={key}>
                    <p><strong>{item.labelText}: </strong></p>
                    {/* Current Attachments Table */}
                    <AttachmentsTable attachments={this.state[item.field]} />
                </div>
            );
        }
        else if(item.type.toLowerCase() === "checkbox"){    //generate checkbox
            return (
                <div key={key}>
                    <p><strong>{item.labelText}: </strong></p>
                       {this.state[item.field] && this.state[item.field].length !== 0 ? this.state[item.field].map((obj, index) =>
                               <p key={index}>{obj}</p>
                      ): ""}
                </div>
            );
        }
        else if(item.type === "clauseRadio") {
            return (
                <GridItem xs={12} sm={12} lg={12} key={key}>
                    <RadioGroupAccordion
                        clauseData={item}
                        radioData={this.state[item.field] && this.state[item.field].length !== 0 ? this.state[item.field] : []}
                        isDetails={true}
                    />
                </GridItem>
            );
        }
        else if(item.type === "response")
            return null

        else{
            return (
                <p key={key}><strong>{item.labelText}: </strong>{this.state[item.field] !== "" ? this.state[item.field] : "None"}</p>
            );
        }
    };

    onOpenAndCloseDialog = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render () {

        const { classes, displayResponseData } = this.props;
        const { details, open, fields, showScrollTop } = this.state;

        return (
            this.stateLoaded() && <div className={classes.divFloatRight + " " + classes.buttonAlign}>
               <Button className={classes.buttonWrap} color={"primary"} onClick={() => this.onOpenAndCloseDialog()}>Response Details</Button>

                <Dialog open={open} onClose={() => this.onOpenAndCloseDialog()} fullScreen={true}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant={"h6"} className={classes.appBarHeading}>
                                {displayResponseData.details}
                            </Typography>

                            <Button
                              size="sm"
                              simple={true}
                              className={classes.appBarButtons}
                              onClick={() => this.onOpenAndCloseDialog()}>
                                Close
                            </Button>

                        </Toolbar>
                    </AppBar>
                    <DialogContent onScroll={this.handleScroll} className={classes.dialogContent}>
                        <div ref={this.topOfPage}/>
                        <GridContainer>
                            <GridItem xs={12} sm={12} lg={12}>
                                <p style={{marginTop : 30}}><strong>Status: </strong>{firstToUpper(details.status)}</p>

                                {fields.map((item, key) =>{
                                    return this.generateComponent(item, key);
                                })}
                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                    {showScrollTop ? <label className={classes.scrollTopLabel}>
                        <IconButton className={classes.scrollTopButton} onClick={() => this.scrollToTop()} component={"span"} size={"sm"} color={"primary"}>
                            <KeyboardArrowUpIcon className={classes.scrollTopIcon}/>
                        </IconButton>
                    </label> : ""
                    }
                </Dialog>
            </div>
        );
    }
}

//set up proptypes for component
SubmissionRevisionDetails.propTypes = {
    rejectFields : PropTypes.array,
    data : PropTypes.object,
};

export default withStyles(communicationRevisionDetailsStyles)(SubmissionRevisionDetails);
