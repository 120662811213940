import React from 'react';
import GridItem from "../Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import GridContainer from "../Grid/GridContainer";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import defectDropdownStyles from "../../assets/styles/defectDropdownStyles";

class DefectDropdown extends React.Component {
    render() {
        const { classes, onChange, errorState, statePrefix, defect, defects } = this.props;
        return (
            <GridContainer style={{marginTop: 10}}>
                {/* defect input */}
                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: 10}}>
                    <FormControl fullWidth style={{color: "#616161"}} error={errorState === "error"}>
                        <InputLabel className={classes.fontSize14} htmlFor="defect-select" style={errorState !== "error" ? {color: "#616161"} : null}>Defect *</InputLabel>
                        <Select
                            value={defect}
                            onChange={(event) => onChange(event, "defect", statePrefix)}
                            style={{fontSize : 14}}
                            inputProps={{
                                id: "defect-select"
                            }}
                        >
                            <MenuItem style={{fontSize : 14}} disabled value={0}><em>Choose defect</em></MenuItem>
                            {defects && defects.length > 0 ? defects.map((item, index) => (
                                <MenuItem style={{fontSize : 14}} key={index}
                                          value={item.id}>{item.title}</MenuItem>
                            )) : ""}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        );
    }
}

DefectDropdown.propTypes = {
    onChange : PropTypes.func,
    defect : PropTypes.number,
    defects : PropTypes.array,
    statePrefix : PropTypes.string,
    errorState : PropTypes.string
};

export default withStyles(defectDropdownStyles)(DefectDropdown);
