import React from "react";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import CustomInput from '../../../../components/CustomInput/CustomInput.jsx';
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { allClausesNoticeTable } from "../../../../variables/general.jsx";
import { verifyLength, verifyEmail } from "../../../../globals";
import { getApiRoot } from "../../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import fileDownload from "js-file-download";

import {Document, Page} from "react-pdf";
import Tooltip from "@material-ui/core/Tooltip";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";

const styles = theme => ({
    ...customSelectStyles,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});

class AddEventNotice extends React.Component{

    state = {

        allProjects:[],
        risks: [],
        attachments: [],
        currentFile: {},
        currentFileName: "No file chosen",

        allClauses:  allClausesNoticeTable.clauses.map((prop, key) => {
            return {
                primaryKey: key,
                number: prop.number,
                name: prop.name,
                tooltipName: prop.tooltipName,
                subClauses: prop.subClauses,
            };
        }),

        postData: {
            previewAttachmentsString: "",
            description: "",
            dateOfEventAwareness : "",
            dateExpectedToHappen : "",
            dateStartedToOccurOn : "",
            details: "",
            affectedActivities : "",
            clauseNumber:"61.3",
            subClauseNumber:"60.1(1)",
            contract: 0,
            risk: null,
            othersToBeInvited: [],
        },

        showExpectedOccurDate: true,
        showExpectedToHappenDate: false,
        selectedDate: "",
        project: 0,

        descriptionState: "",
        increasePricesValueState: "",
        impactDelayCompletionValueState: "",
        currentChosenProject: "",
        currentChosenClause:0,
        subClauseCounter: 0,
        previewPDF: "",
        newOtherEmail: "",
        newOtherName: "",

        numPages: null,
        error: false,
        contractIsLoaded: false,
        riskIsLoaded: false,
        loadingPreview: false,
        loadingSendDownloadEventNotice: false,

        openAddAttachmentDialog: false,
        submitEventNoticeDialogOpen: false,
    };

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    //function that ensures data is validated then calls new early warning API
    handleOpen = (event, type) => {

        if(type === "notice") {
            let flag = true;

            if (this.state.descriptionState === "") {
                this.setState({descriptionState: "error"});
                flag = false;
            }
            if (this.state.descriptionState === "error") {
                flag = false;
            }
            if (this.state.postData.contract === 0) {
                flag = false;
            }
            if (flag) {
                this.previewEventNoticeAPI();
            } else {
                alert("Description and Contract are required fields");
            }
        }
        else if(type === "addAttachment"){
            this.setState({
                openAddAttachmentDialog: true
            })
        }
    };

    // handles when cancel is clicked
    handleCancel = (type) => {

        if (type === "risk") {
            this.setState({
                submitEventNoticeDialogOpen: false
            });
        } else if (type === "addAttachment") {
            this.setState({
                openAddAttachmentDialog: false, currentFile: {}, currentFileName: "No file chosen"
            });
        }
    };


    //function that handles adding a email to othersToBeInvited field
    handleAddOthersToBeInvited = () => {

        if(!verifyLength(this.state.newOtherEmail, 1) || !verifyLength(this.state.newOtherName, 1) ){
            alert("One or more empty fields");
        }
        else if(!verifyEmail(this.state.newOtherEmail)){
            alert("Invalid Email: " + this.state.newOtherEmail);
        }
        else{

            let arr = this.state.postData.othersToBeInvited;

            let obj ={
                name: this.state.newOtherName,
                email: this.state.newOtherEmail,
            };

            arr.push(obj);

            this.setState(
                {
                    newOtherEmail: "",
                    newOtherName: "",
                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {   othersToBeInvited: arr }),
                }
            );
        }
    };

    //function that handles removing a email to othersToBeInvited field
    handleRemoveOthersToBeInvited = (index) => {

        let arr = this.state.postData.othersToBeInvited;
        arr.splice(index, 1);

        this.setState(
            {
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {   othersToBeInvited: arr }),
            }
        );
    };

    //function that handles adding an attachment
    addAttachment = (event) => {

        if(this.state.currentFileName === "No file chosen"){
            event.preventDefault();
            alert("Please choose an attachment");
            return;
        }

        let arr = this.state.attachments;

        let obj = {
            fileName: this.state.currentFileName,
            file: this.state.currentFile
        };

        arr.push(obj);

        this.setState({
            attachments: arr, openAddAttachmentDialog: false, currentFileName: "No file chosen", currentFile: {}
        });

        this.createAttachmentsString(arr);

        event.preventDefault();
    };

    //function that handles removing an attachment
    removeAttachment = (index) => {
        let arr = this.state.attachments;
        arr.splice(index, 1);

        this.setState({
            attachments: arr
        });

        this.createAttachmentsString(arr);
    };

    //string to post attachments to api
    createAttachmentsString = (arr) => {

        if(arr.length !== 0) {

            let attachmentsString = "";

            arr.forEach(object => {
                attachmentsString += object.fileName + "; ";
            });

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  previewAttachmentsString: attachmentsString}),
            });
        }
        else{
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  previewAttachmentsString: ""}),
            });
        }
    };

    //function that handles changing an attachment
    changeAttachment = (event) => {

        if(event.target.files.length === 0){
            this.setState({
                currentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }
    };

    //function that handles dropdown values
    handleOpenDropdown = (event) => {

        let value = parseInt(event.target.value);

        this.setState({ selectedDate: parseInt(event.target.value) });

        if(value === 1){
            this.setState({ showExpectedOccurDate: true});
            this.setState({ showExpectedToHappenDate: false});

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{dateExpectedToHappen: ""}),
            });
        }
        else if(value === 2){
            this.setState({ showExpectedToHappenDate: true});
            this.setState({ showExpectedOccurDate: false });

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{dateStartedToOccurOn: ""}),
            });
        }
    };

    // on change event when submitting data
    change(event) {

        const id = event.target.id;

        if(id === "descriptionInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({descriptionState: "success"});
            } else {
                this.setState({descriptionState: "error"});
            }

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {description: event.target.value }
                ),
            });
        }
        else if(id === "dateOfEventAwarenessInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {dateOfEventAwareness: event.target.value} ),
            });
        }
        else if(id === "dateEventExpectedInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {dateExpectedToHappen: event.target.value} ),
            });
        }
        else if(id === "dateEventExpectedToOccurInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {dateStartedToOccurOn: event.target.value} ),
            });
        }
        else if(id === "projectInput"){

            let index = this.state.allProjects.findIndex(o => o.project.id === parseInt(event.target.value));

            this.setState({
                project: event.target.value, currentChosenProject: index
            });

        }
        else if(id === "contractInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        contract: parseInt(event.target.value)}),
            });
        }
        else if(id === "linkedRiskInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  risk:  parseInt(event.target.value) }),
            });
        }
        else if(id === "increasePricesInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{increasePrices: event.target.value}),
            });
        }
        else if(id === "increasePricesValueInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{increasePricesValue: event.target.value}),
            });
        }
        else if(id === "impactDelayCompletionInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{impactDelayCompletion: event.target.value}),
            });
        }
        else if(id === "impactDelayCompletionValueInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{ impactDelayCompletionValue: event.target.value}),
            });
        }
        else if(id === "impactImpairPerformanceInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {impactImpairPerformance: event.target.value}),
            });
        }
        else if(id === "impactDelayMeetingInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{impactDelayMeeting: event.target.value}),
            });
        }
        else if(id === "detailsInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  details: event.target.value}),
            });
        }
        else if(id === "affectedActivitiesInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{ affectedActivities: event.target.value}),
            });
        }
        else if(id === "increaseContractorsTotalCostInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{ increaseContractorsTotalCost: event.target.value}),
            });
        }
        else if(id === "clauseNumberInput"){

            let index = this.state.allClauses.findIndex(o => o.number === event.target.value);

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{ clauseNumber: event.target.value}), currentChosenClause: index,
            });
        }
        else if(id === "subClauseNumberInput"){

            let index = this.state.allClauses[this.state.currentChosenClause].subClauses.data.findIndex(o => o.number === event.target.value);

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,{subClauseNumber: event.target.value}), subClauseCounter: index,
            });
        }
    }


    //when send or send and download clicked create CEN API is called
    sendEventNotice = (event, type) => {

        if(type === "send"){
            this.setState({ loadingSendEventNotice : true });
        }
        else if(type === "sendDownload"){
            this.setState({ loadingSendDownloadEventNotice : true });
        }

        event.preventDefault();
        let data = JSON.stringify(this.state.postData);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/create_compensation_event_notification', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.json();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);

                if(type === "send"){
                    this.setState({ loadingSendEventNotice : false });
                }
                else if(type === "sendDownload"){
                    this.setState({ loadingSendDownloadEventNotice : false });
                }
            }

        }).then(result => {

            if(result !== undefined){
                this.uploadFiles(result.id, type);
            }
        }).catch(error => alert(error));
    };

    // get risk list
    getRiskData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/risks", {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        riskIsLoaded: true,
                        risks: result,
                    });
                },
                (error) => {
                    this.setState({
                        riskIsLoaded: true,
                        error
                    });
                }
            )
    };

    //function that retrieves the current projects from the projects_contracts_list api to choose from
    getAllProjects = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/projects_contracts_list", {
            credentials: "include",
            headers,
            method: "GET"
            ,})
            .then(res => {

                if(res.status === 200){
                    return res.json();
                }
            })
            .then(
                (result) => {

                    if(result !== undefined)
                    {
                        this.setState({
                            contractIsLoaded: true,
                            allProjects: result,
                            project: result[0].project.id,
                            postData: Object.assign(
                                {},
                                this.state.postData,
                                {
                                    contract: result[0].contracts[0].id
                                }),
                            currentChosenProject: 0
                        });
                    }
                },
                (error) => {
                    this.setState({
                        contractIsLoaded: true,
                        error
                    });
                }
            )
    };

    //function that calls preview CEN API
    previewEventNoticeAPI = () => {

        this.setState({
            loadingPreview: true
        });

        let data = JSON.stringify(this.state.postData);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/preview_compensation_event_notification', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            if(result !== null){
                this.setState({
                    previewPDF: result, submitEventNoticeDialogOpen: true, loadingPreview: false
                });
            }
        }).catch(error => alert(error));
    };

    //upload attachments API
    uploadFiles = (id, type) => {

        if(this.state.attachments.length !== 0) {

            let data = new FormData();
            data.append('id', id);
            data.append('type', "compensationEventNotification");

            this.state.attachments.forEach(object => {
                data.append('file', object.file);
            });

            fetch(getApiRoot() + '/api/upload_files', {
                credentials: "include",
                method: "POST",
                body: data,
            }).then(res => {

                if (res.status === 200) {
                    this.sendAPI(id, type);
                }
                else {
                    alert("Something went wrong - HTTP: " + res.status);

                    if(type === "sendDownload"){
                        this.setState({ loadingSendDownloadEventNotice : false });
                    }
                    else{
                        this.setState({ loadingSendEventNotice : false });
                    }
                }
            }).catch(error => alert(error));
        }
        else{
            this.sendAPI(id, type);
        }
    };

    //calls send API o email recipients
    sendAPI = (id, type) => {

        let obj = {
            id: id
        };

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        let path = "/api/";

        if(type === "send"){
            path += "send_compensation_event_notification";
        }
        else if(type === "sendDownload"){
            path += "send_compensation_event_notification_and_download";
        }

        let fileName = "Compensation Event Notification.pdf";

        fetch(getApiRoot() + path, {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){

                if(type === "sendDownload"){

                    if( res.headers.get('Content-Disposition') !== null) {
                        let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                        if(strArr.length > 1)
                            fileName = strArr[1];
                    }
                    return res.blob();
                }
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);

                if(type === "sendDownload"){
                    this.setState({ loadingSendDownloadEventNotice : false });
                }
                else{
                    this.setState({ loadingSendEventNotice : false });
                }
            }

        }).then(result => {

            if(type === "sendDownload"){
                if(result !== undefined) {
                    fileDownload(result, fileName);

                    let path = "/app/taskView";

                    this.props.history.push({
                        pathname: path
                    });
                }
            }
            else{

                let path = "/app/taskView";

                this.props.history.push({
                    pathname: path
                });

            }
        }).catch(error => alert(error));
    };

    //if page loads successfully this function plays
    componentDidMount() {
        this.getAllProjects();
        this.getRiskData();
    }


    render() {
        const {classes} = this.props;
        const {error, contractIsLoaded, riskIsLoaded, loadingSendEventNotice, loadingPreview, loadingSendDownloadEventNotice, numPages } = this.state;
        if (error) {
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!contractIsLoaded || !riskIsLoaded) {
            //Shows loading wheel until initial API returns data or if an error occurs
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={8} className={classes.alignTable}>
                        <Card className={classes.cardAlignment} style={{paddingTop: 15}}>
                            <CardBody>
                                <form>
                                    <GridContainer>

                                        {/*project and contract*/}
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            {/*Project input*/}
                                            <hr style={{margin: "8px 0 8px 0"}}/>
                                            <p><strong>Project</strong></p>
                                            <hr style={{margin: "8px 0 8px 0"}}/>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.project}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'projectInput',
                                                    }}
                                                >
                                                    <option disabled value={0}>Choose project</option>
                                                    {this.state.allProjects ? this.state.allProjects.map((item, index) => (
                                                        <option key={index}
                                                                value={item.project.id}>{item.project.title}</option>
                                                    )) : ""}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        {/*contract input*/}
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <hr style={{margin: "8px 0 8px 0"}}/>
                                            <p><strong>Contract</strong></p>
                                            <hr style={{margin: "8px 0 8px 0"}}/>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.contract}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'contractInput',
                                                    }}
                                                >
                                                    <option disabled value={0}>Choose contract</option>
                                                    {this.state.currentChosenProject !== "" && this.state.allProjects ? this.state.allProjects[this.state.currentChosenProject].contracts.map((item, index) => (
                                                        <option key={index}
                                                                value={item.id}>{item.number}</option>
                                                    )) : ""}
                                                </Select>
                                            </FormControl>
                                        </GridItem>


                                        {/*Event Description*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="descriptionInput"
                                                labelText="Short Description"
                                                success={this.state.descriptionState === "success"}
                                                error={this.state.descriptionState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.description,
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.descriptionState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : undefined
                                                }}
                                            />
                                        </GridItem>

                                        {/*clauses*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            {/*Clause Input*/}
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Clause</strong></p>
                                            <hr style={{margin: "15px 0 10px 0"}}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p>Choose Clause</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.clauseNumber}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'clauseNumberInput',
                                                    }}
                                                >
                                                    {this.state.allClauses.map((item, index) => (
                                                        <Tooltip
                                                            key={index}
                                                            title={item.tooltipName}
                                                            placement="top"
                                                            classes={{tooltip: classes.tooltip}}
                                                        >
                                                            <option key={index}
                                                                    value={item.number}>{item.number}</option>
                                                        </Tooltip>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <hr style={{marginTop: 50}}/>
                                        <br/>
                                        {/*subClauseNumber*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p>Choose Clause sub-section</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.subClauseNumber}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'subClauseNumberInput',
                                                    }}
                                                >
                                                    {this.state.allClauses[this.state.currentChosenClause].subClauses.data.map((item, index) => (
                                                        <Tooltip
                                                            key={index}
                                                            title={item.tooltipDescription}
                                                            placement="top"
                                                            classes={{tooltip: classes.tooltip}}
                                                        >
                                                            <option key={index}
                                                                    value={item.number}>{item.number}</option>
                                                        </Tooltip>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*Sub clause Details*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p style={{margin: "20px 0 10px 0"}}>Clause sub-section details</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div style={{margin: "10px 0 10px 0"}}>{this.state.allClauses[this.state.currentChosenClause].subClauses.data[this.state.subClauseCounter].description} </div>
                                        </GridItem>

                                        {/*Details*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "25px 0 10px 0"}}/>
                                            <p><strong>Details</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>
                                        {/*Event details*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="detailsInput"
                                                labelText="Event Details"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.details,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/*Affected Activities */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="affectedActivitiesInput"
                                                labelText="Affected Activities"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.affectedActivities,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/*Became aware date */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="dateOfEventAwarenessInput"
                                                labelText="Date of becoming aware"
                                                labelProps={{
                                                    shrink: true
                                                }}

                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.dateOfEventAwareness,
                                                    onChange: event => this.change(event),
                                                    type: "date",
                                                }}
                                            />
                                        </GridItem>

                                        {/*Dropdown */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                        <FormControl fullWidth style={{marginTop: 20, marginBottom: 15}}>
                                            <Select
                                                native
                                                onChange={(event) => this.handleOpenDropdown(event)}
                                                value={this.state.selectedDate}
                                            >
                                                <option value={1}>The compensation event started to occur on:</option>
                                                <option value={2}>The event is expected to happen as a compensation event on or about:</option>

                                            </Select>
                                        </FormControl>
                                        </GridItem>


                                        {/*Date Event is expected to Occur on*/}
                                        { this.state.showExpectedOccurDate === true ?
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="dateEventExpectedToOccurInput"
                                                labelText="The compensation event started to occur on:"
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.dateStartedToOccurOn,
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "date",
                                                }}
                                            />
                                        </GridItem> : ""}


                                        {/*Date Event is expected to happen*/}
                                        { this.state.showExpectedToHappenDate === true ?
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="dateEventExpectedInput"
                                                labelText="The event is expected to happen as a compensation event on or about:"
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.dateExpectedToHappen,
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "date",
                                                }}
                                            />
                                        </GridItem>: ""}

                                        {/*Linked Risk*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "30px 0 10px 0"}}/>
                                            <p><strong>Linked Risk</strong></p>
                                            <hr style={{margin: "8px 0 8px 0"}}/>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.risk !== null ? this.state.postData.risk : 0}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'linkedRiskInput',
                                                    }}
                                                >
                                                    <option value={null}>None</option>
                                                    {this.state.risks.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.description}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/* Attachments */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "40px 0 10px 0"}}/>
                                            <GridContainer>
                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                    <p style={{marginBottom: 0}}><strong>Attachments</strong></p>
                                                </GridItem>
                                                {/* Add Attachment button */}
                                                <GridItem xs={4} sm={4} lg={4}>
                                                    <Add style={{cursor: "pointer", fontSize: 16, float: "right", marginTop: 3}}
                                                         onClick={(event) => this.handleOpen(event,"addAttachment")}/>
                                                </GridItem>
                                            </GridContainer>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Current Attachments list */}
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.attachments.length ? this.state.attachments.sort(function(a,b){
                                                            return parseInt(a.id) - parseInt(b.id);
                                                        }).map((item, index) =>
                                                            <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                      key={index}>
                                                                {item.fileName}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                           style={{cursor: "pointer", fontSize: 16, marginTop: 5}}
                                                                           onClick={() => this.removeAttachment(index)}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                        : "No Attachments"
                                                }
                                            </List>
                                        </GridItem>

                                        {/* Others to be invited Input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "20px 0 10px 0"}}/>
                                            <p><strong>Others To Be Notified</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} lg={12}>

                                            {/* Current Others To Be Invited */}
                                            <div style={{marginBottom: 15}}>
                                                <List>
                                                    {
                                                        this.state.postData.othersToBeInvited === undefined || this.state.postData.othersToBeInvited.length === 0 ?
                                                            <p>None</p> : this.state.postData.othersToBeInvited.map((item, index) =>
                                                                <ListItem
                                                                    style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                    key={index}>

                                                                    <GridContainer>
                                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                                            { item.name + " (" +item.email + ")"}
                                                                        </GridItem>
                                                                    </GridContainer>

                                                                    <ListItemSecondaryAction>
                                                                        <Close className={classes.danger} style={{
                                                                            cursor: "pointer",
                                                                            fontSize: 16,
                                                                            marginTop: 5
                                                                        }}
                                                                               onClick={() => this.handleRemoveOthersToBeInvited(index)}/>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>)
                                                    }

                                                </List>
                                            </div>
                                        </GridItem>

                                        {/* New Other To Be Invited Input */}
                                        <Card style={{margin: "0 15px"}}>
                                            <CardBody>
                                                <GridContainer >
                                                    <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center"}}>
                                                        <Add onClick={() => this.handleAddOthersToBeInvited()}
                                                             style={{
                                                                 cursor: "pointer",
                                                                 float: "right",
                                                                 fontSize: 16,
                                                             }}/>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                                        <CustomInput
                                                            id="newOtherNameInput"
                                                            labelText="Name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newOtherName,
                                                                onChange: (event) =>
                                                                    this.setState({newOtherName: event.target.value}),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                                        <CustomInput
                                                            id="newOtherInput"
                                                            labelText="Email"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newOtherEmail,
                                                                onChange: (event) =>
                                                                    this.setState({newOtherEmail: event.target.value}),
                                                                type: "email",
                                                                style: {marginBottom: 25}
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            <hr style={{margin: "30px 0 30px 0"}}/>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            <div style={{float: "right", position: "relative"}}>
                                                <Button color="primary" disabled={loadingPreview} onClick={(event) => this.handleOpen(event,"notice")}>Preview</Button>
                                                {loadingPreview && <CircularProgress size={24} style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: -12,
                                                    marginLeft: -12
                                                }}/>}
                                            </div>
                                        </GridItem>

                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>


                    {/*// start of dialogs*/}
                    {/*// .....*/}
                    {/*// .....*/}
                    {/*// .....*/}
                    {/*// .....*/}

                    {/* Send Compensation Event Notice Dialog */}
                    <Dialog
                        open={this.state.submitEventNoticeDialogOpen}
                        onClose={() => this.handleCancel("risk")}
                        aria-labelledby="sendEventDialog"
                        maxWidth={"md"}
                    >
                        <form style={{display: "contents"}}>
                            <DialogTitle id="sendEventDialog">Send Compensation Event Notification</DialogTitle>
                            {this.state.previewPDF !== "" ?
                                <DialogContent>
                                <div>
                                    <Document
                                        file={this.state.previewPDF}
                                        onLoadSuccess={this.onDocumentLoad}
                                    >
                                        {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                                            <Page key={i+1} pageNumber={i+1} />
                                        ): ""}
                                    </Document>
                                </div>
                            </DialogContent>
                            : ""}
                            <DialogActions>
                                <Button onClick={() => this.handleCancel("risk")}>
                                    Back
                                </Button>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"}
                                            onClick={(event) => this.sendEventNotice(event, "sendDownload")}
                                            color="info" disabled={loadingSendDownloadEventNotice}>
                                        Send & Download
                                    </Button>
                                    {loadingSendDownloadEventNotice && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"}
                                            onClick={(event) => this.sendEventNotice(event, "send")}
                                            color="primary" disabled={loadingSendEventNotice}>
                                        Send
                                    </Button>
                                    {loadingSendEventNotice && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>


                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openAddAttachmentDialog}
                        onClose={() => this.handleCancel("addAttachment")}
                        aria-labelledby="addAttachmentDialog"
                    >
                        <form>
                            <DialogTitle id="addAttachmentDialog">Add Attachment</DialogTitle>
                            <DialogContent>

                                {/* File Upload */}
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadRiskAttachmentInput"
                                            type="file"
                                            onChange={(event) => this.changeAttachment(event)}
                                        />
                                        <label htmlFor="uploadRiskAttachmentInput">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>

                                    {/* Current Attachment Name */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentFileName}</p>
                                    </GridItem>

                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleCancel("addAttachment")} color="info">
                                    Close
                                </Button>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"} onClick={(event) => this.addAttachment(event)}
                                            color="primary">
                                        Add
                                    </Button>
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>
                    
                    
                </GridContainer>
            );
        }
    }
}
export default withStyles(styles)(AddEventNotice);
