import React from "react";
import * as PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl/index";
import InputLabel from "@material-ui/core/InputLabel/index";
import Select from "@material-ui/core/Select/index";
import MenuItem from "@material-ui/core/MenuItem/index";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import Ballot from "@material-ui/icons/Ballot";
import ReportProblem from "@material-ui/icons/ReportProblem";
// import Search from "@material-ui/icons/Search";
// core components
// import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
// import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
//component styles
import cardImagesStyles from "../../../assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
//Images
import card1Background from "assets/img/Main Cards_Gradient Design-01.png";
import card2Background from "assets/img/Main Cards_Gradient Design-02.png";
import card3Background from "assets/img/Main Cards_Gradient Design-03.png";
import card4Background from "assets/img/Main Cards_Gradient Design-04.png";
//Custom components
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown.jsx';
//ninety10 components
// import ViewSwitch from "../../../components/CustomSwitch/ViewSwitch.jsx";
import DashboardToDoList from "../../../components/CustomLists/DashboardToDoList.jsx";
import DashboardRiskRegisterList from "../../../components/CustomLists/DashboardRiskRegisterList.jsx";
import DashboardEventRegisterList from "../../../components/CustomLists/DashboardEventRegisterList.jsx";
import DashboardDiaryList from "../../../components/CustomLists/DashboardDiaryList.jsx";

// import { taskViewData } from "../../../variables/general.jsx";

import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";

//Custom styles
const styles = theme => ({
  ...cardImagesStyles, ...customSelectStyles, ...dashboardStyle, ...compensationEventTabsStyles(theme),
  textWhite: {
    "&, & *": {
      color: "#FFF"
    },
  taskViewCardAlignment: {
      [theme.breakpoints.down("xs")]: {
          paddingRight: 0,
          paddingLeft: 0,
          marginTop: -5
      }
  },
  taskViewCardPadding: {
      padding: 10,
      [theme.breakpoints.down("xs")]: {
             padding: 0,
          }
      }
  }
});

class Taskview extends React.Component {

    // constructor(props){
    //     super(props);
    //     // this.myInput = React.createRef();
    // }

  state = {
      value: 0,
      multipleSelectProjects: [],
      multipleSelectContracts: [],
      checkedProjectView: false,
      popperOpen: false,
      error: null,
      isLoaded: false,
      toDoItems: [],
      riskItems: [],
      eventItems: [],
      diaryItems: [],

      searchTaskView: "",

      addButtonDropdown: [],
      showAddButton: false,
      refreshAPI: true,

      intervalId: 0,
      firstLoad: false,
  };


  //Function that routes add button to different pages
    handleClickAdd = (event) => {

        let path = "/app/";

        if(event.toLowerCase() === "add early warning"){
            path += 'newEarlyWarning';
        }
        else if(event.toLowerCase() === "add compensation event notification"){
            path += 'addNotice';
        }
        else if(event.toLowerCase() === "add compensation event"){
            path += 'addEvent';
        }
        else if(event.toLowerCase() === "add daily diary entry"){
            path += 'addDiary';
        }

        this.props.history.push({
            pathname: path
        });
    };

    //Function that handles the choose project filter
    handleMultipleProjects = event => {
    this.setState({ multipleSelectProjects: event.target.value });
    };

    //Function that handles the choose contract filter
    handleMultipleContracts = event => {
    this.setState({ multipleSelectContracts: event.target.value });
    };

    //Function that handles clicking on the main cards
    handleCardClick = card => {
        let path = "/app/";
        if(card === "risk"){
             path += 'riskRegister';
        }
        else if(card === "event"){
            path += 'allEventRegister';
        }
        else if(card === "diary"){
            path += 'diary';
        }

        this.props.history.push({
            pathname: path
        });
    };

    //Calls task_view api and populates custom lists
    initialTaskViewData = () => {

        if(this.state.refreshAPI) {

            this.setState({
                refreshAPI: false
            });

            const headers = new Headers({
                "Accept": "application/json",
                //"Cookie": localStorage.getItem('cookie')
            });

            // setTimeout(() => {

            fetch(getApiRoot() + "/api/task_view", {
                credentials: "include",
                headers,
                method: "GET"
                ,
            })
                .then(res => {

                    if (res.status === 200) {
                        return res.json();
                    } else {
                        this.setState({
                            isLoaded: true
                        });

                        alert("Something went wrong - HTTP " + res.status);
                    }

                })
                .then(
                    (result) => {
                        if(this.mounted){
                            if (result !== undefined) {
                                this.setState({
                                    toDoItems: result.toDos,
                                    riskItems: result.risks, //[],
                                    eventItems: result.compensationEvents, //[],
                                    projectItems: result.projects,
                                    contractItems: result.contracts,
                                    diaryItems: result.dailyDiaries, //[],
                                });

                                this.getPermissions();
                            }
                        }

                    },
                    (error) => {
                        if(this.mounted){
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    }
                )
        }
    };

    //calls permission api to show and hide add buttons
    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET"
            ,})
            .then(res => {

                if(res.status === 200){
                    return res.json();
                }
                else{
                    this.setState({
                        isLoaded: true,
                    });

                    alert("Something went wrong - HTTP " + res.status);
                }
            }
            )
            .then(
                (result) => {

                    if(result !== undefined){
                        this.setState({
                            isLoaded: true,
                            refreshAPI: true,
                            firstLoad : true
                            // permissions: result
                        });

                        this.createButtonsArr(result);
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

        //creates dropdown button based on permissions
        createButtonsArr = (permissions) => {

            if(permissions.length !== 0) {

                let createRisk = permissions.find(o => o.type === "risk" && o.subtype === "create").permission;
                let createCompensationEvent = permissions.find(o => o.type === "compensationEvent" && o.subtype === "create").permission;
                let createCompensationEventNotification = permissions.find(o => o.type === "compensationEventNotification" && o.subtype === "create").permission;
                let createDiary = permissions.find(o => o.type === "dailyDiary" && o.subtype === "create").permission;

                let arr = [];


                if(createRisk === "true"){
                    arr.push("Add Early Warning");
                }

                if(createCompensationEvent === "true"){
                    arr.push("Add Compensation Event");
                }

                if(createCompensationEventNotification === "true"){
                    arr.push("Add Compensation Event Notification");
                }

                if(createDiary === "true"){
                    arr.push("Add Daily Diary Entry");
                }

                if(JSON.stringify(arr) !== JSON.stringify(this.state.addButtonDropdown)) {

                    if (createRisk === "false" && createCompensationEvent === "false" && createCompensationEventNotification === "false" && createDiary === "false") {
                        this.setState({
                            showAddButton: false
                        });
                    } else {
                        this.setState({
                            showAddButton: true, addButtonDropdown: arr
                        });
                    }
                }

            }
            else{
                this.setState({
                    showAddButton: false
                });
            }
        };

    //function to be called every 5 seconds
    repeatTaskView = () => {

        if(this.state.firstLoad){
            this.initialTaskViewData();
        }

    };
    //Function that calls initial api to retrieve taskview data
    componentDidMount() {
        this.mounted = true;
        this.initialTaskViewData();

        //set task_view api to be called every 5 seconds
        let intervalId = setInterval(this.repeatTaskView, 5000);

        this.setState({
            intervalId: intervalId
        });

        // console.log(this.props.test);
        // this.myInput.current.scrollTop = 200;
    }

    //Function that clears the interval for calling task_view every 5 seconds
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.mounted = false;
    }


render() {
    const { classes } = this.props;
    const { error, isLoaded } = this.state;
    if (error) {
        return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
    } else if (!isLoaded) {
        //Shows loading wheel until initial API returns data or if an error occurs
        return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
    } else {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8} lg={8} style={{marginTop: 20}}>
                        <GridContainer>
                            {/*Filter by project dropdown*/}
                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{margin: 0}}
                                >
                                    <InputLabel
                                        htmlFor="projectsSelectTaskView"
                                        className={classes.selectLabel}
                                    >
                                        Filter Projects
                                    </InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.multipleSelectProjects}
                                        onChange={this.handleMultipleProjects}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "projectsSelectTaskView",
                                            id: "projectsSelectTaskView"
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Choose Project
                                        </MenuItem>
                                            {this.state.projectItems ? this.state.projectItems.map((item, index) => (
                                                <MenuItem key={index} value={item.id}  classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}>{item.title}</MenuItem>
                                            )) : ""}
                                        </Select>
                                </FormControl>
                            </GridItem>
                            {/*Filter by contract dropdown*/}
                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{margin: "0 !important"}}
                                >
                                    <InputLabel
                                        htmlFor="contractsSelectTaskView"
                                        className={classes.selectLabel}
                                    >
                                        Filter Contracts
                                    </InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.multipleSelectContracts}
                                        onChange={this.handleMultipleContracts}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "contractsSelectTaskView",
                                            id: "contractsSelectTaskView"
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Choose Contract
                                        </MenuItem>
                                        {this.state.contractItems ? this.state.contractItems.map((item, index) => (
                                            <MenuItem key={index} value={item.id}  classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}>{item.number}</MenuItem>
                                        )) : ""}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            {/*Search taskview input*/}
                            {/*<GridItem xs={12} sm={12} md={4} lg={4}>*/}
                            {/*    <div>*/}
                            {/*        <CustomInput*/}
                            {/*            formControlProps={{*/}
                            {/*                className: classes.top + " " + classes.search*/}
                            {/*            }}*/}
                            {/*            inputProps={{*/}
                            {/*                value: this.state.searchTaskView,*/}
                            {/*                onChange: event =>*/}
                            {/*                    this.setState({*/}
                            {/*                        searchTaskView: event.target.value*/}
                            {/*                    }),*/}
                            {/*                placeholder: "Search Task View",*/}
                            {/*                inputProps: {*/}
                            {/*                    "aria-label": "Search Task View",*/}
                            {/*                    className: classes.searchInput*/}
                            {/*                }*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*        <Button*/}

                            {/*            color="white"*/}
                            {/*            aria-label="edit"*/}
                            {/*            justIcon*/}
                            {/*            round*/}
                            {/*        >*/}
                            {/*            <Search/>*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*</GridItem>*/}

                        </GridContainer>
                    </GridItem>
                    {/*Add button*/}
                    { this.state.showAddButton ?
                        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignInNavBarTaskViewButton}>
                            <div className={classes.buttonWrap}>
                                <CustomDropdown
                                    onClick={(event) => this.handleClickAdd(event)}
                                    buttonText={<Add/>}
                                    buttonProps={{
                                        justIcon: true,
                                        round: true,
                                        color: "primary",

                                    }}

                                    dropdownList={this.state.addButtonDropdown}
                                />
                            </div>
                            {/*<div>*/}
                            {/*    /!*Toggle between taskview and project view*!/*/}
                            {/*    <ViewSwitch history={this.props.history} classes={classes} to="/app/projectView"*/}
                            {/*                label={"Project View"} state={false} padding={"20px 0 0 10px"}/>*/}
                            {/*</div>*/}
                        </GridItem> : ""
                    }

                </GridContainer>

                <GridContainer className={classes.taskViewCardPadding}>
                    {/*Main To Do card*/}
                    <GridItem xs={12} sm={6} md={6} lg={3} className={classes.taskViewCardAlignment}>
                        <Card className={classes.textWhite}>
                            <div className={classes.cardImgOverlay} style={{padding: 0}}>
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4} lg={4} style={{height: 100}}>
                                        <p style={{
                                            lineHeight: "84px",
                                            textAlign: "center",
                                            backgroundColor: "rgba(21, 39, 132, 0.5)",
                                            marginBottom: 0
                                        }}><AssignmentTurnedIn style={{marginTop: 35, fontSize: "2em"}}/></p>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={8} lg={8} style={{height: 100}}>
                                        <h4 style={{marginTop: 0, fontWeight: 500, lineHeight: "100px"}}>To Do</h4>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <img
                                className={classes.cardImg}
                                alt="100%x270"
                                style={{height: "100px", width: "100%", display: "block"}}
                                src={card1Background}
                            />
                        </Card>
                        {/*To Do List*/}
                        <DashboardToDoList classes={classes} items={this.state.toDoItems} filterProjects={this.state.multipleSelectProjects} filterContracts={this.state.multipleSelectContracts} /*searchText={this.state.searchTaskView}*//>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={3} className={classes.taskViewCardAlignment}>
                        {/*Main Risk Register Card*/}
                        <Card className={classes.textWhite} style={{cursor: "pointer"}}>
                            <div className={classes.cardImgOverlayWithHover} style={{padding: 0}}
                                 onClick={() => this.handleCardClick("risk")}>
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4} lg={4} style={{height: 100}}>
                                        <p style={{
                                            lineHeight: "84px",
                                            textAlign: "center",
                                            backgroundColor: "rgba(52, 109, 152, 0.5)",
                                            marginBottom: 0
                                        }}><ReportProblem style={{marginTop: 35, fontSize: "2em"}}/></p>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={8} lg={8} style={{height: 100}}>
                                        <h4 style={{marginTop: 0, fontWeight: 500, lineHeight: "100px"}}>Risk
                                            Register</h4>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <img
                                className={classes.cardImg}
                                alt="100%x270"
                                style={{height: "100px", width: "100%", display: "block"}}
                                src={card2Background}
                            />
                        </Card>
                        {/*Risk Register List*/}
                        <DashboardRiskRegisterList classes={classes} items={this.state.riskItems} filterProjects={this.state.multipleSelectProjects} filterContracts={this.state.multipleSelectContracts}/>

                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={3} className={classes.taskViewCardAlignment}>
                        {/*Main Compensation Event Card*/}
                        <Card className={classes.textWhite} onClick={() => this.handleCardClick("event")}
                              style={{cursor: "pointer"}}>
                            <div className={classes.cardImgOverlayWithHover} style={{padding: 0}}>
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4} lg={4} style={{height: 100}}>
                                        <p style={{
                                            lineHeight: "84px",
                                            textAlign: "center",
                                            backgroundColor: "rgba(22, 147, 156, 0.5)",
                                            marginBottom: 0
                                        }}><Ballot style={{marginTop: 35, fontSize: "2em"}}/></p>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={8} lg={8} style={{height: 100}}>
                                        <h4 style={{marginTop: 0, fontWeight: 500, lineHeight: "100px"}}>Event
                                            Register</h4>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <img
                                className={classes.cardImg}
                                alt="100%x270"
                                style={{height: "100px", width: "100%", display: "block"}}
                                src={card3Background}
                            />
                        </Card>

                        {/*Compensation Event List*/}
                        <DashboardEventRegisterList classes={classes} items={this.state.eventItems} filterProjects={this.state.multipleSelectProjects} filterContracts={this.state.multipleSelectContracts}/>

                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} lg={3} className={classes.taskViewCardAlignment}>
                        {/*Main Daily Diary Card*/}
                        <Card className={classes.textWhite} onClick={() => this.handleCardClick("diary")}
                              style={{cursor: "pointer"}}>
                            <div className={classes.cardImgOverlayWithHover} style={{padding: 0}}>
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4} lg={4} style={{height: 100}}>
                                        <p style={{
                                            lineHeight: "84px",
                                            textAlign: "center",
                                            backgroundColor: "rgba(18, 135, 115, 0.5)",
                                            marginBottom: 0
                                        }}><Assignment style={{marginTop: 35, fontSize: "2em"}}/></p>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={8} lg={8} style={{height: 100}}>
                                        <h4 style={{marginTop: 0, fontWeight: 500, lineHeight: "100px"}}>Daily
                                            Diary</h4>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <img
                                className={classes.cardImg}
                                alt="100%x270"
                                style={{height: "100px", width: "100%", display: "block"}}
                                src={card4Background}
                            />
                        </Card>
                        {/*Daily Diary List*/}
                        <DashboardDiaryList classes={classes} items={this.state.diaryItems} filterProjects={this.state.multipleSelectProjects} filterContracts={this.state.multipleSelectContracts}/>

                    </GridItem>
                </GridContainer>



            </div>
        );
    }
  }
}

Taskview.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Taskview);

