import React from "react";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import Button from "../CustomButtons/Button";
import attachmentsTableStyles from "../../assets/styles/attachmentsTableStyles";
import ReactTable from "react-table";
import {formatLongDate} from "../../globals";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

//Icons
import GetApp from "@material-ui/icons/GetApp";

const AttachmentsTable = (props) => {

    const downloadAttachment = (rowId) => {

        let id = props.attachments.findIndex(o => o.id === rowId);
        let uuid = props.attachments[id].uuid;
        let fileName = props.attachments[id].fileName;

        let payload = {
            id: rowId,
            uuid : uuid,
            fileName : fileName
        };

        props.onFileDownload(payload);
    };

    return (
        <div>
            <ReactTable
                data={props.attachments ? props.attachments : []}
                filterable={false}
                sortable={false}
                resizable={false}
                noDataText="No attachments"
                columns={[
                    {
                        Header: "File",
                        accessor: "fileName",
                        headerStyle: {fontSize: 14, fontWeight: 400},
                    },
                    {
                        Header: "Uploaded By",
                        accessor: "user.userDetails",
                        headerStyle: {fontSize: 14, fontWeight: 400},
                        Cell: row => (
                            <p className={props.classes.marBtm0}>{row.value.name + " " + row.value.surname}</p>
                        ),
                    },
                    {
                        Header: "Uploaded",
                        accessor: "attachmentCreated",
                        headerStyle: {fontSize: 14, fontWeight: 400},
                        Cell: row => (
                            <p className={props.classes.marBtm0}>{row.value !== null ? formatLongDate(row.value) : ""}</p>
                        ),
                    },
                    {
                        Header: "Actions",
                        accessor: "id",
                        headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                        style: {"textAlign": "center"},
                        Cell: row => (
                            <div className={props.classes.loadingWheelWrapper}>
                                <Button simple disabled={props.downloadFileLoading[row.value]} onClick={() => downloadAttachment(row.value)}><GetApp color={"primary"}/></Button>
                                {props.downloadFileLoading[row.value] && <CircularProgress size={24} className={props.classes.loadingWheel} />}
                            </div>

                        ),
                    },
                ]}
                defaultSorted={[
                    {
                        id: "id",
                        desc: true
                    }
                ]}
                defaultPageSize={5}
                showPaginationBottom={true}
                className="-striped -highlight"
                showPageJump={false}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        downloadFileLoading : state.fileReducer.downloadFileLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFileDownload : (payload) => dispatch(actionCreators.downloadFile(payload))
    };
};


AttachmentsTable.propTypes = {
    attachments : PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(attachmentsTableStyles)(AttachmentsTable));




