import validationFormsStyle from "../jss/material-dashboard-pro-react/views/validationFormsStyle";
import fullScreenDialogStyles from "./fullScreenDialogStyles";

const communicationRevisionResponseFormStyles = {
    ...validationFormsStyle,
    ...fullScreenDialogStyles,
    checkBoxLabel : {
        marginTop: 30,
        color: "#616161",
        fontWeight: 400
    },
    // verticalAlignContainer : {
    //     display: "table",
    // },
    // verticalAlignElement : {
    //     display: "table-cell", verticalAlign: "middle"
    // },
    noMargin : {
        margin : 0
    },
    requiredDescription : {
        margin: 0,
        fontSize: 12,
        fontStyle : "italic"
    },
    floatRight : {
        float : "right"
    },
    input : {
        '& input::-webkit-calendar-picker-indicator' : { position: "absolute", left: 0, marginLeft: 0 },
        '& ::-webkit-datetime-edit' : { position: "relative", left: 16 },
        '& ::-webkit-datetime-edit-fields-wrapper': { position: "relative", left: 16 }
    }
};
export default communicationRevisionResponseFormStyles;
