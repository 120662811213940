import React, {createRef} from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Close from "@material-ui/icons/Close";
import * as PropTypes from 'prop-types';
import CustomInput from "../CustomInput/CustomInput";
import emailInputStyles from "../../assets/styles/emailInputStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Add from "@material-ui/icons/Add";
import {Tooltip} from "@material-ui/core";

class AddEmailInput extends React.Component {
    constructor(props) {
        super(props);
        this.inputNameRef = createRef();
        this.inputEmailRef = createRef();
    }

    render(){
        const { classes } = this.props;
        return(

            <GridContainer className={classes.gridContainer}>
                {/* Heading and add button */}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <hr style={{margin: "0 0 10px 0"}}/>
                    <GridContainer style={{alignItems: "center"}}>
                        <GridItem xs={10} sm={10} md={8} lg={8}>
                            <p className={classes.heading} style={{color : this.props.headingColor}}><strong>{this.props.heading}</strong></p>
                        </GridItem>
                    </GridContainer>
                    <hr style={{margin: "10px 0 0 0"}}/>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <List>
                        <ListItem className={classes.block} style={{paddingLeft: 0}}>
                            <GridItem xs={12} sm={6} md={6} lg={6} className={classes.inputContainer} style={{marginLeft: -15}}>
                              <CustomInput
                                labelText="Name"
                                success={this.props.addNameInputState === "success"}
                                error={this.props.addNameInputState === "error"}
                                id="name"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                labelProps={{
                                    shrink: true
                                }}
                                inputRef={(userInput) => this.inputNameRef = userInput}
                                inputProps={{
                                    defaultValue: this.props.addNameInput || "",
                                    onBlur: (event) => this.props.onChange(this.props.data, event, "name"),
                                    type: "text",
                                    endAdornment:
                                        this.props.addNameInputState === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.danger}/>
                                            </InputAdornment>
                                    ) : undefined
                                    ,
                                    autoComplete: "name"
                                }} />
                            </GridItem>

                    <GridItem xs={12} sm={6} md={6} lg={6} className={classes.inputContainer + " " + classes.marLftN15}>
                      <CustomInput
                        labelText="Email Address"
                        success={this.props.addEmailInputState === "success"}
                        error={this.props.addEmailInputState === "error"}
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        labelProps={{
                            shrink: true
                        }}
                        inputRef={(userInput) => this.inputEmailRef = userInput}
                        inputProps={{
                            defaultValue: this.props.addEmailInput || "",
                            onBlur: (event) => this.props.onChange(this.props.data, event, "email"),
                            type: "email",
                            endAdornment:
                            this.props.addEmailInputState === "error" ? (
                              <InputAdornment position="end">
                                <Close className={classes.danger}/>
                              </InputAdornment>
                            ) : undefined
                            ,
                            autoComplete: "email"
                        }} />
                    </GridItem>

                            <ListItemSecondaryAction style={{marginTop: -2}}>
                                <Tooltip title={"Add Recipient"} aria-label={"Add Recipient"} enterDelay={1000} leaveDelay={200}>
                                    <Button className={classes.addButton}
                                            onClick={() => this.props.onAddToListParent(this.props.data, this.inputNameRef, this.inputEmailRef)}
                                            color={"info"}
                                            size={"sm"}
                                            round
                                            style={{cursor: "pointer", float: "right", padding: "0.5rem", marginRight: 0}}>
                                        <Add style={{marginRight: 0, fontSize: 26}}/>
                                    </Button>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: -21}}>
                    {/* Current emails list */}
                    <List>
                        {
                            this.props.emailList && this.props.emailList.length !== 0  ? this.props.emailList.map((item, index) =>
                                <ListItem className={classes.customListItem}
                                          key={index}>
                                    {item.name + " (" + item.email + ")"}
                                    {/* Remove item from list */}
                                    <ListItemSecondaryAction>
                                        <Close
                                            className={classes.closeIcon}
                                            onClick={() => this.props.onRemoveFromListParent(index, this.props.data)}/>
                                    </ListItemSecondaryAction>
                                </ListItem>)
                            : <p style={{marginTop: 12, marginBottom: 11}} className={classes.noDataText}>{this.props.noDataText}</p>
                        }
                    </List>
                </GridItem>
            </GridContainer>

        );
    }

}

AddEmailInput.propTypes = {
    onChange: PropTypes.func,
    onAddToListParent: PropTypes.func,
    onRemoveFromListParent: PropTypes.func,
    addEmailInput: PropTypes.string,
    addNameInputState : PropTypes.string,
    addNameInput : PropTypes.string,
    heading: PropTypes.string,
    emailList: PropTypes.array,
    addEmailInputState : PropTypes.string,
    data : PropTypes.object,
    noDataText: PropTypes.string,
    headingColor : PropTypes.string
};
export default withStyles(emailInputStyles)(AddEmailInput);
