import * as actionTypes from '../actions/actionTypes';

const initialState = {
    permissions: [],
    permissionsLoading : false,
    permissionsError : null,
    role : null,
    roleLoading : false,
    roleError : null,
};

//START : Get Permissions

const getPermissionsStart = (state) => {
    return { 
        ...state, 
        permissionsLoading : true
    };
}

const getPermissionsSuccess = (state, action) => {
    return { 
        ...state, 
        permissions : action.data,
        permissionsLoading : false
    };
}

const getPermissionsFail = (state, action) => {
    return { 
        ...state, 
        permissionsError : action.error,
        permissionsLoading : false
    };
}

//END : Get Permissions

//START : Get Role

const getRoleStart = (state) => {
    return { 
        ...state, 
        roleLoading : true
    };
}

const getRoleSuccess = (state, action) => {
    return { 
        ...state, 
        role : action.data,
        roleLoading : false
    };
}

const getRoleFail = (state, action) => {
    return { 
        ...state, 
        roleError : action.error,
        roleLoading : false
    };
}

//END : Get Role

//START : User Logout

const userLogout = (state) => {
    return { 
        ...state,
    };
}

//End : User Logout

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_PERMISSIONS_START: return getPermissionsStart(state);
        case actionTypes.GET_PERMISSIONS_SUCCESS: return getPermissionsSuccess(state, action);
        case actionTypes.GET_PERMISSIONS_FAIL: return getPermissionsFail(state, action);
        case actionTypes.GET_ROLE_START: return getRoleStart(state);
        case actionTypes.GET_ROLE_SUCCESS: return getRoleSuccess(state, action);
        case actionTypes.GET_ROLE_FAIL: return getRoleFail(state, action);
        case actionTypes.USER_LOGOUT: return userLogout(state);
        default: return state;
    }
};

export default reducer;