import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import userReducer from "./store/reducers/user";
import communicationReducer from "./store/reducers/communications";
import errorReducer from "./store/reducers/error";
import successReducer from "./store/reducers/success";
import fileReducer from "./store/reducers/file";
import tabReducer from "./store/reducers/tab";
import projectAndContractReducer from "./store/reducers/projectAndContract";
import toDoReducer from "./store/reducers/toDo";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["tabReducer"]
};

const composeEnhancers = process.env.REACT_APP_ENV === 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
const loggerMiddleWare = createLogger();

const appReducer = combineReducers({
    userReducer: userReducer,
    communicationReducer: communicationReducer,
    errorReducer: errorReducer,
    fileReducer: fileReducer,
    successReducer: successReducer,
    projectAndContractReducer: projectAndContractReducer,
    tabReducer: tabReducer,
    toDoReducer: toDoReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:root');
        state = undefined;
    }
    return appReducer(state, action);
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(thunkMiddleware, loggerMiddleWare)
    )
);

export default store;