import React from 'react';
import { withRouter} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { getCurrentDate } from "../../globals";


class CustomDashBoardToDoItem extends React.Component{

    state = {
        // open: true,

        days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

        toDoItem : {},
        eventState: {},
        type: "",

        timeLeftColor: "",
    };

    //function that routes to specific
    routeToViewMore = () => {

        let path = '';

        if(this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote"){
            path = '/app/viewEvent';

            this.props.history.push({
                pathname: path,
                state: {
                    id: this.props.item.compensationEvent.id,
                },
            });
        }
        else if(this.props.item.type.toLowerCase() === "cen"){
            path = '/app/viewNotice';

            this.props.history.push({
                pathname: path,
                state: {
                    id: this.props.item.compensationEventNotification.id,
                },
            });
        }
        else{
            path = '/app/viewRisk';

            this.props.history.push({
                pathname: path,
                state: {
                    id: this.props.item.risk.id,
                },
            });
        }


    };


    getTimeToReply = (item, type) => {

        let currentDate = new Date(getCurrentDate() + " 23:59:59");

        let actionDays = 0;

        if(type  !== "risk"){
            if(item.actionPeriodDate !== null){
                let actionDate = new Date(item.actionPeriodDate);

                let timeDiff = Math.abs(actionDate.getTime() - currentDate.getTime());
                actionDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            }
            else{
                actionDays = 0;
            }
        }
        else{

            let riskDate = item.riskReductionMeetingDate;
            let riskTime = item.riskReductionMeetingTime;

            let riskDateTimeStr = riskDate + " " + riskTime;
            let actionDate = new Date(riskDateTimeStr);

            let timeDiff = Math.abs(actionDate.getTime() - currentDate.getTime());
            actionDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        }
        return actionDays;

    };

    updateTimeToReply = () => {

        // this.initiate();

        let daysToReply = 0;

        if(this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote"){
            daysToReply = this.getTimeToReply(this.props.item.compensationEvent, "ce");
        }
        else if(this.props.item.type.toLowerCase() === "cen"){
            daysToReply = this.getTimeToReply(this.props.item.compensationEventNotification, "cen");
        }
        else{
            daysToReply = this.getTimeToReply(this.props.item.risk, "risk");
        }

        return daysToReply + " day(s) left";

    };

    updateTimeToReplyColor = () => {
        let daysToReply = 0;
        let colourDays = "";
        let totalActionDays = 0;
        if(this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote"){
            daysToReply = this.getTimeToReply(this.props.item.compensationEvent, "ce");
            totalActionDays = parseInt(this.props.item.compensationEvent.eventState.actionPeriod);
        }
        else if(this.props.item.type.toLowerCase() === "cen"){
            daysToReply = this.getTimeToReply(this.props.item.compensationEventNotification, "cen");
            totalActionDays = parseInt(this.props.item.compensationEventNotification.eventState.actionPeriod);
        }
        if(daysToReply === 0){
            colourDays = "#F44336";
        }
        else{
            if(this.props.item.type.toLowerCase() === "risk"){
                if(daysToReply <= 2){
                    colourDays = "#F44336";
                }
                else if(daysToReply <= 4){
                    colourDays = "#FF9800";
                }
                else{
                    colourDays = "#4CAE50";
                }
            }
            else{
                if(totalActionDays !== 0){
                    let amberBorderLine = Math.ceil(((totalActionDays / 6) * 3));
                    let redBorderLine = Math.ceil((totalActionDays / 6));
                    if(daysToReply <= redBorderLine){
                        colourDays = "#F44336";
                    }
                    else if(daysToReply <= amberBorderLine){
                        colourDays = "#FF9800";
                    }
                    else{
                        colourDays = "#4CAE50";
                    }
                }
                else{
                    colourDays = "#F44336";
                }
            }
        }
        return colourDays;
    };


    render(){
        return (
            <div>
            <Card style={{marginTop: 0, marginBottom: 20}}>
                <CardBody style={{padding: 0}}>
                    <GridContainer>
                        {/* Priority 1: CE/CEN Number*/}
                        <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                            <div style={{padding: "0 0 0 10px"}}>
                                <Tooltip
                                    title={this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote" ? "CE Number":
                                        this.props.item.type.toLowerCase() === "cen" ? "CEN Number" : "EW Number"}
                                    placement="top"
                                >
                                    <p style={{margin: "5px 0 0 0", fontSize: 10}}>{this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote" ? this.props.item.compensationEvent.ceNumber:
                                        this.props.item.type.toLowerCase() === "cen" ? this.props.item.compensationEventNotification.cenNumber : this.props.item.risk.riskNumber}</p>
                                </Tooltip>
                            </div>
                        </GridItem>

                        {/* Priority 1: Day(s) left to reply */}
                        <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                            <div style={{padding: "0 10px 0 0"}}>

                                <p style={{margin: "5px 0 0 0", fontSize: 10, float: "right"}}>
                                    {this.updateTimeToReply()}
                                    <FiberManualRecord style={{float: "right", width: 14, height: 14, marginLeft: 5, marginTop: 3, color: this.updateTimeToReplyColor()}}/>
                                </p>
                            </div>
                        </GridItem>

                        {/* Priority 1: To Do Description*/}
                        <GridItem xs={12} sm={12} lg={12} >
                            <div style={{padding: "0 10px"}}>
                                <Tooltip
                                    title={this.props.item.description !== null ?
                                      this.props.item.description :
                                        this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote" ?
                                            "" + this.props.item.compensationEvent.eventState.description :
                                                this.props.item.type.toLowerCase() === "cen" ?
                                                  "" + this.props.item.compensationEventNotification.eventState.description :
                                                    "" }
                                    placement="top"
                                >
                                    <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}}>{this.props.item.description !== null ? this.props.item.description : this.props.item.type.toLowerCase() === "ce" || this.props.item.type.toLowerCase() === "reviewquote" ?  "" + this.props.item.compensationEvent.eventState.description :
                                        this.props.item.type.toLowerCase() === "cen" ? "" + this.props.item.compensationEventNotification.eventState.description : "" }</h4>
                                </Tooltip>
                            </div>
                        </GridItem>


                        <GridItem xs={12} sm={12} lg={12}>
                            <div style={{padding: "0 10px"}}>
                                <hr style={{margin: 0}}/>
                                <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "5px 0 5px 0"}} onClick={() => this.routeToViewMore()}>View More</Button>
                            </div>
                        </GridItem>


                    </GridContainer>

                </CardBody>
            </Card>

            </div>

        );
    }
}


export default withRouter(CustomDashBoardToDoItem);
