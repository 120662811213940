import cardFooterStyle from "../../assets/jss/material-dashboard-pro-react/components/cardFooterStyle";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const radioGroupInputStyles = {
    ...customCheckboxRadioSwitch,...cardFooterStyle,
    marginTopOfEl : {
        marginTop: 15
    },
    card : {
        marginBottom: -5,
        marginTop: 10,
        backgroundColor: "#fff",
        minWidth: 250
    },
    selfAlignCenter : {
        alignSelf: "center"
    },
    textAlignCenter : {
        textAlign: "center"
    },
    marLeft10 : {
        marginLeft: -10
    },
    marZero : {
        margin: 0
    },
    marLeft20 : {
        marginLeft: -20
    },
    infoDescription : {
        marginTop: 5,
        //marginLeft: 15,
        fontSize: 11,
        fontStyle : "italic"
    }
};

export default radioGroupInputStyles;