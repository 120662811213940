import React from "react"
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
// import {allDiariesTable} from "../../../variables/general.jsx";

import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react.jsx";
import { daysArray, monthsArray } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";
// import Tooltip from "@material-ui/core/Tooltip";
// import {CSVLink} from "react-csv";
// import GetApp from "@material-ui/icons/GetApp";

//Custom styles
const styles = theme => ({
    ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
});

class Diary extends React.Component{

    constructor(props) {

        super(props);

        this.state = {

            diaryRowKey: 0,
            openActionDialog: false,

            days : daysArray(),
            months : monthsArray(),

            diaryItems: [],

            headers : [
                { label: 'Id', key: 'id' },
                { label: 'Contract', key: 'contract.number' },
                { label: 'Project', key: 'contract.project.title' },
                { label: 'Status', key: 'dailyDiaryResponse.status' },
                // { label: 'Submitted Date', key: 'dateCreated' },
                // { label: 'Last Updated', key: 'dateUpdated' },
            ],

            showCreateDiaryEntryButton: false,

        };
    }

    //Add diary entry routing
    routeToAddDiaryEntry = () => {
        let path = '/app/addDiary';
        this.props.history.push({
            pathname: path
        });
    };

    //View details of diary entry routing
    routeViewDiaryEntry = (rowData) => {

        let path = '/app/viewDiary';
        this.props.history.push({
            pathname: path,
            state: {
                id: rowData.id,
            }
        });
    };

    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
          (result) => {

              if(result !== undefined){
                  this.setState({
                      isLoaded: true,
                      // permissions: result
                  });

                  this.setPermissions(result);
              }



          },
          (error) => {
              this.setState({
                  isLoaded: true,
                  error
              });
          }
        )
        // },2000)

    };

    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let creatDiaryEntry = permissions.find(o => o.type === "dailyDiary" && o.subtype === "create").permission;

            if(creatDiaryEntry === "true"){
                this.setState({
                    showCreateDiaryEntryButton: true,
                });
            }
            else{
                this.setState({
                    showCreateDiaryEntryButton: false,
                });
            }

        }
        else{
            this.setState({
                showCreateDiaryEntryButton: false,
            });
        }

    };

    //call initial daily_diaries api request
    getAllDiaries = () => {

        const headers = {
            "Content-Type" : "application/json"
        };

        // setTimeout(() => {
            fetch(getApiRoot() + "/api/daily_diaries", {
                credentials: "include",
                headers,
                method: 'GET',
            }).then(res => {

                if(res.status === 200){
                    return res.json();
                }
                else{
                    alert("Something went wrong - HTTP " + res.status);
                }
            })
                .then(
                    (result) => {

                        if(result !== undefined){
                            this.setState({
                                // isLoaded: true,
                                diaryItems: result,
                            });

                            this.getPermissions();
                        }

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        // },2000)
    };

    //event that fires before component is rendered
    componentDidMount() {
        this.getAllDiaries();
    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}} /></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer>
                    {/*Add Diary Entry*/}

                    { this.state.showCreateDiaryEntryButton ?
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignInNavBar}>
                        <Button justIcon round color="primary" className={classes.buttonStyle2}
                                onClick={() => this.routeToAddDiaryEntry()}><Add/></Button>
                    </GridItem> : "" }
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.cardAlignment}>
                            <CardBody>
                                {/*<GridContainer>*/}
                                {/*    <GridItem xs={12}>*/}
                                {/*        <Tooltip*/}
                                {/*            title={"Export to Excel"}*/}
                                {/*            placement="bottom"*/}
                                {/*            classes={{ tooltip: classes.tooltip }}*/}
                                {/*        >*/}
                                {/*            <CSVLink style={{float: "right"}} filename={"Diary Register.csv"} data={this.state.diaryItems} headers={this.state.headers} separator={";"} ><GetApp color={"primary"}/></CSVLink>*/}
                                {/*        </Tooltip>*/}
                                {/*    </GridItem>*/}
                                {/*</GridContainer>*/}
                                {/*Diary Register Table*/}
                                <ReactTable
                                    /*Click on a row to see diary entry details functionality*/
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {

                                                this.routeViewDiaryEntry(rowInfo.row);

                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.state.diaryItems ? this.state.diaryItems: []}
                                    filterable
                                    resizable={false}
                                    showPageJump={false}
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "Diary Date",
                                            accessor: "diaryDate",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {textAlign: "center"},
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Contract",
                                            accessor: "contract.number",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {textAlign: "center"}
                                        },
                                        {
                                            Header: "Project",
                                            accessor: "contract.project.title",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {textAlign: "center"},
                                        },
                                        {
                                            Header: "Submitted",
                                            accessor: "dateDiaryCreated",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {textAlign: "center"},
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            //Custom filtering with dropdown
                                            Header: "Status",
                                            accessor: "dailyDiaryResponse.status",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {textAlign: "center"},
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "all") {
                                                    return true;
                                                }
                                                if (filter.value === "Pending") {
                                                    return row[filter.id].toLowerCase() === "pending";
                                                }
                                                if (filter.value === "Accepted") {
                                                    return row[filter.id].toLowerCase() === "accepted";
                                                }
                                                return row[filter.id].toLowerCase() === "rejected";
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Accepted">Accepted</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                        }

                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{cursor: "pointer"}}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}
export default withStyles(styles)(Diary);