import compensationEventTabsStyles from "./compensationEventTabsStyles";

const communicationRegisterStyles = theme => ({
    ...compensationEventTabsStyles(theme),
    gridItem : {
        marginTop: 10
    },
    loadGridItem : {
        textAlign: "center"
    },
    loadingWheel : {
        position: "absolute", 
        top: "50%", 
        left: "calc(50% - 20px)"
    },
    marBtm0 : {
        marginBottom: 0
    },
    cardAlignment: {
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            marginTop: 30
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 30
        }
    },
    alignTable: {
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 0
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 0
        }
    },
});
export default communicationRegisterStyles;
