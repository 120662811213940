import React, { createRef } from "react";
import { Dialog } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../CustomButtons/Button";
import {Document, Page} from "react-pdf";
import { pdfjs } from 'react-pdf';
import CircularProgress from "@material-ui/core/CircularProgress";
import previewDialogStyles from "../../../assets/styles/previewDialogStyles";
import Transition from "../../CustomDialogs/FullScreenDialogTransition";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class SubmissionPreviewDialog extends React.Component {

    constructor(props){
        super(props);
        this.topOfPage = createRef(); //ref to top of dialog
    }

    state = {
        numPages : "",
        scale : 1.6,
        showScrollTop : false,
    };

    closeDialog = () => {
        this.props.onClose();
    };

    //when document loads function executes
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    //scroll to the top of the page onclick
    scrollToTop = () => {
        if(this.topOfPage.current){
            this.topOfPage.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    //show scroll to top button when user initially scrolls
    handleScroll = () => {
        if(!this.state.showScrollTop){
            this.setState({
                showScrollTop : true
            });
        }
    };

    //set component styling options depending on the screen size
    setUpResponsiveOptions = () => {
        let mql = window.matchMedia('(max-width: 600px)');
        if(mql.matches){
            this.setState({
                scale : 1.0,
            });
        }
    };

    componentDidMount() {
        //set scale of pdf depending on the screen size
        this.setUpResponsiveOptions();
    }

    render(){
        const { numPages, scale, showScrollTop} = this.state;
        const { classes, loadingSend ,loadingSendDownload } = this.props;

        return (
          <Dialog
              open={this.props.open}
              onClose={() => this.closeDialog()}
              aria-labelledby="previewDialog"
              fullScreen={true}
              TransitionComponent={Transition}
              className={classes.dialog}>

              <AppBar className={classes.appBar}>
                  <Toolbar>
                      <Typography variant={"h6"} className={classes.appBarHeading}>
                          Preview
                      </Typography>

                      {/*close button*/}
                      <Button
                        size="sm"
                        simple={true}
                        className={classes.appBarButtons}
                        onClick={() => this.closeDialog()}
                        disabled={loadingSend || loadingSendDownload}>
                          Close
                      </Button>

                      {/* send & download button */}
                      <div className={classes.relativeDiv}>
                          <Button type={"submit"}
                                  onClick={() => this.props.actionMethod("sendDownload")}
                                  simple={true}
                                  size="sm"
                                  disabled={loadingSend || loadingSendDownload}
                                  className={classes.appBarButtons}>
                              Send & Download
                          </Button>
                          {loadingSendDownload && <CircularProgress size={24} className={classes.loadingWheel} /> }
                      </div>

                      {/* send button */}
                      <div className={classes.relativeDiv}>
                          <Button type={"submit"}
                                  onClick={() => this.props.actionMethod("send")}
                                  simple={true}
                                  size="sm"
                                  disabled={loadingSend || loadingSendDownload}
                                  className={classes.appBarButtons}>
                              Send
                          </Button>
                          {loadingSend && <CircularProgress size={24} className={classes.loadingWheel} /> }
                      </div>
                  </Toolbar>
              </AppBar>

              {/* dialog content which contains the preview pdf */}
              {this.props.data !== "" ?
                <DialogContent onScroll={this.handleScroll} className={classes.dialogContent}>
                    {/* Render preview pdf in dialog */}
                    <div ref={this.topOfPage}/>
                    <div className={classes.pdfContainer}>
                        <Document
                          file={this.props.data}
                          onLoadSuccess={this.onDocumentLoad}
                        >
                            {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                              <Page key={i+1} pageNumber={i+1} scale={scale} className={classes.pdfPage}/>
                            ): ""}
                        </Document>
                    </div>
                </DialogContent>
                : ""}

              {/* Scroll back to top of full page dialog*/}
              {showScrollTop ? <label className={classes.scrollTopLabel}>
                  <IconButton className={classes.scrollTopButton} onClick={() => this.scrollToTop()} component={"span"} size={"sm"} color={"primary"}>
                      <KeyboardArrowUpIcon className={classes.scrollTopIcon}/>
                  </IconButton>
              </label> : ""
              }


          </Dialog>
        );
    }
}

SubmissionPreviewDialog.propTypes = {
    open : PropTypes.bool,
    onClose : PropTypes.func,
    actionMethod : PropTypes.func,
    // data : PropTypes.string,
    loadingSend : PropTypes.bool,
    loadingSendDownload : PropTypes.bool,
};

export default withStyles(previewDialogStyles)(SubmissionPreviewDialog);
