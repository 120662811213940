import React from 'react';

//Custom Components
import CustomDashBoardEventRegisterItem from "../../components/Card/CustomDashBoardEventRegisterItem.jsx";


class DashboardEventRegisterList extends React.Component{

    render(){
        const { classes } = this.props;

        return (
           <div style={{maxHeight:600, overflowX:"hidden", overflowY: "auto"}}>
               {/* Render custom compensation event card for each item in list*/}
                {this.props.items ? this.props.items
                    .filter(item => {       //filter based on project and contract chosen

                        let projectFlag = false;
                        let contractFlag = false;

                        this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                            parseInt(item.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false ) : projectFlag = true;

                        this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                            parseInt(item.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false ) : contractFlag = true;

                        return projectFlag || contractFlag;

                    })
                    .reverse().map( (item, index) =>
               <CustomDashBoardEventRegisterItem key={index} item={item} classes={classes}/>
               ) : ""}
           </div>

        );
    }
}

export default DashboardEventRegisterList;