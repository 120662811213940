const fullScreenDialogStyles = {
  relativeDiv : {
    position: 'relative',
  },
  loadingWheel : {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color : "#ffffff"
  },
  appBar : {
    position: "fixed",
    minHeight: 65,
    justifyContent: "center"
  },
  appBarHeading : {
    flex:1,
    color : "#fff",
    textTransform : "capitalize"
  },
  appBarButtons : {
    padding : 5
  },
  dialogContent : {
    backgroundColor : "#eeeeee",
    position: "absolute",
    top: 65,
    bottom: 0,
    height: "calc(100% - 65px)",
    width: "100%",
    overflow: "auto"
  },
  scrollTopLabel : {
    position: "fixed",
    right : 30,
    bottom : 30,
    "& :hover" : {
      backgroundColor: "#3f51b5",
    },
    "@media (max-width: 600px)": {
      right : 10,
      bottom : 10,
    },
  },
  scrollTopLabelMobile : {
    position: "fixed",
    right : 10,
    bottom : 10,
    "& :hover" : {
      backgroundColor: "#3f51b5",
    },
  },
  scrollTopButton : {
    backgroundColor: "#3f51b5",
    width: 40,
    height: 40,
    "& :hover" : {
      backgroundColor: "#3f51b5",
    }
  },
  scrollTopIcon : {
    color: "#fff"
  }
};

export default fullScreenDialogStyles;