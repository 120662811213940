import React from 'react';
import * as PropTypes from "prop-types";
import { withRouter} from 'react-router-dom';

//Core components
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from '@material-ui/core/Collapse';

//Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

//Custom Components
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

class CustomDashBoardEventRegisterItem extends React.Component{

    state = {
        open: true,
        severityColor: "",
        days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    };

    //function that handles opening priority 2
    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    //function that routes to specific compensation event
    routeToViewEvent = () => {
        let path = '/app/viewEvent';
        this.props.history.push({
            pathname: path,
            state: {
                id: this.props.item.id
            },
        });
    };

    // componentDidMount() {
    //     if(this.props.item.risk.severityImpact.toLowerCase() === "very high"){
    //         this.setState({
    //             severityColor: "#F44336",
    //         })
    //     }
    //     else if(this.props.item.risk.severityImpact.toLowerCase() === "high"){
    //         this.setState({
    //             severityColor: "#F44336",
    //         })
    //     }
    //     else if(this.props.item.risk.severityImpact.toLowerCase() === "medium"){
    //         this.setState({
    //             severityColor: "#FF9800",
    //         })
    //     }
    //     else {
    //         this.setState({
    //             severityColor: "#4CAE50",
    //         })
    //     }
    // }

    render(){
        const { classes } = this.props;

        return (
            <Card style={{marginTop: 0, marginBottom: 20}}>
                <CardBody style={{padding: 0}}>
                    <GridContainer>

                        {/*Priority 1: CE Number*/}
                        <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                            <div style={{padding: "0 0 0 10px"}}>
                                <Tooltip
                                    title="Compensation event number"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <p style={{margin: "5px 0 0 0", fontSize: 10}}>{this.props.item.ceNumber}</p>
                                </Tooltip>
                            </div>
                        </GridItem>

                        {/*Priority 1: Quote status and severity*/}
                        <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>
                            <div style={{padding: "0px 10px 0 0"}}>
                                <p style={{margin: "5px 0 0 0", fontSize: 10, float: "right"}}>{this.props.item.eventState.description}</p>
                            </div>
                        </GridItem>

                        {/*Priority 1: CE description*/}
                        <GridItem xs={12} sm={12} lg={12} >
                            <div style={{padding: "0 10px"}}>
                                <Tooltip
                                    title={this.props.item.description}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}} className={classes.cardTitle}>{this.props.item.description}</h4>
                                </Tooltip>
                            </div>
                        </GridItem>

                        {/*Priority 2 Expand button*/}
                        <GridItem xs={12} sm={12} lg={12}>
                            <div style={{height: 14}}>
                                <Button style={{padding: 0, margin: 0, backgroundColor: "rgb(22, 166, 171)", boxShadow: "none"}} fullWidth onClick={this.handleClick}>{this.state.open ?  <ExpandMore style={{cursor:"pointer",height: 14, width: 14}}/> : <ExpandLess style={{cursor:"pointer", height: 14, width: 14}}  />}</Button>
                            </div>
                        </GridItem>

                    </GridContainer>
                    <Collapse in={!this.state.open} timeout="auto" unmountOnExit>
                        <GridContainer style={{fontSize: 10, padding: "10px 10px 0 10px"}}>

                            {/*Priority 2: Potential Impact*/}
                            {/*<GridItem xs={12} sm={12} lg={12} style={{fontSize: 10, color: "#000", fontWeight: 400}}>*/}
                            {/*    <hr style={{margin: 0}}/>*/}
                            {/*    <p style={{marginBottom: 0}}>Potential Impact</p>*/}
                            {/*    <hr style={{margin: 0}}/>*/}
                            {/*</GridItem>*/}

                            {/*Priority 2: Increase Prices and Delay Completion*/}
                            {/*<GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>*/}
                            {/*    <GridContainer>*/}
                            {/*        <GridItem xs={6} sm={6} lg={6}>*/}
                            {/*            <Tooltip*/}
                            {/*                title={"Increase the total of the Prices:"}*/}
                            {/*                placement="top"*/}
                            {/*                classes={{ tooltip: classes.tooltip }}*/}
                            {/*            >*/}
                            {/*                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Increase the total of the Prices:</p>*/}
                            {/*            </Tooltip>*/}

                            {/*        </GridItem>*/}
                            {/*        <GridItem xs={6} sm={6} lg={6}>*/}
                            {/*            <Tooltip*/}
                            {/*                title={"Delay Planned Completion:"}*/}
                            {/*                placement="top"*/}
                            {/*                classes={{ tooltip: classes.tooltip }}*/}
                            {/*            >*/}
                            {/*                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Delay Planned Completion:</p>*/}
                            {/*            </Tooltip>*/}
                            {/*        </GridItem>*/}
                            {/*    </GridContainer>*/}
                            {/*</GridItem>*/}

                            {/*<GridItem xs={12} sm={12} lg={12}>*/}
                            {/*    <GridContainer>*/}
                            {/*        <GridItem xs={6} sm={6} lg={6}>*/}
                            {/*                <p style={{marginBottom: 0}}>{this.props.item.increaseTheTotalOfPricesValue ? this.props.item.increaseTheTotalOfPrices + " - " + this.props.item.increaseTheTotalOfPricesValue : this.props.item.increaseTheTotalOfPrices}</p>*/}
                            {/*        </GridItem>*/}
                            {/*        <GridItem xs={6} sm={6} lg={6}>*/}
                            {/*            <p style={{marginBottom: 0}}>{this.props.item.delayPlannedCompletionValue ? this.props.item.delayPlannedCompletion + " - " + this.props.item.delayPlannedCompletionValue : this.props.item.delayPlannedCompletion}</p>*/}
                            {/*        </GridItem>*/}
                            {/*    </GridContainer>*/}
                            {/*</GridItem>*/}

                            {/*Priority 2: Impair performance and Delay Meeting*/}
                            {/*<GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>*/}
                            {/*    <GridContainer>*/}
                            {/*        <GridItem xs={12} sm={12} lg={12}>*/}
                            {/*            <Tooltip*/}
                            {/*                title={<p style={{marginBottom: 0}}>Impair performance of the <span style={{fontStyle: "italic"}}>works</span> in use:</p>}*/}
                            {/*                placement="top"*/}
                            {/*                classes={{ tooltip: classes.tooltip }}*/}
                            {/*            >*/}
                            {/*                <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Impair performance of the <span style={{fontStyle: "italic"}}>works</span> in use:</p>*/}
                            {/*            </Tooltip>*/}
                            {/*        </GridItem>*/}
                            {/*    </GridContainer>*/}
                            {/*</GridItem>*/}

                            {/*<GridItem xs={12} sm={12} lg={12}>*/}
                            {/*    <GridContainer>*/}
                            {/*        <GridItem xs={6} sm={6} lg={6}>*/}
                            {/*            /!*<Tooltip*!/*/}
                            {/*            /!*    title="No"*!/*/}
                            {/*            /!*    placement="top"*!/*/}
                            {/*            /!*    classes={{ tooltip: classes.tooltip }}*!/*/}
                            {/*            /!*>*!/*/}
                            {/*            /!*    <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.impactImpairPerformance.charAt(0).toUpperCase() + this.props.item.impactImpairPerformance.slice(1)}</p>*!/*/}
                            {/*            /!*</Tooltip>*!/*/}
                            {/*        </GridItem>*/}
                            {/*        /!*<GridItem xs={6} sm={6} lg={6}>*!/*/}
                            {/*            /!*<Tooltip*!/*/}
                            {/*            /!*    title="No"*!/*/}
                            {/*            /!*    placement="top"*!/*/}
                            {/*            /!*    classes={{ tooltip: classes.tooltip }}*!/*/}
                            {/*            /!*>*!/*/}
                            {/*            /!*    <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.impactDelayMeeting.charAt(0).toUpperCase() + this.props.item.impactDelayMeeting.slice(1)}</p>*!/*/}
                            {/*            /!*</Tooltip>*!/*/}
                            {/*        /!*</GridItem>*!/*/}
                            {/*    </GridContainer>*/}
                            {/*</GridItem>*/}

                            {/*Priority 2: Increase the Contractor's total cost */}
                            {/*<GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>*/}
                            {/*    <Tooltip*/}
                            {/*        title={<p>Increase the <span style={{fontStyle: "italic"}}>Contractor's</span> total cost:</p>}*/}
                            {/*        placement="top"*/}
                            {/*        classes={{ tooltip: classes.tooltip }}*/}
                            {/*    >*/}
                            {/*        <p style={{marginBottom: 0}}>Increase the <span style={{fontStyle: "italic"}}>Contractor's</span> total cost:</p>*/}
                            {/*    </Tooltip>*/}
                            {/*</GridItem>*/}

                            {/*<GridItem xs={12} sm={12} lg={12}>*/}
                            {/*    /!*<Tooltip*!/*/}
                            {/*    /!*    title="No"*!/*/}
                            {/*    /!*    placement="top"*!/*/}
                            {/*    /!*    classes={{ tooltip: classes.tooltip }}*!/*/}
                            {/*    /!*>*!/*/}
                            {/*        <p style={{marginBottom: 0}}>{this.props.item.increaseContractorsTotalCost.charAt(0).toUpperCase() + this.props.item.increaseContractorsTotalCost.slice(1)}</p>*/}
                            {/*    /!*</Tooltip>*!/*/}
                            {/*    <hr style={{margin: 0}}/>*/}
                            {/*</GridItem>*/}

                            {/*Priority 2: Contract and Project */}
                            <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Contract:</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Project:</p>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.props.item.contract.number}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.number}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.props.item.contract.project.title}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.project.title}</p>
                                        </Tooltip>

                                    </GridItem>
                                </GridContainer>
                                <hr style={{margin: 0}}/>
                            </GridItem>

                            {/*Priority 2: View CE and date submitted */}
                            <GridItem xs={12} sm={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "4px 0 4px 0"}} onClick={() => this.routeToViewEvent()}>View Event</Button>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title="Submitted"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{float: "right", margin: "0 0 0 0"}}>{this.props.item.dateOfEventAwareness !== null ? new Date(this.props.item.dateOfEventAwareness).getDate() + " " + this.state.months[new Date(this.props.item.dateOfEventAwareness).getMonth()] + " " + new Date(this.props.item.dateOfEventAwareness).getFullYear() : ""}</p>
                                        </Tooltip>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </CardBody>
            </Card>

        );
    }
}

CustomDashBoardEventRegisterItem.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withRouter(CustomDashBoardEventRegisterItem);