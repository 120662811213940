import React from "react"
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
// import GetApp from "@material-ui/icons/GetApp";
// core components
import Badge from "../../../../components/Badge/Badge.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody.jsx";
import {cardTitle} from "../../../../assets/jss/material-dashboard-pro-react.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { daysArray, monthsArray } from "../../../../globals";
import { getApiRoot } from "../../../../api";

import { CSVLink } from "react-csv";
import fileDownload from "js-file-download";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";
// import Tooltip from "@material-ui/core/Tooltip";
// import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";

//Custom Styles
const styles = theme => ({
    ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
});

class EarlyWarningRegister extends React.Component{

    constructor(props) {
        super(props);

        this.state = {

            days : daysArray(),
            months : monthsArray(),

            error: null,
            isLoaded: false,
            risks: [],

            headers : [
                { label: 'Risk #', key: 'riskNumber' },
                { label: 'Risk Description', key: 'description' },
                { label: 'Severity', key: 'severityImpact' },
                { label: 'Project', key: 'contract.project.title' },
                { label: 'Contract', key: 'contract.number' },
                { label: 'Status', key: 'status' },
                { label: 'Submitted Date', key: 'dateCreated' },
                { label: 'Last Updated', key: 'dateUpdated' },
            ],

            showNewEarlyWarningButton: false,
            loadingDownloadRegister: false,

        };
    }

    //function that routes to new EW page
    routeToAddRiskRegister = () => {
        let path = '/app/newEarlyWarning';
        this.props.history.push({
            pathname: path
        });
    };

    //function that routes to specific risk page
    routeViewRisk = (rowData) => {
        let path = '/app/viewRisk';
        this.props.history.push({
            pathname: path,
            state: {
                id: rowData.id,
            }
        });
    };

    downloadRiskRegister = () => {

        this.setState({
            loadingDownloadRegister: true
        });

        let fileName = "Risk Register.pdf";

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/download_risk_register", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                if( res.headers.get('Content-Disposition') !== null) {
                    let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                    if(strArr.length > 1)
                        fileName = strArr[1];
                }

                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                this.setState({
                    loadingDownloadRegister: false
                });

                if(result !== undefined) {
                    fileDownload(result, fileName);
                }

            },
            (error) => {
               alert(error);
            }
        )
        // },2000)

    };

    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setState({
                        isLoaded: true,
                        // permissions: result
                    });

                    this.setPermissions(result);
                }

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
        // },2000)

    };

    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let createRisk = permissions.find(o => o.type === "risk" && o.subtype === "create").permission;

            if(createRisk === "true"){
                this.setState({
                    showNewEarlyWarningButton: true,
                });
            }
            else{
                this.setState({
                    showNewEarlyWarningButton: false,
                });
            }

        }
        else{
            this.setState({
                showNewEarlyWarningButton: false,
            });
        }

    };

    formatRisksDate = (risksArr) => {

        risksArr.forEach(risk => {

            if(risk.dateCreated === null || risk.dateCreated === ""){
                risk.dateCreated = "";
            }
            else{
                risk.dateCreated = new Date(risk.dateCreated).getDate() + " " + this.state.months[new Date(risk.dateCreated).getMonth()] + " " + new Date(risk.dateCreated).getFullYear();
            }

            if(risk.dateUpdated === null || risk.dateUpdated === ""){
                risk.dateUpdated = "";
            }
            else{
                risk.dateUpdated = new Date(risk.dateUpdated).getDate() + " " + this.state.months[new Date(risk.dateUpdated).getMonth()] + " " + new Date(risk.dateUpdated).getFullYear();
            }

        });

        this.setState({
            risks : risksArr
        });

        this.getPermissions();

    };

    initialRisksData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/risks", {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => {
                if(res.status === 200){
                    return res.json();
                }
                else{
                    alert("Something went wrong - HTTP " + res.status);
                }
            })
            .then(
                (result) => {

                    if(result !== undefined){

                        // this.setState({
                        //     // isLoaded: true,
                        //     risks: result,
                        // });

                        this.formatRisksDate(result);

                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

    //function that calls api before rendering which returns all risks data
    componentDidMount() {
        this.initialRisksData();
    }

    render() {
        const {classes} = this.props;
        const { error, isLoaded, loadingDownloadRegister } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer>
                    {/*<GridItem xs={12}>*/}
                    {/*    <ViewSwitch history={this.props.history} classes={classes} to="/app/risks" label={"Risks"} state={false}/>*/}
                    {/*    /!*<Button justIcon round color="primary" style={{float: "right"}} onClick={this.routeToAddRiskRegister}><Add/></Button>*!/*/}
                    {/*</GridItem>*/}

                    {/* Add new EW button*/}
                    { this.state.showNewEarlyWarningButton ?
                        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignInNavBar}>
                        <Button justIcon round color="primary" className={classes.buttonStyle2}
                                onClick={this.routeToAddRiskRegister}><Add/></Button>
                    </GridItem> : "" }
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.cardAlignment}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>


                                        <div style={{float: "right", position: "relative"}}>
                                            <Button style={{float: "right", padding: 8}} disabled={loadingDownloadRegister} size={"sm"} color={"primary"} onClick={this.downloadRiskRegister}>Export to PDF</Button>
                                            {loadingDownloadRegister && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>

                                        <Button style={{float: "right", padding: 8}} size={"sm"} simple color={"primary"}><CSVLink style={{float: "right"}} filename={"Risk Register.csv"} data={this.state.risks ? this.state.risks: ""} headers={this.state.headers} separator={";"} >Export as CSV</CSVLink></Button>
                                    </GridItem>
                                </GridContainer>


                                {/*<CSVDownload data={this.state.risks} target="_blank" />*/}
                                {/* All risks table */}
                                <ReactTable
                                    /* Click on a row in the table functionality */
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {

                                                //if(rowInfo != null ){
                                                // console.log(rowInfo.row);
                                                // }
                                                // console.log(rowInfo.row.id);
                                                this.routeViewRisk(rowInfo.row);


                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.state.risks ? this.state.risks : []}
                                    filterable
                                    resizable={false}
                                    showPageJump={false}
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "Risk #",
                                            accessor: "riskNumber",
                                        },
                                        {
                                            Header: "Risk Description",
                                            accessor: "description"
                                        },
                                        {
                                            Header: "Severity",
                                            accessor: "severity",
                                            headerStyle: {"textAlign": "center"},
                                            style: {"textAlign": "center"},

                                            //Customized table column for severity
                                            Cell: row => (
                                                row.value.toString() !== ""  ? <Badge color={row.value.toString().toLowerCase() === "very high" ? "danger"
                                                    : row.value.toString().toLowerCase() === "high" ? "danger"
                                                        : row.value.toString().toLowerCase() === "medium" ? "warning"
                                                            : "success"}
                                                >
                                                    {row.value}
                                                </Badge> : ""
                                            ),
                                            //Customized filtering dropdown
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "0") {
                                                    return true;
                                                }
                                                if (filter.value === "1") {
                                                    return row[filter.id].toLowerCase() === "very high";
                                                }
                                                if (filter.value === "2") {
                                                    return row[filter.id].toLowerCase() === "high";
                                                }
                                                if (filter.value === "3") {
                                                    return row[filter.id].toLowerCase() === "medium";
                                                }
                                                return row[filter.id].toLowerCase() === "low";
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="0">Show All</option>
                                                    <option value="1">Very High</option>
                                                    <option value="2">High</option>
                                                    <option value="3">Medium</option>
                                                    <option value="4">Low</option>
                                                </select>
                                        },
                                        {
                                            Header: "Project",
                                            accessor: "contract.project.title"
                                        },
                                        {
                                            Header: "Contract",
                                            accessor: "contract.number"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            //Customized filtering dropdown
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "all") {
                                                    return true;
                                                }
                                                if (filter.value === "Open") {
                                                    return row[filter.id] === "Open";
                                                }
                                                return row[filter.id] === "Closed";
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="Open">Open</option>
                                                    <option value="Closed">Closed</option>
                                                </select>
                                        },
                                        {
                                            Header: "Submitted Date",
                                            accessor: "dateCreated",
                                            //Customized column for date
                                            // Cell: row => (
                                            //     <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            // ),
                                        },
                                        {
                                            Header: "Last Updated",
                                            accessor: "dateUpdated",
                                            //Customized column for date
                                            // Cell: row => (
                                            //     <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            // ),
                                        }

                                    ]}
                                    defaultSorted={[
                                        // {
                                        //     id: "id",
                                        //     desc: true
                                        // },
                                        {
                                            id: "status",
                                            desc: true
                                        },
                                        {
                                            id: "id",
                                            desc: true
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{cursor: "pointer"}}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}
export default withStyles(styles)(EarlyWarningRegister);