import React from "react";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { selectedViewEventNoticeData } from "../../../../variables/general.jsx";
import { allClausesNoticeTable } from "../../../../variables/general.jsx";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ReactTable from "react-table";
import {
    addZero,
    daysArray,
    verifyLength,
    verifyNumber,
    getCurrentDate,
    monthsArray
} from "../../../../globals";
import {getApiRoot} from "../../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle";
import GetApp from "@material-ui/icons/GetApp";
import fileDownload from "js-file-download";
import Tooltip from "@material-ui/core/Tooltip";

import {Document, Page} from "react-pdf";
import { pdfjs } from 'react-pdf';
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";
import * as actionCreators from "../../../../store/actions/tab";
import {connect} from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    ...validationFormsStyle,  ...customSelectStyles, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});

class ViewEventNotice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            days : daysArray(),
            months : monthsArray(),
            
            eventNotice: {
                comments: [],
            },

            timeToReply: 0,

            subClauseDescription: "",

            postData: {
                riskID: "",
                cenID: "",
            },

            noEarlyWarningReason: "",
            showAcceptRejectBtn: false,
            showEarlyWarningInput: false,

            risks:[],
            linkedRisk: 0,
            currentChosenRisk: "",
            ewReasonState: "",
            rejectOptionsState: "",
            earlyWarningSent: "",

            checkedDeclineReason1: false,
            checkedDeclineReason2: false,
            checkedDeclineReason3: false,
            checkedDeclineReason4: false,

            error: null,
            isLoaded: false,
            loadingAddNoticeComment: false,
            loadingSendEW: false,

            extensionDateValue: getCurrentDate(),
            extensionTimeValue: "00:00",
            actionValue: 0,
            assumptions: "",

            previewPDF: "",
            loadingRequestExtension: false,
            loadingSendDownload: false,
            loadingSend: false,
            loadingPreview: false,

            loadingDownloadFile: false,
            loadingAddAttachment: false,
            currentAttachmentFileName: "No file chosen",

            currentFile: {},

            attachments: selectedViewEventNoticeData.event.attachments,
            eventQuote:  selectedViewEventNoticeData.event.eventQuote,
            comments: selectedViewEventNoticeData.event.comments,
            attachmentsUpdated: selectedViewEventNoticeData.event.attachments,
            eventQuoteUpdated:  selectedViewEventNoticeData.event.eventQuote,
            commentsUpdated: selectedViewEventNoticeData.event.comments,

            newNoticeCommentValue: "",
            rejectComments: "",

            rejectAttachments: [],

            costImpactValue: 500000,
            delayImpactValue: 2,
            costImpactValueUpdated: 500000,
            delayImpactValueUpdated: 2,

            contracts: ["Contract 1", "Contract 2" , "Contract 3"],
            projects: ["Project 1", "Project 2" , "Project 3"],
            events: ["Event 1", "Event 2" , "Event 3"],
            linkedRisks: ["Risk 1", "Risk 2", "Risk 3"],
            statusList: ["Active", "Inactive"],
            severityList: ["Low", "Medium", "High"],

            openMainEventEditDialog: false,
            openGlobalEventEditDialog: false,
            openLinkedRiskEditDialog: false,
            openSummaryEventEditDialog: false,
            openImpactEventEditDialog: false,
            openStrategyEventEditDialog: false,
            openAttachmentsEventEditDialog: false,
            openUploadEventAttachmentDialog: false,
            openQuotesEventRejectDialog: false,
            openUploadEventQuoteDialog: false,
            resendNoticeDialogOpen: false,

            openRequestExtensionDialog: false,
            openPreviewDialog: false,
            openAddAssumptionsDialog: false,
            openRejectionDialog: false,
            openAddAttachmentDialog: false
        };

        this.routeChange = this.routeChange.bind(this);
    }

    //when document loads function executes
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    //request extension- and reject dialog changes
    onChange = (event, type) => {

        if(type === "extension"){

            const id = event.target.id;

            if(id === "extensionDateInput"){
                this.setState({
                    extensionDateValue: event.target.value
                });
            }

            if(id === "extensionTimeInput"){
                this.setState({
                    extensionTimeValue: event.target.value
                });
            }
        }

        if(type === "checkBox"){

            const id = event.target.id;

            if(id === "declineReason1"){
                this.setState({
                    checkedDeclineReason1: event.target.checked
                });
            }

            if(id === "declineReason2"){
                this.setState({
                    checkedDeclineReason2: event.target.checked
                });
            }

            if(id === "declineReason3"){
                this.setState({
                    checkedDeclineReason3: event.target.checked
                });
            }

            if(id === "declineReason4"){
                this.setState({
                    checkedDeclineReason4: event.target.checked
                });
            }
        }
    };

    //reason changes when accepting CEN
    change(event) {

        const id = event.target.id;

        if (id === "EWReason") {

            if (verifyLength(event.target.value, 1)) {
                this.setState({ewReasonState: "success"});
            } else {
                this.setState({ewReasonState: "error"});
            }

            this.setState({
                noEarlyWarningReason: event.target.value
            })
        }
    };

    //dropdown changes when accepting CEN
    handleOpenDropdown = (event) => {

        if(event.target.value.toLowerCase() === "yes" || event.target.value.toLowerCase() === "n/a" ){
            this.setState({ showEarlyWarningInput: false, earlyWarningSent: event.target.value });
        }
        else if(event.target.value.toLowerCase() === "no"){
            this.setState({ showEarlyWarningInput: true,  earlyWarningSent: event.target.value});
        }
    };

    //function that opens each edit dialog
    handleClickOpen = (dialog, attachmentType) => {
        if(dialog === "main") {
            this.setState({ openMainEventEditDialog: true });
        }
        else if(dialog === "globals"){
            this.setState({ openGlobalEventEditDialog: true });
        }
        else if(dialog === "risk"){
            this.setState({ openLinkedRiskEditDialog: true });
        }
        else if(dialog === "summary"){
            this.setState({ openSummaryEventEditDialog: true });
        }
        else if(dialog === "impact"){
            this.setState({ openImpactEventEditDialog: true });
        }
        else if(dialog === "quote"){
            this.setState({ openQuotesEventRejectDialog: true});
        }
        else if(dialog === "uploadAttachment"){
            this.setState({ openUploadEventAttachmentDialog: true, currentAttachmentType: attachmentType});
        }
        else if(dialog === "quoteUpload"){
            this.setState({ openUploadEventQuoteDialog: true, currentAttachmentType: attachmentType});
        }
    };

    //cancel clicked on dialogs
    handleCancel = (dialog) => {
        if(dialog === "main"){
            this.setState(
                { openMainEventEditDialog: false, eventTitleUpdated: this.state.eventTitle, eventContractUpdated: this.state.eventContract, eventProjectUpdated: this.state.eventProject, submittedUpdated: this.state.submitted , becameAwareDateUpdated: this.state.becameAwareDate
                });
        }
        else if(dialog === "globals"){
            this.setState(
                { openGlobalEventEditDialog: false, submitterNameUpdated: this.state.submitterName
                });
        }
        else if(dialog === "risk"){
            this.setState(
                { openLinkedRiskEditDialog: false, linkedRiskNameUpdated: this.state.linkedRiskName
                });
        }
        else if(dialog === "summary"){
            this.setState(
                { openSummaryEventEditDialog: false, eventNumberUpdated: this.state.eventNumber, eventStatusUpdated: this.state.eventStatus, eventTypeUpdated: this.state.eventType, eventDetailsUpdated: this.state.eventDetails, affectedActivitiesUpdated: this.state.affectedActivities
                });
        }
        else if(dialog === "impact"){
            this.setState(
                { openImpactEventEditDialog: false, costImpactUpdated: this.state.costImpact, completionDelayUpdated: this.state.completionDelay, impairPerformanceUpdated: this.state.impairPerformance, delayKeyDatesUpdated: this.state.delayKeyDates,
                    costImpactValueEditState: "", completionDelayImpactValueEditState: "",  costImpactValueUpdated: this.state.costImpactValue,  delayImpactValueUpdated: this.state.delayImpactValue,
                });
        }
        else if(dialog === "quote"){
            this.setState({ openQuotesEventRejectDialog: false });
        }
        else if(dialog === "uploadAttachment"){
            this.setState({ openUploadEventAttachmentDialog: false, currentEventAttachmentFileName: "No file chosen"});
        }
        else if(dialog === "quoteUpload"){
            this.setState({ openUploadEventQuoteDialog: false, currentQuoteAttachmentFileName: "No file chosen", currentProgramAttachmentFileName: "No file chosen", quoteCost:""});
        }
    };


    //sets variables after main details changed
    handleChangeMainEventDetails = (event) => {
        const id = event.target.id;

        if(id.toString() === "eventTitleEditInput"){
            this.setState({ eventTitleUpdated: event.target.value});
        }
        else if(id.toString() === "eventContractEditInput"){
            this.setState({ eventContractUpdated: event.target.value});
        }
        else if(id.toString() === "eventProjectEditInput") {
            this.setState({ eventProjectUpdated: event.target.value});
        }
        else if(id.toString() === "eventBecameAwareDateEditInput"){
            this.setState({ becameAwareDateUpdated: event.target.value});
        }
    };

    //sets variables after global details changed
    handleChangeGlobalEventDetails = (event) => {
        const id = event.target.id;

        if(id.toString() === "submitterNameEditInput"){
            this.setState({ submitterNameUpdated: event.target.value});
        }
        else if(id.toString() === "linkedRiskNameEditInput") {
            let index = this.state.risks.findIndex(o => o.id === parseInt(event.target.value));

            this.setState({
                linkedRisk: event.target.value, currentChosenRisk: index
            });
        }
    };

    //sets variables after summary details changed
    handleChangeSummaryEventDetails = (event) => {

        const id = event.target.id;

        if(id.toString() === "eventStatusEditInput"){
            this.setState({ eventStatusUpdated: event.target.value});
        }
        else if(id.toString() === "eventTypeEditInput"){
            this.setState({ eventTypeUpdated: event.target.value});
        }
        else if(id.toString() === "eventDetailsEditInput") {
            this.setState({ eventDetailsUpdated: event.target.value});
        }
        else if(id.toString() === "affectedActivitiesEditInput") {
            this.setState({ affectedActivitiesUpdated: event.target.value});
        }

    };

    //sets variables after impact details changed
    handleChangeImpactEventDetails = (event) => {

        const id = event.target.id;

        if(id.toString() === "costImpactEditInput"){
            this.setState({ costImpactUpdated: event.target.value});
        }
        else if(id.toString() === "costImpactValueEditInput"){

            if(event.target.value === ""){
                this.setState({costImpactValueEditState: ""});
            }
            else if(verifyNumber(event.target.value)) {
                this.setState({costImpactValueEditState: "success"});
            }
            else{
                this.setState({costImpactValueEditState: "error"});
            }
            this.setState({ costImpactValueUpdated: event.target.value});
        }
        else if(id.toString() === "completionDelayEditInput"){
            this.setState({ completionDelayUpdated: event.target.value});
        }
        else if(id.toString() === "completionDelayValueEditInput"){

            if(event.target.value === ""){
                this.setState({completionDelayImpactValueEditState: ""});
            }
            else if(verifyNumber(event.target.value)) {
                this.setState({completionDelayImpactValueEditState: "success"});
            }
            else{
                this.setState({completionDelayImpactValueEditState: "error"});
            }

            this.setState({ delayImpactValueUpdated: event.target.value});
        }
        else if(id.toString() === "impairPerformanceEditInput") {
            this.setState({ impairPerformanceUpdated: event.target.value});
        }
        else if(id.toString() === "delayKeyDatesEditInput") {
            this.setState({ delayKeyDatesUpdated: event.target.value});
        }

    };

    //sets variables after update is clicked on various dialogs
    handleSubmitEditEventDetails = (dialog) => {
        if(dialog === "main") {
            this.setState({ openMainEventEditDialog: false, eventTitle: this.state.eventTitleUpdated, eventContract: this.state.eventContractUpdated, eventProject: this.state.eventProjectUpdated, becameAwareDate: this.state.becameAwareDateUpdated});
        }
        else if(dialog === "globals") {
            this.setState({ openGlobalEventEditDialog: false, submitterName: this.state.submitterNameUpdated});
        }
        else if(dialog === "risk") {
            this.setState({ openLinkedRiskEditDialog: false, linkedRiskName: this.state.linkedRiskNameUpdated});

            this.linkRiskEventNoticeAPI();
        }
        else if(dialog === "summary") {
            this.setState(
                { openSummaryEventEditDialog: false, /*riskNumber: this.state.riskNumberUpdated, */ eventStatus: this.state.eventStatusUpdated, eventType: this.state.eventTypeUpdated, eventDetails: this.state.eventDetailsUpdated, affectedActivities: this.state.affectedActivitiesUpdated
                });
        }
        else if(dialog === "impact") {

            if(this.state.completionDelayImpactValueEditState === "error" || this.state.costImpactValueEditState === "error"){
                alert("Please ensure all values are the correct types");
            }
            else{
                this.setState(
                    { openImpactEventEditDialog: false, costImpact: this.state.costImpactUpdated, costImpactValue: this.state.costImpactValueUpdated, completionDelay: this.state.completionDelayUpdated, delayImpactValue: this.state.delayImpactValueUpdated, impairPerformance: this.state.impairPerformanceUpdated, delayKeyDates: this.state.delayKeyDatesUpdated,
                        costImpactValueEditState: "",  completionDelayImpactValueEditState: ""   });
            }
        }
    };

    //route to go back to notice register
    routeChange() {
        this.props.onSetSelectedTab(1);
        let path = "/app/allEventRegister";
        this.props.history.push({
            pathname: path
        });
    }
    
    //set variables when changing comment
    onCommentChange = (event, type) => {
        if(type === "event"){
            this.setState( { newNoticeCommentValue: event.target.value}
            );
        }

        if(type === "rejectCEN"){
            this.setState( {
                rejectComments: event.target.value
            });
        }
    };

    //function that handles adding a comment
    addComment(type) {

        let data = "";

        if(type === "event"){

            this.setState({
                loadingAddNoticeComment: true
            });

            data = JSON.stringify({
                compensationEventNotification: this.props.location.state.id,
                comment: this.state.newNoticeCommentValue
            });
        }

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/create_comment', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(type === "event"){
                this.setState({ loadingAddNoticeComment : false });
            }

            if(res.status === 200){
                this.setState({ newNoticeCommentValue : "" });
                this.getEventNoticeData();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));

    }

    //function that handles onChange of reason input
    changeReason = (event) => {
        this.setState(({ assumptions: event.target.value }));
    };

    handleOpenPreviewDialog = (actionValue, type) => {

        this.setState({
            actionValue: actionValue
        });

        this.previewAPI(type);
    };

    //function that handles opening dialogs
    handleOpen = (type) => {

        if(type === "extension"){
            this.setState({ openRequestExtensionDialog: true});
        }
        else if(type === "assumptions"){
            this.setState({ openAddAssumptionsDialog: true});
        }
        else if (type === "rejection"){
            this.setState({ openRejectionDialog: true})
        }

        else if(type === "acceptPreview") {
            let flag = true;

            if (this.state.showEarlyWarningInput === true && this.state.noEarlyWarningReason === "") {
                this.setState({ewReasonState: "error"});
                flag = false;
            }

            if (flag) {

                this.handleOpenPreviewDialog(1, "accept");

            } else {
                alert("Please enter a reason");
            }
        }

        else if(type === "rejectPreview") {
            let flag = true;

            if (!this.state.checkedDeclineReason1 && !this.state.checkedDeclineReason2 && !this.state.checkedDeclineReason3 && !this.state.checkedDeclineReason4 ) {
                // this.setState({rejectOptionsState: "error"});
                flag = false;
            }

            if (flag) {

                this.handleOpenPreviewDialog(2, "reject")

            } else {
                alert("Please select at least one statement");
            }
        }

        else if(type === "addAttachment"){
            this.setState({
                openAddAttachmentDialog: true
            });
        }
    };

    //function that handles closing dialogs
    handleClose = (type) => {

        if(type === "extension"){
            this.setState({ openRequestExtensionDialog: false, extensionDateValue: getCurrentDate(), extensionTimeValue: "00:00" });
        }
        else if (type === "assumptions"){
            this.setState({ openAddAssumptionsDialog: false, assumptions: "", showEarlyWarningInput: false, earlyWarningSent: "" })
        }
        else if (type === "rejection"){
            this.setState({ openRejectionDialog: false, checkedDeclineReason1: false, checkedDeclineReason2: false, checkedDeclineReason3: false, checkedDeclineReason4: false, rejectComments: ""})
        }
        else if(type === "addAttachment"){
            this.setState({
                openAddAttachmentDialog: false, currentAttachmentFileName: "No file chosen", currentFile: {}
            });
        }
    };

    //function that closes preview dialog
    handleClosePreviewDialog = () => {

        this.setState({
            openPreviewDialog: false, actionValue: 0, previewPDF: ""
        });

    };

    //function that calls preview api with response value
    previewAPI = (type) => {
        let declinedStatements = [];
        let obj = {};

        this.setState({
            loadingPreview: true
        });

        if(this.state.checkedDeclineReason1){
            declinedStatements.push({description : "arises from a fault of the Contractor"});
        }
        if(this.state.checkedDeclineReason2){
            declinedStatements.push({description : "has not happened and is not expected to happen"});
        }
        if(this.state.checkedDeclineReason3){
            declinedStatements.push({description : "has no effect upon Defined Cost, Completion or meeting a Key Date"});
        }
        if(this.state.checkedDeclineReason4){
            declinedStatements.push({description : "is not one of the compensation events stated in this contract was notified more than eight weeks after the Contractor became aware of it"});
        }

        if (type === "reject"){
            obj = {
                id: this.props.location.state.id,
                declinedStatements: declinedStatements,
                previewAction: "reject",
                rejectComment: this.state.rejectComments
            };
        }
        else if (type === "accept"){
            obj = {
                id: this.props.location.state.id,
                assumptions: this.state.assumptions,
                previewAction: "accept",
                noEarlyWarningReason: this.state.noEarlyWarningReason
            };
        }


        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/preview_compensation_event_notification', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            this.setState({
                loadingPreview: false
            });

            if(result !== undefined){
                this.setState({
                    previewPDF: result, openPreviewDialog: true, loadingPreview: false
                });
            }

        }).catch(error => alert(error));
    };


    // function that handles onClick of actions (request extension)
    handleActionAPI = (type) => {

        let data = {};

        if (type === "send") {
            this.setState({
                loadingSend: true
            });
        } else if (type === "sendDownload") {
            this.setState({
                loadingSendDownload: true
            });
        } else {
            this.setState({
                loadingRequestExtension: true
            });
        }

        if (this.state.actionValue === 1) {
            data = {
                type: "CEN",
                id: this.props.location.state.id,
                response: this.state.actionValue,
                state: this.state.eventNotice.eventState.stateNr,
            };
        } else if (this.state.actionValue === 2) {

            data = {
                type: "CEN",
                id: this.props.location.state.id,
                response: this.state.actionValue,
                state: this.state.eventNotice.eventState.stateNr,
            };
        } else {
            let concatDate = this.state.extensionDateValue + " " + this.state.extensionTimeValue;

            data = {
                type: "CEN",
                id: this.props.location.state.id,
                response: this.state.actionValue,
                state: this.state.eventNotice.eventState.stateNr,
                extensionRequestDate: concatDate
            };
        }

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/action', {
            credentials: "include",
            method: "POST",
            headers,
            body: JSON.stringify(data),
        }).then(res => {

            if (res.status === 200) {


                if (type === "send" || type === "sendDownload") {

                    this.uploadRejectAttachments(type);
                }
                else {
                    window.location.reload();
                }
            }
            else {
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
    };


    //function that calls upload_files api request for attachments
    uploadRejectAttachments = (type) => {

        if(this.state.rejectAttachments.length !== 0) {

            let data = new FormData();
            data.append('id', this.props.location.state.id);
            data.append('type', "compensationEventNotification");

            this.state.rejectAttachments.forEach(object => {
                data.append('file', object.file);
            });

            fetch(getApiRoot() + '/api/upload_files', {
                credentials: "include",
                method: "POST",
                body: data,
            }).then(res => {

                if (res.status === 200) {

                    this.sendAPI(type);
                }
                else {
                    alert("Something went wrong - HTTP: " + res.status);

                    if(type === "sendDownload"){
                        this.setState({ loadingSendDownload : false });
                    }
                    else{
                        this.setState({ loadingSend : false });
                    }
                }
            }).catch(error => alert(error));
        }
        else{
            this.sendAPI(type);
        }

    };


    //implementation of send_response and send_response_and_download api
    sendAPI = (type) => {

        let obj = {};

        if (this.state.actionValue === 1) {

            obj = {
                id: this.props.location.state.id,
                assumptions: this.state.assumptions,
                noEarlyWarningReason: this.state.noEarlyWarningReason
            };
        }
        else if(this.state.actionValue === 2){

            let declinedStatements = [];

            if(this.state.checkedDeclineReason1){
                declinedStatements.push({description : "arises from a fault of the Contractor"});
            }
            if(this.state.checkedDeclineReason2){
                declinedStatements.push({description : "has not happened and is not expected to happen"});
            }
            if(this.state.checkedDeclineReason3){
                declinedStatements.push({description : "has no effect upon Defined Cost, Completion or meeting a Key Date"});
            }
            if(this.state.checkedDeclineReason4){
                declinedStatements.push({description : "is not one of the compensation events stated in this contract was notified more than eight weeks after the Contractor became aware of it"});
            }

            obj = {
                id: this.props.location.state.id,
                declinedStatements: declinedStatements,
                rejectComment: this.state.rejectComments
            };

        }

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        let path = "/api/";

        if(type === "sendDownload"){
            path += "send_compensation_event_notification_and_download";
        }
        else{
            path += "send_compensation_event_notification";
        }

        let fileName = "Approval of CEN.pdf";

        fetch(getApiRoot() + path, {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){

                if(type === "sendDownload"){

                    if( res.headers.get('Content-Disposition') !== null) {
                        let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                        if(strArr.length > 1)
                            fileName = strArr[1];
                    }
                    return res.blob();
                }
                else{
                    return res.text();
                }
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
                if(type === "sendDownload"){
                    this.setState({ loadingSendDownload : false });
                }
                else{
                    this.setState({ loadingSend : false });
                }
            }

        }).then(result => {

            if(result !== undefined) {

                if(type === "sendDownload"){

                    this.setState({
                        loadingSendDownload: false
                    });

                    fileDownload(result, fileName);
                    window.location.reload();
                }
                else{

                    this.setState({
                        loadingSend: false
                    });

                    window.location.reload();
                }
            }

        }).catch(error => alert(error));
    };

    // request extension for CEN
    handleActionClick = (value) => {

        this.setState({
            actionValue: value
        });

        if (verifyLength(this.state.extensionDateValue, 1) && verifyLength(this.state.extensionTimeValue, 1)) {
            this.handleActionAPI("extension");
        }
        else{
            alert("The date of the requested extension cannot be empty");
        }
    };

    // save state of global attachment
    changeEventAttachment = (event) => {
        if(event.target.files.length === 0){
            this.setState({
                currentEventAttachmentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentEventAttachmentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }
    };

    // save state of quote attachment
    changeAttachment = (event) => {
        if(event.target.files.length === 0){
            this.setState({
                currentAttachmentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentAttachmentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }
    };

    //function that handles adding an attachment
    addAttachment = (event) => {

        if(this.state.currentAttachmentFileName === "No file chosen"){
            event.preventDefault();
            alert("Please choose an attachment");
            return;
        }

        let arr = this.state.rejectAttachments;

        let obj = {
            fileName: this.state.currentAttachmentFileName,
            file: this.state.currentFile
        };

        arr.push(obj);

        this.setState({
            rejectAttachments: arr, openAddAttachmentDialog: false, currentAttachmentFileName: "No file chosen", currentFile: {}
        });

        event.preventDefault();
    };

    //function that handles removing an attachment
    removeAttachment = (index) => {
        let arr = this.state.rejectAttachments;
        arr.splice(index, 1);

        this.setState({
            rejectAttachments: arr
        });
    };

    // save state of program attachment
    changeProgramAttachment = (type) => {
        if(type.target.files.length === 0){
            this.setState({
                currentProgramAttachmentFileName: "No file chosen"
            });
        }
        else{
            this.setState({
                currentProgramAttachmentFileName: type.target.files[0].name
            });
        }
    };

    // alert event when submitting global attachment
    handleSubmitAddEventAttachment = (event) => {

        this.setState({
            loadingAddAttachment: true
        });

        let data = new FormData();
        data.append('file', this.state.currentFile);
        data.append('type', 'compensationEventNotification');
        data.append('id', this.props.location.state.id);

        fetch(getApiRoot() + '/api/upload_files', {
            credentials: "include",
            method: "POST",
            body: data,
        }).then(res => {

            this.setState({loadingAddAttachment: false});

            if (res.status === 200) {

                this.setState({
                    openUploadEventAttachmentDialog: false, currentEventAttachmentFileName: "No file chosen", currentFile: {}, loadingDownloadFile: true
                });

                this.getEventNoticeData();

            } else {
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));

        event.preventDefault();
    };

    //implementation of calling download file api
    downloadFileAPI = (uuid, fileName) => {

        fetch(getApiRoot() + '/api/download_file/' + uuid, {
            credentials: "include",
            method: "GET",
        }).then(res => res.blob())
            .then(result => {

                fileDownload(result, fileName);

                this.setState({
                    loadingDownloadFile: false
                });

            }).catch(error => alert(error));
    };

    // alert event when submitting quote attachment
    handleSubmitAddEventQuote = (event) => {
        const id = event.target.id;

        if(id.toString() === "addQuoteCostInput"){
            this.setState({ quoteCost: event.target.value});
        }
        alert("Uploading: " + this.state.currentQuoteAttachmentFileName +  " and " + this.state.currentProgramAttachmentFileName );
        this.setState({
            openUploadEventAttachmentDialog: false, currentQuoteAttachmentFileName: "No file chosen", currentProgramAttachmentFileName: "No file chosen"
        });
        event.preventDefault();
    };

    //calling API
    getEventNoticeData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/compensation_event_notification/" + this.props.location.state.id, {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result !== undefined) {
                        this.setState({
                            // isLoaded: true,
                            eventNotice: result,
                            loadingDownloadFile: false,
                        });

                        this.getSubClauseDescription();

                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                        loadingDownloadFile: false
                    });
                }
            )

    };

    //Get sub clause description
    getSubClauseDescription = () => {

        let clauseIndex = allClausesNoticeTable.clauses.findIndex(o => o.number === this.state.eventNotice.clauseNumber);
        let subClauseIndex = allClausesNoticeTable.clauses[clauseIndex].subClauses.data.findIndex(o => o.number === this.state.eventNotice.subClauseNumber);

        this.setState({
            subClauseDescription: allClausesNoticeTable.clauses[clauseIndex].subClauses.data[subClauseIndex].description
        });

        this.getTimeToReply();

    };

    //calculate days to reply
    getTimeToReply = () => {

        let currentDate = new Date(getCurrentDate() + " 23:59:59");

        if(this.state.eventNotice.actionPeriodDate !== null){

            let actionDate = new Date(this.state.eventNotice.actionPeriodDate);

            let timeDiff = actionDate.getTime() - currentDate.getTime();
            let actionDays = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;

            this.setState({
                timeToReply: actionDays
            });
        }
        else{
            this.setState({
                timeToReply: 0
            });
        }

        this.getRiskData();
    };


    // Get Risk list
    getRiskData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/risks", {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result !== undefined) {
                        this.setState({
                            risks: result,
                        });
                        this.getPermissions();
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    };

    //call permissions api
    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setState({
                        isLoaded: true,
                    });

                    this.setPermissions(result);
                }

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    };

    //based on getPermission API determines permissions
    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let acceptCEN = permissions.find(o => o.type === "compensationEventNotification" && o.subtype === "response").permission;

            if(acceptCEN === "false" ){
                this.setState({
                    showAcceptRejectBtn: false
                });
            }
            else{
                this.setState({
                    showAcceptRejectBtn: true
                });
            }
        }
        else{
            this.setState({
                showAcceptRejectBtn: false,
            });
        }
    };


    //function that retrieves initial Event data to populate the page
    componentDidMount() {
        this.getEventNoticeData();
    }

    render() {
        const { classes } = this.props;
        //loading wheels
        const { error, isLoaded, loadingDownloadFile, loadingRequestExtension, loadingPreview, loadingSend, loadingSendDownload, numPages, timeToReply} = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                // start of selected event notice page
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={6} className={classes.alignFirstCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    <GridItem xs={12} lg={12}>
                                        <h4 style={{marginBottom: 20}}><strong>{this.state.eventNotice.description}</strong></h4>
                                    </GridItem>

                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14}}><strong>Contract: </strong> {this.state.eventNotice.contract.number}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14, float: "right"}}>
                                            <strong>Project: </strong> {this.state.eventNotice.contract.project.title}</p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12}}>Submitted: {this.state.eventNotice.dateCreated !== null ? new Date(this.state.eventNotice.dateCreated).getDate() + " " + this.state.months[new Date(this.state.eventNotice.dateCreated).getMonth()] + " " + new Date(this.state.eventNotice.dateCreated).getFullYear() : ""} </p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12, float: "right"}}>Expected to occur: {this.state.eventNotice.dateExpectedToHappen !== null ? new Date(this.state.eventNotice.dateExpectedToHappen).getDate() + " " + this.state.months[new Date(this.state.eventNotice.dateExpectedToHappen).getMonth()] + " " + new Date(this.state.eventNotice.dateExpectedToHappen).getFullYear() : "N/A"}</p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*event's global details*/}
                    <GridItem xs={12} sm={12} lg={4} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} lg={12}>

                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 15, marginTop: 15}}>
                                            <strong><i>Contractor</i>:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.state.eventNotice.user.userDetails.name +" "+ this.state.eventNotice.user.userDetails.surname + " (" + this.state.eventNotice.user.username + ")"}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 12, marginBottom: 15, marginTop: 15, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.eventNotice.user.userDetails.name +" "+ this.state.eventNotice.user.userDetails.surname + " (" + this.state.eventNotice.user.username + ")"}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 15}}><strong>Linked Risk:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.state.eventNotice.risk ? this.state.eventNotice.risk.description : "No linked risk" }
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 12, marginBottom: 15, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{ this.state.eventNotice.risk ? this.state.eventNotice.risk.description : "No linked risk"}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 10}}><strong>Time to reply:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={timeToReply+ " Day(s) left - " + this.state.eventNotice.eventState.description}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 12, marginBottom: 10, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{timeToReply + " Day(s) left - " + this.state.eventNotice.eventState.description}</p>
                                        </Tooltip>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*event's Summary details*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 16, marginBottom: 30}}><strong>Event Summary</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>

                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Event Number:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.eventNotice.cenNumber}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Status:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.eventNotice.eventState.description}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p style={{fontSize: 14, marginBottom: 10}}><strong>Became Aware:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <Tooltip
                                            title={this.state.eventNotice.dateOfEventAwareness !== null ? new Date(this.state.eventNotice.dateOfEventAwareness).getDate() + " " + this.state.months[new Date(this.state.eventNotice.dateOfEventAwareness).getMonth()] + " " + new Date(this.state.eventNotice.dateOfEventAwareness).getFullYear() : ""}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 14, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.eventNotice.dateOfEventAwareness !== null ? new Date(this.state.eventNotice.dateOfEventAwareness).getDate() + " " + this.state.months[new Date(this.state.eventNotice.dateOfEventAwareness).getMonth()] + " " + new Date(this.state.eventNotice.dateOfEventAwareness).getFullYear() : "N/A"}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Clause: </strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.eventNotice.clauseNumber}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Sub-clause: </strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <div style={{fontSize: 14}}><p>{this.state.eventNotice.subClauseNumber}</p> {this.state.subClauseDescription}</div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={2}>
                                        <p><strong>Details:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.eventNotice.details}</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={2}>
                                        <p><strong>Affected Activities:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.eventNotice.affectedActivities}</p>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*Attachments*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 16}}><strong>Attachments</strong></p>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{position: "relative"}}>
                                            <ReactTable

                                                data={this.state.eventNotice.attachments}
                                                filterable={false}
                                                sortable={false}
                                                resizable={false}
                                                noDataText="No attachments"
                                                columns={[
                                                    {
                                                        Header: "File",
                                                        accessor: "fileName",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                    },
                                                    {
                                                        Header: "Uploaded By",
                                                        accessor: "user.userDetails",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value.name + " " + row.value.surname}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Uploaded",
                                                        accessor: "attachmentCreated",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "id",
                                                        headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                        style: {"textAlign": "center"},
                                                        Cell: row => (
                                                            <div>
                                                                <Button simple disabled={loadingDownloadFile} onClick={() => {

                                                                    this.setState({
                                                                        loadingDownloadFile: true
                                                                    });

                                                                    let id = this.state.eventNotice.attachments.findIndex(o => o.id === row.value);
                                                                    let uuid = this.state.eventNotice.attachments[id].uuid;
                                                                    let fileName = this.state.eventNotice.attachments[id].fileName;

                                                                    this.downloadFileAPI(uuid, fileName);

                                                                }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                                defaultSorted={[
                                                    {
                                                        id: "id",
                                                        desc: true
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationBottom={true}
                                                className="-striped -highlight"
                                                showPageJump={false}
                                            />
                                            { loadingDownloadFile && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                        
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*Accept or Reject grid item*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                    <Card className={classes.cardAlignment}>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <hr style={{margin: "10px 0 10px 0"}}/>
                                <p><strong>Accept or Reject Compensation Event Notification</strong></p>
                                <hr style={{margin: "10px 0 20px 0"}}/>
                            </GridItem>

                            {this.state.eventNotice.eventState.description.toLowerCase() === "awaiting acceptance" || this.state.eventNotice.eventState.description.toLowerCase() === "awaiting acceptance (cl 61.4)" ?
                                <div>
                                    <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                        <p>{"Current Status: " + this.state.eventNotice.eventState.description}</p>
                                        { this.state.showAcceptRejectBtn ?
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} lg={12} style={{padding: "0 !important"}}>

                                                <Button color="primary"
                                                        onClick={() => this.handleOpen("assumptions")}>Accept</Button>
                                                <Button color="info"
                                                        onClick={() => this.handleOpen("rejection")}>Reject</Button>

                                            </GridItem>

                                            {this.state.eventNotice.extension === null ?
                                                <GridItem xs={12} sm={12} lg={12} style={{padding: 0, margin: "5px 15px 0 0"}}>
                                                <Button simple color="primary" style={{float: "left", textAlign: "left", padding: 0, margin: "0 5px"}}
                                                        onClick={() => this.handleOpen("extension")}>Request Extension</Button>
                                            </GridItem> : ""}
                                        </GridContainer> : "" }
                                    </GridItem>

                                </div>
                                :  <GridItem xs={12} sm={12} lg={12}><p>{"Current Status: " + this.state.eventNotice.eventState.description}</p></GridItem>}
                        </CardBody>
                    </Card>
                    </GridItem>

                    <GridContainer justify={"center"}>
                        <GridItem xs={12} sm={12} lg={10}>
                            <Button simple color={"primary"} style={{marginTop: -15}} onClick={this.routeChange}>Back to Event Register</Button>
                        </GridItem>
                    </GridContainer>

                    {/*start of dialogs */}
                    {/*
                //
                //
                //
                //
                */}
                {/*Dialog for editing main details*/}
                    <Dialog
                        open={this.state.openMainEventEditDialog}
                        onClose={() => this.handleCancel("main")}
                        aria-labelledby="mainEventEditDialogTitle"
                    >
                        <DialogTitle id="mainEventEditDialogTitle">Edit Event Main Details</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                id="eventTitleEditInput"
                                name="eventTitle"
                                label="Event Title"
                                fullWidth
                                value={this.state.eventTitleUpdated}
                                onChange={this.handleChangeMainEventDetails}
                                style={{marginBottom: 15}}
                            />
                            <FormControl fullWidth style={{marginBottom: 15}}>
                                <InputLabel htmlFor="eventContractEditInput">
                                    Contract
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.eventContractUpdated}
                                    onChange={this.handleChangeMainEventDetails}
                                    inputProps={{
                                        id: 'eventContractEditInput'
                                    }}
                                >
                                    {this.state.contracts.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth style={{marginBottom: 15}}>
                                <InputLabel htmlFor="eventProjectEditInput">
                                    Project
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.eventProjectUpdated}
                                    onChange={this.handleChangeMainEventDetails}
                                    inputProps={{
                                        id: 'eventProjectEditInput'
                                    }}
                                >
                                    {this.state.projects.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="eventBecameAwareDateEditInput"
                                name="becameAwareDate"
                                label="Became Aware Date"
                                fullWidth
                                value={this.state.becameAwareDateUpdated}
                                onChange={this.handleChangeMainEventDetails}
                                style={{marginBottom: 15}}
                                type="date"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCancel("main")} color="info">
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleSubmitEditEventDetails("main")} color="primary">
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Dialog for editing global details*/}
                    <Dialog
                        open={this.state.openGlobalEventEditDialog}
                        onClose={() => this.handleCancel("globals")}
                        aria-labelledby="globalEventEditDialogTitle"
                    >
                        <DialogTitle id="globalEventEditDialogTitle">Edit Event Global Details</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                id="submitterNameEditInput"
                                label="Submitter Name"
                                fullWidth
                                value={this.state.submitterNameUpdated}
                                onChange={this.handleChangeGlobalEventDetails}
                                style={{marginBottom: 15}}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleCancel("globals")} color="info">
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleSubmitEditEventDetails("globals")} color="primary">
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*dialog to open request extension */}
                    <Dialog
                        open={this.state.openRequestExtensionDialog}
                        onClose={() => this.handleClose("extension")}
                        aria-labelledby="extensionDialog"
                        maxWidth={false}>
                        <DialogTitle id="extensionDialog">Request Extension</DialogTitle>
                        <DialogContent>

                            {/* Date of extension*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <CustomInput
                                    id="extensionDateInput"
                                    labelText="Extended date"
                                    labelProps={{
                                        shrink: true
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.extensionDateValue,
                                        onChange: event => this.onChange(event, "extension"),
                                        type: "date",
                                    }}
                                />
                            </GridItem>
                        </DialogContent>

                        <DialogActions>

                            {/* Extension Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("extension")}>
                                Back
                            </Button>

                            {/* Extension Submit Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleActionClick(3)}
                                        color="primary" >
                                    Submit
                                </Button>
                                {loadingRequestExtension && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>

                        </DialogActions>
                    </Dialog>


                    {/*dialog to add assumptions after accepting CEN */}
                    <Dialog
                        open={this.state.openAddAssumptionsDialog}
                        onClose={() => this.handleClose("assumptions")}
                        aria-labelledby="assumptionsDialog"
                        maxWidth={"md"}
                    >
                        <DialogTitle id="extensionDialog">Accept Compensation Event Notification </DialogTitle>
                        <DialogContent>

                            <GridItem xs={12} sm={12} lg={12}>
                                <CustomInput
                                    id="actionReason"
                                    labelText= "Assumptions in terms of Clause 61.6 "
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 3,
                                        value: this.state.assumptions,
                                        onChange: event => this.changeReason(event),
                                        type: "text",
                                        placeholder: "",
                                    }}
                                />
                            </GridItem>

                            <FormControl style={{marginTop: 20, marginBottom: 15, marginLeft: 15, width: 377}}>
                                <Select
                                    native
                                    onChange={(event) => this.handleOpenDropdown(event)}
                                    value={this.state.earlyWarningSent}
                                >
                                    <option disabled value={""}>Have you received an Early Warning Notification?</option>

                                    <option value={"Yes"}>Yes</option>

                                    <option value={"No"}>No</option>

                                    <option value={"N/A"}>N/A</option>

                                </Select>
                            </FormControl>

                             <GridItem xs={12} sm={12} lg={12}>
                                 {this.state.showEarlyWarningInput === true ?
                                 <CustomInput
                                    id="EWReason"
                                    labelText= "In terms of Clause 61.5, I confirm that you did not give early warning of this event which you could have given because:"
                                    success={this.state.ewReasonState === "success"}
                                    error={this.state.ewReasonState === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        style: {marginTop: 20},
                                        multiline: true,
                                        rows: 3,
                                        value: this.state.noEarlyWarningReason,
                                        onChange: event => this.change(event),
                                        type: "text",

                                        endAdornment:
                                            this.state.ewReasonState === "error" ? (
                                                <InputAdornment position="end">
                                                    <Close className={classes.danger}/>
                                                </InputAdornment>
                                            ) : undefined
                                    }}
                                />
                                : ""}

                            </GridItem>
                        </DialogContent>

                        <DialogActions>
                            {/* assumptions Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("assumptions")}>
                                Back
                            </Button>

                            <div style={{position: "relative"}}>
                                {/* assumptions Submit Button */}
                                <Button type={"submit"} onClick={() => this.handleOpen("acceptPreview")}
                                        color="primary" >
                                    Preview
                                </Button>
                                {loadingPreview && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                    {/*dialog to choose from dropdown after rejecting CEN */}
                    <Dialog
                        open={this.state.openRejectionDialog}
                        onClose={() => this.handleClose("rejection")}
                        aria-labelledby="rejectionDialog"
                        maxWidth={"sm"}
                    >
                        <DialogTitle id="extensionDialog">Reject Compensation Event Notification </DialogTitle>
                        <DialogContent>

                            <FormControl>
                                <FormLabel component="legend">Select the statements which apply</FormLabel>
                                <FormGroup>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedDeclineReason1}
                                                onChange={(event) => this.onChange(event, "checkBox")}
                                                value="arises from a fault of the Contractor"
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'arises from a fault of the Contractor',
                                                    id: 'declineReason1',
                                                }}
                                            />
                                        }
                                        label="arises from a fault of the Contractor"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedDeclineReason2}
                                                onChange={(event) => this.onChange(event, "checkBox")}
                                                value="has not happened and is not expected to happen"
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'has not happened and is not expected to happen',
                                                    id: 'declineReason2',
                                                }}
                                            />
                                        }
                                        label="has not happened and is not expected to happen"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedDeclineReason3}
                                                onChange={(event) => this.onChange(event, "checkBox")}
                                                value="has no effect upon Defined Cost, Completion or meeting a Key Date"
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'has no effect upon Defined Cost, Completion or meeting a Key Date',
                                                    id: 'declineReason3',
                                                }}
                                            />
                                        }
                                        label="has no effect upon Defined Cost, Completion or meeting a Key Date"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedDeclineReason4}
                                                onChange={(event) => this.onChange(event, "checkBox")}
                                                value="is not one of the compensation events stated in this contract was notified more than eight weeks after the Contractor became aware of it"
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'is not one of the compensation events stated in this contract was notified more than eight weeks after the Contractor became aware of it',
                                                    id: 'declineReason4',
                                                }}
                                            />
                                        }
                                        label="is not one of the compensation events stated in this contract was notified more than eight weeks after the Contractor became aware of it"
                                    />
                                </FormGroup>

                            </FormControl>

                            <FormGroup>
                                <FormControl fullWidth>
                                    <CustomInput
                                        labelText="Comments"
                                        id="rejectComments"

                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            multiline: true,
                                            rows: 5,
                                            value: this.state.rejectComments,
                                            onChange: (event) => this.onCommentChange(event, "rejectCEN")
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>

                            <p style={{marginBottom: 0}}><strong>Attachments</strong></p>
                            <Button size={"sm"} onClick={() => this.handleOpen("addAttachment")}>Add Attachment</Button>

                            <List>
                                {
                                    this.state.rejectAttachments.length ? this.state.rejectAttachments.sort(function(a,b){
                                            return parseInt(a.id) - parseInt(b.id);
                                        }).map((item, index) =>
                                            <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                      key={index}>
                                                {item.fileName}
                                                <ListItemSecondaryAction>
                                                    <Close className={classes.danger}
                                                           style={{cursor: "pointer", fontSize: 16, marginTop: 5}}
                                                           onClick={() => this.removeAttachment(index)}/>
                                                </ListItemSecondaryAction>
                                            </ListItem>)
                                        : "No Attachments"
                                }
                            </List>

                            <p style={{marginTop: 30}}>Hence it is the Project Manager’s decision that the Prices, the Completion Date and the Key Dates are not to be changed.</p>
                        </DialogContent>

                        <DialogActions>

                            {/* assumptions Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("rejection")}>
                                Back
                            </Button>
                            <div style={{position: "relative"}}>
                                {/* assumptions Submit Button */}
                                <Button type={"submit"} onClick={() => this.handleOpen("rejectPreview")}
                                        color="primary" >
                                    Preview
                                </Button>
                                {loadingPreview && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                    {/* Preview respond to CEN */}
                    <Dialog
                        open={this.state.openPreviewDialog}
                        onClose={() => this.handleClosePreviewDialog()}
                        aria-labelledby="previewDialog"
                        maxWidth={false}
                    >
                        <DialogTitle id="previewDialog">Preview CEN Response</DialogTitle>
                        {this.state.previewPDF !== "" ?
                            <DialogContent>
                                <div>
                                    <Document
                                        file={this.state.previewPDF}
                                        onLoadSuccess={this.onDocumentLoad}
                                    >
                                        {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                                            <Page key={i+1} pageNumber={i+1} />
                                        ): ""}
                                    </Document>
                                </div>
                            </DialogContent>
                            : ""}
                        <DialogActions>

                            {/* Preview Back Button */}
                            <Button onClick={() => this.handleClosePreviewDialog()}>
                                Back
                            </Button>

                            {/* Preview Send & Download Button */}
                            <div style={{position: "relative"}}>
                                <Button  color="info" onClick={() => this.handleActionAPI("sendDownload")}>
                                    Send & Download
                                </Button>
                                {loadingSendDownload && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>

                            {/* Preview Send Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"}
                                        color="primary" onClick={() => this.handleActionAPI("send")} >
                                    Send
                                </Button>
                                {loadingSend && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                    {/*Dialog for Rejecting quote*/}
                    <Dialog
                        open={this.state.openQuotesEventRejectDialog}
                        onClose={() => this.handleCancel("quotes")}
                        aria-labelledby="quoteEventEditDialogTitle"
                    >
                        <DialogTitle id="quoteEventEditDialogTitle">Reason for Rejection</DialogTitle>
                        <DialogContent>
                            <TextField
                                id="submittedCommentEditInput"
                                label="Comment"
                                fullWidth
                                multiline={true}
                                onChange={(event) => this.handleChangeQuoteEventDetails(event)}
                                style={{marginBottom: 15}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCancel("quote")} color="info">
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                alert("Requested Own Assessment");
                            }} color="primary">
                                Own Assessment
                            </Button>
                            <Button onClick={() => {
                                alert("Requested Alternate Quote");
                            }} color="primary">
                                Alternate Quote
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Dialog for uploading Quote*/}
                    <Dialog
                        open={this.state.openUploadEventQuoteDialog}
                        onClose={() => this.handleCancel("quoteUpload")}
                        aria-labelledby="uploadEventQuoteDialog"
                    >
                        <form>
                            <DialogTitle id="uploadEventQuoteDialog">Upload Quote</DialogTitle>
                            <DialogContent>
                                <CustomInput
                                    autoFocus
                                    id="addQuoteCostInput"
                                    labelText="Quote Cost Amount"
                                    labelProps={{}}
                                    formControlProps={{
                                        fullWidth: true,
                                        style: {marginBottom: 30}
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 1,
                                        value: this.state.quoteCost,
                                        onChange: this.handleSubmitAddEventQuote,
                                        type: "text",
                                    }}
                                />
                                <br/>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <p><strong>Add Quote</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadEventQuoteAttachment"
                                            type="file"
                                            onChange={(event) => this.changeQuoteAttachment(event)}
                                        />

                                        <label htmlFor="uploadEventQuoteAttachment">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentQuoteAttachmentFileName}</p>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                        <p><strong>Add Program</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadEventProgramAttachment"
                                            type="file"
                                            onChange={(type) => this.changeProgramAttachment(type)}
                                        />
                                        <label htmlFor="uploadEventProgramAttachment">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentProgramAttachmentFileName}</p>
                                    </GridItem>
                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleCancel("quoteUpload")} color="info">
                                    Close
                                </Button>
                                <Button type={"submit"}
                                        onClick={(event, type) => this.handleSubmitAddEventQuote(event, type)}
                                        color="primary">
                                    Upload
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openAddAttachmentDialog}
                        onClose={() => this.handleCancel("addAttachment")}
                        aria-labelledby="addAttachmentDialog"
                    >
                        <form>
                            <DialogTitle id="addAttachmentDialog">Add Attachment</DialogTitle>
                            <DialogContent>

                                {/* File Upload */}
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadRejectCENAttachmentInput"
                                            type="file"
                                            onChange={(event) => this.changeAttachment(event)}
                                        />
                                        <label htmlFor="uploadRejectCENAttachmentInput">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>

                                    {/* Current Attachment Name */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentAttachmentFileName}</p>
                                    </GridItem>

                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleClose("addAttachment")} color="info">
                                    Close
                                </Button>

                                {/* Add attachment button */}
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"} onClick={(event) => this.addAttachment(event)}
                                            color="primary">
                                        Add
                                    </Button>
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>

                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        value: state.tabReducer.value
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewEventNotice));

