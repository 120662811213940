import React from "react"
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";
import { getCurrentDate } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter} from 'react-router-dom';
import cardImagesStyles from "../../../assets/jss/material-dashboard-pro-react/cardImagesStyles";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";

const styles = theme => ({
    ...cardImagesStyles,
    ...customSelectStyles,
    ...dashboardStyle,
    ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    textWhite: {
        "&, & *": {
            color: "#FFF"
        }
    }
});

class EventRegister extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            error: null,
            isLoaded: false,
            compensationEvents: [],

            headers : [
                { label: 'CE #', key: 'ceNumber' },
                { label: 'Clause #', key: 'clauseNumber' },
                { label: 'Event Description', key: 'description' },
                { label: 'Status', key: 'eventState.description' },
                { label: 'Remaining period for reply', key: 'eventState.actionPeriod' },
                { label: 'Quoted Cost', key: 'quotedCost' },
                { label: 'Quoted delay to completion', key: 'quotedDelayCompletion' },
            ],
        };
    }

    //route for specific row clicked
    routeViewEvent = (rowData) => {
        let path = '/app/viewEvent';
        this.props.history.push({
            pathname: path,
            state: {
                id: rowData.id,
                toDo: false
            }
        });
    };

    //calculate quoted cost
    getQuotedCost = (quotes) => {

        if(quotes.length !== 0){

            let returnData = "";

            quotes.forEach(quote => {

                if(quote.status.toLowerCase() === "accepted"){
                    returnData = quote.quotedCost;
                }
            });

            return returnData;
        }
        else{
            return "";
        }
    };

    //calculate delay to completion
    getQuotedDelayCompletion = (quotes) => {

        if(quotes.length !== 0){

            let returnData = "";

            quotes.forEach(quote => {

                if(quote.status.toLowerCase() === "accepted"){
                    returnData = quote.quotedDelayCompletion;
                }
            });

            return returnData;
        }
        else{
            return "";
        }
    };

    //calculate remaining period for reply
    getTimeToReply = (eventId) => {

        let event = this.state.compensationEvents.find(o => o.id === eventId);

        let currentDate = new Date(getCurrentDate() + " 23:59:59");

        let actionDays = 0;

       if(event.actionPeriodDate !== null){

            let actionDate = new Date(event.actionPeriodDate);

           let timeDiff = actionDate.getTime() - currentDate.getTime();
           actionDays = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;

        }
        else{
           actionDays = 0;
        }

        return actionDays;
    };

    //filter out clause 61.2's
    filterData = (result) => {
        let filteredData = [];

        result.forEach(object => {
            if(!object.ceNumber.toLowerCase().includes("61.2")) {
                filteredData.push(object);
            }
        });

        this.setState({
            isLoaded: true,
            compensationEvents: filteredData,
        });
    };

    //calling API
    componentDidMount() {

        const headers = new Headers({
            "Accept": "application/json",
        });

            fetch(getApiRoot() + "/api/compensation_events", {
                credentials: "include",
                headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.filterData(result);
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
    }

    render() {
        //loading wheel
        const {classes} = this.props;
        const { error, isLoaded } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                //begin page
                <GridContainer>
                    {/*start of table*/}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card style={{marginTop: 10}}>
                            <CardBody>
                                <ReactTable resizable={false}
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: (e, handleOriginal) => {

                                                this.routeViewEvent(rowInfo.row);

                                                if (handleOriginal) {
                                                    handleOriginal();
                                                }
                                            }
                                        };
                                    }}

                                    data={this.state.compensationEvents}
                                    filterable
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "CE #",
                                            accessor: "ceNumber",
                                            maxWidth: 100
                                        },
                                        {
                                            Header: "Clause #",
                                            accessor: "clauseNumber",
                                            maxWidth: 100
                                        },
                                        {
                                            Header: "Event Description",
                                            accessor: "description",
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "eventState.description",

                                        },
                                        {
                                            Header: "Remaining Period for reply",
                                            accessor: "id",
                                            Cell: row => (
                                                <p>{this.getTimeToReply(row.value) + " day(s)"}</p>
                                            )
                                        },
                                        {
                                            Header: "Cost",
                                            accessor: "quotations",
                                            Cell: row => (
                                                <p>{this.getQuotedCost(row.value)}</p>
                                            )
                                        },
                                        {
                                            Header: "Delay to completion",
                                            accessor: "quotations",
                                            Cell: row => (
                                                <p>{this.getQuotedDelayCompletion(row.value)}</p>
                                            )
                                        },
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                    style={{cursor: "pointer"}}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default withRouter(withStyles(styles)(EventRegister));
