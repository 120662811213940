const cardImagesStyles = {
  cardImgTop: {
    width: "100%",
    borderTopLeftRadius: "calc(.25rem - 1px)",
    borderTopRightRadius: "calc(.25rem - 1px)"
  },
  cardImgBottom: {
    width: "100%",
    borderBottomRightRadius: "calc(.25rem - 1px)",
    borderBottomLeftRadius: "calc(.25rem - 1px)"
  },
  cardImgOverlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    padding: "1.25rem",
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  cardImgOverlayWithHover: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    padding: "1.25rem",
    backgroundColor: "rgba(0,0,0,0.2)",
    "&:hover" :
        {
          backgroundColor: "rgba(0,0,0,0.4)",
        },
  },
  cardImg: {
    width: "100%",
    borderRadius: "calc(.25rem - 1px)"
  }
};

export default cardImagesStyles;
