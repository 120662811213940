import React from "react";
// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody";
import Badge from "../../../../components/Badge/Badge";
import Button from "../../../../components/CustomButtons/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import EditOutlined from "@material-ui/icons/EditOutlined";
import GetApp from "@material-ui/icons/GetApp";
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import CommentsList from "../../../../components/CustomLists/CommentsList.jsx";
// import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Select from '@material-ui/core/Select/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import FormControl from '@material-ui/core/FormControl/index';
// import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
// import Replay from "@material-ui/icons/Replay";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/index";
import ReactTable from "react-table";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import { verifyEmail, addZero, daysArray, monthsArray, verifyLength } from "../../../../globals";
import { getApiRoot } from "../../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import fileDownload from "js-file-download";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";
// import Tooltip from "@material-ui/core/Tooltip";

//Custom Styles
const styles = theme => ({
    ...validationFormsStyle, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});


class ViewEarlyWarning extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            days : daysArray(),
            months : monthsArray(),

            error: null,
            isLoaded: false,
            loadingEditStatus: false,
            loadingSendEW: false,
            loadingAddRiskComment: false,
            // loadingAddMitigationComment: false,
            // loadingMitigationComments: false,
            // loadingMitigationAttachments: false,
            // loadingAddMitigationAttachments: false,
            // loadingAddMitigationStrategy: false,
            loadingAddAttachment: false,
            // loadingMitigationStrategies: false,
            loadingDownloadMitigationFile: false,
            loadingDownloadRiskFile: false,
            loadingChangeReductionDate: false,

            risk: {
                id : "",
                riskNumber : "",
                description : "",
                severityImpact : "",
                severityProbability : "",
                increasePrices : "",
                increasePricesValue : "",
                impactDelayCompletion : "",
                impactDelayCompletionValue : "",
                impactImpairPerformance : "",
                impactDelayMeeting : "",
                increaseContractorsTotalCost : "",
                details : "",
                affectedActivities : "",
                status : "",
                riskReductionMeetingDate : "",
                dateUpdated : "",
                dateCreated : "",
                type : "",
                contract : {
                    id : "",
                    number : "",
                    dateContractCreated : "",
                    project : {
                        id : "",
                        title : "",
                        initialValue : "",
                        revisedValue : "",
                        dateProjectCommences : "",
                        dateProjectCompletes : "",
                        dateProjectCompletesRevised : "",
                        dateProjectCreated : "",
                        dateProjectUpdated : ""
                    },
                    initialValue : "",
                    revisedValue : "",
                    dateContractCommences : "",
                    dateContractCompletes : "",
                    dateContractCompletesRevised : ""
                },
                user : {
                    id : "",
                    username : "",
                    roles : [ ]
                },
                comments: [],
                mitigationStrategies: "",
                attachments: [],
                closingComments: "",
            },

            // isLoggedIn: true,

                // mitigationItems: selectedViewRiskData.risk.mitigationItems,
                // attachments: selectedViewRiskData.risk.attachments,
                // comments: selectedViewRiskData.risk.comments,

                // riskTitleUpdated: "Test",
                // reductionMeetingDateUpdated: "2019-05-10",
                //mitigationItemsUpdated: selectedViewRiskData.risk.mitigationItems,
                //attachmentsUpdated: selectedViewRiskData.risk.attachments,

                statusList: ["Open", "Closed"],
                severityList: ["Low", "Medium", "High"],

                newRiskCommentValue: "",
                newRiskMeetingCommentValue: "",

                // newMitigationStrategy: "",
                // newMitigationStrategyState: "",
                // mitigationCostState: "",

                // mitigationCost: "",
                // mitigatedCostImpact: "No",
                // mitigatedCompletionDelay: "No",
                // mitigatedImpairPerformance: "No",
                // mitigatedDelayKeyDates: "No",
                // mitigatedIncreaseContractorCost: 'No',

                // newMitigationCommentValue: "",
                // newMitigationAttachments: [],

                // newMitigationData: {
                //     risk: this.props.location.state.id,
                //     strategy: "",
                //     costEstimation: 0,
                //     mitigatedPotentialImpact: {
                //         increaseTotalPrices: "No",
                //         delayCompletion: "No",
                //         delayMeetingKeyDate: "No",
                //         impairPerformance: "No",
                //         increaseContractorTotalCost: "No",
                //     }
                // },

                // currentMitigationStrategyId: 0,
                // currentMitigationStrategy: "",
                // currentMitigationCost: "",
                // currentMitigatedCostImpact: "",
                // currentMitigatedCompletionDelay: "",
                // currentMitigatedImpairPerformance: "",
                // currentMitigatedDelayKeyDates: "",
                // currentMitigatedIncreaseContractorsCost: "",
                //
                // currentMitigationComments: [],
                // currentMitigationAttachments: [],

                // openMainRiskEditDialog: false,
                // openGlobalsRiskEditDialog: false,
                openSummaryRiskEditDialog: false,
                // openImpactRiskEditDialog: false,
                openStrategyRiskEditDialog: false,
                openAttachmentsRiskEditDialog: false,
                openMitigationStrategyDetailsDialog: false,
                openUploadRiskAttachmentDialog: false,
                resendEWDialogOpen: false,

                openChangeRiskReductionMeeting: false,

                // riskTitleUpdatedState: "",
                // costImpactValueEditState: "",
                // completionDelayImpactValueEditState: "",

                currentRiskAttachmentFileName: "No file chosen",
                currentAttachmentType: "",
                currentFile: {},

                othersToBeInvited: [],
                newOtherEmail: "",
                newOtherName: "",
                newOtherOrganisation: "",

                showModifyButtons: false,

                newReductionMeetingDate: "",
                newReductionMeetingTime: ""
        };


        // this.routeChange = this.routeChange.bind(this);
    }

    onChange = (event) =>  {

        const id = event.target.id;

        if(id === "riskReductionMeetingDate"){
            this.setState({
                newReductionMeetingDate : event.target.value
            });
        }
        else if(id === "riskReductionMeetingTime"){
            this.setState({
                newReductionMeetingTime : event.target.value
            });
        }

    };


    //function that handles open button of edit dialogs
    handleClickOpen = (dialog, attachmentType) => {
        // if(dialog === "main") {
        //     this.setState({ openMainRiskEditDialog: true });
        // }
        // else if(dialog === "globals"){
        //     this.setState({ openGlobalsRiskEditDialog: true });
        // }
        // else
        if(dialog === "summary"){
            this.setState({ openSummaryRiskEditDialog: true });
        }
        // else if(dialog === "impact"){
        //     this.setState({ openImpactRiskEditDialog: true });
        // }
        // else if(dialog === "strategy"){
        //     this.setState({ openStrategyRiskEditDialog: true});
        // }
        else if(dialog === "uploadAttachment"){
            this.setState({ openUploadRiskAttachmentDialog: true, currentAttachmentType: attachmentType});
        }
        else if(dialog === "reductionMeetingDate"){
            this.setState({
                openChangeRiskReductionMeeting: true
            });
        }

    };

    //function that handles cancel button of edit dialogs
    handleCancel = (dialog) => {
        // if(dialog === "main"){
        //     this.setState(
        //         { openMainRiskEditDialog: false, riskTitleUpdated: this.state.riskTitle, reductionMeetingDateUpdated: this.state.reductionMeetingDate, riskTitleUpdatedState: ""
        //         });
        // }
        // else if(dialog === "warning"){
        //     this.setState(
        //         { openGlobalsRiskEditDialog: false
        //         });
        // }
        // else
            if(dialog === "summary"){
            this.setState({
                openSummaryRiskEditDialog: false, statusUpdated: this.state.risk.status
                //  openSummaryRiskEditDialog: false, severityImpactUpdated: this.state.severityImpact, severityProbabilityUpdated: this.state.severityProbability, riskDetailsUpdated: this.state.riskDetails, affectedActivitiesUpdated: this.state.affectedActivities,
                });
        }
        // else if(dialog === "impact"){
        //     this.setState(
        //         { openImpactRiskEditDialog: false, costImpactUpdated: this.state.costImpact, completionDelayUpdated: this.state.completionDelay, impairPerformanceUpdated: this.state.impairPerformance, delayKeyDatesUpdated: this.state.delayKeyDates,
        //             costImpactValueEditState: "", completionDelayImpactValueEditState: "",  costImpactValueUpdated: this.state.costImpactValue,  delayImpactValueUpdated: this.state.delayImpactValue, increaseContractorCostUpdated: this.state.increaseContractorCost
        //         });
        // }
        // else if(dialog === "strategy"){
        //         this.setState(
        //             {
        //                 newMitigationStrategyState: "",
        //                 mitigationCostState: "",
        //                 openStrategyRiskEditDialog: false,
        //                 newMitigationData: {
        //                     risk: this.props.location.state.id,
        //                     strategy: "",
        //                     costEstimation: 0,
        //                     mitigatedPotentialImpact: {
        //                         increaseTotalPrices: "No",
        //                         delayCompletion: "No",
        //                         delayMeetingKeyDate: "No",
        //                         impairPerformance: "No",
        //                         increaseContractorTotalCost: "No",
        //                     }
        //                 },
        //
        //             });
        // }
        // else if(dialog === "detailedstrategy"){
        //     this.setState({ openMitigationStrategyDetailsDialog: false });
        // }
        else if(dialog === "uploadAttachment"){
            this.setState({ openUploadRiskAttachmentDialog: false, currentRiskAttachmentFileName: "No file chosen", currentFile: {}});
        }
        else if(dialog === "reductionMeetingDate"){
            this.setState({
                openChangeRiskReductionMeeting : false, newReductionMeetingDate : "", newReductionMeetingTime : ""
            });
        }

    };

    //function that handles change of main risk details edit dialog inputs
    // handleChangeMainRiskDetails = (event) => {
    //
    //     const id = event.target.id;
    //
    //     if(id.toString() === "riskDescriptionEditInput"){
    //
    //         if(ViewEarlyWarning.verifyLength(event.target.value, 1)) {
    //             this.setState({riskTitleUpdatedState: "success"});
    //         }
    //         else {
    //             this.setState({riskTitleUpdatedState: "error"});
    //         }
    //         this.setState({ riskTitleUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "reductionMeetingDateEditInput"){
    //         this.setState({ reductionMeetingDateUpdated: event.target.value});
    //     }
    //
    //
    // };

    //function that handles change of risk summary details edit dialog inputs
    handleChangeSummaryRiskDetails = (event) => {

        const id = event.target.id;

        if(id.toString() === "riskStatusEditInput"){
            this.setState({ statusUpdated: event.target.value});
        }
        // else if(id.toString() === "severityImpactEditInput"){
        //     this.setState({ severityImpactUpdated: event.target.value});
        // }
        // else if(id.toString() === "severityProbabilityEditInput") {
        //     this.setState({ severityProbabilityUpdated: event.target.value});
        // }
        // else if(id.toString() === "riskDetailsEditInput") {
        //     this.setState({ riskDetailsUpdated: event.target.value});
        // }
        // else if(id.toString() === "affectedActivitiesEditInput") {
        //     this.setState({ affectedActivitiesUpdated: event.target.value});
        // }

    };

    //function that handles change of risk potential impact edit dialog inputs
    // handleChangeImpactRiskDetails = (event) => {
    //
    //     const id = event.target.id;
    //
    //     if(id.toString() === "costImpactEditInput"){
    //         this.setState({ costImpactUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "costImpactValueEditInput"){
    //
    //         if(event.target.value === ""){
    //             this.setState({costImpactValueEditState: ""});
    //         }
    //         else if(ViewEarlyWarning.verifyNumber(event.target.value)) {
    //             this.setState({costImpactValueEditState: "success"});
    //         }
    //         else{
    //             this.setState({costImpactValueEditState: "error"});
    //         }
    //         this.setState({ costImpactValueUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "completionDelayEditInput"){
    //         this.setState({ completionDelayUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "completionDelayValueEditInput"){
    //
    //         if(event.target.value === ""){
    //             this.setState({completionDelayImpactValueEditState: ""});
    //         }
    //         else if(ViewEarlyWarning.verifyNumber(event.target.value)) {
    //             this.setState({completionDelayImpactValueEditState: "success"});
    //         }
    //         else{
    //             this.setState({completionDelayImpactValueEditState: "error"});
    //         }
    //
    //         this.setState({ delayImpactValueUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "impairPerformanceEditInput") {
    //         this.setState({ impairPerformanceUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "delayKeyDatesEditInput") {
    //         this.setState({ delayKeyDatesUpdated: event.target.value});
    //     }
    //     else if(id.toString() === "increaseContractorCostEditInput"){
    //         this.setState({ increaseContractorCostUpdated: event.target.value});
    //     }
    //
    // };

    //function that handles change of add mitigation strategy dialog inputs
    // handleChangeMitigationStrategy = (event) => {
    //     const id = event.target.id;
    //
    //     if(id.toString() === "addStrategyInput")
    //     {
    //         if(verifyLength(event.target.value, 1)){
    //             this.setState({newMitigationStrategyState: "success"});
    //         }
    //         else{
    //             this.setState({newMitigationStrategyState: "error"});
    //         }
    //
    //         // this.setState({ newMitigationStrategy: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     strategy: event.target.value}),
    //         });
    //     }
    //     else if(id.toString() === "mitigationCostEditInput")
    //     {
    //         if(event.target.value === ""){
    //             this.setState({mitigationCostState: ""});
    //         }
    //         else if(verifyNumber(event.target.value)){
    //             this.setState({mitigationCostState: "success"});
    //         }
    //         else{
    //             this.setState({mitigationCostState: "error"});
    //         }
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     costEstimation: event.target.value}),
    //         });
    //     }
    //     else if(id.toString() === "mitigatedCostImpactEditInput")
    //     {
    //         // this.setState({ mitigatedCostImpact: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { increaseTotalPrices: event.target.value}),
    //                 })
    //         });
    //     }
    //     else if(id.toString() === "mitigatedCompletionDelayEditInput")
    //     {
    //         // this.setState({ mitigatedCompletionDelay: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { delayCompletion: event.target.value}),
    //                 })
    //         });
    //     }
    //     else if(id.toString() === "mitigatedImpairPerformanceEditInput")
    //     {
    //         // this.setState({ mitigatedImpairPerformance: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { impairPerformance: event.target.value}),
    //                 })
    //         });
    //     }
    //     else if(id.toString() === "mitigatedDelayKeyDatesEditInput")
    //     {
    //         // this.setState({ mitigatedDelayKeyDates: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { delayMeetingKeyDate: event.target.value}),
    //                 })
    //         });
    //     }
    //     else if(id.toString() === "mitigatedIncreaseContractorCostEditInput"){
    //
    //         // this.setState({ mitigatedIncreaseContractorCost: event.target.value});
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { increaseContractorTotalCost: event.target.value}),
    //                 })
    //         });
    //     }
    //
    // };

    //function that handles submit button from all edit dialogs
    // handleSubmitEditRiskDetails = (dialog) => {
    //     // if(dialog === "main") {
    //     //
    //     //     if(this.state.riskTitleUpdatedState === "error"){
    //     //        alert("Risk Title cannot be empty");
    //     //     }
    //     //     else
    //     //     {
    //     //         this.setState({ openMainRiskEditDialog: false, riskTitle: this.state.riskTitleUpdated, reductionMeetingDate: this.state.reductionMeetingDateUpdated, riskTitleUpdatedState: ""});
    //     //     }
    //     //
    //     // }
    //     // else if(dialog === "summary") {
    //     //     this.setState(
    //     //         { openSummaryRiskEditDialog: false, /*riskNumber: this.state.riskNumberUpdated, */ status: this.state.statusUpdated, severityImpact: this.state.severityImpactUpdated, severityProbability: this.state.severityProbabilityUpdated, riskDetails: this.state.riskDetailsUpdated, affectedActivities: this.state.affectedActivitiesUpdated
    //     //         });
    //     // }
    //     // else if(dialog === "impact") {
    //     //
    //     //     if(this.state.completionDelayImpactValueEditState === "error" || this.state.costImpactValueEditState === "error"){
    //     //         alert("Please ensure all values are the correct types");
    //     //     }
    //     //     else{
    //     //         this.setState(
    //     //             { openImpactRiskEditDialog: false, costImpact: this.state.costImpactUpdated, costImpactValue: this.state.costImpactValueUpdated, completionDelay: this.state.completionDelayUpdated, delayImpactValue: this.state.delayImpactValueUpdated, impairPerformance: this.state.impairPerformanceUpdated, delayKeyDates: this.state.delayKeyDatesUpdated, increaseContractorCost: this.state.increaseContractorCostUpdated,
    //     //                 costImpactValueEditState: "",  completionDelayImpactValueEditState: ""   });
    //     //     }
    //     //
    //     // }
    //     // else
    //         if(dialog === "strategy"){
    //
    //
    //
    //         let flag = true;
    //
    //         if (this.state.newMitigationStrategyState === "") {
    //             this.setState({ newMitigationStrategyState: "error" });
    //             flag = false;
    //         }
    //
    //         if(this.state.newMitigationStrategyState === "error"){
    //             flag = false;
    //         }
    //
    //         if(this.state.mitigationCostState === "error"){
    //             flag = false;
    //         }
    //
    //         if(flag){
    //
    //             this.setState({
    //                 loadingAddMitigationStrategy: true
    //             });
    //
    //             this.addNewMitigationStrategyAPI();
    //
    //         }
    //         else{
    //             alert("Error in one of the input fields");
    //         }
    //
    //
    //
    //     }
    //
    // };

    //create_mitigation_strategy api
    // addNewMitigationStrategyAPI = () => {
    //
    //     //alert(JSON.stringify(this.state.newMitigationData));
    //
    //     let data = JSON.stringify(this.state.newMitigationData);
    //
    //     const headers = new Headers({
    //         "Content-Type": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + '/api/create_mitigation_strategy', {
    //             credentials: "include",
    //             method: "POST",
    //             headers,
    //             body: data,
    //         }).then(res => {
    //
    //             this.setState({
    //                 loadingAddMitigationStrategy: false
    //             });
    //
    //             if(res.status === 200){
    //
    //                 // alert("Mitigation Strategy successfully added");
    //
    //                 this.getMitigationStrategiesAPI();
    //
    //                 this.setState(
    //                     {
    //                         newMitigationStrategyState: "",
    //                         mitigationCostState: "",
    //                         openStrategyRiskEditDialog: false,
    //                         newMitigationData: {
    //                             risk: this.props.location.state.id,
    //                             strategy: "",
    //                             costEstimation: 0,
    //                             mitigatedPotentialImpact: {
    //                                 increaseTotalPrices: "No",
    //                                 delayCompletion: "No",
    //                                 delayMeetingKeyDate: "No",
    //                                 impairPerformance: "No",
    //                                 increaseContractorTotalCost: "No",
    //                             },
    //                         },
    //                     });
    //             }
    //             else{
    //                 alert("Something went wrong - HTTP: " + res.status);
    //             }
    //
    //         }).catch(error => alert(error));
    //     // }, 2000)
    //
    // };

    //risk_mitigation_strategies api
    // getMitigationStrategiesAPI = () => {
    //
    //     this.setState({
    //         loadingMitigationStrategies: true
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //     fetch(getApiRoot() + "/api/risk_mitigation_strategies/" + this.props.location.state.id, {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => {
    //
    //             this.setState({
    //                 loadingMitigationStrategies: false
    //             });
    //
    //             if(res.status === 200){
    //                 return res.json();
    //             }
    //         })
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     // isLoaded: true,
    //                     risk: Object.assign(
    //                         {},
    //                         this.state.risk,
    //                         { mitigationStrategies : result}
    //                     ),
    //                 });
    //             },
    //             (error) => {
    //                alert(error);
    //             }
    //         )
    //     // },2000)
    //
    // };

    //function that handles the change of risk attachments
    changeRiskAttachment = (event) => {
        // alert(event.target.files[0]);

        if(event.target.files.length === 0){
            this.setState({
                currentRiskAttachmentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentRiskAttachmentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }

    };

    //function that handles the submit button of a risk attachment
    handleSubmitAddRiskAttachment = () => {
        // alert("Uploading: " + this.state.currentRiskAttachmentFileName +  " for " + this.state.currentAttachmentType);

        // event.preventDefault();

        if(this.state.currentRiskAttachmentFileName !== "No file chosen") {

            this.setState({loadingAddAttachment: true});

            // if (this.state.currentAttachmentType === "riskMainAttachment") {
            //
            //     data = JSON.stringify({
            //         // id: this.props.location.state.id,
            //         // type: "risk",
            //         // comment: this.state.currentRiskAttachmentFileName
            //         file: this.state.currentFile
            //     });
            // } else if (this.state.currentAttachmentType === "viewStrategyAttachment") {
            //
            //
            // }
            // else if (this.state.currentAttachmentType === "addStrategyAttachment") {
            //
            //     data = JSON.stringify({
            //         // id: this.props.location.state.id,
            //         type: "newMitigation",
            //         attachment: this.state.currentRiskAttachmentFileName
            //     });
            // }

            let data = new FormData();
            data.append('file', this.state.currentFile);

            if (this.state.currentAttachmentType === "riskMainAttachment") {
                data.append('id', this.props.location.state.id);
                data.append('type', "risk");
            }
            // else if(this.state.currentAttachmentType === "viewStrategyAttachment"){
            //     data.append('id', this.state.currentMitigationStrategyId);
            //     data.append('type', "mitigationStrategy");
            // }


            // setTimeout(() => {
                fetch(getApiRoot() + '/api/upload_files', {
                    credentials: "include",
                    method: "POST",
                    body: data,
                }).then(res => {


                    this.setState({loadingAddAttachment: false});

                    if (res.status === 200) {
                        // alert("Attachment successfully uploaded");0
                        this.setState({currentRiskAttachmentFileName: "No file chosen", currentFile: {}, openUploadRiskAttachmentDialog: false});

                        this.getInitialRiskData();

                    } else {
                        alert("Something went wrong - HTTP: " + res.status);
                    }

                }).catch(error => alert(error));
            // }, 2000);
        }
        else{
            alert("Error, no file chosen");
        }


    };

    downloadFileAPI = (uuid, fileName, type) => {

        fetch(getApiRoot() + '/api/download_file/' + uuid, {
            credentials: "include",
            method: "GET",
        }).then(res => res.blob())
            .then(result => {

                fileDownload(result, fileName);

                if(type === "risk"){
                    this.setState({
                        loadingDownloadRiskFile: false
                    });
                }
                // else if(type === "strategy"){
                //     this.setState({
                //         loadingDownloadMitigationFile: false
                //     });
                // }

                //{

                // if (res.status === 200) {
                //
                //     return res;
                //
                // } else {
                //     alert("Something went wrong - HTTP: " + res.status);
                // }

            }).catch(error => alert(error));


    };

    //function that routes to risk register page
    routeChange = () => {
        let path = "/app/riskRegister";
        this.props.history.push({
            pathname: path
        });
    };

    //function that handles any comment change
    onCommentChange = (event, type) => {

        if(type === "risk"){
            this.setState( { newRiskCommentValue: event.target.value}
            );
        }
        else if(type === "riskMeeting"){
            this.setState( { newRiskMeetingCommentValue: event.target.value}
            );
        }
        // else if(type === "mitigation"){
        //     this.setState( { newMitigationCommentValue: event.target.value}
        //     );
        // }

    };

    //function that handles adding a comment
    addComment = (type) => {
        // alert("Add Comment: " + this.state.newCommentValue);

        let data = "";

        let commentEmpty = false;

        if(type === "risk"){

            if(this.state.newRiskCommentValue === ""){
                commentEmpty = true;
            }
            else{
                this.setState({
                    loadingAddRiskComment: true
                });
            }

             data = JSON.stringify({
                risk: this.props.location.state.id,
                comment: this.state.newRiskCommentValue
            });
        }
        // else if(type === "mitigation"){
        //
        //     this.setState({ loadingAddMitigationComment : true });
        //
        //     if(this.state.newMitigationCommentValue === ""){
        //         commentEmpty = true;
        //     }
        //
        //      data = JSON.stringify({
        //         // id: this.props.location.state.id,
        //          mitigationStrategy: this.state.currentMitigationStrategyId,
        //         comment: this.state.newMitigationCommentValue
        //     });
        // }

        if(!commentEmpty){

            const headers = new Headers({
                "Content-Type": "application/json",
            });

            // setTimeout(() => {
            fetch(getApiRoot() + '/api/create_comment', {
                credentials: "include",
                method: "POST",
                headers,
                body: data,
            }).then(res => {

                if(type === "risk"){
                    this.setState({ loadingAddRiskComment : false,  });
                }
                // else if(type === "mitigation"){
                //     this.setState({ loadingAddMitigationComment : false });
                // }

                if(res.status === 200){
                    // alert("Comment successfully added");


                    if(type === "risk"){
                        this.setState({ newRiskCommentValue : "" });

                        this.getInitialRiskData();
                    }
                    // else if(type === "mitigation"){
                    //     this.setState({ newMitigationCommentValue : "",  openMitigationStrategyDetailsDialog: false});
                    //
                    //     // let index = this.state.risk.mitigationStrategies.findIndex(o => o.id === this.state.currentMitigationStrategyId);
                    //
                    //     this.getMitigationStrategiesAPI();
                    //
                    //     // this.handleViewStrategyClick(index);
                    // }
                }
                else{
                    alert("Something went wrong - HTTP: " + res.status);
                }

            }).catch(error => alert(error));
            // }, 2000)

        }
        else{
            alert("New comment cannot be empty");
        }




    };

    //function that handles retrieving a mitigation strategies comments
    // getMitigationStrategyComments = (id) => {
    //
    //     this.setState({
    //         loadingMitigationComments: false,
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     fetch(getApiRoot() + "/api/comments/mitigation/" + id , {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     loadingMitigationComments: true,
    //                     currentMitigationComments: [],//result,
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     loadingMitigationComments: true,
    //                     error
    //                 });
    //             }
    //         )
    // };

    //function that handles retrieving a mitigation strategies attachments
    // getMitigationStrategyAttachments = (id) => {
    //
    //     this.setState({
    //         loadingMitigationAttachments: false,
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     fetch(getApiRoot() + "/api/attachments/mitigation/" + id , {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     loadingMitigationAttachments: true,
    //                     currentMitigationAttachments: [],//result,
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     loadingMitigationAttachments: true,
    //                     error
    //                 });
    //             }
    //         )
    //
    // };

    //function that handles the view specific mitigation strategy
    // handleViewStrategyClick = (index) => {
    //
    //     this.state.risk.mitigationStrategies.forEach((item, i) => {
    //
    //         if(i === index){
    //
    //             this.setState({
    //                 currentMitigationStrategyId: item.id,
    //                 currentMitigationStrategy: item.strategy,
    //                 currentMitigationCost: item.costEstimation,
    //                 currentMitigatedCostImpact: item.mitigatedPotentialImpact.increaseTotalPrices,
    //                 currentMitigatedCompletionDelay: item.mitigatedPotentialImpact.delayCompletion,
    //                 currentMitigatedImpairPerformance: item.mitigatedPotentialImpact.impairPerformance,
    //                 currentMitigatedDelayKeyDates: item.mitigatedPotentialImpact.delayMeetingKeyDate,
    //                 currentMitigatedIncreaseContractorsCost:  item.mitigatedPotentialImpact.increaseContractorTotalCost,
    //                 currentMitigationComments: item.comments,
    //                 currentMitigationAttachments: item.attachments,
    //             })
    //         }
    //
    //     });
    //
    //     this.setState({ openMitigationStrategyDetailsDialog: true });
    // };

    //function that handles the submit edit status
    handleSubmitEditRiskStatus = () => {
        // if(this.state.statusUpdated === this.state.risk.status){
        //     alert("Status has not been changed");
        // }
        // else{
            // alert("New status: " + this.state.statusUpdated);

        // this.setState({
        //     loadingEditStatus: true
        // });

        if(this.state.newRiskMeetingCommentValue === ""){
            alert("Risk reduction meeting comments cannot be empty");
        }
        else{

            this.setState({
                loadingEditStatus: true
            });

            this.changeStatusAPI();
        }


        // }
    };

    //function that calls post request to change risk status
    changeStatusAPI = () => {

        let data = {
            id : this.state.risk.id,
            // status: this.state.statusUpdated
            status : "Closed",
            closingComments: this.state.newRiskMeetingCommentValue
        };

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + '/api/update_risk_status', {
                credentials: "include",
                method: "POST",
                headers,
                body: JSON.stringify(data),
            }).then(res => {


                if (res.status === 200) {
                    //alert("Status Changed");

                    //this.getInitialRiskData();
                    //this.getStatus();

                    // this.setState({
                    //     openSummaryRiskEditDialog: false
                    // });

                    this.setState({
                        risk: Object.assign(
                            {},
                            this.state.risk,
                            {
                                status: "Closed",
                                closingComments: this.state.newRiskMeetingCommentValue

                            }),
                        // openSummaryRiskEditDialog: false
                    });

                } else {
                    alert("Something went wrong - HTTP: " + res.status);
                }

            }).catch(error => alert(error));
        // }, 2000)
    };

    // getStatus = () => {
    //
    //     const headers = new Headers({
    //         "Content-Type": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + '/api/risk_status/' + this.props.location.state.id, {
    //             credentials: "include",
    //             method: "GET",
    //             headers,
    //         }).then(res => {
    //
    //             this.setState({
    //                 loadingEditStatus: false
    //             });
    //
    //             if (res.status === 200)
    //                 return res.text();
    //             else
    //                 alert("Something went wrong - HTTP: " + res.status);
    //         }).then(
    //             (result) => {
    //                 this.setState({
    //                     risk: Object.assign(
    //                         {},
    //                         this.state.risk,
    //                         {
    //                             status: result
    //                         }),
    //                     openSummaryRiskEditDialog: false
    //                 });
    //             },
    //             (error) => {
    //                 alert(error);
    //             });
    //     // }, 2000)
    //
    // };

    //add email to others to be invited list, resend ew
    handleAddOthersToBeInvited = () => {

        if(!verifyLength(this.state.newOtherEmail, 1) || !verifyLength(this.state.newOtherName, 1) || !verifyLength(this.state.newOtherOrganisation, 1)){
            alert("One or more empty fields");
        }
        else if(!verifyEmail(this.state.newOtherEmail)){
            alert("Invalid Email: " + this.state.newOtherEmail);
        }
        else{

            let arr = this.state.othersToBeInvited;

            let obj ={
                name: this.state.newOtherName,
                organisation: this.state.newOtherOrganisation,
                email: this.state.newOtherEmail,
            };

            arr.push(obj);

            this.setState(
                {
                    newOtherEmail: "",
                    newOtherName: "",
                    newOtherOrganisation: "",
                    othersToBeInvited: arr
                }
            );

        }

    };

    //remove email to others to be invited list, resend ew
    handleRemoveOthersToBeInvited = (index) => {

        let arr = this.state.othersToBeInvited;
        arr.splice(index, 1);

        this.setState(
            {
                othersToBeInvited: arr
            }
        );

    };

    //resend early warning api
    handleSendEw = () => {

        this.setState({
            loadingSendEW: true
        });

        let data = {
            risk: this.props.location.state.id,
            othersToBeInvited: this.state.othersToBeInvited
        };

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + '/api/send_early_warning', {
                credentials: "include",
                method: "POST",
                headers,
                body: JSON.stringify(data),
            }).then(res => {

                this.setState({
                    loadingSendEW: false,
                });

                if (res.status === 200) {
                    // alert("Early Warning sent");

                    this.setState({
                        resendEWDialogOpen: false, othersToBeInvited: [], newOtherEmail: "",
                    });

                } else {
                    alert("Something went wrong - HTTP: " + res.status);
                }

            }).catch(error => alert(error));
        // }, 2000);

    };

    changeRiskReductionDate = () => {



        if(this.state.newReductionMeetingDate !== "" && this.state.newReductionMeetingTime !== ""){

            let data = {
                id : this.state.risk.id,
                riskReductionMeetingDate: this.state.newReductionMeetingDate,
                riskReductionMeetingTime:  this.state.newReductionMeetingTime
            };

            const headers = new Headers({
                "Content-Type": "application/json",
            });

            // setTimeout(() => {
            fetch(getApiRoot() + '/api/update_risk', {
                credentials: "include",
                method: "POST",
                headers,
                body: JSON.stringify(data),
            }).then(res => {

                this.setState({
                    openChangeRiskReductionMeeting: false
                });

                if (res.status === 200) {

                    this.setState({
                        risk: Object.assign(
                            {},
                            this.state.risk,
                            {
                                riskReductionMeetingDate: this.state.newReductionMeetingDate,
                                riskReductionMeetingTime:  this.state.newReductionMeetingTime
                            })
                    });

                } else {
                    alert("Something went wrong - HTTP: " + res.status);
                }

            }).catch(error => alert(error));
        }
        else{
            alert("The date and time is required");
        }



    };

    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setState({
                        isLoaded: true,
                        // permissions: result
                    });
                }

                this.setPermissions(result);

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
        // },2000)

    };

    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let createContract = permissions.find(o => o.type === "risk" && o.subtype === "response").permission;

            if(createContract === "true"){
                this.setState({
                    showModifyButtons: true,
                });
            }
            else{
                this.setState({
                    showModifyButtons: false,
                });
            }

        }
        else{
            this.setState({
                showModifyButtons: false,
            });
        }

    };


    //risk/{id} api, loads initial risk data
    getInitialRiskData = () => {

        // this.setState({
        //     isLoaded: false,
        // });

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + "/api/risk/" + this.props.location.state.id, {
                credentials: "include",
                method: "GET",
                headers,
            })
                .then(res => {
                    if(res.status === 200){
                        return res.json();
                    }
                    else{
                        alert("Something went wrong - HTTP " + res.status);
                    }
                })
                .then(
                    (result) => {

                        if(result !== undefined){
                            this.setState({
                                // isLoaded: true,
                                risk: result,
                                statusUpdated: result.status,
                                // newMitigationData: Object.assign(
                                //     {},
                                //     this.state.newMitigationData,
                                //     { risk : this.props.location.state.id}
                                // ),

                            });

                            this.getPermissions();
                        }

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        // },2000)
    };

    // getComments = () => {
    //
    //     this.setState({
    //         isLoaded: false,
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     fetch(getApiRoot() + "/api/comments/risk/" + this.props.location.state.id , {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     comments: [],//result,
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     error
    //                 });
    //             }
    //         )
    // };

    // getMitigationStrategies = () => {
    //
    //     this.setState({
    //         isLoaded: false,
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     fetch(getApiRoot() + "/api/strategies/risk/" + this.props.location.state.id , {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     mitigationItems: [],//result,
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     error
    //                 });
    //             }
    //         )
    //
    // };

    // getAttachments = () => {
    //
    //     this.setState({
    //         isLoaded: false,
    //     });
    //
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     fetch(getApiRoot() + "/api/attachments/risk/" + this.props.location.state.id , {
    //         credentials: "include",
    //         method: "GET",
    //         headers,
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     attachments: [],//result,
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     error
    //                 });
    //             }
    //         )
    //
    // };

    //function that retrieves initial data to populate the page before rendering
    componentDidMount() {
        this.getInitialRiskData();
        // this.getComments();
        // this.getMitigationStrategies();
        // this.getAttachments();
    }

    render() {
        const { classes } = this.props;
        const { error,
            isLoaded,
            loadingEditStatus,
            loadingSendEW,
            // loadingAddMitigationComment,
            loadingAddRiskComment,
            // loadingMitigationComments,
            // loadingMitigationAttachments,
            loadingAddAttachment,
            // loadingAddMitigationStrategy,
            // loadingMitigationStrategies,
            // loadingDownloadMitigationFile,
            loadingDownloadRiskFile,
            loadingChangeReductionDate
        } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Loading wheel for initial api
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignFirstCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/*<GridItem xs={12} lg={12} style={{marginBottom: -15}}>*/}
                                    {/*    <Tooltip*/}
                                    {/*        title={"Download Current Parts"}*/}
                                    {/*        position={"bottom"}>*/}
                                    {/*        <Button simple color={"primary"} size={"sm"} style={{float: "right", padding: 0}}>Download Documents<GetApp color={"primary"} style={{cursor: "pointer", fontSize: 16, marginLeft: 5, marginRight: -5}} /></Button>*/}
                                    {/*    </Tooltip>*/}
                                    {/*</GridItem>*/}

                                    {/* Risk Description */}
                                    <GridItem xs={12} lg={12}>
                                        <h4 style={{marginBottom: 20}}><strong>{this.state.risk.description}</strong></h4>
                                    </GridItem>


                                    {/* Edit Risk Main Details */}
                                    {/*<GridItem xs={6} lg={4}>*/}
                                    {/*    {this.state.risk.type.toLowerCase() !== "cd" && this.state.showModifyButtons ? <Tooltip*/}
                                    {/*        title={"Resend Early Warning"}*/}
                                    {/*        placement="top"*/}
                                    {/*        classes={{ tooltip: classes.tooltip }}>*/}
                                    {/*        <Replay style={{cursor: "pointer", fontSize: 16, float: "right"}} onClick={() => this.setState({*/}
                                    {/*            resendEWDialogOpen: true*/}
                                    {/*        })}/>*/}
                                    {/*        </Tooltip> : ""}*/}
                                    {/*</GridItem>*/}

                                    {/* Contract Number */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14}}><strong>Contract:</strong> {this.state.risk.contract.number}</p>
                                    </GridItem>

                                    {/* Project */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14, float: "right"}}>
                                            <strong>Project:</strong> {this.state.risk.contract.project.title}</p>
                                    </GridItem>

                                    {/* Submitted Date */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12}}>Submitted: {this.state.risk.dateCreated !== null ? new Date(this.state.risk.dateCreated).getDate() + " " + this.state.months[new Date(this.state.risk.dateCreated).getMonth()] + " " + new Date(this.state.risk.dateCreated).getFullYear() : ""}</p>
                                    </GridItem>

                                    {/* Risk Reduction Date */}
                                    <GridItem xs={6} lg={6}>
                                        {this.state.risk.riskReductionMeetingRequired === "" || this.state.risk.riskReductionMeetingRequired === null || this.state.risk.riskReductionMeetingRequired.toLowerCase() === "no" ? "" : <EditOutlined style={{cursor: "pointer", fontSize: 14, margin: "0 0 0 5px", float: "right"}} onClick={() => this.handleClickOpen("reductionMeetingDate")}/>}
                                        <p style={{fontSize: 12, float: "right", display: "inline"}}>Risk Reduction
                                            Meeting: {this.state.risk.riskReductionMeetingRequired === "" || this.state.risk.riskReductionMeetingRequired === null || this.state.risk.riskReductionMeetingRequired.toLowerCase() === "no" ? "None" : new Date(this.state.risk.riskReductionMeetingDate).getDate() + " " + this.state.months[new Date(this.state.risk.riskReductionMeetingDate).getMonth()] + " " + new Date(this.state.risk.riskReductionMeetingDate).getFullYear()  + " at " + this.state.risk.riskReductionMeetingTime}</p>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>

                    {/*<GridItem xs={12} sm={4} lg={4}>*/}
                    {/*    <Card style={{marginBottom: 0}}>*/}
                    {/*        <CardBody style={{padding: 15}}>*/}
                    {/*            <GridContainer>*/}
                    {/*                <GridItem xs={12} sm={12} lg={12} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>*/}
                    {/*                    <Button color={"primary"} style={{padding: "10px 15px"}}>Current Parts <GetApp style={{cursor: "pointer", fontSize: 16, marginLeft: 5}} onClick={() => this.handleClickOpen("summary")}/></Button>*/}
                    {/*                </GridItem>*/}
                    {/*            </GridContainer>*/}
                    {/*        </CardBody>*/}
                    {/*    </Card>*/}
                    {/*</GridItem>*/}


                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Risk Summary Heading */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <p style={{fontSize: 16, marginBottom: 30}}><strong>Risk Summary</strong></p>
                                    </GridItem>


                                    {/* Risk Summary Edit Button */}
                                    {/*<GridItem xs={6} sm={6} lg={6}>*/}
                                    {/*    <EditOutlined style={{cursor: "pointer", fontSize: 16, float: "right"}} onClick={() => this.handleClickOpen("summary")}/>*/}
                                    {/*</GridItem>*/}

                                    {/* Risk Number */}
                                    <GridItem xs={6} sm={6} lg={3}>
                                        <p><strong>Risk #:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={9}>
                                        <p>{this.state.risk.riskNumber}</p>
                                    </GridItem>

                                    {/* Status */}
                                    <GridItem xs={6} sm={6} lg={3}>
                                        <p><strong>Status:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={9}>
                                        <p style={{display: "inline"}}>{this.state.risk.status}</p>
                                        {/*{this.state.risk.status.toLowerCase() === "open" && this.state.showModifyButtons ? <EditOutlined style={{cursor: "pointer", fontSize: 12, margin: "0 0 0 5px"}} onClick={() => this.handleClickOpen("summary")} /> : ""}*/}
                                    </GridItem>

                                    {/*{this.state.risk.status.toLowerCase() === "closed" ?*/}
                                    {/*    <div>*/}
                                            <GridItem xs={6} sm={6} lg={3}>
                                                <p><strong>Risk reduction meeting conclusion comments:</strong></p>
                                            </GridItem>
                                            <GridItem xs={6} sm={6} lg={9}>
                                                <p>{this.state.risk.closingComments !== "" && this.state.risk.closingComments !== null ? this.state.risk.closingComments : "N/A"}</p>
                                                {/*{this.state.risk.status.toLowerCase() === "open" && this.state.showModifyButtons ? <EditOutlined style={{cursor: "pointer", fontSize: 12, margin: "0 0 0 5px"}} onClick={() => this.handleClickOpen("summary")} /> : ""}*/}
                                            </GridItem>
                                        {/*</div>*/}
                                        {/*    : ""}*/}

                                    {/* Risk Severity */}
                                    <GridItem xs={6} sm={6} lg={3}>
                                        <p><strong>Severity: </strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={9}>
                                        {this.state.risk.severity !== "" && this.state.risk.severity !== null ? <Badge color={this.state.risk.severity.toLowerCase() === "very high" ? "danger"
                                            : this.state.risk.severity.toLowerCase() === "high" ? "danger"
                                                : this.state.risk.severity.toLowerCase() === "medium" ? "warning"
                                                    : "success"}
                                        >
                                            {this.state.risk.severity}
                                        </Badge> : ""}
                                    </GridItem>

                                    {/* Risk Severity Impact*/}
                                    <GridItem xs={6} sm={6} lg={3}>
                                        <p style={{fontSize: 12, marginLeft: 15}}> Impact:</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={9}>
                                        <p style={{fontSize: 12}}>{this.state.risk.severityImpact.charAt(0).toUpperCase() + this.state.risk.severityImpact.slice(1)}</p>
                                    </GridItem>

                                    {/* Risk Severity Probability*/}
                                    <GridItem xs={6} sm={6} lg={3}>
                                        <p style={{fontSize: 12, marginLeft: 15}}>Probability:</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={9}>
                                        <p style={{fontSize: 12}}>{this.state.risk.severityProbability.charAt(0).toUpperCase() + this.state.risk.severityProbability.slice(1)}</p>
                                    </GridItem>

                                    {/* Risk Details */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Details:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={9}>
                                        <p>{this.state.risk.details !== "" ? this.state.risk.details : "None"}</p>
                                    </GridItem>

                                    {/* Risk Affected Activities */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Affected Activities:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={5}>
                                        <p>{this.state.risk.affectedActivities !== "" ? this.state.risk.affectedActivities: "None"}</p>
                                    </GridItem>



                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                     {/*Risk Potential Impact */}
                                    <GridItem xs={12} lg={12}>
                                        <p style={{fontSize: 16, marginBottom: 30}}><strong>Potential impact</strong>
                                        </p>
                                    </GridItem>

                                     {/*Risk Impact Edit Button */}
                                    {/*<GridItem xs={6} sm={6} lg={6}>*/}
                                    {/*    <EditOutlined style={{cursor: "pointer", fontSize: 16, float: "right"}}*/}
                                    {/*                  onClick={() => this.handleClickOpen("impact")}/>*/}
                                    {/*</GridItem>*/}

                                     {/*Risk Potential Impact - Increase the total of the Prices */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Increase the total of the Prices:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p>{this.state.risk.increasePrices}</p>
                                    </GridItem>

                                     {/*Risk Potential Impact - Increase the total of the Prices (Value) */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p style={{fontSize: 12}}>Broad Estimate Value:</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p style={{fontSize: 12}}>{this.state.risk.increasePricesValue}</p>
                                    </GridItem>

                                     {/*Break */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <hr/>
                                    </GridItem>

                                     {/*Risk Potential Impact - Delay Completion */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Delay Completion:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p>{this.state.risk.impactDelayCompletion}</p>
                                    </GridItem>

                                     {/*Risk Potential Impact - Delay Completion (Value) */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p style={{fontSize: 12}}>Broad Estimate Value:</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p style={{fontSize: 12}}>{this.state.risk.impactDelayCompletionValue}</p>
                                    </GridItem>

                                     {/*Break */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <hr/>
                                    </GridItem>

                                     {/*Risk Potential Impact - Delay meeting a Key Date */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Delay meeting a Key Date:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={9}>
                                        <p>{this.state.risk.impactDelayMeeting}</p>
                                    </GridItem>

                                     {/*Break */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <hr/>
                                    </GridItem>

                                     {/*Risk Potential Impact - Impair performance of the works in use */}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Impair performance of the <span
                                            style={{fontStyle: "italic"}}>works</span> in use:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={9}>
                                        <p>{this.state.risk.impactImpairPerformance}</p>
                                    </GridItem>

                                     {/*Break */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <hr/>
                                    </GridItem>

                                     {/*Risk Potential Impact - Contractor's total cost*/}
                                    <GridItem xs={12} sm={12} lg={3}>
                                        <p><strong>Increase the <span
                                            style={{fontStyle: "italic"}}>Contractor's</span> total cost:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={9}>
                                        <p>{this.state.risk.increaseContractorsTotalCost}</p>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Mitigation strategies */}
                                    <GridItem xs={12} lg={12}>
                                        <p style={{fontSize: 16}}><strong>Mitigation strategies</strong></p>
                                    </GridItem>

                                    <GridItem xs={12} lg={12}>
                                        <p style={{fontSize: 14}}>{this.state.risk.mitigationStrategies !== ""  && this.state.risk.mitigationStrategies !== null ? this.state.risk.mitigationStrategies : "None"}</p>
                                    </GridItem>

                                    {/* Add Mitigation strategy button */}
                                    {/*<GridItem xs={6} sm={6} lg={6}>*/}
                                    {/*    <Add onClick={() => this.handleClickOpen("strategy")}*/}
                                    {/*         style={{cursor: "pointer", fontSize: 16, float: "right"}}/>*/}
                                    {/*</GridItem>*/}

                                    {/* Current Mitigation strategies list */}
                                    {/*<GridItem xs={12} lg={12}>*/}
                                    {/*    <List>*/}
                                    {/*        {*/}
                                    {/*            this.state.risk.mitigationStrategies.length ? this.state.risk.mitigationStrategies.sort(function(a,b){*/}
                                    {/*                return parseInt(a.id) - parseInt(b.id);*/}
                                    {/*            }).map((item, index) =>*/}
                                    {/*                <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}*/}
                                    {/*                          key={index}>*/}
                                    {/*                    {item.strategy}*/}
                                    {/*                    <ListItemSecondaryAction>*/}
                                    {/*                        <RemoveRedEye*/}
                                    {/*                            style={{cursor: "pointer", fontSize: 16, marginTop: 5}}*/}
                                    {/*                            onClick={() => this.handleViewStrategyClick(index)}/>*/}
                                    {/*                    </ListItemSecondaryAction>*/}
                                    {/*                </ListItem>)*/}
                                    {/*                : "No Mitigation Strategies"*/}
                                    {/*        }*/}
                                    {/*    </List>*/}
                                    {/*    {loadingMitigationStrategies && <CircularProgress size={24} color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)", marginTop: -12,  marginLeft: -12}}/>}*/}
                                    {/*</GridItem>*/}
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Attachments */}
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 16}}><strong>Attachments</strong></p>
                                    </GridItem>

                                    {/* Add Attachment button */}
                                    {/*<GridItem xs={6} sm={6} lg={6}>*/}
                                    {/*    <Add style={{cursor: "pointer", fontSize: 16, float: "right"}}*/}
                                    {/*         onClick={() => this.handleClickOpen("uploadAttachment", "riskMainAttachment")}/>*/}
                                    {/*</GridItem>*/}

                                    {/* Current Attachments Table */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{position: "relative"}}>
                                            <ReactTable

                                                data={this.state.risk.attachments}
                                                filterable={false}
                                                sortable={false}
                                                resizable={false}
                                                noDataText="No attachments"
                                                columns={[
                                                    {
                                                        Header: "File",
                                                        accessor: "fileName",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                    },
                                                    {
                                                        Header: "Uploaded By",
                                                        accessor: "user.userDetails",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value.name + " " + row.value.surname}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Uploaded",
                                                        accessor: "attachmentCreated",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "id",
                                                        headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                        style: {"textAlign": "center"},
                                                        Cell: row => (
                                                            <div>
                                                                <Button simple disabled={loadingDownloadRiskFile} onClick={() => {

                                                                    this.setState({
                                                                        loadingDownloadRiskFile: true
                                                                    });

                                                                    let id = this.state.risk.attachments.findIndex(o => o.id === row.value);
                                                                    let uuid = this.state.risk.attachments[id].uuid;
                                                                    let fileName = this.state.risk.attachments[id].fileName;

                                                                    this.downloadFileAPI(uuid, fileName, "risk");

                                                                }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                            </div>

                                                        ),
                                                    },
                                                ]}
                                                defaultSorted={[
                                                    {
                                                        id: "id",
                                                        desc: true
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationBottom={true}
                                                className="-striped -highlight"
                                                showPageJump={false}
                                            />
                                            { loadingDownloadRiskFile && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>

                                    {/* Comments */}
                                    <GridItem xs={12} lg={12}>
                                        <p style={{fontSize: 16}}><strong>Comments</strong></p>
                                    </GridItem>

                                    {/* Current Comments */}
                                    <GridItem xs={12} lg={12}>
                                        <CommentsList items={this.state.risk.comments}/>
                                    </GridItem>

                                    {/* New Comment Input */}
                                    <GridItem xs={12} sm={12} lg={12} style={{marginTop: 15}}>
                                        <CustomInput
                                            labelText="New Comment"
                                            id="newRiskComment"

                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            inputProps={{
                                                multiline: true,
                                                value: this.state.newRiskCommentValue,
                                                onChange: (event) => this.onCommentChange(event, "risk")
                                            }}
                                        />
                                    </GridItem>

                                    {/* Add New Comment Button */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <div style={{position: "relative", float:"left"}}>
                                            <Button color="primary" style={{padding: "10px 20px", margin: "15px 0"}}
                                                onClick={() => this.addComment("risk")} disabled={loadingAddRiskComment}>Add</Button>
                                            {loadingAddRiskComment && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {this.state.risk.status.toLowerCase() === "open" && this.state.showModifyButtons ?
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card className={classes.cardAlignment} style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <p style={{fontSize: 16}}><strong>Close Risk</strong></p>
                                    </GridItem>
                                    {/*<GridItem xs={12} sm={12} lg={12}>*/}
                                        {/*<p style={{display: "inline"}}>{this.state.risk.status}</p>*/}
                                        {/*{this.state.risk.status.toLowerCase() === "open" && this.state.showModifyButtons ? <EditOutlined style={{cursor: "pointer", fontSize: 12, margin: "0 0 0 5px"}} onClick={() => this.handleClickOpen("summary")} /> : ""}*/}
                                        {/* Edit Status Input */}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel htmlFor="riskStatusEditInput">*/}
                                    {/*            Status*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <Select*/}
                                    {/*            native*/}
                                    {/*            value={this.state.statusUpdated}*/}
                                    {/*            onChange={this.handleChangeSummaryRiskDetails}*/}
                                    {/*            inputProps={{*/}
                                    {/*                id: 'riskStatusEditInput'*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            /!*<option value={""} disabled>Status</option>*!/*/}
                                    {/*            {this.state.statusList.map((item, index) => (*/}
                                    {/*                <option key={index} value={item}>{item}</option>*/}
                                    {/*            ))}*/}
                                    {/*        </Select>*/}
                                    {/*    </FormControl>*/}
                                    {/*</GridItem>*/}

                                    {/* Risk Reduction Meeting Comments */}
                                    <GridItem xs={12} sm={12} lg={12}>
                                        <FormControl fullWidth>
                                            <CustomInput
                                                labelText="Mitigating action to close risk"
                                                id="newRiskMeetingComment"
                                                // labelProps={{
                                                //     shrink: true
                                                // }}
                                                formControlProps={{
                                                    fullWidth: true,

                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    value: this.state.newRiskMeetingCommentValue,
                                                    onChange: (event) => this.onCommentChange(event, "riskMeeting")
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} lg={12}>
                                        <div style={{position: "relative", float:"left"}}>
                                            <Button type={"submit"} style={{padding: "10px 20px", margin: "15px 0"}}
                                                    onClick={() => this.handleSubmitEditRiskStatus()}
                                                    color="primary" disabled={loadingEditStatus}>
                                                Close Risk
                                            </Button>
                                            {loadingEditStatus && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem> : ""}

                    <GridContainer justify={"center"}>

                        {/* Route back to risk register button */}
                        <GridItem xs={12} sm={12} lg={10}>
                            <Button simple color={"primary"} style={{marginTop: 15}} onClick={this.routeChange}>Back to Risk
                                Register</Button>
                        </GridItem>
                    </GridContainer>

                    {/* ----------------------------------------- Dialogs -----------------------------------------*/}

                    {/* Edit Risk Main Details Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openMainRiskEditDialog}*/}
                    {/*    onClose={() => this.handleCancel("main")}*/}
                    {/*    aria-labelledby="mainRiskEditDialogTitle"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="mainRiskEditDialogTitle">Edit Risk Main Details</DialogTitle>*/}
                    {/*    <DialogContent>*/}
                    
                    {/*        /!* Edit Risk Description Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="riskDescriptionEditInput"*/}
                    {/*            labelText="Risk Title"*/}
                    {/*            success={this.state.riskTitleUpdatedState === "success"}*/}
                    {/*            error={this.state.riskTitleUpdatedState === "error"}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                style: {marginBottom: 15},*/}
                    {/*                value: this.state.riskTitleUpdated,*/}
                    {/*                onChange: this.handleChangeMainRiskDetails,*/}
                    {/*                type: "text",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    
                    {/*        /!* Edit Risk Reduction Meeting Date Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="reductionMeetingDateEditInput"*/}
                    {/*            labelText="Risk Reduction Meeting Date"*/}
                    {/*            labelProps={{*/}
                    {/*                shrink: true*/}
                    {/*            }}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                style: {marginBottom: 15},*/}
                    {/*                value: this.state.reductionMeetingDateUpdated,*/}
                    {/*                onChange: this.handleChangeMainRiskDetails,*/}
                    {/*                type: "date",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => this.handleCancel("main")} color="info">*/}
                    {/*            Cancel*/}
                    {/*        </Button>*/}
                    {/*        <Button onClick={() => this.handleSubmitEditRiskDetails("main")} color="primary">*/}
                    {/*            Update*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/* Edit Risk Summary Details Dialog */}
                    <Dialog
                        open={this.state.openSummaryRiskEditDialog}
                        onClose={() => this.handleCancel("summary")}
                        aria-labelledby="impactRiskEditDialogTitle"
                    >
                        <DialogTitle id="impactRiskEditDialogTitle">Edit Risk Status</DialogTitle>
                        <DialogContent>

                            {/* Edit Status Input */}
                            <FormControl fullWidth>
                                <InputLabel htmlFor="riskStatusEditInput">
                                    Status
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.statusUpdated}
                                    onChange={this.handleChangeSummaryRiskDetails}
                                    inputProps={{
                                        id: 'riskStatusEditInput'
                                    }}
                                >
                                    {this.state.statusList.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Edit Severity Impact Input */}
                            {/*<FormControl fullWidth style={{marginBottom: 15}}>*/}
                            {/*    <InputLabel htmlFor="severityImpactEditInput">*/}
                            {/*        Severity Impact*/}
                            {/*    </InputLabel>*/}
                            {/*    <Select*/}
                            {/*        native*/}
                            {/*        value={this.state.severityImpactUpdated}*/}
                            {/*        onChange={this.handleChangeSummaryRiskDetails}*/}
                            {/*        inputProps={{*/}
                            {/*            id: 'severityImpactEditInput'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {this.state.severityList.map((item, index) => (*/}
                            {/*            <option key={index} value={item}>{item}</option>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            {/* Edit Severity Probability Input */}
                            {/*<FormControl fullWidth style={{marginBottom: 15}}>*/}
                            {/*    <InputLabel htmlFor="severityProbabilityEditInput">*/}
                            {/*        Severity Probability*/}
                            {/*    </InputLabel>*/}
                            {/*    <Select*/}
                            {/*        native*/}
                            {/*        value={this.state.severityProbabilityUpdated}*/}
                            {/*        onChange={this.handleChangeSummaryRiskDetails}*/}
                            {/*        inputProps={{*/}
                            {/*            id: 'severityProbabilityEditInput'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {this.state.severityList.map((item, index) => (*/}
                            {/*            <option key={index} value={item}>{item}</option>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}

                            {/* Edit Risk Details Input */}
                            {/*<TextField*/}
                            {/*    id="riskDetailsEditInput"*/}
                            {/*    label="Risk Details"*/}
                            {/*    fullWidth*/}
                            {/*    multiline={true}*/}
                            {/*    rows={2}*/}
                            {/*    value={this.state.riskDetailsUpdated}*/}
                            {/*    onChange={this.handleChangeSummaryRiskDetails}*/}
                            {/*    style={{marginBottom: 15}}*/}
                            {/*/>*/}

                            {/* Edit Risk Affected Activities Input */}
                            {/*<TextField*/}
                            {/*    id="affectedActivitiesEditInput"*/}
                            {/*    label="Affected Activities"*/}
                            {/*    fullWidth*/}
                            {/*    multiline={true}*/}
                            {/*    rows={2}*/}
                            {/*    value={this.state.affectedActivitiesUpdated}*/}
                            {/*    onChange={this.handleChangeSummaryRiskDetails}*/}
                            {/*    style={{marginBottom: 15}}*/}
                            {/*/>*/}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCancel("summary")} color="info">
                                Cancel
                            </Button>
                            <div style={{position: "relative"}}>
                                <Button type={"submit"}
                                        onClick={() => this.handleSubmitEditRiskStatus()}
                                        color="primary" disabled={loadingEditStatus}>
                                    Update
                                </Button>
                                {loadingEditStatus && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                    {/* Edit Risk Potential Impact Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openImpactRiskEditDialog}*/}
                    {/*    onClose={() => this.handleCancel("impact")}*/}
                    {/*    aria-labelledby="impactRiskEditDialogTitle"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="impactRiskEditDialogTitle">Edit Risk Potential Impact</DialogTitle>*/}
                    {/*    <DialogContent>*/}
                    
                    {/*        /!* Edit Risk Potential Impact - Increase the total of the Prices *!/*/}
                    {/*        <FormControl fullWidth>*/}
                    {/*            <InputLabel htmlFor="costImpactEditInput">*/}
                    {/*                Increase the total of the Prices*/}
                    {/*            </InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={this.state.costImpactUpdated}*/}
                    {/*                onChange={this.handleChangeImpactRiskDetails}*/}
                    {/*                inputProps={{*/}
                    {/*                    id: 'costImpactEditInput'*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={"Yes"}>Yes</option>*/}
                    {/*                <option value={"No"}>No</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    
                    {/*        /!* Edit Risk Potential Impact - Increase the total of the Prices (Value) *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="costImpactValueEditInput"*/}
                    {/*            labelText="Cost Impact Value"*/}
                    {/*            success={this.state.costImpactValueEditState === "success"}*/}
                    {/*            error={this.state.costImpactValueEditState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                value: this.state.costImpactValueUpdated,*/}
                    {/*                onChange: this.handleChangeImpactRiskDetails,*/}
                    {/*                type: "text",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    
                    {/*        /!* Edit Risk Potential Impact - Delay Completion *!/*/}
                    {/*        <FormControl fullWidth>*/}
                    {/*            <InputLabel htmlFor="completionDelayEditInput">*/}
                    {/*                Delay Completion*/}
                    {/*            </InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={this.state.completionDelayUpdated}*/}
                    {/*                onChange={this.handleChangeImpactRiskDetails}*/}
                    {/*                inputProps={{*/}
                    {/*                    id: 'completionDelayEditInput'*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={"Yes"}>Yes</option>*/}
                    {/*                <option value={"No"}>No</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    
                    {/*        /!* Edit Risk Potential Impact - Delay Completion (Value) *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="completionDelayValueEditInput"*/}
                    {/*            labelText="Value of Delay of completion"*/}
                    {/*            success={this.state.completionDelayImpactValueEditState === "success"}*/}
                    {/*            error={this.state.completionDelayImpactValueEditState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                value: this.state.delayImpactValueUpdated,*/}
                    {/*                onChange: this.handleChangeImpactRiskDetails,*/}
                    {/*                type: "text",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    
                    {/*        /!* Edit Risk Potential Impact - Delay meeting a key date *!/*/}
                    {/*        <FormControl fullWidth style={{marginBottom: 15}}>*/}
                    {/*            <InputLabel htmlFor="delayKeyDatesEditInput">*/}
                    {/*                Delay meeting a key date*/}
                    {/*            </InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={this.state.delayKeyDatesUpdated}*/}
                    {/*                onChange={this.handleChangeImpactRiskDetails}*/}
                    {/*                inputProps={{*/}
                    {/*                    id: 'delayKeyDatesEditInput'*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={"Yes"}>Yes</option>*/}
                    {/*                <option value={"No"}>No</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    
                    {/*        /!* Edit Risk Potential Impact -  Impair performance of works in use *!/*/}
                    {/*        <FormControl fullWidth style={{marginBottom: 15}}>*/}
                    {/*            <InputLabel htmlFor="impairPerformanceEditInput">*/}
                    {/*                Impair performance of <span style={{fontStyle: "italic"}}>works</span> in use*/}
                    {/*            </InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={this.state.impairPerformanceUpdated}*/}
                    {/*                onChange={this.handleChangeImpactRiskDetails}*/}
                    {/*                inputProps={{*/}
                    {/*                    id: 'impairPerformanceEditInput'*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={"Yes"}>Yes</option>*/}
                    {/*                <option value={"No"}>No</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    
                    {/*        /!* Edit Risk Potential Impact -  Increase the Contractor's total cost *!/*/}
                    {/*        <FormControl fullWidth style={{marginBottom: 15}}>*/}
                    {/*            <InputLabel htmlFor="3">*/}
                    {/*                Increase the <span style={{fontStyle: "italic"}}>Contractor's</span> total cost*/}
                    {/*            </InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={this.state.increaseContractorCostUpdated}*/}
                    {/*                onChange={this.handleChangeImpactRiskDetails}*/}
                    {/*                inputProps={{*/}
                    {/*                    id: 'increaseContractorCostEditInput'*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={"Yes"}>Yes</option>*/}
                    {/*                <option value={"No"}>No</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    
                    
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => this.handleCancel("impact")} color="info">*/}
                    {/*            Cancel*/}
                    {/*        </Button>*/}
                    {/*        <Button onClick={() => this.handleSubmitEditRiskDetails("impact")} color="primary">*/}
                    {/*            Update*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/* Add mitigation strategy Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openStrategyRiskEditDialog}*/}
                    {/*    onClose={() => this.handleCancel("strategy")}*/}
                    {/*    aria-labelledby="addStrategyDialogTitle"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="addStrategyDialogTitle">Add mitigation strategy</DialogTitle>*/}
                    {/*    <DialogContent style={{width: 600}}>*/}

                    {/*        /!* Strategy Description Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            autoFocus*/}
                    {/*            id="addStrategyInput"*/}
                    {/*            labelText="Strategy"*/}
                    {/*            success={this.state.newMitigationStrategyState === "success"}*/}
                    {/*            error={this.state.newMitigationStrategyState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*                style: {marginBottom: 0}*/}

                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                multiline: true,*/}
                    {/*                rows: 2,*/}
                    {/*                value: this.state.newMitigationData.strategy,*/}
                    {/*                onChange: this.handleChangeMitigationStrategy,*/}
                    {/*                type: "text",*/}
                    {/*            }}*/}
                    {/*        />*/}

                    {/*        /!* Estimated cost of mitigation Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="mitigationCostEditInput"*/}
                    {/*            labelText="Estimated cost of mitigation (ZAR)"*/}
                    {/*            success={this.state.mitigationCostState === "success"}*/}
                    {/*            error={this.state.mitigationCostState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                value: this.state.newMitigationData.costEstimation,*/}
                    {/*                onChange: this.handleChangeMitigationStrategy,*/}
                    {/*                type: "text"*/}
                    {/*            }}*/}
                    {/*        />*/}

                    {/*        /!* Mitigated & Unmitigated Impact *!/*/}
                    {/*        /!*<Card>*!/*/}
                    {/*        /!*    <CardBody>*!/*/}
                    {/*                <GridContainer>*/}

                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "20px 0 10px 0"}}/>*/}
                    {/*                    </GridItem>*/}

                    {/*                    <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                        <p style={{margin: 0}}><strong>Mitigated Impact</strong></p>*/}
                    {/*                    </GridItem>*/}
                    {/*                    <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                        <p style={{textAlign: "right", margin: 0}}><strong>Unmitigated Potential Impact</strong></p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                    </GridItem>*/}

                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <p style={{fontSize: 12}}><strong>Increase the total of the Prices</strong>*/}
                    {/*                        </p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    /!* Mitigated Impact - Increase the total of the Prices *!/*/}
                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <GridContainer>*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <FormControl fullWidth>*/}
                    {/*                                    <Select*/}
                    {/*                                        native*/}
                    {/*                                        value={this.state.newMitigationData.mitigatedPotentialImpact.increaseTotalPrices}*/}
                    {/*                                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                                        inputProps={{*/}
                    {/*                                            id: 'mitigatedCostImpactEditInput',*/}
                    {/*                                            style: {fontSize: 12}*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        <option value={"No"}>No</option>*/}
                    {/*                                        <option value={"Yes"}>Yes</option>*/}
                    {/*                                    </Select>*/}
                    {/*                                </FormControl>*/}
                    {/*                            </GridItem>*/}

                    {/*                            /!* Unmitigated Impact - Increase the total of the Prices *!/*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <p style={{*/}
                    {/*                                    textAlign: "right",*/}
                    {/*                                    fontSize: 12*/}
                    {/*                                }}>{this.state.risk.increasePrices}</p>*/}
                    {/*                            </GridItem>*/}
                    {/*                        </GridContainer>*/}
                    {/*                    </GridItem>*/}


                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                        <p style={{fontSize: 12}}><strong>Delay Completion</strong></p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    /!* Mitigated Impact - Delay Completion *!/*/}
                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <GridContainer>*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <FormControl fullWidth>*/}
                    {/*                                    <Select*/}
                    {/*                                        native*/}
                    {/*                                        value={this.state.newMitigationData.mitigatedPotentialImpact.delayCompletion}*/}
                    {/*                                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                                        inputProps={{*/}
                    {/*                                            id: 'mitigatedCompletionDelayEditInput',*/}
                    {/*                                            style: {fontSize: 12}*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        <option value={"No"}>No</option>*/}
                    {/*                                        <option value={"Yes"}>Yes</option>*/}
                    {/*                                    </Select>*/}
                    {/*                                </FormControl>*/}
                    {/*                            </GridItem>*/}

                    {/*                            /!* Unmitigated Impact - Delay Completion *!/*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <p style={{*/}
                    {/*                                    textAlign: "right",*/}
                    {/*                                    fontSize: 12*/}
                    {/*                                }}>{this.state.risk.impactDelayCompletion}</p>*/}
                    {/*                            </GridItem>*/}
                    {/*                        </GridContainer>*/}
                    {/*                    </GridItem>*/}


                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                        <p style={{fontSize: 12}}><strong>Delay meeting a Key Date</strong></p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    /!* Mitigated Impact - Delay meeting a Key Date *!/*/}
                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <GridContainer>*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <FormControl fullWidth>*/}
                    {/*                                    <Select*/}
                    {/*                                        native*/}
                    {/*                                        value={this.state.newMitigationData.mitigatedPotentialImpact.delayMeetingKeyDate}*/}
                    {/*                                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                                        inputProps={{*/}
                    {/*                                            id: 'mitigatedDelayKeyDatesEditInput',*/}
                    {/*                                            style: {fontSize: 12}*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        <option value={"No"}>No</option>*/}
                    {/*                                        <option value={"Yes"}>Yes</option>*/}

                    {/*                                    </Select>*/}
                    {/*                                </FormControl>*/}
                    {/*                            </GridItem>*/}

                    {/*                            /!* Unmitigated Impact - Delay meeting a Key Date *!/*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <p style={{*/}
                    {/*                                    textAlign: "right",*/}
                    {/*                                    fontSize: 12*/}
                    {/*                                }}>{this.state.risk.impactDelayMeeting}</p>*/}
                    {/*                            </GridItem>*/}
                    {/*                        </GridContainer>*/}
                    {/*                    </GridItem>*/}


                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                        <p style={{fontSize: 12}}><strong>Impair performance of the <span*/}
                    {/*                            style={{fontStyle: "italic"}}>works</span> in use</strong></p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    /!* Mitigated Impact - Impair performance of the works in use *!/*/}
                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <GridContainer>*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <FormControl fullWidth>*/}
                    {/*                                    <Select*/}
                    {/*                                        native*/}
                    {/*                                        value={this.state.newMitigationData.mitigatedPotentialImpact.impairPerformance}*/}
                    {/*                                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                                        inputProps={{*/}
                    {/*                                            id: 'mitigatedImpairPerformanceEditInput',*/}
                    {/*                                            style: {fontSize: 12}*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        <option value={"No"}>No</option>*/}
                    {/*                                        <option value={"Yes"}>Yes</option>*/}
                    {/*                                    </Select>*/}
                    {/*                                </FormControl>*/}
                    {/*                            </GridItem>*/}

                    {/*                            /!* Unmitigated Impact - Impair performance of the works in use *!/*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <p style={{*/}
                    {/*                                    textAlign: "right",*/}
                    {/*                                    fontSize: 12*/}
                    {/*                                }}>{this.state.risk.impactImpairPerformance}</p>*/}
                    {/*                            </GridItem>*/}
                    {/*                        </GridContainer>*/}
                    {/*                    </GridItem>*/}


                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                        <p style={{fontSize: 12}}><strong>Increase the <span*/}
                    {/*                            style={{fontStyle: "italic"}}>Contractor's</span> total cost</strong>*/}
                    {/*                        </p>*/}
                    {/*                    </GridItem>*/}

                    {/*                    /!* Mitigated Impact - Increase the Contractor's total cost *!/*/}
                    {/*                    <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                        <GridContainer>*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <FormControl fullWidth>*/}
                    {/*                                    <Select*/}
                    {/*                                        native*/}
                    {/*                                        value={this.state.newMitigationData.mitigatedPotentialImpact.increaseContractorTotalCost}*/}
                    {/*                                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                                        inputProps={{*/}
                    {/*                                            id: 'mitigatedIncreaseContractorCostEditInput',*/}
                    {/*                                            style: {fontSize: 12}*/}
                    {/*                                        }}*/}
                    {/*                                    >*/}
                    {/*                                        <option value={"No"}>No</option>*/}
                    {/*                                        <option value={"Yes"}>Yes</option>*/}
                    {/*                                    </Select>*/}
                    {/*                                </FormControl>*/}
                    {/*                            </GridItem>*/}

                    {/*                            /!* Unmitigated Impact - Increase the Contractor's total cost *!/*/}
                    {/*                            <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                <p style={{*/}
                    {/*                                    textAlign: "right",*/}
                    {/*                                    fontSize: 12*/}
                    {/*                                }}>{this.state.risk.increaseContractorsTotalCost}</p>*/}
                    {/*                            </GridItem>*/}
                    {/*                        </GridContainer>*/}
                    {/*                        <hr/>*/}
                    {/*                    </GridItem>*/}
                    {/*                </GridContainer>*/}
                    {/*        /!*    </CardBody>*!/*/}
                    {/*        /!*</Card>*!/*/}

                    {/*        /!* Add mitigation strategy Attachments *!/*/}
                    {/*        /!*<Card style={{marginBottom: 0}}>*!/*/}
                    {/*        /!*    <CardBody style={{padding: 30}}>*!/*/}
                    {/*        /!*        <GridContainer>*!/*/}
                    {/*        /!*            <GridItem xs={6} sm={6} lg={6}>*!/*/}
                    {/*        /!*                <p style={{fontSize: 16}}><strong>Attachments</strong></p>*!/*/}
                    {/*        /!*            </GridItem>*!/*/}

                    {/*        /!*            /!* Add mitigation strategy add Attachment button *!/*!/*/}
                    {/*        /!*            <GridItem xs={6} sm={6} lg={6}>*!/*/}
                    {/*        /!*                <Add style={{cursor: "pointer", fontSize: 16, float: "right"}}*!/*/}
                    {/*        /!*                     onClick={() => this.handleClickOpen("uploadAttachment", "addStrategyAttachment")}/>*!/*/}
                    {/*        /!*            </GridItem>*!/*/}

                    {/*        /!*            /!* Add mitigation Attachments table *!/*!/*/}
                    {/*        /!*            <GridItem xs={12} sm={12} md={12} lg={12}>*!/*/}
                    {/*        /!*                <ReactTable*!/*/}

                    {/*        /!*                    data={this.state.newMitigationAttachments}*!/*/}
                    {/*        /!*                    filterable={false}*!/*/}
                    {/*        /!*                    sortable={false}*!/*/}
                    {/*        /!*                    resizable={false}*!/*/}
                    {/*        /!*                    noDataText="No attachments for this strategy"*!/*/}
                    {/*        /!*                    columns={[*!/*/}
                    {/*        /!*                        {*!/*/}
                    {/*        /!*                            Header: "Description",*!/*/}
                    {/*        /!*                            accessor: "description",*!/*/}
                    {/*        /!*                            headerStyle: {fontSize: 14, fontWeight: 400}*!/*/}
                    {/*        /!*                        },*!/*/}
                    {/*        /!*                        {*!/*/}
                    {/*        /!*                            Header: "Uploaded By",*!/*/}
                    {/*        /!*                            accessor: "uploader",*!/*/}
                    {/*        /!*                            headerStyle: {fontSize: 14, fontWeight: 400},*!/*/}
                    {/*        /!*                        },*!/*/}
                    {/*        /!*                        {*!/*/}
                    {/*        /!*                            Header: "Uploaded",*!/*/}
                    {/*        /!*                            accessor: "uploadDate",*!/*/}
                    {/*        /!*                            headerStyle: {fontSize: 14, fontWeight: 400},*!/*/}
                    {/*        /!*                        },*!/*/}

                    {/*        /!*                    ]}*!/*/}
                    {/*        /!*                    defaultPageSize={5}*!/*/}
                    {/*        /!*                    showPaginationBottom={true}*!/*/}
                    {/*        /!*                    className="-striped -highlight"*!/*/}
                    {/*        /!*                    showPageJump={false}*!/*/}
                    {/*        /!*                />*!/*/}
                    {/*        /!*                {loadingAddMitigationAttachments && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }*!/*/}
                    {/*        /!*            </GridItem>*!/*/}

                    {/*        /!*        </GridContainer>*!/*/}
                    {/*        /!*    </CardBody>*!/*/}
                    {/*        /!*</Card>*!/*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => this.handleCancel("strategy")} color="info">*/}
                    {/*            Cancel*/}
                    {/*        </Button>*/}
                    {/*        <div style={{position: "relative"}}>*/}
                    {/*            <Button*/}
                    {/*                    onClick={() => this.handleSubmitEditRiskDetails("strategy")}*/}
                    {/*                    color="primary" disabled={loadingAddMitigationStrategy}>*/}
                    {/*                Add*/}
                    {/*            </Button>*/}
                    {/*            {loadingAddMitigationStrategy && <CircularProgress size={24} style={{*/}
                    {/*                position: 'absolute',*/}
                    {/*                top: '50%',*/}
                    {/*                left: '50%',*/}
                    {/*                marginTop: -12,*/}
                    {/*                marginLeft: -12*/}
                    {/*            }}/>}*/}
                    {/*        </div>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/* View mitigation strategy Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openMitigationStrategyDetailsDialog}*/}
                    {/*    onClose={() => this.handleCancel("detailedstrategy")}*/}
                    {/*    aria-labelledby="viewStrategyDialogTitle"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="viewStrategyDialogTitle">View mitigation strategy</DialogTitle>*/}
                    {/*    <DialogContent style={{width: 600}}>*/}

                    {/*        /!* Current Mitigation strategy *!/*/}
                    {/*        <GridContainer>*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <p style={{fontSize: 16}}>{this.state.currentMitigationStrategy}</p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Current Mitigation Cost *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Cost (ZAR):</strong> {this.state.currentMitigationCost}*/}
                    {/*                </p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Current Impact *!/*/}
                    {/*            <Card style={{marginTop: 5}}>*/}
                    {/*                <CardBody>*/}
                    {/*                    <GridContainer>*/}
                    {/*                        <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                            <p><strong>Mitigated Impact</strong></p>*/}
                    {/*                        </GridItem>*/}
                    {/*                        <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                            <p style={{textAlign: "right"}}><strong>Unmitigated Potential Impact</strong></p>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                            <p style={{fontSize: 12}}><strong>Increase the total of the*/}
                    {/*                                Prices</strong></p>*/}
                    {/*                        </GridItem>*/}


                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <GridContainer>*/}

                    {/*                                /!* Mitigated Impact - Increase the total of the Prices *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{fontSize: 12}}>{this.state.currentMitigatedCostImpact}</p>*/}
                    {/*                                </GridItem>*/}

                    {/*                                /!* Unmitigated Impact - Increase the total of the Prices *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{*/}
                    {/*                                        textAlign: "right",*/}
                    {/*                                        fontSize: 12*/}
                    {/*                                    }}>{this.state.risk.increasePrices}</p>*/}
                    {/*                                </GridItem>*/}
                    {/*                            </GridContainer>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                            <p style={{fontSize: 12}}><strong>Delay Completion</strong></p>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <GridContainer>*/}

                    {/*                                /!* Mitigated Impact - Delay Completion *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{fontSize: 12}}>{this.state.currentMitigatedCompletionDelay}</p>*/}
                    {/*                                </GridItem>*/}

                    {/*                                /!* Unmitigated Impact - Delay Completion *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{*/}
                    {/*                                        textAlign: "right",*/}
                    {/*                                        fontSize: 12*/}
                    {/*                                    }}>{this.state.risk.impactDelayCompletion}</p>*/}
                    {/*                                </GridItem>*/}
                    {/*                            </GridContainer>*/}
                    {/*                        </GridItem>*/}


                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                            <p style={{fontSize: 12}}><strong>Delay meeting a Key Date</strong></p>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <GridContainer>*/}

                    {/*                                /!* Mitigated Impact - Delay meeting a Key Date *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{fontSize: 12}}>{this.state.currentMitigatedDelayKeyDates}</p>*/}
                    {/*                                </GridItem>*/}

                    {/*                                /!* Unmitigated Impact - Delay meeting a Key Date *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{*/}
                    {/*                                        textAlign: "right",*/}
                    {/*                                        fontSize: 12*/}
                    {/*                                    }}>{this.state.risk.impactDelayMeeting}</p>*/}
                    {/*                                </GridItem>*/}
                    {/*                            </GridContainer>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                            <p style={{fontSize: 12}}><strong>Impair performance of the <span*/}
                    {/*                                style={{fontStyle: "italic"}}>works</span> in use</strong></p>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <GridContainer>*/}

                    {/*                                /!* Mitigated Impact - Impair performance of the works in use *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{fontSize: 12}}>{this.state.currentMitigatedImpairPerformance}</p>*/}
                    {/*                                </GridItem>*/}

                    {/*                                /!* Unmitigated Impact - Impair performance of the works in use *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{*/}
                    {/*                                        textAlign: "right",*/}
                    {/*                                        fontSize: 12*/}
                    {/*                                    }}>{this.state.risk.impactImpairPerformance}</p>*/}
                    {/*                                </GridItem>*/}
                    {/*                            </GridContainer>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                            <p style={{fontSize: 12}}><strong>Increase the <span*/}
                    {/*                                style={{fontStyle: "italic"}}>Contractor's</span> total*/}
                    {/*                                cost</strong></p>*/}
                    {/*                        </GridItem>*/}

                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <GridContainer>*/}

                    {/*                                /!* Mitigated Impact - Increase the Contractor's total cost *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{fontSize: 12}}>{this.state.currentMitigatedIncreaseContractorsCost}</p>*/}
                    {/*                                </GridItem>*/}

                    {/*                                /!* Unmitigated Impact - Increase the Contractor's total cost *!/*/}
                    {/*                                <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                                    <p style={{*/}
                    {/*                                        textAlign: "right",*/}
                    {/*                                        fontSize: 12*/}
                    {/*                                    }}>{this.state.risk.impactImpairPerformance}</p>*/}
                    {/*                                </GridItem>*/}
                    {/*                            </GridContainer>*/}
                    {/*                            <hr/>*/}
                    {/*                        </GridItem>*/}
                    {/*                    </GridContainer>*/}
                    {/*                </CardBody>*/}
                    {/*            </Card>*/}


                    {/*            /!* View mitigation strategy Attachments *!/*/}
                    {/*            <Card style={{marginBottom: 0, marginTop: 0}}>*/}
                    {/*                <CardBody style={{padding: 30}}>*/}
                    {/*                    <GridContainer>*/}
                    {/*                        <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                            <p style={{fontSize: 16}}><strong>Attachments</strong></p>*/}
                    {/*                           </GridItem>*/}

                    {/*                        /!* View mitigation strategy Add Attachments Button*!/*/}
                    {/*                        <GridItem xs={6} sm={6} md={6} lg={6}>*/}
                    {/*                            <Add style={{cursor: "pointer", fontSize: 16, float: "right"}}*/}
                    {/*                                 onClick={() => this.handleClickOpen("uploadAttachment", "viewStrategyAttachment")}/>*/}
                    {/*                        </GridItem>*/}

                    {/*                        /!* View mitigation strategy Current Attachments*!/*/}
                    {/*                        <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                            <div style={{position: "relative"}}>*/}
                    {/*                                <ReactTable*/}

                    {/*                                    data={this.state.currentMitigationAttachments}*/}
                    {/*                                    filterable={false}*/}
                    {/*                                    sortable={false}*/}
                    {/*                                    resizable={false}*/}
                    {/*                                    noDataText="No attachments"*/}
                    {/*                                    columns={[*/}
                    {/*                                        {*/}
                    {/*                                            Header: "File",*/}
                    {/*                                            accessor: "fileName",*/}
                    {/*                                            headerStyle: {fontSize: 14, fontWeight: 400},*/}
                    {/*                                        },*/}
                    {/*                                        {*/}
                    {/*                                            Header: "Uploaded By",*/}
                    {/*                                            accessor: "user.userDetails",*/}
                    {/*                                            headerStyle: {fontSize: 14, fontWeight: 400},*/}
                    {/*                                            Cell: row => (*/}
                    {/*                                                <p>{row.value.name + " " + row.value.surname}</p>*/}
                    {/*                                            ),*/}
                    {/*                                        },*/}
                    {/*                                        {*/}
                    {/*                                            Header: "Uploaded",*/}
                    {/*                                            accessor: "attachmentCreated",*/}
                    {/*                                            headerStyle: {fontSize: 14, fontWeight: 400},*/}
                    {/*                                            Cell: row => (*/}
                    {/*                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  new Date(row.value).getHours() + ":" + new Date(row.value).getMinutes() + ":" + new Date(row.value).getSeconds() : ""}</p>*/}
                    {/*                                            ),*/}
                    {/*                                        },*/}
                    {/*                                        {*/}
                    {/*                                            Header: "Actions",*/}
                    {/*                                            accessor: "id",*/}
                    {/*                                            headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},*/}
                    {/*                                            style: {"textAlign": "center"},*/}
                    {/*                                            Cell: row => (*/}
                    {/*                                                <div>*/}
                    {/*                                                    <Button simple disabled={loadingDownloadMitigationFile} onClick={() => {*/}

                    {/*                                                        this.setState({*/}
                    {/*                                                            loadingDownloadMitigationFile: true*/}
                    {/*                                                        });*/}

                    {/*                                                        // let id = this.state.contracts[this.state.contracts.findIndex(o => o.id === this.state.contractRowKey)].attachments.findIndex(o => o.id === row.value);*/}
                    {/*                                                        // let uuid = this.state.contracts[this.state.contracts.findIndex(o => o.id === this.state.contractRowKey)].attachments[id].uuid;*/}
                    {/*                                                        // let fileName = this.state.contracts[this.state.contracts.findIndex(o => o.id === this.state.contractRowKey)].attachments[id].fileName;*/}
                    {/*                                                        //*/}
                    {/*                                                        let id = this.state.currentMitigationAttachments.findIndex(o => o.id === row.value);*/}
                    {/*                                                        let uuid = this.state.currentMitigationAttachments[id].uuid;*/}

                    {/*                                                        let fileName = this.state.currentMitigationAttachments[id].fileName;*/}
                    {/*                                                        //*/}
                    {/*                                                        // this.downloadFileAPI(url);*/}
                    {/*                                                        //*/}
                    {/*                                                        this.downloadFileAPI(uuid, fileName, "strategy");*/}

                    {/*                                                    }}><GetApp color={"primary"} style={{margin: 0}}/></Button>*/}
                    {/*                                                </div>*/}

                    {/*                                            ),*/}
                    {/*                                        },*/}
                    {/*                                    ]}*/}
                    {/*                                    defaultSorted={[*/}
                    {/*                                        {*/}
                    {/*                                            id: "id",*/}
                    {/*                                            desc: true*/}
                    {/*                                        }*/}
                    {/*                                    ]}*/}
                    {/*                                    defaultPageSize={5}*/}
                    {/*                                    showPaginationBottom={true}*/}
                    {/*                                    className="-striped -highlight"*/}
                    {/*                                    showPageJump={false}*/}
                    {/*                                />*/}
                    {/*                                { loadingDownloadMitigationFile && <CircularProgress size={24} style={{*/}
                    {/*                                    position: 'absolute',*/}
                    {/*                                    top: '45%',*/}
                    {/*                                    left: '50%',*/}
                    {/*                                    marginTop: -12,*/}
                    {/*                                    marginLeft: -12*/}
                    {/*                                }}/>}*/}
                    {/*                            </div>*/}
                    {/*                        </GridItem>*/}
                    {/*                    </GridContainer>*/}
                    {/*                </CardBody>*/}
                    {/*            </Card>*/}


                    {/*            /!* View mitigation strategy Comments *!/*/}
                    {/*            /!*<Card style={{marginBottom: 0}}>*!/*/}
                    {/*            /!*    <CardBody style={{padding: 30}}>*!/*/}
                    {/*            /!*        <GridContainer>*!/*/}
                    {/*            /!*            <GridItem xs={12} lg={12}>*!/*/}
                    {/*            /!*                <p style={{fontSize: 16}}><strong>Comments</strong></p>*!/*/}
                    {/*            /!*            </GridItem>*!/*/}
                    {/*            */}
                    {/*            /!*            /!* View mitigation Current Comments *!/*!/*/}
                    {/*            /!*            <GridItem xs={12} lg={12}>*!/*/}
                    {/*            /!*                <CommentsList items={this.state.currentMitigationComments}/>*!/*/}
                    {/*            /!*                {loadingMitigationComments && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }*!/*/}
                    {/*            /!*            </GridItem>*!/*/}
                    {/*            */}
                    {/*            /!*            /!* View mitigation Add New Comment Input *!/*!/*/}
                    {/*            /!*            <GridItem xs={12} sm={12} lg={12} style={{marginTop: 15}}>*!/*/}
                    {/*            /!*                <CustomInput*!/*/}
                    {/*            /!*                    labelText="New Comment"*!/*/}
                    {/*            /!*                    id="newMitigationComment"*!/*/}
                    {/*            */}
                    {/*            /!*                    formControlProps={{*!/*/}
                    {/*            /!*                        fullWidth: true,*!/*/}
                    {/*            */}
                    {/*            /!*                    }}*!/*/}
                    {/*            /!*                    inputProps={{*!/*/}
                    {/*            /!*                        multiline: true,*!/*/}
                    {/*            /!*                        value: this.state.newMitigationCommentValue,*!/*/}
                    {/*            /!*                        onChange: (event) => this.onCommentChange(event, "mitigation")*!/*/}
                    {/*            /!*                    }}*!/*/}
                    {/*            /!*                />*!/*/}
                    {/*            /!*            </GridItem>*!/*/}
                    {/*            */}
                    {/*            /!*            /!* View mitigation Add New Comment Button *!/*!/*/}
                    {/*            /!*            <GridItem xs={12} sm={12} lg={12}>*!/*/}
                    {/*            /!*                <div style={{position: "relative", float: "left"}}>*!/*/}
                    {/*            /!*                    <Button color="primary" style={{padding: "10px 20px", margin: "15px 0"}}*!/*/}
                    {/*            /!*                            onClick={() => this.addComment("mitigation")} disabled={loadingAddMitigationComment}>Add</Button>*!/*/}
                    {/*            /!*                    {loadingAddMitigationComment && <CircularProgress size={24} style={{*!/*/}
                    {/*            /!*                        position: 'absolute',*!/*/}
                    {/*            /!*                        top: '50%',*!/*/}
                    {/*            /!*                        left: '50%',*!/*/}
                    {/*            /!*                        marginTop: -12,*!/*/}
                    {/*            /!*                        marginLeft: -12*!/*/}
                    {/*            /!*                    }}/>}*!/*/}
                    {/*            /!*                </div>*!/*/}
                    {/*            /!*            </GridItem>*!/*/}
                    {/*            /!*        </GridContainer>*!/*/}
                    {/*            /!*    </CardBody>*!/*/}
                    {/*            /!*</Card>*!/*/}
                    {/*        </GridContainer>*/}

                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => this.handleCancel("detailedstrategy")} color="info">*/}
                    {/*            Close*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}


                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openUploadRiskAttachmentDialog}
                        onClose={() => this.handleCancel("uploadAttachment")}
                        aria-labelledby="uploadRiskAttachmentDialog"
                    >
                            <DialogTitle id="uploadRiskAttachmentDialog">Upload Attachments</DialogTitle>
                            <DialogContent>

                                {/* File Upload */}
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadRiskAttachmentInput"
                                            type="file"
                                            onChange={(event) => this.changeRiskAttachment(event)}
                                        />
                                        <label htmlFor="uploadRiskAttachmentInput">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>

                                    {/* Current Attachment Name */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentRiskAttachmentFileName}</p>
                                    </GridItem>

                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleCancel("uploadAttachment")} color="info">
                                    Close
                                </Button>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"} onClick={() => this.handleSubmitAddRiskAttachment()}
                                            color="primary">
                                        Upload
                                    </Button>
                                    {loadingAddAttachment && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </DialogActions>
                    </Dialog>

                    {/* Send Early Warning Dialog */}
                    <Dialog
                        open={this.state.resendEWDialogOpen}
                        onClose={() => this.setState({resendEWDialogOpen: false })}
                        aria-labelledby="sendEWDialog"
                    >
                            <DialogTitle id="sendEWDialog">Send Early Warning</DialogTitle>
                            <DialogContent>
                                <GridContainer>

                                    {/* Others to be invited Input */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <hr style={{margin: "20px 0 10px 0"}}/>
                                        <p><strong>Others To Be Invited</strong></p>
                                        <hr style={{margin: "10px 0 10px 0"}}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={12}>

                                        {/* Current Others To Be Invited */}
                                        <div style={{marginBottom: 15}}>
                                            <List>
                                                {
                                                    this.state.othersToBeInvited === undefined || this.state.othersToBeInvited.length === 0 ?
                                                        <p>None</p> : this.state.othersToBeInvited.map((item, index) =>
                                                            <ListItem
                                                                style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                key={index}>

                                                                <GridContainer>
                                                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                                                        { item.name + " (" + item.email + ")"}
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                                                        Organisation - {item.organisation}
                                                                    </GridItem>
                                                                </GridContainer>

                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger} style={{
                                                                        cursor: "pointer",
                                                                        fontSize: 16,
                                                                        marginTop: 5
                                                                    }}
                                                                           onClick={() => this.handleRemoveOthersToBeInvited(index)}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                }

                                            </List>
                                        </div>
                                    </GridItem>

                                    {/* New Other To Be Invited Input */}

                                    <Card style={{margin: "0 15px"}}>
                                        <CardBody>

                                            <GridContainer >
                                                <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center"}}>
                                                    <Add onClick={() => this.handleAddOthersToBeInvited()}
                                                         style={{
                                                             cursor: "pointer",
                                                             // marginTop: 25,
                                                             float: "right",
                                                             fontSize: 16,
                                                         }}/>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="newOtherNameInput"
                                                        labelText="Name"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.newOtherName,
                                                            onChange: (event) =>
                                                                this.setState({newOtherName: event.target.value}),
                                                            type: "text",
                                                        }}
                                                    />
                                                </GridItem>

                                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                                    <CustomInput
                                                        id="newOtherOrganisationInput"
                                                        labelText="Organisation"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.newOtherOrganisation,
                                                            onChange: (event) =>
                                                                this.setState({newOtherOrganisation: event.target.value}),
                                                            type: "text",
                                                        }}
                                                    />
                                                </GridItem>



                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    <CustomInput
                                                        id="newOtherInput"
                                                        labelText="Email"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: this.state.newOtherEmail,
                                                            onChange: (event) =>
                                                                this.setState({newOtherEmail: event.target.value}),
                                                            type: "email",
                                                            style: {marginBottom: 25}
                                                        }}
                                                    />
                                                </GridItem>


                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridContainer>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ resendEWDialogOpen: false, othersToBeInvited: [], newOtherEmail: ""})} color="info">
                                    Cancel
                                </Button>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"}
                                            onClick={() => this.handleSendEw()}
                                            color="primary" disabled={loadingSendEW}>
                                        Send
                                    </Button>
                                    {loadingSendEW && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </DialogActions>
                    </Dialog>

                    {/*Request change of risk reduction meeting date dialog*/}
                    <Dialog
                        open={this.state.openChangeRiskReductionMeeting}
                        onClose={() => this.handleCancel("reductionMeetingDate")}
                        aria-labelledby="riskReductionMeetingDate"
                        // fullScreen
                        maxWidth={false}>
                        <DialogTitle id="riskReductionMeetingDate">Change Risk Reduction Meeting Date</DialogTitle>
                        <DialogContent>

                            {/* Date of extension*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <CustomInput
                                    id="riskReductionMeetingDate"
                                    labelText="New date"
                                    labelProps={{
                                        shrink: true
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.newReductionMeetingDate,
                                        onChange: event => this.onChange(event),
                                        type: "date",
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} lg={12}>
                                <CustomInput
                                    id="riskReductionMeetingTime"
                                    labelText="New Time"
                                    labelProps={{
                                        shrink: true
                                    }}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        style: {marginBottom: 15},
                                        value: this.state.newReductionMeetingTime,
                                        // onChange={this.handleInputChange}
                                        onChange: event =>
                                            this.onChange(event),
                                        type: "time",
                                    }}
                                />
                            </GridItem>

                        </DialogContent>

                        <DialogActions>
                            {/* Extension Back Button */}
                            <Button color={"info"} onClick={() => this.handleCancel("reductionMeetingDate")}>
                                Back
                            </Button>

                            {/* Extension Submit Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.changeRiskReductionDate()}
                                        color="primary" >
                                    Submit
                                </Button>
                                {loadingChangeReductionDate && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                </GridContainer>


            );
        }
    }
}

export default withStyles(styles)(ViewEarlyWarning);


