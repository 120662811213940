import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {replaceSubStringWithItalics} from "../../globals";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Card from "../Card/Card";
import React from "react";
import * as PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import responseAccordionStyles from "../../assets/styles/responseAccordionStyles";

const ResponseAccordion = (props) =>
    <Card className={props.classes.collapseCard + " " + props.classes.card}>
        <CardBody>
            <GridContainer>
                <GridItem xs={9} sm={8} lg={9} className={props.classes.selfAlignCenter}>
                    <p className={props.classes.marZero}>{replaceSubStringWithItalics(props.item.labelText)}</p>
                </GridItem>

                <GridItem xs={3} sm={4} lg={3}
                          className={props.classes.selfAlignCenter + " " + props.classes.marLeft10}>
                    <GridContainer style={{textAlign: "center"}}>
                        <GridItem xs={12} sm={6} lg={6}>
                            <FormControlLabel
                                className={props.classes.textAlignCenter}
                                control={
                                    <Radio
                                        checked={props.radioData === props.item.responseAction.accept}
                                        onChange={(event) => props.onChange(props.item, event)}
                                        type={"radio"}
                                        value={props.item.responseAction.accept}
                                        aria-label={props.item.responseAction.accept}
                                        icon={
                                            <FiberManualRecord
                                                className={props.classes.radioUnchecked}/>
                                        }
                                        checkedIcon={
                                            <FiberManualRecord
                                                className={props.classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: props.classes.radio,
                                            root: props.classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: props.classes.label
                                }}
                                label={props.item.responseAction.accept}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} lg={6}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={props.radioData === props.item.responseAction.reject}
                                        onChange={(event) => props.onChange(props.item, event)}
                                        value={props.item.responseAction.reject}
                                        type={"radio"}
                                        aria-label={props.item.responseAction.reject}
                                        icon={
                                            <FiberManualRecord
                                                className={props.classes.radioUnchecked}/>
                                        }
                                        checkedIcon={
                                            <FiberManualRecord
                                                className={props.classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: props.classes.radio,
                                            root: props.classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: props.classes.label
                                }}
                                label={props.item.responseAction.reject}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </CardBody>
    </Card>

ResponseAccordion.propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object.isRequired,
    radioData: PropTypes.string.isRequired
};

export default withStyles(responseAccordionStyles)(ResponseAccordion);