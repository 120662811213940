import React from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CustomInput from "../../CustomInput/CustomInput";
import { firstToUpper, getCurrentDate, removeAllWhiteSpace, verifyDecimal, verifyEmail, verifyLength } from "../../../globals";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Button from "../../CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import communicationSubmitForm from "../../../assets/styles/communicationSubmitFormStyles";
import { withStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import NotificationPreviewDialog from "./NotificationPreviewDialog";
import ProjectAndContractDropdown from "../../CustomDropdown/ProjectAndContractDropdown";
import UploadAttachmentInput from "../../CustomInput/UploadAttachmentInput";
import AddEmailInput from "../../CustomInput/AddEmailInput";
import RadioGroupAccordion from "../../Accordion/RadioGroupAccordion";
import * as actionCreators from "../../../store/actions";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DefectDropdown from "../../CustomDropdown/DefectDropdown";
import DefectCategoryDropdown from "../../CustomDropdown/DefectCategoryDropdown";
import RecipientList from "../../CustomLists/RecipientList";

class NotificationCreateForm extends React.Component {
  //Set state values
  state = {
    openPreview: false,
    previewPDF: "",
    showRequiredDescription: false,
    actionType: "",
    defaultRecipients: []
  };

  //event handler for dynamic input boxes
  onChangeInput = (item, event, indexPar = 0) => {

    //validate if item is required
    if (item.required)
      if (item.type === "text" || item.type === "textArea" || item.type === "date" || item.type === "cost")
        verifyLength(removeAllWhiteSpace(event.target.value), 1, item.field)
            ? this.setState({ [item.field + "State"]: "success" })
            : this.setState({ [item.field + "State"]: "error" });

      else if (item.type === "checkBox")
        event.target.checked === true
            ? this.setState({ [item.field + "State"]: "success" })
            : this.setState({ [item.field + "State"]: "error" });

    if (item.type === "clauseRadio") {
      let updatedObj = {};

      if (event.target.type === "radio")
        updatedObj = Object.assign({}, this.state[item.field][indexPar], {
          choice: event.target.value
        });
       else
        updatedObj = Object.assign({}, this.state[item.field][indexPar], {
          comments: event.target.value
        });


      this.setState({
        [item.field]: [
          ...this.state[item.field].slice(0, indexPar),
          updatedObj,
          ...this.state[item.field].slice(indexPar + 1)
        ]
      });
    }
    //update input value state
    if(item.type === "text" || item.type === "textArea" || item.type === "date" || item.type === "cost")
      this.setState({ [item.field] : event.target.value });
    else if(item.type === "checkBox")
      this.setState( { [item.field] : event.target.checked })
  };

  //event handler for dropdowns
  onChangeDropdown = (event, input, itemField) => {
    //project input
    if (input === "project")
      this.setState({
        [itemField + "Project"]: event.target.value
      });

    //contract input
    else if (input === "contract")
      this.setState({
        [itemField + "Contract"]: parseInt(event.target.value)
      });

    //defect input
    else if (input === "defect")
      this.setState({
        [itemField + "Defect"]: event.target.value,
        [itemField + "State"]: "success"
      });

    //defect category input
    else if (input === "category")
      this.setState({
        [itemField + "Category"]: event.target.value,
        [itemField + "State"]: "success"
      });

    else
      this.setState({
        [itemField]: event.target.value,
        [itemField + "State"]: "success"
      });
  };

  //event handler for inputs controlled by other buttons
  onChangeButtonInput = (item, event, input) => {
    //if attachment
    if (item.type === "attachment") {
      //if no file was chosen
      if(event.target.files.length === 0)
        this.props.onSetError("No file chosen");

      //create object containing image data to upload
      let arr = [...this.state[item.field]];

      let obj = {
        fileName: event.target.files[0].name,
        file: event.target.files[0]
      };

      //clear value so that on change fires
      event.target.value = '';

      //push object to attachment array
      arr.push(obj);

      //update state to new values
      this.setState({
        [item.field]: arr,
        [item.field + "HeadingColor"]: "#000000"
      });

      //create string to use when calling preview api
      this.createAttachmentsString(arr, item);
    }
    else {
      if (input === "email") {
        verifyEmail(event.target.value)
            //validate the email address provided and set input state
            ? this.setState({ [item.field + "State"]: "success" })
            : this.setState({ [item.field + "State"]: "error" });

        //update input state
        this.setState({ [item.field + "Input"]: event.target.value });
      }
      else {
        //validate that name input is not empty
        verifyLength(removeAllWhiteSpace(event.target.value), 1)
            ? this.setState({ [item.field + "NameInputState"]: "success" })
            : this.setState({ [item.field + "NameInputState"]: "error" });

        //update name input for email dialog
        this.setState({ [item.field + "NameInput"]: event.target.value });
      }
    }
  };

  //remove item from list components
  removeFromList = (index, item) => {

    //set temp array to list
    let arr = this.state[item.field];

    //remove chosen item from list
    arr.splice(index, 1);

    //change heading color if item is required and list is empty
    if (arr.length === 0 && item.required)
      this.setState({
        [item.field + "HeadingColor"]: "#f44336"
      });

    //update list state
    this.setState({
      [item.field]: arr
    });

    //creates string of attachments to use in preview template
    this.createAttachmentsString(arr, item);
  };

  //add item to list components
  addToList = (item, nameRef, emailRef) => {
    if (item.type === "email") {

      //check if email and name input fields are not empty
      if (this.state[item.field + "NameInput"] === "" && this.state[item.field + "Input"] === "") {
        this.setState({
          [item.field + "NameInputState"]: "error",
          [item.field + "State"]: "error"
        });

        this.props.onSetError("Please fill in required fields", "danger");
      } else if (this.state[item.field + "NameInput"] === "") {
        //check if name input is empty
        this.setState({
          [item.field + "NameInputState"]: "error"
        });

        this.props.onSetError("Please provide a name", "danger");
      } else if (this.state[item.field + "Input"] === "" || !verifyEmail(this.state[item.field + "Input"]))
        //check if email is not empty and contains a valid email address
        this.props.onSetError("Please provide a valid email address", "danger");

      else {
        //update state if all validation has passed

        let arr = this.state[item.field];

        let obj = {
          name: this.state[item.field + "NameInput"],
          email: this.state[item.field + "Input"]
        };

        arr.push(obj);

        nameRef.value = "";
        emailRef.value = "";

        this.setState({
          [item.field]: arr,
          [item.field + "Input"]: "",
          [item.field + "State"]: "",
          [item.field + "NameInputState"]: "",
          [item.field + "NameInput"]: "",
          [item.field + "HeadingColor"]: "#000000"
        });
      }
    }
  };

  //create attachment string to use for preview
  createAttachmentsString = (arr, item) => {

    //check if list is empty
    if (arr.length !== 0) {
      let attachmentsString = "";

      arr.forEach(object => {
        attachmentsString += object.fileName + "; ";
      });

      this.setState({
        ["preview" + firstToUpper(item.field) + "String"]: attachmentsString
      });
    } else
      this.setState({
        ["preview" + firstToUpper(item.field) + "String"]: ""
      });
  };

  //function that handles closing preview dialogs
  closePreview = () => {
    this.setState({
      openPreview: false
    });
  };

  //validate form before calling preview api
  validateForm = () => {
    let validationPassed = true;

    for (let i = 0; i < this.props.clauseData.dataFields.length; i++) {
      if (!this.props.isRevision || (this.props.isRevision && this.props.clauseData.dataFields[i].revisionField)) {
        if (this.props.clauseData.dataFields[i].required && (this.props.clauseData.dataFields[i].type === "attachment" || this.props.clauseData.dataFields[i].type === "email")) {
          //change heading color if list is required
          if (this.state[this.props.clauseData.dataFields[i].field].length === 0) {
            this.setState({
              [this.props.clauseData.dataFields[i].field +
              "HeadingColor"]: "#f44336"
            });
            validationPassed = false;
          }
        }
        else if (this.props.clauseData.dataFields[i].required && this.props.clauseData.dataFields[i].type === "defect") {
          if (this.state[this.props.clauseData.dataFields[i].field + "Defect"] === 0) {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "error"
            });
            validationPassed = false;
          }
          else {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "success"
            });
          }
        }

        //TODO

        else if (this.props.clauseData.dataFields[i].required && this.props.clauseData.dataFields[i].type === "category") {
          if (this.state[this.props.clauseData.dataFields[i].field + "Category"] === "") {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "error"
            });
            validationPassed = false;
          }
          else {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "success"
            });
          }
        }
        else if (this.props.clauseData.dataFields[i].required && this.props.clauseData.dataFields[i].type === "cost") {
          if (verifyDecimal(this.state[this.props.clauseData.dataFields[i].field]) === false) {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "error"
            });
            validationPassed = false;
          }
          else {
            this.setState({
              [this.props.clauseData.dataFields[i].field + "State"]: "success"
            });
          }
        }

        else if (this.props.clauseData.dataFields[i].required && removeAllWhiteSpace(this.state[this.props.clauseData.dataFields[i].field]) === "") {
          //check if other required inputs are not empty

          this.setState({
            [this.props.clauseData.dataFields[i].field + "State"]: "error"
          });
          validationPassed = false;
        }

        else if (this.props.clauseData.dataFields[i].field === "title" && !verifyLength(this.state[this.props.clauseData.dataFields[i].field], 1, this.props.clauseData.dataFields[i].field)) {
          this.setState({
            [this.props.clauseData.dataFields[i].field + "State"]: "error"
          });
          validationPassed = false;
        }

        else if (this.props.clauseData.dataFields[i].type === "cost" && verifyDecimal(this.state[this.props.clauseData.dataFields[i].field]) === false)
        {
          this.setState({
            [this.props.clauseData.dataFields[i].field + "State"]: "error"
          });
          validationPassed = false;
        }

        else if (this.props.clauseData.dataFields[i].type === "contract" && this.state[this.props.clauseData.dataFields[i].field] === 0) {
          this.setState({
            [this.props.clauseData.dataFields[i].field + "State"]: "error"
          });
          validationPassed = false;
        }
      }
    }
    return validationPassed;
  };

  //dynamically build json object to post to api
  buildJsonObject = () => {
    let jsonObj = {};

    if (this.props.isRevision && this.props.clauseData.revisionIsResponse) {
      jsonObj[this.props.type + "Revision"] = this.props.id;
      jsonObj["status"] = { statusValue : "open"};
      for (let i = 0; i < this.props.clauseData.dataFields.length; i++) {
        if (!this.props.isRevision || (this.props.isRevision && this.props.clauseData.dataFields[i].revisionField)) {
          if (this.props.clauseData.dataFields[i].type === "attachment")
            jsonObj["preview" + firstToUpper(this.props.clauseData.dataFields[i].field) + "String"] = this.state["preview" + firstToUpper(this.props.clauseData.dataFields[i].field) + "String"];

          else if (this.props.clauseData.dataFields[i].type === "defect")
            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field + "Defect"];

          else if (this.props.clauseData.dataFields[i].type === "category")
            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field + "Category"];

          else
            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field];

        }
      }
    }
    else{
      if (this.props.isRevision)
        jsonObj[this.props.type] = this.props.id;

      else
        jsonObj["clause"] =
          this.props.initialData !== undefined
              ? this.props.initialData.clause
              : this.props.clauseData.clauseNumber;

      for (let i = 0; i < this.props.clauseData.dataFields.length; i++) {
        if (!this.props.isRevision || (this.props.isRevision && this.props.clauseData.dataFields[i].revisionField)) {
          if (this.props.clauseData.dataFields[i].type === "attachment")
            jsonObj["preview" + firstToUpper(this.props.clauseData.dataFields[i].field) + "String"] = this.state["preview" + firstToUpper(this.props.clauseData.dataFields[i].field) + "String"];

          else if (this.props.clauseData.dataFields[i].type === "contract")
            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field + "Contract"];

          else if (this.props.clauseData.dataFields[i].type === "defect")

            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field + "Defect"];

          else if (this.props.clauseData.dataFields[i].type === "category")

            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field + "Category"];

          else
            jsonObj[this.props.clauseData.dataFields[i].field] = this.state[this.props.clauseData.dataFields[i].field];

        }
        else
          jsonObj[this.props.clauseData.dataFields[i].field] = this.props.initialData[this.props.clauseData.dataFields[i].field];

      }
    }
    return jsonObj;
  };

  //pass correct action type (Send / SendDownload)
  onActionClickHandler = (id, sendType) => {
    this.setState({
          actionType: sendType
        },
        () => {
          this.sendCommunication(id, sendType);
        });
  };

  //get the redux state name to identify current clause type and number
  getReduxStateName = () => {
    return this.props.type + "_" + this.props.clauseData.id;
  };

  //calls preview api that returns template
  previewCommunication = () => {
    if (this.validateForm()) {
      let payload = {
        id: this.getReduxStateName(),
        url: this.props.apiEndpoints.preview,
        data: this.buildJsonObject()
      };

      if (this.props.isRevision && this.props.clauseData.revisionIsResponse)
        payload.url = this.props.apiEndpoints.previewRevisionResponse;

      else if (this.props.isRevision)
        payload.url = this.props.apiEndpoints.previewRevision;

      this.props.onPreview(payload).then(() => {
        this.setState({
          openPreview: true,
          previewPDF: this.props.preview[this.getReduxStateName()]
        });
      });
    } else
      //validation failed
      this.props.onSetError("One or more invalid fields");

  };

  //create, create revision, and create revision response api
  createCommunication = type => {
    let payload = {
      id: this.getReduxStateName(),
      url: this.props.apiEndpoints.create,
      data: this.buildJsonObject()
    };

    if (this.props.isRevision && this.props.clauseData.revisionIsResponse)
      payload.url = this.props.apiEndpoints.createRevisionResponse;

    else if (this.props.isRevision)
      payload.url = this.props.apiEndpoints.createRevision;

    this.props.onCreate(payload).then(() => {
      this.onActionClickHandler(
          this.props.createId[this.getReduxStateName()],
          type
      );
    });
  };

  //calls apis for send and send & download of preview
  sendCommunication = (id, sendType) => {
    let promises = [];
    let type = this.props.type;

    for (let i = 0; i < this.props.clauseData.dataFields.length; i++) {
      let item = this.props.clauseData.dataFields[i];

      if (item.type === "attachment") {
        if (this.state[item.field].length !== 0) {
          if (this.props.isRevision) {
            type += "_revision";
            if(this.props.clauseData.revisionIsResponse)
              type += "_response";
          }

          let data = new FormData();
          data.append("id", id);
          data.append("type", type);

          this.state[item.field].forEach(object => {
            data.append("file", object.file);
          });

          let individualPayload = {
            url: this.props.apiEndpoints.attachments,
            data: data
          };
          promises.push(this.props.onUploadFiles(individualPayload));
        }
      }

      else if (item.type === "email") {
        if (this.props.communicationId)
        {

          let arr = this.state[item.field];
          let arrInitial = this.props.recipients[this.getReduxStateName()];

          if (arr.length !== 0 && arrInitial.length !== 0) {

            arrInitial.forEach(initialItem => {

              if (!arr.includes(initialItem))
              {
                let payload = { url: this.props.apiEndpoints.deleteNotificationTo + "/" + initialItem.id };
                this.props.onDeleteRecipient(payload);
              }
            })
          }

          else if (arrInitial.length !== 0 && arr.length === 0)
          {
            arrInitial.forEach(item => {
              let payload = { url: this.props.apiEndpoints.deleteNotificationTo + "/" + item.id };
              this.props.onDeleteRecipient(payload);
            })
          }
        }
      }
    }

    let payload = {
      promises: promises
    };

    this.props.onUploadAllFiles(payload).then(() => {
      let payload = {
        id: this.getReduxStateName(),
        createId: this.props.createId[this.getReduxStateName()]
      };

      if (sendType === "sendDownload") {

        payload.url = this.props.apiEndpoints.sendDownload;

        if (this.props.isRevision && this.props.clauseData.revisionIsResponse)
          payload.url = this.props.apiEndpoints.sendDownloadRevisionResponse;

        else if (this.props.isRevision)
          payload.url = this.props.apiEndpoints.sendDownloadRevision;


        this.props.onSendDownload(payload).then(() => {
          if (this.props.isRevision) {
            let pagePayload = {
              url: this.props.apiEndpoints.details + "/" + this.props.communicationId
            };

            this.props.onGetDetailsAndPermissions(pagePayload).then(() => {
              let message = "Clause " + this.props.clauseData.clauseNumber + " revision submitted";
              this.props.onSuccess(message);
            });
          } else {
            this.closePreview();
            let message = "Clause " + this.props.clauseData.clauseNumber + " submitted";
            this.props.onSuccess(message);
          }
        });
      } else {
        payload.url = this.props.apiEndpoints.send;

        if (this.props.isRevision && this.props.clauseData.revisionIsResponse)
          payload.url = this.props.apiEndpoints.sendRevisionResponse;

        else if (this.props.isRevision)
          payload.url = this.props.apiEndpoints.sendRevision;


        this.props.onSend(payload).then(() => {
          if (this.props.isRevision) {
            let pagePayload = {
              url: this.props.apiEndpoints.details + "/" + this.props.communicationId
            };

            this.props.onGetDetailsAndPermissions(pagePayload).then(() => {
              let message = "Clause " + this.props.clauseData.clauseNumber + " revision submitted";
              this.props.onSuccess(message);
            });
          } else {
            this.closePreview();
            let message = "Clause " + this.props.clauseData.clauseNumber + " submitted";
            this.props.onSuccess(message);
          }
        });
      }
    });
  };

  //build dynamic check states to use for checkbox
  buildCheckedStates = (array, fieldName) => {
    array.forEach((obj, index) => {
      this.setState({
        ["checked" + index] : false,
        [fieldName + "StateSet"] : true,
      })
    });
  };

  //ensure setState finishes before rendering components
  stateLoaded = () => {
    let flag = true;

    for (let i = 0; i < this.props.clauseData.dataFields.length; i++) {
      if (!this.props.isRevision && this.state[this.props.clauseData.dataFields[i].field + "StateSet"] !== true)
        flag = false;

      else if (this.props.isRevision && this.props.clauseData.dataFields[i].revisionField && this.state[this.props.clauseData.dataFields[i].field + "StateSet"] !== true)
        flag = false;

    }

    return flag;
  };

  loadDefaultRecipients = async (item, payload) => {
    if (this.props.clauseData.clauseNumber === "42.2") {
      const contractId = this.props.communicationId ? 
        this.props.initialData.contract.id 
        : this.state[item.field + "Contract"]

      const getDefaultRecipientsParams = {
        url: this.props.apiEndpoints.defaultNotificationTo,
        params: {
          ...payload.params,
          clauseNr : this.props.clauseData.clauseNumber,
          contract_id: contractId
        }
      };

      try {
        if (!this.props.defaultRecipients || this.props.defaultRecipients.length === 0) {
          await this.props.onGetDefaultRecipients(getDefaultRecipientsParams);

          if (this.props.defaultRecipients && this.props.defaultRecipients.length !== 0) {
            this.setState({
              ...this.state,
              defaultRecipients: this.props.defaultRecipients,
              [item.field + "StateSet"]: true
            });
          }
          else {
            this.setState({
              ...this.state,
              [item.field + "StateSet"]: true
            });
          }
        } else {
            this.setState({
              defaultRecipients: this.props.defaultRecipients,
              [item.field + "StateSet"]: true
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
    else {
      this.setState({
        ...this.setState,
        [item.field + "StateSet"]: true
      });
    }
  }

  //call api to GET requested items for item type
  loadItems = item => {
    let payload = {
      id: this.getReduxStateName(),
    };

    if (item.type === "contract") {
      if (!this.props.projectsAndContracts || this.props.projectsAndContracts.length === 0) {

        payload.url = this.props.apiEndpoints.projectsAndContracts;

        this.props.onGetProjectsAndContracts(payload).then(() => {
          this.setState({
            [item.field + "List"]: this.props.projectsAndContracts,
            [item.field + "Project"]: this.props.projectsAndContracts[0].project.id,
            [item.field + "Contract"]: this.props.projectsAndContracts[0].contracts[0].id,
            [item.field + "CurrentChosenProject"]: 0,
          },
           () => {
              this.loadDefaultRecipients(item, payload);
           })
        }
        );
      }
      else
        this.setState({
          [item.field + "List"]: this.props.projectsAndContracts,
          [item.field + "Project"]: this.props.projectsAndContracts[0].project.id,
          [item.field + "Contract"]: this.props.projectsAndContracts[0].contracts[0].id,
          [item.field + "CurrentChosenProject"]: 0,
        },
            () => {
              this.loadDefaultRecipients(item, payload);
        });
    }

    else if (item.type === "defect") {
      if (!this.props.defects[this.getReduxStateName()] || this.props.defects[this.getReduxStateName()].length === 0)
      {
      payload.url = this.props.apiEndpoints.defects;

      this.props.onGetDefects(payload).then(() => {
          if (this.props.defects[this.getReduxStateName()] && this.props.defects[this.getReduxStateName()].length !== 0)
            this.setState({
              [item.field + "List"]: this.props.defects[this.getReduxStateName()],
              [item.field + "StateSet"]: true,
            });

          else
          this.setState({
            [item.field + "StateSet"]: true,
            });
          })
      }
      else
        this.setState({
          [item.field + "List"]: this.props.defects[this.getReduxStateName()],
          [item.field + "StateSet"]: true,
        });

    }

    else if (item.type === "email") {

      if (this.props.communicationId) {

        if (!this.props.recipients[this.getReduxStateName()]) {

        payload.url = this.props.apiEndpoints.notificationTos + "/" + this.props.communicationId;

        this.props.onGetRecipients(payload).then(() => {

            if (this.props.recipients[this.getReduxStateName()] && this.props.recipients[this.getReduxStateName()].length !== 0)
              this.setState({
                  [item.field]: this.props.recipients[this.getReduxStateName()].slice(),
                [item.field + "StateSet"]: true,
              });

            else
              this.setState({
                [item.field + "StateSet"]: true,
              });

          });
        }
        else if (this.props.recipients[this.getReduxStateName()] && this.props.recipients[this.getReduxStateName()].length !== 0)
            this.setState({
              [item.field]: this.props.recipients[this.getReduxStateName()].slice(),
              [item.field + "StateSet"]: true,
            });

        else
          this.setState({
            [item.field + "StateSet"]: true,
        });

      }
      else
        this.setState({
          [item.field + "StateSet"]: true,
        });
    }

    else if (item.type === "default") {
      if (this.props.communicationId)
        this.loadDefaultRecipients(item, payload);
      else
        this.setState({
          [item.field + "StateSet"]: true,
        });
    }
  }

  //dynamically create state to be used for inputs of submit form
  constructState = item => {
    if (item.required)
      this.setState({
        showRequiredDescription: true
      });

    switch (item.type) {
      case "date":
        this.setState({
          [item.field]: getCurrentDate(),
          [item.field + "State"]: "",
          [item.field + "StateSet"]: true
        });
        break;
      case "attachment":
        this.setState({
          [item.field]: [],
          [item.field + "HeadingColor"]: "#000000",
          ["preview" + firstToUpper(item.field) + "String"]: "",
          [item.field + "StateSet"]: true
        });
        break;
      case "email":
        this.setState({
          [item.field]: [],
          [item.field + "Input"]: "",
          [item.field + "NameInput"]: "",
          [item.field + "NameInputState"]: "",
          [item.field + "State"]: "",
          [item.field + "HeadingColor"]: "#000000",
        },
            () => this.loadItems(item));
        break;
      case "contract":
        this.setState(
            {
              [item.field + "List"]: [],
              [item.field + "Project"]: 0,
              [item.field + "Contract"]: 0,
              [item.field + "CurrentChosenProject"]: -1,
            },
            () => this.loadItems(item)
        );
        break;
      case "clauseRadio": {
        this.setState({
          [item.field]: item.objectTemplate.slice(0),
          [item.field + "StateSet"]: true
        });
        break;
      }
      case "defect" :
        this.setState(
            {
              [item.field + "List"]: [],
              [item.field + "Defect"]: 0,
            },
            () => this.loadItems(item)
        );
        break;
      case "default":
        this.loadItems(item)
        break;
      case "category":
        this.setState({
              [item.field + "List"]: item.objectTemplate.slice(0),
              [item.field + "Category"]: "",
              [item.field + "StateSet"]: true
            })
        break;
      case "checkBox" :
        this.setState({
          [item.field + "State"] : "",
          [item.field + "HeadingColor"] : "#000000",
        }, () => this.buildCheckedStates(item.checkBoxTemplate, item.field));
        break;
      default:
        this.setState({
          [item.field]: "",
          [item.field + "State"]: "",
          [item.field + "StateSet"]: true
        });
        break;
    }
  };

  //component did mount handler
  componentDidMount() {
    //dynamically create state to be used for inputs of submit form
    this.props.clauseData.dataFields.forEach(item => {
      if (this.props.isRevision && item.revisionField)
        this.constructState(item);
      else if (!this.props.isRevision)
        this.constructState(item);
      }
    );
  }

  //dynamically generate components based on fields
  generateComponents = (item, key) => {
    switch (item.type) {
      case "text":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <CustomInput
                  labelText={item.required ? item.labelText + " *" : item.labelText}
                  success={this.state[item.field + "State"] === "success"}
                  error={this.state[item.field + "State"] === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: this.state[item.field] || "",
                    onBlur: event => this.onChangeInput(item, event),
                    type: "text",
                    endAdornment:
                        this.state[item.field + "State"] === "error" ? (
                            <InputAdornment position="end">
                              <Close className={this.props.classes.danger} />
                            </InputAdornment>
                        ) : undefined
                  }}
              />
            </GridItem>
        );
      case "textArea":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <CustomInput
                  labelText={item.required ? item.labelText + " *" : item.labelText}
                  success={this.state[item.field + "State"] === "success"}
                  error={this.state[item.field + "State"] === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    defaultValue: this.state[item.field] || "",
                    onBlur: event => this.onChangeInput(item, event),
                    type: "text",
                    endAdornment:
                        this.state[item.field + "State"] === "error" ? (
                            <InputAdornment position="end">
                              <Close className={this.props.classes.danger} />
                            </InputAdornment>
                        ) : undefined
                  }}
              />
            </GridItem>
        );
      case "date":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <CustomInput
                  labelText={item.required ? item.labelText + " *" : item.labelText}
                  success={this.state[item.field + "State"] === "success"}
                  error={this.state[item.field + "State"] === "error"}
                  labelProps={{
                    shrink: true
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: this.state[item.field] || "",
                    onBlur: event => this.onChangeInput(item, event),
                    type: "date",
                    className: this.props.classes.input,
                    endAdornment:
                        this.state[item.field + "State"] === "error" ? (
                            <InputAdornment position="end">
                              <Close className={this.props.classes.danger} />
                            </InputAdornment>
                        ) : undefined
                  }}
              />
            </GridItem>
        );
      case "cost":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <CustomInput
                  labelText={item.required ? item.labelText + " *" : item.labelText}
                  success={this.state[item.field + "State"] === "success"}
                  error={this.state[item.field + "State"] === "error"}
                  labelProps={{
                    shrink: true
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    defaultValue: this.state[item.field] || "",
                    onBlur: event => this.onChangeInput(item, event),
                    type: "number",
                    startAdornment:
                        <InputAdornment position="start">R</InputAdornment>,
                    endAdornment:
                        this.state[item.field + "State"] === "error" ? (
                            <InputAdornment position="end">
                              <Close className={this.props.classes.danger} />
                            </InputAdornment>
                        ) : undefined
                  }}
              />
            </GridItem>
        );
      case "clauseRadio":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <RadioGroupAccordion
                  clauseData={item}
                  radioData={this.state[item.field]}
                  onChange={this.onChangeInput}
                  isDetails={false}
              />
            </GridItem>
        );
      case "attachment":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <UploadAttachmentInput
                  onChange={this.onChangeButtonInput}
                  data={item}
                  id={this.props.initialData !== undefined
                      ? "upload" + firstToUpper(item.field + this.props.initialData.clause) + "Input"
                      : "upload" + firstToUpper(item.field + this.props.clauseData.clauseNumber) + "Input"}
                  onRemoveFromListParent={this.removeFromList}
                  attachments={this.state[item.field]}
                  heading={item.required ? item.labelText + " *" : item.labelText}
                  headingColor={this.state[item.field + "HeadingColor"]}
                  noDataText={
                    item.required ? "No attachments (required)" : "No attachments"
                  }
              />
            </GridItem>
        );
      case "email":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <AddEmailInput
                  onChange={this.onChangeButtonInput}
                  data={item}
                  onAddToListParent={this.addToList}
                  onRemoveFromListParent={this.removeFromList}
                  addEmailInput={this.state[item.field + "Input"]}
                  addEmailInputState={this.state[item.field + "State"]}
                  addNameInput={this.state[item.field + "NameInput"]}
                  addNameInputState={this.state[item.field + "NameInputState"]}
                  emailList={this.state[item.field]}
                  heading={item.required ? item.labelText + " *" : item.labelText}
                  headingColor={this.state[item.field + "HeadingColor"]}
                  noDataText={
                    item.required ? "No recipients (required)" : "No recipients"
                  }
              />
            </GridItem>
        );
      case "default":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <RecipientList
                  emailList={this.state.defaultRecipients}
                  heading={item.required ? item.labelText + " *" : item.labelText}
                  headingColor={this.state[item.field + "HeadingColor"]}
                  noDataText={"No recipients"}
              />
            </GridItem>
        );
      case "contract":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <ProjectAndContractDropdown
                  onChange={this.onChangeDropdown}
                  projectsAndContracts={this.state[item.field + "List"]}
                  project={this.state[item.field + "Project"]}
                  contract={this.state[item.field + "Contract"]}
                  currentChosenProject={
                    this.state[item.field + "CurrentChosenProject"]
                  }
                  statePrefix={item.field}
              />
            </GridItem>
        );
      case "checkBox" :
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <p>Defects</p>
              {this.state[item.checkBoxTemplate].map((obj, index) =>
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={this.props["checked" + index]}
                            onChange={(event) => this.onChangeInput(item, event,"checked" + index)}
                            value={obj}
                            color="primary"
                        />
                      }
                      label={obj}
                      key={index}
                  />
              )}
            </GridItem>
        );
      case "defect":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <DefectDropdown
                  onChange={this.onChangeDropdown}
                  defects={this.state[item.field + "List"]}
                  defect={this.state[item.field + "Defect"]}
                  statePrefix={item.field}
                  errorState={this.state[item.field + "State"]}
              />
            </GridItem>
        );
      case "category":
        return (
            <GridItem xs={12} sm={12} lg={12} key={key}>
              <DefectCategoryDropdown
                  onChange={this.onChangeDropdown}
                  categories={this.state[item.field + "List"]}
                  category={this.state[item.field + "Category"]}
                  statePrefix={item.field}
                  inputState={this.state[item.field + "State"]}
              />
            </GridItem>
        );
      default:
        return "";
    }
  };

  render() {
    const { classes } = this.props;
    const { openPreview, previewPDF, actionType } = this.state;

    if ((!this.props.isRevision && (this.props.projectsAndContractsErrors || this.props.defectsErrors[this.getReduxStateName()] || this.props.recipientsErrors[this.getReduxStateName()] || this.props.defaultRecipientsErrors))
        || (this.props.isRevision && (this.props.recipientsErrors[this.getReduxStateName()] || this.props.defaultRecipientsErrors)))
      return null
    else if ((!this.props.isRevision && (this.props.projectsAndContractsLoading || this.props.defectsLoading[this.getReduxStateName()] || this.props.recipientsLoading[this.getReduxStateName()] || this.props.defaultRecipientsLoading))
        || (this.props.isRevision && (this.props.recipientsLoading[this.getReduxStateName()] || this.props.defaultRecipientsLoading))) {
      return (
          <GridContainer>
            <GridItem xs={12} style={{ textAlign: "center" }}>
              <CircularProgress
                  color={"primary"}
                  className={classes.loadingWheel}
              />
            </GridItem>
          </GridContainer>
        );
    } else {
      return (
          this.stateLoaded() && (
              <GridContainer>
                <GridItem xs={12} sm={12} lg={12}>
                  <Card className={classes.card}>
                    <CardBody>
                      {/* generate input fields dynamically based on data sent in */}
                      {this.props.clauseData.dataFields.map((item, key) => {
                        if (this.props.isRevision && item.revisionField) {
                          return this.generateComponents(item, key);
                        } else if (!this.props.isRevision) {
                          return this.generateComponents(item, key);
                        } else {
                          return "";
                        }
                      })}
                      {/* show required information for all required fields in the form */}
                      {this.state.showRequiredDescription ? (
                          <GridContainer>
                            <GridItem xs={12} sm={6} lg={6}>
                              {/* preview fields description*/}
                              <p className={classes.requiredDescription}>
                                * Required Information
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={6} lg={6}>
                              {/* Submit form button*/}
                              <div className={classes.submitButtonContainer}>
                                <Button
                                    type={"submit"}
                                    className={classes.submitButton}
                                    onClick={this.previewCommunication}
                                    color="primary"
                                    disabled={this.props.previewLoading[this.getReduxStateName()]}
                                >
                                  Preview
                                </Button>
                                {/* loading wheel for preview dialog */}
                                {this.props.previewLoading[this.getReduxStateName()] &&
                                (<CircularProgress size={24} className={classes.previewLoadingWheel}/>)
                                }
                              </div>
                            </GridItem>
                          </GridContainer>
                      ) : (
                          <GridContainer>
                            <GridItem xs={12} sm={12} lg={12}>
                              {/* preview form button*/}
                              <div className={classes.submitButtonContainer}>
                                <Button
                                    type={"submit"}
                                    className={classes.submitButton}
                                    onClick={this.previewCommunication}
                                    color="primary"
                                    disabled={this.props.previewLoading[this.getReduxStateName()]}
                                >
                                  Preview
                                </Button>
                                {/* loading wheel for preview dialog */}
                                {this.props.previewLoading[
                                    this.getReduxStateName()
                                    ] && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.previewLoadingWheel}
                                    />
                                )}
                              </div>
                            </GridItem>
                          </GridContainer>
                      )}
                    </CardBody>
                  </Card>

                  {/* preview dialog */}
                  <NotificationPreviewDialog
                      open={openPreview}
                      onClose={this.closePreview}
                      actionMethod={this.createCommunication}
                      data={previewPDF}
                      loadingSend={
                        this.props.sendLoading[this.getReduxStateName()] ||
                        (this.props.createLoading[this.getReduxStateName()] &&
                            actionType === "send") ||
                        (this.props.uploadFileLoading && actionType === "send")
                      }
                      loadingSendDownload={
                        this.props.sendDownloadLoading[this.getReduxStateName()] ||
                        (this.props.createLoading[this.getReduxStateName()] &&
                            actionType === "sendDownload") ||
                        (this.props.uploadFileLoading &&
                            actionType === "sendDownload")
                      }
                  />
                </GridItem>
              </GridContainer>
          )
      );
    }
  }
}

//set up prop types to suppress warnings
NotificationCreateForm.propTypes = {
  type: PropTypes.string,
  isRevision: PropTypes.bool,
  initialData: PropTypes.object,
  clauseData: PropTypes.object,
  apiEndpoints: PropTypes.object,
  id: PropTypes.number
};

const mapStateToProps = state => {
  return {
    uploadFileLoading: state.fileReducer.uploadFileLoading,
    uploadFileError: state.fileReducer.uploadFileLoading,
    preview: state.communicationReducer.preview,
    previewLoading: state.communicationReducer.previewLoading,
    previewError: state.communicationReducer.previewError,
    createId: state.communicationReducer.createId,
    createLoading: state.communicationReducer.createLoading,
    createError: state.communicationReducer.createError,
    sendLoading: state.communicationReducer.sendLoading,
    sendError: state.communicationReducer.sendError,
    sendDownloadLoading: state.communicationReducer.sendDownloadLoading,
    sendDownloadError: state.communicationReducer.sendDownloadError,
    projectsAndContracts: state.projectAndContractReducer.projectsAndContracts,
    projectsAndContractsLoading: state.projectAndContractReducer.projectsAndContractsLoading,
    projectsAndContractsErrors: state.projectAndContractReducer.projectsAndContractsErrors,
    defects: state.communicationReducer.defects,
    defectsLoading: state.communicationReducer.defectsLoading,
    defectsErrors: state.communicationReducer.defectsErrors,
    recipients: state.communicationReducer.recipients,
    recipientsLoading: state.communicationReducer.recipientsLoading,
    recipientsErrors: state.communicationReducer.recipientsErrors,
    defaultRecipients: state.communicationReducer.defaultRecipients,
    defaultRecipientsLoading: state.communicationReducer.defaultRecipientsLoading,
    defaultRecipientsErrors: state.communicationReducer.defaultRecipientsErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPreview: payload => dispatch(actionCreators.previewCommunication(payload)),
    onCreate: payload => dispatch(actionCreators.createCommunication(payload)),
    onSend: payload => dispatch(actionCreators.sendCommunication(payload)),
    onSendDownload: payload => dispatch(actionCreators.sendDownloadCommunication(payload)),
    onUploadFiles: payload => dispatch(actionCreators.uploadFiles(payload)),
    onUploadAllFiles: payload => dispatch(actionCreators.uploadAllFiles(payload)),
    onGetProjectsAndContracts: payload => dispatch(actionCreators.getProjectsAndContracts(payload)),
    onSuccess: payload => dispatch(actionCreators.setSuccessMessage(payload)),
    onGetDetailsAndPermissions: payload => dispatch(actionCreators.getPermissionsAndDetails(payload)),
    onSetError: payload => dispatch(actionCreators.setError(payload)),
    onGetDefects: payload => dispatch(actionCreators.getDefects(payload)),
    onGetRecipients: payload => dispatch(actionCreators.getRecipients(payload)),
    onDeleteRecipient: payload => dispatch(actionCreators.deleteRecipient(payload)),
    onGetDefaultRecipients: payload => dispatch(actionCreators.getDefaultRecipients(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(communicationSubmitForm)(NotificationCreateForm));
