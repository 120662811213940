import React from "react";

// @material-ui/core components

//icons
// import EmailOutlined from "@material-ui/icons/EmailOutlined";
import InputAdornment from "@material-ui/core/InputAdornment/index";
// import LockOutlined from "@material-ui/icons/LockOutlined";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import Person from "@material-ui/icons/Person";
// import SchoolOutlined from "@material-ui/icons/SchoolOutlined";
import Smartphone from "@material-ui/icons/Smartphone";
import WorkOutline from "@material-ui/icons/WorkOutline";

// custom components
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import Clearfix from "../../../components/Clearfix/Clearfix.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

// import { titles } from "../../../variables/general.jsx";

//custom styles
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import {verifyLength} from "../../../globals";
import {getApiRoot} from "../../../api";
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";

import withStyles from "@material-ui/core/styles/withStyles";

//Custom styles
const styles  = theme => ({
  ...userProfileStyles, ...validationFormsStyle,
    input: {
        display: 'none',
    },
    cardAlignment: {
        [theme.breakpoints.down("sm")]: {
            marginTop: 40
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 40
        }
    },
    gridPadding: {
        [theme.breakpoints.down("xs")]: {
            padding: 0
        }
    }
});

class UserProfile extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            userDetails: {},

            postData: {
                name : "",
                surname : "",
                contactNumber : "",
                companyName: "",
            },
            // possibleTitles: titles,

            nameState: "",
            surnameState: "",
            contactNumberState: "",
            companyNameState: "",

            changedFlag: false,
            loadingUpdateDetails: false,

            currentFile: {},
            currentFileName: "No file chosen",
        }
    }

    change = (event) => {
        const id = event.target.id;

        if(id === "firstNameInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({nameState: "success"});
            } else {
                this.setState({nameState: "error"});
            }

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        name: event.target.value
                    })
            });

            if(event.target.value !== this.state.userDetails.name) {
                this.setState({
                    changedFlag: true,
                });
            }
            else{
                this.setState({
                    changedFlag: false,
                    nameState: "",
                });
            }

        }
        else if(id === "lastNameInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({surnameState: "success"});
            } else {
                this.setState({surnameState: "error"});
            }

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        surname: event.target.value
                    })
            });

            if(event.target.value !== this.state.userDetails.surname) {
                this.setState({
                    changedFlag: true,
                });
            }
            else{
                this.setState({
                    changedFlag: false,
                    surnameState: "",
                });
            }
        }
        else if(id === "contactNumberInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({contactNumberState: "success"});
            } else {
                this.setState({contactNumberState: "error"});
            }


            this.setState({
                changedFlag: true,
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        contactNumber: event.target.value
                    })
            });

            if(event.target.value !== this.state.userDetails.contactNumber) {
                this.setState({
                    changedFlag: true,
                });
            }
            else{
                this.setState({
                    changedFlag: false,
                    contactNumberState: "",
                });
            }
        }
        else if(id === "companyNameInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({companyNameState: "success"});
            } else {
                this.setState({companyNameState: "error"});
            }

            this.setState({
                changedFlag: true,
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        companyName: event.target.value
                    })
            });

            if(event.target.value !== this.state.userDetails.companyName) {
                this.setState({
                    changedFlag: true,
                });
            }
            else{
                this.setState({
                    changedFlag: false,
                    companyNameState: "",
                });
            }
        }
        else if(id === "uploadSignatureInput"){

            if(event.target.files.length === 0){
                this.setState({
                    currentFileName: "No file chosen", currentFile: {}
                });
            }
            else{
                this.setState({
                    currentFileName: event.target.files[0].name, currentFile: event.target.files[0]
                });
            }
        }
    };

    updateProfile = () => {

        if(this.state.changedFlag){

            let flag = true;

            if (this.state.nameState === "error" || this.state.surnameState === "error" || this.state.contactNumberState === "error" || this.state.companyNameState === "error") {
                flag = false;
            }

            if(flag) {

                this.setState({loadingUpdateDetails: true});

                let data = JSON.stringify(this.state.postData);

                const headers = new Headers({
                    "Content-Type": "application/json",
                });

                // setTimeout(() => {
                    fetch(getApiRoot() + '/api/update_user_details', {
                        credentials: "include",
                        method: "POST",
                        headers,
                        body: data,
                    }).then(res => {

                        this.setState({
                            loadingUpdateDetails: false,
                            nameState: "",
                            surnameState: "",
                            contactNumberState: "",
                            companyNameState: "",
                        });

                        if (res.status === 200) {

                            //alert("Details successfully updated");
                            this.getUserDetails();
                            this.setState({changedFlag: false});
                        } else {
                            alert("Something went wrong - HTTP: " + res.status);
                        }

                    }).catch(error => alert(error));
                // }, 2000)
            }
            else{
                alert("One or more empty fields");
            }

        }
        else{
            alert("No changes detected");

            this.setState({
                nameState: "",
                surnameState: "",
                contactNumberState: "",
                companyNameState: "",
            });
        }
    };

    getUserDetails = () => {
        this.setState({
            isLoaded: false,
        });

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + "/api/user_details" , {
                credentials: "include",
                headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(
                    (result) => {

                        if(result){

                            this.setState({
                                isLoaded: true,
                                userDetails: result,
                                postData: {
                                    name: result.name,
                                    surname : result.surname,
                                    contactNumber : result.contactNumber,
                                    companyName: result.companyName,
                                }
                            });

                        }
                        else{

                            this.setState({
                                isLoaded: true,
                                userDetails: {
                                    name: "",
                                    surname : "",
                                    contactNumber : "",
                                    companyName: "",
                                },
                                postData: {
                                    name: "",
                                    surname : "",
                                    contactNumber : "",
                                    companyName: "",
                                }
                            });
                        }

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        // },2000)
    };


    componentDidMount() {
        this.getUserDetails();
    }

    render()
    {
        const {classes} = this.props;
        const { error, isLoaded, loadingUpdateDetails } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Shows loading wheel until initial API returns data or if an error occurs
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <div>
                    <GridContainer justify={"center"}>

                        <GridItem xs={12} sm={12} md={8} lg={8} className={classes.gridPadding}>
                            <Card className={classes.cardAlignment}>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <Person/>
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Edit Profile
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer justify={"center"}>
                                        {/*Title Input*/}
                                        {/*<GridItem xs={12} sm={12} md={6}>*/}
                                            {/*<CustomInput*/}

                                            {/*    labelText="Title"*/}

                                            {/*    formControlProps={{*/}
                                            {/*        fullWidth: true,*/}
                                            {/*        className: classes.customFormControlClasses*/}
                                            {/*    }}*/}
                                            {/*    inputProps={{*/}

                                            {/*        // value: this.state.userDetails.title,*/}

                                            {/*        startAdornment: (*/}
                                            {/*            <InputAdornment*/}
                                            {/*                position="start"*/}
                                            {/*                className={classes.inputAdornment}*/}
                                            {/*            >*/}
                                            {/*                <SchoolOutlined className={classes.inputAdornmentIcon}/>*/}
                                            {/*            </InputAdornment>*/}
                                            {/*        ),*/}
                                            {/*        type: "text"*/}
                                            {/*    }}*/}

                                            {/*/>*/}

                                        {/*    <FormControl fullWidth style={{marginTop: 11}}>*/}
                                        {/*        <InputLabel htmlFor="titleInput">*/}
                                        {/*            Title*/}
                                        {/*        </InputLabel>*/}
                                        {/*        <Select*/}
                                        {/*            native*/}
                                        {/*            value={"Mr"}*/}
                                        {/*            onChange={(event) => this.change(event)}*/}
                                        {/*            inputProps={{*/}
                                        {/*                id: 'titleInput',*/}
                                        {/*                // style: {fontSize: 12}*/}
                                        {/*            }}*/}
                                        {/*        >*/}
                                        {/*            {this.state.possibleTitles.map((item, index) => (*/}
                                        {/*                <option key={index}*/}
                                        {/*                        value={item}>{item}</option>*/}
                                        {/*            ))}*/}
                                        {/*        </Select>*/}
                                        {/*    </FormControl>*/}
                                        {/*</GridItem>*/}


                                        {/*First Name Input*/}
                                        <GridItem xs={12} sm={12} md={10}>
                                            <CustomInput
                                                labelText="First Name"
                                                success={this.state.nameState === "success"}
                                                error={this.state.nameState === "error"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}

                                                inputProps={{
                                                    id: "firstNameInput",
                                                    value: this.state.postData.name,
                                                    onChange: event => this.change(event),
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <PersonOutlined className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment:
                                                        this.state.nameState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    type: "text"
                                                }}
                                            />

                                        </GridItem>
                                        {/*Last Name Input*/}
                                        <GridItem xs={12} sm={12} md={10}>
                                            <CustomInput
                                                success={this.state.surnameState === "success"}
                                                error={this.state.surnameState === "error"}
                                                labelText="Last Name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.surname,
                                                    id: "lastNameInput",
                                                    onChange: event => this.change(event),
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <PersonOutlined className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment:
                                                        this.state.surnameState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    type: "text"
                                                }}
                                            />
                                        </GridItem>

                                    </GridContainer>
                                    <GridContainer justify={"center"}>
                                        {/*Email Input*/}
                                        {/*<GridItem xs={12} sm={12} md={6}>*/}
                                        {/*    <CustomInput*/}

                                        {/*        labelText="Email"*/}

                                        {/*        formControlProps={{*/}
                                        {/*            fullWidth: true,*/}
                                        {/*            className: classes.customFormControlClasses*/}
                                        {/*        }}*/}
                                        {/*        inputProps={{*/}

                                        {/*            value: this.state.userDetails.user.username,*/}

                                        {/*            startAdornment: (*/}
                                        {/*                <InputAdornment*/}
                                        {/*                    position="start"*/}
                                        {/*                    className={classes.inputAdornment}*/}
                                        {/*                >*/}
                                        {/*                    <EmailOutlined className={classes.inputAdornmentIcon}/>*/}
                                        {/*                </InputAdornment>*/}
                                        {/*            ),*/}
                                        {/*            type: "text"*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</GridItem>*/}
                                        {/*Company Input*/}
                                        <GridItem xs={12} sm={12} md={10}>
                                            <CustomInput
                                                success={this.state.companyNameState === "success"}
                                                error={this.state.companyNameState === "error"}
                                                labelText="Company"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    id: "companyNameInput",
                                                    onChange: event => this.change(event),
                                                    value: this.state.postData.companyName,
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <WorkOutline className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment:
                                                        this.state.companyNameState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    type: "text"
                                                }}
                                            />
                                        </GridItem>

                                        {/*Cell Number Input*/}
                                        <GridItem xs={12} sm={12} md={10}>
                                            <CustomInput
                                                success={this.state.contactNumberState === "success"}
                                                error={this.state.contactNumberState === "error"}
                                                labelText="Cellphone"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    id: "contactNumberInput",
                                                    onChange: event => this.change(event),
                                                    value: this.state.postData.contactNumber,
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Smartphone className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment:
                                                        this.state.contactNumberState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    type: "text"
                                                }}
                                            />
                                        </GridItem>

                                        {/*<GridItem xs={12} sm={12} md={12} lg={10}*/}
                                        {/*          style={{margin: "10px 15px 0 15px"}}>*/}
                                        {/*    <input*/}
                                        {/*        accept="*"*/}
                                        {/*        className={classes.input}*/}
                                        {/*        id="uploadSignatureInput"*/}
                                        {/*        type="file"*/}
                                        {/*        onChange={(event) => this.change(event)}*/}
                                        {/*    />*/}
                                        {/*    <label htmlFor="uploadSignatureInput">*/}
                                        {/*        <Button size={"sm"} variant="contained" component="span"*/}
                                        {/*                className={classes.button}>*/}
                                        {/*            Upload Signature*/}
                                        {/*        </Button>*/}
                                        {/*    </label>*/}
                                        {/*</GridItem>*/}

                                        {/*/!* Current Attachment Name *!/*/}
                                        {/*<GridItem xs={12} sm={12} md={12} lg={10}>*/}
                                        {/*    <p style={{*/}
                                        {/*        fontSize: 12,*/}
                                        {/*        // textAlign: "center",*/}
                                        {/*        margin: "5px 0 0 5px"*/}
                                        {/*    }}>{this.state.currentFileName}</p>*/}
                                        {/*</GridItem>*/}

                                    </GridContainer>
                                    {/*<GridContainer>*/}
                                    {/*    /!*Password Input*!/*/}
                                    {/*    <GridItem xs={12} sm={12} md={6}>*/}
                                    {/*        <CustomInput*/}

                                    {/*            labelText="Password"*/}

                                    {/*            formControlProps={{*/}
                                    {/*                fullWidth: true,*/}
                                    {/*                className: classes.customFormControlClasses*/}
                                    {/*            }}*/}
                                    {/*            inputProps={{*/}
                                    {/*                startAdornment: (*/}
                                    {/*                    <InputAdornment*/}
                                    {/*                        position="start"*/}
                                    {/*                        className={classes.inputAdornment}*/}
                                    {/*                    >*/}
                                    {/*                        <LockOutlined className={classes.inputAdornmentIcon}/>*/}
                                    {/*                    </InputAdornment>*/}
                                    {/*                ),*/}
                                    {/*                type: "password"*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </GridItem>*/}
                                    {/*    /!* Confirm password Input*!/*/}
                                    {/*    <GridItem xs={12} sm={12} md={6}>*/}
                                    {/*        <CustomInput*/}

                                    {/*            labelText="Confirm Password"*/}

                                    {/*            formControlProps={{*/}
                                    {/*                fullWidth: true,*/}
                                    {/*                className: classes.customFormControlClasses*/}
                                    {/*            }}*/}
                                    {/*            inputProps={{*/}
                                    {/*                startAdornment: (*/}
                                    {/*                    <InputAdornment*/}
                                    {/*                        position="start"*/}
                                    {/*                        className={classes.inputAdornment}*/}
                                    {/*                    >*/}
                                    {/*                        <LockOutlined className={classes.inputAdornmentIcon}/>*/}
                                    {/*                    </InputAdornment>*/}
                                    {/*                ),*/}
                                    {/*                type: "password"*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </GridItem>*/}
                                    {/*</GridContainer>*/}
                                        <div style={{position: "relative", float: "right", marginTop: 15}}>
                                            <Button color="primary" disabled={loadingUpdateDetails} className={classes.updateProfileButton} style={{float: "right"}} onClick={() => this.updateProfile()} >
                                                Update Profile
                                            </Button>
                                            {loadingUpdateDetails && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }
                                        </div>
                                        <Clearfix/>
                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>
                </div>
            );
        }
    }

}export default withStyles(styles)(UserProfile);

// }export default withStyles(userProfileStyles)(UserProfile);
