import React from "react";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import CustomInput from '../../../../components/CustomInput/CustomInput.jsx';
import InputAdornment from "@material-ui/core/InputAdornment/index";
import Close from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl/index";
import Select from "@material-ui/core/Select/index";
import { verifyLength, verifyEmail } from "../../../../globals";
import { getApiRoot } from "../../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import {verifyNumber} from "../../../../globals";
// import renderHTML from 'react-render-html';

import fileDownload from "js-file-download";
import {Document, Page} from "react-pdf";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";
// import Datetime from "react-datetime";

//Custom styles
const styles = theme => ({
    ...customSelectStyles,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});


class NewEarlyWarning extends React.Component{

    state = {

        allProjects: [],

        postData: {
            description: "",
            riskReductionMeetingRequired: "No",
            riskReductionMeetingDate: "",
            riskReductionMeetingTime: "",
            severityImpact: "Low",
            severityProbability : "Low",
            increasePrices : "No",
            increasePricesValue : "",
            impactDelayCompletion : "No",
            impactDelayCompletionValue : "",
            impactImpairPerformance : "No",
            impactDelayMeeting : "No",
            increaseContractorsTotalCost: "No",
            details: "",
            affectedActivities: "",
            type: "EW",
            contract: 0,
            othersToBeInvited: [],
            status: "Open",
            mitigationStrategies: "",
            previewAttachmentsString: "",
        },
        project: 0,

        // newMitigationStrategyState: "",
        // mitigationCostState: "",
        // newMitigationData: {
        //     risk: 0,
        //     strategy: "",
        //     costEstimation: 0,
        //     mitigatedPotentialImpact: {
        //         increaseTotalPrices: "No",
        //         delayCompletion: "No",
        //         delayMeetingKeyDate: "No",
        //         impairPerformance: "No",
        //         increaseContractorTotalCost: "No",
        //     }
        // },

        currentFile: {},
        currentFileName: "No file chosen",

        attachments: [],
        mitigationStrategies: [],

        descriptionState: "",
        currentChosenProject: "",
        newOtherEmail: "",
        newOtherName: "",
        newOtherOrganisation: "",

        isLoaded: false,
        error: false,
        submitEWDialogOpen: false,

        loadingSendEW: false,
        loadingPreview: false,

        showReductionMeetingDate: false,
        // openAddMitigationStrategyDialog: false,
        openAddAttachmentDialog: false,

        // attachmentAPICompleted: false,
        // strategiesAPICompleted: false,
        loadingSendDownloadEW: false,

        previewPDF: "",

    };

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };


    //function that handles when cancel button is clicked on dialog
    handleCancel = (type) => {

        //Preview Dialog
        if(type === "risk"){
            this.setState({
                submitEWDialogOpen: false
            });
        }
        //Upload Attachment Dialog
        else if(type === "addAttachment"){
            this.setState({
                openAddAttachmentDialog: false, currentFile: {}, currentFileName: "No file chosen"
            });
        }
        //New Mitigation Strategy Dialog
        // else if (type === "strategy"){
        //     this.setState(
        //         {
        //             newMitigationStrategyState: "",
        //             mitigationCostState: "",
        //             openAddMitigationStrategyDialog: false,
        //             newMitigationData: {
        //                 risk: 0,
        //                 strategy: "",
        //                 costEstimation: 0,
        //                 mitigatedPotentialImpact: {
        //                     increaseTotalPrices: "No",
        //                     delayCompletion: "No",
        //                     delayMeetingKeyDate: "No",
        //                     impairPerformance: "No",
        //                     increaseContractorTotalCost: "No",
        //                 }
        //             },
        //
        //         });
        // }

    };

    //function that handles when a dialog is opened
    handleOpen = (type) => {

        //Preview Dialog
        if(type === "risk"){

            let flag = true;

            if (this.state.descriptionState === "") {
                this.setState({ descriptionState: "error" });
                flag = false;
            }

            if (this.state.descriptionState === "error") {
                flag = false;
            }

            if(this.state.postData.contract === 0){
                flag = false;
            }

            if(flag) {

                //If risk reduction meeting is required, ensures that there is a date
                if(this.state.showReductionMeetingDate){
                    if (verifyLength(this.state.postData.riskReductionMeetingDate, 1) && verifyLength(this.state.postData.riskReductionMeetingTime, 1)) {
                        this.previewEarlyWarningAPI();
                    }
                    else{
                        alert("When a Reduction Meeting is required, the date and time should be specified");
                    }
                }
                else{
                    this.previewEarlyWarningAPI();
                }

            }
            else {
                alert("Description and Contract are required fields");
            }

        }
        //upload attachment dialog
        else if(type === "addAttachment"){
            this.setState({
                openAddAttachmentDialog: true
            });
        }
        //new mitigation strategy dialog
        // else if(type === "strategy"){
        //     this.setState({
        //         openAddMitigationStrategyDialog: true
        //     });
        // }


    };

    //function that handles adding an attachment to the attachments array
    addAttachment = (event) => {

        if(this.state.currentFileName === "No file chosen"){
            event.preventDefault();
            alert("Please choose an attachment");
            return;
        }

        let arr = this.state.attachments;

        let obj = {
            fileName: this.state.currentFileName,
            file: this.state.currentFile
        };

        arr.push(obj);

        this.setState({
            attachments: arr, openAddAttachmentDialog: false, currentFileName: "No file chosen", currentFile: {}
        });

        this.createAttachmentsString(arr);

        event.preventDefault();
    };

    //function that handles removing an attachment from the attachments array
    removeAttachment = (index) => {
        let arr = this.state.attachments;
        arr.splice(index, 1);

        this.setState({
            attachments: arr
        });

        this.createAttachmentsString(arr);
    };

    createAttachmentsString = (arr) => {

        if(arr.length !== 0) {

            let attachmentsString = "";

            arr.forEach(object => {
                attachmentsString += object.fileName + "; ";
            });

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  previewAttachmentsString: attachmentsString}),
            });
        }
        else{
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData, {  previewAttachmentsString: ""}),
            });
        }
    };

    //function that handles adding a mitigation strategy
    // addMitigationStrategy = () => {
    //
    //     let arr = this.state.mitigationStrategies;
    //
    //     arr.push(this.state.newMitigationData);
    //
    //     this.setState(
    //         {
    //             mitigationStrategies: arr,
    //             newMitigationStrategyState: "",
    //             mitigationCostState: "",
    //             openAddMitigationStrategyDialog: false,
    //             newMitigationData: {
    //                 risk: 0,
    //                 strategy: "",
    //                 costEstimation: 0,
    //                 mitigatedPotentialImpact: {
    //                     increaseTotalPrices: "No",
    //                     delayCompletion: "No",
    //                     delayMeetingKeyDate: "No",
    //                     impairPerformance: "No",
    //                     increaseContractorTotalCost: "No",
    //                 },
    //             },
    //         });
    //
    // };

    //function that handles removing a mitigation strategy
    // removeStrategy = (index) => {
    //     let arr = this.state.mitigationStrategies;
    //     arr.splice(index, 1);
    //
    //     this.setState({
    //         mitigationStrategies: arr
    //     });
    // };

    //function that handles adding a email to othersToBeInvited field
    handleAddOthersToBeInvited = () => {

        if(!verifyLength(this.state.newOtherEmail, 1) || !verifyLength(this.state.newOtherName, 1) || !verifyLength(this.state.newOtherOrganisation, 1)){
            alert("One or more empty fields");
        }
        else if(!verifyEmail(this.state.newOtherEmail)){
            alert("Invalid Email: " + this.state.newOtherEmail);
        }
        else{

            let arr = this.state.postData.othersToBeInvited;

            let obj ={
                name: this.state.newOtherName,
                organisation: this.state.newOtherOrganisation,
                email: this.state.newOtherEmail,
            };

            arr.push(obj);

            this.setState(
                {
                    newOtherEmail: "",
                    newOtherName: "",
                    newOtherOrganisation: "",
                    postData: Object.assign(
                        {},
                        this.state.postData,
                        {   othersToBeInvited: arr }),
                }
            );

        }
    };

    //function that handles removing a email to othersToBeInvited field
    handleRemoveOthersToBeInvited = (index) => {

        let arr = this.state.postData.othersToBeInvited;
        arr.splice(index, 1);

        this.setState(
            {
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {   othersToBeInvited: arr }),
            }
        );
    };

    //function that handles changing an attachment
    changeAttachment = (event) => {

        if(event.target.files.length === 0){
            this.setState({
                currentFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }

    };

    //function that handles changing a mitigation strategy's input fields
    // handleChangeMitigationStrategy = (event) => {
    //
    //     const id = event.target.id;
    //
    //     //strategy input
    //     if(id.toString() === "addStrategyInput")
    //     {
    //         if(verifyLength(event.target.value, 1)){
    //             this.setState({newMitigationStrategyState: "success"});
    //         }
    //         else{
    //             this.setState({newMitigationStrategyState: "error"});
    //         }
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     strategy: event.target.value}),
    //         });
    //     }
    //     //mitigation cost input
    //     else if(id.toString() === "mitigationCostEditInput")
    //     {
    //         if(event.target.value === ""){
    //             this.setState({mitigationCostState: ""});
    //         }
    //         else if(verifyNumber(event.target.value)){
    //             this.setState({mitigationCostState: "success"});
    //         }
    //         else{
    //             this.setState({mitigationCostState: "error"});
    //         }
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     costEstimation: event.target.value}),
    //         });
    //     }
    //     //increase total prices dropdown
    //     else if(id.toString() === "mitigatedIncreaseTotalPricesEditInput")
    //     {
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { increaseTotalPrices: event.target.value}),
    //                 })
    //         });
    //     }
    //     //delay completion dropdown
    //     else if(id.toString() === "mitigatedCompletionDelayEditInput")
    //     {
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { delayCompletion: event.target.value}),
    //                 })
    //         });
    //     }
    //     //impair performance dropdown
    //     else if(id.toString() === "mitigatedImpairPerformanceEditInput")
    //     {
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { impairPerformance: event.target.value}),
    //                 })
    //         });
    //     }
    //
    //     //delay meeting a key date dropdown
    //     else if(id.toString() === "mitigatedDelayKeyDatesEditInput")
    //     {
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { delayMeetingKeyDate: event.target.value}),
    //                 })
    //         });
    //     }
    //     //increase contractors total cost
    //     else if(id.toString() === "mitigatedIncreaseContractorCostEditInput"){
    //
    //         this.setState({
    //             newMitigationData: Object.assign(
    //                 {},
    //                 this.state.newMitigationData,
    //                 {
    //                     mitigatedPotentialImpact: Object.assign(
    //                         {},
    //                         this.state.newMitigationData.mitigatedPotentialImpact,
    //                         { increaseContractorTotalCost: event.target.value}),
    //                 })
    //         });
    //     }
    // };


    //function that handles onChange of new ew input fields
    change = (event) => {

        const id = event.target.id;

        //Description Input
        if(id === "descriptionInput"){

            if (verifyLength(event.target.value, 1)) {
                this.setState({descriptionState: "success"});
            } else {
                this.setState({descriptionState: "error"});
            }

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {description: event.target.value}),
            });
        }
        //Risk Reduction Meeting Required Input
        else if(id === "riskReductionMeetingRequiredInput"){

            if(event.target.value.toLowerCase() === "yes"){
                this.setState({ postData: Object.assign(
                        {},
                        this.state.postData,
                        {   riskReductionMeetingRequired: event.target.value }),
                    showReductionMeetingDate: true,
                });
            }
            else{
                this.setState({ postData: Object.assign(
                        {},
                        this.state.postData,
                        {   riskReductionMeetingRequired: event.target.value,  riskReductionMeetingDate: "", riskReductionMeetingTime: "" }),
                    showReductionMeetingDate: false,
                });
            }


        }
        //Risk Reduction Meeting Date Input
        else if(id === "riskReductionMeetingDateInput"){
            this.setState({ postData: Object.assign(
                    {},
                    this.state.postData,
                    {   riskReductionMeetingDate: event.target.value }),
            });
        }
        //Risk Reduction Meeting Time Input
        else if(id === "riskReductionMeetingTimeInput") {
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {riskReductionMeetingTime: event.target.value}),
            });
        }
        //Project Input
        else if(id === "projectInput"){

            let index = this.state.allProjects.findIndex(o => o.project.id === parseInt(event.target.value));

            this.setState({
                project: event.target.value, currentChosenProject: index
            });

        }
        //Contract Input
        else if(id === "contractInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                contract: parseInt(event.target.value)}),
            });
        }
        //Severity Impact Input
        else if(id === "severityImpactInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                severityImpact: event.target.value }),
            });
        }
        //Severity Probability Input
        else if(id === "severityProbabilityInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                severityProbability: event.target.value }),
            });
        }
        //Increase Prices Input
        else if(id === "increasePricesInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {increasePrices: event.target.value}),
            });
        }
        //Increase Prices Value Input
        else if(id === "increasePricesValueInput"){

            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { increasePricesValue: event.target.value }),
            });
        }
        //Impact Delay Completion Input
        else if(id === "impactDelayCompletionInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { impactDelayCompletion: event.target.value }),
            });
        }
        //Impact Delay Completion Value Input
        else if(id === "impactDelayCompletionValueInput"){

            this.setState({ postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                impactDelayCompletionValue: event.target.value }),
            });
        }
        //Impact Impair Performance Input
        else if(id === "impactImpairPerformanceInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { impactImpairPerformance: event.target.value }),
            });
        }
        //Impact Delay Meeting Input
        else if(id === "impactDelayMeetingInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { impactDelayMeeting: event.target.value }),
            });
        }
        //Increase Contractors Total Cost Input
        else if(id === "increaseContractorsTotalCostInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                increaseContractorsTotalCost: event.target.value }),
            });
        }
        //Details Input
        else if(id === "detailsInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                details: event.target.value }),
            });
        }
        //Affected Activities Input
        else if(id === "affectedActivitiesInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                affectedActivities: event.target.value}),
            });
        }
        //Mitigation Strategy Input
        else if(id === "strategyInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    {
                        mitigationStrategies: event.target.value}),
            });
        }

    };

    //function that retrieves the current projects from the projects_contracts_list api to choose from
    getAllProjects = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/projects_contracts_list", {
            credentials: "include",
            headers,
            method: "GET"
            ,})
            .then(res => {

                if(res.status === 200){
                    return res.json();
                }
            })
            .then(
                (result) => {

                    if(result !== undefined)
                    {
                        this.setState({
                            isLoaded: true,
                            allProjects: result,
                            project: result[0].project.id,
                            postData: Object.assign(
                                {},
                                this.state.postData,
                                {
                                    contract: result[0].contracts[0].id
                                }),
                            currentChosenProject: 0

                        });
                    }


                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

    //function that calls preview_early_warning api with postData
    previewEarlyWarningAPI = () => {

        this.setState({
            loadingPreview: true
        });

        let data = JSON.stringify(this.state.postData);

        // alert(data);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/preview_early_warning', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            if(result !== undefined){
                this.setState({
                    previewPDF: result, submitEWDialogOpen: true, loadingPreview: false
                });
            }

        }).catch(error => alert(error));
        // }, 2000)

    };

    //function that handles calling the creat_early_warning api request if send or send & download is clicked
    createEW = (event, type) => {

        if(type === "send"){
            this.setState({ loadingSendEW : true });
        }
        else if(type === "sendDownload"){
            this.setState({ loadingSendDownloadEW : true });
        }

        event.preventDefault();

        let data = JSON.stringify(this.state.postData);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/create_risk', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.json();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);

                if(type === "send"){
                    this.setState({ loadingSendEW : false });
                }
                else if(type === "sendDownload"){
                    this.setState({ loadingSendDownloadEW : false });
                }
            }

        }).then(result => {

            if(result !== undefined){
                this.uploadFiles(result.id, type);
                // this.addMitigationStrategies(result.id, type);
            }
        }).catch(error => alert(error));
        // }, 2000)


    };

    //function that calls upload_files api request for attachments
    uploadFiles = (id, type) => {

        if(this.state.attachments.length !== 0) {

            let data = new FormData();
            data.append('id', id);
            data.append('type', "risk");

            this.state.attachments.forEach(object => {
                data.append('file', object.file);
            });

            // setTimeout(() => {
            fetch(getApiRoot() + '/api/upload_files', {
                credentials: "include",
                method: "POST",
                body: data,
            }).then(res => {

                if (res.status === 200) {

                    // this.setState({
                    //     attachmentAPICompleted: true
                    // });

                    this.sendAPI(id, type);

                }
                else {
                    alert("Something went wrong - HTTP: " + res.status);

                    // this.setState({
                    //     attachmentAPICompleted: true
                    // });

                    if(type === "sendDownload"){
                        this.setState({ loadingSendDownloadEW : false });
                    }
                    else{
                        this.setState({ loadingSendEW : false });
                    }
                }

            }).catch(error => alert(error));
            // }, 2000);
        }
        else{
            this.sendAPI(id, type);
        }

    };

    //function that handles looping through all strategies and calls the create_mitigation_strategy api for each 
    // addMitigationStrategies = (id, type) => {
    //
    //     if(this.state.mitigationStrategies.length !== 0){
    //
    //         this.state.mitigationStrategies.forEach((strategy, index) => {
    //             strategy.risk = id;
    //
    //             this.setState({
    //                 currentMitigationStrategyId :index,
    //             });
    //
    //             this.addNewMitigationStrategyAPI(strategy, type, id);
    //         });
    //     }
    //     else{
    //         this.setState({
    //             strategiesAPICompleted: true
    //         });
    //
    //         if(this.state.attachmentAPICompleted){
    //             this.sendAPI(id, type);
    //         }
    //
    //
    //     }
    //
    // };

    //function that handles calling the create_mitigation_strategy for each strategy
    // addNewMitigationStrategyAPI = (strategy, type, id) => {
    //
    //     let data = JSON.stringify(strategy);
    //
    //     const headers = new Headers({
    //         "Content-Type": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //     fetch(getApiRoot() + '/api/create_mitigation_strategy', {
    //         credentials: "include",
    //         method: "POST",
    //         headers,
    //         body: data,
    //     }).then(res => {
    //
    //         if(res.status === 200){
    //
    //             let arrLength = this.state.mitigationStrategies.length;
    //
    //             if( (arrLength-1) === this.state.currentMitigationStrategyId){
    //
    //                 this.setState({
    //                     strategiesAPICompleted: true
    //                 });
    //
    //                 if(this.state.attachmentAPICompleted){
    //                     this.sendAPI(id, type);
    //                 }
    //
    //             }
    //         }
    //         else{
    //             alert("Something went wrong - HTTP: " + res.status);
    //
    //             this.setState({
    //                 strategiesAPICompleted: false
    //             });
    //
    //             if(type === "sendDownload"){
    //                 this.setState({ loadingSendDownloadEW : false });
    //             }
    //             else{
    //                 this.setState({ loadingSendEW : false });
    //             }
    //         }
    //
    //     }).catch(error => alert(error));
    //     // }, 2000)
    //
    // };

    //implementation of send_early_warning and send_early_warning_and_download api
    sendAPI = (id, type) => {

        let obj = {
            risk: id
        };

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        let path = "/api/";

        if(type === "send"){
            path += "send_early_warning";
        }
        else if(type === "sendDownload"){
            path += "send_early_warning_and_download";
        }

        let fileName = "Early Warning Notification.pdf";

        // setTimeout(() => {
            fetch(getApiRoot() + path, {
                credentials: "include",
                method: "POST",
                headers,
                body: data,
            }).then(res => {

                if(res.status === 200){

                    if(type === "sendDownload"){

                        if( res.headers.get('Content-Disposition') !== null) {
                          let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                          if(strArr.length > 1)
                            fileName = strArr[1];
                        }

                        return res.blob();
                    }

                }
                else{
                    alert("Something went wrong - HTTP: " + res.status);

                    if(type === "sendDownload"){
                        this.setState({ loadingSendDownloadEW : false });
                    }
                    else{
                        this.setState({ loadingSendEW : false });
                    }
                }

            }).then(result => {

                if(type === "sendDownload"){
                    if(result !== undefined) {
                        fileDownload(result, fileName);

                        let path = "/app/taskView";

                        this.props.history.push({
                            pathname: path
                        });
                    }
                }
                else{

                    let path = "/app/taskView";

                    this.props.history.push({
                        pathname: path
                    });

                }
            }).catch(error => alert(error));
        // }, 2000)


    };

    //function that runs before component is rendered
    componentDidMount() {
        this.getAllProjects();
    }


    render(){
        const { classes } = this.props;
        const { error, isLoaded, loadingSendEW,loadingSendDownloadEW, loadingPreview, numPages } = this.state;

        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Shows loading wheel until initial API returns data or if an error occurs
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={8} className={classes.alignTable}>
                        <Card className={classes.cardAlignment} style={{paddingTop: 15}}>
                            <CardBody>
                                <form>
                                    <GridContainer>

                                        {/*project input*/}

                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Project</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>

                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.project}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'projectInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option disabled value={0}>Choose project</option>
                                                    {this.state.allProjects ? this.state.allProjects.map((item, index) => (
                                                        <option key={index}
                                                                value={item.project.id}>{item.project.title}</option>
                                                    )) : ""}
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*contract input*/}
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Contract</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>

                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.contract}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'contractInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option disabled value={0}>Choose contract</option>
                                                    {this.state.currentChosenProject !== "" && this.state.allProjects ? this.state.allProjects[this.state.currentChosenProject].contracts.map((item, index) => (
                                                        <option key={index}
                                                                value={item.id}>{item.number}</option>
                                                    )) : ""}
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/* Description Input */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="descriptionInput"
                                                labelText="Early Warning Short Description"
                                                success={this.state.descriptionState === "success"}
                                                error={this.state.descriptionState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.description,
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.descriptionState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>
                                                        ) : (
                                                            undefined
                                                        )
                                                }}
                                            />
                                        </GridItem>

                                        {/* Details Group */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                            <p><strong>Details</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/*Risk details input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="detailsInput"
                                                labelText="Risk Details"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.details,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/*Risk Affected Activities input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="affectedActivitiesInput"
                                                labelText="Affected Activities"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.affectedActivities,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>

                                        {/*severity input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "20px 0 10px 0"}}/>
                                            <p><strong>Risk Severity</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p>Impact</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.severityImpact}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'severityImpactInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"low"}>Low</option>
                                                    <option value={"medium"}>Medium</option>
                                                    <option value={"high"}>High</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr/>
                                            <p>Probability</p>
                                        </GridItem>

                                        {/* Severity Probability input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.severityProbability}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'severityProbabilityInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"low"}>Low</option>
                                                    <option value={"medium"}>Medium</option>
                                                    <option value={"high"}>High</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*potential impact*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "50px 0 10px 0"}}/>
                                            <p><strong>Potential Impact</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/*Increase the total of the Prices Input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p>Increase the total of the Prices</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.increasePrices}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'increasePricesInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*Increase the total of the Prices Value Input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                id="increasePricesValueInput"
                                                labelText="Broad Estimate Value (ZAR)"
                                                labelProps={{
                                                    style: {fontSize: 12}
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.increasePricesValue,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                    style: {fontSize: 12},
                                                }}
                                            />
                                        </GridItem>

                                        {/*Delay Completion Input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr/>
                                            <p>Delay Completion</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.impactDelayCompletion}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'impactDelayCompletionInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*Delay Completion Value Input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                id="impactDelayCompletionValueInput"
                                                labelText="Broad Estimate Value (days)"
                                                labelProps={{
                                                    style: {fontSize: 12}
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state.postData.impactDelayCompletionValue,
                                                    onChange: (event) => this.change(event),
                                                    type: "number",
                                                    style: {fontSize: 12},
                                                }}
                                            />
                                        </GridItem>

                                        {/*Delay meeting Input*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr/>
                                            <p>Delay meeting a Key Date</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.impactDelayMeeting}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'impactDelayMeetingInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>

                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/* Impair performance Input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr/>
                                            <p>Impair performance of the <span
                                                style={{fontStyle: "italic"}}>works</span> in use</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.impactImpairPerformance}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'impactImpairPerformanceInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <hr/>

                                        {/* Increase the Contractor's total cost Input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr/>
                                            <p>Increase the <span
                                                style={{fontStyle: "italic"}}>Contractor's</span> total cost</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.increaseContractorsTotalCost}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'increaseContractorsTotalCostInput',
                                                        // style: {fontSize: 12}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>


                                        {/* Mitigation strategies */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "50px 0 10px 0"}}/>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                                    <p style={{marginBottom: 0}}><strong>Mitigation Strategies</strong></p>
                                                </GridItem>

                                                {/*Add Mitigation strategy button*/}
                                                {/*<GridItem xs={4} sm={4} lg={4}>*/}
                                                {/*    <Add onClick={() => this.handleOpen("strategy")}*/}
                                                {/*         style={{cursor: "pointer", fontSize: 16, float: "right", marginTop: 3}}/>*/}
                                                {/*</GridItem>*/}
                                            </GridContainer>

                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="strategyInput"
                                                labelText="Strategies"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    multiline: true,
                                                    rows: 5,
                                                    style: {marginBottom: 15},
                                                    value: this.state.postData.mitigationStrategies,
                                                    onChange: (event) => this.change(event),
                                                    type: "text",
                                                }}
                                            />
                                        </GridItem>


                                        {/* Current Mitigation strategies list */}
                                        {/*<GridItem xs={12} lg={12}>*/}
                                        {/*    <List>*/}
                                        {/*        {*/}
                                        {/*            this.state.mitigationStrategies.length ? this.state.mitigationStrategies.sort(function(a,b){*/}
                                        {/*                    return parseInt(a.id) - parseInt(b.id);*/}
                                        {/*                }).map((item, index) =>*/}
                                        {/*                    <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}*/}
                                        {/*                              key={index}>*/}
                                        {/*                        {item.strategy}*/}
                                        {/*                        <ListItemSecondaryAction>*/}
                                        {/*                            <Close className={classes.danger}*/}
                                        {/*                                   style={{cursor: "pointer", fontSize: 16, marginTop: 5}}*/}
                                        {/*                                   onClick={() => this.removeStrategy(index)}/>*/}
                                        {/*                        </ListItemSecondaryAction>*/}
                                        {/*                    </ListItem>)*/}
                                        {/*                : "No Mitigation Strategies"*/}
                                        {/*        }*/}
                                        {/*    </List></GridItem>*/}


                                        {/* Attachments */}

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "20px 0 10px 0"}}/>
                                            <GridContainer>
                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                    <p style={{marginBottom: 0}}><strong>Attachments</strong></p>
                                                </GridItem>
                                                {/* Add Attachment button */}
                                                <GridItem xs={4} sm={4} lg={4}>
                                                    <Add style={{cursor: "pointer", fontSize: 16, float: "right", marginTop: 3}}
                                                         onClick={() => this.handleOpen("addAttachment")}/>
                                                </GridItem>
                                            </GridContainer>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>

                                        {/* Current Attachments list */}
                                        <GridItem xs={12} lg={12}>
                                            <List>
                                                {
                                                    this.state.attachments.length ? this.state.attachments.sort(function(a,b){
                                                            return parseInt(a.id) - parseInt(b.id);
                                                        }).map((item, index) =>
                                                            <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                      key={index}>
                                                                {item.fileName}
                                                                <ListItemSecondaryAction>
                                                                    <Close className={classes.danger}
                                                                        style={{cursor: "pointer", fontSize: 16, marginTop: 5}}
                                                                        onClick={() => this.removeAttachment(index)}/>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>)
                                                        : "No Attachments"
                                                }
                                            </List>
                                        </GridItem>

                                        {/*<hr/>*/}

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "50px 0 10px 0"}}/>
                                            <p><strong>Risk Reduction Meeting</strong></p>
                                            <hr style={{margin: "10px 0 30px 0"}}/>
                                        </GridItem>

                                        {/* Risk Reduction Meeting Required Input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <p>Is Meeting Required</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <FormControl fullWidth>
                                                <Select
                                                    native
                                                    value={this.state.postData.riskReductionMeetingRequired}
                                                    onChange={(event) => this.change(event)}
                                                    inputProps={{
                                                        id: 'riskReductionMeetingRequiredInput',
                                                        style: {fontSize: 14, marginTop: 0}
                                                    }}
                                                >
                                                    <option value={"No"}>No</option>
                                                    <option value={"Yes"}>Yes</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/*<CustomInput*/}
                                        {/*    id="riskReductionMeetingDateInput"*/}
                                        {/*    labelText="Risk Reduction Meeting Date"*/}
                                        {/*    labelProps={{*/}
                                        {/*        shrink: true*/}
                                        {/*    }}*/}
                                        {/*    formControlProps={{*/}
                                        {/*        fullWidth: true*/}
                                        {/*    }}*/}
                                        {/*    inputProps={{*/}
                                        {/*        style: {marginBottom: 15},*/}
                                        {/*        value: this.state.postData.riskReductionMeetingDate,*/}
                                        {/*        // onChange={this.handleInputChange}*/}
                                        {/*        onChange: event =>*/}
                                        {/*            this.change(event),*/}
                                        {/*        type: "date",*/}
                                        {/*    }}*/}
                                        {/*/> */}

                                        {/* Risk Reduction Meeting Date Input */}
                                        <GridItem xs={12} sm={12} lg={12} style={{marginTop: 15}}>
                                            {this.state.showReductionMeetingDate &&
                                            <CustomInput
                                                id="riskReductionMeetingDateInput"
                                                labelText="Risk Reduction Meeting Date"
                                                labelProps={{
                                                    shrink: true,
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{

                                                    value: this.state.postData.riskReductionMeetingDate,
                                                    // onChange={this.handleInputChange}
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "date",
                                                }}
                                            />
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            {this.state.showReductionMeetingDate &&
                                            <CustomInput
                                                id="riskReductionMeetingTimeInput"
                                                labelText="Risk Reduction Meeting Time"
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style: {marginBottom: 15},
                                                    // value: this.state.postData.riskReductionMeetingTime,
                                                    // onChange={this.handleInputChange}
                                                    onChange: event =>
                                                        this.change(event),
                                                    type: "time",
                                                }}
                                            />
                                            }
                                        </GridItem>

                                        {/* Others to be invited Input */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <hr style={{margin: "20px 0 10px 0"}}/>
                                            <p><strong>Others To Be Invited</strong></p>
                                            <hr style={{margin: "10px 0 10px 0"}}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} lg={12}>

                                            {/* Current Others To Be Invited */}
                                            <div style={{marginBottom: 15}}>
                                                <List>
                                                    {
                                                        this.state.postData.othersToBeInvited === undefined || this.state.postData.othersToBeInvited.length === 0 ?
                                                            <p>None</p> : this.state.postData.othersToBeInvited.map((item, index) =>
                                                                <ListItem
                                                                    style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                                    key={index}>

                                                                    <GridContainer>
                                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                                            { item.name + " (" +item.email + ")"}
                                                                        </GridItem>
                                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                                            Organisation - {item.organisation}
                                                                        </GridItem>
                                                                    </GridContainer>

                                                                    <ListItemSecondaryAction>
                                                                        <Close className={classes.danger} style={{
                                                                            cursor: "pointer",
                                                                            fontSize: 16,
                                                                            marginTop: 5
                                                                        }}
                                                                               onClick={() => this.handleRemoveOthersToBeInvited(index)}/>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>)
                                                    }

                                                </List>
                                            </div>
                                        </GridItem>

                                        {/* New Other To Be Invited Input */}

                                        <Card style={{margin: "0 15px"}}>
                                            <CardBody>

                                                <GridContainer >
                                                    <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center"}}>
                                                        <Add onClick={() => this.handleAddOthersToBeInvited()}
                                                             style={{
                                                                 cursor: "pointer",
                                                                 // marginTop: 25,
                                                                 float: "right",
                                                                 fontSize: 16,
                                                             }}/>
                                                    </GridItem>
                                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                                        <CustomInput
                                                            id="newOtherNameInput"
                                                            labelText="Name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newOtherName,
                                                                onChange: (event) =>
                                                                    this.setState({newOtherName: event.target.value}),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>

                                                    <GridItem xs={6} sm={6} md={6} lg={6}>
                                                        <CustomInput
                                                            id="newOtherOrganisationInput"
                                                            labelText="Organisation"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newOtherOrganisation,
                                                                onChange: (event) =>
                                                                    this.setState({newOtherOrganisation: event.target.value}),
                                                                type: "text",
                                                            }}
                                                        />
                                                    </GridItem>



                                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                                        <CustomInput
                                                            id="newOtherInput"
                                                            labelText="Email"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                value: this.state.newOtherEmail,
                                                                onChange: (event) =>
                                                                    this.setState({newOtherEmail: event.target.value}),
                                                                type: "email",
                                                                style: {marginBottom: 25}
                                                            }}
                                                        />
                                                    </GridItem>


                                                </GridContainer>
                                            </CardBody>
                                        </Card>

                                        <GridItem xs={12} sm={12} lg={12}>
                                            <hr style={{margin: "30px 0 30px 0"}}/>
                                        </GridItem>


                                        {/* Preview Button */}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <div style={{float: "right", position: "relative"}}>
                                                <Button color="primary" disabled={loadingPreview} onClick={() => this.handleOpen("risk")}>Preview</Button>
                                                {loadingPreview && <CircularProgress size={24} style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: -12,
                                                    marginLeft: -12
                                                }}/>}
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/* Add mitigation strategy Dialog */}
                    {/*<Dialog*/}
                    {/*    open={this.state.openAddMitigationStrategyDialog}*/}
                    {/*    onClose={() => this.handleCancel("strategy")}*/}
                    {/*    aria-labelledby="addStrategyDialogTitle"*/}
                    {/*>*/}
                    {/*    <DialogTitle id="addStrategyDialogTitle">Add mitigation strategy</DialogTitle>*/}
                    {/*    <DialogContent style={{width: 600}}>*/}

                    {/*        /!* Strategy Description Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            autoFocus*/}
                    {/*            id="addStrategyInput"*/}
                    {/*            labelText="Strategy"*/}
                    {/*            success={this.state.newMitigationStrategyState === "success"}*/}
                    {/*            error={this.state.newMitigationStrategyState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*                style: {marginBottom: 0}*/}

                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                multiline: true,*/}
                    {/*                rows: 2,*/}
                    {/*                value: this.state.newMitigationData.strategy,*/}
                    {/*                onChange: this.handleChangeMitigationStrategy,*/}
                    {/*                type: "text",*/}
                    {/*            }}*/}
                    {/*        />*/}

                    {/*        /!* Estimated cost of mitigation Input *!/*/}
                    {/*        <CustomInput*/}
                    {/*            id="mitigationCostEditInput"*/}
                    {/*            labelText="Estimated cost of mitigation (ZAR)"*/}
                    {/*            success={this.state.mitigationCostState === "success"}*/}
                    {/*            error={this.state.mitigationCostState === "error"}*/}
                    {/*            labelProps={{}}*/}
                    {/*            formControlProps={{*/}
                    {/*                fullWidth: true,*/}
                    {/*            }}*/}
                    {/*            inputProps={{*/}
                    {/*                value: this.state.newMitigationData.costEstimation,*/}
                    {/*                onChange: this.handleChangeMitigationStrategy,*/}
                    {/*                type: "text"*/}
                    {/*            }}*/}
                    {/*        />*/}

                    {/*        /!* Mitigated & Unmitigated Impact *!/*/}
                    {/*        /!*<Card>*!/*/}
                    {/*        /!*    <CardBody>*!/*/}
                    {/*        <GridContainer>*/}

                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "20px 0 10px 0"}}/>*/}
                    {/*            </GridItem>*/}

                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <p style={{margin: 0}}><strong>Mitigated Impact</strong></p>*/}
                    {/*            </GridItem>*/}

                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*            </GridItem>*/}

                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Increase the total of the Prices</strong></p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Mitigated Impact - Increase the total of the Prices *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <FormControl fullWidth>*/}
                    {/*                    <Select*/}
                    {/*                        native*/}
                    {/*                        value={this.state.newMitigationData.mitigatedPotentialImpact.increaseTotalPrices}*/}
                    {/*                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                        inputProps={{*/}
                    {/*                            id: 'mitigatedIncreaseTotalPricesEditInput',*/}
                    {/*                            style: {fontSize: 12}*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={"No"}>No</option>*/}
                    {/*                        <option value={"Yes"}>Yes</option>*/}
                    {/*                    </Select>*/}
                    {/*                </FormControl>*/}
                    {/*            </GridItem>*/}


                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Delay Completion</strong></p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Mitigated Impact - Delay Completion *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <FormControl fullWidth>*/}
                    {/*                    <Select*/}
                    {/*                        native*/}
                    {/*                        value={this.state.newMitigationData.mitigatedPotentialImpact.delayCompletion}*/}
                    {/*                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                        inputProps={{*/}
                    {/*                            id: 'mitigatedCompletionDelayEditInput',*/}
                    {/*                            style: {fontSize: 12}*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={"No"}>No</option>*/}
                    {/*                        <option value={"Yes"}>Yes</option>*/}
                    {/*                    </Select>*/}
                    {/*                </FormControl>*/}
                    {/*            </GridItem>*/}


                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Delay meeting a Key Date</strong></p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Mitigated Impact - Delay meeting a Key Date *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <FormControl fullWidth>*/}
                    {/*                    <Select*/}
                    {/*                        native*/}
                    {/*                        value={this.state.newMitigationData.mitigatedPotentialImpact.delayMeetingKeyDate}*/}
                    {/*                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                        inputProps={{*/}
                    {/*                            id: 'mitigatedDelayKeyDatesEditInput',*/}
                    {/*                            style: {fontSize: 12}*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={"No"}>No</option>*/}
                    {/*                        <option value={"Yes"}>Yes</option>*/}

                    {/*                    </Select>*/}
                    {/*                </FormControl>*/}
                    {/*            </GridItem>*/}


                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Impair performance of the <span*/}
                    {/*                    style={{fontStyle: "italic"}}>works</span> in use</strong></p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Mitigated Impact - Impair performance of the works in use *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <FormControl fullWidth>*/}
                    {/*                    <Select*/}
                    {/*                        native*/}
                    {/*                        value={this.state.newMitigationData.mitigatedPotentialImpact.impairPerformance}*/}
                    {/*                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                        inputProps={{*/}
                    {/*                            id: 'mitigatedImpairPerformanceEditInput',*/}
                    {/*                            style: {fontSize: 12}*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={"No"}>No</option>*/}
                    {/*                        <option value={"Yes"}>Yes</option>*/}
                    {/*                    </Select>*/}
                    {/*                </FormControl>*/}
                    {/*            </GridItem>*/}


                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <hr style={{margin: "10px 0 10px 0"}}/>*/}
                    {/*                <p style={{fontSize: 12}}><strong>Increase the <span*/}
                    {/*                    style={{fontStyle: "italic"}}>Contractor's</span> total cost</strong>*/}
                    {/*                </p>*/}
                    {/*            </GridItem>*/}

                    {/*            /!* Mitigated Impact - Increase the Contractor's total cost *!/*/}
                    {/*            <GridItem xs={12} sm={12} md={12} lg={12}>*/}
                    {/*                <FormControl fullWidth>*/}
                    {/*                    <Select*/}
                    {/*                        native*/}
                    {/*                        value={this.state.newMitigationData.mitigatedPotentialImpact.increaseContractorTotalCost}*/}
                    {/*                        onChange={this.handleChangeMitigationStrategy}*/}
                    {/*                        inputProps={{*/}
                    {/*                            id: 'mitigatedIncreaseContractorCostEditInput',*/}
                    {/*                            style: {fontSize: 12}*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={"No"}>No</option>*/}
                    {/*                        <option value={"Yes"}>Yes</option>*/}
                    {/*                    </Select>*/}
                    {/*                </FormControl>*/}
                    {/*            </GridItem>*/}


                    {/*        </GridContainer>*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => this.handleCancel("strategy")} color="info">*/}
                    {/*            Cancel*/}
                    {/*        </Button>*/}

                    {/*        /!* Add mitigation strategy*!/*/}

                    {/*        <div style={{position: "relative"}}>*/}
                    {/*            <Button*/}
                    {/*                onClick={() => this.addMitigationStrategy()}*/}
                    {/*                color="primary">*/}
                    {/*                Add*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}

                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openAddAttachmentDialog}
                        onClose={() => this.handleCancel("addAttachment")}
                        aria-labelledby="addAttachmentDialog"
                    >
                        <form>
                            <DialogTitle id="addAttachmentDialog">Add Attachment</DialogTitle>
                            <DialogContent>

                                {/* File Upload */}
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadRiskAttachmentInput"
                                            type="file"
                                            onChange={(event) => this.changeAttachment(event)}
                                        />
                                        <label htmlFor="uploadRiskAttachmentInput">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>

                                    {/* Current Attachment Name */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentFileName}</p>
                                    </GridItem>

                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleCancel("addAttachment")} color="info">
                                    Close
                                </Button>

                                {/* Add attachment button */}
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"} onClick={(event) => this.addAttachment(event)}
                                            color="primary">
                                        Add
                                    </Button>
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>

                    {/* Send Early Warning Dialog */}
                    <Dialog
                        open={this.state.submitEWDialogOpen}
                        onClose={() => this.handleCancel("risk")}
                        aria-labelledby="sendEWDialog"
                        // fullScreen
                        maxWidth={false}
                    >
                        <form style={{display: "contents"}}>
                            <DialogTitle id="sendEWDialog">Preview Early Warning</DialogTitle>
                            <DialogContent>
                                {this.state.previewPDF !== "" ?
                                    <div>
                                        <Document
                                            file={this.state.previewPDF}
                                            onLoadSuccess={this.onDocumentLoad}
                                        >
                                            {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                                                <Page key={i+1} pageNumber={i+1} />
                                            ): ""}
                                        </Document>

                                    </div> : ""}
                            </DialogContent>
                            <DialogActions>

                                {/* Preview Back Button */}
                                <Button onClick={() => this.handleCancel("risk")}>
                                    Back
                                </Button>

                                {/* Preview Send & Download Button */}
                                <div style={{position: "relative"}}>
                                    <Button onClick={(event) => this.createEW(event, "sendDownload")} disabled={loadingSendDownloadEW} color="info">
                                        Send & Download
                                    </Button>
                                    {loadingSendDownloadEW && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>

                                {/* Preview Send Button */}
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"}
                                            onClick={(event) => this.createEW(event, "send")}
                                            color="primary" disabled={loadingSendEW}>
                                        Send
                                    </Button>
                                    {loadingSendEW && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>

                </GridContainer>
            );
        }
    }

} export default withStyles(styles)(NewEarlyWarning);