import React from "react";
import cx from "classnames";
import * as PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import routes from "../routes";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "../assets/img/LoginBackground.jpg";
import logo from "../assets/img/Contractly Logo Icon Small-04.png";

// let ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "white",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      stateLoaded : false,
      scroll : false
    };

    // this.refOfTheParent = React.createRef();

    this.resizeFunction = this.resizeFunction.bind(this);
  }

  // test = () => {
  //   this.setState({
  //     scroll : true
  //   });
  // };
  //
  // scrollTo()  {
  //     this.refOfTheParent.scrollTop = 500;
  //     // console.log("here");
  // }

  componentDidMount() {

    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refOfTheParent, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    window.addEventListener("resize", this.resizeFunction);

  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {

    if (e.history.location.pathname !== e.location.pathname) {
     this.refOfTheParent.scrollTop = 0;

     this.setState({
       stateLoaded : true
     });

      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  // getRoute() {
  //   return this.props.location.pathname;// !== "/admin/full-screen-maps";
  // }
  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        // [classes.mainPanelWithPerfectScrollbar]:
        //   navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"ContractLy"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={element => this.refOfTheParent = element}>
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/*{this.getRoute() ? (*/}
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
          {/*) : (*/}
          {/*  <div className={classes.map}>*/}
          {/*    <Switch>{this.getRoutes(routes)}</Switch>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Admin);
