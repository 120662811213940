const compensationEventTabsStyles = theme => ({
    alignInNavBar : {
        [theme.breakpoints.down("sm")]: {
            position: "sticky",
            top: 0,
            right: 0,
            zIndex: 1029,
            marginTop: -62,
            paddingTop: 10,
            textAlign: "right",
            marginLeft: "80%"
        },
        [theme.breakpoints.down("xs")]: {
            position: "sticky",
            top: 0,
            right: 0,
            zIndex: 1029,
            marginTop: -62,
            textAlign: "right",
            paddingTop: 0,
            marginLeft: "80%"
        }
    },
    alignInNavBarTaskViewButton: {
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 1029,
            marginTop: 0,
            paddingTop: 10,
            textAlign: "right",
            marginLeft: "80%"
        },
        [theme.breakpoints.down("xs")]: {
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 1029,
            marginTop: 0,
            textAlign: "right",
            paddingTop: 0,
            marginLeft: "80%"
        }
    },
    buttonWrap : {
        float: "right",
        marginRight: 15,
        [theme.breakpoints.down("sm")]: {
            float: "none",
            marginRight: 5
        },
        [theme.breakpoints.down("xs")]: {
            float: "none",
            marginRight: 5
        },
    },
    cardAlignment: {
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            marginTop: 30
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 30
        }
    },
    alignTable: {
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -5
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -5
        }
    },
    alignCard: {
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -15
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -15
        }
    },
    alignFirstCard: {
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -5
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: -5
        }
    },
    // alignTableWithTabs: {
    //     [theme.breakpoints.down("sm")]: {
    //         paddingRight: 0,
    //         paddingLeft: 0,
    //         marginTop: -10
    //     },
    //     [theme.breakpoints.down("xs")]: {
    //         paddingRight: 0,
    //         paddingLeft: 0,
    //         marginTop: -10
    //     }
    // },
    buttonStyle: {
        float: "right",
        [theme.breakpoints.down("sm")]: {
            float: "none",
            marginTop: 15,
            background: "inherit",
            boxShadow: "none"
        },
        [theme.breakpoints.down("xs")]: {
            float: "none",
            marginTop: 15,
            background: "inherit",
            boxShadow: "none"
        }
    },
    buttonStyle2: {
        float: "right",
        marginTop: 5,
        [theme.breakpoints.down("sm")]: {
            float: "none",
            marginTop: 5,
            background: "inherit",
            boxShadow: "none"
        },
        [theme.breakpoints.down("xs")]: {
            float: "none",
            marginTop: 15,
            background: "inherit",
            boxShadow: "none"
        }
    }
});

export default compensationEventTabsStyles;