import React from "react";
import Tabs from "../../../components/CustomTabs/CustomTabs.jsx";
import EventRegister from "./EventRegister.jsx";
import InstructionRegister from "./InstructionRegister.jsx";
import NoticeRegister from "./CompensationEventNotification/NoticeRegister.jsx";
import GridItem from "../../../components/Grid/GridItem";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import Add from "@material-ui/icons/Add";
import GridContainer from "../../../components/Grid/GridContainer";
import { getApiRoot } from "../../../api";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actionCreators from "../../../store/actions/tab";
import {connect} from "react-redux";

class CompensationEventTabs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddButton: false,
      addDropdownButton: [],
      stateLoaded : false,
    };
  }

    //Function that routes add button to different pages
    handleClickAdd = (event) => {

        let path = "/app/";

        if(event.toLowerCase() === "add compensation event notification"){
            path += 'addNotice';
        }
        else if(event.toLowerCase() === "add compensation event"){
            path += 'addEvent';
        }

        this.props.history.push({
            pathname: path
        });

    };

    //get permissions API
    getPermissions = () => {

        const headers = new Headers({
          "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/permissions", {
          credentials: "include",
          headers,
          method: "GET",
        }).then(res => {
          if(res.status === 200){
            return res.json();
          }
          else{
            this.setState({
              isLoaded: true,
            });

            alert("Something went wrong - HTTP " + res.status);
          }
        }).then(
          (result) => {

            if(result !== undefined){
              this.setPermissions(result);
            }

          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    //set permission variables based on get permission api
    setPermissions = (permissions) => {

    if(permissions.length !== 0){

      let creatCE = permissions.find(o => o.type === "compensationEvent" && o.subtype === "create").permission;
      let creatCEN = permissions.find(o => o.type === "compensationEventNotification" && o.subtype === "create").permission;

      let arr = this.state.addDropdownButton;

      if(creatCE === "true"){
        arr.push("Add Compensation Event");
      }
      if(creatCEN === "true"){
        arr.push("Add Compensation Event Notification");
      }
      if(creatCE === "false" && creatCEN === "false"){
        this.setState({
          showAddButton: false
        });
      }
      else{
        this.setState({
          showAddButton: true, addButtonDropdown: arr
        });
      }
    }
    else{
      this.setState({
        showAddButton: false,
      });
    }
    };

    componentWillUnmount() {
        this.props.onSetSelectedTab(0);
    }

    componentDidMount() {
    this.getPermissions();

      if(!this.props.location.state){
          this.setState({
              stateLoaded : true
          });
      }
  }

  render() {
       const { classes } = this.props;
      const { stateLoaded } = this.state;
        return (
            stateLoaded && <GridContainer>
                {/*Add button*/}
              { this.state.showAddButton ?
                <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignInNavBar}>
                    <div className={classes.buttonWrap}>
                        <CustomDropdown
                            onClick={(event) => this.handleClickAdd(event)}
                            buttonText={<Add/>}
                            buttonProps={{
                                justIcon: true,
                                round: true,
                                color: "primary",
                            }}
                            dropdownList={this.state.addDropdownButton}
                        />
                    </div>
                </GridItem> : "" }
                {/*tabs*/}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Tabs
                        plainTabs={true}
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: "Compensation Events",
                                tabContent: (
                                  <EventRegister classes={classes}/>
                                )
                            },
                            {
                                tabName: "Compensation Event Notices",
                                tabContent: (
                                   <NoticeRegister classes={classes}/>
                                )
                            },
                            {
                                tabName: "61.2 Register",
                                tabContent: (
                                    <InstructionRegister classes={classes}/>
                                )
                            },

                        ]}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        value: state.tabReducer.value
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(compensationEventTabsStyles)(CompensationEventTabs));
