import React from 'react';

//Custom Components
import CustomDashBoardDiaryItem from "../../components/Card/CustomDashBoardDiaryItem.jsx";

class DashboardDiaryList extends React.Component{

    render(){
        const { classes } = this.props;

        return (
           <div style={{maxHeight:600, overflowX:"hidden", overflowY: "auto"}}>
               {/* Render custom diary card for each item in list*/}
               {this.props.items ? this.props.items
                   .filter(item => {        //filer based on project and contract chosen

                       let projectFlag = false;
                       let contractFlag = false;

                       this.props.filterProjects.length !== 0 ? this.props.filterProjects.map(project =>
                           parseInt(item.contract.project.id) === parseInt(project) ? projectFlag = true : projectFlag = false ) : projectFlag = true;

                       this.props.filterContracts.length !== 0 ? this.props.filterContracts.map(contract =>
                           parseInt(item.contract.id) === parseInt(contract) ? contractFlag = true : contractFlag = false ) : contractFlag = true;

                       return projectFlag || contractFlag;

                   })
                   .reverse().map( (item, index) =>
               <CustomDashBoardDiaryItem key={index} item={item} classes={classes}/>
               ) : ""}
           </div>

        );
    }
}
export default DashboardDiaryList;