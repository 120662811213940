import * as actionTypes from '../actions/actionTypes';

const initialState = {
    sendTimeBarNotificationLoading : {},
    sendTimeBarNotificationError : {},
    acknowledgeToDoLoading : {},
    acknowledgeToDoError : {},
};

//START : Send Time Bar Notification

const sendTimeBarNotificationStart = (state, action) => {
    return {
        ...state,
        sendTimeBarNotificationLoading : {
            ...state.sendTimeBarNotificationLoading,
            [action.id] : true
        }
    };
}

const sendTimeBarNotificationSuccess = (state, action) => {
    return {
        ...state,
        sendTimeBarNotificationError : {
            ...state.sendTimeBarNotificationError,
            [action.id] : null
        },
        sendTimeBarNotificationLoading : {
            ...state.sendTimeBarNotificationLoading,
            [action.id] : false
        }
    };
}

const sendTimeBarNotificationFail = (state, action) => {
    return {
        ...state,
        sendTimeBarNotificationError : {
            ...state.sendTimeBarNotificationError,
            [action.id] : action.error
        },
        sendTimeBarNotificationLoading : {
            ...state.sendTimeBarNotificationLoading,
            [action.id] : false
        }
    };
}

//END : Send Time Bar Notification

//START : Acknowledge ToDo

const acknowledgeToDoStart = (state, action) => {
    return {
        ...state,
        acknowledgeToDoLoading : {
            ...state.acknowledgeToDoLoading,
            [action.id] : true
        }
    };
}

const acknowledgeToDoSuccess = (state, action) => {
    return {
        ...state,
        acknowledgeToDoError : {
            ...state.acknowledgeToDoError,
            [action.id] : null
        },
        acknowledgeToDoLoading : {
            ...state.acknowledgeToDoLoading,
            [action.id] : false
        }
    };
}

const acknowledgeToDoFail = (state, action) => {
    return {
        ...state,
        acknowledgeToDoError : {
            ...state.acknowledgeToDoError,
            [action.id] : action.error
        },
        acknowledgeToDoLoading : {
            ...state.acknowledgeToDoLoading,
            [action.id] : false
        }
    };
}

//END : Acknowledge ToDo

//Reducer
const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SEND_TIME_BAR_NOTIFICATION_START: return sendTimeBarNotificationStart(state, action);
        case actionTypes.SEND_TIME_BAR_NOTIFICATION_SUCCESS: return sendTimeBarNotificationSuccess(state, action);
        case actionTypes.SEND_TIME_BAR_NOTIFICATION_FAIL: return sendTimeBarNotificationFail(state, action);
        case actionTypes.ACKNOWLEDGE_TODO_START: return acknowledgeToDoStart(state, action);
        case actionTypes.ACKNOWLEDGE_TODO_SUCCESS: return acknowledgeToDoSuccess(state, action);
        case actionTypes.ACKNOWLEDGE_TODO_FAIL: return acknowledgeToDoFail(state, action);
        default: return state;
    }
};
export default reducer;