import React from "react";
import * as PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

//icons
// import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
// import CreditCardOutlined from "@material-ui/icons/CreditCardOutlined";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import Home from "@material-ui/icons/Home";
import InputAdornment from "@material-ui/core/InputAdornment/index";
import LockOutlined from "@material-ui/icons/LockOutlined";
// import PersonOutlined from "@material-ui/icons/PersonOutlined";
// import SchoolOutlined from "@material-ui/icons/SchoolOutlined";
// import Smartphone from "@material-ui/icons/Smartphone";
// import WorkOutline from "@material-ui/icons/WorkOutline";

// custom components
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import InfoArea from "../../../components/InfoArea/InfoArea.jsx";

import { verifyEmail, verifyLength } from "../../../globals";
import { getApiRoot } from "../../../api";

//custom styles
import registerPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import CircularProgress from "@material-ui/core/CircularProgress";

class RegisterPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",

            loading: false
        }
    }

    //function that routes if a user successfully registers
    successfulRegistration = () => {
        let path = "/auth/loginPage";

        this.props.history.push({
            pathname: path
        });
    };

    getStarted = () => {

       if(!verifyEmail(this.state.email)){
           alert("Invalid Email");
       }
       else if(!verifyLength(this.state.password, 8) || !verifyLength(this.state.passwordConfirm, 8)){
           alert("Password must be at least 8 characters long");
       }
       else if(this.state.password !== this.state.passwordConfirm){
           alert("Passwords do not match");
       }
       else {
           this.registrationAPI();
       }

    };

    //function that calls register API
    registrationAPI = () => {

        // let obj = {};
        // obj.username = this.state.email;
        // obj.password = this.state.password;
        // obj.passwordConfirm = this.state.passwordConfirm;

        this.setState({
            loading: true
        });

        let data = new URLSearchParams();
        data.append('username', this.state.email);
        data.append('password',  this.state.password);
        data.append('passwordConfirm',  this.state.passwordConfirm);

        const headers = new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + "/api/registration",
                {
                    credentials: "include",
                    headers,
                    method: "POST",
                    body: data
                }
            ).then(res => {

                this.setState({
                    loading: false
                });

                if (res.status === 200) {
                    this.successfulRegistration();
                }
                else {
                    // alert("Error: " + res.text());
                    return res.text();
                }

            }).then(
            (result) => {
                if(result){
                    alert(result);
                }
            }).catch((error) => {
                alert("Error: " + error);
            });


        // }, 2000)

        // alert("Email: " + obj.username + ", Password: " + obj.password + ", Confirm Password: " + obj.passwordConfirm);

    };

    //function that handles change of input fields
    handleChange = (event, type) => {

        if(type === "email"){
            this.setState({
                email: event.target.value
            })
        }
        else if(type === "password"){
            this.setState({
                password: event.target.value
            })
        }
        else if(type === "confirmPassword"){
            this.setState({
                passwordConfirm: event.target.value
            })
        }

    };

    //function that calls register api when enter key is pressed
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.getStarted();
        }
    };


    render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card style={{padding: 30}}>
              {/* Header */}
              <h2 className={classes.cardTitle}>Register</h2>
              <CardBody>
                <GridContainer justify="center">
                    {/* Extra Info */}
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="Contractly Info 1"
                      description="Contractly is a web-based contract administration system which identifies and communicates commercial risk/change and automates proper contractual response and compliance."
                      icon={Home}
                      iconColor="info"
                    />

                    {/*<InfoArea*/}
                    {/*    title="Contractly Info 2"*/}
                    {/*    description="Contractly is a web-based contract administration system which identifies and communicates commercial risk/change and automates proper contractual response and compliance."*/}
                    {/*    icon={AccountBalanceOutlined}*/}
                    {/*    iconColor="info"*/}
                    {/*/>*/}

                    {/*<InfoArea*/}
                    {/*    title="Contractly Info 3"*/}
                    {/*    description="Contractly is a web-based contract administration system which identifies and communicates commercial risk/change and automates proper contractual response and compliance."*/}
                    {/*    icon={CreditCardOutlined}*/}
                    {/*    iconColor="info"*/}
                    {/*/>*/}
                  </GridItem>

                    {/* Registration forms */}
                  <GridItem xs={12} sm={8} md={5}>
                    <form className={classes.form} onKeyPress={event => this.handleKeyPress(event)}>
                      {/*  Title Input */}
                      {/*<CustomInput*/}
                      {/*    formControlProps={{*/}
                      {/*      fullWidth: true,*/}
                      {/*      className: classes.customFormControlClasses*/}
                      {/*    }}*/}
                      {/*    inputProps={{*/}
                      {/*      startAdornment: (*/}
                      {/*          <InputAdornment*/}
                      {/*              position="start"*/}
                      {/*              className={classes.inputAdornment}*/}
                      {/*          >*/}
                      {/*            <SchoolOutlined className={classes.inputAdornmentIcon} />*/}
                      {/*          </InputAdornment>*/}
                      {/*      ),*/}
                      {/*      placeholder: "Title",*/}
                      {/*      type: "text"*/}
                      {/*    }}*/}
                      {/*/>*/}
                        {/*  First Name Input */}
                      {/*<CustomInput*/}
                      {/*  formControlProps={{*/}
                      {/*    fullWidth: true,*/}
                      {/*    className: classes.customFormControlClasses*/}
                      {/*  }}*/}
                      {/*  inputProps={{*/}
                      {/*    startAdornment: (*/}
                      {/*      <InputAdornment*/}
                      {/*        position="start"*/}
                      {/*        className={classes.inputAdornment}*/}
                      {/*      >*/}
                      {/*        <PersonOutlined className={classes.inputAdornmentIcon} />*/}
                      {/*      </InputAdornment>*/}
                      {/*    ),*/}
                      {/*    placeholder: "First Name",*/}
                      {/*    type: "text"*/}
                      {/*  }}*/}
                      {/*/>*/}

                        {/*  Last Name Input */}
                      {/*<CustomInput*/}
                      {/*    formControlProps={{*/}
                      {/*      fullWidth: true,*/}
                      {/*      className: classes.customFormControlClasses*/}
                      {/*    }}*/}
                      {/*    inputProps={{*/}
                      {/*      startAdornment: (*/}
                      {/*          <InputAdornment*/}
                      {/*              position="start"*/}
                      {/*              className={classes.inputAdornment}*/}
                      {/*          >*/}
                      {/*            <PersonOutlined className={classes.inputAdornmentIcon} />*/}
                      {/*          </InputAdornment>*/}
                      {/*      ),*/}
                      {/*      placeholder: "Last Name",*/}
                      {/*      type: "text"*/}
                      {/*    }}*/}
                      {/*/>*/}
                        {/*  Email Input */}
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange:  event => this.handleChange(event, "email"),
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <EmailOutlined className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Email Address",
                          type: "text",
                            autoComplete: "username"
                        }}
                      />
                        {/*  Company Name Input */}
                      {/*<CustomInput*/}
                      {/*    formControlProps={{*/}
                      {/*      fullWidth: true,*/}
                      {/*      className: classes.customFormControlClasses*/}
                      {/*    }}*/}
                      {/*    inputProps={{*/}
                      {/*      startAdornment: (*/}
                      {/*          <InputAdornment*/}
                      {/*              position="start"*/}
                      {/*              className={classes.inputAdornment}*/}
                      {/*          >*/}
                      {/*            <WorkOutline className={classes.inputAdornmentIcon} />*/}
                      {/*          </InputAdornment>*/}
                      {/*      ),*/}
                      {/*      placeholder: "Company Name",*/}
                      {/*      type: "text"*/}
                      {/*    }}*/}
                      {/*/>*/}
                        {/*  Cell Number Input */}
                      {/*<CustomInput*/}
                      {/*    formControlProps={{*/}
                      {/*      fullWidth: true,*/}
                      {/*      className: classes.customFormControlClasses*/}
                      {/*    }}*/}
                      {/*    inputProps={{*/}
                      {/*      startAdornment: (*/}
                      {/*          <InputAdornment*/}
                      {/*              position="start"*/}
                      {/*              className={classes.inputAdornment}*/}
                      {/*          >*/}
                      {/*            <Smartphone className={classes.inputAdornmentIcon} />*/}
                      {/*          </InputAdornment>*/}
                      {/*      ),*/}
                      {/*      placeholder: "Phone Number",*/}
                      {/*      type: "number"*/}
                      {/*    }}*/}
                      {/*/>*/}
                        {/*  Password Input */}
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange:  event => this.handleChange(event, "password"),
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <LockOutlined className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Password",
                          type: "password",
                            autoComplete: "new-password"
                        }}
                      />
                        {/*  Confirm Password Input */}
                      <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                              onChange:  event => this.handleChange(event, "confirmPassword"),
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                  <LockOutlined className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: "Confirm Password",
                            type: "password",
                              autoComplete: "new-password"
                          }}
                      />
                      <div style={{marginTop: 30, position: "relative", float: "left"}}>
                        <Button color="info" onClick={() => this.getStarted()} disabled={loading}>
                          Get started
                        </Button>
                          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, float: "left"}} /> }
                      </div>
                    </form>
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
