import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error : null,
    isOpen : false
};

// const setError = (action) => {
//     return { 
//         error : action.error,
//         isOpen : true
//     };
// }

// const hideError = () => {
//     return { 
//         error : null,
//         isOpen : false
//     };
// }

const reducer = (state = initialState, action) => {

    const { error } = action;

    if(error){
        return {
            error : error,
            isOpen : true
        }
    }
    else if(action.type === actionTypes.HIDE_ERROR){
        return {
            error : null,
            isOpen : false
        }
    }

    return state;

    // switch ( action.type ) {
    //     case actionTypes.SET_ERROR: return setError(action);
    //     case actionTypes.HIDE_ERROR: return hideError();
    //     default: return state;
    // }
};

export default reducer;