import React from 'react';
import communicationRevisionActionStyles from "../../../assets/styles/communicationRevisionActionStyles";
import { withStyles } from '@material-ui/core/styles';
import Button from "../../CustomButtons/Button";
import NotificationRevisionResponseDialog from "./NotificationRevisionResponseDialog";
import * as PropTypes from "prop-types";
import NotificationPreviewContent from "./NotificationPreviewContent";
import NotificationResponseFormContent from "./NotificationResponseFormContent";

class NotificationRevisionAction extends React.Component {

    //set state to be used
    state = {
        openDialog : false,
        fields : [],
        componentsLoaded : false,
        components : [],
        fieldsLoaded : false,
    };

    //method that handles opening and closing the respond dialog
    openClose = () => {
        if(this.state.openDialog){
            this.setState({
                fields :  [],
                fieldsLoaded : false
            });
        }
        this.setState(state => ({ openDialog: !state.openDialog }));
    };

    //onclick handler for actions
    onClick = (action) => {
        //set the fields to use based on which button was pressed
        if(action === "accepted"){
            this.setState({
                fields :  this.props.clauseData.response.acceptFields,
                openDialog : true,
                actionType : action,
                fieldsLoaded : true
            });
        }
        else{
            this.setState({
                fields :  this.props.clauseData.response.rejectFields,
                openDialog : true,
                actionType : action,
                fieldsLoaded : true
            });
        }

        //define components that render one after another
        let components = [
            {
                component: NotificationResponseFormContent,
                number : 1,
                id: "responseDialog",
                type : "response",
                responseType : action
            },
            {
                component: NotificationPreviewContent,
                number : 2,
                id: "previewDialog",
                type : "preview",
                responseType : ""
            }
        ];

        this.setState({
            components : components,
            componentsLoaded : true
        });
    };

    //ensure state has been set before rendering components
    stateLoaded = () => {
        return (this.state.fieldsLoaded && this.state.componentsLoaded);
    };

    render () {
        const { fields, components } = this.state;
        const { classes, apiEndpoints, revisionId, type, clauseData, communicationId, revisionDataIfResponse } = this.props;

        return (
            <div className={classes.divFloatRight + " " + classes.alignRight}>

                {/* Action buttons for revision response */}
                <Button color={"primary"} onClick={() => this.onClick("accepted")}>{clauseData.displayData.response.accept}</Button>
                <Button color={"info"} onClick={() => this.onClick("rejected")}>{clauseData.displayData.response.reject}</Button>

                {/* Generate components dynamically */}
                { this.stateLoaded() && <NotificationRevisionResponseDialog open={this.state.openDialog}
                                 openAndCloseDialog={this.openClose}
                                 fields={fields}
                                 components={components}
                                 revisionId={revisionId}
                                 revisionDataIfResponse={revisionDataIfResponse ? revisionDataIfResponse : null}
                                 communicationId={communicationId}
                                 apiEndpoints={apiEndpoints}
                                 type={type}
                                 clauseData={clauseData}
                                 revisionIsResponse={clauseData.revisionIsResponse} />
                }
            </div>
        );
    }
}

//set proptypes of component
NotificationRevisionAction.propTypes = {
    type : PropTypes.string,
    id : PropTypes.number,
    apiEndpoints : PropTypes.object,
    revisionId : PropTypes.number,
    communicationId : PropTypes.number,
    clauseData : PropTypes.object
};

export default withStyles(communicationRevisionActionStyles)(NotificationRevisionAction);