import fullScreenDialogStyles from "./fullScreenDialogStyles";

const previewDialogStyles = {
    ...fullScreenDialogStyles,
    // submitButtonContainer : {
    //     position: "relative",
    //     float: "right",
    // },
    // submitButton : {
    //     float: "right"
    // },
    pdfPage: {
        '& canvas' : {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            background : "#fff"
        }
    },
    pdfContainer : {
        marginTop : 5
    }
};

export default previewDialogStyles;
