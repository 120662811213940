import compensationEventTabsStyles from "./compensationEventTabsStyles";

const communicationDetailedPageStyles = theme => ({
    ...compensationEventTabsStyles(theme),
    smallFont : {
        fontSize : 12,
    },
    infoCard : {
        padding: "15px 20px"
    },

    floatRight : {
        [theme.breakpoints.up('sm')]: {
            float: "right"
        }
    },
    noMargin : {
        margin : 0
    },
    noTopMargin : {
        marginTop : 0
    },
    loadingWheel : {
        position: "absolute",
        top: "50%",
        left: "calc(50% - 20px)"
    },
    collapseCard: {
        cursor:"pointer",
        margin: "10px 0 0 0",

    },
    collapseParagraph: {
        margin: 0,
        display: "inline"
    },
    collapseIcon : {
        color: "#000"
    },
    collapseButton:{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(0, 0, 0 ,0)",
        boxShadow: "none",
        float: "right",
        width: "fit-content"
    },
});
export default communicationDetailedPageStyles;
