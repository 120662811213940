import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import * as PropTypes from 'prop-types';
import recipientListStyles from "../../assets/styles/recipientListStyles";

const RecipientList = (props) => 
    <GridContainer className={props.classes.gridContainer}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <hr style={{margin: "0 0 10px 0"}}/>
            <GridContainer style={{alignItems: "center"}}>
                <GridItem xs={10} sm={10} md={8} lg={8}>
                    <p className={props.classes.heading} style={{color : props.headingColor}}><strong>{props.heading}</strong></p>
                </GridItem>
            </GridContainer>
            <hr style={{margin: "10px 0 20px 0"}}/>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: -21}}>
            <List>
                {
                    props.emailList && props.emailList.length !== 0  ? props.emailList.map((item, index) =>
                            <ListItem className={props.classes.customListItem}
                                        key={index}>
                                {item.name + " (" + item.email + ")"}
                            </ListItem>)
                        : <p style={{marginTop: 12, marginBottom: 11}} className={props.classes.noDataText}>{props.noDataText}</p>
                }
            </List>
        </GridItem>
    </GridContainer>

RecipientList.propTypes = {
    heading: PropTypes.string,
    emailList: PropTypes.array,
    noDataText: PropTypes.string,
    headingColor : PropTypes.string
};
export default withStyles(recipientListStyles)(RecipientList);
