import React, {createRef} from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../CustomButtons/Button";
import {Document, Page} from "react-pdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import previewDialogContentStyles from "../../../assets/styles/previewDialogContentStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from "@material-ui/core/IconButton";

class NotificationPreviewContent extends React.Component {

    constructor(props){
        super(props);
        this.topOfPage = createRef(); //ref to top of dialog
    }

    //set the number of pages that the preview api returns
    state = {
        numPages : "",
        scale : 1.6,
        showScrollTop : false,
    };

    //when document loads function executes
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    //scroll to the top of the page onclick
    scrollToTop = () => {
        if(this.topOfPage.current){
            this.topOfPage.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    //show scroll to top button when user initially scrolls
    handleScroll = () => {
        if(!this.state.showScrollTop){
            this.setState({
                showScrollTop : true
            });
        }
    };

    //set component styling options depending on the screen size
    setUpResponsiveOptions = () => {
        let mql = window.matchMedia('(max-width: 600px)');
        if(mql.matches){
            this.setState({
                scale : 1.0,
            });
        }
    };

    componentDidMount() {
        //set scale of pdf depending on the screen size
        this.setUpResponsiveOptions();
    }

    render(){
        const { numPages, scale, showScrollTop } = this.state;
        const { classes } = this.props;

        return (
          <React.Fragment>
              {/* App bar component that contains title and dialog actions */}
              <AppBar className={classes.appBar}>
                  <Toolbar>
                      <Typography variant={"h6"} className={classes.appBarHeading}>
                          Preview
                      </Typography>

                      {/*close button*/}
                      <Button
                        size="sm"
                        simple={true}
                        className={classes.appBarButtons}
                        onClick={this.props.openClose}
                        disabled={this.props.loadingSend || this.props.loadingSendDownload}>
                          Back
                      </Button>

                      {/* send & download button */}
                      <div className={classes.relativeDiv}>
                          <Button type={"submit"}
                                  onClick={() => this.props.actionMethod("sendDownload")}
                                  simple={true}
                                  size="sm"
                                  disabled={this.props.loadingSendDownload || this.props.loadingSend}
                                  className={classes.appBarButtons}>
                              Send & Download
                          </Button>
                          {this.props.loadingSendDownload && <CircularProgress size={24} className={classes.loadingWheel} /> }
                      </div>

                      {/* send button */}
                      <div className={classes.relativeDiv}>
                          <Button type={"submit"}
                                  onClick={() => this.props.actionMethod("send")}
                                  simple={true}
                                  size="sm"
                                  disabled={this.props.loadingSend || this.props.loadingSendDownload}
                                  className={classes.appBarButtons}>
                              Send
                          </Button>
                          {this.props.loadingSend && <CircularProgress size={24} className={classes.loadingWheel} /> }
                      </div>
                  </Toolbar>
              </AppBar>

              {/* dialog content which contains the preview pdf */}
              {this.props.previewPDF !== "" ?
                <DialogContent onScroll={this.handleScroll} className={classes.dialogContent}>
                    {/* Render preview pdf in dialog */}
                    <div ref={this.topOfPage}/>
                    <div className={classes.pdfContainer}>
                        <Document
                          file={this.props.previewPDF}
                          onLoadSuccess={this.onDocumentLoad}
                        >
                            {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                              <Page key={i+1} pageNumber={i+1} scale={scale} className={classes.pdfPage}/>
                            ): ""}
                        </Document>
                    </div>
                </DialogContent>
                : ""}

              {/* Scroll back to top of full page dialog*/}
              {showScrollTop ? <label className={classes.scrollTopLabel}>
                  <IconButton className={classes.scrollTopButton} onClick={this.scrollToTop} component={"span"} size={"sm"} color={"primary"}>
                      <KeyboardArrowUpIcon className={classes.scrollTopIcon}/>
                  </IconButton>
              </label> : ""
              }
          </React.Fragment>
        );
    }
}
//set up component proptypes
NotificationPreviewContent.propTypes = {
    openClose : PropTypes.func,
    actionMethod : PropTypes.func,
    fields : PropTypes.array,
    // previewPDF : PropTypes.string,
};

export default withStyles(previewDialogContentStyles)(NotificationPreviewContent);
