import React from "react"
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Add from "@material-ui/icons/Add";
// import Close from "@material-ui/icons/Close";
// import Person from "@material-ui/icons/Person";
import PresentToAll from "@material-ui/icons/PresentToAll";
import Button from "../../../components/CustomButtons/Button.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
// import {allContractsForProject} from "../../../variables/general.jsx";

import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react.jsx";

import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import CustomInput from "../../../components/CustomInput/CustomInput";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { addZero } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import GetApp from "@material-ui/icons/GetApp";
import fileDownload from "js-file-download";
import { daysArray, monthsArray } from "../../../globals";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";


//import custom styles
const styles = theme => ({
    ...dashboardStyle,...validationFormsStyle, ...compensationEventTabsStyles(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    input: {
        display: 'none',
    },
});

class ContractView extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

            //row of contract action clicked
            contractRowKey: 0,

            // openContractorDialog: false,
            // addContractorEmail: "",

            days : daysArray(),
            months : monthsArray(),

            // loadingContractors: false,
            isLoaded: false,
            error: null,

            //contract view data
            contracts: [],

            // currentContractors: [],

            //Attachments variables
            loadingAddAttachment: false,
            openAttachmentsDialog: false,
            openUploadAttachmentDialog: false,
            currentAttachmentFileName: "No file chosen",
            currentFile: {},

            contractAttachments: [],

            loadingDownloadFile: false,

            showUploadContractButton: false,

            //contractAttachments: []

            // data: allContractsForProject.contracts.map((prop, key) => {
            //     return {
            //         contractors: prop.contractors,
            //     };
            // })
        };
    }

    //function that handles opening dialogs
    handleClickOpen = () => {

        this.setState({
            openUploadAttachmentDialog: true
        });
    };

    //function that handles closing dialogs
    handleCancel = () => {

        this.setState({
            openUploadAttachmentDialog: false, currentAttachmentFileName: "No file chosen"
        });

    };

    //calls upload_files api for each attachment
    handleAddAttachment = (event) => {

        // alert(this.state.currentFile);

        this.setState({
            loadingAddAttachment: true
        });

        let data = new FormData();
        data.append('file', this.state.currentFile);
        data.append('type', 'contract');
        data.append('id', this.state.contractRowKey);

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/upload_files', {
            credentials: "include",
            method: "POST",
            body: data,
        }).then(res => {


            this.setState({loadingAddAttachment: false});

            if (res.status === 200) {
                // alert("Attachment successfully uploaded");
                this.setState({currentAttachmentFileName: "No file chosen", currentFile: {}, openUploadAttachmentDialog: false, loadingDownloadFile: true});

                this.initialContractViewData();

            } else {
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
        // }, 2000);

        event.preventDefault();
    };

    //calls download_file api when action on attachment is clicked
    downloadFileAPI = (uuid, fileName) => {

        // setTimeout(() => {
        fetch(getApiRoot() + '/api/download_file/' + uuid, {
            credentials: "include",
            method: "GET",
        }).then(res => res.blob())
            .then(result => {

                fileDownload(result, fileName);

                this.setState({
                    loadingDownloadFile: false
                });

                //{

                // if (res.status === 200) {
                //
                //     return res;
                //
                // } else {
                //     alert("Something went wrong - HTTP: " + res.status);
                // }

            }).catch(error => alert(error));

    };

    //on_change event handler for attachments
    changeAttachment = (event) => {

        if(event.target.files.length === 0){
            this.setState({
                currentAttachmentFileName: "No file chosen"
            });
        }
        else{
            // alert(event.target.files[0]);
            this.setState({
                currentAttachmentFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }

    };

    //function that cal;s get contractors API
    // getContractorsAPI = () => {
    //     const headers = new Headers({
    //         "Accept": "application/json",
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + "/api/contractors/" + this.state.contractRowKey, {
    //             credentials: "include",
    //             headers,
    //             method: "GET"
    //         }).then((res) => {
    //             // alert(res.status);
    //             if(res.status === 200)
    //                 return res.json();
    //         }).then(
    //             (result) => {
    //                 this.setState({
    //                     currentContractors: result,
    //                     loadingContractors: false
    //                 });
    //             },
    //             (error) => {
    //                 alert(error);
    //
    //                 this.setState({
    //                     loadingContractors: false
    //                 });
    //             }
    //         )
    //     // },2000)
    // };

    //Add contractor
    // handleAddContractor = () => {
    //
    //     this.setState({ addContractorEmail: ""});
    //
    //     if(verifyEmail(this.state.addContractorEmail)){
    //
    //         let newContractorObj = {
    //             user_id: 1,
    //             user_email: this.state.addContractorEmail,
    //             contract_id: this.state.contractRowKey
    //         };
    //
    //         let data = JSON.stringify(newContractorObj);
    //
    //         const headers = new Headers({
    //             "Content-Type": "application/json",
    //         });
    //
    //         this.setState({
    //             loadingContractors: true
    //         });
    //
    //         // setTimeout(() => {
    //             fetch(getApiRoot() + '/api/add_contractor', {
    //                 credentials: "include",
    //                 method: "POST",
    //                 headers,
    //                 body: data,
    //             }).then(res => {
    //
    //                 if (res.status === 200) {
    //
    //                     this.setState({
    //                         addContractorEmail: ""
    //                     });
    //
    //                     this.getContractorsAPI();
    //
    //                 } else {
    //                     alert("Something went wrong - HTTP: " + res.status);
    //
    //                     this.setState({
    //                         loadingContractors: false
    //                     });
    //                 }
    //
    //
    //             }).catch(error => alert(error));
    //         // }, 2000)
    //     }
    //     else{
    //         alert("Invalid Email: " + this.state.addContractorEmail);
    //     }
    //
    // };

    //Remove contractor
    // handleRemoveContractor = (index) => {
    //
    //     let removeContractorObj = {
    //         user_id : 1,
    //         user_remove_id: this.state.currentContractors[index].id,
    //         contract_id: this.state.contractRowKey
    //     };
    //
    //     let data = JSON.stringify(removeContractorObj);
    //
    //     const headers = new Headers({
    //         "Content-Type": "application/json",
    //     });
    //
    //     this.setState({
    //         loadingContractors: true
    //     });
    //
    //     // setTimeout(() => {
    //         fetch(getApiRoot() + '/api/delete_contractor', {
    //             credentials: "include",
    //             method: "POST",
    //             headers,
    //             body: data,
    //         }).then(res => {
    //
    //             if (res.status === 200) {
    //                 this.getContractorsAPI();
    //
    //             } else {
    //                 alert("Something went wrong - HTTP: " + res.status);
    //             }
    //
    //
    //         }).catch(error => alert(error));
    //     // }, 2000)
    // };

    //calls projects_contracts api that poulates initial table data
    initialContractViewData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/project_contracts/" + (this.props.location.state.projectId !== null ? this.props.location.state.projectId : 1), {
            credentials: "include",
            headers,
            method: 'GET',
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                alert("Something went wrong - HTTP " + res.status)
            }
        })
            .then(
                (result) => {

                    if(result !== undefined){

                        this.setState({
                            contracts: result,
                            loadingDownloadFile: false
                        });

                        this.getPermissions();

                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        // },2000)

    };

    //calls the get permission api
    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        // setTimeout(() => {
        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
                    if(res.status === 200){
                        return res.json();
                    }
                    else{
                        this.setState({
                            isLoaded: true,
                        });

                        alert("Something went wrong - HTTP " + res.status);
                    }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setState({
                        isLoaded: true,
                        // permissions: result
                    });
                }

                this.setPermissions(result);

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
        // },2000)

    };

    //hide and show the upload contract button based on permissions
    setPermissions = (permissions) => {

        if(permissions.length !== 0){

            let createContract = permissions.find(o => o.type === "contract" && o.subtype === "create").permission;

            if(createContract === "true"){
                this.setState({
                    showUploadContractButton: true,
                });
            }
            else{
                this.setState({
                    showUploadContractButton: false,
                });
            }

        }
        else{
            this.setState({
                showUploadContractButton: false,
            });
        }

    };


    //Initial GET request, that populates react table
    componentDidMount() {
        this.initialContractViewData();
    }


    render() {
        const {classes} = this.props;
        const { error, isLoaded, /*loadingContractors,*/ loadingAddAttachment, loadingDownloadFile } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            //Loading wheel for initial API request
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}} /></GridItem></GridContainer>;
        } else {
            return (
                <GridContainer>
                    {/*Add contract button*/}
                    {/*<GridItem xs={12}>*/}
                    {/*    <Button justIcon round color="primary" style={{float: "right"}}><Add/></Button>*/}
                    {/*</GridItem>*/}
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.alignTable}>
                        <Card className={classes.cardAlignment}>
                            <CardBody>
                                {/*React table with Diaries*/}
                                <ReactTable
                                    data={this.state.contracts}
                                    filterable
                                    columns={[
                                        {
                                            accessor: "id",
                                            show: false
                                        },
                                        {
                                            Header: "Contract Number",
                                            accessor: "number",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },

                                        {
                                            Header: "Initial Contract Value",
                                            accessor: "initialValue",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },
                                        {
                                            Header: "Revised Contract Value",
                                            accessor: "revisedValue",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"}
                                        },
                                        {
                                            Header: "Commencement Date",
                                            accessor: "dateContractCommences",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Completion Date",
                                            accessor: "dateContractCompletes",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            Header: "Revised Completion Date",
                                            accessor: "dateContractCompletesRevised",
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            //Template for date
                                            Cell: row => (
                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() : ""}</p>
                                            ),
                                        },
                                        {
                                            //Open Contractors dialog
                                            Header: "Actions",
                                            accessor: "id",
                                            Cell: row => (
                                                <div>
                                                    {/*<Tooltip*/}
                                                    {/*    title={"Edit Contractors"}*/}
                                                    {/*    placement="top"*/}
                                                    {/*    classes={{ tooltip: classes.tooltip }}*/}
                                                    {/*>*/}
                                                    {/*    <Button*/}
                                                    {/*        size={"sm"}*/}
                                                    {/*        justIcon*/}
                                                    {/*        round*/}
                                                    {/*        simple*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            let obj = this.state.contracts.find(o => o.id === row.value);*/}
                                                    {/*            this.setState({ openContractorDialog: true, contractRowKey: obj.id, loadingContractors: true});*/}

                                                    {/*            this.getContractorsAPI();*/}
                                                    {/*        }}*/}
                                                    {/*        color="primary"*/}
                                                    {/*    >*/}
                                                    {/*        <Person />*/}
                                                    {/*    </Button>*/}
                                                    {/*</Tooltip>*/}
                                                    {this.state.showUploadContractButton ?
                                                    <Tooltip
                                                        title={"Upload Contracts"}
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button
                                                            size={"sm"}
                                                            justIcon
                                                            round
                                                            simple
                                                            onClick={() => {
                                                                let obj = this.state.contracts.find(o => o.id === row.value);
                                                                let attachmentObj = this.state.contracts[this.state.contracts.findIndex(o => o.id === obj.id)].attachments.find(obj => obj.type === "main")

                                                                this.setState({
                                                                    openAttachmentsDialog: true,
                                                                    contractRowKey: obj.id,
                                                                    contractAttachments: [attachmentObj]
                                                                });
                                                            }}
                                                            color="info"
                                                        >
                                                            <PresentToAll />
                                                        </Button>
                                                    </Tooltip> : ""}
                                                </div>
                                            ),
                                            headerStyle: {"textAlign": "center", fontSize: 14},
                                            style: {"textAlign": "center"},
                                            filterable: false,
                                            sortable: false,
                                        }

                                    ]}
                                    defaultSorted={[
                                        {
                                            id: "id",
                                            desc: true
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-expandable -striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/*Contractors Dialog*/}
                    {/*<Dialog*/}
                    {/*    open={this.state.openContractorDialog}*/}
                    {/*    onClose={() => {*/}
                    {/*        this.setState({openContractorDialog: false});*/}
                    {/*    }}*/}
                    {/*    aria-labelledby="contractorDialog"*/}
                    {/*>*/}
                    {/*    <DialogTitle id={"contractorDialog"}>Contractors</DialogTitle>*/}
                    {/*    <DialogContent style={{width: 400}}>*/}
                    {/*        <div>*/}
                    {/*            /!*Current Contractors*!/*/}
                    {/*            <p style={{fontSize: 14, marginTop: 20}}>*/}
                    {/*                Current Contractor(s):*/}
                    {/*            </p>*/}
                    {/*            {loadingContractors && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }*/}
                    {/*            <List>*/}
                    {/*                {*/}
                    {/*                    this.state.currentContractors.map((item, index) =>*/}
                    {/*                        <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}*/}
                    {/*                                  key={index}>*/}
                    {/*                            {item.email}*/}
                    {/*                            <ListItemSecondaryAction>*/}
                    {/*                                <Close className={classes.danger}*/}
                    {/*                                       style={{cursor: "pointer", fontSize: 16, marginTop: 5}}*/}
                    {/*                                       onClick={() => this.handleRemoveContractor(index)}/>*/}
                    {/*                            </ListItemSecondaryAction>*/}
                    {/*                        </ListItem>)*/}
                    {/*                }*/}
                    {/*            </List>*/}

                    {/*            /!*Add Contractor*!/*/}
                    {/*            <CustomInput*/}
                    {/*                labelText="Add Contractor"*/}
                    {/*                formControlProps={{*/}
                    {/*                    fullWidth: true*/}
                    {/*                }}*/}
                    {/*                inputProps={{*/}
                    {/*                    value: this.state.addContractorEmail,*/}
                    {/*                    onChange: (event) =>*/}
                    {/*                        this.setState({addContractorEmail: event.target.value}),*/}
                    {/*                    type: "email",*/}
                    {/*                    endAdornment: (*/}
                    {/*                        <Add onClick={() => this.handleAddContractor()}*/}
                    {/*                             style={{cursor: "pointer", fontSize: 16, marginTop: 5}}/>)*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => {*/}
                    {/*            this.setState({openContractorDialog: false, currentContractors: [], addContractorEmail: ""});*/}
                    {/*        }}*/}
                    {/*                color="info">*/}
                    {/*            Close*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/* Contracts Dialog*/}
                    <Dialog
                            open={this.state.openAttachmentsDialog}
                            onClose={() => {
                                this.setState({openAttachmentsDialog: false});
                            }}
                            aria-labelledby="contractsDialog"
                    >
                        <DialogTitle id={"contractsDialog"}>Contracts</DialogTitle>
                        <DialogContent style={{width: 600}}>

                        {/* View Contract Upload */}
                            <Card style={{marginBottom: 0, marginTop: 0}}>
                                <CardBody style={{padding: 30}}>
                                    <GridContainer>

                                        {/* Upload Contract Button*/}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Add style={{cursor: "pointer", fontSize: 16, float: "right"}}
                                                 onClick={() => this.handleClickOpen()}/>
                                        </GridItem>

                                        {/* Contract uploads */}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div style={{position: "relative"}}>
                                                <ReactTable

                                                    data={this.state.contractAttachments.length !== 0 ? this.state.contractAttachments : []}
                                                    // data={[]}
                                                    filterable={false}
                                                    sortable={false}
                                                    resizable={false}
                                                    noDataText="No attachments"
                                                    columns={[
                                                        {
                                                            Header: "File",
                                                            accessor: "fileName",
                                                            headerStyle: {fontSize: 14, fontWeight: 400},
                                                        },
                                                        {
                                                            Header: "Uploaded By",
                                                            accessor: "user.userDetails",
                                                            headerStyle: {fontSize: 14, fontWeight: 400},
                                                            Cell: row => (
                                                                <p>{row.value.name + " " + row.value.surname}</p>
                                                            ),
                                                        },
                                                        {
                                                            Header: "Uploaded",
                                                            accessor: "attachmentCreated",
                                                            headerStyle: {fontSize: 14, fontWeight: 400},
                                                            Cell: row => (
                                                                <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</p>
                                                            ),
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "id",
                                                            headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                            style: {"textAlign": "center"},
                                                            Cell: row => (
                                                                <div>
                                                                    <Button simple disabled={loadingDownloadFile} onClick={() => {

                                                                        this.setState({
                                                                            loadingDownloadFile: true
                                                                        });

                                                                        let id = this.state.contractAttachments.findIndex(o => o.id === row.value);
                                                                        let uuid = this.state.contractAttachments[id].uuid;
                                                                        let fileName = this.state.contractAttachments[id].fileName;

                                                                        this.downloadFileAPI(uuid, fileName);

                                                                    }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                                </div>

                                                            ),
                                                        },
                                                    ]}
                                                    defaultSorted={[
                                                        {
                                                            id: "id",
                                                            desc: true
                                                        }
                                                    ]}
                                                    defaultPageSize={5}
                                                    showPaginationBottom={true}
                                                    className="-striped -highlight"
                                                    showPageJump={false}
                                                />
                                                { this.state.loadingDownloadFile && <CircularProgress size={24} style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '50%',
                                                    marginTop: -12,
                                                    marginLeft: -12
                                                }}/>}
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({openAttachmentsDialog: false});
                            }}
                                    color="info">
                                Close
                            </Button>
                        </DialogActions>

                    </Dialog>

                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openUploadAttachmentDialog}
                        onClose={() => this.handleCancel()}
                        aria-labelledby="uploadRiskAttachmentDialog"
                    >
                        <form>
                            <DialogTitle id="uploadAttachmentDialog">Upload Attachments</DialogTitle>
                            <DialogContent>

                                {/* File Upload */}
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12} lg={12}
                                              style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                        <input
                                            accept="*"
                                            className={classes.input}
                                            id="uploadAttachmentInput"
                                            type="file"
                                            onChange={(event) => this.changeAttachment(event)}
                                        />
                                        <label htmlFor="uploadAttachmentInput">
                                            <Button size={"sm"} variant="contained" component="span"
                                                    className={classes.button}>
                                                Choose File
                                            </Button>
                                        </label>
                                    </GridItem>

                                    {/* Current Attachment Name */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <p style={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            marginTop: 10
                                        }}>{this.state.currentAttachmentFileName}</p>
                                    </GridItem>

                                </GridContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleCancel()} color="info">
                                    Close
                                </Button>
                                <div style={{position: "relative"}}>
                                    <Button type={"submit"} onClick={(event) => this.handleAddAttachment(event)} disabled={loadingAddAttachment}
                                            color="primary">
                                        Upload
                                    </Button>
                                    {loadingAddAttachment && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </DialogActions>
                        </form>
                    </Dialog>

                </GridContainer>
            );
        }
    }
}
export default withStyles(styles)(ContractView);
