import React from 'react';
import * as PropTypes from "prop-types";
import { withRouter} from 'react-router-dom';

//Core components
import Collapse from '@material-ui/core/Collapse';
import Tooltip from "@material-ui/core/Tooltip";

//Icons
import Close from '@material-ui/icons/Close';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

//Custom Components
import Button from "../CustomButtons/Button";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";


class CustomDashBoardDiaryItem extends React.Component{

    state = {
        open: true,
        days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    };

    //function that handles priority 2 collapse
    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    //function that routes to diary entry
    routeToViewEntry = () => {
        let path = '/app/viewDiary';
        this.props.history.push({
            pathname: path,
            state: {id: this.props.item.id}
        });
    };

    render(){
        const { classes } = this.props;

        return (
            <Card style={{marginTop: 0, marginBottom: 20}}>
                <CardBody style={{padding: 0}}>
                    <GridContainer>
                        {/*Card Header: Day*/}
                        <GridItem xs={6} sm={6} lg={6} >
                            <div style={{padding: "0 10px"}}>
                                <p style={{margin: "5px 0 0 0", fontSize: 10}}>{this.props.item.diaryDate !== null ? this.state.days[new Date(this.props.item.diaryDate).getDate()] : ""}</p>
                            </div>
                        </GridItem>

                        {/*Card Header: Action*/}
                        <GridItem xs={6} sm={6} lg={6} style={{marginBottom: 0}}>

                        {this.props.item.dailyDiaryResponse.status.toLowerCase() === "accepted" ?

                            <div style={{padding: "5px 10px 0 0"}}>
                                <Tooltip
                                    title={"Current Status - " + this.props.item.dailyDiaryResponse.status}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <DoneIcon style={{float: "right", width: 12, height: 12, color: "gray", marginLeft: 5}}/>
                                </Tooltip>
                            </div>: this.props.item.dailyDiaryResponse.status.toLowerCase() === "rejected" ?
                                    <div style={{padding: "5px 10px 0 0"}}>
                                        <Tooltip
                                            title={"Current Status - " + this.props.item.dailyDiaryResponse.status}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Close style={{float: "right", width: 12, height: 12, color: "gray", marginLeft: 5}}/>
                                        </Tooltip>
                                    </div> :
                                <div style={{padding: "5px 10px 0 0"}}>
                                    <Tooltip
                                        title={"Current Status - " + this.props.item.dailyDiaryResponse.status}
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <PriorityHigh style={{float: "right", width: 12, height: 12, color: "gray", marginLeft: 5}}/>
                                    </Tooltip>
                                </div>}
                        </GridItem>

                        {/*Card Header: Date*/}
                        <GridItem xs={6} sm={6} lg={6} >
                            <div style={{padding: "0 10px"}}>
                                <h4 style={{fontSize: "1em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", marginTop: 0, color: "#000 "}} className={classes.cardTitle}>{new Date(this.props.item.diaryDate).getDate() + " " + this.state.months[new Date(this.props.item.diaryDate).getMonth()] + " " + new Date(this.props.item.diaryDate).getFullYear()}</h4>
                            </div>
                        </GridItem>

                        {/*Priority 1: Contract Number*/}
                        <GridItem xs={6} sm={6} lg={6} >
                            <div style={{padding: "0 10px"}}>
                                <Tooltip
                                    title={"Contract: " + this.props.item.contract.number}
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                <p style={{margin: 0, fontSize: 10, float: "right"}}>{this.props.item.contract.number}</p>
                                </Tooltip>
                            </div>
                        </GridItem>

                        {/*Expand Priority 2 Button*/}
                        <GridItem xs={12} sm={12} lg={12}>
                            <div style={{height: 14}}>
                                <Button style={{padding: 0, margin: 0, backgroundColor: "rgb(20, 154, 130)", boxShadow: "none"}} fullWidth onClick={this.handleClick}>{this.state.open ?  <ExpandMore style={{cursor:"pointer",height: 14, width: 14}}/> : <ExpandLess style={{cursor:"pointer", height: 14, width: 14}}  />}</Button>
                            </div>
                        </GridItem>

                    </GridContainer>
                    <Collapse in={!this.state.open} timeout="auto" unmountOnExit>
                        <GridContainer style={{fontSize: 10, padding: "10px 10px 0 10px"}}>
                            {/*Priority 2: Submitted date and Approver*/}
                            <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Submitted:</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Reviewed by:</p>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>{this.props.item.dateDiaryCreated !== null ? new Date(this.props.item.dateDiaryCreated).getDate() + " " + this.state.months[new Date(this.props.item.dateDiaryCreated).getMonth()] + " " + new Date(this.props.item.dateDiaryCreated).getFullYear() : ""}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>{this.props.item.dailyDiaryResponse.user !== null ? this.props.item.dailyDiaryResponse.user.userDetails.name + " " + this.props.item.dailyDiaryResponse.user.userDetails.surname: "Pending"}</p>
                                    </GridItem>
                                </GridContainer>
                                <hr style={{margin: 0}}/>
                            </GridItem>

                            {/*Priority 2: Contract and Project*/}
                            <GridItem xs={12} sm={12} lg={12} style={{color: "#000", fontWeight: 400}}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Contract:</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{marginBottom: 0}}>Project:</p>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12} sm={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.props.item.contract.number}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.number}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.props.item.contract.project.title}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{marginBottom: 0, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.item.contract.project.title}</p>
                                        </Tooltip>

                                    </GridItem>
                                </GridContainer>
                                <hr style={{margin: 0}}/>
                            </GridItem>

                            {/*Priority 2: View Diary Entry Button*/}
                            <GridItem xs={12} sm={12} lg={12}>
                                <Button simple size="sm" color="primary" style={{float: "left", padding: 0, fontSize: 9, margin: "5px 0 5px 0"}} onClick={() => this.routeToViewEntry()}>View Entry</Button>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </CardBody>
            </Card>

        );
    }
}

CustomDashBoardDiaryItem.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withRouter(CustomDashBoardDiaryItem);