
const communicationClauseStyles = {
    collapseCard: {
        cursor:"pointer",
        margin: "10px 0 0 0",

    },
    collapseParagraph: {
        margin: 0,
        display: "inline"
    },
    collapseIcon : {
        color: "#000"
    },
    accordionIcon : {
        color: "#fff"
    },
    collapseButton:{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(0, 0, 0 ,0)",
        boxShadow: "none",
        float: "right",
        width: "fit-content"
    },
    gridItem:{
        margin: 0
    },
    gridMargin:{
        margin: "33px 0 0 0"
    },
    cardIcon:{
        marginTop: "-39px !important"
    }
};

export default communicationClauseStyles;
