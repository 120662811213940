import React from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import {selectedViewEventData} from "../../../variables/general.jsx";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ReactTable from "react-table";
import Close from "@material-ui/icons/Close";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import { addZero, daysArray, getCurrentDate, monthsArray, verifyLength } from "../../../globals";
import { getApiRoot } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetApp from "@material-ui/icons/GetApp";
import fileDownload from "js-file-download";
import Tooltip from "@material-ui/core/Tooltip";
import {allClausesNoticeTable, allClausesTable} from "../../../variables/general";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import {Document, Page, pdfjs} from "react-pdf";
import customSelectStyles from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import * as actionCreators from "../../../store/actions/tab";
import compensationEventTabsStyles from "../../../assets/styles/compensationEventTabsStyles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles  = theme => ({
    ...validationFormsStyle,  ...customSelectStyles, ...compensationEventTabsStyles(theme),
    input: {
        display: 'none',
    },
});

class ViewEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            event: {
                comments: [],
            },

            timeToReply: 0,
            subClauseDescription: "",

            risks:[],

            currentFile: {},
            linkedRisk: 0,
            currentChosenRisk: "",

            checkedDeclineReason1: false,
            checkedDeclineReason2: false,
            checkedDeclineReason3: false,
            checkedDeclineReason4: false,

            attachments:[],
            quoteAttachments: [],
            quotedCost: 0,
            quotedDelayCompletion: 0,
            quoteIndex: 0,

            pageNumber: 1,
            currentUploadFileName: "No file chosen",

            actionValue: 0,
            extensionDateValue: getCurrentDate(),
            extensionTimeValue: "23:59",

            revisedQuoteReason: "",

            openPreviewDialog: false,
            openRequestExtensionDialog: false,
            showAddButton: false,
            showReviewButtons: false,
            openViewAttachmentsDialog: false,
            openPreviewActionDialog: false,
            showRevisedQuoteDialog: false,
            showOwnAssessmentDialog: false,

            error: null,
            isLoaded: false,
            loadingAddEventComment: false,
            loadingSend: false,
            loadingSendDownload: false,
            loadingRequestExtension: false,

            days : daysArray(),
            months : monthsArray(),

            costImpact: selectedViewEventData.event.costImpact,
            completionDelay: selectedViewEventData.event.completionDelay,
            impairPerformance: selectedViewEventData.event.impairPerformance,
            delayKeyDates: selectedViewEventData.event.delayKeyDates,
            contractorCost: selectedViewEventData.event.contractorCost,

            comments: selectedViewEventData.event.comments,
            increaseContractorCost: selectedViewEventData.event.increaseContractorCost,
            // increaseContractorCostUpdated: selectedViewEventData.event.increaseContractorCost,

            newEventCommentValue: "",
            ownAssessmentComments: "",

            costImpactValue: 500000,
            delayImpactValue: 2,

            costImpactValueUpdated: 500000,
            delayImpactValueUpdated: 2,
            submitQuoteComments: "",

            openUploadQuoteDialog: false,
            openAddAttachmentDialog: false,

            loadingDownloadFile: false,
            loadingPreview:false,

            previewPDF: "",
            previewAttachmentsString: ""
        };

        this.routeChange = this.routeChange.bind(this);
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    getTimeToReply = () => {

        let currentDate = new Date(getCurrentDate() + " 23:59:59");

        if(this.state.event.actionPeriodDate !== null){

            let actionDate = new Date(this.state.event.actionPeriodDate);

            let timeDiff = actionDate.getTime() - currentDate.getTime();
            let actionDays = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;

            this.setState({
                timeToReply: actionDays
            });
        }
        else{

            this.setState({
                timeToReply: 0
            });
        }

        this.getRiskData();
    };

    handleOpenDropdown = (event, rowID) => {

        let value = parseInt(event.target.value);

        this.setState({ quoteIndex: rowID});

        if(value === 1){
            this.handleOpenPreviewDialog( "accept",1)
        }
        else if(value === 2){ //revised quotation
            this.setState({ showRevisedQuoteDialog: true});
        }
        else if(value === 20){
            this.setState({ showOwnAssessmentDialog: true});
        }
        else if(value === 3){
            this.handleOpen("extension");
        }
        else if(value === 22){
            this.handleOpenPreviewDialog( "NoInstruction",22)
        }

    };

    handleOpenPreviewDialog = (type, actionValue) => {

        this.setState({
            actionValue: actionValue,
        });

        this.previewAPI(type);
    };

    handleClosePreviewDialog = () => {

        this.setState({
            openPreviewActionDialog: false, actionValue: 0, previewPDF: ""
        });
    };

    //function that ensures data is validated then calls new early warning API
    handleOpen = (type, value) => {

        if(type === "addQuote"){
            this.setState({ openUploadQuoteDialog: true });
        }
        else if(type === "preview"){
            this.previewQuoteAPI();
        }
        else if(type === "addAttachment"){
            this.setState({ openAddAttachmentDialog: true});
        }
        else if(type === "extension"){
            this.setState({ openRequestExtensionDialog: true});
        }
        else if(type === "attachments"){
            this.setState({ openViewAttachmentsDialog: true });

            let index = this.state.event.quotations.findIndex(o => o.id === value);

            this.setState({
                quoteAttachments : this.state.event.quotations[index].attachments
            });
        }

        else if(type === "ownPreview") {
            let flag = true;

            if ( !this.state.checkedDeclineReason1 && !this.state.checkedDeclineReason2 && !this.state.checkedDeclineReason3 && !this.state.checkedDeclineReason4) {
                flag = false;
            }
            if (flag) {
                this.handleOpenPreviewDialog("own", 20)

            } else {
                alert("Please choose at least one statement");
            }
        }
    };

    //function that handles closing upload quote dialog
    handleClose = (type) => {

        if(type === "addQuote"){
            this.setState({ openUploadQuoteDialog: false, attachments: []});
        }
        else if(type === "preview"){
            this.setState({ openPreviewDialog: false, actionValue: 0, previewPDF: ""});
        }
        else if(type === "addAttachment"){
            this.setState({ openAddAttachmentDialog: false, currentUploadFileName: "", currentFile: {}});
        }
        else if(type === "extension"){
            this.setState({ openRequestExtensionDialog: false, extensionDateValue: getCurrentDate(), extensionTimeValue: "23:59" });
        }
        else if(type === "viewAttachment"){
            this.setState({ openViewAttachmentsDialog: false, quoteAttachments: [] });
        }
        else if(type === "revisedQuote"){
            this.setState({ showRevisedQuoteDialog: false , revisedQuoteReason: ""});
        }
        else if(type === "ownAssessment"){
            this.setState({ showOwnAssessmentDialog: false,  checkedDeclineReason1: false, checkedDeclineReason2: false, checkedDeclineReason3: false, checkedDeclineReason4: false});
        }
    };


    onChange = (event, type) => {

        if(type === "extension"){

            const id = event.target.id;

            if(id === "extensionDateInput"){
                this.setState({
                    extensionDateValue: event.target.value
                });
            }
            if(id === "extensionTimeInput"){
                this.setState({
                    extensionTimeValue: event.target.value
                });
            }
        }

        if(type === "addQuote"){

            const id = event.target.id;

            if(id === "addQuoteCostInput"){
                this.setState({
                    quotedCost: event.target.value
                });
            }
            if(id === "addQuoteDelayInput"){
                this.setState({
                    quotedDelayCompletion: event.target.value
                });
            }

        }

        if(type === "revisedQuote"){
            this.setState({
                revisedQuoteReason: event.target.value
            });
        }

        if(type === "checkBox"){

            const id = event.target.id;

            if(id === "declineReason1"){
                this.setState({
                    checkedDeclineReason1: event.target.checked
                });
            }
            if(id === "declineReason2"){
                this.setState({
                    checkedDeclineReason2: event.target.checked
                });
            }
            if(id === "declineReason3"){
                this.setState({
                    checkedDeclineReason3: event.target.checked
                });
            }
            if(id === "declineReason4"){
                this.setState({
                    checkedDeclineReason4: event.target.checked
                });
            }
        }
    };

    // open dialogs
    // handleClickOpen = (dialog, value) => {
    //
    //     if(dialog === "reject"){
    //
    //         this.setState({ openQuotesEventRejectDialog: true, quoteIndex: value});
    //     }
    // };


    // assign values of main data to variables
    handleChangeMainEventDetails = (event) => {

        const id = event.target.id;

        if(id.toString() === "eventTitleEditInput"){
            this.setState({ eventTitleUpdated: event.target.value});
        }
        else if(id.toString() === "eventContractEditInput"){
            this.setState({ eventContractUpdated: event.target.value});
        }
        else if(id.toString() === "eventProjectEditInput") {
            this.setState({ eventProjectUpdated: event.target.value});
        }
        else if(id.toString() === "eventBecameAwareDateEditInput"){
            this.setState({ becameAwareDateUpdated: event.target.value});
        }
    };

    // assign values of global data to variables
    handleChangeGlobalEventDetails = (event) => {
        const id = event.target.id;

        if(id.toString() === "linkedRiskNameEditInput") {
            let index = this.state.risks.findIndex(o => o.id === parseInt(event.target.value));

            this.setState({
                linkedRisk: event.target.value, currentChosenRisk: index
            });
        }
    };

    // assign values of summary data to variables
    handleChangeSummaryEventDetails = (event) => {
        const id = event.target.id;

        if(id.toString() === "eventStatusEditInput"){
            this.setState({ eventStatusUpdated: event.target.value});
        }
        else if(id.toString() === "eventTypeEditInput"){
            this.setState({ eventTypeUpdated: event.target.value});
        }
        else if(id.toString() === "eventDetailsEditInput") {
            this.setState({ eventDetailsUpdated: event.target.value});
        }
        else if(id.toString() === "affectedActivitiesEditInput") {
            this.setState({ affectedActivitiesUpdated: event.target.value});
        }
    };


    // assign values of impact data to variables
    handleChangeImpactEventDetails = (event) => {
        const id = event.target.id;

        if(id.toString() === "costImpactEditInput"){
            this.setState({ costImpactUpdated: event.target.value});
        }
        else if(id.toString() === "costImpactValueEditInput"){

            if(event.target.value === ""){
                this.setState({costImpactValueEditState: ""});
            }
            else if(ViewEvent.verifyNumber(event.target.value)) {
                this.setState({costImpactValueEditState: "success"});
            }
            else{
                this.setState({costImpactValueEditState: "error"});
            }
            this.setState({ costImpactValueUpdated: event.target.value});
        }
        else if(id.toString() === "completionDelayEditInput"){
            this.setState({ completionDelayUpdated: event.target.value});
        }
        else if(id.toString() === "completionDelayValueEditInput"){

            if(event.target.value === ""){
                this.setState({completionDelayImpactValueEditState: ""});
            }
            else if(ViewEvent.verifyNumber(event.target.value)) {
                this.setState({completionDelayImpactValueEditState: "success"});
            }
            else{
                this.setState({completionDelayImpactValueEditState: "error"});
            }

            this.setState({ delayImpactValueUpdated: event.target.value});
        }
        else if(id.toString() === "impairPerformanceEditInput") {
            this.setState({ impairPerformanceUpdated: event.target.value});
        }
        else if(id.toString() === "delayKeyDatesEditInput") {
            this.setState({ delayKeyDatesUpdated: event.target.value});
        }

    };


    //function that handles adding an attachment to the attachments array
    addAttachment = () => {
        let arr = this.state.attachments;

        let obj = {
            fileName: this.state.currentUploadFileName,
            file: this.state.currentFile
        };

        arr.push(obj);

        this.setState({
            attachments: arr, openAddAttachmentDialog: false, currentUploadFileName: "", currentFile: {}
        });

        this.createAttachmentsString(arr);
    };

    //function that handles removing an attachment from the attachments array
    removeAttachment = (index) => {
        let arr = this.state.attachments;
        arr.splice(index, 1);

        this.setState({
            attachments: arr
        });

        this.createAttachmentsString(arr);
    };

    createAttachmentsString = (arr) => {

        if(arr.length !== 0) {

            let attachmentsString = "";

            arr.forEach(object => {
                attachmentsString += object.fileName + "; ";
            });

            this.setState({
               previewAttachmentsString: attachmentsString
            });
        }
        else{
            this.setState({
                previewAttachmentsString: ""
            });
        }
    };

    //function that handles onChange of the upload button
    changeUpload = (event) => {

        if(event.target.files.length === 0){
            this.setState({
                currentUploadFileName: "No file chosen", currentFile: {}
            });
        }
        else{
            this.setState({
                currentUploadFileName: event.target.files[0].name, currentFile: event.target.files[0]
            });
        }

    };


    //set the state of comment variables
    onCommentChange = (event, type) => {

        if(type === "event"){
            this.setState( { newEventCommentValue: event.target.value}
            );
        }
        if(type === "ownAssessment"){
            this.setState( {
                ownAssessmentComments: event.target.value
            });
        }
        if(type === "submitQuote"){
            this.setState({
                submitQuoteComments: event.target.value
            });
        }
    };

    //function that handles adding a comment
    addComment(type) {

        let data = "";

        if(type === "event"){

            this.setState({
                loadingAddEventComment: true
            });

            data = JSON.stringify({
                compensationEvent: this.props.location.state.id,
                comment: this.state.newEventCommentValue
            });
        }

        const headers = new Headers({
            "Content-Type": "application/json",
        });


        fetch(getApiRoot() + '/api/create_comment', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(type === "event"){
                this.setState({ loadingAddEventComment : false });
            }

            if(res.status === 200){
                this.setState({ newEventCommentValue : "" });
                this.getEventData();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
    }



    //function that calls quote response preview api with response value
    previewAPI = (type) => {

        let declinedStatementsQuotation = [];
        this.setState({
            loadingPreview: true
        });
        let obj = {};

        if(this.state.checkedDeclineReason1){
            declinedStatementsQuotation.push({description : "has not submitted the required quotation and details of his assessment within the time allowed"});
        }
        if(this.state.checkedDeclineReason2){
            declinedStatementsQuotation.push({description : "has not assessed the compensation event correctly"});
        }
        if(this.state.checkedDeclineReason3){
            declinedStatementsQuotation.push({description : "has not submitted a programme which this contract requires the Contractor to submit"});
        }
        if(this.state.checkedDeclineReason4){
            declinedStatementsQuotation.push({description : "the Contractor’s latest programme has not been accepted for one of the reasons stated in this contract"});
        }

        if (type === "accept"){//1
            obj = {
                id: this.props.location.state.id,
                previewAction: "accept"
            }
        }
        else if (type === "revised"){//2
            if(this.state.revisedQuoteReason === ""){
                alert("Reason required when revised quotation needs to be submitted");
                return;
            }

            obj = {
                id: this.props.location.state.id,
                previewAction: "revised",
                revisedQuotationReason: this.state.revisedQuoteReason};
        }
        else if (type === "own"){//20

            obj = {
                id: this.props.location.state.id,
                previewAction: "own",
                declinedStatementsQuotation: declinedStatementsQuotation,
                ownAssessmentComment: this.state.ownAssessmentComments
            };
        }
        else {//22
            obj = {
                id: this.props.location.state.id,
                previewAction: "noInstruction"
            };
        }

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/preview_compensation_event', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            if(result !== undefined){

                this.setState({
                    previewPDF: result, openPreviewActionDialog: true, loadingPreview: false
                });
            }

        }).catch(error => alert(error));
    };


    handleActionClick = (value) => {

        this.setState({
            actionValue: value
        });

        if (verifyLength(this.state.extensionDateValue, 1) && verifyLength(this.state.extensionTimeValue, 1)) {
            this.handleActionAPI("extension");
        }
        else{
            alert("The date of the requested extension cannot be empty");
        }
    };


    // function that handles onClick of actions (request extension)
    handleActionAPI = (type) => {

        let data = {};

        if(type === "send"){
            this.setState({
                loadingSend: true
            });
        }
        else if(type === "sendDownload"){
            this.setState({
                loadingSendDownload: true
            });
        }
        else{
            this.setState({
                loadingRequestExtension: true
            });
        }
        if(this.state.actionValue === 1){
            data = {
                type: "Quotation",
                id: this.state.quoteIndex,
                response: this.state.actionValue,
                state: this.state.event.eventState.stateNr,
            };
        }
        else if(this.state.actionValue === 2){

            data = {
                type: "Quotation",
                id: this.state.quoteIndex,
                response: this.state.actionValue,
                state: this.state.event.eventState.stateNr,
            };
        }
        else if(this.state.actionValue === 20){

            data = {
                type: "Quotation",
                id: this.state.quoteIndex,
                stateChange: this.state.actionValue,
                state: this.state.event.eventState.stateNr,
            };
        }
        else if(this.state.actionValue === 22){

            data = {
                type: "Quotation",
                id: this.state.quoteIndex,
                stateChange: this.state.actionValue,
                state: this.state.event.eventState.stateNr,
            };
        }
        else{
            let concatDate = this.state.extensionDateValue + " " + this.state.extensionTimeValue;

            data = {
                type: "CE",
                id: this.props.location.state.id,
                response: 3,
                state: this.state.event.eventState.stateNr,
                extensionRequestDate: concatDate
            };
        }


        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/action', {
            credentials: "include",
            method: "POST",
            headers,
            body: JSON.stringify(data),
        }).then(res => {

            if (res.status === 200) {

                if(type === "send" || type === "sendDownload"){
                    this.sendApprovalAPI(type);
                }
                else{
                    this.setState({
                        loadingRequestExtension: false
                    });

                    window.location.reload();
                }

            } else {
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
    };


    //implementation of send_quotation and send_quotation_and_download api
    sendApprovalAPI = (type) => {

        let obj = {};

        if(this.state.actionValue === 20){
            let declinedStatementsQuotation = [];

            if(this.state.checkedDeclineReason1){
                declinedStatementsQuotation.push({description : "has not submitted the required quotation and details of his assessment within the time allowed"});
            }
            if(this.state.checkedDeclineReason2){
                declinedStatementsQuotation.push({description : "has not assessed the compensation event correctly"});
            }
            if(this.state.checkedDeclineReason3){
                declinedStatementsQuotation.push({description : "has not submitted a programme which this contract requires the Contractor to submit"});
            }
            if(this.state.checkedDeclineReason4){
                declinedStatementsQuotation.push({description : "the Contractor’s latest programme has not been accepted for one of the reasons stated in this contract"});
            }

            obj = {
                id: this.props.location.state.id,
                declinedStatementsQuotation: declinedStatementsQuotation,
                ownAssessmentComment: this.state.ownAssessmentComments
            };
        }
        else{
            obj = {
                id: this.props.location.state.id,
            };
        }

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        let path = "/api/";

        if(type === "sendDownload"){
            path += "send_compensation_event_and_download";
        }
        else{
            path += "send_compensation_event";
        }

        let fileName = "CE Approval.pdf";

        fetch(getApiRoot() + path, {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){

                if(type === "sendDownload"){

                    if( res.headers.get('Content-Disposition') !== null) {
                        let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                        if(strArr.length > 1)
                            fileName = strArr[1];
                    }

                    return res.blob();
                }
                else{
                    return res.text();
                }
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);

                if(type === "sendDownload"){
                    this.setState({ loadingSendDownload : false });
                }
                else{
                    this.setState({ loadingSend : false });
                }
            }

        }).then(result => {

            if(result !== undefined) {

                if(type === "sendDownload"){

                    this.setState({
                        loadingSendDownload: false
                    });

                    fileDownload(result, fileName);
                    window.location.reload();
                }
                else{
                    this.setState({
                        loadingSend: false
                    });

                    window.location.reload();
                }
            }
        }).catch(error => alert(error));
    };


    downloadFileAPI = (uuid, fileName) => {

        fetch(getApiRoot() + '/api/download_file/' + uuid, {
            credentials: "include",
            method: "GET",
        }).then(res => res.blob())
            .then(result => {

                fileDownload(result, fileName);

                this.setState({
                    loadingDownloadFile: false
                });

            }).catch(error => alert(error));
    };


    //function that calls preview api with response value when creating quote
    previewQuoteAPI = () => {

        this.setState({
            loadingPreview: true
        });

        let obj = {
            id: this.props.location.state.id,
            alteredProgrammeRemainingWork: this.state.alteredProgrammeRemainingWork,
            previewAttachmentsString: this.state.previewAttachmentsString,
            quotationComment: this.state.submitQuoteComments
        };

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/preview_compensation_event', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.blob();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            if(result !== undefined){
                this.setState({
                    previewPDF: result, openPreviewDialog: true, loadingPreview: false
                });
            }

        }).catch(error => alert(error));
    };


    //function that handles onClick of submit quote button
    handleSendQuote = (type) => {

        if(type === "send"){
            this.setState({
                loadingSend: true
            });
        }

        if(type === "sendDownload"){
            this.setState({
                loadingSendDownload: true
            });
        }

        let data = new FormData();
        data.append('compensationEvent', this.props.location.state.id);
        data.append('quotedCost', this.state.quotedCost);
        data.append('quotedDelayCompletion', this.state.quotedDelayCompletion);

        this.state.attachments.forEach(object => {
            data.append('file', object.file);
        });

        fetch(getApiRoot() + '/api/submit_quotation', {
            credentials: "include",
            method: "POST",
            body: data,
        }).then(res => {

            if(res.status === 200){
                return res.json();
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).then(result => {

            if(result !== undefined){

                this.sendAPI(result.id, type);
            }

        }).catch(error => alert(error));
    };


    //implementation of send_quotation and send_quotation_and_download api
    sendAPI = (id, type) => {

        let obj = {
            id: this.props.location.state.id,
            alteredProgrammeRemainingWork: this.state.alteredProgrammeRemainingWork,
            previewAttachmentsString: this.state.previewAttachmentsString,
            quotationComment: this.state.submitQuoteComments
        };

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        let path = "/api/";

        if(type === "sendDownload"){
            path += "send_compensation_event_and_download";
        }
        else{
            path += "send_compensation_event";
        }

        let fileName = "Quotation.pdf";

        fetch(getApiRoot() + path, {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            if(res.status === 200){

                if(type === "sendDownload"){

                    if( res.headers.get('Content-Disposition') !== null) {
                        let strArr = res.headers.get('Content-Disposition').toString().split("\"");

                        if(strArr.length > 1)
                            fileName = strArr[1];
                    }

                    return res.blob();
                }
                else{
                    return res.text();
                }
            }
            else{
                alert("Something went wrong - HTTP: " + res.status);

                if(type === "sendDownload"){
                    this.setState({ loadingSendDownload : false });
                }
                else{
                    this.setState({ loadingSend : false });
                }
            }

        }).then(result => {

            if(result !== undefined) {

                if(type === "sendDownload"){

                    this.setState({
                        loadingSendDownload: false
                    });

                    fileDownload(result, fileName);
                    window.location.reload();
                }
                else{

                    this.setState({
                        loadingSend: false
                    });

                    window.location.reload();
                }
            }

        }).catch(error => alert(error));
    };


    getPermissions = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/permissions", {
            credentials: "include",
            headers,
            method: "GET",
        }).then(res => {
            if(res.status === 200){
                return res.json();
            }
            else{
                this.setState({
                    isLoaded: true,
                });

                alert("Something went wrong - HTTP " + res.status);
            }
        }).then(
            (result) => {

                if(result !== undefined){
                    this.setPermissions(result);
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    };


    setPermissions = (permissions) => {

        if(permissions.length !== 0){


            let creatCE = permissions.find(o => o.type === "compensationEvent" && o.subtype === "create").permission;
            let creatCEN = permissions.find(o => o.type === "compensationEventNotification" && o.subtype === "create").permission;

            if (this.state.event.eventState.stateNr === 20 ){/*if PM's own Assessment*/

                if(creatCE === "true"){
                    this.setState({
                        showAddButton: true
                    });
                }
                else{
                    this.setState({
                        showAddButton: false
                    });
                }

                this.setState({
                    showReviewButtons: false
                });
            }
            else if(this.state.event.eventState.stateNr === 8 || this.state.event.eventState.stateNr === 11 ){/*if submit Q or submit alternative Q*/

                if(creatCEN === "true"){
                    this.setState({
                        showAddButton: true
                    });
                }
                else{
                    this.setState({
                        showAddButton: false
                    });
                }

                this.setState({
                    showReviewButtons: false
                });

            }else{

                this.setState({
                    showAddButton: false
                });
            }

            if (this.state.event.eventState.stateNr === 21 ){/*if PM's assessment submitted*/

                this.setState({
                    showReviewButtons: false,
                    showAddButton: false
                });
            }
            else if (this.state.event.eventState.stateNr === 9 ||  this.state.event.eventState.stateNr === 12 ||  this.state.event.eventState.stateNr === 13 ||
                this.state.event.eventState.stateNr === 15 ||  this.state.event.eventState.stateNr === 16 ||  this.state.event.eventState.stateNr === 19  ) { /*Review Q, Review alternate Q, Review Q, Review alternate Q, review Q(cl 62.6), Review alternative Q(cl 62.6)*/

                if(creatCE === "true"){
                    this.setState({
                        showReviewButtons: true
                    });
                }
                else{
                    this.setState({
                        showReviewButtons: false
                    });
                }

                this.setState({
                    showAddButton: false
                });

            }else{

                this.setState({
                    showReviewButtons: false
                });
            }
        }
        else{
            this.setState({
                showAddButton: false,
                showReviewButtons: false,
            });
        }

        this.setState({
            isLoaded: true,
        });
    };


//route for button at end of page
    routeChange() {
        if(this.state.event.clauseNumber === "61.3")
        {
            this.props.onSetSelectedTab(0);
        }
        else if (this.state.event.clauseNumber === "61.2")
        {
            this.props.onSetSelectedTab(2);
        }

        let path = "/app/allEventRegister";
        this.props.history.push({
            pathname: path,
        });
    }


    getEventData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/compensation_event/" + this.props.location.state.id, {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result !== undefined){
                        this.setState({
                            // isLoaded: true,
                            event: result,
                            loadingDownloadFile: false
                        });
                        this.getSubClauseDescription();
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                        loadingDownloadFile: false
                    });
                }
            )
    };

    //Get sub clause description
    getSubClauseDescription = () => {

        if(this.state.event.clauseNumber === "61.3")
        {
            let clauseIndex = allClausesNoticeTable.clauses.findIndex(o => o.number === this.state.event.clauseNumber);
            let subClauseIndex = allClausesNoticeTable.clauses[clauseIndex].subClauses.data.findIndex(o => o.number === this.state.event.subClauseNumber);

            this.setState({
                subClauseDescription: allClausesNoticeTable.clauses[clauseIndex].subClauses.data[subClauseIndex].description
            });
        }else
        {
            let clauseIndex = allClausesTable.clauses.findIndex(o => o.number === this.state.event.clauseNumber);
            let subClauseIndex = allClausesTable.clauses[clauseIndex].subClauses.data.findIndex(o => o.number === this.state.event.subClauseNumber);


            this.setState({
                subClauseDescription: allClausesTable.clauses[clauseIndex].subClauses.data[subClauseIndex].description
            });
        }

        this.getTimeToReply();
    };


    // get risk list
    getRiskData = () => {

        const headers = new Headers({
            "Accept": "application/json",
        });

        fetch(getApiRoot() + "/api/risks", {
            credentials: "include",
            headers,
            method: "GET"
        })
            .then(res => res.json())
            .then(
                (result) => {

                    if(result !== undefined) {
                        this.setState({
                            // isLoaded: true,
                            risks: result,
                        });
                        this.getPermissions();
                    }

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    };


    // call API for adding event
    linkRiskEventNoticeAPI = () => {

        let obj = {
            risk_id: parseInt(this.state.linkedRisk),
            ce_id: this.props.location.state.id,
        };

        let data = JSON.stringify(obj);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        fetch(getApiRoot() + '/api/link_risk_compensation_event', {
            credentials: "include",
            method: "POST",
            headers,
            body: data,
        }).then(res => {

            this.setState({ loading: false });

            if(res.status === 200){

                let obj = this.state.risks.find(o => o.id === parseInt(this.state.linkedRisk));

                this.setState({
                    event: Object.assign(
                        {}, this.state.event, {
                            risk: Object.assign(
                                {}, this.state.event.risk, {
                                    description: obj.description
                                }
                            )
                        }
                    )
                });

            } else {
                alert("Something went wrong - HTTP: " + res.status);
            }

        }).catch(error => alert(error));
    };


    //function that retrieves initial Event data to populate the page
    componentDidMount() {
        this.getEventData();

        if(this.state.event.clauseNumber === "61.2"){

            this.setState({
                alteredProgrammeRemainingWork: "As the programme for remaining work is likely to be affected by the proposed instruction or changed decision, we have included proposed alterations to the Accepted Programme.",
                alteredProgrammeRemainingWorkOptions: [{
                    value: 0, short: "The programme for remaining work is likely to be affected", long: "As the programme for remaining work is likely to be affected by the proposed instruction or changed decision, we have included proposed alterations to the Accepted Programme."
                }
                    ,{
                        value: 1, short: "The programme for remaining work is not likely to be altered", long: "As the programme for remaining work is not likely to be altered by the proposed event, we have not included any proposed alterations to the Accepted Programme."

                    }],
            });
        }
        else{

            this.setState({
                alteredProgrammeRemainingWork: "As the programme for remaining work is altered by the compensation event, we have included the alterations to the Accepted Programme.",
                alteredProgrammeRemainingWorkOptions:
                    [
                        {
                            value: 0, short: "The programme for remaining work is altered", long: "As the programme for remaining work is altered by the compensation event, we have included the alterations to the Accepted Programme."
                        },
                        {
                            value: 1, short: "The programme for remaining work is not altered", long: "As the programme for remaining work is not altered by the compensation event, we have not included any alterations to the Accepted Programme due to this event."
                        }
                    ],
            });

        }
    }


    render() {
        const { classes } = this.props;
        //loading wheel
        const { error, isLoaded, loadingDownloadFile, loadingPreview, loadingSendDownload, loadingSend, loadingRequestExtension, numPages } = this.state;
        if (error) {
            return  <GridContainer><GridItem xs={12} style={{textAlign: "center"}}>Error: {error.message}</GridItem></GridContainer>;
        } else if (!isLoaded) {
            return <GridContainer><GridItem xs={12} style={{textAlign: "center"}}><CircularProgress color={"primary"} className={classes.progress} style={{position: "absolute", top: "50%", left: "calc(50% - 20px)"}}/></GridItem></GridContainer>;
        } else {
            return (
                // start of page
                //event description
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={6} className={classes.alignFirstCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={12} lg={12}>
                                        <h4 style={{marginBottom: 20}}><strong>{this.state.event.description}</strong></h4>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14}}><strong>Contract:</strong> {this.state.event.contract.number}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 14, float: "right"}}>
                                            <strong>Project:</strong> {this.state.event.contract.project.title}</p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12}}>Submitted: {this.state.event.dateCreated !== null ? new Date(this.state.event.dateCreated).getDate() + " " + this.state.months[new Date(this.state.event.dateCreated).getMonth()] + " " + new Date(this.state.event.dateCreated).getFullYear() : ""}</p>
                                    </GridItem>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 12, float: "right"}}>Expected to occur: {this.state.event.dateExpectedToHappen !== null ? new Date(this.state.event.dateExpectedToHappen).getDate() + " " + this.state.months[new Date(this.state.event.dateExpectedToHappen).getMonth()] + " " + new Date(this.state.event.dateExpectedToHappen).getFullYear() : "N/A"}</p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={4} className={classes.alignCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} lg={12}>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 10, marginTop: 10}}>
                                            <strong><i>Project Manager</i>:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.state.event.user.userDetails.name +" "+ this.state.event.user.userDetails.surname + " (" + this.state.event.user.username + ")"}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 12, marginBottom: 10, marginTop: 10, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.event.user.userDetails.name +" "+ this.state.event.user.userDetails.surname + " (" + this.state.event.user.username + ")"}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 10, marginTop: 10}}><strong>Linked Risk:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.state.event.risk ? this.state.event.risk.description : "No Linked Risk"}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <p style={{fontSize: 12, marginBottom: 10, marginTop: 10, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.event.risk ? this.state.event.risk.description : "No Linked Risk"}</p>
                                        </Tooltip>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 12, marginBottom: 5, marginTop: 10}}><strong>Time to reply:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <Tooltip
                                            title={this.state.timeToReply + " Day(s) left - " + this.state.event.eventState.description}
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                        <p style={{fontSize: 12, marginBottom: 5, marginTop: 10, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.state.timeToReply + " Day(s) left - " + this.state.event.eventState.description}</p>
                                        </Tooltip>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*event summary details*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <p style={{fontSize: 16, marginBottom: 30}}><strong>Event Summary</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={6}>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Event Number:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.ceNumber}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Status:</strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.eventState.description}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Clause: </strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.clauseNumber}</p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={2}>
                                        <p><strong>Sub-clause: </strong></p>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} lg={10}>
                                        <div style={{fontSize: 14}}><p>{this.state.event.subClauseNumber}</p> {this.state.subClauseDescription}</div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={2}>
                                        <p><strong>Details:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.details}</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={2}>
                                        <p><strong>Assumptions:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.assumptions}</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={2}>
                                        <p><strong>Affected Activities:</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} lg={10}>
                                        <p style={{fontSize: 14}}>{this.state.event.affectedActivities}</p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*Attachments*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 16}}><strong>Attachments</strong></p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{position: "relative"}}>
                                            <ReactTable

                                                data={this.state.event.attachments}
                                                filterable={false}
                                                sortable={false}
                                                resizable={false}
                                                noDataText="No attachments"
                                                columns={[
                                                    {
                                                        Header: "File",
                                                        accessor: "fileName",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                    },
                                                    {
                                                        Header: "Uploaded By",
                                                        accessor: "user.userDetails",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value.name + " " + row.value.surname}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Uploaded",
                                                        accessor: "attachmentCreated",
                                                        headerStyle: {fontSize: 14, fontWeight: 400},
                                                        Cell: row => (
                                                            <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</p>
                                                        ),
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "id",
                                                        headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                        style: {"textAlign": "center"},
                                                        Cell: row => (
                                                            <div>
                                                                <Button simple disabled={loadingDownloadFile} onClick={() => {

                                                                    this.setState({
                                                                        loadingDownloadFile: true
                                                                    });

                                                                    let id = this.state.event.attachments.findIndex(o => o.id === row.value);
                                                                    let uuid = this.state.event.attachments[id].uuid;
                                                                    let fileName = this.state.event.attachments[id].fileName;

                                                                    this.downloadFileAPI(uuid, fileName);

                                                                }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                                defaultSorted={[
                                                    {
                                                        id: "id",
                                                        desc: true
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationBottom={true}
                                                className="-striped -highlight"
                                                showPageJump={false}
                                            />
                                            { loadingDownloadFile && <CircularProgress size={24} style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '50%',
                                                marginTop: -12,
                                                marginLeft: -12
                                            }}/>}
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*Quotes*/}
                    <GridItem xs={12} sm={12} lg={10} className={classes.alignCard}>
                        <Card style={{marginBottom: 0}}>
                            <CardBody style={{padding: 30}}>
                                <GridContainer>
                                    <GridItem xs={6} lg={6}>
                                        <p style={{fontSize: 16}}><strong>Quotes/Assessments</strong></p>
                                    </GridItem>
                                    { this.state.showAddButton ?
                                    <GridItem xs={6} sm={6} lg={6}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} lg={12}>

                                                {this.state.event.eventState.stateNr === 8 ?
                                                    <Button size="sm" color="primary" style={{float: "right", margin: 0}} onClick={() => this.handleOpen("addQuote")}>Submit Quote</Button>
                                                    : this.state.event.eventState.stateNr === 11 ?
                                                        <Button size="sm" color="primary" style={{float: "right", margin: 0}} onClick={() => this.handleOpen("addQuote")}>Submit Revised Quote</Button>
                                                        : <Button size="sm" color="primary" style={{float: "right", margin: 0}} onClick={() => this.handleOpen("addQuote")}>Own Assessment</Button>
                                                }
                                                {this.state.event.extension === null && this.state.event.eventState.stateNr !== 20 ?
                                                    <Button size="sm" simple color="primary" style={{float: "right", padding: 2, marginRight: 10}} onClick={() => this.handleOpen("extension")}>Request Extension</Button>
                                                    : ""}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} lg={12}>

                                            </GridItem>
                                        </GridContainer>
                                    </GridItem> : "" }

                                    <GridItem xs={12} lg={12}>
                                        <ReactTable
                                            data={this.state.event.quotations}
                                            filterable={false}
                                            sortable={false}
                                            resizable={false}
                                            noDataText="No Quotes added yet"
                                            columns={[
                                                {
                                                    Header: "#",
                                                    maxWidth: 130,
                                                    accessor: "id",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                    Cell: row => (
                                                      <div>{this.state.event.quotations[this.state.event.quotations.findIndex(o => o.id === row.value)].type === "quotation" ? "Quote " + this.state.event.quotations[this.state.event.quotations.findIndex(o => o.id === row.value)].number : "Assessment " + this.state.event.quotations[this.state.event.quotations.findIndex(o => o.id === row.value)].number}</div>
                                                    )
                                                },
                                                {
                                                    Header: "Cost",
                                                    maxWidth: 100,
                                                    accessor: "quotedCost",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                },
                                                {
                                                    Header: "Delay to completion (Days)",
                                                    accessor: "quotedDelayCompletion",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                },
                                                {
                                                    Header: "Uploaded By",
                                                    accessor: "user.userDetails",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                    Cell: row => (
                                                        <div>{row.value.name + " " + row.value.surname}</div>
                                                    ),
                                                },
                                                {
                                                    Header: "Upload Date",
                                                    maxWidth: 180,
                                                    accessor: "dateCreated",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                    Cell: row => (
                                                        <div>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  addZero(new Date(row.value).getHours()) + ":" + addZero(new Date(row.value).getMinutes()) + ":" + addZero(new Date(row.value).getSeconds()) : ""}</div>
                                                    ),
                                                },
                                                {
                                                    Header: "Actions",
                                                    maxWidth: 215,
                                                    accessor: "id",
                                                    headerStyle: {fontSize: 14, fontWeight: 400},
                                                    Cell: row => (
                                                        <div>

                                                            { this.state.event.quotations.find(o => o.id === row.value).status.toLowerCase() === "pending" && this.state.showReviewButtons ?
                                                                <FormControl style={{width: 135}}>
                                                                <Select
                                                                native
                                                                onChange={(event) => this.handleOpenDropdown(event, row.value)}
                                                                value={0}
                                                                >
                                                                <option disabled value={0}>Choose Action</option>

                                                                <option value={1}>Accept</option>

                                                                <option value={20}>Own Assessment</option>

                                                                <option value={2}>Revised Quotation</option>

                                                                {this.state.event.extension === null ?
                                                                <option value={3}>Request Extension</option>
                                                                : ""}

                                                                {this.state.event.clauseNumber.toLowerCase() === "61.2" ?
                                                                <option value={22}>No Instruction</option>
                                                                : ""}

                                                                </Select>
                                                                </FormControl>
                                                                : ""}

                                                            <Tooltip
                                                                title={"View Attachments"}
                                                                placement="top"
                                                                classes={{ tooltip: classes.tooltip }}
                                                            >
                                                            <Button
                                                                size={"sm"}
                                                                justIcon
                                                                round
                                                                simple
                                                                color="primary"
                                                                onClick={() => this.handleOpen("attachments", row.value)}
                                                            >
                                                                <LibraryBooksIcon />
                                                            </Button>
                                                            </Tooltip>
                                                        </div>
                                                    ),
                                                }

                                            ]}
                                            defaultSorted={[
                                                {
                                                    id: "id",
                                                    desc: true
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                            showPageJump={false}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    {/*Button to return to event register*/}
                    <GridContainer justify={"center"}>
                        <GridItem xs={12} sm={12} lg={10}>
                            <Button simple color={"primary"} style={{marginTop: 15}} onClick={this.routeChange}>Back to Event Register</Button>
                        </GridItem>
                    </GridContainer>



                    {/*start of dialogs */}
                    {/*
                //
                //
                //
                //
                */}

                    {/* Add Quote Dialog*/}
                    <Dialog
                        open={this.state.openUploadQuoteDialog}
                        onClose={() => this.handleClose("addQuote")}
                        aria-labelledby="submitQuoteDialog"
                        maxWidth={"xs"}
                    >
                        {
                            this.state.event.eventState.stateNr === 8 ?
                            <DialogTitle id="submitQuoteDialog">Submit Quote</DialogTitle>
                            : this.state.event.eventState.stateNr === 11 ?
                                <DialogTitle id="submitQuoteDialog">Submit Revised Quote</DialogTitle>
                                : <DialogTitle id="submitQuoteDialog">Own assessment</DialogTitle>
                        }
                        <DialogContent>
                            <GridContainer justify={"center"}>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                        autoFocus
                                        id="addQuoteCostInput"
                                        labelText="Amount (ZAR)"
                                        labelProps={{}}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {marginBottom: 0}
                                        }}
                                        inputProps={{
                                            value: this.state.quoteCost,
                                            onChange: (event) => this.onChange(event, "addQuote"),
                                            type: "number",
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                        autoFocus
                                        id="addQuoteDelayInput"
                                        labelText="Delay to Completion (Days)"
                                        labelProps={{}}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {marginBottom: 30}
                                        }}
                                        inputProps={{
                                            value: this.state.quoteDelay,
                                            onChange: (event) => this.onChange(event, "addQuote"),
                                            type: "number",
                                        }}
                                    />
                                </GridItem>


                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <FormControl>
                                        <Select
                                            native
                                            value={this.state.alteredProgrammeRemainingWork}
                                            onChange={(event) => this.setState({
                                                alteredProgrammeRemainingWork : event.target.value
                                            })}
                                            inputProps={{
                                                id: 'alteredProgrammeRemainingWork',
                                                // style: {fontSize: 12}
                                            }}
                                        >
                                            {this.state.alteredProgrammeRemainingWorkOptions ? this.state.alteredProgrammeRemainingWorkOptions.map((item, index) => (


                                                <option key={index} value={item.long}>{item.short}</option>
                                            )) : ""}
                                        </Select>
                                    </FormControl>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <FormControl fullWidth>
                                        <CustomInput
                                            labelText="Comments"
                                            id="submitQuoteComments"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                multiline: true,
                                                rows: 5,
                                                value: this.state.submitQuoteComments,
                                                onChange: (event) => this.onCommentChange(event, "submitQuote")
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>


                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <hr style={{margin: "20px 0 10px 0"}}/>
                                    <GridContainer>
                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                            <p style={{marginBottom: 0}}><strong>Attachments</strong></p>
                                        </GridItem>
                                        {/*Add Attachment button */}
                                        <GridItem xs={4} sm={4} lg={4}>
                                            <Add style={{cursor: "pointer", fontSize: 16, float: "right", marginTop: 3}}
                                                 onClick={() => this.handleOpen("addAttachment")}/>
                                        </GridItem>
                                    </GridContainer>
                                    <hr style={{margin: "10px 0 10px 0"}}/>
                                </GridItem>

                                {/* Current Attachments list */}
                                <GridItem xs={12} lg={12}>
                                    <List>
                                        {
                                            this.state.attachments.length ? this.state.attachments.map((item, index) =>
                                                <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}}
                                                          key={index}>
                                                    {item.fileName}
                                                    <ListItemSecondaryAction>
                                                        <Close
                                                            style={{cursor: "pointer", fontSize: 16, marginTop: 5, color: "#f44336"}}
                                                            onClick={() => this.removeAttachment(index)}/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>)
                                                : "No Attachments"
                                        }
                                    </List>
                                </GridItem>

                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose("addQuote")} color="info">
                                Close
                            </Button>
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleOpen("preview")}
                                        color="primary">
                                    Preview
                                </Button>
                                {loadingPreview && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>

                        </DialogActions>
                    </Dialog>

                    {/* Preview add quote CE */}
                    <Dialog
                        open={this.state.openPreviewDialog}
                        onClose={() => this.handleClose("preview")}
                        aria-labelledby="previewDialog"
                        maxWidth={false}
                    >
                        <DialogTitle id="previewDialog">Preview Submit Quotation</DialogTitle>
                        {this.state.previewPDF !== "" ?
                            <DialogContent>
                                <div>
                                    <Document
                                        file={this.state.previewPDF}
                                        onLoadSuccess={this.onDocumentLoad}
                                    >
                                        {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                                            <Page key={i+1} pageNumber={i+1} />
                                        ): ""}
                                    </Document>
                                </div>
                            </DialogContent>
                            : ""}
                        <DialogActions>

                            {/* Preview Back Button */}
                            <Button onClick={() => this.handleClose("preview")}>
                                Back
                            </Button>

                            {/* Preview Send & Download Button */}
                            <div style={{position: "relative"}}>
                                <Button color="info" onClick={() => this.handleSendQuote("sendDownload")}>
                                    Send & Download
                                </Button>
                                {loadingSendDownload && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>

                            {/* Preview Send Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleSendQuote("send")}
                                        color="primary" >
                                    Send
                                </Button>
                                {loadingSend && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>

                    {/* Preview when accepting or rejecting CE */}
                    <Dialog
                        open={this.state.openPreviewActionDialog}
                        onClose={() => this.handleClosePreviewDialog()}
                        aria-labelledby="previewDialog"
                        maxWidth={false}
                    >
                        <DialogTitle id="previewDialog">Preview Quotation Review</DialogTitle>
                        {this.state.previewPDF !== "" ?
                            <DialogContent>
                                <div>
                                    <Document
                                        file={this.state.previewPDF}
                                        onLoadSuccess={this.onDocumentLoad}
                                    >
                                        {numPages !== null ? Array.from(Array(numPages), (e, i) =>
                                            <Page key={i+1} pageNumber={i+1} />
                                        ): ""}
                                    </Document>
                                </div>
                            </DialogContent>
                            : ""}
                        <DialogActions>

                            {/* Preview Back Button */}
                            <Button onClick={() => this.handleClosePreviewDialog()}>
                                Back
                            </Button>

                            {/* Preview Send & Download Button */}
                            <div style={{position: "relative"}}>
                                <Button  color="info" onClick={() => this.handleActionAPI("sendDownload")}>
                                    Send & Download
                                </Button>
                                {loadingSendDownload && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>

                            {/* Preview Send Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"}
                                        color="primary" onClick={() => this.handleActionAPI("send")} >
                                    Send
                                </Button>
                                {loadingSend && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>


                    {/* Upload Attachment Dialog */}
                    <Dialog
                        open={this.state.openAddAttachmentDialog}
                        onClose={() => this.handleClose("addAttachment")}
                        aria-labelledby="addAttachmentDialog"
                    >
                        <DialogTitle id="addAttachmentDialog">Add Attachment</DialogTitle>
                        <DialogContent>

                            {/* File Upload */}
                            <GridContainer justify={"center"}>
                                <GridItem xs={12} sm={12} md={12} lg={12}
                                          style={{textAlign: "center", margin: "15px 15px 0 15px"}}>
                                    <input
                                        accept="*"
                                        className={classes.input}
                                        id="uploadQuoteInput"
                                        type="file"
                                        style={{display: "none"}}
                                        onChange={(event) => this.changeUpload(event)}
                                    />
                                    <label htmlFor="uploadQuoteInput">
                                        <Button size={"sm"} variant="contained" component="span"
                                                className={classes.button}>
                                            Choose File
                                        </Button>
                                    </label>
                                </GridItem>

                                {/* Current Attachment Name */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p style={{
                                        fontSize: 12,
                                        textAlign: "center",
                                        marginTop: 10
                                    }}>{this.state.currentUploadFileName}</p>
                                </GridItem>

                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose("addAttachment")} color="info">
                                Close
                            </Button>

                            {/* Add attachment button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.addAttachment()}
                                        color="primary">
                                    Add
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>


                    {/* View Quote Attachments Dialog */}
                    <Dialog
                        open={this.state.openViewAttachmentsDialog}
                        onClose={() => this.handleClose("viewAttachment")}
                        aria-labelledby="viewAttachmentDialog"

                    >
                        <DialogTitle id="viewAttachmentDialog">Attachments</DialogTitle>
                        <DialogContent style={{width: 600}}>

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div style={{position: "relative"}}>
                                    <ReactTable

                                        data={this.state.quoteAttachments}
                                        filterable={false}
                                        sortable={false}
                                        resizable={false}
                                        noDataText="No attachments"
                                        columns={[
                                            {
                                                Header: "File",
                                                accessor: "fileName",
                                                headerStyle: {fontSize: 14, fontWeight: 400},
                                            },
                                            {
                                                Header: "Uploaded By",
                                                accessor: "user.userDetails",
                                                headerStyle: {fontSize: 14, fontWeight: 400},
                                                Cell: row => (
                                                    <p>{row.value.name + " " + row.value.surname}</p>
                                                ),
                                            },
                                            {
                                                Header: "Uploaded",
                                                accessor: "attachmentCreated",
                                                headerStyle: {fontSize: 14, fontWeight: 400},
                                                Cell: row => (
                                                    <p>{row.value !== null ? new Date(row.value).getDate() + " " + this.state.months[new Date(row.value).getMonth()] + " " + new Date(row.value).getFullYear() + " at " +  new Date(row.value).getHours() + ":" + new Date(row.value).getMinutes() + ":" + new Date(row.value).getSeconds() : ""}</p>
                                                ),
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "id",
                                                headerStyle: {fontSize: 14, fontWeight: 400, textAlign: "center"},
                                                style: {"textAlign": "center"},
                                                Cell: row => (
                                                    <div>
                                                        <Button simple disabled={loadingDownloadFile} onClick={() => {

                                                            this.setState({
                                                                loadingDownloadFile: true
                                                            });

                                                            let id = this.state.quoteAttachments.findIndex(o => o.id === row.value);
                                                            let uuid = this.state.quoteAttachments[id].uuid;
                                                            let fileName = this.state.quoteAttachments[id].fileName;

                                                            this.downloadFileAPI(uuid, fileName);

                                                        }}><GetApp color={"primary"} style={{margin: 0}}/></Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "id",
                                                desc: true
                                            }
                                        ]}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        showPageJump={false}
                                    />
                                    { loadingDownloadFile && <CircularProgress size={24} style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}/>}
                                </div>
                            </GridItem>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose("viewAttachment")} color="info">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Request extension dialog*/}
                    <Dialog
                        open={this.state.openRequestExtensionDialog}
                        onClose={() => this.handleClose("extension")}
                        aria-labelledby="extensionDialog"
                        maxWidth={false}>
                        <DialogTitle id="extensionDialog">Request Extension</DialogTitle>
                        <DialogContent>

                            {/* Date of extension*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <CustomInput
                                    id="extensionDateInput"
                                    labelText="Extended date"
                                    labelProps={{
                                        shrink: true
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: this.state.extensionDateValue,
                                        onChange: event => this.onChange(event, "extension"),
                                        type: "date",
                                    }}
                                />
                            </GridItem>
                        </DialogContent>

                        <DialogActions>
                            {/* Extension Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("extension")}>
                                Back
                            </Button>

                            {/* Extension Submit Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleActionClick(3)}
                                        color="primary" >
                                    Submit
                                </Button>
                                {loadingRequestExtension && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>


                    {/*Dialog that opens when revised quotation is clicked */}
                    <Dialog
                        open={this.state.showRevisedQuoteDialog}
                        onClose={() => this.handleClose("revisedQuote")}
                        maxWidth={false}
                    >
                        <DialogTitle>Revised Quotation</DialogTitle>
                        <DialogContent style={{width: 300}}>

                            {/* Reason for Revised Quotation*/}
                            <GridItem xs={12} sm={12} lg={12} >
                                <CustomInput
                                    id="revisedQuoteReason"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 3,
                                        value: this.state.revisedQuoteReason,
                                        onChange: event => this.onChange(event, "revisedQuote"),
                                        type: "text",
                                        placeholder: "Reason for Revised Quotation",
                                    }}
                                />
                            </GridItem>
                        </DialogContent>

                        <DialogActions>
                            {/* revised Quotation Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("revisedQuote")}>
                                Back
                            </Button>

                            {/* revised Quotation preview Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleOpenPreviewDialog( "revised",2)}
                                        color="primary" >
                                    Preview
                                </Button>
                                {loadingRequestExtension && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>


                    {/*Dialog that opens when Own assessment is clicked */}
                    <Dialog
                        open={this.state.showOwnAssessmentDialog}
                        onClose={() => this.handleClose("ownAssessment")}
                        maxWidth={"sm"}
                    >
                        <DialogTitle>Own Assessment</DialogTitle>
                        <DialogContent>

                            {/* Own assessment declined statements */}
                            <GridItem xs={12} sm={12} lg={12} >
                                <p>A notification that the Project Manager will be making his own assessment <br/> because in terms of Clause 64.1, the Contractor …</p>

                                {/*dropdown*/}
                                <FormControl>
                                    <FormLabel style={{fontSize: 14}} component="legend">Select the statements which apply</FormLabel>
                                    <FormGroup>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedDeclineReason1}
                                                    onChange={(event) => this.onChange(event, "checkBox")}
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'has not submitted the required quotation and details of his assessment within the time allowed',
                                                        id: 'declineReason1',
                                                    }}
                                                />
                                            }
                                            label="has not submitted the required quotation and details of his assessment within the time allowed"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedDeclineReason2}
                                                    onChange={(event) => this.onChange(event, "checkBox")}
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'has not assessed the compensation event correctly',
                                                        id: 'declineReason2',
                                                    }}
                                                />
                                            }
                                            label="has not assessed the compensation event correctly"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedDeclineReason3}
                                                    onChange={(event) => this.onChange(event, "checkBox")}
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'has not submitted a programme which this contract requires the Contractor to submit',
                                                        id: 'declineReason3',
                                                    }}
                                                />
                                            }
                                            label="has not submitted a programme which this contract requires the Contractor to submit"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedDeclineReason4}
                                                    onChange={(event) => this.onChange(event, "checkBox")}
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'the Contractor’s latest programme has not been accepted for one of the reasons stated in this contract',
                                                        id: 'declineReason4',
                                                    }}
                                                />
                                            }
                                            label="the Contractor’s latest programme has not been accepted for one of the reasons stated in this contract"
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormGroup>
                                            <FormControl fullWidth>
                                                <CustomInput
                                                    labelText="Comments"
                                                    id="ownAssessmentComments"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        multiline: true,
                                                        rows: 5,
                                                        value: this.state.ownAssessmentComments,
                                                        onChange: (event) => this.onCommentChange(event, "ownAssessment")
                                                    }}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </FormGroup>
                                </FormControl>
                            </GridItem>
                        </DialogContent>

                        <DialogActions>
                            {/* Own assessment Back Button */}
                            <Button color={"info"} onClick={() => this.handleClose("ownAssessment")}>
                                Back
                            </Button>

                            {/* Own assessment preview Button */}
                            <div style={{position: "relative"}}>
                                <Button type={"submit"} onClick={() => this.handleOpen("ownPreview")}// this.handleOpenPreviewDialog( "own",20)}
                                    color="primary" >
                                    Preview
                                </Button>
                                {loadingRequestExtension && <CircularProgress size={24} style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12
                                }}/>}
                            </div>
                        </DialogActions>
                    </Dialog>


                </GridContainer>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        value: state.tabReducer.value
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetSelectedTab: payload => dispatch(actionCreators.setSelectedTab(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewEvent));
