import React from "react";
import * as PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment/index";

//icons
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import LockOutlined from "@material-ui/icons/LockOutlined";

// core components
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

//custom styles
import loginPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

//custom assets
import smallLogo from "../../../assets/img/Contractly Logo Icon Small-04.png";

import { getApiRoot } from "../../../api";

import CircularProgress from "@material-ui/core/CircularProgress";

// const styles = {
//
//     ...loginPageStyle,
//
//     buttonProgress: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     },
// };

class LoginPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        email: "",
        password: "",

        loading: false,
    };
  }

  //function that handles change of the input boxes
  handleChange = (event, type) => {

    if(type === "email"){
      this.setState({
        email: event.target.value
      })
    }
    else{
      this.setState({
        password: event.target.value
      })
    }

  };

  //function that routes if a user successfully logs in
  successfulLogin = () => {
      let path = "/app/taskView";

      this.props.history.push({
          pathname: path
      });
  };

  //function that calls login api when enter key is pressed
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.loginAPI();
        }
    };

  //Login API
  loginAPI = () => {

      this.setState({
          loading: true
      });

      // let obj = {};
      // obj.username = this.state.email;
      // obj.password = this.state.password;

      //const formBody = Object.keys(obj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&');

      let data = new URLSearchParams();
      data.append('username', this.state.email);
      data.append('password',  this.state.password);


      const headers = new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
      });

      // setTimeout(() => {
          fetch(getApiRoot() +'/auth/loginPage', {
              credentials: "include",
              method: "POST",
              headers,
              body: data
          }).then(res => {

              this.setState({
                  loading: false
              });

              if(res.status === 200){
                  // let cookie = res.headers.get('Set-Cookie');
                  //localStorage.setItem('cookie', cookie);
                  this.successfulLogin();
              }

              else{
                  alert("Invalid Credentials, Please try again");
              }

          }).catch((error) => {
              alert("Error: " + error);
          });
      // },2000)

  };

  render() {
      const { loading } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onKeyPress={event => this.handleKeyPress(event)}>
              <Card>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <img alt="small logo" src={smallLogo} style={{width: 40}}/>
                  <h3 style={{marginTop: 5}}><span><strong>Welcome Back</strong></span></h3>
                </CardHeader>
                <CardBody>
                  {/*Email Input*/}
                  <CustomInput
                    labelText="Email Address"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:  event => this.handleChange(event, "email"),
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailOutlined className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                        autoComplete: "username"
                    }}
                  />
                    {/*Password Input*/}
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:  event => this.handleChange(event, "password"),

                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlined className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      type: "password",
                        autoComplete: "current-password"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                    <div style={{position: "relative", width: "100%"}}>
                      <Button
                          color="info"
                          size={"lg"}
                          style={{width: "100%"}}
                          disabled={loading}
                          onClick={() => this.loginAPI() } >
                        Login
                      </Button>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }
                    </div>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
