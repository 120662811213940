const attachmentsTableStyles = {

    collapseCard: {
        cursor:"pointer",
        margin: "10px 0 0 0",

    },
    otherCard : {
        margin: "30px 0 0 0",
    },
    collapseParagraph: {
        margin: 0,
        display: "inline"
    },
    collapseIcon : {
        color: "#000"
    },
    collapseButton:{
        padding: 0,
        margin: 0,
        backgroundColor: "rgba(0, 0, 0 ,0)",
        boxShadow: "none",
        float: "right",
        width: "fit-content"
    },
    gridItem:{
        margin: 0
    },
    botMargin : {
        marginBottom : 30
    },
    loadingWheelWrapper : {
        position: 'relative'
    },
    loadingWheel : {
        position: 'absolute',
        top: 'calc(50% - 14px)',
        left: 'calc(50% - 14px)'
    },
    marBtm0 : {
        marginBottom: 0
    },
};
export default attachmentsTableStyles;
