import customSelectStyles from "../jss/material-dashboard-pro-react/customSelectStyle";
import validationFormsStyle from "../jss/material-dashboard-pro-react/views/validationFormsStyle";

const attachmentInputStyles = {
    ...customSelectStyles,...validationFormsStyle,
    input: {
        display: 'none',
    },
    gridContainer : {
        marginTop: 15
    },
    gridItem : {
        // margin: "15px 15px 0 15px"
    },
    heading : {
        marginBottom: 0, fontWeight: 400, display: "inline", marginRight: 10
    },
    addButton : {
        // marginTop: 15
    },
    customListItem : {
        paddingLeft: 0, borderBottom: "1px solid #999"
    },
    closeIcon : {
        cursor: "pointer", fontSize: 16, marginTop: 5, color: "red"
    },
    fileUploadInput : {
        display : "none",
    },
    currentAttachmentName : {
        fontSize: 12,
        // textAlign: "center",
        marginTop: 10
    },
    noDataText : {
        fontSize:14,
        fontStyle:"italic",
        // marginLeft: 5,
        marginBottom : 0,
        //fontWeight : "bold"
    }

};
export default attachmentInputStyles;
