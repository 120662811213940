import React from "react";
import cx from "classnames";
import * as PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
// import LockOpen from "@material-ui/icons/LockOpen";
// import MonetizationOn from "@material-ui/icons/MonetizationOn";
import logoWord from "assets/img/logoWord.png";
// core components
import Button from "../../components/CustomButtons/Button";
import authNavbarStyle from "../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle.jsx";
import smallLogo from "assets/img/Contractly Logo Icon Small-04.png";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return (this.props.location.pathname.indexOf(routeName) > -1);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    let list = (
      <List className={classes.list}>
        {/*<ListItem className={classes.listItem}>*/}
        {/*  <NavLink*/}
        {/*    to={"/auth/pricingPage"}*/}
        {/*    className={cx(classes.navLink, {*/}
        {/*      [classes.navLinkActive]: this.activeRoute("/auth/pricingPage")*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    <MonetizationOn className={classes.listItemIcon} />*/}
        {/*    <ListItemText*/}
        {/*      primary={"Pricing"}*/}
        {/*      disableTypography={true}*/}
        {/*      className={classes.listItemText}*/}
        {/*    />*/}
        {/*  </NavLink>*/}
        {/*</ListItem>*/}
        <ListItem className={classes.listItem}>
          <NavLink
            to={"/auth/registerPage"}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: this.activeRoute("/auth/registerPage")
            })}
          >
            <PersonAdd className={classes.listItemIcon} />
            <ListItemText
              primary={"Register"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
          <NavLink
            to={"/auth/loginPage"}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: this.activeRoute("/auth/loginPage")
            })}
          >
            <Fingerprint className={classes.listItemIcon} />
            <ListItemText
              primary={"Login"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden mdUp>
            <Button
                className={classes.sidebarButton + " " + classes.buttonMargin}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden smDown>
            <div className={classes.flex}>
              <img src={smallLogo} style={{width: 40}} alt={"Contractly"}/>
              <img src={logoWord} alt={"Contractly"} style={{marginLeft: 10, width: 100}}/>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <img src={smallLogo} style={{width: 40}} alt={"Contractly"}/>
              <img src={logoWord} alt={"Contractly"} style={{marginLeft: 10, width: 100}}/>
            </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"left"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

AuthNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};

export default withStyles(authNavbarStyle)(AuthNavbar);
