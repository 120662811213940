import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {addZero} from "../../globals";

class CommentsList extends React.Component{

   constructor(props){
       super(props);

       this.state  = {
           days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
           months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
       };
   }

    //returns list of comments based on properties
    render(){
        return (
            <List>
                {/*List with comments*/}
                {this.props.items.length !== 0 ? this.props.items.sort(function(a,b){
                    return parseInt(a.id) - parseInt(b.id);
                }).map((item, index) => <ListItem style={{paddingLeft: 0, borderBottom: "1px solid #999"}} key={index}><ListItemText primary={item.comment} secondary={item.commentCreated !== null ? "Added by " + item.user.userDetails.name + " " + item.user.userDetails.surname + " on " + new Date(item.commentCreated).getDate() + " " + this.state.months[new Date(item.commentCreated).getMonth()] + " " + new Date(item.commentCreated).getFullYear() + " at " +  addZero(new Date(item.commentCreated).getHours()) + ":" + addZero(new Date(item.commentCreated).getMinutes()) + ":" + addZero(new Date(item.commentCreated).getSeconds()): "Added by " + item.user.userDetails.name + " " + item.user.userDetails.surname}/></ListItem>)
                : "No Comments"}
            </List>
        );
    }
}
export default CommentsList;