import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Close from "@material-ui/icons/Close";
import * as PropTypes from 'prop-types';
import attachmentInputStyles from "../../assets/styles/attachmentInputStyles";

class UploadAttachmentInput extends React.Component {

    render(){
        const { classes } = this.props;
        return(
            <GridContainer className={classes.gridContainer}>
                {/* Attachments */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <hr style={{margin: "0 0 10px 0"}}/>
                        <p className={classes.heading} style={{color : this.props.headingColor}}><strong>{this.props.heading}</strong></p>
                        <hr style={{margin: "10px 0 10px 0"}}/>
                    </GridItem>
                {/* Add Attachment Button */}
                    <GridItem xs={12} sm={12} md={12} lg={12} style={{margin: "0 0 10px 0"}}>
                        <input
                            accept="*"
                            id={this.props.id}
                            type="file"
                            className={classes.fileUploadInput}
                            onChange={(event) => this.props.onChange(this.props.data, event)}
                        />
                        <label htmlFor={this.props.id}>
                            <Button size={"sm"} color={"info"} variant="contained" component="span" className={classes.addButton} style={{cursor: "pointer"}}
                            >
                                Add File
                            </Button>
                        </label>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={12}>
                    {/* Current Attachments list */}
                        <List>
                            {
                                this.props.attachments && this.props.attachments.length !== 0 ? this.props.attachments.map((item, index) =>
                                    <ListItem className={classes.customListItem}
                                              key={index}>
                                        {item.fileName}
                                        {/* Remove item from list */}
                                        <ListItemSecondaryAction>
                                            <Close
                                                className={classes.closeIcon}
                                                onClick={() => this.props.onRemoveFromListParent(index, this.props.data)}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>)
                                : <p className={classes.noDataText}>{this.props.noDataText}</p>
                            }
                        </List>
                    </GridItem>
            </GridContainer>
        );
    }
}

UploadAttachmentInput.propTypes = {
    onChange: PropTypes.func,
    onRemoveFromListParent: PropTypes.func,
    attachments: PropTypes.array,
    noDataText: PropTypes.string,
    heading : PropTypes.string,
    headingColor : PropTypes.string,
    id : PropTypes.string
};
export default withStyles(attachmentInputStyles)(UploadAttachmentInput);
