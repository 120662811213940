import * as actionTypes from './actionTypes';
import fileDownload from "js-file-download";
import axiosInstance from "../../axiosInstance";

//START : Upload files

const uploadFilesStart = () => {
    return {
        type: actionTypes.UPLOAD_FILES_START
    };
};

const uploadFilesFail = (error) => {
    return {
        type: actionTypes.UPLOAD_FILES_FAIL,
        data: null,
        error: error
    };
};

const uploadFilesSuccess = () => {
    return {
        type: actionTypes.UPLOAD_FILES_SUCCESS,
        error: null
    };
};

export const uploadFiles = (payload) => {
    return (dispatch) => {
            dispatch(uploadFilesStart());
            
        return axiosInstance.post("/api" + payload.url, payload.data,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .catch(error => {
                dispatch(uploadFilesFail(error));
                throw error;
            });

    };
};

export const uploadAllFiles = (payload) => {
    return (dispatch) => {
        return Promise.all(payload.promises)
        .then(() => {
            dispatch(uploadFilesSuccess());
        });
    };
};


//END : Upload files

//START : Download file

const downloadFileStart = (id) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_START,
        id: id
    };
};

const downloadFileFail = (error, id) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_FAIL,
        error: error,
        id: id
    };
};

const downloadFileSuccess = (id) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_SUCCESS,
        error: null,
        id: id
    };
};

export const downloadFile = (payload) => {
    return (dispatch) => {
        dispatch(downloadFileStart(payload.id));
        return axiosInstance.get("/api/download_file/" + payload.uuid, {
                responseType: 'blob',
            })
            .then(response => {
                dispatch(downloadFileSuccess(payload.id));
                fileDownload(response.data, payload.fileName);
            })
            .catch(error => {
                dispatch(downloadFileFail(error, payload.id));
                throw error;
            });
    };
};

//END : Download file