import * as actionTypes from '../actions/actionTypes';

const initialState = {
    uploadFileLoading : false,
    uploadFileError : null,
    downloadFileLoading : {},
    downloadFileError : {}
};

//START : Upload File

const uploadFileStart = (state) => {
    return {
        ...state,
        uploadFileLoading : true
    };
};

const uploadFileSuccess = (state) => {
    return {
        ...state,
        uploadFileLoading : false,
        uploadFileError : null
    };
};

const uploadFileFail = (state, action) => {
    return {
        ...state,
        uploadFileLoading : false,
        uploadFileError : action.error
    };
};

//END : Upload File

//START : Download File

const downloadFileStart = (state, action) => {
    return {
        ...state,
        downloadFileLoading : {
            ...state.downloadFileLoading, [action.id] : true
        }
    };
};

const downloadFileSuccess = (state, action) => {
    return {
        ...state,
        downloadFileLoading : {
            ...state.downloadFileLoading, [action.id] : false
        },
        downloadFileError : {
            ...state.downloadFileError, [action.id] : null
        },
    };
};

const downloadFileFail = (state, action) => {
    return {
        ...state,
        downloadFileLoading : {
            ...state.downloadFileLoading, [action.id] : false
        },
        downloadFileError : {
            ...state.downloadFileError, [action.id]: action.error
        }
    };
};

//END : Download File

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.UPLOAD_FILES_START: return uploadFileStart(state);
        case actionTypes.UPLOAD_FILES_SUCCESS: return uploadFileSuccess(state);
        case actionTypes.UPLOAD_FILES_FAIL: return uploadFileFail(state, action);
        case actionTypes.DOWNLOAD_FILE_START: return downloadFileStart(state, action);
        case actionTypes.DOWNLOAD_FILE_SUCCESS: return downloadFileSuccess(state, action);
        case actionTypes.DOWNLOAD_FILE_FAIL: return downloadFileFail(state, action);
        default: return state;
    }
};
export default reducer;