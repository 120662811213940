import React from "react";
import * as PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
//Core components
import InputAdornment from "@material-ui/core/InputAdornment/index";
//Icons
import Close from "@material-ui/icons/Close";
//Custom components
import Button from "../../../../components/CustomButtons/Button";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody";
import CustomInput from '../../../../components/CustomInput/CustomInput.jsx';
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
//Custom styles
import customSelectStyles from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import validationFormsStyle from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

import { verifyNumber, verifyLength } from "../../../../globals";
import { getApiRoot } from "../../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import compensationEventTabsStyles from "../../../../assets/styles/compensationEventTabsStyles";


//Custom styles
const styles = theme => ({
   ...customSelectStyles,...validationFormsStyle, ...compensationEventTabsStyles(theme),
});

class AddProject extends React.Component{

    state = {

        postData: {
            title: "",
            initial_value: "",
            user_id: 1,
            commences: "",
            completes:  "",
        },

        titleState: "",
        initial_valueState: "",
        completesState: "",
        commencesState: "",

        loading: false,

    };

    //function that fires when add project button is pressed
    handleSubmitAddProject = (event) => {
        // alert(
        // "Submitted data:  \n{ \nProject Name: " + this.state.title +
        // ", \nInitial Value: " + this.state.initial_value +
        // ", \nInitial Start Date: " + this.state.commences +
        // ", \nInitial End Date: " + this.state.completes +
        // "\n}"
        // );

        let flag = true;

        if (this.state.titleState === "") {
            this.setState({ titleState: "error" });
            flag = false;
        }
        else if(this.state.titleState === "error"){
            flag = false;
        }

        if (this.state.initial_valueState === "") {
            this.setState({ initial_valueState: "error" });
            flag = false;
        }
        else if(this.state.initial_valueState === "error"){
            flag = false;
        }

        if (this.state.commencesState === "") {
            this.setState({ commencesState: "error" });
            flag = false;
        }
        else if(this.state.commencesState === "error"){
            flag = false;
        }


        if (this.state.completesState === "") {
            this.setState({ completesState: "error" });
            flag = false;
        }
        else if(this.state.completesState === "error"){
            flag = false;
        }

        if(flag){
            this.addProjectAPI();
        }
        else{
            alert("One or more empty fields");
        }

        event.preventDefault();
    };

    //onChange handler for input fields
    change(event, stateName, type, stateNameEqualTo) {

        const id = event.target.id;

        if(id === "titleInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { title: event.target.value }),
            });
        }
        else if(id === "projectInitialValueInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { initial_value: event.target.value }),
            });
        }
        else if(id === "projectInitialStartDateInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { commences: event.target.value }),
            });
        }
        else if(id === "projectInitialEndDateInput"){
            this.setState({
                postData: Object.assign(
                    {},
                    this.state.postData,
                    { completes: event.target.value }),
            });
        }

        switch (type) {
            case "number":
                if (verifyNumber(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "length":
                if (verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            default:
                break;
        }
    }

    //New Project API
    addProjectAPI = () => {

        // alert(JSON.stringify(this.state.postData));

        this.setState({
            loading: true
        });

        let data = JSON.stringify(this.state.postData);

        const headers = new Headers({
            "Content-Type": "application/json",
        });

        // setTimeout(() => {
            fetch(getApiRoot() + '/api/create_project', {
                credentials: "include",
                method: "POST",
                headers,
                body: data,
            }).then(res => {

                this.setState({
                    loading: false
                });

                if (res.status === 200) {
                    // alert("Project Created Successfully");
                    let path = "/app/projectView";

                    this.props.history.push({
                        pathname: path
                    });
                } else {
                    alert("Something went wrong - HTTP: " + res.status);
                }


            }).catch(error => alert(error));
        // }, 2000)
    };


    render(){
        const { loading } = this.state;
        const { classes } = this.props;
        return(
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} lg={8} className={classes.alignTable}>
                        <Card className={classes.cardAlignment} style={{paddingTop: 15}}>
                            <CardBody>
                                <form>
                                    <GridContainer>
                                        {/*Project name input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="titleInput"
                                                labelText="Project Name *"
                                                success={this.state.titleState === "success"}
                                                error={this.state.titleState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style:{marginBottom: 15},
                                                    value: this.state.title,
                                                    onChange: event =>
                                                        this.change(event, "title", "length", 1),
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.titleState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger} />
                                                            </InputAdornment>
                                                        ) : undefined
                                                }}
                                            />
                                        </GridItem>
                                        {/*Project initial value input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="projectInitialValueInput"
                                                labelText="Initial Value *"
                                                success={this.state.initial_valueState === "success"}
                                                error={this.state.initial_valueState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style:{marginBottom: 15},
                                                    value: this.state.initial_value,
                                                    // onChange={this.handleInputChange}
                                                    onChange: event =>
                                                        this.change(event, "initial_value", "number"),
                                                    type: "number",
                                                    endAdornment:
                                                        this.state.initial_valueState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger} />
                                                            </InputAdornment>
                                                        ) : undefined
                                                }}
                                            />
                                        </GridItem>
                                        {/*Project initial start date input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="projectInitialStartDateInput"
                                                labelText="Commencement Date *"
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                success={this.state.commencesState === "success"}
                                                error={this.state.commencesState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style:{marginBottom: 15},
                                                    value: this.state.commences,
                                                    // onChange={this.handleInputChange}
                                                    onChange: event =>
                                                        this.change(event, "commences", "length", 1),
                                                    type: "date",
                                                    endAdornment:
                                                        this.state.commencesState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger} />
                                                            </InputAdornment>
                                                        ) : undefined
                                                }}
                                            />
                                        </GridItem>
                                        {/*Project initial end date input*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <CustomInput
                                                id="projectInitialEndDateInput"
                                                labelText="Completion Date *"
                                                labelProps={{
                                                    shrink: true
                                                }}
                                                success={this.state.completesState === "success"}
                                                error={this.state.completesState === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    style:{marginBottom: 15},
                                                    value: this.state.completes,
                                                    // onChange={this.handleInputChange}
                                                    onChange: event =>
                                                        this.change(event, "completes", "length", 1),
                                                    type: "date",
                                                    endAdornment:
                                                        this.state.completesState === "error" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger} />
                                                            </InputAdornment>
                                                        ) : undefined
                                                }}
                                            />
                                        </GridItem>
                                        {/*Required fields marker*/}
                                        <GridItem xs={12} sm={12} lg={12}>
                                            <p style={{color: "rgba(0, 0, 0, 0.54)", float: "right"}}>Required fields marked with *</p>
                                        </GridItem>
                                        {/*Add project button*/}
                                        <GridItem  xs={12} sm={12} lg={12}>
                                            <div style={{position: "relative", float: "right"}}>
                                                <Button
                                                    disabled={loading}
                                                    type={"submit"}
                                                    style={{float: "right"}}
                                                    onClick={(event) => this.handleSubmitAddProject(event)}
                                                    color="primary">
                                                    Add
                                                </Button>
                                                {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12}} /> }
                                            </div>
                                        </GridItem>

                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
        );
    }

}

AddProject.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddProject);