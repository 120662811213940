import * as actionTypes from './actionTypes';

export const setSuccessMessage = (success) => {
    return {
        type: actionTypes.SET_SUCCESS,
        success: success
    };
};

export const hideSuccessMessage = () => {
    return {
        type: actionTypes.HIDE_SUCCESS
    };
};