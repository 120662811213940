import React from 'react';
import projectAndContractDropdownStyles from "../../assets/styles/projectAndContractDropdownStyles";
import GridItem from "../Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import GridContainer from "../Grid/GridContainer";
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";

const styles = {
    ...projectAndContractDropdownStyles
};

class ProjectAndContractDropdown extends React.Component {

    componentDidMount() {
        // this.props.onLoad(this.props.statePrefix);
    }

    render() {

        return (
            <GridContainer>
                {/* project input */}
                <GridItem xs={12} sm={6} md={6} lg={6} style={{marginBottom: 10}}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="project-select" style={{fontSize: 14, color: "#616161"}}>Project *</InputLabel>
                        <Select
                            value={this.props.project}
                            onChange={(event) => this.props.onChange(event, "project", this.props.statePrefix)}
                            style={{fontSize : 14}}
                            inputProps={{
                                id: "project-select"
                            }}
                        >
                            <MenuItem style={{fontSize : 14}} disabled value={0}><em>Choose project</em></MenuItem>
                            {this.props.projectsAndContracts && this.props.projectsAndContracts.length > 0 ? this.props.projectsAndContracts.map((item, index) => (
                                <MenuItem style={{fontSize : 14}} key={index}
                                          value={item.project.id}>{item.project.title}</MenuItem>
                            )) : ""}
                        </Select>
                    </FormControl>
                </GridItem>

                {/* contract input */}
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="contract-select" style={{fontSize: 14, color: "#616161"}}>Contract *</InputLabel>
                        <Select
                            value={this.props.contract}
                            onChange={(event) => this.props.onChange(event, "contract", this.props.statePrefix)}
                            style={{fontSize : 14}}
                            inputProps={{
                                id: "contract-select"
                            }}
                        >
                            <MenuItem style={{fontSize : 14}} disabled value={0}><em>Choose contract</em></MenuItem>
                            {this.props.projectsAndContracts && this.props.currentChosenProject !== -1 && this.props.projectsAndContracts.length > 0 ? this.props.projectsAndContracts[this.props.currentChosenProject].contracts.map((item, index) => (
                                <MenuItem style={{fontSize : 14}} key={index}
                                          value={item.id}>{item.number}</MenuItem>
                            )) : ""}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        );
    }

}

ProjectAndContractDropdown.propTypes = {
    onChange : PropTypes.func,
    onLoad : PropTypes.func,
    project : PropTypes.number,
    contract : PropTypes.number,
    projectsAndContracts : PropTypes.array,
    currentChosenProject : PropTypes.number,
    statePrefix : PropTypes.string
};

export default withStyles(styles)(ProjectAndContractDropdown);
