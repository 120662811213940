//USER
export const GET_PERMISSIONS_START =  'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_FAIL =  'GET_PERMISSIONS_FAIL';
export const GET_PERMISSIONS_SUCCESS =  'GET_PERMISSIONS_SUCCESS';

export const GET_ROLE_START =  'GET_ROLE_START';
export const GET_ROLE_FAIL =  'GET_ROLE_FAIL';
export const GET_ROLE_SUCCESS =  'GET_ROLE_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';

//COMMUNICATIONS
export const GET_COMMUNICATION_REGISTER_START =  'GET_COMMUNICATION_REGISTER_START';
export const GET_COMMUNICATION_REGISTER_FAIL =  'GET_COMMUNICATION_REGISTER_FAIL';
export const GET_COMMUNICATION_REGISTER_SUCCESS =  'GET_COMMUNICATION_REGISTER_SUCCESS';

export const GET_COMMUNICATION_DETAILS_START =  'GET_COMMUNICATION_DETAILS_START';
export const GET_COMMUNICATION_DETAILS_FAIL =  'GET_COMMUNICATION_DETAILS_FAIL';
export const GET_COMMUNICATION_DETAILS_SUCCESS =  'GET_COMMUNICATION_DETAILS_SUCCESS';

export const PREVIEW_COMMUNICATION_START = 'PREVIEW_COMMUNICATION_START';
export const PREVIEW_COMMUNICATION_FAIL = 'PREVIEW_COMMUNICATION_FAIL';
export const PREVIEW_COMMUNICATION_SUCCESS = 'PREVIEW_COMMUNICATION_SUCCESS';

export const CREATE_COMMUNICATION_START = 'CREATE_COMMUNICATION_START';
export const CREATE_COMMUNICATION_FAIL = 'CREATE_COMMUNICATION_FAIL';
export const CREATE_COMMUNICATION_SUCCESS = 'CREATE_COMMUNICATION_SUCCESS';

export const SEND_COMMUNICATION_START = 'SEND_COMMUNICATION_START';
export const SEND_COMMUNICATION_FAIL = 'SEND_COMMUNICATION_FAIL';
export const SEND_COMMUNICATION_SUCCESS = 'SEND_COMMUNICATION_SUCCESS';

export const SEND_DOWNLOAD_COMMUNICATION_START = 'SEND_DOWNLOAD_COMMUNICATION_START';
export const SEND_DOWNLOAD_COMMUNICATION_FAIL = 'SEND_DOWNLOAD_COMMUNICATION_FAIL';
export const SEND_DOWNLOAD_COMMUNICATION_SUCCESS = 'SEND_DOWNLOAD_COMMUNICATION_SUCCESS';

export const GET_PROJECTS_AND_CONTRACTS_START =  'GET_PROJECTS_AND_CONTRACTS_START';
export const GET_PROJECTS_AND_CONTRACTS_FAIL =  'GET_PROJECTS_AND_CONTRACTS_FAIL';
export const GET_PROJECTS_AND_CONTRACTS_SUCCESS =  'GET_PROJECTS_AND_CONTRACTS_SUCCESS';

export const GET_DEFECTS_START =  'GET_DEFECTS_START';
export const GET_DEFECTS_FAIL =  'GET_DEFECTS_FAIL';
export const GET_DEFECTS_SUCCESS =  'GET_DEFECTS_SUCCESS';

export const GET_RECIPIENTS_START =  'GET_RECIPIENTS_START';
export const GET_RECIPIENTS_FAIL =  'GET_RECIPIENTS_FAIL';
export const GET_RECIPIENTS_SUCCESS =  'GET_RECIPIENTS_SUCCESS';

export const DELETE_RECIPIENT_START = 'DELETE_RECIPIENT_START'
export const DELETE_RECIPIENT_FAIL = 'DELETE_RECIPIENT_FAIL'
export const DELETE_RECIPIENT_SUCCESS = 'DELETE_RECIPIENT_SUCCESS'

export const GET_DEFAULT_RECIPIENTS_START =  'GET_DEFAULT_RECIPIENTS_START';
export const GET_DEFAULT_RECIPIENTS_FAIL =  'GET_DEFAULT_RECIPIENTS_FAIL';
export const GET_DEFAULT_RECIPIENTS_SUCCESS =  'GET_DEFAULT_RECIPIENTS_SUCCESS';

//FILE
export const UPLOAD_FILES_START = 'UPLOAD_FILES_START';
export const UPLOAD_FILES_FAIL = 'UPLOAD_FILES_FAIL';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';

//ERROR
export const SET_ERROR =  'SET_ERROR';
export const HIDE_ERROR =  'HIDE_ERROR';

//SUCCESS
export const SET_SUCCESS =  'SET_SUCCESS';
export const HIDE_SUCCESS =  'HIDE_SUCCESS';

//TABS
export const SET_SELECTED_TAB =  'SET_SELECTED_TAB';

//TO DO
export const SEND_TIME_BAR_NOTIFICATION_START = 'SEND_TIME_BAR_NOTIFICATION_START';
export const SEND_TIME_BAR_NOTIFICATION_FAIL = 'SEND_TIME_BAR_NOTIFICATION_FAIL';
export const SEND_TIME_BAR_NOTIFICATION_SUCCESS = 'SEND_TIME_BAR_NOTIFICATION_SUCCESS';

export const ACKNOWLEDGE_TODO_START = 'ACKNOWLEDGE_TODO_START';
export const ACKNOWLEDGE_TODO_FAIL = 'ACKNOWLEDGE_TODO_FAIL';
export const ACKNOWLEDGE_TODO_SUCCESS = 'ACKNOWLEDGE_TODO_SUCCESS';