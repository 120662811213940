const clause432Dropdown = [
  "4 weeks. In the case of replacement of Plant Materials, the defects correction period commences upon delivery of the replacement Plant and Materials to the Site.",
  "Defects which the Supervisor notifies may jeopardise the performance of the works in use is the shortest possible time starting immediately upon notification of the Defect, with the period not exceeding 2 weeks.",
  "Defects which the Supervisor notifies require urgent correction is 48 (forty eight) hours, with it being that the Employer gives access within that time, the correction being limited to the neutralisation of the effect of the Health and Safety defect only where after the normal procedure for correcting defects will be applicable."
]

export const notificationClauses = {
  apiUrls: {
    preview: "/preview_notification",
    previewRevision: "/preview_notification_revision",
    previewRevisionResponse: "/preview_notification_revision_response",
    create: "/create_notification",
    createRevision: "/create_notification_revision",
    createRevisionResponse: "/create_notification_revision_response",
    updateRevisionResponseStatus: "/update_notification_revision_response_status",
    send: "/send_notification",
    sendRevision: "/send_notification_revision",
    sendRevisionResponse: "/send_notification_revision_response",
    sendDownload: "/send_notification_and_download",
    sendDownloadRevision: "/send_notification_revision_and_download",
    sendDownloadRevisionResponse: "/send_notification_revision_response_and_download",
    register: "/notification_by_clause",
    details: "/notification",
    attachments: "/upload_files",
    projectsAndContracts: "/projects_contracts_list",
    defects: "/defects",
    defectsRegister : "/notification_by_clause/42.2",
    accessArrangedRegister : "/notification_by_clause/43.4",
    uncorrectedDefectRegister : "/notification_by_clause/45.1",
    uncorrectedDefectSecondRegister : "/notification_by_clause/45.2",
    notificationTos : "/notification_tos",
    deleteNotificationTo : "/delete_notification_to",
    defaultNotificationTo : "/automatically_included"
  },
  clauseData: [
    {
      id : 1,
      clauseNumber: "40.3",
      clauseTitle: "Notify Test or Inspection",
      hasRevisions: true,
      displayData : {
        submit : {
          initial : "Notify",
          revision : "Notify new Test/Inspection"
        },
        response : {
          accept : "Pass",
          reject : "Fail",
          details : "Results"
        },
        status : {
          accepted : "Passed",
          rejected : "Failed",
        },
        details : "Test/Inspection Revision"
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Date of Test",
          field: "dateOfTest",
          required: true,
          type: "date",
          revisionField: true
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "notificationTos",
          required: false,
          type: "email",
          revisionField: true
        }
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Current revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Revision Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for failure",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          },
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 2,
      clauseNumber: "42.2",
      clauseTitle: "Notify Defect",
      hasRevisions: true,
      revisionIsResponse : true,
      displayData : {
        submit : {
          initial : "Notify",
          revision : "Notify Correction"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Correction Results"
        },
        status : {
          accepted : "Accepted",
          rejected : "Rejected",
        },
        details : "Correction of Defect Revision"
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false,
        },
        {
          labelText : "Title",
          field : "title",
          required : true,
          type : "text",
          revisionField: true,
          displayInitialDetails : true
        },
        {
          labelText : "Description",
          field : "description",
          required : true,
          type : "textArea",
          revisionField: true,
          displayInitialDetails : true
        },
        {
          labelText : "Defects Category",
          field : "category",
          required : true,
          type : "category",
          revisionField: false,
          displayInitialDetails : true,
          objectTemplate: clause432Dropdown
        },
        {
          labelText : "Defects Location",
          field : "defectsLocation",
          required : true,
          type : "textArea",
          revisionField: false,
          displayInitialDetails : true
        },
        // {
        //   labelText : "Date of Defect",
        //   field : "dateNotified",
        //   required : true,
        //   type : "date",
        //   revisionField: false,
        //   displayInitialDetails : true
        // },
        {
          labelText : "Attachments",
          field : "attachments",
          required : false,
          type : "attachment",
          revisionField: true,
          displayInitialDetails : true
        },
        {
          labelText: "Send To (Others)",
          field: "notificationTos",
          required: false,
          type: "email",
          revisionField: true
        },
        {
          labelText: "Included Recipients",
          field: "defaultRecipients",
          required: false,
          type: "default",
          revisionField: true
        },
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header : "Correction revisions",
            property: "numberRevisions",
            visible: true
          },
          {
            header : "Correction Status",
            property: "revisionStatus",
            visible: true
          }
        ]
      },
      response: {
        rejectFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          },
          {
            labelText: "Reasons for rejection",
            field: "rejectReasons",
            required: true,
            type: "textArea"
          },
          {
            labelText: "Attachments",
            field: "attachments",
            required: false,
            type: "attachment"
          }
        ],
        acceptFields: [
          {
            labelText: "Comments",
            field: "comments",
            required: false,
            type: "textArea"
          }
        ]
      }
    },
    {
      id : 3,
      clauseNumber: "43.4",
      clauseTitle: "Notify Contractor of Access being arranged with Employer",
      hasRevisions: false,
      displayData : {
        submit : {
          initial : "Notify",
          revision : "Notify new access being arranged"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        },
        status : {
          accepted : "Accepted",
          rejected : "Rejected",
        },
        details : "Details"
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Date of Access",
          field: "dateOfAccess",
          required: true,
          type: "date",
          revisionField: true
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Defect",
          type: "defect",
          required: true,
          field: "defect",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "notificationTos",
          required: false,
          type: "email",
          revisionField: true
        },
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header: "Date Submitted",
            property: "dateCreated",
            visible: true
          },
        ]
      },
      response: null
    },
    {
      id : 4,
      clauseNumber: "45.1",
      clauseTitle: "Assessment of Cost for uncorrected Defect",
      hasRevisions: false,
      displayData : {
        submit : {
          initial : "Assessment",
          revision : "Submit new Assessment"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        },
        status : {
          accepted : "Accepted",
          rejected : "Rejected",
        },
        details : "Details"
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Defect",
          type: "defect",
          required: true,
          field: "defect",
          revisionField: false
        },
        {
          labelText: "Cost",
          field: "cost",
          required: true,
          type: "text",
          revisionField: false
        },
        // {
        //   labelText: "Date",
        //   field: "date",
        //   required: true,
        //   type: "date",
        //   revisionField: true
        // },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "notificationTos",
          required: false,
          type: "email",
          revisionField: true
        },
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header: "Date Submitted",
            property: "dateCreated",
            visible: true
          },
        ]
      },
      response: null
    },
    {
      id : 5,
      clauseNumber: "45.2",
      clauseTitle: "Assessment of Cost for uncorrected Defect",
      hasRevisions: false,
      displayData : {
        submit : {
          initial : "Assessment",
          revision : "Submit new Assessment"
        },
        response : {
          accept : "Accept",
          reject : "Reject",
          details : "Response Details"
        },
        status : {
          accepted : "Accepted",
          rejected : "Rejected",
        },
        details : "Details"
      },
      dataFields: [
        {
          type: "contract",
          field: "contract",
          revisionField: false
        },
        {
          labelText: "Title",
          field: "title",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Description",
          field: "description",
          required: true,
          type: "textArea",
          revisionField: false
        },
        {
          labelText: "Defect",
          type: "defect",
          required: true,
          field: "defect",
          revisionField: false
        },
        {
          labelText: "Cost",
          field: "cost",
          required: true,
          type: "text",
          revisionField: false
        },
        {
          labelText: "Attachments",
          field: "attachments",
          required: false,
          type: "attachment",
          revisionField: true
        },
        {
          labelText: "Send To (Others)",
          field: "notificationTos",
          required: false,
          type: "email",
          revisionField: true
        },
      ],
      register: {
        fields: [
          {
            header: "",
            property: "id",
            visible: false
          },
          {
            header: "",
            property: "type",
            visible: false
          },
          {
            header: "Title",
            property: "title",
            visible: true
          },
          {
            header: "Date Submitted",
            property: "dateCreated",
            visible: true
          },
        ]
      },
      response: null
    },
  ]
};