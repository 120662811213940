const communicationRevisionActionStyles = theme => ({

    // acceptButton : {
    //     backgroundColor : "#4CAE50"
    // },
    // rejectButton : {
    //     backgroundColor : "#F44336"
    // }

    divFloatRight : {
        [theme.breakpoints.up('sm')]: {
            float: "right"
        }
    },
    alignRight : {
        textAlign: "right"
    }

});
export default communicationRevisionActionStyles;
