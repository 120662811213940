import React from "react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import { withStyles } from '@material-ui/core/styles';
import Collapse from "@material-ui/core/Collapse";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Button from "../../CustomButtons/Button";
import SubmissionCreateForm from "./SubmissionCreateForm";
import communicationClauseStyles from "../../../assets/styles/communicationClauseStyles";
import * as PropTypes from "prop-types";
import SubmissionRegister from "./SubmissionRegister";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import List from '@material-ui/icons/List';
import { replaceSubStringWithItalics} from '../../../globals';
import { withRouter } from 'react-router-dom';

class SubmissionClause extends React.Component {

    state = {
        openMainCollapse: false,
        openSubmitCollapse: false,
        openRegisterCollapse: false,
    };

    handleToggleCollapse = (type) => {
        if(type === "main"){
            this.setState(prevState => ({ openMainCollapse: !prevState.openMainCollapse }));
        }
        else if(type === "submit"){
            this.setState(prevState => ({ openSubmitCollapse: !prevState.openSubmitCollapse }));
        }
        else if(type === "register"){
            this.setState(prevState => ({ openRegisterCollapse: !prevState.openRegisterCollapse }));
        }

    };

    shouldSubmitAccordionShow = () => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            //submission to be replaced with this.props.type
            const permissionObject = this.props.permissions.find(o => o.type === "submission" && o.subtype === "create");
            if(permissionObject && permissionObject.permission === "true"){
               return true;
            }
        }
        return false;
    };

    shouldRegisterAccordionShow = () => {
        if(this.props.permissions && this.props.permissions.length !== 0){
            //submission to be replaced with this.props.type
            const permissionObject = this.props.permissions.find(o => o.type === "submission" && o.subtype === "read");
            if(permissionObject && permissionObject.permission === "true"){
               return true;
            }
        }
        return false;
    };

    generateComponent = (classes) => {
        const showCreateForm = this.shouldSubmitAccordionShow();
        // const showRegister = this.shouldSubmitAccordionShow();

        if(showCreateForm && this.props.clauseData.register !== null){
            return (
              <GridItem xs={12} sm={12} lg={12}  className={classes.gridItem}>
                  <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse("main")}>
                      <CardBody>
                          <p className={classes.collapseParagraph} ><strong>Clause {this.props.clauseData.clauseNumber}</strong> - {replaceSubStringWithItalics(this.props.clauseData.clauseTitle)}</p>
                          <Button className={classes.collapseButton} fullWidth>{!this.state.openMainCollapse ?  <ExpandMore className={classes.collapseIcon}/> : <ExpandLess className={classes.collapseIcon}  />}</Button>
                      </CardBody>
                  </Card>
              <Collapse in={this.state.openMainCollapse} timeout="auto">

                  <GridItem xs={12} sm={12} lg={12} className={classes.gridItem}>
                      <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse("submit")}>
                          <CardBody>
                              <p className={classes.collapseParagraph}><Add style={{marginBottom: -3, fontSize: 16}}/> {this.props.clauseData.displayData.submit.initial}</p>
                              <Button className={classes.collapseButton} fullWidth>{!this.state.openSubmitCollapse ?  <ExpandMore className={classes.collapseIcon}/> : <ExpandLess className={classes.collapseIcon}  />}</Button>
                          </CardBody>
                      </Card>

                      <Collapse in={this.state.openSubmitCollapse} timeout="auto" unmountOnExit>
                          <SubmissionCreateForm type={this.props.type}
                                                   clauseData={this.props.clauseData}
                                                   apiEndpoints={this.props.apiEndpoints}
                                                   isRevision={false}/>
                      </Collapse>

                  </GridItem>

                  <GridItem xs={12} sm={12} lg={12} className={classes.gridItem}>
                      <Card className={classes.collapseCard}
                            onClick={() => this.handleToggleCollapse("register")}>
                          <CardBody>
                              <p className={classes.collapseParagraph}><List style={{marginBottom: -4, fontSize: 16}}/> Register</p>
                              <Button className={classes.collapseButton}
                                      fullWidth>{!this.state.openRegisterCollapse ?
                                <ExpandMore className={classes.collapseIcon}/> :
                                <ExpandLess className={classes.collapseIcon}/>}</Button>
                          </CardBody>
                      </Card>

                      <Collapse in={this.state.openRegisterCollapse} timeout="auto" unmountOnExit>
                          <SubmissionRegister type={this.props.type}
                                              apiEndpoints={this.props.apiEndpoints}
                                              clauseData={this.props.clauseData} />
                      </Collapse>
                  </GridItem>

              </Collapse>
              </GridItem>
            );
        }
        else if(showCreateForm && this.props.clauseData.register === null){
            return (
              <GridItem xs={12} sm={12} lg={12}  className={classes.gridItem}>
                  <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse("main")}>
                      <CardBody>
                          <p className={classes.collapseParagraph} ><strong>Clause {this.props.clauseData.clauseNumber}</strong> - {replaceSubStringWithItalics(this.props.clauseData.clauseTitle)}</p>
                          <Button className={classes.collapseButton} fullWidth>{!this.state.openMainCollapse ?  <ExpandMore className={classes.collapseIcon}/> : <ExpandLess className={classes.collapseIcon}  />}</Button>
                      </CardBody>
                  </Card>
              <Collapse in={this.state.openMainCollapse} timeout="auto" unmountOnExit>
                          <SubmissionCreateForm type={this.props.type}
                                                   clauseData={this.props.clauseData}
                                                   apiEndpoints={this.props.apiEndpoints}
                                                   isRevision={false}/>
                </Collapse>
              </GridItem>
            );
        }
        else if(!showCreateForm && this.props.clauseData.register !== null){
            return (
              <GridItem xs={12} sm={12} lg={12}  className={classes.gridItem}>
                  <Card className={classes.collapseCard} onClick={() => this.handleToggleCollapse("main")}>
                      <CardBody>
                          <p className={classes.collapseParagraph} ><strong>Clause {this.props.clauseData.clauseNumber}</strong> - {replaceSubStringWithItalics(this.props.clauseData.clauseTitle)}</p>
                          <Button className={classes.collapseButton} fullWidth>{!this.state.openMainCollapse ?  <ExpandMore className={classes.collapseIcon}/> : <ExpandLess className={classes.collapseIcon}  />}</Button>
                      </CardBody>
                  </Card>
              <Collapse in={this.state.openMainCollapse} timeout="auto" unmountOnExit>

                      <SubmissionRegister type={this.props.type}
                                             apiEndpoints={this.props.apiEndpoints}
                                             clauseData={this.props.clauseData}/>
              </Collapse>
              </GridItem>
            );
        }
        else{
            return null;
        }
    };

    render() {
        const { classes } = this.props;
        return(
            <GridContainer>
                {this.generateComponent(classes)}
            </GridContainer>
        );
    }
}

SubmissionClause.propTypes = {
    clauseData: PropTypes.object,
    apiEndpoints : PropTypes.object,
    type : PropTypes.string,
};

export default withRouter(withStyles(communicationClauseStyles)(SubmissionClause));

