import React from "react"
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import { withStyles } from '@material-ui/core/styles';
import Collapse from "@material-ui/core/Collapse";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import * as PropTypes from "prop-types";

//Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import radioGroupAccordionStyles from "../../assets/styles/radioGroupAccordionStyles";
import RadioGroupInput from "../CustomInput/RadioGroupInput";
import {replaceSubStringWithItalics} from "../../globals";

class RadioGroupAccordion extends React.Component {

    state = {
        openMainCollapse: false
    };

    handleToggleCollapse = (type) => {
        if(type === "main"){
            this.setState(state => ({ openMainCollapse: !state.openMainCollapse }));
        }
    };

    render() {
        const { classes, radioData, clauseData } = this.props;
        return(
            radioData && radioData.length !== 0 ? <GridContainer>
                <GridItem xs={12} sm={12} lg={12}  className={classes.gridItem + " " + classes.gridItemMar15}>
                    <Card className={classes.collapseCard + " " + classes.card} onClick={() => this.handleToggleCollapse("main")}>
                        <CardBody>
                            <p className={classes.collapseParagraph}><strong>In terms of Clause {clauseData.clauseNumber}</strong> - {replaceSubStringWithItalics(clauseData.labelText)}</p>
                            <Button className={classes.collapseButton} fullWidth>{!this.state.openMainCollapse ?  <ExpandMore className={classes.collapseIcon}/> : <ExpandLess className={classes.collapseIcon}  />}</Button>
                        </CardBody>
                    </Card>
                    <Collapse in={this.state.openMainCollapse} timeout="auto">
                        <GridContainer>
                            {clauseData.subText ?
                                <GridItem xs={12} sm={12} lg={12}>
                                    <Card className={classes.card}>
                                        <CardBody>
                                            <p className={classes.marZero}>{replaceSubStringWithItalics(clauseData.subText)}</p>
                                        </CardBody>
                                    </Card>
                                </GridItem> : null
                            }

                            {
                                radioData.map((item, index) => {
                                    return <React.Fragment key={index}>
                                        <RadioGroupInput dataItem={item} clauseData={clauseData} index={index} onChange={this.props.onChange} isDetails={this.props.isDetails}/>
                                    </React.Fragment>
                                })
                            }
                        </GridContainer>
                    </Collapse>
                </GridItem>
            </GridContainer> : <React.Fragment />
        );
    }
}

RadioGroupAccordion.propTypes = {
    onChange: PropTypes.func,
    clauseData : PropTypes.object.isRequired,
    radioData : PropTypes.array.isRequired,
    isDetails : PropTypes.bool
};

export default withStyles(radioGroupAccordionStyles)(RadioGroupAccordion);

